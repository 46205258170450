
import { Linking, Platform } from 'react-native';
import StaticApi from '../api/Static.api';
import Settings from './settings';

import * as FileSystem from 'expo-file-system';
import useSessionStore from 'src/context/sessionStore';
import { SessionUser } from 'src/models/user';


// import * as Sharing from 'expo-sharing';




export type RestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH';

export type DownloadResultNty = { status: number; uri: string; file_name: string; headers: Headers; error?: any };

export type ApiCallOptions = { response_type?: 'Response' | 'json' | 'text' | 'blob'; throw_errors?: boolean; headers?: HeadersInit, avoid_using_token?: boolean };

export const DEFAULT_CALL_OPTIONS: ApiCallOptions = { response_type: 'json', throw_errors: true, avoid_using_token: false };

export const apiUrl = Settings.getApiURL();
export const apiV2Url = Settings.getApiV2Url();

export function buildApiUrl(path: string, v2: boolean = false): URL {
    if (!path.startsWith('/')) {
        path = '/' + path;
    }
    return new URL((v2 ? apiV2Url : apiUrl) + path);
}

export async function http_request<T = ResponseTypes<any>>(
    url: string | URL,
    method: RestMethods,
    params?: object,
    arg_options?: ApiCallOptions
): Promise<T> {

    const options = { ...DEFAULT_CALL_OPTIONS, ...arg_options };
    // console.log({ url, method, arg_options, has_auth: arg_options?.headers?.has('Authorization'), options })

    const token = options.avoid_using_token ? null : useSessionStore.getState()?.token ?? SessionUser.NTY_GUEST_DEFAULT_MAIL;

    let headers: HeadersInit = options.headers ?? {};

    // console.log({ headers })

    if (params) {
        headers['Content-Type'] = 'application/json';
    }
    // console.log({ token, headers })
    if (token && !headers['Authorization']) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    const fetchParams: RequestInit = {
        method,
        headers
    };

    if (params) {
        fetchParams.body = JSON.stringify(params);
    }

    const isCompleteUrl = (urlString: string): boolean => {
        return urlString.startsWith('http://') || urlString.startsWith('https://');
    };

    if (typeof url === 'object') {
        if (!isCompleteUrl(url.href)) {
            url = new URL(apiUrl + url.href);
        }
        if (token)
            url.searchParams.append('token', token);
    } else {
        if (!isCompleteUrl(url)) {
            url = apiUrl + url;
        }
        if (token) {
            const separator = url.indexOf('?') !== -1 ? '&' : '?';
            url = url + (token ? separator + 'token=' + token : '');
        }
    }

    const fetchPromise = fetch(url, fetchParams);

    return handleResponse<T>(fetchPromise, options);
}

export type ExpoResponse<T = any> = {
    type: string;
    status: number;
    ok: boolean;
    statusText: string;
    headers: Headers;
    url: string;
    bodyUsed: boolean;
    text: () => Promise<string>;
    json: () => Promise<T>;
    blob: () => Promise<Blob>;
} | Response

function getExpoUsableResponse<T = any>(response: Response): ExpoResponse<T> {

    return { type: response.type, status: response.status, ok: response.ok, statusText: response.statusText, headers: response.headers, url: response.url, bodyUsed: response.bodyUsed, text: response.text, json: response.json, blob: response.blob }
}

type ResponseTypes<T> = string | Blob | ExpoResponse<T> | T

export async function handleResponse<T = ResponseTypes<any>>(fetchPromise: Promise<Response>, options: ApiCallOptions = DEFAULT_CALL_OPTIONS): Promise<T> {
    try {
        const response = await fetchPromise;

        if (!response.ok) {
            console.error(response)
            if (options.throw_errors) {
                throw new Error('Response Code: ' + response.status + '. path: ' + response.url);
            }
        }

        switch (options.response_type) {
            case 'json':
                return await response.json();
            case 'text':
                //@ts-expect-error
                return await response.text();
            case 'blob':
                if (Platform.OS == 'web') {
                    //@ts-expect-error
                    return await response.blob()
                } else {
                    console.error('Blob fetch is not supported in expo mobile!')
                    return null;
                }
            default:
                if (Platform.OS == 'web') {
                    //@ts-expect-error
                    return response
                } else {
                    //@ts-expect-error
                    return getExpoUsableResponse(response);
                }
        }
    } catch (e) {
        if (options.throw_errors) {
            throw e;
        }
    }
}

type DownloadOptions = {
    url: string | URL;
    method: RestMethods;
    params?: object;
    override_filename?: string;
};

async function handleDownload(response: Response | ExpoResponse, fileName: string, platform: string = Platform.OS): Promise<DownloadResultNty> {
    let result: DownloadResultNty;

    if (platform === 'web') {
        const uri = await StaticApi.downloadFile(await response.blob(), fileName);
        result = { file_name: fileName, status: response.status, headers: response.headers, uri };
    } else {
        // handleDownloadMobileWriteAsString(response, fileName)
        // handleDownloadMobileDownloadAsync(response, fileName)
        result = await handleDownloadMobileThroughBrowser(response, fileName)
    }

    // console.log({ downloadFile: result });
    return result;
}

async function handleDownloadMobileWriteAsString(response: Response | ExpoResponse, fileName: string) {

    // Write as text
    const data = await response.text();
    console.log({ data, fileName })
    await FileSystem.writeAsStringAsync(FileSystem.documentDirectory + fileName, data, { encoding: FileSystem.EncodingType.UTF8 });
    await Linking.openURL(FileSystem.documentDirectory + fileName);
    return { file_name: fileName, status: 200, headers: new Headers(response.headers), uri: FileSystem.documentDirectory + fileName };

}
async function handleDownloadMobileDownloadAsync(response: Response | ExpoResponse, fileName: string) {

    const url = response.url
    // Write as text
    const downloaded_file = await FileSystem.downloadAsync(
        url,
        FileSystem.documentDirectory + fileName,
        {
            headers: {
                'Authorization': 'Bearer ' + this.token
            },
        })
    if (downloaded_file.status == 200) {

        const contentUri = await FileSystem.getContentUriAsync(downloaded_file.uri)
        console.log({ fileUri: downloaded_file.uri, contentUri })
        // TODO: Cant make this dependency work
        // Sharing.shareAsync(contentUri, {
        //     UTI: 'text/csv',
        //     mimeType: 'text/csv'
        // })
        Linking.openURL(contentUri)
        return { file_name: fileName, status: 200, headers: new Headers(response.headers), uri: contentUri };

    } else {
        console.error('Error Downloading file at: ' + url)
        console.error({ details: { url: url, response, downloaded_file } })
        return { file_name: fileName, status: downloaded_file.status, headers: new Headers(response.headers), uri: url };
    }

}

async function handleDownloadMobileThroughBrowser(response: ExpoResponse, filename: string) {


    await Linking.openURL(response.url)
    return { file_name: filename, status: response.status, headers: new Headers(response.headers), uri: response.url };

}

export async function downloadFile(options: DownloadOptions): Promise<DownloadResultNty> {
    const { url, method, params, override_filename } = options;

    try {
        console.log('Downloading ' + url);
        const response = await http_request<ExpoResponse>(url, method, params, { response_type: 'Response', throw_errors: false });

        const fileName = override_filename ?? response.headers.get('Content-Disposition')?.split('filename=')[1].replace(new RegExp('"', 'g'), '');
        return handleDownload(response, fileName);
    } catch (e) {
        console.error('Error in downloadFile');
        console.error(e);
        return { file_name: null, status: 500, headers: null, uri: null, error: 'error' };
    }
}
