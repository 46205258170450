import React from 'react'
import { PublisherListProps } from './publisherlist.types'
import HorizontalPublisherList from './HorizontalPublisherList'
import VerticalPublisherList from './VerticalPublisherList'

function PublisherList(props: PublisherListProps) {
    return (
        props.horizontal ? <HorizontalPublisherList {...props} /> : <VerticalPublisherList {...props} />
    )
}

export default PublisherList
