import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import React, { memo, MutableRefObject } from 'react';
import { FlatList, GestureResponderEvent, ListRenderItemInfo, Platform, RefreshControl, useWindowDimensions, View, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { Article, HighlightInterface, HighlightModel } from 'systemDomain';
import ArticleApi from '../../../../api/ArticleApi';
import HighlightApi from '../../../../api/HighlightApi';
import { getNumColumnsFlatlist, openInBrowser, openNewTabLink } from '../../../../core/utils';
import { useHighlightOptionsStore } from '../../../context/optionStores';
import LoadMoreButton from '../../LoadMoreButton';
import TextNty from '../../nty/text/TextNty';
import HighlightCard from '../cards/HighlightCard';


type HighLightListProps<T> = {
    highlights: T[];
    loadMoreDisabled: boolean;
    onLoadMore?: () => void,
    showMeasuresInfo?: boolean;
    /**
     * Indicates the number of columns that this Flatlist must render per row in the available space.
     *
     * The size of the items is represented as a fraction (factor) of the available size, so
     * the developer can decide what type of grid is going to be printed from outside of this component.
     *
     * Example:
     *
     *   | Columns | Factor |
     *   | ------ | ------ |
     *   | 1 | 1/1 |
     *   | 2 | 1/2 |
     *   | 3 | 1/3 |
     *   | 4 | 1/4 |
     *
     * Default value is calculated with the screen width see __getNumColumnsFlatlist__ from utils.ts:
     *
     * ```js
     * const {width} = useWindowDimensions();
     * ...
     * numCols = getNumColumnsFlatlist(width);
     * ```
     *
     *
     * __This property will be used in `FlatList.numColumns`:__
     *
     * Multiple columns can only be rendered with horizontal={false} and will zig-zag like a flexWrap layout. Items should all be the same height - masonry layouts are not supported.
     *
     *
     */
    numColumns?: number,
    /**
    * Whether or not to make this FlatList horizontal.
    * __Setting this to 'true' sets the 'numColumns' property value to 1, whatever it was.__
    */
    horizontal?: boolean,
    /**
     * Set this parameter in order to fix the height of each individual item in the list.
     */
    fixedItemHeight?: number,
    /**
    * Size of the width of each card displayed horizontally. Default is 300.
    * __This value takes no effect when 'horizontal' is set to false.__
    */
    horizontalCardWidth?: string | number,
    /**
     * Function that will be executed each time the refresh control gets launched.
     */
    onRefresh?: () => void,
    onScroll?: (event) => void,
    onItemPress?: (item: T, event?: GestureResponderEvent) => void,
    onItemLongPress?: (item: T, event?: GestureResponderEvent) => void,
    /**
     * Pass in a function in order to rewrite the default behaviour for hihglight options.
     */
    onOptionsPressed?: (item: T, event?: GestureResponderEvent) => void,
    /***
     * Use this flag to manually disable the Highlight Options default mechanism.
     * This will make the Highlight options not to render within this list.
     * Default is false.
     */
    hideOptions?: boolean,
    contentContainerStyle?: ViewStyle
};

const HighlightsList = (
    {
        highlights,
        onLoadMore,
        loadMoreDisabled,
        showMeasuresInfo = false,
        numColumns,
        horizontal = false,
        fixedItemHeight = Platform.OS == 'web' ? 500 : null,
        horizontalCardWidth = 300,
        onRefresh,
        onScroll,
        onItemPress,
        onItemLongPress,
        onOptionsPressed = undefined,
        hideOptions = false,
        contentContainerStyle
    }: HighLightListProps<HighlightInterface>) => {

    const [refreshing, setRefreshing] = React.useState(false);
    const flatListRef: MutableRefObject<FlatList> = React.useRef(null);
    const navigation = useNavigation();

    const { width } = useWindowDimensions();

    const { open } = useHighlightOptionsStore.getState();

    const defaultItemHorizontalPadding = 10;
    const defaultRowSeparatorHeightInternal = 25;
    const rowHorizontalPadding = 15;

    const numColsI = horizontal ? 1 : numColumns ? numColumns : getNumColumnsFlatlist(width);


    const ITEM_WIDTH = horizontal ? horizontalCardWidth : (width - (rowHorizontalPadding * 2)) / numColsI;

    const itemStyle = {
        width: ITEM_WIDTH,
        height: fixedItemHeight,
        paddingHorizontal: defaultItemHorizontalPadding,
        // borderWidth: showMeasuresInfo ? 1 : 0
    }

    const cardStyle = {
        height: fixedItemHeight
    }

    const viewArticleAction = async (article: Article) => {
        ArticleApi.viewArticleAction(article);
    }

    const viewHighlightAction = async (highlight: HighlightInterface) => {
        HighlightApi.viewHighlightAction(highlight);
    }

    const viewHighlightArticle = (article: Partial<Article>, event?: GestureResponderEvent) => {
        if (Platform.OS == 'web' && article?.webview) {
            openNewTabLink(article?.url);
        } else {
            const articleId = article._id
            navigation.push('viewArticle', { id: articleId, newspaper: article.site});
        }
        viewArticleAction(article)
    }

    const highlightPressInternal = async (highlight: HighlightInterface, event?: GestureResponderEvent) => {

        if (highlight.url) {
            const url = highlight.url;
            const opened = await openInBrowser(url);
            if (opened) {
                viewHighlightAction(highlight)
            }
        } else if (highlight.article) {
            viewHighlightArticle(highlight.article)
        }

    }


    const getItemLayoutInternal = React.useCallback((data, index) => {

        return (
            {
                length: fixedItemHeight,
                offset: (fixedItemHeight + defaultRowSeparatorHeightInternal) * (index / numColsI),
                index
            }
        )

    }, [fixedItemHeight, numColsI])

    const itemPress = onItemPress ? onItemPress : highlightPressInternal;
    const keyExtractor = (item: HighlightInterface, index: number) => (item._id + '_' + index);

    const defaultFooterComponent = () => onLoadMore ? <LoadMoreButton onLoadMore={onLoadMore} /> : null;
    const defaultRowSeparatorComponent = () => <View style={{ height: defaultRowSeparatorHeightInternal }}></View>;

    const optionsPressedInternal = hideOptions ? null : onOptionsPressed ? onOptionsPressed : (highlight: HighlightInterface, event) => {
        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
        open(new HighlightModel(highlight));
    }

    const highlightLongPressInternal = onItemLongPress ?? onOptionsPressed ?? optionsPressedInternal;

    // const newspaperPressInternal = (newspaper: NewspaperModel) => {
    //     navigation.navigate('searchNewspaper', { newspaper: newspaper.props.keyName })
    // }

    // const authorPressInternal = (author: string) => {
    //     navigation.navigate('searchAuthor', { author })
    // }

    const renderItemInternal: (info: ListRenderItemInfo<HighlightInterface>) => React.ReactElement<any, string | React.JSXElementConstructor<any>> =

        (info: ListRenderItemInfo<HighlightInterface>) => {


            return (
                <View style={itemStyle}>
                    <HighlightCard
                        highlight={info.item}
                        style={cardStyle}
                        onPress={itemPress}
                        onLongPress={highlightLongPressInternal}
                        // onNewspaperPress={newspaperPressInternal}
                        // onAuthorPress={authorPressInternal}
                    // onOptionsPress={optionsPressedInternal}
                    />
                </View>

            );

        }


    const showMeasuresInfoComponent = () => {
        return (
            <View style={{ width: "100%" }}>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Screen Width:  </TextNty> {width}</TextNty>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Num Columns:  </TextNty> {numColsI}</TextNty>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Item Horizontal Padding:  </TextNty> {defaultItemHorizontalPadding}</TextNty>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Content Horizontal padding:  </TextNty> {rowHorizontalPadding}</TextNty>
                <TextNty><TextNty style={{ fontWeight: 'bold' }}>Item Width:  </TextNty> {ITEM_WIDTH}</TextNty>
                <TextNty><TextNty style={{ fontWeight: 'bold' }}>Item Height:  </TextNty> {fixedItemHeight}</TextNty>
            </View>
        )
    }

    const defaultRefreshControl = () =>
        <RefreshControl
            progressViewOffset={20}
            refreshing={refreshing}
            onRefresh={onRefresh}
        />



    return (

        <Animated.FlatList
            ref={flatListRef}
            data={highlights}
            getItemLayout={fixedItemHeight ? getItemLayoutInternal : null}
            ListHeaderComponent={showMeasuresInfo ? showMeasuresInfoComponent() : null}
            maxToRenderPerBatch={30}
            renderItem={renderItemInternal}
            ItemSeparatorComponent={defaultRowSeparatorComponent}
            key={numColsI + '_'}
            keyExtractor={keyExtractor}
            numColumns={numColsI}
            // columnWrapperStyle={{borderWidth: showMeasuresInfo ? 1 : null}}
            onScroll={
                onScroll ?
                    typeof onScroll == 'function' ?
                        (event) => { onScroll(event); }
                        :
                        (event) => { return onScroll; }
                    : null}
            onEndReached={loadMoreDisabled ? null : Platform.OS == 'web' ? null : onLoadMore ? onLoadMore : null}
            onEndReachedThreshold={0.5}
            horizontal={horizontal}
            contentContainerStyle={[{ paddingHorizontal: rowHorizontalPadding, ...contentContainerStyle, paddingVertical: 6 }, showMeasuresInfo ? { borderWidth: 2, borderColor: 'red' } : null]}
            ListFooterComponent={defaultFooterComponent}
            showsVerticalScrollIndicator={Platform.OS != 'web'}
            onRefresh={onRefresh ? onRefresh : null}
            refreshing={onRefresh ? refreshing : null}
            refreshControl={onRefresh ? defaultRefreshControl() : null}
        />

    );
}

export default memo(HighlightsList);
