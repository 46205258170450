import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import PublisherBubble from '../PublisherBubble';
import { HorizontalPublisherListProps } from './publisherlist.types';



export default function HorizontalPublisherList(props: HorizontalPublisherListProps) {
    return (
        <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={props.publishers}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
                props.renderBubble ? props.renderBubble(item, index) :
                    <PublisherBubble
                        publisher={item}
                        showLabel={true}
                        labelPosition='bottom'
                        fontSize={15}
                        {...props.bubbleProps}
                        onPress={(entity) => props.onBubblePress && props.onBubblePress(entity, index)}
                        onLongPress={(entity) => props.onBubbleLongPress && props.onBubbleLongPress(entity, index)}
                    />
            )}
            contentContainerStyle={styles.container}
            {...props}
        />
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
