
import React, { memo } from "react";
import { Linking, Platform, StyleSheet, View } from 'react-native';
import { Card, Divider, IconButton, List } from "react-native-paper";
import Logo from '../../components/Logo';
import { useAppTheme } from '../../core/theme/ThemeBuilder';

import { openAppInAndroMarket, openAppInIOSMarket } from "../../core/utils";
import { Navigation } from "../../types";
import Background from "../Background";
import ButtonNty from '../components/nty/button/ButtonNty';
import TitleHeaderNty from '../components/nty/header/TitleHeaderNty';
import TextNty from '../components/nty/text/TextNty';



type Props = {
    navigation: Navigation;
    setHeaderBorder: Function;
    setHeaderStyle: null;
    route: any;
};

const ContactInfoScreen = ({ navigation, setHeaderBorder, setHeaderStyle, route }: Props) => {

    const theme = useAppTheme()

    const styles = React.useMemo(() => StyleSheet.create({
        items: {
            color: theme.colors.text_primary[500],
            fontSize: 15,
            fontStyle: 'italic'
        },
        titles: {
            color: theme.colors.text_primary[500],
            fontSize: 16,
            fontFamily: theme.fonts.default.medium.fontFamily
        },
        card: {
            color: 'rgba(0,0,0,0.7)',
            backgroundColor: 'white',
            padding: 10
        },
        advise: {
            fontSize: 20,
            textShadowOffset: { height: 0, width: 0 },
            textShadowRadius: 1,
            fontFamily: 'Roboto_400Regular',
            color: theme.colors.text_primary[500],
            textAlign: 'center',


        },
        rateBtn: {
            borderColor: theme.colors.text_primary[500],
            borderWidth: 1
        }
    }), [theme])


    return (
        <Background safeInsets>
            <TitleHeaderNty title='Contacto' />
            <View style={{ width: '100%', maxWidth: 460, alignSelf: 'center', marginTop: 40, flex: 1, paddingHorizontal: 20 }}>

                <View style={{ flexDirection: "row", justifyContent: 'center', height: 60, marginBottom: 30 }}>
                    <Logo />
                </View>
                <Card style={styles.card} mode={"elevated"} elevation={3}>

                    <Card.Content>
                        <List.Item
                            titleStyle={styles.titles}
                            descriptionStyle={styles.items}
                            title="Email"
                            description="team@thenewstoyou.com"
                            left={props => <List.Icon {...props} icon="mailbox" color={theme.colors.text_primary[500]} />}
                        />
                        <Divider style={{ backgroundColor: theme.colors.secondary[200] + '50', width: '90%', alignSelf: 'center' }} />
                        <List.Item
                            titleStyle={styles.titles}
                            descriptionStyle={styles.items}
                            title="Teléfono"
                            description="+34 631 31 52 12"
                            left={props => <List.Icon {...props} icon="card-account-phone" color={theme.colors.text_primary[500]} />}
                        />
                        <Divider style={{ backgroundColor: theme.colors.secondary[200] + '50', width: '90%', alignSelf: 'center' }} />
                        <List.Item
                            titleStyle={styles.titles}
                            descriptionStyle={styles.items}
                            title="Dirección"
                            description="Calle Cardenal Cisneros 65, 28010, Madrid"
                            left={props => <List.Icon {...props} icon="map-marker-outline" color={theme.colors.text_primary[500]} />}
                        />
                    </Card.Content>
                </Card>
                <View style={{ flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <TextNty style={styles.advise}>¡Ayúdanos a mejorar!</TextNty>
                </View>
                <ButtonNty
                    icon="mail"
                    onPress={() => { Linking.openURL('mailto:team@thenewstoyou.com') }}
                >Buzón de Sugerencias</ButtonNty>
                <View style={{ flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <TextNty style={[styles.advise, { fontSize: 15 }]}>Déjanos tu opinión:</TextNty>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginBottom: 15 }}>
                    {Platform.OS != 'ios' && <IconButton
                        icon="android"
                        color={theme.colors.text_primary[500]}
                        size={20}
                        style={styles.rateBtn}
                        onPress={openAppInAndroMarket}
                    />}
                    {Platform.OS != 'android' && <IconButton
                        icon="apple"
                        color={theme.colors.text_primary[500]}
                        size={20}
                        style={styles.rateBtn}
                        onPress={openAppInIOSMarket}
                    />}

                </View>

            </View>
        </Background>
    );
}



export default memo(ContactInfoScreen);
