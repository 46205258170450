import moment from 'moment';
import { useAppTrialStore } from 'src/context/appTrialStore';
import useDeviceInfoStore from 'src/context/deviceInfoStore';
import useSessionStore from 'src/context/sessionStore';
import { buildApiUrl, downloadFile, http_request } from 'src/core/http-utils';
import { ActionInterface } from 'systemDomain';
import ApiCaller from '../core/ApiCaller';
import { ActionStats } from '../models/ActionStats';
import { TrialTypes } from '../models/Trial';
import { SessionUser } from '../models/user';


const getApiCaller = (currentUser: SessionUser): ApiCaller => {
    const apiCaller = new ApiCaller(currentUser.token);
    return apiCaller
}
const getApiCallerNoAuth = (): ApiCaller => {
    const apiCaller = new ApiCaller();
    return apiCaller
}

export enum ACTION_NAMES {
    VIEW_ARTICLE = 'viewArticle',
    VIEW_ARTICLE_QR = 'viewArticleQr',
    SHARE_ARTICLE = 'shareArticle',
    /**
     * This action should be used when performing a Share Intent with a plain generic URL.
     */
    SHARE_URL = 'shareUrl',
    VIEW_HIGHLIGHT = 'viewHighlight',
    REGISTER = 'register',
    REGISTER_TRY = 'registerTry',
    LOGIN = 'login',
    LOGOUT = 'logout',
    SESSION_START = 'session_start',
    SESSION_LOGOUT = 'session_logout',
    SESSION_RECOVERED = 'guest_session_recovered',
    TRIAL_ACCES = 'trialAccess',
    BOTTOM_NAVIGATION = 'bottomNavigation',
    CLICK_PREMIUM = 'clickPremium',
    GONE_PREMIUM = 'gonePremium',
    VIEW_QUICKNEW = 'viewQuickNew',
    OPEN_QUICKNEW = 'openQuickNew',
    // FETCH_ARTICLES = 'fetchArticles',
    SUBSCRIBE_NEWSPAPER = 'subscribeNewspaper',
    UNSUBSCRIBE_NEWSPAPER = 'unsubscribeNewspaper',
    SUBSCRIBE_AUTHOR = 'subscribeAuthor',
    UNSUBSCRIBE_AUTHOR = 'unsubscribeAuthor',
    DOWNLOAD_APP = 'downloadApp',
    ACCOUNT_DELETED = 'accountDeleted',
    SEARCH = 'search',
    SEARCH_KEYWORD = 'searchKeyword',
    SAVE_ARTICLE = 'saveArticle',
    GO_TO = 'goTo',
    SPOTIFY_PLAY = 'spotifyPlay',
    REQUEST_NEW_SOURCE = 'requestNewSource',
    ENTERPRISE_FEED_FETCH = 'enterpriseFeedFetch',
    ENTERPRISE_FEED_FETCH_GROUPED = 'enterpriseFeedFetchGrouped',
    ENTERPRISE_FEED_REFRESH = 'enterpriseFeedRefresh',
    CREATE_HIGHLIGHT = 'createHighlight',
    DELETE_HIGHLIGHT = 'deleteHighlight',
    VIEW_KEYWORD = 'viewKeyword',
    VIEW_FAVOURITE_LIST = 'viewFavouriteList',
    VIEW_FAVOURITE = 'viewFavourite',
    PURCHASE_CLICK = 'purchaseClick',
    RESTORE_PURCHASE_CLICK = 'restorePurchaseClick',
    SEND_FAV_NEWSLETTER = 'sendFavNewsletter'
}

class ActionApi {

    constructor() { }

    // static async getByName(actionName: string, currentUser: SessionUser): Promise<ActionCollection> {
    //     const name = actionName ? actionName.trim() : '';
    //     const data = await getApiCaller(currentUser).call('/v1/actions/' + name, 'GET');
    //     return new ActionCollection(data.map((item: any) => {
    //         return new ActionModel(item);
    //     }));
    // }


    static async getStatsByActionName<K>(actionName: string, currentUser: SessionUser): Promise<ActionStats<K>[]> {
        const name = actionName ? actionName.trim() : '';
        const data = await getApiCaller(currentUser).call('/v1/actions/' + name + '/stats', 'GET');
        return data;
    }

    static async getLabelsByActionName(actionName: string): Promise<string[]> {
        const name = actionName ? actionName.trim() : '';
        // const data = await getApiCaller(currentUser).call('/v1/actions/' + name + '/dataLabels', 'GET');
        const url = '/v1/actions/' + name + '/dataLabels';
        const data = await http_request(url, 'GET');
        return data;
    }

    static async getStatsByActionNameAndLabel<K>(actionName: string, dataLabel: string, timeInterval?: 'ever' | 'today' | 'yesterday' | 'lastWeek' | 'lastMonth' | 'lastMonths_3' | 'lastMonths_6'): Promise<ActionStats<K>[]> {
        const name = actionName ? actionName.trim() : '';
        const label = dataLabel ? dataLabel.trim() : '';

        let intervalTime;

        switch (timeInterval) {

            case 'ever': intervalTime = null; break;
            case 'today': intervalTime = moment(new Date()).hours(0).minutes(0).seconds(0).toISOString(); break;
            case 'yesterday': intervalTime = moment(new Date()).hours(0).minutes(0).seconds(0).subtract(1, 'days').toISOString(); break;
            case 'lastWeek': intervalTime = moment(new Date()).hours(0).minutes(0).seconds(0).subtract(7, 'days').toISOString(); break;
            case 'lastMonth': intervalTime = moment(new Date()).hours(0).minutes(0).seconds(0).subtract(30, 'days').toISOString(); break;
            case 'lastMonths_3': intervalTime = moment(new Date()).hours(0).minutes(0).seconds(0).subtract((30 * 3), 'days').toISOString(); break;
            case 'lastMonths_6': intervalTime = moment(new Date()).hours(0).minutes(0).seconds(0).subtract((30 * 6), 'days').toISOString(); break;
            default: intervalTime = null;

        }

        if (intervalTime) {

            // const data = await getApiCaller(currentUser).call('/v1/actions/' + name + '/' + label + '/stats?from=' + intervalTime, 'GET');
            const data = http_request(buildApiUrl('/v1/actions/' + name + '/' + label + '/stats?from=' + intervalTime), 'GET');
            return data;

        } else {

            // const data = await getApiCaller(currentUser).call('/v1/actions/' + name + '/' + label + '/stats', 'GET');
            const data = http_request(buildApiUrl('/v1/actions/' + name + '/' + label + '/stats'), 'GET');
            return data;

        }
    }

    static async downloadStatsByActionNameAndLabel<K>(actionName: string, dataLabel: string): Promise<void> {
        const name = actionName ? actionName.trim() : '';
        const label = dataLabel ? dataLabel.trim() : '';
        const url = '/v1/actions/' + name + '/' + label + '/stats/download/';
        // if (Platform.OS == 'web') {

        //     const response = await getApiCaller(currentUser).callFilesWeb(url, 'GET');
        //     const fileName = response.headers.get('Content-Disposition')?.split("filename=")[1];
        //     await StaticApi.downloadFile(await response.blob(), fileName ? fileName : name + '_' + label + '.csv');
        // } else {
        //     Linking.openURL(getLocalhost + "/api" + url + '?token=' + currentUser.token);
        //     return Promise.resolve();
        // }
        const url_ = buildApiUrl(url)
        await downloadFile({ url: url_, method: 'GET', override_filename: name + '_' + label + '.csv' })

    }

    static async downloadDateAggregationByActionNameAndLabel<K>(actionName: string, dataLabel: string): Promise<void> {
        const name = actionName ? actionName.trim() : '';
        const label = dataLabel ? dataLabel.trim() : '';
        const url = '/v1/actions/' + name + '/dateAggregation/download/';
        // if (Platform.OS == 'web') {

        //     const response = await getApiCaller(currentUser).callFilesWeb(url, 'POST', { dataLabels: [label] });
        //     const fileName = response.headers.get('Content-Disposition')?.split("filename=")[1];
        //     await StaticApi.downloadFile(await response.blob(), fileName ? fileName : 'dateAggregation' + name + '_' + label + '.csv');

        // } else {
        //     Linking.openURL(getLocalhost + "/api" + url + '?token=' + currentUser.token);
        //     return Promise.resolve();
        // }
        const url_ = buildApiUrl(url)
        await downloadFile({ url: url_, method: 'POST', params: { dataLabels: [label] }, override_filename: 'dateAggregation' + name + '_' + label + '.csv' })

    }

    // static async all(currentUser: SessionUser): Promise<ActionCollection> {
    //     const data = await getApiCaller(currentUser).call('/v1/actions', 'GET');
    //     return new ActionCollection(data.map((item: any) => {
    //         return new ActionModel(item);
    //     }));
    // }

    // static async read(currentUser: SessionUser, id: string): Promise<ActionModel> {
    //     const data = await getApiCaller(currentUser).call('/v1/actions/' + id, 'GET');
    //     return new ActionModel(data);
    // }

    /**
     * **See {@link ActionApi.createFrom} before using this method**
     *
     * Attempts to create a new action in database.
     *
     * Returns the created action or null in case something bad happened while creating.
     *
     * @param action
     * @returns
     */
    static async create(action: ActionInterface): Promise<ActionInterface> {

        try {
            const data = await getApiCallerNoAuth().call('/v1/actions', 'POST', action);
            return data;
        } catch (e) {
            console.error('Error while creating action: ' + action.name, e);
            return null;
        }
    }

    static async createFrom(action: Partial<ActionInterface> | { name: string, created?: string, profileEmail?: string, enterprise?: string, data: any }) {


        if (!action.name) {
            throw new Error("Tried to create an action without a name!");
        }

        const sessionInfo = useSessionStore.getState()
        const deviceInfo = useDeviceInfoStore.getState()?.deviceInfo
        const trialData = useAppTrialStore.getState()

        let email

        if (action?.profileEmail) {
            email = action.profileEmail
        }

        const commonActionData: any = {
            profileEmail: email,
            created: action.created ?? new Date().toISOString(),
            sessionId: sessionInfo?.session_id,
            deviceInfo,
            enterprise: sessionInfo?.enterprise ?? false
        }

        if (trialData && trialData.trial && trialData.trialData) {
            commonActionData.qr = trialData.trial ? trialData.trial == TrialTypes.QR : false
            commonActionData.partner = trialData.trialData?.enterpriseKey
        }

        if (commonActionData.enterprise == false) {
            delete commonActionData.enterprise
        }

        const finalAction: ActionInterface = { ...commonActionData, name: action.name, data: action.data };


        return ActionApi.create(finalAction)

    }

    // /**
    //  * Attempts to create a new action in database from the given action fields.
    //  *
    //  * Returns the created action or null in case something bad happened while creating.
    //  *
    //  * @param action
    //  * @returns
    //  * @deprecated
    //  */
    //  static async createFrom_(action: Partial<ActionInterface> | {name: string, created?: string, profileEmail?: string, enterprise?: string, data?: any, user?: SessionUser}): Promise<ActionModel> {

    //     const sessionData = useAppSessionStore.getState()
    //     const trialData = await getTrialSnapshot()

    //     const commonActionData: any = {
    //         profileEmail: action.user && action.user?.email ? action.user?.email : sessionData.user ? sessionData.user.email : 'unauthenticated',
    //         created: new Date().toISOString(),
    //         sessionId: sessionData?.session?.id,
    //         userType: sessionData?.user?.type,
    //         deviceInfo: sessionData?.session?.deviceInfo,
    //         enterprise: sessionData.isEnterprise ? sessionData.enterprise?.props.name : false
    //     }

    //     if(trialData && trialData.trial && trialData.trialData) {
    //         commonActionData.qr = trialData.trial ? trialData.trial == TrialTypes.QR : false
    //         commonActionData.partner = trialData.trialData?.enterpriseKey
    //     }

    //     if(commonActionData.enterprise == false) {
    //         delete commonActionData.enterprise
    //     }

    //     if(action.enterprise === null) {
    //         delete action.enterprise;
    //     }

    //     if(!action.name) {
    //         throw new Error("Tried to create an action without a name!");
    //     }

    //     if(!action.created) {
    //         action.created = new Date().toISOString();
    //     }

    //     const finalAction: ActionInterface = {...action, name: action.name, data: action.data};

    //     if(!finalAction.profileEmail) {

    //         finalAction.profileEmail = commonActionData.profileEmail;

    //     }

    //     if(!finalAction.created) {

    //         finalAction.created = commonActionData.created;

    //     }

    //     if(!finalAction.sessionId) {

    //         finalAction.sessionId = commonActionData.sessionId;

    //     }

    //     if(!finalAction.userType) {

    //         finalAction.userType = commonActionData.userType;

    //     }

    //     if(!finalAction.deviceInfo) {

    //         finalAction.deviceInfo = commonActionData.deviceInfo;

    //     }

    //     if(!finalAction.name) {
    //         finalAction.name = action.name;
    //     }

    //     const actionModel = new ActionModel(finalAction)

    //     return ActionApi.create(actionModel)
    // }







    // static async update(currentUser: SessionUser, action: ActionModel): Promise<ActionModel> {
    //     const data = await getApiCaller(currentUser).call('/v1/actions/' + action.getId(), 'POST', action.toObject());
    //     return new ActionModel(data);
    // }

    // static async delete(currentUser: SessionUser, id: string): Promise<void> {
    //     return await getApiCaller(currentUser).call('/v1/actions/' + id + '/delete', 'GET');
    // }

}

export default ActionApi;
