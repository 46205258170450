import { DrawerNavigationProp } from '@react-navigation/drawer';
import React, { memo } from 'react';
import { Animated, Easing, Image, Platform, StyleSheet, View } from 'react-native';
import Background from '../app/Background';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import Logo from '../components/Logo';
import { useAppTheme } from '../core/theme/ThemeBuilder';

type Props = {
  navigation: any;
};

const LoginSelectionScreen = ({ navigation }: Props) => {

  const _onRegisterPressed = () => {
    navigation.navigate('register')
  }


  const _onLoginPressed = async () => {
    //do login
    navigation.navigate('login')

  };

  const {colors} = useAppTheme()

  const logoYInitial = -60;
  const logoYFinal = 160;
  const logoY = new Animated.Value(logoYInitial);


  const logoOpacityAnim = logoY.interpolate({
    inputRange: [logoYInitial, logoYFinal],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  // const newspapersOpacityAnim = logoY.interpolate({
  //   inputRange: [logoYInitial, logoYFinal],
  //   outputRange: [0, 0.7],
  //   extrapolate: 'clamp'
  // })


  React.useLayoutEffect(() => {
    // ((navigation as any) as DrawerNavigationProp<{}>).closeDrawer();

    Animated.timing(logoY,
      {
        toValue: logoYFinal,
        duration: 1300,
        useNativeDriver: true,
        easing: Easing.out(Easing.quad)
      }).start();

  }, []);




  return (
    <Background safeInsets>
      <View
        style={{ flex: 1, flexDirection: "column", width: "100%", padding: 15 }}>


        <Animated.View style={[{ height: 50, alignItems: 'center' }, Platform.OS != 'web' ? {transform: [{ translateY: logoY }], opacity: logoOpacityAnim } : {transform: [{translateY: logoYFinal}]}]}>
          <Logo height={120}/>
        </Animated.View>
        {/*
        <Animated.View style={{ zIndex: -1000, position: 'absolute', flexDirection: "row", justifyContent: 'center', top: 30, left: '-60%', transform: [{ rotateX: '50deg' }, { rotateZ: '15deg' }], opacity: newspapersOpacityAnim }}>
          <Image source={require('../assets/designs/newspapers_transparent_shadow.png')} style={{ maxHeight: 850, resizeMode: 'contain' }}></Image>
        </Animated.View> */}


        {/* <View style={{ flexDirection: "column", flex: 1, justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 40, paddingHorizontal: Platform.OS == 'web' ? "20%": null}}>
          <ButtonNty  onPress={_onRegisterPressed}  mergeStyles={[{marginBottom: 30}, Platform.OS == 'web' ? {maxWidth: 350} : null]}>Registrarme</ButtonNty>
          <ButtonNty color={colors.secondary[700]} mergeStyles={[{marginBottom: 30}, Platform.OS == 'web' ? {maxWidth: 350} : null]} onPress={_onLoginPressed} >Ya tengo cuenta</ButtonNty>
        </View> */}
        <ButtonNty color={colors.secondary[700]} mode='floatingBottom' mergeStyles={[ Platform.OS == 'web' ? {maxWidth: 350, marginBottom: 30} : null]} onPress={_onLoginPressed} >Ya tengo cuenta</ButtonNty>

      </View>
    </Background>
  );
};

export default LoginSelectionScreen;
