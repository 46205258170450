import React from 'react';
import { Animated, ColorValue, Platform, StyleSheet, TextProps } from 'react-native';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';


export default function TitleNty(props: TextProps & {
    color?: ColorValue,
    type?: 'regular' | 'medium' | 'bold' | 'light' | 'thin' | 'black' | 'semiBold' | 'extraBold' | 'extraLight',
    fontSize?: number,
    numberOfLines?:number
 }) {

    const theme = useAppTheme()

    const fontType = props.type ?? 'bold';
    let fontSize = props.fontSize ?? (Platform.OS == 'web' ? 16 : 22)

    const style = StyleSheet.create({
        title: {
            fontSize: fontSize ,
            lineHeight: fontSize + 3,
            color: props.color ?? theme.colors.text_primary[500]
        }
    })
    return (
        <Animated.Text {...props} style={[style.title,theme.fonts.default[fontType], props.style]}>{props.children}</Animated.Text>
    )
}
