import React from 'react'
import { View } from 'react-native'
import { MailRecipient } from 'systemDomain'
import TextNty from '../../nty/text/TextNty'
import TitleNty from '../../nty/title/TitleNty'
import { useAppTheme } from 'src/core/theme/ThemeBuilder'

function ContactCard(props: { contact: MailRecipient }) {

    const theme = useAppTheme()

    const title = typeof props.contact == 'string' ? props.contact : props.contact.name
    const subtitle = typeof props.contact == 'string' ? null : props.contact.email

    return (
        <View style={{ paddingVertical: 5, paddingHorizontal: 10 }}>
            {/* <TextNty fontSize={18} type='thin' color={theme.colors.text_primary[300]} style={{ marginRight: 10 }}>Contacto</TextNty> */}
            <View style={{ flex: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
                {title && <TitleNty adjustsFontSizeToFit fontSize={18} style={{marginBottom: 5}}>{title}</TitleNty>}
                {subtitle && <TextNty adjustsFontSizeToFit fontSize={15} type='thin' color={theme.colors.text_primary[300]}>{subtitle}</TextNty>}
            </View>

        </View>
    )
}

export default ContactCard
