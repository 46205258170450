
import { Article, HomeDefinition } from "systemDomain";

import useSessionStore from "src/context/sessionStore";
import { buildApiUrl, http_request } from "src/core/http-utils";


class HomeApi {

    constructor() { }


    static async getHome(): Promise<HomeDefinition> {
        try {
            const URL = buildApiUrl('/home/user/' + useSessionStore.getState().profile.email, true)
            const data = await http_request<HomeDefinition>(URL, 'GET')
            return data
        } catch (e) {
            console.error(e)
            return { welcomeMessage: 'Something went wrong', logo: null, sections: [] }
        }

    }

    static async sectionQuery(sectionQuery: string, offset: number = 0, limit: number = 10): Promise<Article[]> {
        try {
            const URL = buildApiUrl('/home/section/query', true)
            const data = http_request<Article[]>(URL, 'POST', {query: sectionQuery, skip: offset, limit: limit})
            return data
        } catch (e) {
            console.warn(e)
            return []
        }

    }


}

export default HomeApi;
