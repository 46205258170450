import { useNavigation } from '@react-navigation/native'
import React from 'react'
import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'
import ArticleSuperUserOptionsList from '../../../components/article/options/ArticleSuperUserOptionsList'
import { useArticleSuperUserOptionsStore } from '../../optionStores'

function ArticleSuperUserOptionsModal() {

    const { modalVisible, setModalVisible } = useArticleSuperUserOptionsStore()
    const modalRef = React.useRef<NewsToYouSheetModalHandle>()

    const visibleRef = React.useRef(false)

    const hideModal = () => {

        visibleRef.current = false;
        modalRef.current.hide()
        setModalVisible(false)

    }
    const showModal = () => {

        visibleRef.current = true;
        modalRef.current.show()
        setModalVisible(true)

    }

    React.useEffect(() => {
        // console.log({visible, visibleRef: visibleRef.current})
        if (modalVisible == true && visibleRef.current == false) {
            console.log('showing')
            showModal()
        } else if(modalVisible == false && visibleRef.current == true){
            console.log('closing')
            hideModal()
        }
    }, [modalVisible])

    const navigation = useNavigation()

    return (
        <NtySheetModal ref={modalRef} onClose={() => hideModal() } modalHeight={550} >
            <ArticleSuperUserOptionsList hide={() => { hideModal() }} navigation={navigation} />
        </NtySheetModal>
    )
}

export default ArticleSuperUserOptionsModal
