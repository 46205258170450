import { Article } from "systemDomain";
import { AUDIO_FORMATS, VIDEO_FORMATS } from "../../../../../core/utils";

export const getMediaUrlExtension = (url: string, article: Article): "image" | "video" | "pdf" | "audio" | "online" | null => {
    if (article.category === 'online') {
        return 'online';
    }

    if (!url) {
        return 'image'; // Devuelve el valor predeterminado si la URL no está definida
    }

    const articleMediaExtension = url.substring(url.lastIndexOf('.') + 1, url.indexOf('?') === -1 ? url.length : url.indexOf('?'));

    if (AUDIO_FORMATS.includes(articleMediaExtension)) {
        return 'audio';
    }

    if (VIDEO_FORMATS.includes(articleMediaExtension)) {
        return 'video';
    }

    if (articleMediaExtension === 'pdf') {
        return 'pdf';
    }

    return 'image'; // Devuelve 'image' como valor predeterminado si no se cumplen las condiciones anteriores
}
