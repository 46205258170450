import React from 'react'
import { useWindowDimensions } from 'react-native'

function useAspectRatio() {

    const dimensions = useWindowDimensions()
    const aspectRatio = React.useMemo(() => dimensions.width / dimensions.height, [dimensions.width, dimensions.height])
    const screenOrientation: 'horizontal' | 'vertical' = aspectRatio > 1 ? 'horizontal' : 'vertical'

    return {aspectRatio, screenOrientation}

}

export default useAspectRatio
