import React, { MutableRefObject } from 'react'
import { ColorValue, Image, ImageSourcePropType, Platform, RefreshControl, StyleSheet, useWindowDimensions, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Animated, { Extrapolate, interpolateNode, useValue } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { hexTransparency } from '../../../core/jsutils'
import { fixed_colors } from '../../../core/theme/colors'
import Background from '../../Background'
import TextNty from '../nty/text/TextNty'

export type ScrollHeaderScreenProps = {
  header: JSX.Element,
  children: JSX.Element | JSX.Element[],
  headerHeight?: number,
  onRefresh?: () => void,
  snapToInterval?: number | Animated.Node<number>,
  decelerationRate?: number | "fast" | "normal" | Animated.Node<number | "fast" | "normal">,
  headerBgColor?: ColorValue, image?: ImageSourcePropType | string

}

function ScrollHeaderScreen(props: ScrollHeaderScreenProps & {scrollRef?: React.ForwardedRef<ScrollView>}) {

  const HEADER_HEIGHT = React.useMemo(() => props.headerHeight ?? (Platform.OS == 'web' ? 240 : 300), [props.headerHeight])
  const insets = useSafeAreaInsets()

  // const theme = useAppTheme()

  const bg = props.headerBgColor ?? hexTransparency(fixed_colors.black[400], 45)
  const scrollY = useValue(0)

  const translateY = interpolateNode(scrollY, {
    inputRange: [0, HEADER_HEIGHT + insets.top],
    outputRange: [0, -HEADER_HEIGHT + insets.top],
    extrapolate: Extrapolate.CLAMP,
  })


  const scale = interpolateNode(scrollY, {
    inputRange: [-HEADER_HEIGHT * 2, 0],
    outputRange: [3, 1],
    extrapolate: Extrapolate.CLAMP,
  })

  const opacity = interpolateNode(scrollY, {
    inputRange: [0, HEADER_HEIGHT],
    outputRange: [1, 0],
    extrapolate: Extrapolate.CLAMP,
  })





  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#000',
    },
    header: {
      height: HEADER_HEIGHT,
      width: "100%",
      paddingTop: insets.top
    },
    items: {
      paddingTop: HEADER_HEIGHT,
      // paddingHorizontal: 8,
    },
    image: {
      ...StyleSheet.absoluteFillObject,
      top: 0,
      // height: IMAGE_HEIGHT,
      width: '100%',
      height: HEADER_HEIGHT,
      resizeMode: 'cover'
    },
    item: {
      backgroundColor: '#1C1C1C',
      height: 50,
      width: '100%',
      marginTop: 8,
    }
  });

  const defaultRefreshControl = () =>
    <RefreshControl
      progressViewOffset={20}
      refreshing={false}
      onRefresh={props.onRefresh}
    />

  const dimensions = useWindowDimensions()



  return (

    Platform.OS == 'web' ?
      <Background>
        <ScrollView style={{ height: dimensions.height }}>
          <View style={[styles.header, { zIndex: 10 }]}>
            <View style={{
              ...StyleSheet.absoluteFillObject, backgroundColor: hexTransparency(fixed_colors.black[400], 30), zIndex: 9
            }} />

            <View style={{
              ...StyleSheet.absoluteFillObject,
              top: 0,
              // height: IMAGE_HEIGHT,
              width: '100%',
              height: HEADER_HEIGHT,
              backgroundColor: bg
            }} >
              <Image defaultSource={require('../../../assets/logos/logo_nty.png')} source={typeof props.image == 'string' ? { uri: props.image, cache: 'force-cache' } : props.image} style={{ resizeMode: 'cover', width: props.image ? "100%" : "50%", height: props.image ? "100%" : "50%", alignSelf: 'center' }} />

            </View>

            {props.header}
            {/* <TextNty>This is web</TextNty> */}
          </View>

          {props.children}
        </ScrollView>

      </Background> :
      <Background>
        <Animated.View style={[styles.header, { transform: [{ translateY: translateY }], zIndex: 10, position: 'absolute' }]}>
          <Animated.View style={{
            ...StyleSheet.absoluteFillObject, backgroundColor: bg, zIndex: 9, transform: [
              { scale: scale }
            ]
          }} />

          {props.image ? <Animated.View style={{
            ...StyleSheet.absoluteFillObject,
            // top: 0,
            height: HEADER_HEIGHT,
            alignContent: 'center',
            width: '100%',
            // height: HEADER_HEIGHT,
            backgroundColor: bg,
            opacity: opacity,
            transform: [
              { scale: scale }
            ]
          }} >

            <Image defaultSource={require('../../../assets/logos/logo_nty.png')} source={typeof props.image == 'string' ? { uri: props.image, cache: 'force-cache' } : props.image} style={{ resizeMode: 'cover', width: props.image ? "100%" : "50%", height: props.image ? "100%" : "50%", alignSelf: 'center' }} />

          </Animated.View>
            : null}



          <Animated.View style={{ zIndex: 100, flex: 1, opacity: opacity }}>

            {props.header}

          </Animated.View>

        </Animated.View>
        <Animated.ScrollView
          ref={props.scrollRef}
          snapToInterval={props.snapToInterval}
          decelerationRate={props.decelerationRate}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
            { useNativeDriver: true }
          )}
          scrollEventThrottle={16}
          refreshControl={props.onRefresh ? defaultRefreshControl() : null}>
          <View style={styles.items}>
            {props.children}
          </View>


        </Animated.ScrollView>
      </Background>
  )
}





export default React.forwardRef((props: ScrollHeaderScreenProps, ref: React.ForwardedRef<ScrollView>) => {

  return <ScrollHeaderScreen {...props} scrollRef={ref} />

})
