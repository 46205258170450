import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';
import { theme } from '../core/theme';
import { LinearGradient } from 'expo-linear-gradient';

type Props = React.ComponentProps<typeof PaperButton>;

const Button = ({ mode, style, children, ...props }: Props) => (
  <LinearGradient
    // Button Linear Gradient
    colors={theme.gradients.main}
    style={{margin: 0, flex: 1, marginVertical: 30, borderRadius:8}}
    start={[0,0]}
    end ={[1,0]}
    >
    <PaperButton
      style={[
        styles.button,
        mode === 'outlined' && { backgroundColor: theme.colors.surface },
        style,
      ]}
      labelStyle={styles.text}
      mode={mode}
      {...props}
    >
      {children}
    </PaperButton>
  </LinearGradient>
);

const styles = StyleSheet.create({
  button: {

  },
  text: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 26,
    color: "white",    
    flex: 1
  },
});

export default memo(Button);
