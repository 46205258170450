import produce from "immer";
import { create } from "zustand";

type SnackBarStoreData = {
    visibleInfoMessage: boolean,
    infoMessage: string,
    showInfoMessage: (message: string) => void,
    hideInfoMessage: () => void
}

export const useSnackBar = create<SnackBarStoreData>((set) => ({
    visibleInfoMessage: false,
    infoMessage: '',
    showInfoMessage: (message: string) => set(produce((draft:SnackBarStoreData) => {
        draft.visibleInfoMessage = true;
        draft.infoMessage = message;
    })),
    hideInfoMessage: () => set(produce((draft:SnackBarStoreData) => {
        draft.visibleInfoMessage = false;
    }))
}));
