import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { GestureResponderEvent, StyleSheet, View, ViewStyle } from 'react-native';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import TextNty from '../text/TextNty';
import TitleNty from '../title/TitleNty';
import { StyleProp } from 'react-native';
import { TextStyle } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';


export type NtySectionBarProps = {
    children: React.ReactNode | JSX.Element | JSX.Element[],
    key?: string,
    title: string,
    subtitle?: string,
    onViewSectionPress?: (key: string, title: string, event?: GestureResponderEvent) => void,
    styles?: {
        title?: StyleProp<TextStyle>,
        textContainer?: StyleProp<ViewStyle>,
        subtitle?: StyleProp<TextStyle>,
        container?: StyleProp<ViewStyle>
    }
}

const styles = StyleSheet.create({
    text: {
        fontSize: 27,
        lineHeight: 30

    },
    textContainer: {
        // paddingTop: 15,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});


function NtySectionBar(props: NtySectionBarProps) {


    const key = props.key ? props.key : (props.title + '_' + Date.now())

    const theme = useAppTheme()

    return (
        <View style={[{ backgroundColor: theme.colors.background[500], paddingVertical: 5, marginVertical: 15, /* ...theme.shadows.box.medium, shadowOffset: { height: -100, width: 0 }, shadowRadius: 5, shadowOpacity: 0.05 */ }, props.styles?.container]}>

            <TouchableWithoutFeedback style={[styles.textContainer, props.styles?.textContainer]}  onPress={props.onViewSectionPress ? () => props.onViewSectionPress(key, props.title) : null}>
                <View style={{flexDirection: 'column', justifyContent: 'center'}}>
                    <TitleNty type={'medium'} style={[styles.text, props.styles?.title]}>{props.title}</TitleNty>
                    {props.subtitle && <TextNty type={'thin'} style={[{ marginVertical: 8 }, props.styles?.subtitle]} fontSize={18}>{props.subtitle}</TextNty>}
                    {/* <TextNty type={'thin'} style={[{ marginVertical: 8 }, props.styles?.subtitle]} fontSize={18}>test</TextNty> */}
                </View>
                {props.onViewSectionPress &&
                    <MaterialCommunityIcons
                        name="arrow-right"
                        size={25}
                        style={{ alignContent: 'flex-end' }}
                        onPress={(ev) => props.onViewSectionPress(key, props.title, ev)}
                    />
                }
            </TouchableWithoutFeedback>



            {
                props.children &&
                <View >
                    {props.children}
                </View>
            }

        </View>
    )
}

export default NtySectionBar
