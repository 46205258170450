import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { Image, ImageStyle, ImageURISource, View, ViewStyle } from 'react-native';
import { getAppTheme } from 'src/core/theme/ThemeBuilder';

interface IProps {
  source: ImageURISource | number;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  style?: ImageStyle;
  loadingSource?: ImageURISource | number;
  loadingGifStyle?: ImageStyle;
  containerStyle?: ViewStyle;
}

const AutoImage: React.FC<IProps> = ({
  source,
  width,
  height,
  maxWidth,
  maxHeight, style, loadingSource: loadingGif, loadingGifStyle, containerStyle }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [dimensions, setDimensions] = useState({ width: width ?? 0, height: height ?? 0 });

  useEffect(() => {

    // @ts-ignore
    if ((typeof dimensions.height == "string" && typeof dimensions.width == "string") && (dimensions.width != 0 && dimensions.height != 0)) return

    if (source && typeof source !== 'number') {
      Image.getSize(source.uri, (w, h) => adjustDimensions(w, h), () => {
        console.error('Error retrieving image size');
      });
    } else if (typeof source === 'number') {
      const sourceInfo = Image.resolveAssetSource(source);
      adjustDimensions(sourceInfo.width, sourceInfo.height);
    }
  }, [source]);

  const adjustDimensions = (w: number, h: number) => {
    let finalWidth = w;
    let finalHeight = h;

    // code will only reach here if both dimensions and height are not strings

    if (width) {
      finalWidth = width;
      finalHeight = (width / w) * h;
    } else if (height) {
      finalHeight = height;
      finalWidth = (height / h) * w;
    }

    if (maxWidth && finalWidth > maxWidth) {
      finalWidth = maxWidth;
      finalHeight = (maxWidth / w) * h;
    }

    if (maxHeight && finalHeight > maxHeight) {
      finalHeight = maxHeight;
      finalWidth = (maxHeight / h) * w;
    }

    setDimensions({ width: finalWidth, height: finalHeight });
  };

  return (
    <View style={[{ width: dimensions.width != 0 ? dimensions.width : 100, height: dimensions.height != 0 ? dimensions.height : 100, alignItems: 'center', justifyContent: 'center' }, containerStyle]}>
      <View style={{ position: 'absolute' }}>
        {isLoading && loadingGif &&
          <Image source={loadingGif} style={loadingGifStyle ?? { width: 32, height: 32 }} />
        }
        {loadError &&
          // <MaterialCommunityIcons name='image-broken' size={45} color={getAppTheme().colors.text_primary[400]} />
          <Image source={require('src/assets/logos/logo_nty.png')} style={{width: 80, height: 80, resizeMode: 'cover'}} />
        }
      </View>
      <Image
        style={[
          { width: dimensions.width, height: dimensions.height },
          { opacity: isLoading ? 0 : 1 },
          style
        ]}
        source={source}
        onError={(error) => setLoadError(error.nativeEvent.error)}
        onLoadEnd={() => { setIsLoading(false) }}
      />
    </View>
  );
};

export default AutoImage;
