import React from 'react'
import { Platform, Pressable, View } from 'react-native'
import Logo from 'src/components/Logo'
import { useSearchStore } from '../../../../context/appSearchStore'
import useDebounce from '../../../../core/hooks/useDebounce'
import Loading from '../../loaders/Loading'
import NtySearchBar from '../../nty/searchbar/NtySearchBar'
import SearchFilters from './SearchFilters'

function SearchBar() {

    const loading = useSearchStore((store) => store.loading)

    const [text, setText] = React.useState('')

    const debouncedText = useDebounce(text, 700)

    React.useEffect(() => {
        if (debouncedText == '') return
        useSearchStore.getState().search({ searchText: debouncedText }, null, false)
    }, [debouncedText])


    // const onFilterPress = React.useCallback(() => {
    //     useSearchDateOptionsStore.getState().open().then(() => {
    //         console.log(useSearchStore.getState().currentSearch)
    //     })
    // }, [])

    const resetSearch = () => {

        useSearchStore.getState().reset()
        setText('')
        useSearchStore.getState().refresh()
    }



    return (
        <View style={{ flex: 1, zIndex: 10000, paddingHorizontal: Platform.OS == 'web' ? "5%" : 10 }}>

            <View style={{ marginBottom: 10 }} >
                {/* <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}> */}

                <Pressable style={{ alignItems: 'center', width: "100%", marginBottom: 10, marginTop: 5 }} onPress={resetSearch}>
                    <Logo height={45} />
                </Pressable>


                {/* <View style={{  flex: 1 }}> */}

                <NtySearchBar
                    onChangeText={setText}
                    value={text}
                    placeholder='Busca entre la actualidad'
                    clearIconPress={resetSearch}
                />
                {/* </View> */}
                {/* <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                        {
                            //@ts-expect-error
                            <IconButton icon={'filter'} size={27} color={fixed_colors.grey[600]} onPress={onFilterPress} style={{ backgroundColor: fixed_colors.white[200] }} />
                        }
                    </View> */}
                {/* </View> */}
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 5 }}>
                <SearchFilters />
            </View>
            {loading &&
                <View style={{ marginVertical: 10, zIndex: 1000, justifyContent: 'center', flex: 1 }}>
                    <Loading style={{ zIndex: 1100 }} />
                </View>
            }
        </View>
    )
}

export default SearchBar
