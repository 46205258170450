//!
import ApiCaller from '../lib/ApiCaller';
import { ProfileModel, ProfileModelCollection } from 'systemDomain';

class ProfileApiBase {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    async all(): Promise<ProfileModelCollection> {
        const data = await this.apiCaller.call('/v1/profile', 'GET');
        return new ProfileModelCollection(data.map((item:any) => {
            return new ProfileModel(item);
        }));
    }

    async read(id: string): Promise<ProfileModel> {
        const data = await this.apiCaller.call('/v1/profile/'+id, 'GET');
        return new ProfileModel(data);
    }

    async create(profile: ProfileModel): Promise<void>{
        return this.apiCaller.call('/v1/profile', 'POST', profile.toObject());
    }

    async update(id: string, profile: ProfileModel): Promise<ProfileModel>{
        const data = this.apiCaller.call('/v1/profile/'+id, 'POST', profile.toObject());
        return data;
    }

    async delete(id: string): Promise<void>{
        return this.apiCaller.call('/v1/profile/'+id+'/delete', 'POST', {});
    }
}

export default ProfileApiBase;