import { getLocalhost } from "src/../environments";
import { downloadFile } from "../core/http-utils";

export class PDFApi {

    static BASE_URL = getLocalhost + '/pdf-api/'


    static favouritelist_pdf(favlist_id: string) {

        let url = new URL(this.BASE_URL + 'favlist/' + favlist_id)
        return  downloadFile({ url, method: 'GET'})

    }
}
