import { MaterialCommunityIcons } from "@expo/vector-icons"
import moment from "moment"
import { TouchableOpacity, View, StyleSheet } from "react-native"
import { ScrollView } from "react-native-gesture-handler"
import { Chip } from "react-native-paper"
import ButtonNty from "src/app/components/nty/button/ButtonNty"
import TitleNty from "src/app/components/nty/title/TitleNty"
import { useSearchChipStyles } from "src/app/components/search/components/styles"
import { useDatePrompt } from "src/app/context/datePromptContext"
import { useSearchDateModal } from "src/app/context/searchModalsStores"
import { DateFilterType, defaultDateFilter, useSearchStore } from "src/context/appSearchStore"
import { formatDate } from "src/core/jsutils"
import { getAppTheme } from "src/core/theme/ThemeBuilder"



const theme = getAppTheme()

const styles = StyleSheet.create({
    labels: { color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold, fontSize: 20 }
})

type ListItemProps = {
    label: string,
    description?: string,
    icon?: string | { color: string, size: number, name: string },
    onPress?: () => void
}

const defaultIconSize = 35

function ListItem({ label, icon, onPress, description }: ListItemProps) {
    return (
        <TouchableOpacity style={{ paddingHorizontal: 20, paddingVertical: 20, flexDirection: 'row' }} onPress={onPress}>

            {icon && <View style={{ marginRight: 10, flex: 1, justifyContent: 'center' }}>
                {
                    typeof icon == 'string' ?
                        // @ts-expect-error
                        <MaterialCommunityIcons name={icon} size={defaultIconSize} color={theme.colors.text_primary[300]} />
                        :
                        // @ts-expect-error
                        <MaterialCommunityIcons name={icon.name} size={icon.size ?? defaultIconSize} color={icon.color ?? theme.colors.text_primary[300]} />
                }
            </View>}

            <View style={{ flex: 4, justifyContent: 'center' }}>
                <TitleNty fontSize={20} type='medium'>{label}</TitleNty>
                {description && <TitleNty fontSize={18} type='light' color={theme.colors.text_primary[400]}>{description}</TitleNty>}
            </View>

            <View style={{ flex: 2, justifyContent: 'center', alignItems: 'flex-end' }}>
                <MaterialCommunityIcons name={'chevron-right'} size={defaultIconSize} color={theme.colors.text_primary[300]} />
            </View>

        </TouchableOpacity>
    )
}



// const Scroll = Platform.OS === 'web' ? ScrollView : BottomSheetScrollView

function SearchDateFilters() {

    const { fromDate, toDate } = useSearchStore(store => store.currentSearch ?? {})

    const custom_date_filters = [
        {
            label: 'Última Hora',
            type: DateFilterType.LAST_HOUR,
            onPress: () => {

                const from = moment().subtract(1, 'hour').toISOString();
                const to = moment().milliseconds(0).toISOString()

                useSearchStore.getState().search({ fromDate: from, toDate: to })
                useSearchStore.getState().setDateFilterType(DateFilterType.LAST_HOUR)
                useSearchDateModal.getState().close()
            }
        },
        {
            label: 'Hoy',
            type: DateFilterType.TODAY,
            onPress: () => {

                // const from = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString();
                // const to = moment().seconds(0).milliseconds(0).toISOString()

                useSearchStore.getState().search(defaultDateFilter())
                useSearchStore.getState().setDateFilterType(DateFilterType.TODAY)
                useSearchDateModal.getState().close()
            }
        },
        {
            label: 'Ayer',
            type: DateFilterType.YESTERDAY,
            onPress: () => {

                const yesterday = moment().subtract(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0)
                const yesterday_final = moment().subtract(1, 'days').hours(23).minutes(59).seconds(59).milliseconds(999)

                useSearchStore.getState().search({ fromDate: yesterday.toISOString(), toDate: yesterday_final.toISOString() })
                useSearchStore.getState().setDateFilterType(DateFilterType.YESTERDAY)
                useSearchDateModal.getState().close()
            }
        },
        {
            label: 'Última semana',
            type: DateFilterType.LAST_WEEK,
            onPress: () => {

                const weekAgo = moment().subtract(7, 'days').hours(0).minutes(0).seconds(0).milliseconds(0)
                const today = moment()

                useSearchStore.getState().search({ fromDate: weekAgo.toISOString(), toDate: today.toISOString() })
                useSearchStore.getState().setDateFilterType(DateFilterType.LAST_WEEK)
                useSearchDateModal.getState().close()
            }
        },
    ]

    const styles = useSearchChipStyles()

    return (
        <>


            <ScrollView horizontal={true} contentContainerStyle={{ flexDirection: 'row', paddingHorizontal: 20, paddingVertical: 10 }} >

                {custom_date_filters.map(({ label, onPress }) => <Chip
                    mode='outlined'
                    style={{ ...styles.selectedChip }}
                    textStyle={{ ...styles.selectedChipText, fontSize: 16 }}
                    onPress={onPress}
                >{label}</Chip>)}

            </ScrollView>




            <ListItem
                label={'Desde'}
                description={formatDate(fromDate)}
                icon={'calendar-arrow-right'}
                onPress={() => {
                    useDatePrompt.getState().promptDate({ title: 'F. Desde' }).then((date) => {
                        date.setHours(0, 0, 0)
                        console.log('transformed date', date.toISOString())
                        useSearchStore.getState().updateSearch({ fromDate: date.toISOString() })
                        useSearchStore.getState().setDateFilterType(DateFilterType.CUSTOM)
                    })
                }} />
            <ListItem
                label={'Hasta'}
                description={formatDate(toDate)}
                icon={'calendar-arrow-left'}
                onPress={() => {
                    useDatePrompt.getState().promptDate({ title: 'F. Hasta' }).then((date) => {
                        date.setHours(23, 59, 59)
                        console.log('transformed date', date.toISOString())
                        useSearchStore.getState().updateSearch({ toDate: date.toISOString() })
                        useSearchStore.getState().setDateFilterType(DateFilterType.CUSTOM)
                    })
                }}
            />

            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 10 }}>

                {/* <TitleNty fontSize={25} type='medium' style={{ marginLeft: 25 }}>Filtros de Fecha</TitleNty> */}
                <ButtonNty
                    mergeStyles={{ marginRight: 25 }}
                    labelMergeStyles={{ fontSize: 14, paddingVertical: 2 }}
                    hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                    onPress={() => {
                        useSearchStore.getState().refresh()
                        useSearchDateModal.getState().close()
                    }}
                >Buscar</ButtonNty>
            </View>



        </>
    )
}


export default SearchDateFilters
