import { Platform } from 'react-native';
import { http_request } from 'src/core/http-utils';

class ConfigApi {

    static getAppInfoFromServer():Promise<AppConfig>  {
        const platform = Platform.OS
        return http_request('/v1/appVersion_'+platform, 'GET');
    }
}

export default ConfigApi;





interface Splash {
    image: string;
    resizeMode: string;
    backgroundColor: string;
}

interface Config {
    organization: string;
    project: string;
    authToken: string;
}

interface PostPublish {
    file: string;
    config: Config;
}

interface Hooks {
    postPublish: PostPublish[];
}

interface Updates {
    fallbackToCacheTimeout: number;
    enabled: boolean;
}

interface Ios {
    supportsTablet: boolean;
    bundleIdentifier: string;
    buildNumber: string;
}

interface AdaptiveIcon {
    foregroundImage: string;
    backgroundColor: string;
}

interface Android {
    adaptiveIcon: AdaptiveIcon;
    package: string;
    versionCode: number;
}

interface Web {
    favicon: string;
}

interface Expo {
    name: string;
    slug: string;
    scheme: string;
    version: string;
    orientation: string;
    icon: string;
    splash: Splash;
    hooks: Hooks;
    updates: Updates;
    assetBundlePatterns: string[];
    ios: Ios;
    android: Android;
    web: Web;
    plugins: any[];
}

export interface AppConfig {
    expo: Expo;
}



