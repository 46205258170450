import { MemoExoticComponent } from "react";
import AddHighlight from "../../../app/pages/AddHighlight";
import ContactInfoScreen from "../../../app/pages/ContactInfoScreen";
import PlansScreen from "../../../app/pages/PlansScreen";
import PremiumErrorScreen from "../../../app/pages/PremiumErrorScreen";
import PremiumScreen from "../../../app/pages/PremiumScreen";
import SearchEnterpriseFeed from "../../../app/pages/SearchEnterpriseFeed";
import SearchKey from "../../../app/pages/SearchKey";
import ConfigurationPage from "../../../app/pages/configuration/ConfigurationPage";
import FavouritesListPage from "../../../app/pages/favourites/FavouriteListPage";
import NewsPage from "../../../app/pages/news/NewsPage";
import ProfileInfoPage from "../../../app/pages/profileinfo/ProfileInfoPage";
import UsageStatsPage from "../../../app/pages/stats/UsageStatsPage";
import TestingPage from "../../../app/pages/testing/testingPage";
import CreateFromPage from "../../../pages/highlights/CreateFromPage";

import { ParamListBase, PathConfig, RouteConfig, StackNavigationState } from "@react-navigation/native";
import { StackNavigationEventMap, StackNavigationOptions } from "@react-navigation/stack";
import SuperUserCreateArticlePage from "src/app/pages/superuser/article/pages/SuperUserCreateArticlePage";
import RegisterEnterprise from "src/pages/RegisterEnterprise";
import { Article, Section } from "systemDomain";
import BottomSheetTextStyles from "../../../app/components/BottomSheetTextStyles";
import ScrollHeaderScreen from "../../../app/components/common-ui/ScrollHeaderScreen";
import NewSourceRequest from "../../../app/components/modals/new-source/NewSourceRequest";
import ArticlesSectionPage from "../../../app/components/new-home/pages/sections/ArticlesSectionPage";
import TitleHeaderNty from "../../../app/components/nty/header/TitleHeaderNty";
import SearchEnterpriseFeedInterest from "../../../app/pages/SearchEnterpriseFeedInterest";
import ArticleFeedsPage from "../../../app/pages/article/ArticleFeedsPage";
import ArticlePage from "../../../app/pages/article/ArticlePage/ArticlePage";
import SuperUserPage from "../../../app/pages/superuser/SuperUserPage";
import SendNewsletterFromFavouriteList from "src/app/components/newsletters/pages/SendNewsletterFromFavouriteList";
import FavlistNewsletterHistoryPage from "src/app/components/newsletters/pages/FavlistNewsletterHistory";





export type AppScreen = Partial<RouteConfig<ScreensParamsList, keyof ScreensParamsList, StackNavigationState<ParamListBase>, StackNavigationOptions, StackNavigationEventMap>> & {
    /** The component to be rendered */
    component: ((props?: any) => JSX.Element) | JSX.Element | MemoExoticComponent<any>,
    /** Identifying name for this screen, for example: 'contact-screen'. __Sould not be shared between screens__. Data identification and filtering purpouses only */
    name: string,
    /** Display Name for this screen. Could be displayed as the title of the Screen if needed, visual purpouses only. */
    title?: string,
    /**
     * Slash-separated string representing the path of the route or a Path Object containing information about the param names and its corresponding type
     */
    route?: string | Omit<PathConfig<{}>, "screens" | "initialRouteName">
    // /**
    //  * Flag to indicate that this screen is prepared to recieve a guest user, no user authentication is needed.
    //  * If `true` then this screen is always loaded in the Root Drawer navigation despite of the type of the user
    //  *
    //  */
    // guestEnabled?: boolean

}


const screens: AppScreen[] = [

    // {
    //     component: MainBottomTabsNavigator,
    //     name: "home"
    // },
    {
        component: RegisterEnterprise,
        name: "registerEnterprise",
        route: { path: "register-enterprise", parse: { email: String, enterprise: String } }

    },
    {
        component: ArticlePage,
        name: "viewArticle",
        route: { path: "article/:id", parse: { id: String, keywords: String, article: String } }
    },
    {
        component: ArticlePage,
        name: "viewEnterpriseArticle",
        route: { path: "enterprise/article/:id", parse: { id: String, keywords: String, article: String, enterpriseKey: String } }
    },
    {
        component: PremiumScreen,
        name: "premium"
    },
    {
        component: PlansScreen,
        name: "plans"
    },
    {
        component: ContactInfoScreen,
        name: "contactinfo"
    },
    // {
    //     component: SubscriptionsManagement,
    //     name: "subscriptionsmanagement",
    //     options: {
    //         presentation: 'modal'
    //     }
    // },
    // {
    //     component: PaymentScreen,
    //     name: "payment"
    //     //routes: ["home"]
    // },
    // {
    //     component: PaymentPortalScreen,
    //     name: "manage",
    //     route: { path: 'manage/subscription' },
    //     //routes: ["home"]
    // },
    {
        component: PremiumErrorScreen,
        name: 'cancel'
    },
    // {
    //     component: ManageNewspapersPage,
    //     route: { path: 'manage/newspapers', parse: { goTo: String, type: String } },
    //     name: 'ManageNewspapersPage',
    //     options: {
    //         presentation: 'modal'
    //     }


    // },
    // {
    //     component: ManageAuthorsPage,
    //     route: { path: 'manage/authors', parse: { goTo: String, type: String } },
    //     name: 'ManageAuthorsPage',
    //     options: {
    //         presentation: 'modal'
    //     }
    // },
    {
        component: UsageStatsPage,
        name: 'usagestats'
    },
    {
        component: ProfileInfoPage,
        name: 'profileinfodev'
    },
    {
        component: TestingPage,
        name: "testScreen"
    },
    // {
    //     component: ListPartners,
    //     name: "listPartners"
    // },
    // {
    //     component: ViewPartner,
    //     name: "viewPartner"
    // },
    // {
    //     component: RulePage,
    //     name: "rules"
    // },
    {
        component: CreateFromPage,
        name: 'createHighlightFrom',
        route: { path: "highlight/create-from/:id", parse: { id: String } }
    },
    {
        component: SearchKey,
        name: "searchKeyword",
        route: { path: "search/keyword/:keyword", parse: { keyword: String } }
    },
    {
        component: SearchEnterpriseFeed,
        name: "searchEnterpriseFeed",
        route: { path: "search/enterprise/feed/:id", parse: { id: String } }
    },
    {
        component: AddHighlight,
        name: 'addHighlight'
    },
    {
        component: ConfigurationPage,
        name: 'configuration'
    },
    {
        component: NewsPage,
        name: 'news'
    },
    {
        component: FavouritesListPage,
        name: 'viewFavouriteList',
        route: { path: "favourites/list/:id", parse: { id: String } }
    },
    {
        component: NewSourceRequest,
        name: 'NewSourceRequest',
        options: {
            presentation: 'modal',
            headerShown: true,
            headerTransparent: true,
            header: (props) => <TitleHeaderNty title={null} onBackIconPressed={() => props.navigation.goBack()} />
        },
        route: { path: "request/new-source", parse: { from: String } }
    },
    {
        component: BottomSheetTextStyles,
        name: 'textStyles',
        options: {
            presentation: 'modal',
            headerShown: true,
            header: (props) => <TitleHeaderNty title={'Estilos de Lectura'} onBackIconPressed={() => props.navigation.goBack()} />
        }
    },
    {
        component: ArticleFeedsPage,
        name: 'editArticleFeed',
        options: {
            presentation: 'modal',
            headerShown: true,
            header: (props) => <TitleHeaderNty title={'Añadir a Feed'} onBackIconPressed={() => props.navigation.goBack()} />
        }
    },
    {
        component: ScrollHeaderScreen,
        name: 'test',
        route: { path: "test" }
    },
    {
        component: ArticlesSectionPage,
        name: 'viewArticlesSection'
    },
    {
        component: SearchEnterpriseFeedInterest,
        name: 'viewInterestArticles',
        route: { path: "search/enterprise/feed/:feed_id/interest/:interest_key", parse: { feed_id: String, interest_key: String } }
    },
    {
        component: SuperUserPage,
        name: 'superUserPage',
        route: { path: "super-user-options" }
    },
    {
        component: SuperUserCreateArticlePage,
        name: 'superUserCreateArticle',
        route: { path: "super-user-options/article/create" }
    },
    {
        component: SendNewsletterFromFavouriteList,
        name: 'sendNewsletter',
        route: { path: "newsletters/favourite_list/:id/send", parse: { id: String } },
        options: {
            presentation: 'modal',
            headerShown: true,
            header: (props) => <TitleHeaderNty title={'Enviar Newsletter'} onBackIconPressed={() => props.navigation.canGoBack() ? props.navigation.goBack() : props.navigation.navigate('home')} />
        }
    },
    {
        component: FavlistNewsletterHistoryPage,
        name: 'favlistNewsletterDispatch',
        route: { path: "newsletters/favourite_list/:id/dispatch", parse: { id: String } },
        options: {
            presentation: 'modal',
            headerShown: true,
            header: (props) => <TitleHeaderNty  title={'Historial de Envíos'} onBackIconPressed={() => props.navigation.canGoBack() ? props.navigation.goBack() : props.navigation.navigate('home')} />
        }
    }
    // ,
    // {
    //     component: Loading,
    //     name: 'Loading',
    //     route: {path: 'loading', parse: {message: String}},
    //     options: {
    //         presentation: 'transparentModal'
    //     }
    // }
    // {
    //     component: LoginSelectionScreen,
    //     name: 'guest-welcome',
    //     guestEnabled: true
    // },
    // {
    //     component: LoginScreen,
    //     name: 'login',
    //     guestEnabled: true
    // },
    // {
    //     component: RegisterScreen,
    //     name: 'register',
    //     guestEnabled: true
    // },
    // {
    //     component: ForgotPasswordScreen,
    //     name: 'forgotpassword',
    //     guestEnabled: true
    // },
    // {
    //     component: ChangePasswordScreen,
    //     name: 'changepassword',
    //     guestEnabled: true
    // }

];


export type idParam<T = string> = {
    id: T
}

export type ScreensParamsList = {
    nty: undefined,
    home: undefined
    subscriptions: undefined
    viewArticle: idParam & { article?: Article, keywords?: string[] }
    viewEnterpriseArticle: idParam & { article?: Article, keywords?: string[], enterpriseKey?: String },
    viewArticleScreenshot: idParam & { article?: Article },
    premium: undefined
    plans: undefined
    contactinfo: undefined
    // subscriptionsmanagement: { following: 'authors' | 'newspapers' }
    payment: { planId: String }
    manage: undefined
    cancel: undefined
    // ManageNewspapersPage: { goTo?: string, type?: 'reset' | 'navigate' },
    // ManageAuthorsPage: { goTo?: string, type?: 'reset' | 'navigate' },
    usagestats: undefined
    profileinfodev: undefined
    testScreen: undefined
    testHomeScreenMobile: undefined
    listPartners: undefined
    viewPartner: undefined
    // rules: undefined
    highlights: undefined
    createHighlightFrom: idParam
    searchKeyword: { keyword: string }
    searchEnterpriseFeed: idParam
    addHighlight: undefined
    configuration: undefined
    news: undefined
    viewFavouriteList: idParam,
    NotFound: undefined,
    changepassword: undefined,
    forgotpassword: undefined,
    login: undefined,
    logout: { goTo?: string },
    register: { email: string },
    registerEnterprise: { email: string, enterprise: string },
    "guest-welcome": undefined,
    NewSourceRequest: { from: string },
    Loading: { message: String },
    test: undefined,
    textStyles: undefined,
    viewArticlesSection: { section: Section },
    editArticleFeed: { article_id: string },
    viewInterestArticles: {
        feed_id?: string,
        interest_key?: string
    },
    superUserPage: undefined,
    superUserCreateArticle: undefined,
    sendNewsletter: idParam,
    favlistNewsletterDispatch: idParam


}


export const screens_definition = screens.map((screen) => (
    {
        ...screen,
        route: screen.route ?? screen.name,
        title: screen.title ?? screen.name
    }))






// const toScreensObject = (screens: AppScreen[]) => {
//     return {
//         screens: screens.map((screen: AppScreen) => {
//             return {
//                 ...screen,
//                 route: screen.route ? screen.route : screen.name,
//                 title: screen.title ?? screen.name
//             }
//         }),
//         getScreen: (screenName: string) => screens.find((screen: AppScreen) => screen.name == screenName)
//     }
// }







