import React from 'react'
import { View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
// import { theme } from '../../../core/theme'
import Background from '../../Background'
import ListItem, { ListItemProps } from '../../components/lists/ListItem'
import TitleHeaderNty from '../../components/nty/header/TitleHeaderNty'

function ConfigurationPage() {

    // const theme = useAppTheme()

    // const [search, setSearch] = React.useState();

    // const navigation = useNavigation()

    // const forceTheme = useAppSessionStore.getState().forceTheme

    // const { alert, hide } = useAlert(store => ({ alert: store.alert, hide: store.hide }))


    const configItems: ListItemProps[] = [
        // {
        //     label: 'Temas',
        //     description: 'Modifica la paleta de colores de News To You',
        //     icon: 'palette',
        //     onPress: (label: string) => {
        //         alert({
        //             title: 'Cambia el tema',
        //             message:
        //                 <ScrollView style={{ width: "100%", flexDirection: 'column' }}>

        //                     {Object.keys(themes).map((theme) => {
        //                         return (
        //                             // <ButtonNty key={theme} onPress={() => setEnterprise(theme)} color={getAppTheme(theme).colors.primary[500]}>{theme}</ButtonNty>
        //                             <TouchableHighlight key={theme} onPress={() => { forceTheme(themes[theme]); hide() }} style={{ padding: 8, marginBottom: 10, backgroundColor: fixed_colors.white[700], borderRadius: 10 }}>
        //                                 <View nativeID='container' style={{ flex: 1 }}>

        //                                     <View nativeID='header' style={{ paddingVertical: 2 }}>
        //                                         <TitleNty color={fixed_colors.black[500]}>{theme}</TitleNty>
        //                                     </View>
        //                                     <View nativeID='content' style={{ flexDirection: 'row', justifyContent: 'space-evenly', flex: 2 }}>
        //                                         <View nativeID='square' style={{ backgroundColor: fixed_colors.white[600], height: 60, width: 60, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 15 }}>

        //                                             <View nativeID='circle' style={{ backgroundColor: getAppTheme(theme).colors.primary[500], width: 40, height: 40, borderRadius: 20 }} />

        //                                         </View>
        //                                         <View nativeID='square' style={{ backgroundColor: fixed_colors.white[600], height: 60, width: 60, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 15 }}>

        //                                             <View nativeID='circle' style={{ backgroundColor: getAppTheme(theme).colors.background[500], width: 40, height: 40, borderRadius: 20 }} />

        //                                         </View>
        //                                         <View nativeID='square' style={{ backgroundColor: fixed_colors.white[600], height: 60, width: 60, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 15 }}>

        //                                             <View nativeID='circle' style={{ backgroundColor: getAppTheme(theme).colors.secondary[500], width: 40, height: 40, borderRadius: 20 }} />

        //                                         </View>
        //                                     </View>
        //                                 </View>
        //                             </TouchableHighlight>
        //                         )
        //                     })}
        //                 </ScrollView>

        //         })
        //     }
        // },
        // {
        //     label: 'Información',
        //     description: 'Consulta la información legal, términos y condiciones acerca de nuestro servicio.',
        //     icon: 'scale-balance',
        //     onPress: null
        // }
    ]

    return (
        <>
            <Background safeInsets mobileConstraint>


                <TitleHeaderNty title='Configuración' />


                <FlatList
                    data={configItems}
                    renderItem={({ item }) => <>
                        <ListItem {...item} />
                        {/* <Divider /> */}
                    </>
                    }
                    ItemSeparatorComponent={() => <View style={{ height: 20 }}></View>}
                />

            </Background>
        </>
    )
}

export default ConfigurationPage
