import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { Platform } from "react-native";
import { NewspaperCollection, NewspaperModel } from "systemDomain";
import { StoreApi, create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import ConfigParamApi from "../api/ConfigParamApi";
import NewspaperApi from "../api/NewspaperApi";
import Settings from "../core/settings";
// import { Image } from 'react-native'
import { ConfigParamModel } from "systemDomain";
import { cacheURLPictures, containsSame } from "../core/utils";
import { Notification } from "../models/Notification";


export type AppDataStoreType = {

    updated: Date,
    initialized: boolean,
    newspapers: NewspaperCollection,
    newspapersBySection: { [section: string]: NewspaperModel[] },
    notifications: Notification[],
    seenNotifications: Notification[],
    /**
     *
     * A map containing all the necessary configParams to be used in the app.
     *
     * **This field should not be used to get 'whatever' config param from database** as this store only contains the
     * config params informed in {@link RELEVANT_CONFIG_PARAMS}. You can consider adding more param names or groups in order to prefetch them at startup.
     *
     * If any config param could not be loaded for whatever reason (connectivity, 'active = false', no match...) then,
     * it will not exist in the dictionary.
     *
     */
    configParams: Map<string, ConfigParamModel[]>,
    configParamsLoaded: boolean,
    // orderedSections: string[],
    init: () => Promise<void>,
    /**
     * Method that fetches data thats not required to start the application: Notifications, pictures caché
     */
    loadSecondaryData: () => Promise<void>,
    /***
     * This method performs some operations in order to test if appDataStore data needs to be refreshed, and acts accordingly.
     *
     * If the store was updated more than {@link Settings.appDataDeprecationInterval} in hour units, required data will be re-fetched.
     *
     * If not, only secondary data will be re-fetched.
     *
     */
    checkState: () => Promise<void>,
    setInitialized: (to: boolean) => void,
    siteTranslator: (keyName: string) => string,
    getNewspapersByKeyName: (keyNames: string[]) => NewspaperModel[],
    seenNotification: (notification: Notification) => void,
    clearSeenNotifications: () => void

}

/**
 * A list of necessary params from database.
 *
 * Place a new param name or groupName in the corresponding field
 * in order to make it available in the {@link AppDataStoreType.configParams} field at startup.
 *
 */
const RELEVANT_CONFIG_PARAMS = {
    groups: [],
    names: ['newsBoard']
}



// const readAppVisibleNewspapers = async () => {

//     let newspapers = new NewspaperCollection([]);
//     try {
//         const data = await NewspaperApi.all();
//         newspapers = data;

//     } catch (err) {
//         console.error("Error while fetching app newspapers.");
//         console.error(err);
//     }
//     finally {
//         return newspapers;
//     }
// }


const preloadConfigParams: () => Promise<Map<string, ConfigParamModel[]>> = async () => {

    //Fetch groups:
    const groups: { name: string, items: any[] }[] = [];

    for (let groupName in RELEVANT_CONFIG_PARAMS.groups) {
        try {
            const result = await ConfigParamApi.getConfigParamsByGroupName(groupName);
            if (result && result.configParamArr) {
                groups.push({ name: groupName, items: result.configParamArr });
            }
        } catch (e) {
            console.warn('Error while loading config param groups');
        }
    }

    //Fetch names:
    const names: { name: string, item: any }[] = [];


    for (let name of RELEVANT_CONFIG_PARAMS.names) {
        try {
            // console.log('fetching ' + name)
            const result = await ConfigParamApi.getConfigParamByName(name);
            if (result) {
                // console.log('Got ' + name)
                names.push({ name: name, item: result });
            }
        } catch (e) {
            console.warn('Error while loading config param names');
        }
    }

    const dictionary = new Map<string, ConfigParamModel[]>();

    names.forEach((name) => {
        // console.log('Adding ' + name + ' to dictionary')
        const param = dictionary.get(name.name);
        if (param) {
            param.push(name.item)
            dictionary.set(name.name, param)
        } else {
            dictionary.set(name.name, [name.item])
        }
    })

    groups.forEach((group) => {
        const paramGroup = dictionary.get(group.name);
        if (paramGroup) {
            dictionary.set(group.name, [...paramGroup, ...group.items])
        } else {
            dictionary.set(group.name, group.items)
        }
    })

    return dictionary;

}


const getNotifications: () => Promise<Notification[]> = async () => {


    try {
        const params = await ConfigParamApi.getConfigParamsByGroupName('notifications');

        if (params && params.configParamArr) {
            return params.configParamArr.sort((n1, n2) => n1.props.order && n2.props.order ? n1.props.order - n2.props.order : 0)
            //@ts-expect-error
            .map<Notification>((param) => ({ ...param.props.data, _id: param.props._id }))
        }

    } catch (e) {
        return []
    }



}



const cachNewspaperLogos = async (newspapers: NewspaperModel[]) => {

    for await (let n of newspapers) {
        cacheURLPictures([n.props.logo?.square, n.props.logo?.rect.horizontal], false)
    }


}



const AppDataStore: (set: StoreApi<AppDataStoreType>['setState'], get: StoreApi<AppDataStoreType>['getState']) => AppDataStoreType =
    (set, get) => ({
        newspapers: null,
        newspapersBySection: null,
        // orderedSections: [],
        notifications: [],
        seenNotifications: [],
        configParams: new Map([]),
        configParamsLoaded: false,
        initialized: false,
        updated: null,
        setInitialized: (to: boolean) => {
            set({ initialized: to })
        },
        siteTranslator: (keyName: string) => {

            const items = get().newspapers.newspaperArr;
            const newspaper = items.find(n => n.props.keyName == keyName);
            return newspaper ? newspaper.props.name : keyName;

        },
        getNewspapersByKeyName: (keyNames: string[]) => {
            // console.log('######################################')
            // console.log('Searching for: ', keyNames)
            // console.log('######################################')
            const newspapers = keyNames.map((keyName) => {
                const items = get().newspapers.newspaperArr;
                let newspaper = items.find(n => n.props.keyName == keyName);
                if (!newspaper) {
                    new NewspaperModel({ created: new Date().toISOString(), active: true, keyName: keyName, name: keyName, logo: null, domain: keyName, description: '', section: '--', visible: true, management: false, score: 0, sectionScore: 0, _id: keyName + '_' + Date.now() })
                }
                return newspaper;
            });

            return newspapers;

        },
        clearSeenNotifications: () => {
            set({ seenNotifications: [] })
        },
        seenNotification: (notification: Notification) => {
            const current = get().seenNotifications;
            if (!current.find(n => n._id == notification._id))
                set({ seenNotifications: [notification, ...current] })
        },
        init: async () => {

            console.log('initializing appdataStore')

            try {
                // const visibleNewspapers = await readAppVisibleNewspapers();

                //Ordered sections from config
                // const param = await ConfigParamApi.getConfigParamByName(CONFIG_PARAM_NAMES.SECTIONS_ORDER);
                // const sectionsConfig: string[] = param && param.props && param.props.data && param.props.data.sectionsOrder ? param.props.data.sectionsOrder ?? [] : [];
                //Alphabetical ordered sections from distinct visibleNewspapers sections removing the ones from config
                // const sectionsNewspapers: string[] = [...new Set<string>(visibleNewspapers.newspaperArr.map(n => n.props.section))].filter(n => !sectionsConfig.includes(n)).sort()

                // const orderedSections = [...sectionsConfig, ...sectionsNewspapers];
                // const newspapersBySection = {};

                // orderedSections.forEach((section) => {
                //     newspapersBySection[section] = visibleNewspapers.newspaperArr.filter((n) => { return n.props.section == section })
                // })


                const paramsDictionary = await preloadConfigParams();
                console.log('Got ' + paramsDictionary.size + ' configParams')
                set({ updated: new Date(), initialized: true, configParams: paramsDictionary, configParamsLoaded: true })

                get().loadSecondaryData();



            } catch (e) {
                console.error(e);
                //Do nothing, hope theres a previous loaded instance of appData
            }

            return;

        },
        checkState: async () => {

            console.log('Checking appData state...')

            const state = get();

            const lastUpdated = moment(state.updated ?? new Date().toISOString());
            const now = moment()

            const duration = moment.duration(now.diff(lastUpdated));
            const hours = duration.asHours();

            if (__DEV__ || hours >= Settings.appDataDeprecationInterval) {
                // console.log('DataStore data was deprecated.')
                // state.setInitialized(false)
                await state.init()
                // console.log('DataStore initialized!!');
                // set({ initialized: true });

            } else {
                await state.loadSecondaryData()
            }
        },
        loadSecondaryData: async () => {

            console.log('Loading Secondary Data...')
            const notifications = await getNotifications();
            console.log('Got ' + notifications.length + ' notifications')
            // Fetch app relevant config params
            const old_notifications = get().notifications

            // console.log({notifications, old_notifications})

            if(!containsSame(notifications, old_notifications)){
                console.log('refreshing notifications')
                set({ notifications, updated: new Date() })
            }

            // if (Platform.OS != 'web') {
            //     console.log('Caching newspapers logos');
            //     cachNewspaperLogos(get().newspapers.newspaperArr)
            // }


        }
    });

let store;

// for testing purpouses
if (__DEV__ && Platform.OS == 'web') {
    store = devtools(AppDataStore);
} else {
    store = AppDataStore;
}

const avoidPersistingThisFields = [];

export const APP_DATA_STORE = 'appDataStore'

const persistedStore = persist(store, {
    name: APP_DATA_STORE,
    storage: createJSONStorage(() => AsyncStorage),
    partialize: (state: AppDataStoreType) => {

        const toBePersisted: Partial<AppDataStoreType> = {};
        const keys = Object.keys(state).filter(key => avoidPersistingThisFields.indexOf(key) == -1).filter(key => typeof state[key] !== 'function')

        for (let key of keys) {
            toBePersisted[key] = state[key]
        }

        // console.log('Filtered from storage: ', toBePersisted)
        return toBePersisted;

    },
    onRehydrateStorage: (state: AppDataStoreType | never) => {

        const onHydrationDone = (state: AppDataStoreType) => {

            if (state && state.updated && state.initialized) {

                state.checkState().then(() => console.log('done checking ' + APP_DATA_STORE + ' state'))

            } else {
                //theres no updated date
                //First time app has been launched, or appData has been wiped out
                state.init().then(() => {
                    console.log(APP_DATA_STORE + ' FIRST INITIALIZATION!!');
                })

            }

            // console.log('Hydrated Store!!', state);
            // state.init().then(() => {
            //     console.log('SessionStore initialized!!');
            // })
        }
        return onHydrationDone;

    }
});

export const useAppDataStore = create<AppDataStoreType>()(persistedStore);
