import { MaterialCommunityIcons } from "@expo/vector-icons"
import { Picker } from "@react-native-picker/picker"
import React from "react"
import { TouchableOpacity, View, StyleSheet, Platform } from "react-native"
import ButtonNty from "src/app/components/nty/button/ButtonNty"
import TitleNty from "src/app/components/nty/title/TitleNty"
import SearchOrder from "src/app/components/search/components/SearchOrder"
import { useSearchChipStyles } from "src/app/components/search/components/styles"
import { useDatePrompt } from "src/app/context/datePromptContext"
import { useSearchDateModal } from "src/app/context/searchModalsStores"
import { NtyPicker } from "src/components/common-ui/picker/NtyPicker"
import { useSearchStore } from "src/context/appSearchStore"
import { formatDate } from "src/core/jsutils"
import { getAppTheme } from "src/core/theme/ThemeBuilder"
import { ArticleSearchOrderBy, SortDirection } from "systemDomain"



// const theme = getAppTheme()

// const styles = StyleSheet.create({
//     labels: { color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold, fontSize: 20 }
// })

// type ListItemProps = {
//     label: string,
//     description?: string,
//     icon?: string | { color: string, size: number, name: string },
//     onPress?: () => void
// }

// const defaultIconSize = 35

// function ListItem({ label, icon, onPress, description }: ListItemProps) {
//     return (
//         <TouchableOpacity style={{ paddingHorizontal: 20, paddingVertical: 20, flexDirection: 'row' }} onPress={onPress}>

//             {icon && <View style={{ marginRight: 10, flex: 1, justifyContent: 'center' }}>
//                 {
//                     typeof icon == 'string' ?
//                         // @ts-expect-error
//                         <MaterialCommunityIcons name={icon} size={defaultIconSize} color={theme.colors.text_primary[300]} />
//                         :
//                         // @ts-expect-error
//                         <MaterialCommunityIcons name={icon.name} size={icon.size ?? defaultIconSize} color={icon.color ?? theme.colors.text_primary[300]} />
//                 }
//             </View>}

//             <View style={{ flex: 4, justifyContent: 'center' }}>
//                 <TitleNty fontSize={20} type='medium'>{label}</TitleNty>
//                 {description && <TitleNty fontSize={18} type='light' color={theme.colors.text_primary[400]}>{description}</TitleNty>}
//             </View>

//             <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
//                 <MaterialCommunityIcons name={'chevron-right'} size={defaultIconSize} color={theme.colors.text_primary[300]} />
//             </View>

//         </TouchableOpacity>
//     )
// }



// const Scroll = Platform.OS === 'web' ? ScrollView : BottomSheetScrollView

function SearchSortOptions() {




    return (
        <>
            <SearchOrderSelector />
        </>
    )
}

export function useSearchOptions() {
    const { sort, searchText } = useSearchStore(state => ({ sort: state.currentOptions.sort, searchText: state.currentSearch?.searchText }))
    const selectedOrder = sort?.orderBy + '_' + sort?.direction

    const possibleOrders = React.useMemo(() => {

        const orders = [
            {
                key: ArticleSearchOrderBy.DATE + '_' + SortDirection.DESC,
                orderBy: ArticleSearchOrderBy.DATE,
                direction: SortDirection.DESC,
                icon: 'clock',
                label: 'Más recientes'
            },
            {
                key: ArticleSearchOrderBy.DATE + '_' + SortDirection.ASC,
                orderBy: ArticleSearchOrderBy.DATE,
                direction: SortDirection.ASC,
                icon: 'clock',
                label: 'Más antiguos'
            },
            {
                key: ArticleSearchOrderBy.ECONOMICAL_VALUE + '_' + SortDirection.DESC,
                orderBy: ArticleSearchOrderBy.ECONOMICAL_VALUE,
                direction: SortDirection.DESC,
                icon: 'cash',
                label: 'Mayor valor económico'
            },
            {
                key: ArticleSearchOrderBy.AUDIENCE + '_' + SortDirection.DESC,
                orderBy: ArticleSearchOrderBy.AUDIENCE,
                direction: SortDirection.DESC,
                icon: 'account-group',
                label: 'Mayor audiencia'
            }
        ]

        if (searchText && searchText.length > 0) {
            orders.unshift({
                key: ArticleSearchOrderBy.RELEVANCE + '_' + SortDirection.DESC,
                orderBy: ArticleSearchOrderBy.RELEVANCE,
                direction: SortDirection.DESC,
                icon: 'star',
                label: 'Mejores resultados',
            })
        }

        return orders
    }, [searchText])

    return { selectedOrder, possibleOrders }
}

export function SearchOrderSelector() {

    const { selectedOrder, possibleOrders } = useSearchOptions()


    return <NtyPicker
            pickerTitle={Platform.OS == 'web' ? 'Ordenar por:' : null}
            titleStyle={{ fontSize: 15 }}
            selectedValue={selectedOrder}
            onValueChange={(value, index) => {
                const order = possibleOrders.find(order => order.key == value)
                useSearchStore.getState().search(null, { sort: { orderBy: order.orderBy, direction: order.direction } })
            }}
            pickerStyle={{ paddingVertical: 2, paddingHorizontal: 5, fontSize: 14 }}
            containerStyle={{ marginTop: Platform.OS == 'web' ? 0 : -20, borderColor: 'transparent', borderBottomWidth: 0 }}
        >
            {possibleOrders.map(order => (
                <Picker.Item label={order.label} value={order.key} key={order.key} />
            ))}
        </NtyPicker>


}


export default SearchSortOptions
