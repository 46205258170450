import { StackScreenProps } from '@react-navigation/stack';
// Analytics
// import * as Analytics from 'expo-firebase-analytics';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { memo, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Modal, Platform, StatusBar, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ImageViewer from 'react-native-image-zoom-viewer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TextNty from 'src/app/components/nty/text/TextNty';
import AutoImage from 'src/components/AutoImage';
import { fixed_colors } from 'src/core/theme/colors';
import { Article } from "systemDomain";
import ArticleApi from '../../../../api/ArticleApi';
import { ScreensParamsList } from '../../../../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import { openInBrowser } from '../../../../core/utils';
import ArticleBottomOptions from '../../../components/ArticleBottomOptions';
import ArticleWebView from '../../../components/ArticleWebView';
import ArticleContent from '../../../components/article/detail/ArticleContent';
import ArticleHeading from '../../../components/article/detail/ArticleHeading';
import VirtualScrollHeaderScreen from '../../../components/common-ui/VirtualScrollHeaderScreen';
import { useArticleOptionsStore } from '../../../context/optionStores';
import { styles } from './styles';

type Props = StackScreenProps<ScreensParamsList, 'viewArticle' | 'viewEnterpriseArticle'>;

const ArticlePage = ({ route, navigation }: Props) => {
    const insetTop = useSafeAreaInsets().top
    //TODO: Add this link to newsletter
    const openedFromEnterpriseRoute = route.name == 'viewEnterpriseArticle';
    const id = route.params?.id;
    const { storeArticle, setArticle } = useArticleOptionsStore((store) => ({ setArticle: store.setArticle, storeArticle: store.article }));
    const article = React.useMemo(() => {
        return route.params.article && typeof route.params.article != 'string' ? route.params.article : storeArticle
    }, [id, route, storeArticle])

    //TODO: use this to pass an array of custom keywords to pass down to the article content
    const keywords = null

    const [loading, setLoading] = React.useState<boolean>(article ? false : true);

    const [useWebView, setUseWebView] = useState(false);
    const [onlyWebView, setOnlyWebView] = useState(false);

    const readArticle = async (data?: Article) => {
        let apiArticle: Article;
        if (!data || data.webview)
            setLoading(true);
        if (!data) {

            try {
                apiArticle = await ArticleApi.read(id);
            }
            catch (e) {
                console.error('Error: ', e)
            }

            setArticle(apiArticle);

        } else {
            apiArticle = data
        }

        try {

            const webview = apiArticle.webview

            if (openedFromEnterpriseRoute) {

                setUseWebView(false);
                setOnlyWebView(false);
                setLoading(false);
                return
            }

            // @ts-ignore
            if (webview == true || webview == 'true') {

                if (Platform.OS == 'web') {
                    openInBrowser(apiArticle?.url)

                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('home');
                    }
                } else {
                    setUseWebView(true);
                    setOnlyWebView(true);
                }
            } else {
                setUseWebView(false);
            }

        } catch (e) {
            setUseWebView(false);
        }
        setLoading(false);
    }

    // React.useEffect(() => {

    //     if(article && route.params?.screenshot  && article.props?.media?.screenshot.url) {
    //         openInBrowser(article.props.media.screenshot.url)
    //     }

    // }, [article])

    React.useEffect(() => {

        StatusBar.setBarStyle('light-content')

        if (article && article?._id != id) {
            readArticle(article);
        } else if (!article) {
            readArticle()
        }

        return (() => { /* setArticle(null); */ StatusBar.setBarStyle('dark-content') })
    }, []);

    const theme = useAppTheme()

    const ref = useRef<Modal>()

    const w_height = Dimensions.get('window').height
    const w_width = Dimensions.get('window').width

    const [modalVisible, setModalVisible] = React.useState(false)

    const screenshots = React.useMemo(() => {
        let urls: { url: string }[] = []

        if (article) {
            if (article.media.items?.length) {
                urls = urls.concat(article?.media.items?.map(m => ({ url: m.url })))
            }

            // if (article.props.media.screenshot) {
            //     urls.push({ url: article.props.media.screenshot.url })
            // }
        }

        return urls.filter(i => i.url != null && i.url.length > 0)

    }, [article])

    return (
        <>

            {

                loading ?
                    <View style={styles.activityIndicatorContainer}>
                        <ActivityIndicator size="large" animating={true} color={theme.colors.primary[500]} style={{ flex: 1 }} />
                    </View>
                    :
                    (useWebView) ?
                        <View style={{ flex: 1, paddingTop: insetTop }}>
                            <ArticleWebView
                                article={article}
                                src={article?.url}
                                useNativeWebView={true}
                                useIframe={false} />
                        </View>
                        :
                        <VirtualScrollHeaderScreen
                            image={article?.media?.cover?.url}
                            header={
                                <ArticleHeading article={article} />
                            }
                        >

                            {article ? <ArticleContent article={article} keywords={keywords} /> : null}


                        </VirtualScrollHeaderScreen>
            }
            {article?.media?.items?.length > 0 &&
                <View
                    style={{ position: 'absolute', zIndex: 1000, bottom: w_height * 0.10, right: w_width * 0.05, ...theme.shadows.box.medium, borderRadius: 5, backgroundColor: theme.colors.background[400] }}
                >
                    <TouchableOpacity
                        onPress={() => setModalVisible(true)}
                    >
                        <AutoImage maxWidth={w_width * 0.2} maxHeight={w_height * 0.2} style={{ resizeMode: 'contain' }} source={{ uri: article.media?.screenshots?.[0].url ?? article?.media?.items?.[0]?.url }} />
                    </TouchableOpacity>
                </View>
            }
            <ArticleBottomOptions
                article={article}
                navigation={navigation}
                lockedState={onlyWebView}
                toggleWebView={() => { setUseWebView(!useWebView) }}
                isWebView={useWebView} />


            <Modal ref={ref} visible={modalVisible} transparent={true} onRequestClose={() => setModalVisible(false)}>

                <ImageViewer
                    renderIndicator={(currentIndex, allSize) =>
                        <TextNty style={{ zIndex: 1000, position: 'absolute', bottom: 10, left: 10 }} fontSize={20} color={fixed_colors.white[500]}>{currentIndex}/{allSize}</TextNty>
                    }
                    saveToLocalByLongPress={false} onCancel={() => setModalVisible(false)}
                    enableSwipeDown={true}
                    imageUrls={screenshots}
                    renderHeader={(index) =>

                        <View style={{ position: 'absolute', top: Platform.OS == 'web' ? 20 : 60, right: Platform.OS == 'web' ? 20 : 10, zIndex: 1000 }}>
                            <MaterialCommunityIcons name='close-box' color={'white'} size={40} onPress={() => setModalVisible(false)} />
                        </View>
                    }
                />
            </Modal>




        </>
    );
}



export default memo(ArticlePage);
