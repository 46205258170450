import React from 'react'
import { ColorValue, Image, ImageSourcePropType, Platform, RefreshControl, StyleSheet, useWindowDimensions, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import Animated, { Extrapolate, interpolateNode, useValue } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { hexTransparency } from '../../../core/jsutils'
import { fixed_colors } from '../../../core/theme/colors'
import Background from '../../Background'

export type VirtualScrollHeaderProps = {
  header: JSX.Element,
  children: JSX.Element | JSX.Element[],
  headerHeight?: number,
  onRefresh?: () => void,
  snapToInterval?: number | Animated.Node<number>,
  decelerationRate?: number | "fast" | "normal" | Animated.Node<number | "fast" | "normal">,
  headerBgColor?: ColorValue,
  image?: ImageSourcePropType | string,
  headerAnimated?: boolean

}

function VirtualScrollHeaderScreen(props: VirtualScrollHeaderProps & { scrollRef?: React.ForwardedRef<FlatList> }) {

  const HEADER_HEIGHT = React.useMemo(() => props.headerHeight ?? (Platform.OS == 'web' ? 200 : 300), [props.headerHeight])
  const insets = useSafeAreaInsets()

  const animated = props.headerAnimated ?? true
  // const theme = useAppTheme()

  const bg = props.headerBgColor ?? (props.image ? hexTransparency(fixed_colors.black[400], 40) : 'transparent')
  const scrollY = useValue(0)

  const translateY = interpolateNode(scrollY, {
    inputRange: [0, HEADER_HEIGHT + insets.top],
    outputRange: [0, -HEADER_HEIGHT + insets.top],
    extrapolate: Extrapolate.CLAMP,
  })


  const scale = interpolateNode(scrollY, {
    inputRange: [-HEADER_HEIGHT * 2, 0],
    outputRange: [3, 1],
    extrapolate: Extrapolate.CLAMP,
  })

  const opacity = interpolateNode(scrollY, {
    inputRange: [0, HEADER_HEIGHT],
    outputRange: [1, 0],
    extrapolate: Extrapolate.CLAMP,
  })



  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#000',
    },
    header: {
      height: HEADER_HEIGHT,
      width: "100%",
      paddingTop: insets.top
    },
    items: {
      paddingTop: Platform.OS != 'web' ? HEADER_HEIGHT : null,
      // paddingHorizontal: 8,
    },
    image: {
      ...StyleSheet.absoluteFillObject,
      top: 0,
      // height: IMAGE_HEIGHT,
      width: '100%',
      height: HEADER_HEIGHT,
      resizeMode: 'cover'
    },
    item: {
      backgroundColor: '#1C1C1C',
      height: 50,
      width: '100%',
      marginTop: 8,
    }
  });

  const defaultRefreshControl = () =>
    <RefreshControl
      progressViewOffset={20}
      refreshing={false}
      onRefresh={props.onRefresh}
    />

  const dimensions = useWindowDimensions()

  const renderItem = React.useCallback(() => {
    return (
      <View style={styles.items}>
        {props.children}
      </View>
    );
  }, [props.children])



  return (

    Platform.OS == 'web' ?
      <Background>
        <Animated.FlatList
          //@ts-ignore
          ref={props.scrollRef}
          // StickyHeaderComponent={props.header}
          data={['single_item']}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          ListHeaderComponent={
            <View style={[styles.header, { zIndex: 1000 }]}>
              <View style={{
                ...StyleSheet.absoluteFillObject, backgroundColor: bg, zIndex: 1000
              }} />

              {props.image ? <View style={{
                ...StyleSheet.absoluteFillObject,
                top: 0,
                // height: IMAGE_HEIGHT,
                width: '100%',
                height: HEADER_HEIGHT,
                backgroundColor: bg
              }} >
                <Image defaultSource={require('../../../assets/logos/logo_nty.png')} source={typeof props.image == 'string' ? { uri: props.image, cache: 'force-cache' } : props.image} style={{ resizeMode: 'cover', width: props.image ? "100%" : "50%", height: props.image ? "100%" : "50%", alignSelf: 'center' }} />

              </View> : null}
              <View style={{ flex: 1, zIndex: 1100 }}>

                {props.header}
              </View>
              {/* <TextNty>This is web</TextNty> */}
            </View>
          }
          snapToInterval={props.snapToInterval}
          decelerationRate={props.decelerationRate}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
            { useNativeDriver: true }
          )}
          scrollEventThrottle={16}
          refreshControl={props.onRefresh ? defaultRefreshControl() : null}
          style={{ height: dimensions.height - (insets.top + insets.bottom) }}
        />

      </Background> :
      <>
        <Animated.View style={[styles.header, { transform: [{ translateY: translateY }], zIndex: 10, position: 'absolute' }]}>
          <Animated.View style={{
            ...StyleSheet.absoluteFillObject, backgroundColor: bg, zIndex: 9, transform: [
              { scale: scale }
            ]
          }} />

          {props.image ? <Animated.View style={{
            ...StyleSheet.absoluteFillObject,
            // top: 0,
            height: HEADER_HEIGHT,
            alignContent: 'center',
            width: '100%',
            // height: HEADER_HEIGHT,
            backgroundColor: bg,
            opacity: opacity,
            transform: [
              { scale: scale }
            ]
          }} >

            <Image defaultSource={require('../../../assets/logos/logo_nty.png')} source={typeof props.image == 'string' ? { uri: props.image, cache: 'force-cache' } : props.image} style={{ resizeMode: 'cover', width: props.image ? "100%" : "50%", height: props.image ? "100%" : "50%", alignSelf: 'center' }} />

          </Animated.View>
            : null}



          <Animated.View style={{ zIndex: 100, flex: 1, opacity: opacity }}>

            {props.header}

          </Animated.View>

        </Animated.View>
        <Animated.FlatList
          //@ts-ignore
          ref={props.scrollRef}
          data={['single_item']}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          snapToInterval={props.snapToInterval}
          decelerationRate={props.decelerationRate}
          onScroll={animated ? Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
            { useNativeDriver: true }
          ) : null}
          scrollEventThrottle={16}
          refreshControl={props.onRefresh ? defaultRefreshControl() : null}
        />
      </>
  )
}





export default React.forwardRef((props: VirtualScrollHeaderProps, ref: React.ForwardedRef<FlatList>) => {

  return <VirtualScrollHeaderScreen {...props} scrollRef={ref} />

})
