import { SessionInfo } from "systemDomain";
import { buildApiUrl, http_request } from "../core/http-utils";

type Pagination = {
    skip: number,
    limit: number,
    sort?: Record<string, -1 | 1>
}

export class NamedQueryApi {


    static async fetch<T = any[]>(name: string, params: any, page: Pagination = {skip: 0, limit: 10}): Promise<T> {

        let url = buildApiUrl('named-queries/fetch', true)
        console.log({name, params, page})
        const results = await http_request<T>(url, 'POST', {name, params, page})

        console.log(results)
        return results

    }
}
