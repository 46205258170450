import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { ScrollView, View } from 'react-native'
import { Card, IconButton, Title } from 'react-native-paper'
import useSessionStore from 'src/context/sessionStore'
import getEnvVars from '../../../../environments'
import { useAppTheme } from '../../../core/theme/ThemeBuilder'
import { resetStackNavigator } from '../../../core/utils'
import Background from '../../Background'
import TextNty from '../../components/nty/text/TextNty'
import { useFavouritesStore } from '../../context/favoritesStore'

export default function ProfileInfoPage() {

    const theme = useAppTheme()
    const navigation = useNavigation();
    const { session_id, profile, updated_at, started_at, appConfig: appConfigData} = useSessionStore.getState()
    const { favourites } = useFavouritesStore.getState()



    const printObject = (object: any, argkey?: string, indentation: number = 0) => {

        let keys;

        if (object == null) {
            return <TextNty>{argkey + ': ' ?? ''}{"null"}</TextNty>
        }

        if (Array.isArray(object)) {
            if (object.length == 0) {
                return <TextNty>[{"  "}]</TextNty>
            }
            keys = Array.from(Array(object.length).keys());

        } else {
            keys = Object.keys(object);
        }



        return (

            keys.map((key: string) => {

                let begin = null;
                let end = null;

                let content = null;


                if (typeof object[key] == 'object' && !(object[key] instanceof Date)) {
                    content =
                    <View>
                        <TextNty style={{ fontWeight: 'bold', marginVertical: 3, marginLeft: 5 * indentation }}>{key}:</TextNty>
                        {printObject(object[key], key, indentation + 1)}
                    </View>
                } else

                    if (typeof object[key] == 'string') {
                        content = <TextNty style={{ fontWeight: 'bold', marginVertical: 3, marginLeft: 10 * indentation }}>{key}: <TextNty style={{ fontWeight: 'normal', color: "green" }}>"{object[key]}"</TextNty></TextNty>
                    } else

                        if (typeof object[key] == 'number') {
                            content = <TextNty style={{ fontWeight: 'bold', marginVertical: 3, marginLeft: 10 * indentation }}>{key}: <TextNty style={{ fontWeight: 'normal', color: "cyan" }}>{object[key]}</TextNty></TextNty>
                        } else

                            if (typeof object[key] == 'boolean') {
                                content = <TextNty style={{ fontWeight: 'bold', marginVertical: 3, marginLeft: 10 * indentation }}>{key}: <TextNty style={{ fontWeight: 'normal', color: "darkblue" }}>{object[key] ? "true" : "false"}</TextNty></TextNty>
                            } else {
                                content = <TextNty style={{ fontWeight: 'bold', marginVertical: 3, marginLeft: 10 * indentation }}>{key}: <TextNty style={{ fontWeight: 'normal', color: "cyan" }}>{<TextNty>{JSON.stringify(object[key])?.replace(",", ",\r\n").replace("\":{", "\":\r\n\t{\r\n\t")}</TextNty>}</TextNty></TextNty>
                            }
                return content;

            })
        )

    }

    // const onBoarding = async () => {

    //     const done = await hasDoneOnBoarding(session.userInfo);
    //     return done ? "true" : "false";

    // }

    return (
        <Background safeInsets>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 20 }}>
                    <IconButton icon={'arrow-left'} size={35} color={theme.colors.text_primary[500]} onPress={() => resetStackNavigator('home', navigation)} />
                    <Title style={{ fontSize: 28 }}>Estadísticas Internas:</Title>
                </View>


                <Card style={{ marginVertical: 10, backgroundColor: theme.colors.background[400] }} key='AppSessionStore'>

                    <Card.Title title='AppSessionStore' />

                    <Card.Content>

                        <View style={{ flexDirection: 'column', justifyContent: 'space-evenly' }}>

                            <TextNty><TextNty type='bold'>Session ID: </TextNty> "{session_id}"</TextNty>
                            <TextNty><TextNty type='bold'>Started At: </TextNty> "{started_at}"</TextNty>
                            <TextNty><TextNty type='bold'>Updated At: </TextNty> "{updated_at}"</TextNty>
                        </View>

                    </Card.Content>

                </Card>

                <Card style={{ marginVertical: 10, backgroundColor: theme.colors.background[400] }} key='profile'>

                    <Card.Title title='Profile' />

                    <Card.Content>

                        <View>

                            {profile ? printObject(profile) : null}

                        </View>

                    </Card.Content>

                </Card>

                <Card style={{ marginVertical: 20 }} key='app'>

                    <Card.Title title='app.json' />

                    <Card.Content>

                        <View>
                            {/* <TextNty>{JSON.stringify(favourites)}</TextNty> */}
                            {appConfigData ? printObject(appConfigData) : null}
                        </View>

                    </Card.Content>

                </Card>

                <Card style={{ marginVertical: 20 }} key='env'>

                    <Card.Title title='Environment' />

                    <Card.Content>

                        <View>
                            {/* <TextNty>{JSON.stringify(favourites)}</TextNty> */}
                            {printObject(getEnvVars())}
                        </View>

                    </Card.Content>

                </Card>

                <Card style={{ marginVertical: 20 }} key='favs'>

                    <Card.Title title='Favourites' />

                    <Card.Content>

                        <View>
                            {/* <TextNty>{JSON.stringify(favourites)}</TextNty> */}
                            {favourites ? printObject(favourites) : null}
                        </View>

                    </Card.Content>

                </Card>


            </ScrollView>
        </Background>
    )
}
