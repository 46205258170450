import React from 'react';

import * as SplashScreen from 'expo-splash-screen';

import {
    PlayfairDisplay_800ExtraBold,
    PlayfairDisplay_700Bold,
    PlayfairDisplay_600SemiBold,
    PlayfairDisplay_500Medium,
    PlayfairDisplay_400Regular,
    useFonts as useGoogleFonts
} from '@expo-google-fonts/playfair-display';
import {
    Roboto_700Bold,
    Roboto_500Medium,
    Roboto_400Regular,
    Roboto_300Light,
} from '@expo-google-fonts/roboto'

import {
    Archivo_900Black,
    Archivo_800ExtraBold,
    Archivo_700Bold,
    Archivo_600SemiBold,
    Archivo_500Medium,
    Archivo_400Regular,
    Archivo_300Light,
    Archivo_200ExtraLight,
    Archivo_100Thin
} from '@expo-google-fonts/archivo'


function FontsLoader(props: { fontsLoaded?: (error?: Error) => void }) {


    let [googleFontsLoaded, error] = useGoogleFonts({
        // PlayFair
        PlayfairDisplay_800ExtraBold,
        PlayfairDisplay_700Bold,
        PlayfairDisplay_600SemiBold,
        PlayfairDisplay_500Medium,
        PlayfairDisplay_400Regular,
        // Roboto
        Roboto_700Bold,
        Roboto_500Medium,
        Roboto_400Regular,
        Roboto_300Light,
        // Archivo
        Archivo_900Black,
        Archivo_800ExtraBold,
        Archivo_700Bold,
        Archivo_600SemiBold,
        Archivo_500Medium,
        Archivo_400Regular,
        Archivo_300Light,
        Archivo_200ExtraLight,
        Archivo_100Thin
    })

    React.useEffect(() => {

        if (googleFontsLoaded) {
            // console.log('Additional Fonts Loaded')
            props.fontsLoaded?.(undefined)
            SplashScreen.hideAsync();

        } else if (error) {
            props.fontsLoaded?.(error)

        }

    }, [googleFontsLoaded])


    return (
        null
    )
}

export default React.memo(FontsLoader)
