
import React from 'react'
import { Keyboard, Platform, TextInput, TouchableWithoutFeedback, View } from 'react-native'
import { fixed_colors } from '../../../../core/theme/colors'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'


import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'
import ButtonNty from '../../../components/nty/button/ButtonNty'
import TextNty from '../../../components/nty/text/TextNty'
import NtyTextInputRound from '../../../components/nty/TextInput/TextInputNtyRound'
import TitleNty from '../../../components/nty/title/TitleNty'
import { usePrompt } from '../../alertContext'

function interpolate(i, a, b, a2, b2): number {
    return (i - a) * (b2 - a2) / (b - a) + a2;
}

function NtyPromptAlertModal() {

    const theme = useAppTheme()
    const { visible, promptInfo, hide, show, setData, data, resolvePromise, rejectPromise } = usePrompt()
    const refAlert = React.useRef<NewsToYouSheetModalHandle>()

    const [validationErrors, setValidationErrors] = React.useState<{ result: Boolean, errorMessage: string }[]>()

    const visibleRef = React.useRef(false)

    const hidePrompt = (dissmissKeyboard = true) => {

        // console.log('hidePrompt')
        // rejectPromise({ button: null, prompt: promptInfo, data })
        visibleRef.current = false;
        // console.log('alertVisible', refAlert.current.state.textDiagVisible)
        refAlert?.current?.hide()
        hide()
        // Keyboard.dismiss()
        dissmissKeyboard ? setTimeout(() => Keyboard.dismiss(), 500) : null

    }


    const showPrompt = () => {

        visibleRef.current = true;
        refAlert?.current?.show()
        show()
        setTimeout(() => inputRef?.current?.focus(), 100)

    }

    const validateInput = (text: string) => {
        if (promptInfo.validations && promptInfo.validations.length > 0) {

            let errors = []

            if (promptInfo?.validations?.length > 0 && (text == null || text?.length == 0)) {
                errors.push({ result: false, errorMessage: 'Obligatorio' })
            }

            promptInfo.validations.forEach((validation) => {

                let result = true



                if (validation.regex) {
                    result = validation.regex.test(text)
                }

                if (validation.function) {
                    result = result && validation.function(text)
                }

                if (!result) {
                    errors.push({ result, errorMessage: validation.errorMessage })
                }

            })

            // console.log(errors)

            if (errors.length >= 1) {
                setValidationErrors(errors)
            } else {
                setValidationErrors(null)
            }
        }
    }

    React.useEffect(() => {
        // console.log({visible, visibleRef: visibleRef.current})
        setValidationErrors(null)
        if (visible == true && visibleRef.current == false) {
            // console.log('useEffect showing')
            showPrompt()
        } else if (visible == false && visibleRef.current == true) {
            // console.log('useEffect closing')
            // console.log('closing')
            hidePrompt()
        }
    }, [visible])

    const inputRef = React.useRef<TextInput>()

    // React.useEffect(() => {
    //     // console.log('validating...')
    //     validateInput(data)
    // }, [promptInfo?.validations])
    return (
        <NtySheetModal dissmisable={promptInfo?.dismissable != false} ref={refAlert} onClose={() => { /* console.log('onClose'); */ hide(); resolvePromise({prompt: promptInfo, button: null, data: null}) }} snapPoints={[interpolate(typeof promptInfo.message == 'string' ? promptInfo.message.length : 100, 0, 600, 260, 700), "50%"]}  >

            <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
                <View style={{ flex: 1, paddingHorizontal: 30, paddingTop: 40 }}>

                    <TitleNty>{promptInfo.title}</TitleNty>
                    {
                        typeof promptInfo.message == 'string' ?
                            <View style={{ width: "95%", marginVertical: 20 }}>
                                <TextNty fontSize={interpolate(promptInfo.message.length, 0, 600, 20, 11)} type='light'>{promptInfo.message}</TextNty>
                            </View> :
                            promptInfo.message
                    }

                    <NtyTextInputRound
                        ref={inputRef}
                        onKeyPress={Platform.OS == 'web' ? (e) => {
                            if (e.nativeEvent.key == 'Enter') {
                                console.log('enter pressed')
                                if (!validationErrors || validationErrors?.length == 0) {
                                    console.log('no validation errors', { button: null, prompt: promptInfo, data })
                                    resolvePromise({ button: null, prompt: promptInfo, data })
                                    hidePrompt(false)
                                } else {
                                    console.log('validation errors', validationErrors)
                                }
                            }
                        } : null}
                        onSubmitEditing={Platform.OS != 'web' ? () => {
                            console.log('submitted')

                            if (!validationErrors || validationErrors?.length == 0) {
                                console.log('no validation errors')
                                resolvePromise({ button: null, prompt: promptInfo, data })
                                setTimeout(() => hidePrompt(false), 50)
                            } else {
                                console.log('validation errors', validationErrors)
                            }
                        } : null
                        }
                        style={{ marginVertical: 10 }}
                        borderBottomColor={validationErrors?.length > 0 ? theme.colors.danger[500] : theme.colors.text_primary[500]}
                        placeholder={promptInfo.inputPlaceHolder}
                        value={data}
                        bottomSheetInput
                        onFocus={() => validateInput(data)}
                        onBlur={(props) => {
                            Keyboard.dismiss()
                        }}
                        onChangeText={(text) => {
                            setData(text)
                            validateInput(text)
                        }}
                    />
                    {
                        validationErrors && validationErrors.length >= 1 ?
                            <View style={{ paddingVertical: 2 }}>
                                {validationErrors.map((validation) => <TextNty key={validation.errorMessage} type='medium' color={fixed_colors.red[500]}>{validation.errorMessage}</TextNty>)}
                            </View>
                            : null
                    }


                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}>
                        {promptInfo.buttons.map((b) => <ButtonNty key={b.label + Date.now().toString()} disabled={b.cancel != true && validationErrors?.length > 0} mode={b.type ?? 'contained'} onPress={() => { b.dismissButton != false ? hidePrompt() : false; b.onPress ? b.onPress(b, data) : null }}>{b.label}</ButtonNty>)}
                    </View>
                </View>

            </TouchableWithoutFeedback>

        </NtySheetModal>
    )
}

export default NtyPromptAlertModal
