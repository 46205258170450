import { SubscriptionInterface } from "systemDomain";

/**
 * Values fot User.type field
 */
export enum UserTypes {

    /**
     * A new user that just entered for the first time or an user that has no data of session usage in this device.
     */
    GUEST = 'guest',

    /**
     * A normal __not premium__ logged in user.
     */
    USER = 'user',

    /**
    * A __premium__ logged in user.
    */
    USER_PREMIUM = 'user_premium'

}



/**
 * This type is used to define the structure of the information being stored in the User.payment field
 */
export type Payment = {


    subscriptions: SubscriptionInterface[]

}

/**
 * This class is used to define and document the types used inside the session store `user` prop.
 *
 * Stores user data related to the _'personality'_ of the user thats using the application:
 *   - email
 *   - premium
 *   - token
 *   - type
 *   - status
 *   - rights
 *   - payments
 *     - subscriptions
 *
 * @deprecated not used anymore. User info is replaced by profile on the session store
 */
export class SessionUser {

    email: string;
    premium: boolean;
    token: string;
    /**
     * Type of 'person' using the app could be One of {@link UserTypes}
     */
    type: UserTypes;

    /**
     * Legacy from older versions, not sure what it means, a value that comes from auth table mostly as `'confirmed'`
     */
    status: string;
    /**
     * Not yet used. Ahead implementation for custom app rights.
     */
    rights: string[];
    /**
     * Used to store information about payments
     */
    payment: Payment;

    public static NTY_PREMIUM_PLAN_NAME = 'NTY Premium';
    public static NTY_GUEST_DEFAULT_MAIL = 'guest@guest.thenewstoyou.com';

    private constructor(email: string, premium: boolean, token: string, type: UserTypes, status?: string, payment?: Payment) {
        this.email = email;
        this.premium = premium;
        this.token = token;
        this.type = type;
        this.status = status;
        this.payment = payment;
    }

    public static from(user: SessionUser) {
        return new SessionUser(user.email, user.premium, user.token, user.type, user.status, user.payment);
    }

    /**
     *
     * __Method used to create user objects safely.__
     *
     * Indicate the type of user and some other parameters if you want.
     *
     * @param type this param will be used to determine wich builder to use.
     * @param token if you pass this param, the builded user will have the given token.
     * @param status Legacy from older versions, not sure what it means, a value that comes from auth table mostly as `'confirmed'`
     * @param email if you pass this param, the builded user will have the given email.
     * @param subscriptions if you pass this param, the builded user will have the given subscriptions added to the payment object.
     * @param trial if you pass this param, the builded user will have the given trial type
     * @param trialData if you pass this param, the builded user will have the given trial data
     */
    public static build(type: UserTypes, token?: string, email: string = SessionUser.NTY_GUEST_DEFAULT_MAIL, subscriptions?: SubscriptionInterface[], status: string = 'confirmed'): SessionUser {

        switch (type) {
            case UserTypes.GUEST: return SessionUser.buildGuestUser(email, status);
            case UserTypes.USER: return SessionUser.buildNormalUser(email, token, subscriptions, status);
            case UserTypes.USER_PREMIUM: return SessionUser.buildPremiumUser(email, token, subscriptions, status);
        }

    }

    public static buildGuestUser(email: string, status: string = 'confirmed'): SessionUser {
        const user = new SessionUser(email, false, SessionUser.NTY_GUEST_DEFAULT_MAIL, UserTypes.GUEST, status, { subscriptions: [] })
        user.isPremium();
        return user;
    }

    public static buildNormalUser(email: string, token: string, subscriptions?: SubscriptionInterface[], status: string = 'confirmed'): SessionUser {
        const user = new SessionUser(email, false, token, UserTypes.USER, status, { subscriptions: subscriptions ?? [] })
        user.isPremium();
        return user;
    }

    public static buildPremiumUser(email: string, token: string, subscriptions: SubscriptionInterface[], status: string = 'confirmed'): SessionUser {
        if (subscriptions == null || subscriptions.length == 0) {
            throw new Error("Premium users must be created with subscriptions information");
        }
        const user = new SessionUser(email, true, token, UserTypes.GUEST, status, { subscriptions: subscriptions })
        user.isPremium();
        return user;
    }


    public toObject(): any {
        return { email: this.email, type: this.type, status: this.status, paymen: this.payment };
    }

    public static fromObject(user: typeof SessionUser.prototype): SessionUser {
        return new SessionUser(user?.email, user?.premium, user?.token, user?.type, user?.status, user?.payment);
    }

    /**
     * @deprecated Premium features are being removed from the app.
     * @returns
     */
    public isPremium(): boolean {

        const ntyPremiumPlan = this.payment?.subscriptions ? this.payment.subscriptions.filter((subscription) => (subscription.plan == SessionUser.NTY_PREMIUM_PLAN_NAME && subscription.status + '' == 'active')) : null;

        if (ntyPremiumPlan && ntyPremiumPlan.length > 0) {
            this.premium = true;
            this.type = UserTypes.USER_PREMIUM;
            return true;
        }

    }

    public isGuest(): boolean {
        return this.type == UserTypes.GUEST;
    }
}

export type AuthDBUser = {

    email: string,
    token: string,
    type: string,
    status: string

}

//duplicated from auth-server
class User {
    email: string;
    token: string;
    type: string;
    status: string;

    constructor(email: string, token: string, type: string, status: string) {
        this.email = email;
        this.token = token;
        this.type = type;
        this.status = status;
    }

    load(data: any): User {
        return new User(data.email, data.token, data.type, data.status);
    }

    toObject(): any {
        return { email: this.email, type: this.type, status: this.status };
    }

    isEmpty(): boolean {
        return this.email == undefined;
    }
}

export default User
