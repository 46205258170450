import { ColorPallete, FixedColors } from "./types"



export const palletes: {default: ColorPallete} & {[key: string]: ColorPallete} = {

    default: {
        background: {
            200: "#FFFFFF",
            300: "#FAFAFA",
            400: "#F5F6FA",
            500: "#f8f8f7",
            600: "#E9EAF2",
            700: "#D3D4DE",
            muted: "#B3B5BD"
        },
        primary: {
            200: "#ffbdbd",
            300: "#ffa1a1",
            400: "#fa5f5f",
            500: "#EB3F3F",
            600: "#B21212",
            700: "#930000",
            muted: "#BF4343"
        },
        secondary: {
            200: "#E6E6E6",
            300: "#BFBFBF",
            400: "#807C7C",
            500: "#595555",
            600: "#332E2E",
            700: "#262222",
            muted: "#3B3B3B"
        },
        success: {
            400: '#7ACC7A',
            500: '#53A653',
            600: '#338033'
        },
        warning: {

            400: '#FFC859',
            500: '#FFA800',
            600: '#E57300'

        },
        danger: {
            400: '#F5693D',
            500: '#FF4206',
            600: '#AE2D04'
        },
        text_primary: {
            200: '#666464',
            300: '#4D4C4C',
            400: '#2E2E2E',
            500: '#1E1E1E',
            600: '#1A1A1A',
            700: '#000000',
            muted: '#A6A6A6'
        },
        text_secondary: {
            200: '#FFFFFF',
            300: '#F7F7F7',
            400: '#F2F0F0',
            500: '#E5E3E3',
            600: '#D9D4D4',
            700: '#C7C3C3',
            muted: '#B4B4B4'
        },
        links: {
            400: '#FFFFFF',
            500: '#168AFF',
            600: '#033698',
            muted: '#681DA8'
        }

    }

}


export const fixed_colors: FixedColors = {


    white: {
        200: '#FFFFFF',
        300: '#FAF9F5',
        400: '#F8F6EE',
        500: '#F2F0E6',
        600: '#EBE8DF',
        700: '#E0DED5',
        muted: '#DBDDDC'
    },
    black: {
        200: '#595959',
        300: '#404040',
        400: '#333333',
        500: '#262626',
        600: '#1A1A1A',
        700: '#000000',
        muted: '#423e39'
    },
    gray: {
        200: '#F5F5F4',
        300: '#F3F3F1',
        400: '#F0F0EF',
        500: '#EEEEEC',
        600: '#D0D0CF',
        700: '#B3B2B1',
        muted: '#777776'
    },
    grey: {
        200: '#DBDBDD',
        300: '#C5C5C8',
        400: '#8D8D92',
        500: '#67676C',
        600: '#3C3C3F',
        700: '#252526',
        muted: '#F3F3F8'
    },
    red: {
        200: '#FF887D',
        300: '#FF6E63',
        400: '#FF453A',
        500: '#FF3B30',
        600: '#F11010',
        700: '#CC0800',
        muted: '#c55852'
    },
    orange: {
        200: '#FFC466',
        300: '#FFAB4D',
        400: '#FFA518',
        500: '#FF9500',
        600: '#EB8100',
        700: '#AD4300',
        muted: '#bf8a40'
    },
    yellow: {
        200: '#ffeb0b',
        300: '#edd900',
        400: '#FFDB2B',
        500: '#FCC900',
        600: '#d0a600',
        700: '#945E00',
        muted: '#bfa540'
    },
    green: {
        200: '#65FF7E',
        300: '#51F66A',
        400: '#37DD50',
        500: '#28CD41',
        600: '#1FB236',
        700: '#007B00',
        muted: '#53a760'
    },
    blue: {
        200: '#4DD2FF',
        300: '#4DB5FF',
        400: '#168AFF',
        500: '#007AFF',
        600: '#0056FD',
        700: '#003DDB',
        muted: '#407dbf'
    },
    purple: {
        200: '#EC8FFF',
        300: '#D87BFF',
        400: '#C45DF9',
        500: '#AF52DE',
        600: '#942FC8',
        700: '#7215A1',
        muted: '#9965b3'
    },
    pink: {
        200: '#FF7AA2',
        300: '#FF6088',
        400: '#FF3C63',
        500: '#FF2D55',
        600: '#E6143C',
        700: '#CC0F32',
        muted: '#c55167'
    },
    brown: {
        200: '#DBBC86',
        300: '#C7A879',
        400: '#B6976F',
        500: '#A2845E',
        600: '#816747',
        700: '#594B31',
        muted: '#b5a68a'
    },
    abante_pink: {
        700: '#6F0034',
        600: '#A1003C',
        500: '#d0043c',
        400: '#D92359',
        300: '#E24475',
        200: '#E96690',
        muted: '#bf8097'
    }

}
