import React from 'react'
import PublishersSelector from 'src/app/components/brands_sources/PublisherSelector';
import { useSearchPublisherSelectorModal } from 'src/app/context/searchModalsStores';
import { useSearchStore } from 'src/context/appSearchStore';

function SearchPublisherSelector() {

    const _onCancel = () => {
        useSearchPublisherSelectorModal.getState().close()
    }

    const _onDone = (keyNames: string[]) => {
        useSearchStore.getState().search({ sources: keyNames })
        useSearchPublisherSelectorModal.getState().close()
    }


  return (
    <PublishersSelector onCancel={_onCancel} onDone={_onDone} />
  )
}

export default SearchPublisherSelector
