import React from 'react'
import { View } from 'react-native'
import { DefinedSection, DefinedSectionTypesNames, Section, getDefinedSectionType } from 'systemDomain'
import TextNty from '../../../nty/text/TextNty'
import TitleNty from '../../../nty/title/TitleNty'

import ArticlesSection from './article/ArticlesSection'
import ArticlesTitlesSection from './article/ArticlesTitlesSection'
import NewspapersCoversSection from './newspapers/NewspapersCoversSection'
import NewspapersSection from './newspapers/NewspapersSection'
import ArticleCardPlaceHolder from '../../../article/cards/ArticleCardPlaceHolder'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreensParamsList } from '../../../../../core/navigation/screens/NavigationScreens'
import SectionArticlesSection from './defined-sections/SectionArticlesSection'
import FeedArticlesSection from './defined-sections/FeedArticlesSection'

function NotYetImplemented(props) {
    return <View style={{ marginVertical: 20, borderWidth: 1, justifyContent: 'center' }}>
        <TitleNty>Not yet implemented</TitleNty>
        <TextNty>{JSON.stringify({ props })}</TextNty>
    </View>
}

/**
 *
 * Used to render a non defined section by the section type. Sections should be defined in the systemDomain in order to have better control over the sections properties.
 * This is used for retrocompatibility with home.service (see rest-api/home)
 *
 * @param props
 * @returns
 */
function SimpleSectionTypeComponent(props: { section: Section<any, any> }) {

    const nav = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()

    switch (props.section.type) {
        case 'articles': return <ArticlesSection {...props} listProps={
            {
                articleCardProps: {
                    showSubtitle: false,
                    feed: props.section.info.feed ?? undefined
                },
                ListFooterComponent: props.section.info.data.items && props.section.info.data.items.length == 10 ? () => {
                    return <ArticleCardPlaceHolder title="Ver más" onPress={() => {
                        nav.push('viewArticlesSection', { section: props.section })
                    }} />
                } : null,
                contentContainerStyle: { paddingHorizontal: 10, paddingVertical: 15 },
            }
        } />
        case 'articleTitles': return <ArticlesTitlesSection {...props} />
        case 'newspapers': return <NewspapersSection {...props} />
        case 'covers': return <NewspapersCoversSection {...props} />
        case 'highlights': return <NotYetImplemented {...props} />
        default: return <NotYetImplemented {...props} switch={'default'} />
    }

}


function SectionComponent(props: { section: DefinedSection | Section | any }) {

    const definedSectionType = getDefinedSectionType(props.section);

    switch (definedSectionType) {
        case DefinedSectionTypesNames.SECTION_ARTICLES: return <SectionArticlesSection {...props} />
        case DefinedSectionTypesNames.FEED: return <FeedArticlesSection {...props} />
        default: return <SimpleSectionTypeComponent {...props} />
    }
}

export default SectionComponent


