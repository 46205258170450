import React, { memo, MutableRefObject } from "react";
import { StyleSheet, View } from "react-native";
import { Button, ToggleButton } from "react-native-paper";

import Swiper from "react-native-swiper/src";

import {
  TextThemeColor, useTextStylesStore
} from "../../context/TextStylesStore";
import ColorIcon from "./ColorIcon";

type Props = {
  swiperRef?: MutableRefObject<Swiper>;
  navigation?: any;
  onClose?: () => void;
};

const BottomSheetTextStylesSimple = ({
  swiperRef,
  navigation,
  onClose,
}: Props) => {
  // const { isPremium } = useAppSessionStore((store) => ({ isPremium: store.isPremium }))
  // const { isTrialActive } = useAppTrialStore((store) => ({ isTrialActive: store.isTrialActive }))
  const setStyle = useTextStylesStore((textStyles) => textStyles.setStyle);
  const style = useTextStylesStore((textStyles) => textStyles.style);

  const textTheme = useTextStylesStore((textStyles) => textStyles.textTheme);
  const setTextTheme = useTextStylesStore((textStyles) => textStyles.changeThemeColor);

  // const showRegisterModal = useRegisterModal(store => store.show)

  const [currentStyle, setCurrentStyle] = React.useState("medium");

  const [currentTextThemeName, setCurrentTextThemeName] = React.useState(null);

  const changeStyle = (style: string) => {
    setStyle(style);
    setCurrentStyle(style);
  };

  const changeTextTheme = (themeName) => {

    const theme = TextThemeColor.getTheme(themeName);

    if (theme) {
      setCurrentTextThemeName(theme.name);
      setTextTheme(theme);
    }

  };

  const customizePressed = () => {
    // if (isTrialActive() || isPremium()) {
      swiperRef.current.scrollTo(2, true);
    // } else {
    //   onClose ? onClose() : null;
    //   // navigation.navigate("plans");
    //   showRegisterModal()
    // }
  };

  React.useEffect(() => {
    if (currentStyle != style) {
      setCurrentStyle(style);
    }
    if (currentTextThemeName != textTheme.name) {
      setCurrentTextThemeName(textTheme.name)
    }
  }, [currentStyle, textTheme]);

  return (
    <View style={{ paddingHorizontal: 20, flex: 1 }}>
      {/* COLOR THEME */}
      <View style={[styles.item, styles.flex2]}>
        <View style={styles.itemContent}>
          <ToggleButton.Row
            onValueChange={(value) => changeTextTheme(value)}
            value={currentTextThemeName}
            style={{ height: 120 }}
          >
            <ToggleButton
              icon={({ size }) => (
                <ColorIcon
                  selected={currentTextThemeName == TextThemeColor.default.name}
                  color={TextThemeColor.default.bgColor}
                  size={size}
                />
              )}
              size={50}
              value={TextThemeColor.default.name}
              style={{ height: 120, width: 120, marginRight: 15}}
            />
            <ToggleButton
              icon={({ size }) => (
                <ColorIcon
                  selected={currentTextThemeName == TextThemeColor.newspaperDark.name}
                  color={TextThemeColor.newspaperDark.bgColor}
                  size={size}
                />
              )}
              size={50}
              value={TextThemeColor.newspaperDark.name}
              style={{ height: 120, width: 120 }}
            />
          </ToggleButton.Row>
        </View>
      </View>

      {/* FONT SIZE */}
      <View style={[styles.item, styles.flex2]}>
        <View style={styles.itemContent}>
          <ToggleButton.Row
            onValueChange={(value) => changeStyle(value)}
            value={currentStyle}
            style={{ height: 120 }}
          >
            <ToggleButton
              icon={"format-align-justify"}
              size={30}
              value="small"
              style={{ height: 100, width: 100, marginRight: 15 }}
            />
            <ToggleButton
              icon="format-align-justify"
              size={50}
              value="medium"
              style={{ height: 100, width: 100, marginRight: 15 }}
            />
            <ToggleButton
              icon="format-align-justify"
              size={70}
              value="large"
              style={{ height: 100, width: 100 }}
            />
          </ToggleButton.Row>
        </View>
      </View>

      <View style={[styles.item, styles.flex1]}>
        <View style={styles.itemContent}>
          <Button
            style={{ width: "100%", borderRadius: 10 }}
            onPress={customizePressed}
          >
            Personalizar
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContent: {
    flex: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  tittleItem: {
    flex: 1,
    alignContent: "center",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  item: {
    flexDirection: "column",
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex3: {
    flex: 3,
  },
});

export default memo(BottomSheetTextStylesSimple);

