import React from 'react'
import DownloadTheAppModal from '../../../components/modals/DownloadTheAppModal'
import { useRegisterModal } from '../../alertContext'

function RegisterModal() {

    const { visible, hide } = useRegisterModal()

    return (
        <DownloadTheAppModal visible={visible} onActionPressed={(action) => hide()} />
    )
}

export default RegisterModal
