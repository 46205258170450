
import ActionApi, { ACTION_NAMES } from 'src/api/ActionApi';
import { theme } from 'src/core/theme';
import { getArticleReport, openSpotifyPodcastLink } from 'src/core/utils';
import { ArticleMediaProps } from './types';
import React, { memo } from "react"
import { Platform, View, ActivityIndicator, StyleSheet } from 'react-native';
import WebView from 'react-native-webview';
import AutoImage from 'src/components/AutoImage';

const SpotifyPlugIn = ({ article }: ArticleMediaProps) => {
  const [spotyEpisodeNotFound, setSpotyEpisodeNotFound] = React.useState(false);
  const showId = article?.url;

  const embeded = Platform.OS === 'web' ? (
    <View style={styles.spotifyContainer}>
      <iframe
        src={`https://open.spotify.com/embed/episode/${showId}?utm_source=generator&theme=0`}
        width="100%"
        height="100%"
        allow="encrypted-media"
        frameBorder="0"
      />
    </View>
  ) : (
    <View style={styles.spotifyContainer}>
      <WebView
        overScrollMode="never"
        style={styles.spotifyWebView}
        startInLoadingState={true}
        renderLoading={() => (
          <ActivityIndicator size="large" style={styles.loadingIndicator} animating={true} color={theme.colors.primary[500]} />
        )}
        scalesPageToFit={true}
        scrollEnabled={false}
        javaScriptEnabled
        allowsAirPlayForMediaPlayback={true}
        injectedJavaScript={`
          try {
            document.getElementById("play-button").onclick = function() {
              window.ReactNativeWebView.postMessage("420");
            }
          } catch (e) {

          }
        }`}
        onMessage={() => ActionApi.createFrom({ name: ACTION_NAMES.SPOTIFY_PLAY, data: getArticleReport(article) })}
        onShouldStartLoadWithRequest={(req) => {
          if (req.url.startsWith("https://open.spotify.com/embed-podcast/") || req.url.startsWith('https://open.spotify.com/embed/episode/')) {
            return true;
          }
          openSpotifyPodcastLink(article);
          return false;
        }}
        onHttpError={() => setSpotyEpisodeNotFound(true)}
        containerStyle={{ flex: 1, minHeight: '100%' }}
        source={{
          uri: `https://open.spotify.com/embed/episode/${showId}?utm_source=generator&theme=0`
        }}
      />
    </View>
  );

  return spotyEpisodeNotFound ? <AutoImage source={{ uri: article.media.cover.url }} height={"100%"} width={"100%"} loadingSource={require('src/assets/icons/nty-square-loading.gif')} /> : embeded;
};

export default memo(SpotifyPlugIn);

const styles = StyleSheet.create({
  spotifyContainer: {
    width: '100%',
    height: '100%',
  },
  spotifyWebView: {
    backgroundColor: 'black',
  },
  loadingIndicator: {
    bottom: 20,
    position: 'absolute',
  },
})
