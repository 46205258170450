import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { memo } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ActivityIndicator, Divider } from 'react-native-paper';
// import NtyAlert, { NtyAlertType } from '../../../../components/NtyAlert';

import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
// import { theme } from '../../../../core/theme';
import { Navigation } from '../../../../types';
import { PromptStore, useAlert, usePrompt } from '../../../context/alertContext';
import { useFavouritesStore } from '../../../context/favoritesStore';
import { useFavouriteListOptionsStore } from '../../../context/optionStores';
import { useSnackBar } from '../../../context/snackBarStore';
import TextNty from '../../nty/text/TextNty';
import TitleNty from '../../nty/title/TitleNty';

// import * as MediaLibrary from 'expo-media-library';
// import * as Permissions from 'expo-permissions';

import useSessionStore from 'src/context/sessionStore';
import { DownloadResultNty } from '../../../../core/http-utils';
import { StackNavigationProp } from '@react-navigation/stack';
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens';


export type FavouriteListOptionsListProps = {
    hide?: () => void,
    show?: () => void,
    navigation?: StackNavigationProp<ScreensParamsList>;
}

/**
 *
 * This component is used to show common actions associated with an article.
 *
 * - Subscribe to newspaper
 * - Subscribe to author
 * - Save Newspaper
 * - Share article
 * - Open in external browser (only for devices)
 *
 */
const FavouriteListOptionsList = ({ navigation, show, hide }: FavouriteListOptionsListProps) => {


    if (navigation == undefined) {
        navigation = useNavigation<StackNavigationProp<ScreensParamsList>>();
    }

    const theme = useAppTheme()

    const alert = useAlert((store) => store.alert)
    const prompt = usePrompt((store: PromptStore<string>) => store.prompt)

    const { favourites, favouriteLists, turnDefault, sendEmail, generatePDF, renameList, emptyList, deleteList, downloadList, changeIcon } = useFavouritesStore(store => (
        {
            favouriteLists: store.favouriteLists,
            turnDefault: store.turnDefault,
            sendEmail: store.sendEmailFromFavlist,
            generatePDF: store.generatePDF,
            renameList: store.renameList,
            changeIcon: store.changeIcon,
            emptyList: store.emptyList,
            deleteList: store.deleteList,
            downloadList: store.downloadList,
            favourites: store.favourites
        }))



    const showInfoMessage = useSnackBar(store => store.showInfoMessage);


    // const { isEnterprise, user } = useAppSessionStore((store) => ({ user: store.user, isEnterprise: store.isEnterprise }));
    const enterprise = useSessionStore(store => store.enterprise)


    const list = useFavouriteListOptionsStore((store) => store.list);
    const listFavourites = React.useMemo(() => favourites?.filter((fav) => fav.list == list?._id), [list])
    const [emptyListLoading, setEmptyListLoading] = React.useState<boolean>(false);

    const _emptyList = async () => {

        setEmptyListLoading(true);


        alert({
            title: 'Vaciando \'' + list.name + '\'',
            message: 'Vas a el contenido de la lista, ¿estás seguro?. Esta acción no se puede deshacer.',
            buttons: [
                {
                    label: 'Vaciar Lista', onPress: async () => {

                        try {
                            await emptyList(list, showInfoMessage)
                        } finally {
                            setEmptyListLoading(false); hide ? hide() : null;
                        }
                    }
                }

            ]
        }).finally(
            () => setEmptyListLoading(false)
        )

    }

    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);

    const _deleteList = () => {

        setDeleteLoading(true)

        alert({
            title: 'Eliminando \'' + list.name + '\'',
            message: 'Vas a eliminar la lista y su contenido, ¿estás seguro?. Esta acción no se puede deshacer.',
            buttons: [
                {
                    label: 'Eliminar', onPress: async () => {

                        try {
                            await deleteList(list, showInfoMessage)
                        } finally {
                            setDeleteLoading(false); hide ? hide() : null;
                        }
                    }
                }
            ]
        }).finally(
            () => setDeleteLoading(false)
        )

    }

    const [renameLoading, setRenameLoading] = React.useState<boolean>(false);

    const _renameList = async () => {

        setRenameLoading(true);

        try {

            const result = await prompt({
                title: 'Renombrando: ' + list.name, validations: [
                    { function: (data) => favouriteLists?.find((list) => list.name == data) ? false : true, errorMessage: "El nombre ya existe en tus listas" },
                    { regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' }
                ]
            })

            if (result && result.data) {
                renameList(list, result.data, showInfoMessage)
            }

        } finally {
            setRenameLoading(false); hide ? hide() : null;
        }


    }
    const _changeIcon = async () => {

        setRenameLoading(true);

        try {

            const result = await prompt({
                title: 'Nuevo Icono: ', validations: [
                    { regex: /.{3,}/, errorMessage: 'Introduce al menos 3 caracteres' },
                    { regex: /(http|https)?:\/\/.{3,}/, errorMessage: 'Introduce una URL válida' }
                ]
            })

            if (result && result.data) {
                changeIcon(list, result.data, showInfoMessage)
            }

        } finally {
            setRenameLoading(false); hide ? hide() : null;
        }


    }

    // const [emailLoading, setEmailLoading] = React.useState<boolean>(false);

    const _sendEmail = async () => {


        navigation.navigate('sendNewsletter', { id: list._id })
        hide()

    }

    const _viewHistory = async () => {

        navigation.navigate('favlistNewsletterDispatch', { id: list._id })
        hide()

    }

    const [pdfLoading, setPdfLoading] = React.useState<boolean>(false);

    const _generatePDF = async () => {

        setPdfLoading(true)
        try {

            alert({
                title: 'Esta operación tiene coste asociado',
                message: 'Vas a generar un documento con los ' + listFavourites?.length + ' artículos de la lista.',
                buttons: [
                    {
                        label: 'Continuar', onPress: async () => {

                            try {
                                await generatePDF(list)
                            } catch (e) {
                                console.error(e)
                                useSnackBar.getState().showInfoMessage('Hubo un problema generando el PDF, inéntelo más tarde.')
                            }
                        }
                    },
                    { label: 'Cancelar', cancel: true }
                ]
            }).finally(
                () => setPdfLoading(false)
            )

            // await generatePDF(list)
        } finally {
            setPdfLoading(false); hide ? hide() : null;
        }

    }

    const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);
    const downloadCSV = async () => {

        let result: DownloadResultNty
        setDownloadLoading(true)
        try {

            result = await downloadList(list)

        } catch (e) {
            console.error(e)
        } finally {
            setDownloadLoading(false);
            if (result?.status != 200) {
                showInfoMessage('Hubo un problema descargando el archivo...')
            }
            hide ? hide() : null;
        }


    }


    // const test = async () => {


    //     const ffun = async (response_type) => {
    //         console.log('performing request...')
    //         const response = await fetch('https://jsonplaceholder.typicode.com/posts/1', {response_type: 'blob'})

    //         // console.log(response)

    //         switch (response_type) {
    //             case 'Response': return {type: response.type, status: response.status, ok: response.ok, statusText: response.statusText, headers: response.headers, url: response.url, bodyUsed: response.bodyUsed, text: response.text, json: response.json, blob: response.blob}
    //             case 'json': console.log(Object.keys(response)); return await  response.json();
    //             case 'text': return await response.text();
    //             case 'blob': return null
    //         }
    //     }

    //     ffun('Response').then((r) => console.log(r))
    //     // ffun('Response').then((r) => console.log(r))

    //     // ffun('text').then((r) => console.log(r))
    //     // ffun('blob').then((r) => console.log(r))

    //     // const response = await fetch('https://jsonplaceholder.typicode.com/posts/1')

    //         // console.log(response)

    // }

    const [turnDefaultLoading, setTurnDefaultLoading] = React.useState(false)

    const _turnDefault = async () => {

        setTurnDefaultLoading(true)
        try {
            await turnDefault(list, showInfoMessage)
        } finally {
            setTurnDefaultLoading(false); hide ? hide() : null;
        }
    }

    if (!list) return <View style={{ justifyContent: 'center' }}><TitleNty style={{ alignSelf: 'center' }}>No hay ninguna lista seleccionada</TitleNty></View>


    return (
        <>
            <View style={{ flex: 1, paddingHorizontal: 40, paddingVertical: 10, alignContent: 'center' }}>



                {!list.default &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={_deleteList}>


                            {deleteLoading ?
                                <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                                <MaterialCommunityIcons
                                    name="trash-can"
                                    color={theme.colors.danger[500]}
                                    size={25}
                                />
                            }
                            <TextNty style={[styles.text, { color: theme.colors.danger[500] }]}>Eliminar Lista</TextNty>

                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                }


                <>
                    <TouchableOpacity style={styles.itemContainer} onPress={_emptyList}>


                        {emptyListLoading ?
                            <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                            <MaterialCommunityIcons
                                name="delete-empty"
                                color={theme.colors.danger[400]}
                                size={25}
                            />
                        }
                        <TextNty style={[styles.text, { color: theme.colors.danger[500] }]}>Vaciar Lista</TextNty>

                    </TouchableOpacity >
                    <Divider style={styles.divider} />
                </>


                {!list.default &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={_turnDefault}>


                            {turnDefaultLoading ?
                                <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                                <MaterialCommunityIcons
                                    name="heart"
                                    color={theme.colors.text_primary[400]}
                                    size={25}
                                />
                            }
                            <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Convertir en 'Por defecto'</TextNty>

                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                }
                <>
                    <TouchableOpacity style={styles.itemContainer} onPress={_renameList}>


                        {renameLoading ?
                            <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                            <MaterialCommunityIcons
                                name="pencil"
                                color={theme.colors.text_primary[400]}
                                size={25}
                            />
                        }
                        <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Cambiar Nombre</TextNty>

                    </TouchableOpacity >
                    <Divider style={styles.divider} />
                </>
                <>
                    <TouchableOpacity style={styles.itemContainer} onPress={_changeIcon}>


                        {renameLoading ?
                            <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                            <MaterialCommunityIcons
                                name="image"
                                color={theme.colors.text_primary[400]}
                                size={25}
                            />
                        }
                        <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Cambiar Logo</TextNty>

                    </TouchableOpacity >
                    <Divider style={styles.divider} />
                </>


                {enterprise && enterprise.options?.newsletter?.active != false &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={_sendEmail}>



                            <MaterialCommunityIcons
                                name={"email"}
                                color={theme.colors.text_primary[400]}
                                size={25}
                            />

                            <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Enviar por email</TextNty>


                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                }
                {enterprise && enterprise.options?.newsletter?.active != false &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={_viewHistory}>

                            <MaterialCommunityIcons
                                name={"history"}
                                color={theme.colors.text_primary[400]}
                                size={25}
                            />

                            <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Historial de Envíos</TextNty>


                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                }


                {enterprise && enterprise.options?.pdf?.active &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={_generatePDF}>


                            {pdfLoading ?
                                <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                                <MaterialCommunityIcons
                                    name={"file-document"}
                                    color={theme.colors.text_primary[400]}
                                    size={25}
                                />
                            }

                            <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Descargar PDF</TextNty>


                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                }


                {enterprise &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={() => downloadCSV()} disabled={downloadLoading}>


                            {downloadLoading ?
                                <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                                <MaterialCommunityIcons
                                    name={"download"}
                                    color={theme.colors.text_primary[400]}
                                    size={25}
                                />
                            }

                            <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Descargar</TextNty>


                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                }

                {/* {isEnterprise &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={() => test()} disabled={downloadLoading}>


                            {downloadLoading ?
                                <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> :
                                <MaterialCommunityIcons
                                    name={"download"}
                                    color={theme.colors.text_primary[400]}
                                    size={25}
                                />
                            }

                            <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>Test</TextNty>


                        </TouchableOpacity >
                        <Divider style={styles.divider} />
                    </>
                } */}



            </View>
        </>
    );
}

const styles = StyleSheet.create({
    text: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
        marginLeft: 25
    },
    itemContainer: {
        maxHeight: 30,
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center'
    },
    divider: {
        marginBottom: 20
    }
})

export default memo(FavouriteListOptionsList);
