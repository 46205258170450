import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { Image, ImageStyle, Pressable, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Avatar } from 'react-native-paper'
import useSessionStore from 'src/context/sessionStore'
import { Brand, Publisher } from 'systemDomain'
import { useAppTheme } from '../../../core/theme/ThemeBuilder'
import TextNty from '../nty/text/TextNty'

export type PublisherBubbleEntity = Brand | Publisher | string;
export type BubbleProps = {
    /**
     * The entity to display, can be a Brand or a Publisher.
     *
     * If the entity is a string, it will be converted to a Publisher, but is not recommended.
     */
    publisher: PublisherBubbleEntity,
    size?: number,
    fontSize?: number,
    onPress?: (entity: PublisherBubbleEntity) => void,
    onLongPress?: (entity: PublisherBubbleEntity) => void,
    style?: ImageStyle,
    disabled?: boolean,
    showLabel?: boolean,
    labelStyle?: TextStyle,
    labelPosition?: 'top' | 'bottom' | 'left' | 'right'
}

/**
 *
 * This component is used to display a Publisher or a Brand as a bubble.
 *
 * @param props The props of the component.
 * @returns
 */
 function PublisherBubble({
    publisher: entity,
    size = 50,
    fontSize,
    onPress: onEntityPress,
    onLongPress: onEntityLongPress,
    style,
    disabled = false,
    showLabel,
    labelPosition = 'bottom',
    labelStyle,
}: BubbleProps) {

    if(entity == undefined) {

        return null

    }

    const theme = useAppTheme();

    const superUser = useSessionStore.getState().profile?.superUser

    const styles = StyleSheet.create({
        image: {
            width: size,
            height: size,
            resizeMode: 'cover'
        },
        bubble: {
            width: size,
            height: size,
            borderRadius: size / 2,
        }
    })

    const _bubblePressed = () => {
        onEntityPress && onEntityPress(entity);
    }

    const _bubbleLongPressed = () => {
        onEntityLongPress && onEntityLongPress(entity);
    }

    const viewStyle = React.useMemo<ViewStyle>(() => {
        switch (labelPosition) {
            case 'bottom': return { flexDirection: 'column', alignItems: 'center', width: size * 1.2 };
            case 'top': return { flexDirection: 'column-reverse', alignItems: 'center', width: size * 1.2 };
            case 'left': return { flexDirection: 'row-reverse', alignItems: 'center', height: size * 1.2 };
            case 'right': return { flexDirection: 'row', alignItems: 'center',  height: size * 1.2 };
        }
    }, [])

    let publisher: Publisher

    // If the entity is a Brand, convert it to a Publisher
    if(typeof entity == 'string') {
        publisher = {
            name: entity,
            description: '',
            keyName: entity,
            domain: '',
            sections: [],
            logo: '',
            publisher_type: 'unknown',
            brand: null,
            source: null,
            known_publisher: false,
            enterprise: false
        }
    }

    if (typeof entity == 'object') {
        publisher = {
            name: entity.name,
            description: entity.description,
            keyName: entity.keyName,
            domain: entity.domain,
            sections: entity.sections,
            logo: entity.logo,
            known_publisher: entity.known_publisher ?? false,
            siteName: entity.siteName ?? entity.name,
            publisher_type: entity.publisher_type ?? 'brand',
            brand: entity.brand ?? entity.keyName,
            enterprise: entity.enterprise ?? false
        }
    }

    const fontSizeI = fontSize ?? size / 2


    return (
        <View style={[viewStyle, style]}>

                <View style={[styles.bubble]}>
                    {superUser && publisher?.known_publisher === false ? <Avatar.Icon icon={'help'} size={10} style={{position: 'absolute', right: 2, top: 0, zIndex: 1000, backgroundColor: 'red'}} color='white'/> : null }
                    <Pressable
                        style={[{ width: size, height: size, backgroundColor: theme.colors.background[300], justifyContent: 'center', borderRadius: size / 2, overflow: 'hidden' }]}
                        onPress={!disabled && _bubblePressed}
                        onLongPress={!disabled && _bubbleLongPressed}
                        disabled={disabled}
                    >
                        {/* {entity.logo ? <Image source={{ uri: entity.logo, cache: 'force-cache' }} style={[styles.image]} /> : <MaterialCommunityIcons name={'newspaper'} size={size * 0.8} style={{ alignSelf: 'center' }} color={theme.colors.text_primary[400]} />} */}
                        {publisher.logo ? <Image source={{ uri: publisher.logo, cache: 'force-cache' }} style={[styles.image]} /> : publisher.domain ? <Image source={{ uri: `https://www.google.com/s2/favicons?sz=96&domain_url=${publisher.domain}`, cache: 'force-cache' }} style={[styles.image]} /> : <MaterialCommunityIcons name={'newspaper'} size={size * 0.8} style={{ alignSelf: 'center' }} color={theme.colors.text_primary[400]} />}
                    </Pressable>
                </View>
            {showLabel &&
                <View style={{ zIndex: 1000, padding: 5, justifyContent: 'center', alignContent: 'center' }}>
                    <TextNty
                    fontSize={fontSizeI}
                    style={[{

                        // lineHeight: (size / 6) + 3,
                        textAlign: 'center',
                        textAlignVertical: 'center',
                        position: 'relative',
                        fontFamily: theme.fonts.default.medium.fontFamily,
                    }, labelStyle]}>{publisher?.name?.length > 35 ? publisher?.name?.substring(0, 30) + '...' : publisher?.name}
                    </TextNty>
                </View>
            }
        </View>
    )
}


export default React.memo(PublisherBubble)
