import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { Dimensions, View } from 'react-native';
import { FlatList, TouchableOpacity } from "react-native-gesture-handler";
import { Checkbox, Divider } from "react-native-paper";
import useSessionStore from "src/context/sessionStore";
import { Article } from "systemDomain";
import ArticleApi from "../../../api/ArticleApi";
import { ScreensParamsList } from "../../../core/navigation/screens/NavigationScreens";
import { useAppTheme } from "../../../core/theme/ThemeBuilder";
import { containsSameStr, extractKeywordsFromFeed } from "../../../core/utils";
import Background from "../../Background";
import ArticleTitle from "../../components/article/cards/ArticleTitle";
import LoadingIndicator from "../../components/loaders/Loading";
import ButtonNty from "../../components/nty/button/ButtonNty";
import TextNty from "../../components/nty/text/TextNty";
import TitleNty from "../../components/nty/title/TitleNty";
import { useSnackBar } from "../../context/snackBarStore";


export type ArticleFeedsPageProps = StackScreenProps<ScreensParamsList, 'editArticleFeed'>;



const ArticleFeedsPage = ({ navigation, route }: ArticleFeedsPageProps) => {


    const [article, setArticle] = React.useState<Article>()

    const [initial_feeds, setInitialFeeds] = React.useState([])
    const [editing_feeds, setEditingFeeds] = React.useState<string[]>([])

    const [loadingArticle, setLoadingArticle] = React.useState(true)

    // const { user, feeds } = useAppSessionStore((store) => ({ user: store.user, feeds: store.enterpriseFeeds }))
    const {feeds} = useSessionStore(store => ({feeds: store.feeds}))


    const loadArticle = async (article_id: string) => {

        let article: Article;

        setLoadingArticle(true)
        try {
            article = await ArticleApi.read(article_id)
        } catch (e) {
            console.warn(e)
        } finally {
            setLoadingArticle(false)
        }
        if (article) {
            setInitialFeeds(article.feeds ?? [])
            setEditingFeeds(article.feeds ?? [])
            setArticle(article)

        }

    }

    const showMessage = useSnackBar.getState().showInfoMessage


    const save_changes = async () => {

        // @ts-ignore
        const result = await ArticleApi.update(article._id, { feeds: editing_feeds })
        console.log({ sent_update: editing_feeds, initial_feeds, update: result.feeds })
        showMessage('Cambios Guardados')
        navigation.goBack()

    }



    React.useEffect(() => {

        let article = route.params.article_id
        loadArticle(article)

    }, [route.params.article_id])

    function toggleFeed(feed_id: string) {
        const index = editing_feeds.indexOf(feed_id)

        const newFeeds = [...editing_feeds]

        if (index != undefined && index != -1) {
            console.log('deleting');
            newFeeds.splice(index, 1)
            setEditingFeeds(newFeeds)
            return 'unchecked'
        } else {
            console.log('adding');
            newFeeds.push(feed_id);
            setEditingFeeds(newFeeds)
            return 'checked'
        }
    }

    const hasChanges = React.useMemo(() => {
        console.log('Calculating changes...', {initial_feeds, editing_feeds})
        const result = !containsSameStr(initial_feeds, editing_feeds)
        console.log('hasChanges: ', result)
        return result
    }, [editing_feeds])


    return <Background>

        <View style={{ marginVertical: 10, paddingHorizontal: 30, paddingVertical: 10 }}>

            {loadingArticle || !article ? <LoadingIndicator /> : <ArticleTitle article={article} disabled={true} />}

        </View>


        {loadingArticle || !article ? <LoadingIndicator /> :
            <FlatList

                data={feeds}
                keyExtractor={(f) => f._id}

                renderItem={({ item }) => {


                    return <FeedItem feed={item} article_feeds={editing_feeds} toggleFeed={toggleFeed} />

                }}
                ItemSeparatorComponent={() => <Divider />
                }
            >

            </FlatList>}

        {hasChanges && <ButtonNty mode="floatingBottom" onPress={() => save_changes()}>Guardar Cambios</ButtonNty>}

    </Background>

}


export default React.memo(ArticleFeedsPage)



const FeedItem = ({ feed, article_feeds, toggleFeed }) => {

    const theme = useAppTheme()

    const [checked, setChecked] = React.useState(article_feeds.indexOf(feed._id) != -1 ? 'checked' : 'unchecked')

    const LIST_HEADER_HEIGHT = 150
    const ITEM_HEIGHT = (Dimensions.get('window').height - LIST_HEADER_HEIGHT) / 10

    return <TouchableOpacity style={{ height: ITEM_HEIGHT, flexDirection: 'row', paddingHorizontal: 20, alignItems: 'center' }} onPress={() => setChecked(toggleFeed(feed._id))}>

        {/* <View nativeID="icon">

                    </View> */}

        <View nativeID="textContent" style={{ flex: 5, flexDirection: 'column' }}>

            <TitleNty style={{ marginBottom: 5 }}>{feed.name}</TitleNty>
            <TextNty>{extractKeywordsFromFeed(feed).slice(0, 3).join(", ") + '...'}</TextNty>

        </View>

        <View nativeID="addedButton">

            <View style={{ borderRadius:25, borderWidth: 1.5, borderColor: theme.colors.primary[500],  backgroundColor: checked == 'checked' ? theme.colors.primary[500]: 'transparent'}}>

                <Checkbox color={theme.colors.background[200]} status={checked} />
            </View>

        </View>

    </TouchableOpacity>
}
