import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useEffect } from "react";
import { useAppTrialStore } from "src/context/appTrialStore";
import useSessionStore from "src/context/sessionStore";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";

export const useLogInControl = () => {
    // const { isEnterprise, user } = useAppSessionStore(store => ({
    //     isEnterprise: store.isEnterprise,
    //     user: store.user
    // }));
    // const isGuest = useAppSessionStore.getState().isGuest;
    const {enterprise, isGuest} = useSessionStore(store => ({ enterprise: store.enterprise, isGuest: store.isGuest }));
    const isTrialActive = useAppTrialStore.getState().isTrialActive;
    // const alertState = useAlert.getState();
    const navigator = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>();

    const logoutRoutes = ['logout', 'login', 'register', 'registerEnterprise', 'guest', 'guest-welcome'];

    const testLoginControl = async () => {
        const routeName = navigator.getState().routes[navigator.getState().index].name;
        const shouldLogout = ((isGuest && !isTrialActive) || (!isGuest && !enterprise)) && logoutRoutes.indexOf(routeName) === -1;

        console.log('Testing if we should logout', {
            isEnterprise: Boolean(enterprise),
            isGuest: isGuest,
            routeName,
            isTrialActive: isTrialActive,
            shouldLogout
        });

        if (shouldLogout) {
            try {
                navigator.reset({ index: 0, routes: [{ name: 'login' }] });

            } catch (e) {
                console.error("Error showing alert:", e);
            } finally {
                console.log('Reseting navigator');
            }
        }
    };

    useEffect(() => {
        testLoginControl();
    }, [enterprise]);

    return null;
};
