import { create } from "zustand";

export type DatePromptStore = {
    visible: boolean,
    date: Date,
    options: DatePromptOptions,
    setDate: (date: Date) => void
    handleCancel: () => void,
    handleOk: (date: Date) => void,
    promptDate: (options?: DatePromptOptions) => Promise<Date>,
    setVisible: (visible?: boolean) => void
}


export type CommonDateModes = 'date' | 'time';
export type CommonDisplayModes = 'default' | 'spinner';

export type DatePromptOptions = {
    /**
     * The date picker mode. default: 'date'
     */
    mode?: CommonDateModes,
    /**
     * The date picker display mode. default: 'default'
     */
    display?: CommonDisplayModes,
    /**
     * The date picker title label. default: 'Selecciona una fecha'
     */
    title?: string,
    /**
     * a range of valid dates. default: no range
     */
    validDates?: {
        from?: Date,
        to?: Date
    }
    onCancel?: () => void,
    onOk?: () => void
}

export const DEFAULT_DATE_PROMPT_OPTIONS: DatePromptOptions = {
    mode: 'date',
    display: 'default',
    title: 'Selecciona una fecha',
}

export const DEFAULT_TIME_PROMPT_OPTIONS: DatePromptOptions = {
    mode: 'time',
    display: 'spinner',
    title: 'Selecciona una hora',
}

export const useDatePrompt = create<DatePromptStore>((set, get) => ({
    visible: false,
    date: new Date(),
    range: {
        from: null,
        to: null
    },
    handleCancel: () => set({ visible: false, date: new Date() }),
    handleOk: (date) => set({ date: date, visible: false }),
    options: DEFAULT_DATE_PROMPT_OPTIONS,
    promptDate: async (options?: DatePromptOptions) => {

        const def = options.mode === 'time' ? DEFAULT_TIME_PROMPT_OPTIONS : DEFAULT_DATE_PROMPT_OPTIONS
        options = { ...def, ...options }

        set({ visible: true, options, date: new Date() })
        let handleCancel, handleOk
        const promise = new Promise<Date>((resolve, reject) => {
            const { onCancel, onOk } = options || {}

            handleCancel = () => {
                set({ visible: false, date: null })
                if (onCancel) onCancel()
                reject('cancelled')
            }
            handleOk = (date) => {

                console.log('selectedDate', { date })
                set({ date: date, visible: false })
                if (onOk) onOk()
                resolve(date)
            }

        })
        console.log({ handleCancel, handleOk, date: get().date })
        set({ handleCancel, handleOk })
        return promise
    },
    setDate: (date: Date) => {
        set({ date: date })
    },
    setVisible: (visible?: boolean) => {
        if (visible === undefined) visible = !get().visible
        set({ visible: visible })
    }
}));
