import React, { MutableRefObject, RefObject } from 'react';
import { Animated, FlatList, Image, Insets, ListRenderItemInfo, NativeSyntheticEvent, PointPropType, useWindowDimensions, View, ViewStyle } from 'react-native';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { theme } from '../../../core/theme';
import { containsSame } from '../../../core/utils';
import TitleNty from '../nty/title/TitleNty';


export type TabsNativeDefinition = {
    key: string,
    title: string
    backgroundImage?: string;
    /** Ref to be used in order to get measures of the tab when is mounted */
    ref: RefObject<View>,
    /** Additional parameters for the tab ```renderTabItem``` function */
    data?: any,
    /** Ref to be used in order to access the parent flatList methods */
    tabsFlatListRef?: MutableRefObject<FlatList>

}



/**
* Props for Tabs Component, try creating an object implementing this interface to know more about the parameters
*/
export type TabsNativeProps = {

    /** Tab Definitions. Could be a complex tab definition or a simple array of strings, where each item represents one tab that should be displayed. */
    tabsInfo: TabsNativeDefinition[] | string[],
    /**
     *  Function to be passed as a renderItem property to the FlatList, the `info.item` object will always be `TabsNativeDefinition`
     * ```
     * _renderItem = (info) => (
     *   <TouchableOpacity onPress={() => this._onPress(params.item)}>
     *     <Text>{params.item.label}</Text>
     *     <Text>{params.item.key}</Text>
     *     <Text>{params.item.name}</Text>
     *   <TouchableOpacity/>
     * );
     * ...
     * <FlatList data={[{title: 'Title Text', key: 'item1'}]} renderItem={this._renderItem} />
     * ```
     */
    renderTabItem?: (info: ListRenderItemInfo<TabsNativeDefinition>) => React.ReactElement<any, string | React.JSXElementConstructor<any>>,

    /**
     * Default is 100.
     * This number represents the time (in miliseconds) of the interval that's set to fire the onScroll event __for the horizontal flatlist__
     */
    scrollEventThrottle?: number,

    /** Styles to be used in the FlatList.contentContainerStyle prop */
    contentContainerStyle?: ViewStyle,

    /** Method to be passed to the onScroll of the horizontal FlatList.
    *  Passing a parameter would NOT override default implementation.
    */
    onScroll?: (event: NativeSyntheticEvent<unknown>) => void;

    /**
     * Reference for the horizontal flatlist, it can be passed from outside if you want to link TabsComponent and TabsHeader from outside TabsComponent
     */
    ref?: MutableRefObject<FlatList>,

    /**
     * FlatList Content Inset
     */
    contentInsets?: Animated.WithAnimatedObject<Insets>

    /**
     * FlatList Content Offset. Offset for the scroll in FlatList.
     */
    contentOffset?: Animated.WithAnimatedObject<PointPropType>

    /**
     * Event emitter that gets triggered when the tab index changes.
     *
     * @param index The tab index that is now visible
     * @param tab the {@link TabDefinitionInternal} that is now visible
     *
     */
    onTabIndexChanged?: (index: number, tab: TabsNativeDefinition) => void,

    /**
     * Boolean flag to show the header within the tabs component.
     */
    tabsHeader?: boolean
}


function TabsNative<T>(props: TabsNativeProps) {


    const flatListRef = props.ref ?? React.useRef<Animated.FlatList>();

    const { width, height } = useWindowDimensions();

    const defaultRenderTabItem = (info: ListRenderItemInfo<TabsNativeDefinition>) => {
        return (
            <View style={{ width, height }}>
                {
                    info.item.backgroundImage ?
                        <Image style={{ flex: 1, resizeMode: 'cover' }} source={{ uri: info.item.backgroundImage }}></Image>
                        :
                        <TitleNty style={{ alignSelf: 'center' }}>{info.item.title}</TitleNty>
                }
            </View>
        )
    }

    const [tabsDefinition, setTabsDefinition] = React.useState<TabsNativeDefinition[]>();


    const scrollXInternal = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {

        let tabs;

        if (props.tabsInfo && props.tabsInfo.length && typeof props.tabsInfo[0] == 'string') {
            tabs = props.tabsInfo.map<TabsNativeDefinition>(tabName => ({ title: tabName, key: tabName + Date.now(), ref: React.createRef<View>() }))
        } else if (props.tabsInfo && props.tabsInfo.length) {
            tabs = props.tabsInfo;
        }

        if (!tabsDefinition || (tabsDefinition && !containsSame(tabs, tabsDefinition))) {

            setTabsDefinition(tabs)

        }


    }, [props.tabsInfo]);



    return (
        <>
            <Animated.FlatList

                ref={flatListRef}
                showsHorizontalScrollIndicator={false}
                horizontal
                pagingEnabled
                data={tabsDefinition}
                keyExtractor={item => item.key}
                renderItem={props.renderTabItem ?? defaultRenderTabItem}
                onScroll={props.onScroll ?? Animated.event(
                    [{ nativeEvent: { contentOffset: { x: scrollXInternal } } }],
                    { useNativeDriver: false }
                )}

            />

            {props.tabsHeader && tabsDefinition &&

                <TabsNativeHeader
                    tabsDefinition={tabsDefinition}
                    headerType={'labels'}
                    scrollX={scrollXInternal}
                    flatListRef={flatListRef}
                    headerContainerStyle={{ position: 'absolute', top: 50 }}
                />
            }

            {/* {props.tabsHeader
                && <TabsHeader
                    headerType={tabsHeader}
                    scrollX={scrollX}
                    tabsDefinition={tabsDefinition}
                    onItemPress={onItemPress}
                    scrollHeader={scrollHeader}
                    headerContainerStyle={headerContainerStyle}
                    indicatorColor={indicatorColor}
                    flatListContainerWidth={width}
                    indicatorWidth={indicatorWidth}
                    tabTextStyle={tabTextStyle}
                />} */}
        </>
    );

}

export type TabsNativeHeaderProps = {

    headerType: 'labels' | 'none',
    /** Animated value that would be interpolated by the header indicator in order to change position and width */
    scrollX: Animated.Value,
    /** The tabs info */
    tabsDefinition: TabsNativeDefinition[],
    /** Function wich recieves the index of the tab clicked in order to perform operations outside the component */
    onItemPress?: (index: number) => void,
    /** Flag to toggle scroll behaviour on 'labels' header, default is false */
    scrollHeader?: boolean,
    /** Custom style to merge with the default styling of the header component container view */
    headerContainerStyle?: Animated.WithAnimatedObject<ViewStyle>,
    /**
     * Function executed each time the header changes the selected tab
     */
    onTabIndexChanged?: (index: number) => void,

    /** FlatList reference, mandatory*/
    flatListRef: MutableRefObject<FlatList<any>>

}

export function TabsNativeHeader(props: TabsNativeHeaderProps) {


    const { width } = useWindowDimensions();

    const [currentTabIndex, setCurrentTabIndex] = React.useState<number>(0);

    const renderTabHeaderItem = (tab: TabsNativeDefinition, index: number) => {

        return (

            <View ref={tab.ref} key={tab.key} >
                <TouchableOpacity style={{ paddingHorizontal: 10 }} onPress={() => onTabPressed(tab, index)}>
                    <TitleNty style={{color: currentTabIndex == index ? theme.colors.ntyColor : theme.colors.text}}>{tab.title}</TitleNty>
                </TouchableOpacity>

            </View>

        )

    }


    const onTabPressed = (tab: TabsNativeDefinition, index: number) => {

        const slideTo = index == 0 ? measures[index] : measures[index - 1];

        try {
            containerRef.current.scrollTo({ x: slideTo.x, animated: true })
            props.flatListRef.current.scrollToIndex({index, animated: true})
        } catch (e) {
            console.warn(e);
        }

    }

    const containerRef = React.useRef<ScrollView>();
    const [measures, setMeasures] = React.useState<{ x, y, width, height }[]>();

    React.useEffect(() => {
        if (props.scrollX)
            props.scrollX.addListener((state) => {

                const offsetX = state.value;
                const mod = offsetX % width;
                if (containerRef.current && (mod < 1 || mod == 0)) {

                    const tab = Math.floor(offsetX / width);
                    console.log(tab)
                    if (currentTabIndex !== tab) {
                        setCurrentTabIndex(tab)
                        console.log('Tab:' + tab)
                        const slideTo = tab == 0 ? measures[tab] : measures[tab - 1];
                        try {
                            containerRef.current.scrollTo({ x: slideTo.x, animated: true })
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                }
            })


        return () => {
            props.scrollX?.removeAllListeners();
        }


    }, [props.tabsDefinition, measures, currentTabIndex])

    React.useEffect(() => {

        let m = [];
        props.tabsDefinition.forEach((tab) => {

            tab.ref.current.measureLayout(containerRef.current, (x, y, width, height) => {

                console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`);

                m.push({ x, y, width, height });
                if (m.length === props.tabsDefinition.length) {
                    setMeasures(m);
                }

            }, null);

        })

    }, [props.tabsDefinition])

    return (
        <Animated.View style={[{ flexDirection: 'row', justifyContent: 'center', width: width }, props.headerContainerStyle]}>

            <ScrollView horizontal ref={containerRef} showsHorizontalScrollIndicator={false}>
                {props.tabsDefinition.map((tab, index) => renderTabHeaderItem(tab, index))}
                {measures && <TabsNativeIndicator scrollX={props.scrollX} measures={measures} tabsDefinition={props.tabsDefinition} />}
            </ScrollView>

        </Animated.View>
    )

}

export type TabsNativeIndicatorProps = {
    scrollX: Animated.Value
    measures: { x, y, width, height }[]
    tabsDefinition: TabsNativeDefinition[]
}


export function TabsNativeIndicator(props: TabsNativeIndicatorProps) {

    const { width } = useWindowDimensions();

    const inputRange = props.tabsDefinition.map((_, i) => i * width);

    const indicatorWidth = props.scrollX.interpolate({
        inputRange,
        outputRange: props.measures.map(m => m.width)
    })

    const indicatorX = props.scrollX.interpolate({
        inputRange,
        outputRange: props.measures.map(m => m.x)
    })


    return (
        <Animated.View
            style={{
                position: 'absolute',
                height: 6,
                borderRadius: 3,
                backgroundColor: theme.colors.ntyColor,
                bottom: -2,
                left: indicatorX,
                zIndex: 100,
                width: indicatorWidth
            }}
        />

    )

}


/**
 * Using the WrappedTab component in order to fordward the `MutableRefObject` to the downside component without losing it's reference in memmory
 */
 const WrappedNativeTabsComponent = React.forwardRef((props: TabsNativeProps, ref: React.MutableRefObject<FlatList>) => {
    //In this case we dont use the 'ref' property, as the MutableRefObject created from the outside is going to be passed in the 'forwardedRef' property
    return <TabsNative {...props} ref={ref} />
  })

export default WrappedNativeTabsComponent
