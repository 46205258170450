import { MaterialCommunityIcons } from '@expo/vector-icons';
import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { memo } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { ActivityIndicator, Divider } from 'react-native-paper';
import { Article, EnterpriseFeedV2 } from 'systemDomain';
import ActionApi, { ACTION_NAMES } from '../../../../api/ActionApi';
import ArticleApi from '../../../../api/ArticleApi';
import HighlightApi from '../../../../api/HighlightApi';
import { ScreensParamsList } from '../../../../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import { getArticleReport } from '../../../../core/utils';

import useSessionStore from 'src/context/sessionStore';

import { useAlert } from '../../../context/alertContext';
import { useArticleOptionsStore } from '../../../context/optionStores';
import { useSnackBar } from '../../../context/snackBarStore';
import ButtonNty from '../../nty/button/ButtonNty';
import TextNty from '../../nty/text/TextNty';
import TitleNty from '../../nty/title/TitleNty';



export type ArticleEnterpriseAdminOptionsProps = {
    /**
     * Optional.
     *
     * Use this parameter to make this instance of ArticleOptionsList work for a specific article object.
     * If not passed, the article in {@link useArticleOptionsStore} would be used.
     */
    article?: Article;
    hide?: () => void,
    show?: () => void,
    navigation?: StackNavigationProp<ScreensParamsList, null, undefined>
}

/**
 *
 * This component is used to show common actions associated with an article that are supposed to be accesed only for administrators of an enterprise
 *
 */
const ArticleEnterpriseAdminOptionsList = ({ navigation, show, hide, article: articleParam = null }: ArticleEnterpriseAdminOptionsProps) => {

    if (navigation == undefined) {
        navigation = useNavigation();
    }

    const theme = useAppTheme();

    // const { user, enterprise, enterpriseFeeds } = useAppSessionStore();
    const { enterprise, enterpriseFeeds } = useSessionStore(store => ({ enterprise: store.enterprise, enterpriseFeeds: store.feeds }))
    const { alert, hide: hideAlert } = useAlert((store) => ({ alert: store.alert, hide: store.hide }))
    const [loading, setloading] = React.useState(false)

    const showInfoMessage = useSnackBar(store => store.showInfoMessage)

    // const action = React.useRef<'highlight' | 'article' | 'custom_highlight'>(null)

    const article = articleParam ? articleParam : useArticleOptionsStore((store) => store.article);

    const articleId = article?._id;

    const createEnterpriseHighlight = async () => {
        setloading(true)
        try {
            const highlight = await HighlightApi.createFrom({ enterprise: enterprise.key }, articleId)
            ActionApi.createFrom({ name: ACTION_NAMES.CREATE_HIGHLIGHT, data: { title: highlight.props.slideshow, url: highlight.props.url, ...getArticleReport(highlight.props.article) } })
            showInfoMessage(`Enviado a portada`)
        } catch (e) {
            console.error(e)
            alert({ title: 'Hubo un error inesperado', message: 'Inténtalo más tarde...' })

        }
        setloading(false)
        hide()
    }

    // const addToFeed = async (feed: EnterpriseFeedV2) => {

    //     setloading(true)
    //     try {
    //         const updated = await ArticleApi.addToFeed(articleId, feed._id)
    //         useArticleOptionsStore.getState().setArticle(updated)
    //         showInfoMessage(`Añadido a '${feed.name}'`)

    //     } catch (e) {
    //         console.error(e)
    //         alert({ title: 'Hubo un error inesperado', message: 'Inténtalo más tarde...' })
    //     }
    //     setloading(false)
    // }


    // const createCustomHighlightForFeed = (f: EnterpriseFeedV2) => {
    //     setloading(true)
    //     try {
    //         // @ts-expect-error
    //         navigation.navigate('createHighlightFrom', { id: articleId, enterprise: enterprise, feedId: f._id, feedName: f.name })
    //     } catch (e) {
    //         alert({ title: 'Hubo un error inesperado', message: 'Inténtalo más tarde...' })
    //     }
    //     setloading(false)
    // }


    // const feedAction = (f: EnterpriseFeedV2) => {
    //     switch (action.current) {
    //         // case "article": addToFeed(f); break;
    //         // case "highlight": addHihglightToFeed(f); break;
    //         case "custom_highlight": createCustomHighlightForFeed(f); break;
    //     }
    //     hide ? hide() : null;
    // }

    // const showFeedSelector = () => {

    //     const Component = Platform.OS == 'web' ? ScrollView : BottomSheetScrollView

    //     alert({
    //         title: 'Selecciona una Feed',
    //         message: <View style={{ height: 300 }}>
    //             <Component contentContainerStyle={{ paddingTop: 15 }} >
    //                 {enterpriseFeeds?.map(f => <ButtonNty mode='outlined' key={f._id} labelMergeStyles={{ paddingVertical: 10 }} mergeStyles={{ marginVertical: 5 }} onPress={() => { feedAction(f); hideAlert() }} >{f.name}</ButtonNty>)}
    //             </Component>
    //         </View>
    //         ,
    //         buttons: [
    //             { label: 'Cancelar', cancel: true }
    //         ]
    //     })

    // }

    const deleteFromEnterprise = async () => {

        const erase = async () => {

            const enterprises: string[] = article.enterprises ?? []
            const feeds: string[] = article.feeds ?? []
            const feedIds = enterpriseFeeds.map(e => e._id)

            const newEnterprises = enterprises.filter((e) => e != enterprise.key)
            const newFeeds = feeds.filter((e) => feedIds.indexOf(e) == -1)


            await ArticleApi.update(article._id, { feeds: newFeeds, enterprises: newEnterprises })
            showInfoMessage('Eliminado correctamente. Refresca para ver los cambios.')

        }

        alert({
            title: 'Borrar Artículo',
            message: 'Vas a elminiar el artículo. Este artículo ya no aparecerá en ' + enterprise.name + '. ¿Estás seguro?',
            buttons: [
                { label: 'Continuar', onPress: () => { erase() } }
            ]
        }).then((r) => {
            hide ? hide() : null
        })

    }


    if (!article) return <View style={{ flex: 1, justifyContent: 'center' }}><TitleNty style={{ alignSelf: 'center' }}>No se ha selecionado un artículo</TitleNty></View>
    if (!enterprise) return <View style={{ flex: 1, justifyContent: 'center' }}><TitleNty style={{ alignSelf: 'center' }}>No estás en modo empresa</TitleNty></View>

    return (
        <>
            {loading &&
                // @ts-expect-error
                <ActivityIndicator color={theme} size={50} style={{ position: 'absolute', top: "10%", alignSelf: 'center' }} />}
            <View style={{ flex: 1, paddingHorizontal: 40, paddingTop: 15, paddingBottom: 30 }}>

                <TitleNty style={{ marginBottom: 20 }}>Opciones para: {enterprise?.name}</TitleNty>

                <TouchableOpacity style={styles.itemContainer} onPress={() => { createEnterpriseHighlight() }}>

                    <MaterialCommunityIcons
                        name="star-circle"
                        color={theme.colors.primary[500]}
                        size={25}
                    />

                    <TextNty style={styles.text}>Enviar a portada</TextNty>

                </TouchableOpacity>

                <Divider style={styles.divider} />

                {/* <TouchableOpacity style={styles.itemContainer} onPress={() => { action.current = 'custom_highlight'; showFeedSelector() }}>

                    <MaterialCommunityIcons
                        name="pencil"
                        color={theme.colors.primary[400]}
                        size={25}
                    />

                    <TextNty style={styles.text}>Destacar en una feed</TextNty>

                </TouchableOpacity>

                <Divider style={styles.divider} /> */}



                <TouchableOpacity style={styles.itemContainer} onPress={() => { navigation.navigate('editArticleFeed', { article_id: article._id }); hide ? hide() : null }}>

                    <MaterialCommunityIcons
                        name="plus-circle"
                        color={theme.colors.primary[400]}
                        size={25}
                    />

                    <TextNty style={styles.text}>Enviar a feed</TextNty>

                </TouchableOpacity>

                <Divider style={styles.divider} />

                <TouchableOpacity style={styles.itemContainer} onPress={() => { deleteFromEnterprise() }}>

                    <MaterialCommunityIcons
                        name="trash-can"
                        color={theme.colors.danger[500]}
                        size={25}
                    />

                    <TextNty style={styles.text}>Borrar de {enterprise.name}</TextNty>

                </TouchableOpacity>

                <Divider style={styles.divider} />




            </View>



        </>
    );
}


const styles = StyleSheet.create({
    text: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
        marginLeft: 25
    },
    itemContainer: {
        maxHeight: 30,
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center'
    },
    divider: {
        marginVertical: 20
    }
})

export default memo(ArticleEnterpriseAdminOptionsList);

