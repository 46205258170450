import { useNavigation } from '@react-navigation/native'
import React from 'react'
import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'
import ArticleOptionsList from '../../../components/article/options/ArticleOptionsList'
import { useArticleOptionsStore } from '../../optionStores'

function ArticleOptionsModal() {

    const navigation = useNavigation()
    const { modalVisible, setModalVisible } = useArticleOptionsStore()

    const refArticleOptions = React.useRef<NewsToYouSheetModalHandle>()

    const visibleRef = React.useRef(false)

    const hideModal = () => {

        visibleRef.current = false;
        refArticleOptions.current.hide()
        setModalVisible(false)

    }
    const showModal = () => {

        visibleRef.current = true;
        refArticleOptions.current.show()
        setModalVisible(true)

    }

    React.useEffect(() => {
        // console.log({visible, visibleRef: visibleRef.current})
        if (modalVisible == true && visibleRef.current == false) {
            console.log('showing')
            showModal()
        } else if(modalVisible == false && visibleRef.current == true){
            console.log('closing')
            hideModal()
        }
    }, [modalVisible])

    return (
        <NtySheetModal ref={refArticleOptions} scrollableContent={true} onClose={()=>hideModal()}>
            <ArticleOptionsList
                hide={() => hideModal()}
                navigation={navigation}
            />
        </NtySheetModal>
    )
}

export default ArticleOptionsModal
