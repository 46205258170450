import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { Platform, Pressable, ScrollView, View } from 'react-native'
import { Avatar, Chip, IconButton } from 'react-native-paper'
import FavouriteListApi from 'src/api/FavouriteListApi'
import Background from 'src/app/Background'
import { useAlert, usePrompt } from 'src/app/context/alertContext'
import { useFavouritesStore } from 'src/app/context/favoritesStore'
import useSessionStore from 'src/context/sessionStore'
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens'
import { FavouriteListInterface } from 'systemDomain'
import { create } from 'zustand'
import LoadingIndicator from '../../loaders/Loading'
import NtyTextInputRound from '../../nty/TextInput/TextInputNtyRound'
import ButtonNty from '../../nty/button/ButtonNty'
import TitleNty from '../../nty/title/TitleNty'
import ContactCard from '../../profile/contacts/ContactCard'

import ProfileApi from 'src/api/Profile.api'
import { useAppTheme } from 'src/core/theme/ThemeBuilder'
import { StackNavigationProp } from '@react-navigation/stack'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useSnackBar } from 'src/app/context/snackBarStore'
import { fixed_colors } from 'src/core/theme/colors'

type sendNewsletterStoreType = {
    to: string[],
    cco: string[],
    subject: string,
    addTo: (contact: string) => void,
    setTo: (contacts: string[]) => void,
    deleteTo: (contact: string) => void,
    addCco: (contact: string) => void,
    setCco: (contacts: string[]) => void,
    deleteCco: (contact: string) => void,
    setSubject: (subject: string) => void,
    reset: () => void
}

const sendNewsletterStore = create<sendNewsletterStoreType>((set) => ({
    to: [],
    cco: [],
    subject: '',
    addTo: (contact: string) => {
        if (!sendNewsletterStore.getState().cco.includes(contact) && !sendNewsletterStore.getState().to.includes(contact)) {
            set((state) => ({ to: [...state.to, contact] }))
        }
    },
    setTo: (contacts: string[]) => set({ to: contacts }),
    setCco: (contacts: string[]) => set({ cco: contacts }),
    deleteTo: (contact: string) => set((state) => ({ to: state.to.filter((c) => c != contact) })),
    addCco: (contact: string) => {
        if (!sendNewsletterStore.getState().cco.includes(contact) && !sendNewsletterStore.getState().to.includes(contact)) {
            set((state) => ({ cco: [...state.cco, contact] }))
        }
    },
    deleteCco: (contact: string) => set((state) => ({ cco: state.cco.filter((c) => c != contact) })),
    setSubject: (subject: string) => set({ subject }),
    reset: () => set({ to: [], cco: [], subject: '' })
}))

// const outlookSegmentRegex = /(?:\"?([^\"<>]+?)\"?\s*<)?([^\"<>;,\s@]+@[^\"<>;,\s@]+)>?/;

// const parseOutlookContacts = (input: string) => {
//     const contacts: { name?: string; email: string }[] = [];
//     const segments = input.split(/[,;]/);

//     for (const segment of segments) {
//         const trimmedSegment = segment.trim();
//         if (!trimmedSegment) continue; // Ignora segmentos vacíos

//         const match = outlookSegmentRegex.exec(trimmedSegment);
//         if (match) {
//             let name = match[1]?.trim();
//             const email = match[2].trim();

//             if (name === email) {
//                 name = undefined;
//             }

//             if (email) {
//                 contacts.push({ ...(name && { name }), email });
//             }
//         }
//     }

//     return contacts;
// };

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const outlookFormatRegex = /(?:\"?([^\"<>]*)\"?\s*<)?([^\"<>;,\s@]+@[^\"<>;,\s@]+)>?/;

const isValidEmail = (email) => {
    return emailRegex.test(email);
};

const parseOutlookContacts = (input) => {
    const contacts = [];
    const segments = input.split(/[,;]/);

    for (const segment of segments) {
        const trimmedSegment = segment.trim();
        if (!trimmedSegment) continue;

        const match = outlookFormatRegex.exec(trimmedSegment);
        if (match) {
            let name = match[1]?.trim();
            const email = match[2].trim();

            if (name === email) {
                name = undefined;
            }

            if (email && isValidEmail(email)) {
                contacts.push({ ...(name && { name }), email });
            }
        } else if (isValidEmail(trimmedSegment)) {
            contacts.push({ email: trimmedSegment });
        }
    }

    return contacts;
};


const ContactsSelector = (props: { dest: string }) => {

    const sendStore = sendNewsletterStore()

    const { profile, addContact, deleteContact } = useSessionStore()

    const addProfileContacts = async (dest) => {
        const response = await usePrompt.getState().prompt<string>({
            title: 'Introduce emails de los contactos',
            // message: 'Los emails deben ir separados por comas.',
            validations:
                [
                    { regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' },
                    {
                        function: (input) => {
                            const contacts = parseOutlookContacts(input ?? '')
                            if (contacts.length == 0) {
                                return false
                            }
                            return true
                        }, errorMessage: 'Puedes copiar desde Outlook. Los emails deben ir separados por comas o puntos y comas. ej: `"example@thenewstoyou.com" <example@thenewstoyou.com>; "Contacto de Ejemplo" <example2@thenewstoyou.com>; example3@gmail.com'
                    }
                ]
        })
        if (response && response.data) {
            let contacts = parseOutlookContacts(response.data)
            console.log(contacts)
            contacts.forEach((contact) => {
                if (dest == 'to') {
                    sendStore.addTo(contact.email)
                } else {
                    sendStore.addCco(contact.email)
                }
                ProfileApi.createProfileContact(profile.email, contact)
                addContact(contact)
            })
        }

    }

    if (!profile.data?.contacts || profile.data?.contacts.length == 0) {

        return <View style={{ flexDirection: 'column', marginTop: 10 }}>
            <TitleNty fontSize={20} fontSize={20} style={{marginVertical: 10}}>No tienes contactos</TitleNty>
            <ButtonNty onPress={() => addProfileContacts(props.dest)} icon='plus'>Crear contactos</ButtonNty>
        </View>

    }

    if(profile.data?.contacts.filter(contact => ![...sendStore.to, ...sendStore.cco].includes(typeof contact == 'string' ? contact : contact.email)).length == 0){

        return <View style={{ flexDirection: 'column', marginTop: 10 }}>
            <TitleNty type='light' fontSize={20} style={{marginVertical: 10}}>No quedan contactos por asignar...</TitleNty>
            <ButtonNty onPress={() => addProfileContacts(props.dest)} icon='plus'>Crear contactos</ButtonNty>
        </View>
    }

    return <View style={{ flexDirection: 'column', marginTop: 10 }}>
        <ScrollView style={{ height: 250 }}>
            {
                profile.data?.contacts.filter(contact => ![...sendStore.to, ...sendStore.cco].includes(typeof contact == 'string' ? contact : contact.email))?.map((contact) =>

                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

                        <IconButton icon='trash-can' color={fixed_colors.red[500]} size={20} onPress={() => {

                            deleteContact(contact.email)
                            ProfileApi.deleteProfileContact(profile.email, contact)

                        }} />
                        <TouchableOpacity
                            key={JSON.stringify(contact)}
                            style={{ paddingVertical: 15, flexDirection: 'row', justifyContent: 'space-between', borderWidth: 1, borderRadius: 20, marginBottom: 10 }}
                            onPress={() => {
                                if (props.dest == 'to') {
                                    sendStore.addTo(typeof contact == 'string' ? contact : contact.email)
                                } else {
                                    sendStore.addCco(typeof contact == 'string' ? contact : contact.email)
                                }
                            }}>
                            <ContactCard contact={contact} />
                            <Avatar.Icon icon='plus' size={18} style={{ alignSelf: 'center', marginRight: 10 }} />

                        </TouchableOpacity>
                    </View>
                )
            }
        </ScrollView>
        <ButtonNty onPress={() => addProfileContacts(props.dest)} icon='plus'>Crear contactos</ButtonNty>
    </View>
}




function SendNewsletterFromFavouriteList() {

    const sendStore = sendNewsletterStore()
    const navigation = useNavigation<StackNavigationProp<ScreensParamsList>>()

    const { favouriteLists, sendEmailFromFavlist } = useFavouritesStore()
    const route = useRoute<RouteProp<ScreensParamsList, 'sendNewsletter'>>()
    const [favouriteList, setFavouriteList] = React.useState<FavouriteListInterface>(null)

    const fetchList = async (id: string) => {
        let list = favouriteLists?.find((l) => l._id == id)
        if (!list) {
            list = await FavouriteListApi.read(id)
        }
        setFavouriteList(list)
        sendStore.setSubject(list.name)

        try {
            const lastDispatch = await FavouriteListApi.getLastFavlistNewsletterDispatch(list._id)
            console.log(lastDispatch)

            if (lastDispatch) {
                const to_mails = lastDispatch.to.map((contact) => typeof contact == 'string' ? contact : contact.email)
                const cco_mails = lastDispatch.cco.map((contact) => typeof contact == 'string' ? contact : contact.email)
                console.log({ to_mails, cco_mails })
                sendStore.setTo(to_mails)
                sendStore.setCco(cco_mails)
                sendStore.setSubject(lastDispatch.subject)
            }

        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {

        const id = route.params.id;
        fetchList(id)

    }, [route])


    const selectContacts = (dest: 'to' | 'cco') => {

        useAlert.getState().alert({

            title: 'Selecciona contactos',
            message: <ContactsSelector dest={dest} />

        })

    }

    const sendNewsletter = async () => {

        await sendEmailFromFavlist(favouriteList, { recipients: sendStore.to, cco: sendStore.cco, subject: sendStore.subject })
        sendStore.reset()
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('home')


    }

    const theme = useAppTheme()

    return (
        <Background>
            {
                !favouriteList ? <LoadingIndicator /> :
                    <View style={{ padding: 10 }}>

                        <NtyTextInputRound
                            contentContainerStyle={{ marginTop: 50, flex: 1 }}
                            value={sendStore.subject}
                            onChangeText={(text) => sendStore.setSubject(text)}
                            placeholder='Asunto'
                        />
                        <View nativeID='destinatariosBox' style={{ marginTop: 10 }}>

                            <TitleNty fontSize={20}>Destinatarios</TitleNty>
                            <View style={{ marginTop: 10, flexDirection: 'row', padding: 5 }}>

                                <TitleNty>Para:</TitleNty>

                                <ScrollView style={{ maxHeight: 200, minHeight: 40, marginLeft: 10, borderWidth: .5, borderRadius: 10, borderColor: theme.colors.primary[500] }} contentContainerStyle={[{ paddingHorizontal: 10 }, Platform.OS == 'web' ? { flex: 1, flexDirection: 'row', flexWrap: 'wrap' } : { flexDirection: 'row', flexWrap: 'wrap' }]}>
                                    {
                                        sendStore.to?.map((mail) => <Chip
                                            key={mail}
                                            style={{ margin: 5 }}
                                            onClose={() => sendStore.deleteTo(mail)}
                                        >{mail}</Chip>)
                                    }
                                    <Chip
                                        style={{ margin: 5 }}
                                        icon='plus'
                                        onPress={() => selectContacts('to')}
                                    > Añadir</Chip>
                                </ScrollView>
                            </View>
                            <View style={{ marginTop: 10, flexDirection: 'row', padding: 5 }}>

                                <TitleNty>CCO:</TitleNty>

                                <ScrollView style={{ maxHeight: 200, minHeight: 40, marginLeft: 10, borderWidth: .5, borderRadius: 10, borderColor: theme.colors.primary[500] }} contentContainerStyle={[{ paddingHorizontal: 10 }, Platform.OS == 'web' ? { flex: 1, flexDirection: 'row', flexWrap: 'wrap' } : { flexDirection: 'row', flexWrap: 'wrap' }]}>
                                    {
                                        sendStore.cco?.map((mail) => <Chip
                                            key={mail}
                                            style={{ margin: 5 }}
                                            onClose={() => sendStore.deleteCco(mail)}
                                        >{mail}</Chip>)
                                    }
                                    <Chip
                                        style={{ margin: 5 }}
                                        icon='plus'
                                        onPress={() => selectContacts('cco')}
                                    > Añadir</Chip>
                                </ScrollView>
                            </View>

                        </View>

                        <View style={{ width: "100%", justifyContent: 'flex-end', flexDirection: 'row', marginTop: 10 }}>

                            <ButtonNty onPress={() => sendStore.reset()} mode='outlined' icon='close'>Limpiar</ButtonNty>
                            <ButtonNty onPress={sendNewsletter} mergeStyles={{ marginLeft: 20 }} icon='email-fast'>Enviar</ButtonNty>

                        </View>

                    </View>
            }
        </Background>
    )
}


export default SendNewsletterFromFavouriteList
