import React from "react"


import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import HorizontalPublisherList from "src/app/components/brands_sources/lists/HorizontalPublisherList"
import { Section } from "systemDomain"
import { ScreensParamsList } from "../../../../../../core/navigation/screens/NavigationScreens"
import NtySectionBar from '../../../../nty/section-bar/NtySectionBar'
import { useSectionNav } from "../hooks/useSectionNav"

function NewspapersSection(props: { section: Section }) {

    const nav = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()

    const { goToFunc } = useSectionNav(props.section.goTo)


    return (

        <NtySectionBar title={props.section.title} onViewSectionPress={props.section.goTo ? goToFunc : null}>

            <HorizontalPublisherList
                publishers={props.section.info.data.items}
                // onPublisherPress={(publisher) => nav.push('searchPublisher', { publisher: publisher.props.keyName })}
                // onPublisherLongPress={(publisher) => nav.push('searchPublisher', { publisher: publisher.props.keyName })}
                bubbleProps={{
                    size: 60,
                    style: { marginHorizontal: 5, marginVertical: 5 }
                }}
            />

        </NtySectionBar>
    )
}

export default React.memo(NewspapersSection)
