import React from 'react'
import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'

import { useSearchSortModal } from '../../../searchModalsStores'
import SearchSortOptions from './SearchSortOptions'

function SearchSortModal() {

    // const navigation = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>();
    const { modalVisible, setModalVisible, resolve } = useSearchSortModal()
    const refSearchOptions = React.useRef<NewsToYouSheetModalHandle>()
    const visibleRef = React.useRef(false)

    const hideModal = () => {

        visibleRef.current = false;
        refSearchOptions.current.hide()
        setModalVisible(false)
        resolve ? resolve() : null

    }
    const showModal = () => {

        visibleRef.current = true;
        refSearchOptions.current.show()
        setModalVisible(true)

    }

    React.useEffect(() => {
        // console.log({visible, visibleRef: visibleRef.current})
        if (modalVisible == true && visibleRef.current == false) {

            showModal()
        } else if (modalVisible == false && visibleRef.current == true) {

            hideModal()
        }
    }, [modalVisible])

    return (
        // @ts-ignore
        <NtySheetModal ref={refSearchOptions} scrollableContent={true} onClose={() => hideModal()} snapPoints={[240]}>

            <SearchSortOptions />

        </NtySheetModal>
    )
}

export default SearchSortModal
