import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
    DrawerItem
} from '@react-navigation/drawer';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import React, { memo, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Divider } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getLocalhost } from '../../environments';
import TrialBanner from '../components/trial/TrialBanner';
import { useAppDataStore } from '../context/appDataStore';
import { DownloadResultNty, buildApiUrl, downloadFile } from '../core/http-utils';
import { ScreensParamsList } from '../core/navigation/screens/NavigationScreens';

import useSessionStore from 'src/context/sessionStore';
import { useAppTheme } from '../core/theme/ThemeBuilder';
import { getContrastColor } from '../core/utils';
import LoadingIndicator from './components/loaders/Loading';
import DeleteProfileModal from './components/modals/DeleteProfileModal';
import NtySheetModal from './components/modals/general/NtySheetModal';
import ButtonNty from './components/nty/button/ButtonNty';
import TextNty from './components/nty/text/TextNty';
import TitleNty from './components/nty/title/TitleNty';
import { useAlert, usePrompt } from './context/alertContext';
import { useSnackBar } from './context/snackBarStore';
import useDeviceInfoStore from 'src/context/deviceInfoStore';
import { NtyPicker } from 'src/components/common-ui/picker/NtyPicker';
import { Picker } from '@react-native-picker/picker';
import { CreateQueueArticle, EnterpriseFeedV2, QueueStatus } from 'systemDomain';
import ArticleApi from 'src/api/ArticleApi';
import NtyTextInput from './components/nty/TextInput/TextInputNty';
import NtyTextInputRound from './components/nty/TextInput/TextInputNtyRound';
import WebView from 'react-native-webview';
import MultiPlatformWebview from 'src/components/webview/MultiPlatformWebview';



const Menu = (props) => {

    const theme = useAppTheme()
    const { top, bottom } = useSafeAreaInsets()

    const styles = React.useMemo(() => StyleSheet.create({
        drawerContent: {
            flex: 1
        },
        userInfoSection: {
            marginHorizontal: 20,
            marginBottom: 20
        },
        title: {
            // width: "98%",
            marginTop: 20,
            fontWeight: 'bold',
            color: theme.colors.text_primary[500],
            ...theme.fonts.default.bold
        },
        drawerSection: {
        },
        subscriptionTitle: {
            flex: 1,
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 18,
            color: theme.colors.text_primary.muted,
            ...theme.fonts.default.bold
        },
        subscriptionNumber: {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: 18,
            color: theme.colors.primary[600],
            ...theme.fonts.default.bold
        }
    }), [theme])

    const navigation = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>();

    // const { profile, user, isEnterprise, enterprise } = useAppSessionStore((store) => ({ profile: store.profile, user: store.user, isEnterprise: store.isEnterprise, enterprise: store.enterprise }))
    const { profile, enterprise, isGuest } = useSessionStore((store) => ({ profile: store.profile, enterprise: store.enterprise, isGuest: store.isGuest }));
    const { appConfig: appConfigData } = useDeviceInfoStore(store => ({ appConfig: store.appConfig }))
    // const showRegisterModal = useRegisterModal(store => store.show)
    // const {
    //     subscribedAuthors,
    //     subscribedNewspapers,
    //     clearUserSubscriptionsStore
    // } = useUserSubscriptionsStore((store) => (
    //     {
    //         subscribedAuthors: store.authors,
    //         subscribedNewspapers: store.newspapers,
    //         clearUserSubscriptionsStore: store.clearStore
    //     }))

    // const clearUserSubscriptionsStore = useUserSubscriptionsStore.getState().clearStore;
    // const cleanFavs = useFavouritesStore.getState().cleanStore;

    // const mediosPressed = () => {
    //     if (currentUser.isGuest()) {
    //         showRegisterModal();
    //         navigation.dispatch(DrawerActions.closeDrawer())
    //     } else {
    //         navigation.navigate('ManageNewspapersPage')
    //     }
    // }


    // const gestionarMediosPressed = () => {
    //     if (currentUser.isGuest()) {
    //         showRegisterModal();
    //         navigation.dispatch(DrawerActions.closeDrawer())
    //     }
    //     else {
    //         navigation.navigate('ManageNewspapersPage');
    //     }
    // }

    // const autoresPressed = () => {
    //     if (currentUser.isGuest()) {
    //         showRegisterModal();
    //         navigation.dispatch(DrawerActions.closeDrawer())
    //     }
    //     else {
    //         navigation.navigate('subscriptionsmanagement', { following: 'authors' })
    //     }
    // }


    const [configVisible, setConfigVisible] = useState<boolean>(false);

    const modalRef = React.useRef(null);
    const seguimientoModalRef = React.useRef(null);

    // const [loadingLogout, setLoadingLogout] = React.useState(false)

    const logOut = async () => {


        useAlert.getState().alert({
            title: '¿Estás seguro?', message: 'Vas a cerrar la sesión de ' + profile.email, buttons: [
                { label: 'Cancelar', onPress: () => { console.log('Cancelled logout...') } },
                { label: 'Cerrar sesión', onPress: () => { console.log('Logging out...'); navigation.navigate('logout') } }
            ]
        })


    }

    const [unseenNotifications, setUnseenNotifications] = React.useState<any>(false);

    const { notifications, seenNotifications, clear } = useAppDataStore((store) => ({ notifications: store.notifications, seenNotifications: store.seenNotifications, clear: store.clearSeenNotifications }));

    const checkUnseenNotifications = async () => {

        let seen = 0;
        for (let n of notifications) {
            const key = 'notification_' + n._id;
            const found = seenNotifications.find((seen) => seen._id == n._id)
            if (found) {
                seen++;
            }
        }

        if (notifications.length != seen) {
            setUnseenNotifications(notifications.length - seen)
        } else {
            setUnseenNotifications(false)
        }
    }

    React.useEffect(

        () => {
            if (!notifications || notifications.length == 0) return

            checkUnseenNotifications()
        }
        , [notifications, seenNotifications]);


    const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);
    const { showInfoMessage } = useSnackBar.getState()

    const onEnterpriseReportDownload = React.useCallback(async () => {


        let result: DownloadResultNty
        setDownloadLoading(true)
        try {

            result = await downloadFile({ url: buildApiUrl('enterprises/' + enterprise?.key + '/articles/xlsx', true), method: 'GET' })

        } catch (e) {
            console.error(e)
        } finally {
            setDownloadLoading(false);
            if (result?.status != 200) {
                showInfoMessage('Hubo un problema descargando el archivo...')
            }
        }



    }, [enterprise?.key])

    const feedRef = React.useRef<EnterpriseFeedV2>()
    const titleRef = React.useRef<string>()

    const onCreateArticle = async () => {

        try {

            const result = await usePrompt.getState().prompt<string>({
                title: 'Añadiendo Noticia', message: 'Enlace directo a la noticia', inputPlaceHolder: 'Introduce la URL de la noticia.', validations: [
                    { regex: /.{3,}/, errorMessage: 'Introduce al menos 3 caracteres' },
                    { regex: /(http|https)?:\/\/.{3,}/, errorMessage: 'Introduce una URL válida' }
                ]
            })

            console.log(result)


            if (result.data && !result.button?.cancel) {

                const url = result.data



                const feedResult = await useAlert.getState().alert({
                    title: 'Campos Adicionales',
                    message: () => {

                        const [selectedFeed, setSelectedFeed] = React.useState(useSessionStore.getState().feeds[0])
                        const [selectedTitle, setSelectedTitle] = React.useState<string>()

                        React.useEffect(() => {

                            feedRef.current = selectedFeed
                            titleRef.current = selectedTitle


                        }, [selectedFeed, selectedTitle])

                        return <View style={{ paddingVertical: 10, paddingHorizontal: 5 }}>

                            <NtyTextInputRound

                                placeholder='Titular'
                                bottomSheetInput={Platform.OS != 'web'}
                                onChangeText={setSelectedTitle}
                                value={selectedTitle}
                                // contentContainerStyle={{paddingHorizontal: 10}}

                            />

                            <NtyPicker
                                pickerTitle='Feed*'
                                selectedValue={selectedFeed._id}
                                containerStyle={{flex: 1, alignSelf: 'flex-start'}}
                                onValueChange={(value, index) => {setSelectedFeed(useSessionStore.getState().feeds.find(f => f._id == value)) }}
                            >
                                {useSessionStore.getState().feeds.map(feed => (
                                    <Picker.Item label={feed.name} value={feed._id} key={feed._id} />
                                ))}
                            </NtyPicker>
                        </View>

                    }
                })

                // console.log('Result', feedResult)

                if (feedResult?.button?.cancel === false) {

                    // console.log('feed', feedRef.current)

                    const queue_article: CreateQueueArticle = {
                        url,
                        artifact: 'app-enterprise-user',
                        artifact_instance: 'app-enterprise-user-' + useSessionStore.getState().profile.email,
                        enterprises: [enterprise.key],
                        insert_action: 'UPSERT',
                        feeds: [feedRef.current._id],
                        priority: 100,
                        published_at: new Date().toISOString(),
                        force_insert: true,
                        // avoid_matches: true,
                        status: QueueStatus.PENDING
                    }

                    if(titleRef.current && titleRef.current.length > 0) {
                        queue_article.title = titleRef.current
                    }

                    try {
                        const created = await ArticleApi.createQueueArticle(queue_article)
                        useAlert.getState().alert(({ title: 'Completado!', message: 'Estamos creando tu noticia. La verás en unos minutos en tu Feed "'+feedRef.current.name+'"' }))
                    } catch (e) {
                        console.error(e)
                        useAlert.getState().alert({ title: 'Error', message: 'No se ha podido crear la noticia, + [' + e + ']', buttons: [{ label: 'Ok' }] })
                    }


                }





            }
        } catch (e) {
            console.error(e)
        }

    }


    return (
        <>
            <View style={{ flex: 1, paddingTop: top, paddingBottom: bottom, backgroundColor: theme.colors.background[400] }}>



                <View style={styles.drawerContent}>
                    <View nativeID='topBarSection' style={{ justifyContent: 'space-between', flexDirection: 'row' }}>

                        <TouchableOpacity style={{ paddingVertical: 15, paddingHorizontal: 15, marginLeft: 5 }} onPress={() => { navigation.dispatch(DrawerActions.closeDrawer()) }}>
                            <TextNty fontSize={13} color={theme.colors.primary[300]}>Cerrar</TextNty>
                        </TouchableOpacity>

                        {/* <TouchableOpacity style={{ paddingVertical: 15, paddingHorizontal: 15, marginLeft: 5 }} onPress={() => { navigation.push('Loading'); setTimeout( ()=> navigation.pop(), 2000) }}>
                            <TextNty fontSize={13} color={theme.colors.primary[300]}>Cargar</TextNty>
                        </TouchableOpacity> */}


                        {
                            profile && profile.superUser == true && <TouchableOpacity style={{ paddingVertical: 15, paddingHorizontal: 15, marginRight: 5, alignSelf: 'flex-end' }} onPress={() => { navigation.navigate('configuration') }}>

                                <MaterialCommunityIcons name='cog' size={20} color={theme.colors.text_primary[300]} />

                            </TouchableOpacity>
                        }
                    </View>
                    <View style={[styles.userInfoSection]}>

                        <TitleNty fontSize={20} style={styles.title} adjustsFontSizeToFit>{isGuest ? 'Invitado' : profile.email}</TitleNty>
                        <TrialBanner />


                    </View>
                    <View style={{ flex: 10, marginTop: 10 }}>
                        <ScrollView>
                            <ButtonNty
                                mergeStyles={{ marginHorizontal: 25, marginBottom: 20 }}
                                icon="mail"
                                onPress={() => { /* Linking.openURL('mailto:team@thenewstoyou.com') */ seguimientoModalRef.current.show() }}
                            >{enterprise ? 'Solicitar seguimiento' : 'Send Fedback'}</ButtonNty>

                            {!enterprise && <DrawerItem
                                icon={(data: any) => (
                                    <>
                                        {unseenNotifications &&
                                            <View style={{ position: 'absolute', top: 10, left: 6, width: 12, height: 12, borderRadius: 6, zIndex: 10, backgroundColor: theme.colors.primary[500] }}>
                                                <TextNty fontSize={9} type='bold' color={getContrastColor(theme.colors.primary[500])} style={{ textAlign: 'center', textAlignVertical: 'center' }}>{unseenNotifications}</TextNty>
                                            </View>}
                                        <MaterialCommunityIcons
                                            name="bell-outline"
                                            color={theme.colors.text_primary[400]}
                                            size={data.size}
                                        />
                                    </>
                                )}
                                label="Novedades"
                                labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                onPress={() => navigation.navigate('news')}
                            />}

                            {!isGuest && enterprise &&
                                <>
                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                                    <View>
                                        <DrawerItem
                                            icon={(data: any) => (
                                                <MaterialCommunityIcons
                                                    name="download-multiple"
                                                    color={theme.colors.text_primary[400]}
                                                    size={data.size}
                                                />
                                            )}
                                            label="Descargar Informe Noticias"

                                            labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                            onPress={onEnterpriseReportDownload}
                                        />
                                        {downloadLoading && <LoadingIndicator size={20} />}
                                    </View>
                                </>
                            }
                            {!isGuest && enterprise && enterprise.admins.find((value) => value == profile.email) &&
                                <>
                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                                    <View>
                                        <DrawerItem
                                            icon={(data: any) => (
                                                <MaterialCommunityIcons
                                                    name="plus-circle-outline"
                                                    color={theme.colors.text_primary[400]}
                                                    size={data.size}
                                                />
                                            )}
                                            label="Añadir una noticia"

                                            labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                            onPress={onCreateArticle}
                                        />
                                        {downloadLoading && <LoadingIndicator size={20} />}
                                    </View>
                                </>
                            }

                            {/* {!currentUser.isGuest() && !isEnterprise &&
                                <>
                                    <DrawerItem
                                        icon={(data: any) => (
                                            <MaterialCommunityIcons
                                                name="newspaper"
                                                color={theme.colors.text_primary[400]}
                                                size={data.size}
                                            />
                                        )}
                                        label="Gestiona tus medios"
                                        labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                        onPress={gestionarMediosPressed}
                                    />
                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                                </>
                            } */}

                            <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                            <DrawerItem
                                icon={(data: any) => (
                                    <MaterialCommunityIcons
                                        name="information-outline"
                                        color={theme.colors.text_primary[400]}
                                        size={data.size}
                                    />
                                )}
                                label="Contacto"
                                labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                onPress={() => navigation.navigate('contactinfo')}
                            />

                            <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <DrawerItem
                                    icon={(data: any) => (
                                        <MaterialCommunityIcons
                                            name="cog"
                                            color={theme.colors.text_primary[400]}
                                            size={data.size}
                                        />
                                    )}
                                    label="Ajustes"
                                    style={{ flex: 1 }}
                                    labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                    onPress={() => setConfigVisible(!configVisible)}
                                />
                                <MaterialCommunityIcons
                                    name={configVisible ? "chevron-up" : "chevron-down"}
                                    color={theme.colors.text_primary[400]}
                                    size={30}
                                    style={{ alignSelf: 'center', marginRight: 20 }}
                                    onPress={() => setConfigVisible(!configVisible)}
                                />
                            </View>
                            {
                                configVisible ? <>

                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />

                                    <DrawerItem
                                        icon={(data: any) => (
                                            <MaterialCommunityIcons
                                                name="satellite-uplink"
                                                color={theme.colors.text_primary[400]}
                                                size={data.size}
                                            />
                                        )}
                                        label="Nuestras Redes"
                                        labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                        onPress={() => { Linking.openURL('https://linktr.ee/newstoyou') }}
                                    />

                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />

                                    <DrawerItem
                                        icon={(data: any) => (
                                            <MaterialCommunityIcons
                                                name="file-cog-outline"
                                                color={theme.colors.text_primary[400]}
                                                size={data.size}
                                            />
                                        )}
                                        label="NTY terms and conditions"
                                        labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                        onPress={() => Linking.openURL(getLocalhost + "/api/v1/policy")}
                                    />
                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                                    <DrawerItem
                                        icon={(data: any) => (
                                            <MaterialCommunityIcons
                                                name="file-cog-outline"
                                                color={theme.colors.text_primary[400]}
                                                size={data.size}
                                            />
                                        )}
                                        label="NTY políticas de privacidad"
                                        labelStyle={{ marginLeft: -20, color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold }}
                                        onPress={() => Linking.openURL(getLocalhost + "/api/v1/policyCookies")}
                                    />
                                    <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                                    {
                                        !isGuest ?
                                            <DrawerItem
                                                icon={(data: any) => (
                                                    <MaterialCommunityIcons
                                                        name="delete"
                                                        color={theme.colors.text_primary[400]}
                                                        size={data.size}
                                                    />
                                                )}
                                                label="Borrar mi cuenta"
                                                labelStyle={{ marginLeft: -20, color: theme.colors.danger[400], ...theme.fonts.default.semiBold }}
                                                onPress={() => modalRef.current.show()}
                                            /> : null
                                    }
                                </> : null
                            }
                            {/* <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                            {
                                !currentUser.isGuest() ?
                                    <>
                                        {
                                            !currentUser.isPremium() ?
                                                <DrawerItem
                                                    label="News To You Premium"
                                                    labelStyle={{ color: theme.colors.primary[600], ...theme.fonts.default.bold }} //PREMIUM
                                                    onPress={() => { currentUser.isGuest() ? navigation.navigate('login') : navigation.navigate("plans") }}
                                                />
                                                :
                                                <DrawerItem
                                                    label="Gestionar Premium"
                                                    labelStyle={{ color: theme.colors.primary[600] }} //PREMIUM
                                                    onPress={() => navigation.navigate('manage')}
                                                />
                                        }
                                    </>
                                    : null
                            } */}
                            {/* {
                                !isGuest && user.isPremium() ?

                                    <>
                                        <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
                                        <DrawerItem
                                            label="Gestionar Premium"
                                            labelStyle={{ color: theme.colors.primary[600] }} //PREMIUM
                                            onPress={() => {
                                                navigation.navigate('manage')
                                            }}
                                        />
                                    </>
                                    :
                                    null

                            } */}

                            {
                                !isGuest ?
                                    <View>
                                        <DrawerItem
                                            label="Cerrar sesión"
                                            labelStyle={{ color: theme.colors.danger[500] }}
                                            onPress={logOut}
                                        />
                                        {/* {loadingLogout && <LoadingIndicator size={20} />} */}
                                    </View>
                                    :
                                    <DrawerItem
                                        label="Login"
                                        labelStyle={{ color: theme.colors.primary[400] }}
                                        onPress={() => { navigation.navigate('login') }}
                                    />
                            }
                            {
                                profile && profile.superUser == true &&
                                <DrawerItem
                                    label="Limpiar Notificaciones Vistas"
                                    labelStyle={{ color: theme.colors.text_primary[400], fontWeight: 'bold' }}
                                    onPress={() => { clear() }}
                                />
                            }
                            {
                                profile && profile.superUser == true &&
                                <DrawerItem
                                    label="Estdísticas Uso"
                                    labelStyle={{ color: theme.colors.text_primary[400], fontWeight: 'bold' }}
                                    onPress={() => { navigation.navigate('usagestats') }}
                                />
                            }

                            {profile && profile.superUser == true &&
                                <DrawerItem
                                    label="Opciones de Super Usuarios"
                                    labelStyle={{ color: theme.colors.text_primary[400], fontWeight: 'bold' }}
                                    onPress={() => { navigation.navigate('superUserPage') }}
                                />
                            }

                            {profile && profile.superUser == true &&
                                <DrawerItem
                                    label="Datos del perfil"
                                    labelStyle={{ color: theme.colors.text_primary[400], fontWeight: 'bold' }}
                                    onPress={() => { navigation.navigate('profileinfodev') }}
                                />
                            }
                        </ScrollView>
                    </View>

                    <TextNty style={{ color: theme.colors.text_primary.muted, padding: 10 }}>App Version: {appConfigData.expo.version}</TextNty>
                </View>
            </View >
            <NtySheetModal ref={modalRef} forceModal={true}>
                <DeleteProfileModal onLogOut={() => logOut()} onDismiss={() => modalRef.current.hide()} />
            </NtySheetModal>
            <NtySheetModal ref={seguimientoModalRef} forceModal={true} modalHeight={600}>
                <MultiPlatformWebview source={{ uri: 'https://share-eu1.hsforms.com/1sgN3S2YoQtWIETzn8TnFQweulvk' }} />
            </NtySheetModal>
        </>

    );
}



export default memo(Menu);
