import {StyleSheet} from 'react-native'

export const styles = StyleSheet.create({
    activityIndicatorContainer: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        alignItems: 'center'
    }
})