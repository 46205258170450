import React from "react";
import {
  StyleSheet, useWindowDimensions, View, ViewStyle
} from "react-native";
import RenderHTML from "react-native-render-html";
import { Subject } from "rxjs";
// import WebView from "react-native-webview";
import useSessionStore from "src/context/sessionStore";
import { Article } from "systemDomain";
import {
  useTextStylesStore
} from "../../context/TextStylesStore";
import { fixed_colors } from "../../core/theme/colors";
import { useAppTheme } from "../../core/theme/ThemeBuilder";
import { extractKeywordsFromArticle } from "../../core/utils";
import TextNty from "./nty/text/TextNty";
// import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';


// const renderers = {
//   iframe: IframeRenderer
// }

// const customHTMLElementModels = {
//   graphic
// }

function clean_html(text) {
  // Elimina comentarios HTML
  text = text.replace(/<!--[\s\S]*?-->/g, '');

  // Manejo de saltos de línea y espacios
  // ------------------------------------

  // Reemplaza combinaciones de tabulaciones y saltos de línea con un solo salto de línea
  text = text.replace(/\t\n|\n\t/g, '\n');

  // Reemplaza múltiples saltos de línea con un solo salto de línea
  text = text.replace(/\n+/g, '\n');

  // Reemplaza múltiples tabulaciones con una sola tabulación
  text = text.replace(/\t+/g, '\t');

  // Reemplaza espacios seguidos de saltos de línea y letras minúsculas
  text = text.replace(/(\s)\n([a-z])/g, "$1$2");
  text = text.replace(/([a-zA-Z])\n([a-z])/g, "$1$2");
  text = text.replace(/(\s)\r\n([a-z])/g, "$1$2");
  text = text.replace(/([a-zA-Z])\r\n([a-z])/g, "$1$2");

  // Elimina todos los saltos de línea
  // text = text.replace(/\n/g, "");
  // text = text.replace(/\r\n/g, "");


  // Elimina espacios dobles
  text = text.replace(/  /g, "");

  // Manejo de atributos y etiquetas
  // -------------------------------

  // Elimina los saltos de linea '<br/> con etiquetas
  text = text.replace(/(<br\s*\/?>(\s|\t|\r)*<\/?br\s*\/?>)+(<br\s*\/?>)*/g, '</br>')

  // Cambia data-src a src
  text = text.replace(/data-src/g, 'src');

  // Cambia etiquetas <label a <p
  text = text.replace(/<label/g, '<p');
  // Cambia las etiquetas strong a 'b'
  text = text.replace(/<strong/g, '<b');
  return text;
}


function clean_text(text) {
  // Si el contenido no existe, devolvemos una cadena vacía.
  if (!text) return "";

  // Reemplaza cada salto de línea con dos saltos de línea.
  // text = text.replace(/\n/g, "\n\n");

  // Elimina saltos de línea que están entre palabras o caracteres.
  text = text.replace(/(\s)\n([a-z])/g, "$1$2");
  text = text.replace(/([a-zA-Z])\n([a-z])/g, "$1$2");
  text = text.replace(/(\s)\r\n([a-z])/g, "$1$2");
  text = text.replace(/([a-zA-Z])\r\n([a-z])/g, "$1$2");

  // Elimina URLs no deseados.
  text = text.replace(/about:\/\/\/blank/g, '');

  // Reemplaza múltiples tabulaciones con un salto de línea.
  text = text.replace(/\t+/g, '\r\n');

  // Reemplaza múltiples espacios con un salto de línea.
  text = text.replace(/ {2,}/g, '\r\n');

  // Reemplaza tabulaciones individuales con un salto de línea.
  text = text.replace(/\t/g, '\r\n');

  return text;
}






export interface ArticleTextProps {
  article: Article;
  showHtml: boolean;
  contentContainerStyle?: ViewStyle,
  keywords?: string[]
}

export const requestWebViewReload: Subject<boolean> = new Subject();

export default function ArticleText({
  article,
  showHtml,
  contentContainerStyle,
  keywords
}: ArticleTextProps) {



  if(!keywords) {
    keywords = extractKeywordsFromArticle(article, useSessionStore.getState()?.enterprise?.key)
  }


  const { fontSize, fontWeight, fontFamily, lineHeight, textAlign, textTheme } =
    useTextStylesStore();

  const { width } = useWindowDimensions();

  function getUseHtml() {
    // if(article.props.text) {
    //   return false
    // } else {
    //   return true
    // }
    if (!showHtml) {
      return false;
    }

    try {
      let hasHtml = article.html

      if (hasHtml && hasHtml[0] == '<') {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  const styles = StyleSheet.create({
    bodyNoTextFormat: {
      marginTop: 5,
      // alignSelf: 'center',
      marginBottom: 20,
    },
    contentContainerNoHeigth: {
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      paddingVertical: 5,
    },
    textStyles: {
      fontSize: fontSize,
      fontWeight: fontWeight,
      lineHeight: lineHeight,
      fontFamily: fontFamily,
      textAlign: textAlign,
      color: textTheme.textColor,
    },
    keywordStyles: {
      fontSize: fontSize + 2,
      fontWeight: fontWeight,
      lineHeight: lineHeight + 2,
      fontFamily: fontFamily,
      textAlign: textAlign,
      color: fixed_colors.black[500],
      backgroundColor: fixed_colors.yellow[400]
    }
  });

  const theme = useAppTheme()


  const getHighlightedText = (keywords: string[]) => {
    // search text user inputs
    // console.log('Im Here')
    const content = article.text
    let text = clean_text(content)



    if (text == "" || text == null) {
      return <TextNty>{"..."}</TextNty>
    } else {
      // split the search value

      if (!keywords || keywords.length == 0) return text;

      const children = [];

      const words = keywords?.map(k => k.replace(new RegExp("\"", 'g'), "")).filter(k => k != null).join('|')

      const re = new RegExp(words, 'gi')

      let before, highlighted, match, pos = 0;
      // match using RegExp with my text
      const matches = text.match(re);

      console.log(matches)

      if (matches != null) {
        // loop all the matches
        for (match of matches) {
          match = re.exec(text)

          if (pos < match.index) {
            // before has all the text before the word
            // that has to highlighted
            before = text.substring(pos, match.index);

            if (before.length) {
              children.push(before)
            }
          }
          highlighted = <TextNty style={[styles.bodyNoTextFormat,
          {
            fontSize: fontSize,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            fontFamily: fontFamily,
            textAlign: textAlign,
            color: textTheme.textColor,
            backgroundColor: fixed_colors.yellow[300]
          },
          ]}>{match[0]}</TextNty>
          // text is highlighted
          children.push(highlighted);

          pos = match.index + match[0].length;
        }
      }
      if (pos < text.length) {
        // text after the highlighted part
        const last = text.substring(pos);
        children.push(last);
      }

      // children array will have the entire text
      return <TextNty style={[styles.bodyNoTextFormat,
      {
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        fontFamily: fontFamily,
        textAlign: textAlign,
        color: textTheme.textColor,
      },
      ]}>{children}</TextNty>
    }
  }
  const getHighlightedHTML = (keywords: string[]) => {
    // search text user inputs
    // console.log('Im Here')
    let text = article.readeable_html ?? article.html
    if (!text) return ''
    text = clean_html(text)

    if (text == "" || text == null) {
      return text
    } else {
      // split the search value

      if (!keywords || keywords.length == 0) return text;

      const children = [];

      const words = keywords?.map(k => k?.replace(new RegExp("\"", 'g'), "")).filter(k => k != null).join('|')
      //  const words = '(?:<.+.>)'+keywords?.map(k => k.replace(new RegExp("\"", 'g'), "")).join('|')+ '(?=<.*\/.+.?>)'
      const re = new RegExp(words, 'gi')

      // const re = new RegExp(`<\\w+[^>]*>(?:(?:(?!<\\/\\w+>).)*?(${keywords?.map(k => k.replace(new RegExp("\"", "")).join('|')})(?:(?!<\\/\\w+>).)*?)<\\/\\w+>`, 'g'), 'gi');

      let before, highlighted, match, pos = 0;
      // match using RegExp with my text
      const matches = text.match(re);



      if (matches != null) {

        for (match of matches) {
          match = re.exec(text)
          // console.log(match.index)
          // console.log(text.substring(match.index, match.index + match[0].length + 20))
          if (pos < match.index) {
            // before has all the text before the word
            // that has to highlighted
            before = text.substring(pos, match.index);

            if (before.length) {
              children.push(before)
            }
          }
          highlighted = `<span style='background-color: yellow'>${match[0]}</span>`;
          // text is highlighted
          children.push(highlighted);

          pos = match.index + match[0].length;
          // console.log(before.substring(-10) + highlighted + text.substring(pos, pos +10))
        }
      }
      if (pos < text.length) {
        // text after the highlighted part
        const last = text.substring(pos);
        children.push(last);
      }

      // children array will have the entire text
      return children.join('')
    }
  }



  return (
    <View style={[contentContainerStyle]}>
      {
        getUseHtml() &&
          article?.html ? (
          <RenderHTML
            source={{ html: getHighlightedHTML(keywords) }}

            tagsStyles={{
              a: {
                textDecorationColor: theme.colors.links[600],
                color: theme.colors.links[600],
              },
              p: {
                fontSize: fontSize,
                fontWeight: fontWeight,
                lineHeight: lineHeight,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              h1: {
                fontSize: fontSize + 15,
                fontWeight: "bold",
                lineHeight: lineHeight + 20,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              h2: {
                fontSize: fontSize + 12,
                fontWeight: "bold",
                lineHeight: lineHeight + 17,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              h3: {
                fontSize: fontSize + 10,
                fontWeight: "bold",
                lineHeight: lineHeight + 15,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              h4: {
                fontSize: fontSize + 7,
                fontWeight: "bold",
                lineHeight: lineHeight + 12,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              h5: {
                fontSize: fontSize + 5,
                fontWeight: "bold",
                lineHeight: lineHeight + 10,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              ol: {
                fontSize: fontSize + 2,
                lineHeight: lineHeight + 2,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              ul: {
                fontSize: fontSize + 2,
                lineHeight: lineHeight + 2,
                fontFamily: fontFamily,
                textAlign: textAlign,
                color: textTheme.textColor,
              },
              table: {
                backgroundColor: textTheme.bgColor,
                color: textTheme.textColor,
                borderWidth: 1,
                borderColor: textTheme.textColor,
                borderRadius: 5,
                fontSize: fontSize * 0.7,
                marginVertical: 5
              },
              td: {
                fontSize: fontSize * 0.6,
                borderLeftWidth: 0.2,
                borderColor: textTheme.textColor,
                fontWeight: 'normal',
                textAlign: 'center',
                textAlignVertical: 'center',
                justifyContent: 'center'
              },
              tr: {
                borderBottomWidth: 0.6,
                borderColor: textTheme.textColor
              },
              th: {
                borderBottomWidth: 0.6,
                borderLeftWidth: 0.6,
                borderColor: textTheme.textColor,
                fontWeight: 'bold',
                textAlign: 'center',
                textAlignVertical: 'center',
                justifyContent: 'center'
              },
              iframe: {
                maxWidth: width - 50,
                paddingVertical: 0,
                marginVertical: 10,
                alignSelf: 'center'
              }

            }}
            defaultTextProps={{ selectable: true, style: { fontFamily: fontFamily } }}
            contentWidth={width}
            ignoredDomTags={['input']}
          ></RenderHTML>
        ) : (
          <TextNty
            style={[styles.bodyNoTextFormat,
            {
              fontSize: fontSize,
              fontWeight: fontWeight,
              lineHeight: lineHeight,
              fontFamily: fontFamily,
              textAlign: textAlign,
              color: textTheme.textColor,
            },
            ]}
          >
            {getHighlightedText(keywords)}
          </TextNty>

        )}
    </View>
  );
}
