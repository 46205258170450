
import { pdb } from "src/../environments";
import React from "react";
import { View, ScrollView } from "react-native";
import { IconButton, Chip, ToggleButton } from "react-native-paper";
import { NewContentButton } from "src/app/components/article/lists/NewContentButton";
import HighlightsList from "src/app/components/highlights/lists/HighlightsList";
import LoadingIndicator from "src/app/components/loaders/Loading";
import TitleNty from "src/app/components/nty/title/TitleNty";
import { useSnackBar } from "src/app/context/snackBarStore";
import useSessionStore from "src/context/sessionStore";
import { DownloadResultNty, downloadFile, buildApiUrl } from "src/core/http-utils";
import { useAppTheme } from "src/core/theme/ThemeBuilder";
import { extractKeywordsFromFeed, openInBrowser } from "src/core/utils";
import { Article, EnterpriseFeedV2, HighlightInterface } from "systemDomain";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";
import AutoImage from "src/components/AutoImage";
import useAspectRatio from "src/app/hooks/useAspectRatio";
import ActionApi, { ACTION_NAMES } from "src/api/ActionApi";

type FeedHeaderProps = {
    feed: EnterpriseFeedV2,
    onRefresh: () => void,
    fetchArticles: (limit: number, skip: number, sort: { [key: string]: 1 | -1 }) => Promise<Article[]>,
    data: Article[],
    highlights?: HighlightInterface[],
    showBackButton?: boolean,
    display?: { showDisplay: boolean, displayType: 'view-sequential' | 'view-grid', setDisplayType: React.Dispatch<React.SetStateAction<"view-sequential" | "view-grid">> }
}

export const FeedHeader = (props: FeedHeaderProps) => {

    const nav = useNavigation<StackNavigationProp<ScreensParamsList>>()

    const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);
    const { showInfoMessage } = useSnackBar.getState()
    const onDownload = React.useCallback(async () => {

        let result: DownloadResultNty
        setDownloadLoading(true)
        try {
            result = await downloadFile({ url: buildApiUrl('feeds/' + props.feed?._id + '/articles/xlsx', true), method: 'GET' })
        } catch (e) {
            console.error(e)
        } finally {
            setDownloadLoading(false);
            if (result?.status != 200) {
                showInfoMessage('Hubo un problema descargando el archivo...')
            }
        }

    }, [props.feed])

    const _onRefresh = () => {

        setNewContent(null)
        props.onRefresh()


    }

    const [newContent, setNewContent] = React.useState<Article[]>()

    React.useEffect(() => {

        const minutes = 5
        const time = 1000 * 60 * minutes
        console.log('Starting newContent interval...')
        const interval = setInterval(() => {
            console.log('Searching for new content...')
            // if (useAppStateStore.getState().state == 'active') {
            props.fetchArticles(5, 0, { published_at: -1 }).then(
                (result) => {
                    if (result && result.length > 0) {
                        if (props.data && result[0]._id != props.data?.[0]._id) {
                            console.log('New Content Found')
                            // if (true) {
                            // props.data[0].publisher.logo
                            setNewContent(result)
                        } else
                            console.log('No New Content Found')
                    }
                }
            )
            // }
        }, time)

        return () => {
            console.log('Clearing newContent interval...')
            clearInterval(interval)
        }

    }, [props.data])

    const theme = useAppTheme()

    const iconsColor = theme.colors.secondary.muted as string

    const profile = useSessionStore(store => store.profile)

    const navigation = useNavigation<StackNavigationProp<ScreensParamsList>>()

    const goBack = () => {
        if (navigation.canGoBack()) {
            navigation.goBack()
        }
        else {
            navigation.navigate('home');
        }
    }

    if (!props.feed) return null

    const { screenOrientation } = useAspectRatio()


    return <View style={{ marginBottom: 15, flexDirection: 'column', paddingLeft: 10 }}>


        <View style={{ marginBottom: 10 }}>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {props.showBackButton && <IconButton
                    color={theme.colors.text_primary[500]}
                    size={25}
                    // style={{ position: 'absolute', left: 0 }}
                    icon='chevron-left'
                    onPress={goBack}
                />}
                {props.feed?.icon && <AutoImage height={40} source={{ uri: props.feed.icon }} style={{ marginRight: 20 }} />}
                <TitleNty fontSize={25} style={{ marginRight: 20 }}>{props.feed?.name}</TitleNty>

                {
                    screenOrientation == 'horizontal' &&
                    <>
                        {downloadLoading ? <LoadingIndicator size={20} color={theme.colors.primary[400]} style={{ marginHorizontal: 11 }} /> :
                            <IconButton icon={'download'} size={20} color={iconsColor} onPress={() => onDownload()} />}
                        {props.display?.showDisplay && props.feed.version == 'v2' && props.feed.interests?.length > 0 && <ToggleButton icon={props.display?.displayType} onPress={() => { props.display.setDisplayType(props.display.displayType == 'view-grid' ? 'view-sequential' : 'view-grid') }} color={iconsColor} />}
                        {profile?.superUser && <IconButton icon={'database'} size={20} color={iconsColor} onPress={() => { openInBrowser(pdb + '/db/db/enterprise_feed/' + '"' + props.feed?._id + '"') }} />}
                        <IconButton icon={'refresh'} size={25} color={iconsColor} onPress={() => props.onRefresh()} />
                    </>
                }
            </View>
            {screenOrientation == 'vertical' &&

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {downloadLoading ? <LoadingIndicator size={20} color={theme.colors.primary[400]} style={{ marginHorizontal: 11 }} /> :
                        <IconButton icon={'download'} size={20} color={iconsColor} onPress={() => onDownload()} />}
                    {props.display?.showDisplay && props.feed.version == 'v2' && props.feed.interests?.length > 0 && <ToggleButton icon={props.display?.displayType} onPress={() => { props.display.setDisplayType(props.display.displayType == 'view-grid' ? 'view-sequential' : 'view-grid') }} color={iconsColor} />}
                    {profile?.superUser && <IconButton icon={'database'} size={25} color={iconsColor} onPress={() => { openInBrowser(pdb + '/db/db/enterprise_feed/' + '"' + props.feed?._id + '"') }} />}
                    <IconButton icon={'refresh'} size={25} color={iconsColor} onPress={() => props.onRefresh()} />
                </View>

            }


        </View>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} >
            {extractKeywordsFromFeed(props.feed).map((k) => <Chip onPress={() => { ActionApi.createFrom({ name: ACTION_NAMES.VIEW_KEYWORD, data: { keyword: k } }); nav.navigate('searchKeyword', { keyword: k }) }} style={{ marginRight: 10 }} textStyle={{ fontSize: 10 }} mode={'outlined'} key={k}>{k}</Chip>)}
        </ScrollView>
        {newContent ?
            <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                <NewContentButton articles={newContent} onPress={_onRefresh} />
            </View> : null}

        {(props.highlights && props.highlights.length > 0) ?
            <HighlightsList
                highlights={props.highlights}
                loadMoreDisabled={true}
                horizontal={true}
                fixedItemHeight={300}
                contentContainerStyle={{ zIndex: 100 }}
            /> : null
        }

    </View>

}
