import React from "react";
import {View} from 'react-native';
import { getAppTheme } from "../../core/theme/ThemeBuilder";

export default function ColorIcon({ selected, size, color }) {

    const theme = getAppTheme()

    return (
      <View style={{ flexDirection: "column", alignItems: "center", padding: 2 }}>
        <View
          style={[
            {
              height: size,
              width: size,
              borderRadius: size / 2,
              backgroundColor: color,
            },
            selected
              ? { borderWidth: 2, borderColor: theme.colors.primary[600] }
              : { borderWidth: 1, borderColor: theme.colors.text_primary.muted },
          ]}
        ></View>
      </View>
    );
  }


