import React from 'react';
import { GestureResponderEvent, Image, StyleSheet, View, ViewStyle } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { Article, ArticleInterface, HighlightInterface, NewspaperModel } from 'systemDomain';
import { getLocalhost } from '../../../../../environments';
import { useAppDataStore } from '../../../../context/appDataStore';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import { fixed_colors } from '../../../../core/theme/colors';
import { getRandomColor } from '../../../../core/utils';
import ArticleMediaComponent from '../../article/cards/ArticleMedia';
import PublisherBubble from '../../brands_sources/PublisherBubble';
import TextNty from '../../nty/text/TextNty';
import TitleNty from '../../nty/title/TitleNty';






export type HighlightCardProps = {
  highlight: HighlightInterface,
  style?: ViewStyle,
  onPress?: (hihglight: HighlightInterface, event?: GestureResponderEvent) => void
  onLongPress?: (hihglight: HighlightInterface, event?: GestureResponderEvent) => void,
  // onOptionsPress?: (hihglight: HighlightInterface, event?: GestureResponderEvent) => void,
  onNewspaperPress?: (newspaper: NewspaperModel) => void
  onAuthorPress?: (author: string) => void
}

const style = StyleSheet.create({
  card: {
    elevation: 2,
    borderRadius: 10,
    flexDirection: 'column',
    width: "100%",
    overflow: 'hidden',
    backgroundColor: fixed_colors.grey[600]
  }
})

type LengthProps = {
  data: ArticleInterface,
  maxLength?: number
}

function HighlightCard(props: HighlightCardProps) {

  const article: Partial<Article> = props.highlight.article as Partial<Article>;


  const theme = useAppTheme();

  // const bgColor = theme.colors.background//getCategoryBG(article.get('category'));
  // const color = theme.colors.grey.full; // getContrastColor(bgColor);
  // const textColor = theme.colors.white.light; //getContrastColor(bgColor);
  // const fontFamily = theme.fonts.archivo.regular;

  const getNewspapersByKeyName = useAppDataStore.getState().getNewspapersByKeyName;
  const newspaper: NewspaperModel = getNewspapersByKeyName([article?.site])[0];

  const HighlightImage = React.useMemo(() => {

    let showCustomPic = false;
    let pic;

    if (props.highlight.picture) {
      showCustomPic = true;
      pic = props.highlight.picture;
    }

    return (

      <View style={[StyleSheet.absoluteFillObject, { zIndex: -1 }]}>

        {showCustomPic || article == null ?
          showCustomPic ?
            <Image
              source={
                {
                  uri: pic.includes("http") ?
                    pic
                    : getLocalhost + pic
                }
              }
              style={{ width: "100%", height: "100%", resizeMode: 'cover' }}
            /> :

            <View style={{ backgroundColor: getRandomColor(), flex: 1 }}>
            </View>
          :
          <ArticleMediaComponent article={article} forceState={'image'}/>
        }
      </View>

    )
  }, [article, props.highlight])



  return (
    <View style={[style.card, theme.shadows.box.medium, props.style]}>
      <>
        <View style={[StyleSheet.absoluteFillObject, { backgroundColor: fixed_colors.black[600]+'60' }]} />
        {HighlightImage}
        <TouchableRipple
          style={{ flex: 1 }}
          onPress={() => props.onPress ? props.onPress(props.highlight) : null}
          onLongPress={() => props.onLongPress ? props.onLongPress(props.highlight) : null}
        >
          <View style={[{ flex: 1, padding: 10, flexDirection: 'column', justifyContent: 'flex-end' }]}>

            <TitleNty style={{ marginBottom: 10 }} fontSize={article ? 19 : 25} type={'semiBold'} color={fixed_colors.white[400]}>{props.highlight.slideshow ?? article?.title}</TitleNty>
            {article &&
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <PublisherBubble publisher={article.publisher ?? article.site} size={30} />
                <TextNty type='semiBold' fontSize={15} color={fixed_colors.white[400]} adjustsFontSizeToFit={true} style={{ marginLeft: 5 }}>{newspaper?.props?.name ?? article?.site}</TextNty>
                {/* <CardAuthor data={article} maxLength={20}/> */}
              </View>
            }
          </View>
        </TouchableRipple>
      </>

    </View>

  )
}
export default HighlightCard

