import React from 'react';
import { Platform } from 'react-native';
import CookieAdvisor, { CookiesResponse } from '../../app/components/modals/CookieAdvisor';
import Storage from '../../core/storage';

function CookiesChecker() {

    if (Platform.OS != 'web') {
        return null;
    }

    const [cookiesVisible, setCookiesVisible] = React.useState(false);


    React.useEffect(() => {

        const init = async () => {

            const cookiesSettings: CookiesResponse = await Storage.read('cookies_response');

            if (!cookiesSettings || !cookiesSettings.responded) {
                setCookiesVisible(true);
            }
        }

        init()

    }, [])



    return (
        <CookieAdvisor visible={cookiesVisible} actionDone={(response) => { console.log(response); setCookiesVisible(false) }} />
    )
}

export default React.memo(CookiesChecker)
