import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import useSessionStore from 'src/context/sessionStore'
import Background from '../app/Background'
import Loading from '../app/components/loaders/Loading'
import TitleHeaderNty from '../app/components/nty/header/TitleHeaderNty'
import { ScreensParamsList } from '../core/navigation/screens/NavigationScreens'


function LogoutScreen() {

    const navigation = useNavigation<StackNavigationProp<ScreensParamsList, 'logout'>>()

    const route = useRoute<RouteProp<ScreensParamsList, 'logout'>>()

    const { logout } = useSessionStore.getState();
    // const clearUserSubscriptionsStore = useUserSubscriptionsStore.getState().clearStore;

    const logOut = async () => {


        await logout()
        if (route.params?.goTo) {
            // @ts-ignore
            navigation.navigate(route.params?.goTo)
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'guest-welcome' }] })
        }
    }

    React.useEffect(() => {


        console.log('Logout Page')
        logOut()

    }, [])


    return (
        <Background safeInsets>
            <TitleHeaderNty
                goBackHidden
                title='Cerrando sesión...'

            />
            <Loading style={{ position: 'absolute', alignSelf: 'center', top: "40%" }} />
            {/* <View style={{width: "100%", alignItems: 'center'}}>
                <Logo imageStyle={{ alignSelf: 'center', marginTop: 50 }} />
            </View> */}

        </Background>

    )
}

export default LogoutScreen
