import React from "react"
import { Section } from "systemDomain"
import ArticleListHorizontal, { ArticleListHorizontalProps } from "../../../../article/lists/ArticleListHorizontal"
import NtySectionBar, { NtySectionBarProps } from '../../../../nty/section-bar/NtySectionBar'
import { useSectionNav } from "../hooks/useSectionNav"


export type ArticlesSectionProps = {
    section: Section,
    listProps?: ArticleListHorizontalProps,
    sectionStyles?: NtySectionBarProps['styles']
}

function ArticlesSection(props: ArticlesSectionProps) {


    const {goToFunc} = useSectionNav(props.section.goTo)


    return (

        <NtySectionBar title={props.section.title} subtitle={props.section.subtitle} onViewSectionPress={props.section.goTo ? goToFunc : null} styles={props.sectionStyles}>

            <ArticleListHorizontal
                data={props.section.info.data.items.filter(a => a != undefined)}
                paginateEvery={2}
                {...props.listProps}
            />

        </NtySectionBar>
    )
}

export default React.memo(ArticlesSection)
