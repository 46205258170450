import React from 'react';
import { Platform, View } from 'react-native';
import { getAppTheme } from '../../../../core/theme/ThemeBuilder';
import ButtonNty from '../../../components/nty/button/ButtonNty';
import { DEFAULT_DATE_PROMPT_OPTIONS, useDatePrompt } from '../../datePromptContext';

import DateTimePicker from '@react-native-community/datetimepicker';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { hexTransparency } from '../../../../core/jsutils';
import { fixed_colors } from '../../../../core/theme/colors';
import TitleNty from '../../../components/nty/title/TitleNty';


type AndroidMode = 'date' | 'time';
type AndroidDisplay = 'default' | 'spinner' | 'clock' | 'calendar';

type IOSMode = 'date' | 'time' | 'datetime' | 'countdown';
type IOSDisplay = 'default' | 'compact' | 'inline' | 'spinner';

function DatePicker() {


    const theme = getAppTheme()
    const { date, setDate, visible, handleOk, handleCancel, options } = useDatePrompt()
    // console.log('DatePicker', date, visible, mode)

    const renderWeb = () => {

        // console.log('renderWeb', date, visible, options)

        return options.mode == 'date' ?
            //@ts-ignore
            <DatePickerModal
                // locale="en"
                mode="single"
                visible={visible}
                date={date}
                onDismiss={() => { handleCancel() }}
                onConfirm={(params) => { handleOk(params.date) }}
                validRange={options.validDates ? {
                    startDate: options.validDates?.from,  // optional
                    endDate: options.validDates?.to // optional
                    //   disabledDates: [new Date()] // optional
                } : null}
                // onChange={} // same props as onConfirm but triggered without confirmed by user
                saveLabel="Confirmar" // optional
                // saveLabelDisabled={true} // optional, default is false
                // uppercase={false} // optional, default is true
                label={options.title} // optional
            // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
            // startYear={2000} // optional, default is 1800
            // endYear={2100} // optional, default is 2200
            // closeIcon="close" // optional, default is "close"
            // editIcon="pencil" // optional, default is "pencil"
            // calendarIcon="calendar" // optional, default is "calendar"
            />
            :
            <TimePickerModal
                visible={visible}
                onDismiss={() => { handleCancel() }}
                hours={date?.getHours()}
                minutes={date?.getMinutes()}
                onConfirm={(params) => {
                    const hourDate = date ? date : new Date()
                    hourDate.setHours(params.hours)
                    hourDate.setMinutes(params.minutes)
                    handleOk(hourDate)
                }}
                // hours={12} // default: current hours
                // minutes={14} // default: current minutes
                label={options.title} // optional, default 'Select time'
                uppercase={false} // optional, default is true
                cancelLabel="Cancelar" // optional, default: 'Cancel'
                confirmLabel="Confirmar" // optional, default: 'Ok'
                animationType="fade" // optional, default is 'none'
            // locale="en" // optional, default is automically detected by your system
            // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
            // clockIcon="clock-outline" // optional, default is "clock-outline"
            />

    }


    // const ref = useRef<NewsToYouSheetModal>(null)



    const renderIOS = () => {

        // console.log('renderIOS', { date, setDate, visible, mode, display, handleOk, handleCancel })

        const iosMode: IOSMode = options.mode ?? DEFAULT_DATE_PROMPT_OPTIONS.mode
        const iosDisplay: IOSDisplay = options.display ?? DEFAULT_DATE_PROMPT_OPTIONS.display

        if (visible == false) return null

        return <View style={{ height: "100%", width: "100%", position: 'absolute', backgroundColor: hexTransparency(fixed_colors.black[500], 50), justifyContent: 'flex-end' }}>

            <View style={{ flexDirection: 'column', justifyContent: 'center', paddingVertical: 25, backgroundColor: fixed_colors.white[300] }}>

                <TitleNty style={{ marginBottom: 10, marginLeft: 20 }}>{options.title}</TitleNty>

                <DateTimePicker
                    testID="dateTimePicker"
                    value={date}
                    mode={iosMode}
                    style={{ paddingBottom: 10 }}
                    maximumDate={options.validDates?.to}
                    minimumDate={options.validDates?.from}
                    //@ts-ignore
                    accentColor={theme.colors.primary[500]}
                    display={iosDisplay == 'default' ? 'inline' : iosDisplay}
                    is24Hour={true}
                    onChange={(event, date) => {
                        if (event.type && event.type == 'dismissed') {
                            handleCancel()
                        } else {
                            console.log('Selected Date', date)
                            setDate(date)
                        }
                    }}

                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 10 }}>
                    <ButtonNty onPress={handleCancel} mode='text' mergeStyles={{ marginVertical: 3 }}>
                        {'Cancelar'}
                    </ButtonNty>
                    <ButtonNty onPress={() => handleOk(date)} mode='contained' mergeStyles={{ marginVertical: 3 }}>
                        {'Aceptar'}
                    </ButtonNty>
                </View>

            </View>
        </View>


    }

    const renderAndroid = () => {


        const androidMode: AndroidMode = options.mode ?? DEFAULT_DATE_PROMPT_OPTIONS.mode
        const androidDisplay: AndroidDisplay = options.display ?? DEFAULT_DATE_PROMPT_OPTIONS.display

        console.log('renderAndroid', { androidMode, androidDisplay })

        if (visible == false) return null

        //Adroid no sporta colores

        return <DateTimePicker
            testID="dateTimePicker"
            value={date}
            mode={androidMode}
            display={androidDisplay}
            is24Hour={true}
            maximumDate={options.validDates?.to}
            minimumDate={options.validDates?.from}
            onChange={(event, date) => {
                if (event.type && event.type == 'dismissed') {
                    handleCancel()
                } else {
                    setDate(date)
                }
            }}

        />


    }

    const renderNative = () => {

        if (Platform.OS == 'ios') return renderIOS()

        if (Platform.OS == 'android') return renderAndroid()
    }

    // React.useEffect(() => {

    //     if (Platform.OS == 'ios') {
    //         if (visible == true) {
    //             ref.current?.show()
    //         } else {
    //             ref.current?.hide()
    //         }
    //     }

    // }, [visible])

    return (

        <>

            {
                Platform.OS == 'web' ? renderWeb() : renderNative()
            }



        </>
    )

}

export default DatePicker
