import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import ActionApi, { ACTION_NAMES } from "src/api/ActionApi";
import { create, StoreApi } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { QrTrial, QrTrialData, TrialTypes } from "../models/Trial";

export const APP_TRIAL_STORE_KEY = 'appTrialStore'

export type AppTrialStoreType = {

    key: string,
    /**
     * Date ISO String representing the last time the store was updated.
     */
    updated: string,
    /**
     * Date ISO String representing moment the store was completely initialized, that is, the last time the store changed from ready = false to ready = true
     */
    created: string,
    /**
     * Flag that indicates the store is ready to use at this very moment.
     * The store has passed through the 'init()' method almost once. aka 'been propperly created'.
     * This flag __must be used in screens that use the appSessionStore__ in a way (or time) where the store might not be fully well builded.
     * That is, the screens closer to the start point of the execution of the ap, aka: {@link Main}, {@link App}
     */
    ready: boolean,
    /**
     * This flag indicates whether or not this instance of AppSessionStore has been created through re-hydration from {@link AsyncStorage}
     *
     * _Internal flag that should only be used by AppSessionStore implementation._
     *
     */
    _hasHydrated: boolean,

    /**
    * Type of trial the current session is running the app with. Could be One of {@link TrialTypes}
    */
    trial: TrialTypes,
    /**
     * Additional data used for the trial. Could be anything as every type of trial could have his own implementation
     */
    trialData: any,
    /**
     * This method should be only used at application startup.
     *
     * __Do not use from the outside of the AppSessionStore implementation__
     */
    init: () => Promise<void>,
    setHasHydrated: (to: boolean) => void,
    setReady: (to: boolean) => void,
    /**
     * Use this function in order to start a trial for this session.
     *
     * Use this carefully, running this method may override any other existing trial within this session.
     *
     * @returns a trial object containing data of the started trial, null otherwise
     *
     */
    startTrial: (trial: TrialTypes, trialParams: { enterpriseKey: string } | any) => QrTrial

    /**
     * Use this function in order to resume the last run version of a trial for this session.
     *
     * @returns a Promise that resolves when the trial ends.
     *
     */
    resumeTrial: () => QrTrial,

    /**
     * Use this function in order to stop any running trial and clear the data behind for this session.
     *
     * @returns a Promise that resolves when the trial get cleared, no need to respond, just listen to changes on session.trial
     *
     */
    clearTrial: () => void,

    //FAQ
    isTrialActive: (type?: TrialTypes) => boolean,
    checkQrAccess: () => QrTrial

}



const AppTrialStore: (set: StoreApi<AppTrialStoreType>['setState'], get: StoreApi<AppTrialStoreType>['getState']) => AppTrialStoreType =
    (set, get) => ({
        key: APP_TRIAL_STORE_KEY,
        updated: null,
        created: null,
        ready: false,
        trial: TrialTypes.NONE,
        trialData: null,
        _hasHydrated: false,
        setHasHydrated: (to: boolean) => {
            set({ _hasHydrated: to });
        },
        setReady: (to: boolean) => {
            set({ ready: to });
        },
        init: async () => {

            get().checkQrAccess()
            return;
        },
        resumeTrial: () => {

            const lastVersion = get();

            const trialData = { ...lastVersion.trialData };
            const type = lastVersion.trial;

            if (trialData && (type && type != TrialTypes.NONE)) {

                console.log('Resuming Trial...')
                console.log('Trial Type: ', type);
                console.log('Trial Data: ', trialData);

                switch (type) {
                    case TrialTypes.QR:
                        const trial = QrTrial.from(trialData)
                        if (!trial.isExpired()) {
                            trial.start(get, set);
                            return trial
                        } else {
                            console.log('Trial was expired, clearing trial data...');
                            set({ trialData: null, trial: TrialTypes.NONE, updated: new Date().toISOString() })
                            return null
                        }
                    default: return null;
                }
            } else {
                // console.log('No trial to resume')
                return null
            }

        },

        checkQrAccess : () => {

            if(Platform.OS != 'web') {
                return null
            }

            // console.log('Checking Qr Access...')

            try {

                const urlParams = new URLSearchParams(window.location.search);
                const isQr = urlParams.get('qr');
            const enterprise = urlParams.get('enterprise');


            if (isQr == 'true') {

                console.log('Start Trial...', { enterpriseKey: enterprise })
                ActionApi.createFrom({ name: ACTION_NAMES.TRIAL_ACCES, data: { qr: true, enterprise } })
                return get().startTrial(TrialTypes.QR, { enterpriseKey: enterprise });

            } else if (isQr == 'clearQrTrial') {
                console.log('Clear Trial...')
                get().clearTrial();
                return null;
            } else {
                return get().resumeTrial()
            }
            } catch(e) {
                console.log(e)
            }

        },

        startTrial: (trial, trialParams) => {

            if (!get().ready) {
                console.error('session is not ready yet')
                return;
            }

            switch (trial) {
                case TrialTypes.QR:
                    const qrTrial = QrTrial.build(trialParams);
                    console.log('Starting QR trial at: ' + new Date().toISOString())
                    console.log(qrTrial.data);
                    qrTrial.start(get, set);
                    set({ trial: trial, trialData: qrTrial.data });
                    return qrTrial
                case TrialTypes.NONE:
                    return null;

                default: throw new Error('TrialType Not supported: ' + trial);
            }

        },
        clearTrial: () => {

            console.log('Stopping active trials...');
            set({ trial: TrialTypes.NONE, trialData: { cancelled: true } })

        },
        isTrialActive: (type?: TrialTypes) => {

            if (!get()._hasHydrated) {
                console.warn('Session not ready to determine if theres an active trial')
                return false;
            }

            if (!type)
                type = get().trial;

            if (type != TrialTypes.NONE && get().trialData) {

                switch (type) {
                    case TrialTypes.QR:
                        const data: QrTrialData = get().trialData;
                        const trial = QrTrial.from(data);
                        return trial.isActive();

                    default: return false;
                }
            } else {
                return false;
            }
        }
    });



let store;

// for testing purpouses
if (__DEV__ && Platform.OS == 'web') {
    store = devtools(AppTrialStore);
} else {
    store = AppTrialStore;
}

const avoidPersistingThisFields = ['ready', '_hasHydrated'];

const persistedStore = persist(store, {
    name: APP_TRIAL_STORE_KEY,
    storage: createJSONStorage(() => AsyncStorage),
    partialize: (state: AppTrialStoreType) => {

        const toBePersisted: Partial<AppTrialStoreType> = {};
        const keys = Object.keys(state).filter(key => avoidPersistingThisFields.indexOf(key) == -1).filter(key => typeof state[key] !== 'function')

        for (let key of keys) {
            toBePersisted[key] = state[key]
        }

        return toBePersisted;

    },
    onRehydrateStorage: (state: AppTrialStoreType | never) => {


        const onHydrationDone = (state: AppTrialStoreType) => {
            // console.log('Hydrated Store \'' + state.key + '\'');
            hydrationSetup(state);
            state.init().then(() => {
                // console.log('\'' + state.key + '\' initialized!!');
                // state.setReady(true);
            })
        }
        return onHydrationDone;

    }
});


const hydrationSetup = (state: AppTrialStoreType) => {

    state.setReady(true);
    state.setHasHydrated(true);

}

export const useAppTrialStore = create<AppTrialStoreType>()(persistedStore);
