import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Video } from 'expo-av';
import React from 'react';
import { ActivityIndicator, Image, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { IconButton, ProgressBar } from 'react-native-paper';
import { NewspaperModel } from 'systemDomain';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import TextNty from '../../nty/text/TextNty';


export type ArticleAudioProps = {
    url: string;
    newspaper?: NewspaperModel;
    resizeMode?: any;
};

type AVVideoStatus = {
    isLoaded: boolean;
    androidImplementation?: string;
    uri?: string;
    progressUpdateIntervalMillis?: number;
    durationMillis?: number;
    positionMillis?: number;
    playableDurationMillis?: number;
    seekMillisToleranceBefore?: number;
    seekMillisToleranceAfter?: number;
    shouldPlay?: boolean;
    isPlaying?: boolean;
    isBuffering?: boolean;
    rate?: number;
    shouldCorrectPitch?: boolean;
    volume?: number;
    isMuted?: boolean;
    isLooping?: boolean;
    didJustFinish?: boolean;
    error?: string
};

type InteractionStatus = {

    isVideoShowing: boolean;
    isThumbnailLoading: boolean;

};


export default function ArticleAudio({ url, resizeMode, newspaper }: ArticleAudioProps) {


    const theme = useAppTheme()

    const initialVideoStatus = {
        progressUpdateIntervalMillis: 100,
        positionMillis: 0,
        shouldPlay: false,
        rate: 1.0,
        shouldCorrectPitch: false,
        volume: 1.0,
        isMuted: false,
        isLooping: false,
        isLoaded: false
    };

    const [status, setStatus] = React.useState<AVVideoStatus>(initialVideoStatus);
    const [iStatus, setIStatus] = React.useState<InteractionStatus>({ isVideoShowing: false, isThumbnailLoading: true });
    const video = React.useRef(null);
    const lastPositionMilis = React.useRef<number>(null);

    const iconColor = theme.colors.primary[600];
    // const backGroundImage = newspaper?.props?.logo?.rect ?? require('./../../../../assets/logos/logo_nty.png')
    const backGroundImage = {uri: 'https://i.postimg.cc/MTyLTcnb/audiogif.gif'}



    return (
        <TouchableWithoutFeedback
            disabled={true}
            onPress={(event) => {

                event.preventDefault();

                if (iStatus.isVideoShowing == false) {
                    // If we touch the video when the thumb is loaded, then load the actual video
                    setIStatus({ ...iStatus, isVideoShowing: true });

                }

                if (iStatus.isVideoShowing == true) {
                    // If we touch the video when the thumb is loaded, then load the actual video

                    if (video && video.current) {

                        if (status.error) {
                            setIStatus({ ...iStatus, isVideoShowing: false });
                        } else

                            if (status.isPlaying) {

                                lastPositionMilis.current = status.positionMillis;
                                video.current.stopAsync();
                                setIStatus({ ...iStatus, isVideoShowing: false });

                            } else {
                                if (lastPositionMilis.current) {
                                    video.current.playFromPositionAsync(lastPositionMilis.current, { toleranceMillisAfter: 500, toleranceMillisBefore: 500 });
                                } else {
                                    video.current.playAsync();
                                }
                            }
                    }

                }
            }}
            //disabled={status.error && status.error.length > 1}
            style={{ flex: 1 }}
        >
            <View style={{ flex: 1 }}>

                {
                    iStatus.isVideoShowing ?

                        <>


                            <View>

                                <Video
                                    ref={video}
                                    style={{
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    source={{
                                        uri: url,
                                    }}
                                    resizeMode={resizeMode ?? 'contain'}
                                    onLoad={() => {
                                        if (lastPositionMilis.current) {
                                            video.current ? video.current.playFromPositionAsync(lastPositionMilis.current) : false;
                                        } else {
                                            video.current ? video.current.playFromPositionAsync(lastPositionMilis.current) : false;
                                        }
                                    }}
                                    isLooping
                                    onPlaybackStatusUpdate={(status) => { setStatus(status); }}
                                    onError={(error) => { console.log(error); }}
                                />
                                {status.error && status.error.length > 1 &&
                                    <View
                                        style={{ position: 'absolute', top: 0, width: "100%", flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.colors.background[500] + '10', paddingHorizontal: 30, borderWidth: 1, height: '100%' }}
                                    >
                                        <MaterialCommunityIcons
                                            name={'alert-circle'}
                                            color={theme.colors.primary[700]}
                                            size={25}
                                            style={{ marginLeft: 10 }}
                                        />
                                        <TextNty style={{ fontSize: 10 }}>{status.error ?? 'Unknown error'}</TextNty>
                                        <IconButton
                                            icon={'refresh'}
                                            color={theme.colors.primary[700]}
                                            size={25}
                                            style={{ marginRight: 10 }}
                                            onPress={() => {
                                                video.current.unloadAsync();
                                                setIStatus({ ...iStatus, isVideoShowing: false })
                                            }}
                                        />
                                    </View>
                                }
                                {status && status.isPlaying && <Image source={require('./../../../../assets/logos/audio-wave.gif')} style={{ height: "100%", width: "100%", resizeMode: 'cover', position: 'absolute', top: 0 }} />}
                                {/* <Text>{(status.positionMillis * 100 / status.durationMillis)/100}</Text> */}
                                {status && status.isPlaying && status.positionMillis && status.durationMillis ? <ProgressBar progress={Math.round((status.positionMillis * 100 / status.durationMillis)) / 100} color={theme.colors.primary[700]} style={{ position: 'absolute', bottom: 0 }} /> : null}
                            </View>
                            {status && !status.error && !status.isLoaded && status.isBuffering &&
                                <ActivityIndicator size={50} color={theme.colors.primary[700]} style={[StyleSheet.absoluteFillObject, { zIndex: 1, backgroundColor: status.isPlaying ? 'transparent' : theme.colors.background[500] + '10' }]} />
                            }
                        </>
                        :
                        <>


                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <MaterialCommunityIcons
                                    name={'music'}
                                    color={iconColor}
                                    size={30}
                                    style={[StyleSheet.absoluteFillObject, { zIndex: 1, backgroundColor: 'transparent', marginLeft: 10, marginTop: 10 }]} />
                                <Image source={backGroundImage} style={{ width: "100%", height: 200 }} />
                                <View style={[StyleSheet.absoluteFillObject, { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.colors.background[500] + '10' }]}>
                                    <View style={{ width: 90, height: 90, borderRadius: 45, backgroundColor: theme.colors.background[300], flexDirection: 'row', justifyContent: 'center', alignItems: 'center', elevation: 1 }}>
                                        <IconButton icon={'play'} size={90} color={iconColor} />
                                    </View>
                                </View>
                            </View>

                            {/* {iStatus.isThumbnailLoading ?
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <MaterialCommunityIcons
                                        name={'video'}
                                        color={theme.colors.ntyColor}
                                        size={30}
                                        style={[StyleSheet.absoluteFillObject, { zIndex: 1, backgroundColor: 'transparent', marginLeft: 10, marginTop: 10 }]} />
                                    <Logo />
                                    <ActivityIndicator size={50} color={theme.colors.ntyColorDark} style={[StyleSheet.absoluteFillObject, { zIndex: 1, backgroundColor: status.isPlaying ? 'transparent' : 'rgba(0,0,0,0.2)' }]} />
                                </View>
                                :
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <MaterialCommunityIcons
                                        name={'video'}
                                        color={theme.colors.ntyColor}
                                        size={30}
                                        style={[StyleSheet.absoluteFillObject, { zIndex: 1, backgroundColor: 'transparent', marginLeft: 10, marginTop: 10 }]} />
                                    <Image source={thumb == "error" ? require('./../../../../assets/logos/thumbnail_ko.png') : { uri: thumb }} style={{ resizeMode: 'cover', width: "100%", height: "100%" }} />
                                </View>
                            } */}

                        </>

                }
            </View>
        </TouchableWithoutFeedback>
    )
}
