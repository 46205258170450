import { Picker, PickerProps } from '@react-native-picker/picker'
import { ItemValue } from '@react-native-picker/picker/typings/Picker'
import React from 'react'
import { View, ActivityIndicator, StyleSheet, ViewStyle, Platform, StyleProp, TextStyle } from 'react-native'
import TextNty from 'src/app/components/nty/text/TextNty'
import { hexTransparency } from 'src/core/jsutils'
import { useAppTheme } from 'src/core/theme/ThemeBuilder'
import { fixed_colors } from 'src/core/theme/colors'

type NtyPickerProps<T = ItemValue> = {
    pickerTitle?: string,
    titleFontSize?: number,
    loading?: boolean,
    containerStyle?: ViewStyle,
    pickerStyle?: TextStyle,
    titleStyle?: TextStyle,
} & PickerProps<T>



export function NtyPicker<T=string | number>(props: NtyPickerProps<T>) {

const theme = useAppTheme()

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        ...Platform.select({
            ios: {
                borderBottomColor: hexTransparency(theme.colors.text_primary[300], 20),
                borderBottomWidth: 1
            },
            android: {
                borderColor: hexTransparency(theme.colors.text_primary[300], 20),
                borderBottomWidth: 1,
                borderRadius: 10,
                alignSelf: 'center',
                marginTop: 15,
                marginLeft: 20,
                marginRight: 20,
            },
            web: {
                borderColor: hexTransparency(theme.colors.text_primary[300], 20),
                borderBottomWidth: 1,
                borderRadius: 15,
                alignSelf: 'center',
                marginTop: 15,
                paddingHorizontal: 0,
                paddingVertical: 5,
            },

        }),
    },
    picker: {
        flex: 1,
        ...Platform.select({
            ios: {},
            android: {},
            web: {
                paddingVertical: 10,
                paddingHorizontal: 10,
                borderRadius: 40,
                borderColor: fixed_colors.grey[300],
                fontSize: 18,
                textAlign: 'center',
                fontFamily: theme.fonts.default.regular.fontFamily,
            }
        }),
    },
    title: {
        marginRight: 10,
        fontWeight: '600',
        fontSize: Platform.OS === 'ios' ? 22 : 20,
    },
});

    return (
        <View style={[styles.container, props.containerStyle]}>
            {props.pickerTitle && <TextNty style={{...styles.title, ...props.titleStyle}}>{props.pickerTitle}</TextNty>}
            <Picker<T>
                style={{ flex: 1, ...styles.picker, ...props.pickerStyle }}
                prompt={props.pickerTitle ?? 'Selecciona una opción'}
                dropdownIconColor={'#000'}
                {...props}
            >
                {props.children}
            </Picker>
            {props.loading != undefined && <ActivityIndicator animating={props.loading} />}
        </View>
    )
}
