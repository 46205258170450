import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { memo } from "react";
import { Linking, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { ActivityIndicator, Card, Dialog, Paragraph, Portal, Title } from 'react-native-paper';
import { getLocalhost } from '../../../environments';
import { useAppTheme } from '../../core/theme/ThemeBuilder';

import { StackNavigationProp } from '@react-navigation/stack';
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens';
import Background from "../Background";
import ButtonNty from '../components/nty/button/ButtonNty';
import TextNty from '../components/nty/text/TextNty';
import TitleNty from "../components/nty/title/TitleNty";
import { useAlert } from '../context/alertContext';
import Payments from '../lib/Payments';

type Props = {
    navigation: StackNavigationProp<ScreensParamsList, 'plans'>;
    setHeaderBorder: Function;
    setHeaderStyle: Function;
    route: any;
};

const PlansScreen = ({ navigation, setHeaderBorder, setHeaderStyle, route }: Props) => {

    const theme = useAppTheme()

    const styles = React.useMemo(() => StyleSheet.create({
        card: {
            borderWidth: 0.5,
            borderColor: 'rgba(0,0,0,0.1)',
            borderRadius: 15,
            margin: 10,
            backgroundColor: theme.colors.background[400]
        },
        text: {
            textAlign: 'center',
            fontWeight: '700',
            fontSize: 24,
            lineHeight: 26,
            marginBottom: 10
        },
        textPolicy: {
            textAlign: 'center',
            fontWeight: '700',
            fontSize: 14,
            lineHeight: 16,
            color: theme.colors.links[400]

        }
    }), [theme])


    const [plans, setPlans] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // const { session, user, isGuest } = useAppSessionStore((store) => ({ session: store.session, user: store.user, isGuest: store.isGuest }));
    const [visible, setVisible] = React.useState<boolean>(false);
    // const showRegisterModal = useRegisterModal(store => store.show)


    const showDialog = () => {
        setVisible(true);
    }
    const hideDialog = () => {
        setVisible(false);
    }


    // const readPlans = async () => {
    //     setLoading(true)
    //     const plans = await Payments.getPlans();
    //     console.log(plans);
    //     setPlans(plans);
    //     setLoading(false);
    // }

    React.useEffect(() => {
        // if (user && user.email && !isGuest()) {
        //     Payments.init(user.email);
        //     readPlans();
        // } else {
        //     showRegisterModal();
        // }
    }, [])

    const getMonthlyOrAnualPrice = (name: string) => {
        return name.includes('Anual') ? "año" : "mes";
    }

    // React.useEffect(() => {
    //     setHeaderStyle('none'); setNewspaperTag('');
    // }, [])

    React.useLayoutEffect(() => {
        // readPlans()
        useAlert.getState().alert({ title: 'Planes Premium', message: 'En estos momentos no hay planes disponibles. Disculpa las molestias.' }).then(() => navigation.navigate('home'))
    }, [])


    return (
        <Background safeInsets>
            <View style={{ width: '100%', maxWidth: 600, alignSelf: 'center', justifyContent: 'center', flex: 1 }}>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center', width: '100%', marginBottom: 60 }}>
                    <MaterialCommunityIcons
                        name="chevron-left"
                        color={theme.colors.text_primary[500]}
                        size={30}
                        style={{ marginRight: 10 }}
                        onPress={() => {
                            navigation.canGoBack() ? navigation.goBack() : navigation.navigate('home');
                        }}
                    />
                    {/* <IconButton
                        color={theme.colors.text_primary[500]}
                        size={24}
                        icon='chevron-left'
                        onPress={() => {
                            navigation.canGoBack() ? navigation.goBack() : navigation.navigate('home');
                        }
                        }
                    /> */}
                    <TextNty style={styles.text}>Planes Premium</TextNty>
                    <MaterialCommunityIcons
                        name="information-outline"
                        color={theme.colors.text_primary[500]}
                        size={24}
                        style={{ marginLeft: 10 }}
                        onPress={() => showDialog()}
                    />
                </View>
                <View style={{ width: '90%', alignSelf: 'center', justifyContent: 'center', flex: 1, marginVertical: 10 }}>
                    {/* <Text style={styles.textPolicy} onPress={() => Linking.openURL(getLocalhost + "/api/v1/policy")}>Términos y condiciones</Text> */}
                    <View style={{ flex: 20 }}>
                        <ScrollView
                            scrollEventThrottle={1}
                        >
                            {
                                plans != undefined && plans.length > 0 ?
                                    plans.map((plan: any, index: number) =>
                                    (
                                        <Card
                                            style={styles.card}
                                            key={index}
                                            elevation={5}
                                            onPress={() => Payments.purchase(navigation, plan)}
                                        >
                                            <Card.Content style={{ flex: 1 }}>
                                                {/* { Platform.OS !== 'ios' && <Image source={{ uri: plan.product?.images[0] }} style={{ resizeMode: 'contain', width: 50, height: 50, alignSelf: 'center' }} />} */}
                                                <TitleNty style={{ textAlign: 'center' }}>{plan.product.name}</TitleNty>
                                                <Paragraph style={{ textAlign: 'center', marginVertical: 30 }}>{plan.product.description}</Paragraph>
                                            </Card.Content>
                                            <View style={{ paddingBottom: 20, paddingHorizontal: 20, justifyContent: "flex-end", alignSelf: 'center' }}>
                                                <TitleNty color={theme.colors.primary[500]}>{(parseFloat(plan.amount) / 100).toFixed(2) + " €/" + getMonthlyOrAnualPrice(plan.product.name)}</TitleNty>
                                            </View>
                                        </Card>
                                    ))
                                    :
                                    plans.length == 0 && !loading ?
                                        <Card
                                            style={styles.card}
                                            elevation={5}
                                        >
                                            <Card.Content style={{ flex: 1 }}>
                                                {/* { Platform.OS !== 'ios' && <Image source={{ uri: plan.product?.images[0] }} style={{ resizeMode: 'contain', width: 50, height: 50, alignSelf: 'center' }} />} */}
                                                <TitleNty style={{ textAlign: 'center' }}>No hay planes disponilbles</TitleNty>
                                                <Paragraph style={{ textAlign: 'center', marginVertical: 30 }}>Es posible que estemos realizando mantenimientos a la pasarela de pago. Disculpa las molestias.</Paragraph>
                                            </Card.Content>
                                        </Card>
                                        :
                                        <ActivityIndicator color={theme.colors.primary[500]} size={50} />
                            }
                        </ScrollView>
                    </View>
                </View>
            </View>

            {Platform.OS == 'ios' && <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10, alignSelf: 'center' }}>
                <TextNty style={{ textDecorationLine: 'underline', color: theme.colors.links[500] }} onPress={() => Linking.openURL("https://www.apple.com/legal/internet-services/itunes/dev/stdeula")}>Terms of use (EULA)</TextNty><MaterialCommunityIcons name="apple-ios" size={15} color={'black'} />
            </View>}
            {Platform.OS == 'ios' && <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10, alignSelf: 'center', paddingVertical: 10 }}>
                <ButtonNty mode='text' onPress={async () => { await Payments.restorePurchases() }}>Restaurar Compras</ButtonNty>
            </View>}
            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10, alignSelf: 'center' }}>
                <TextNty fontSize={13} onPress={() => Linking.openURL(getLocalhost + "/api/v1/policy")}>Política de privacidad |</TextNty>
                <TextNty fontSize={13} onPress={() => showDialog()}> Condiciones del servicio</TextNty>
            </View>
            <View style={{ width: '80%', maxWidth: 200, paddingTop: 10, alignSelf: 'center', marginBottom: 20 }}>
                <ButtonNty mode='outlined' onPress={() => navigation.navigate('home')}>Atrás</ButtonNty>
            </View>
            <Portal>
                <Dialog visible={visible} style={{ padding: 30, maxWidth: 600, alignSelf: 'center' }} onDismiss={hideDialog}>
                    <Title>Detalles de suscripciones</Title>
                    <Paragraph style={{}}>
                        {"Tus pagos van a ser cargados en tu cuenta de iTunes una vez confirmes tu mejora al plan premium.\n\n"
                            + "Los cargos del pago se van a aplicar nuevamente a tu cuenta de iTunes " +
                            "una vez tu suscripcion se renueve ( de forma automática) al llegar al final de periodo de tu actual suscripción." +
                            " Esta operación va a ser periódica a no ser que la renovación del plan se anule con almenos 24 horas antes" +
                            "de que acabe el actual periodo de suscripción.\n\n"
                            + "Puedes administrar o anular la renovación automática en Ajustes de cuenta > Apple ID en cualquier momento despues de contratar el plan premium."}
                    </Paragraph>
                </Dialog>
            </Portal>
        </Background>
    );
}



export default memo(PlansScreen);
