import React from 'react';
import { ListRenderItemInfo, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import LoadingIndicator from 'src/app/components/loaders/Loading';
import SectionComponent from 'src/app/components/new-home/components/sections/Section';
import useSessionStore from 'src/context/sessionStore';
import { DefinedSection, DefinedSectionTypesNames } from 'systemDomain';
import { getAppTheme, useAppTheme } from '../../../../core/theme/ThemeBuilder';
import LoadMoreButton from '../../../components/LoadMoreButton';
import WebTopBar from './components/WebTopBar';





const HomeSectionsSession = () => {

    // const navigation = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()
    const theme = getAppTheme()
    const [shownTabs, setShownTabs] = React.useState<DefinedSection<DefinedSectionTypesNames.FEED | DefinedSectionTypesNames.SECTION_ARTICLES>[]>();
    // const { sections } = useAppSessionStore((store) => ({ sections: store.sessionSections }));
    const {sections} = useSessionStore((store) => ({sections: store.sections}))
    // const sections = []

    React.useEffect(() => {

        // if(!sections) {
        //     useAppSessionStore.getState().buildTabs(true)
        // }

        if (sections && sections?.length && shownTabs == null)
            setShownTabs(sections?.slice(0, 9))
    }, [sections])


    // const renderItem = (item: ListRenderItemInfo<TabsDefinition>) => {
    //     const tab = item.item;
    //     return tab.data?.isEnterprise ?
    //         <EnterpriseFeedHList feed={tab.data?.feed} key={tab.key} />
    //         :
    //         <ViewCategoryContent categoryLabel={tab.label} categoryKey={tab.name} key={tab.key} />

    // }
    const renderItem = (item: ListRenderItemInfo<DefinedSection<DefinedSectionTypesNames.FEED>>) => {
        const tab = item.item;
        return <SectionComponent section={tab} key={tab.title + item.index + Date.now()} />
    }

    const onLoadMore = () => {

        const sliceStart = shownTabs.length;
        const sliceEnd = sliceStart + 10;

        if (sliceEnd > sections.length) {
            setShownTabs(sections);
        } else {
            setShownTabs([...shownTabs, ...sections.slice(sliceStart, sliceEnd)])
        }


    }


    return (
        <View style={{ flex: 1 }}>
            {shownTabs && shownTabs.length > 0 ?

                <FlatList
                    ListHeaderComponent={
                        <WebTopBar/>
                        // <WebHeader />
                    }
                    keyExtractor={(section) => section.title + Date.now()}
                    data={shownTabs}
                    renderItem={renderItem}
                    onEndReached={null}
                    numColumns={1}
                    contentContainerStyle={{ flex: 1, paddingBottom: 60 }}
                    ListFooterComponent={() => (<View style={{ paddingTop: 10, paddingBottom: 50 }}>{shownTabs?.length < sections?.length ? <LoadMoreButton onLoadMore={onLoadMore} /> : <View style={{ height: 10, width: 10, backgroundColor: theme.colors.secondary[600], borderRadius: 5, alignSelf: 'center' }}></View>}</View>)}
                    showsVerticalScrollIndicator={false}

                />
                :
                <LoadingIndicator style={{position: 'absolute', top: "20%", left: "50%"}}/>
            }

        </View>
    )
};

export default HomeSectionsSession;

