import { MaterialCommunityIcons } from "@expo/vector-icons"
import { View } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"
import TitleNty from "src/app/components/nty/title/TitleNty"
import { getAppTheme } from "src/core/theme/ThemeBuilder"

const theme = getAppTheme()



type ListItemProps = {
    label: string,
    description?: string,
    icon?: string | { color: string, size: number, name: string },
    onPress?: () => void
}

const defaultIconSize = 35

export function ListItem({ label, icon, onPress, description }: ListItemProps) {
    return (
        <TouchableOpacity style={{ paddingHorizontal: 20, paddingVertical: 20, flexDirection: 'row', width: "100%" }} containerStyle={{width: "100%"}} onPress={onPress}>

            {icon && <View style={{ marginRight: 10, flex: 1, justifyContent: 'center' }}>
                {
                    typeof icon == 'string' ?
                        // @ts-expect-error
                        <MaterialCommunityIcons name={icon} size={defaultIconSize} color={theme.colors.text_primary[300]} />
                        :
                        // @ts-expect-error
                        <MaterialCommunityIcons name={icon.name} size={icon.size ?? defaultIconSize} color={icon.color ?? theme.colors.text_primary[300]} />
                }
            </View>}

            <View style={{ flex: 4, justifyContent: 'center', paddingHorizontal: 10, minWidth: 100 }}>
                <TitleNty fontSize={18} type='medium'>{label}</TitleNty>
                {description && <TitleNty fontSize={14} type='light' color={theme.colors.text_primary[400]}>{description}</TitleNty>}
            </View>

            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                <MaterialCommunityIcons name={'chevron-right'} size={defaultIconSize} color={theme.colors.text_primary[300]} />
            </View>

        </TouchableOpacity>
    )
}
