import React from 'react'
import { View } from 'react-native'
import WelcomeHeaderNew from 'src/app/components/new-home/WelcomeHeaderNew'
import { useLogInControl } from 'src/app/hooks/useLogInControl'
import useSessionStore from 'src/context/sessionStore'
import { fixed_colors } from 'src/core/theme/colors'
import ScrollHeaderScreen from '../../common-ui/ScrollHeaderScreen'
import TextNty from '../../nty/text/TextNty'
import TitleNty from '../../nty/title/TitleNty'
import HomeEnterprise from './HomeEnterprise'

function HomeNew() {

    // const sessionReady = useAppSessionStore(store => store.ready)
    // const { isEnterprise } = useAppSessionStore(store => ({ isEnterprise: store.isEnterprise }))
    const enterprise = useSessionStore(store => store.enterprise)

    useLogInControl()



    return (
        enterprise ? <HomeEnterprise /> :
            <ScrollHeaderScreen

                headerHeight={120}
                headerBgColor={'transparent'}
                header={
                    <WelcomeHeaderNew iconsColor={fixed_colors.black[500]} />
                    // <TextNty style={{ color: fixed_colors.black[500], fontSize: 20, fontWeight: 'bold' }}>No tienes ninguna empresa asignada</TextNty>
                    // <View/>
                }
            >


                <View style={{marginHorizontal: 30}}>
                <TitleNty style={{marginBottom: 30, marginTop: 50}}>No tienes ninguna empresa asignada </TitleNty>
                <TextNty style={{ color: fixed_colors.black[500], fontSize: 16 }}>Contacta a tu administrador para que se te asigne una empresa.</TextNty>
                </View>



            </ScrollHeaderScreen>
    )
}

export default HomeNew
