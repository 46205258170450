import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { memo, MutableRefObject } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Button, IconButton, ToggleButton } from "react-native-paper";
import Swiper from "react-native-swiper/src";
import {
  TextThemeColor,
  useTextStylesStore
} from "../../context/TextStylesStore";
import ColorIcon from "./ColorIcon";
import TextNty from "./nty/text/TextNty";




type Props = {
  swiperRef?: MutableRefObject<Swiper>;
  navigation?: any;
  onClose?: () => void;
};

const BottomSheetTextStylesPremium = ({
  swiperRef,
  onClose,
}: Props) => {

  const {
    fontSize,
    fontFamily,
    lineHeight,
    incrementLineHeight,
    decrementLineHeight,
    setTextAlign,
    decrementFontSize,
    incrementFontSize,
    toggleBold,
    textTheme,
    changeThemeColor
  } = useTextStylesStore();

  // FONT
  const increaseFontSize = () => {
    incrementFontSize();
  };

  const decreaseFontSize = () => {
    decrementFontSize();
    //requestWebViewReload.next(true);
  };

  const changeBold = () => {
    toggleBold();
  };

  //Line Height
  const increaseLineHeigth = () => {
    incrementLineHeight();
  };

  const decreaseLineHeigth = () => {
    decrementLineHeight();
    //requestWebViewReload.next(true);
  };


  const alignTextTo = (string) => {
    setTextAlign(string);
  };

  // FONT


  // THEME
  const [currentTextThemeName, setCurrentTextThemeName] = React.useState(null);

  const changeTextTheme = (themeName) => {
    const theme = TextThemeColor.getTheme(themeName);

    if (theme) {
      setCurrentTextThemeName(theme.name);
      changeThemeColor(theme);
    }
  };

  const goBack = () => {
    swiperRef.current
    swiperRef.current.scrollTo(0, true);
  }
  // THEME
  React.useEffect(() => {

    if(currentTextThemeName != textTheme.name) {
      setCurrentTextThemeName(textTheme.name)
    }
  }, [textTheme]);

  return (
    <View style={{ paddingHorizontal: 20, flex: 1}}>
      {/* COLOR THEME */}
      <View style={[styles.itemRow, styles.flex2]}>
        <View style={styles.itemContent}>
          <ToggleButton.Row
            onValueChange={(value) => changeTextTheme(value)}
            value={currentTextThemeName}
          >
            <ToggleButton
              icon={({ size }) => (
                <ColorIcon
                  selected={currentTextThemeName == TextThemeColor.default.name}
                  color={TextThemeColor.default.bgColor}
                  size={size}
                />
              )}
              size={30}
              value={TextThemeColor.default.name}
              style={{ height: 60, width: 60 }}
            />
            <ToggleButton
              icon={({ size }) => (
                <ColorIcon
                  selected={
                    currentTextThemeName == TextThemeColor.newspaper.name
                  }
                  color={TextThemeColor.newspaper.bgColor}
                  size={size}
                />
              )}
              size={30}
              value={TextThemeColor.newspaper.name}
              style={{ height: 60, width: 60 }}
            />
            <ToggleButton
              icon={({ size }) => (
                <ColorIcon
                  selected={
                    currentTextThemeName == TextThemeColor.newspaperSepia.name
                  }
                  color={TextThemeColor.newspaperSepia.bgColor}
                  size={size}
                />
              )}
              size={30}
              value={TextThemeColor.newspaperSepia.name}
              style={{ height: 60, width: 60 }}
            />

            <ToggleButton
              icon={({ size }) => (
                <ColorIcon
                  selected={
                    currentTextThemeName == TextThemeColor.newspaperDark.name
                  }
                  color={TextThemeColor.newspaperDark.bgColor}
                  size={size}
                />
              )}
              size={30}
              value={TextThemeColor.newspaperDark.name}
              style={{ height: 60, width: 60 }}
            />
          </ToggleButton.Row>
        </View>
      </View>

      {/* FONT */}
      <View style={[styles.itemRow, styles.flex3, styles.borderBottom, styles.borderTop]}>
        {/* FONT SIZE */}
        <View style={[styles.itemContent, styles.flex3, styles.borderRight]}>
            <IconButton icon={"minus"} onPress={decreaseFontSize} />
          <View style={[styles.itemCol, { alignItems: "center" }]}>
            <MaterialCommunityIcons
              name="format-size"
              color={"black"}
              size={40}
            />
            <TextNty>{fontSize}</TextNty>
          </View>
          <IconButton icon={"plus"} onPress={increaseFontSize} />
        </View>
        {/* Line Spacing */}
        <View style={[styles.itemContent, styles.flex3]}>
            <IconButton icon={"minus"} onPress={decreaseLineHeigth} />
          <View style={[styles.itemCol, { alignItems: "center" }]}>
            <MaterialCommunityIcons
              name="format-line-spacing"
              color={"black"}
              size={40}
            />
            <TextNty>{lineHeight}</TextNty>
          </View>
          <IconButton icon={"plus"} onPress={increaseLineHeigth} />
        </View>
      </View>

      {/* Font Family */}
      <View style={[styles.itemCol, styles.flex1, styles.borderBottom]}>
        <View style={styles.itemContent}>

          <Button
          style={{width: "100%"}}
          labelStyle={{fontFamily: fontFamily, fontSize: 20}}
          contentStyle={{justifyContent: 'center'}}
          onPress={()=>swiperRef.current.scrollTo(3, true)}
          icon="format-font">
              Fuente

          </Button>
          {/* <TouchableRipple onPress={()=>swiperRef.current.scrollTo(3, true)}>
              <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <Title style={{fontFamily: fontFamily}}>Fuente</Title>
              </View>
          </TouchableRipple> */}
        </View>
      </View>

      {/* Text ALIGN */}
      <View style={[styles.itemCol, styles.flex1]}>
        <View style={styles.itemContent}>
          <IconButton
            icon={"format-align-left"}
            color={"black"}
            size={30}
            onPress={() => alignTextTo("left")}
          ></IconButton>

          <IconButton
            icon={"format-align-center"}
            color={"black"}
            size={30}
            onPress={() => alignTextTo("center")}
          ></IconButton>

          <IconButton
            icon={"format-align-justify"}
            color={"black"}
            size={30}
            onPress={() => alignTextTo("justify")}
          ></IconButton>

          <IconButton
            icon={"format-align-right"}
            color={"black"}
            size={30}
            onPress={() => alignTextTo("right")}
          ></IconButton>
        </View>
      </View>

      {/* Go Back */}

      <Pressable
      onPress={()=>swiperRef.current.scrollBy(-1, true)}
      style={[styles.itemRow, {flex:1, justifyContent: 'flex-start', alignItems: 'center'}]}>
      <MaterialCommunityIcons
          name="chevron-left"
          color={"black"}
          size={20}
        />
        <TextNty>Atrás</TextNty>
      </Pressable>


      </View>
  );
};

const styles = StyleSheet.create({
  fontButtonContainer: {
    flex: 1,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    borderWidth: 3,
    borderRadius: 10,
    maxWidth: 100,
  },
  fontButton: {
    width: "99%",
    height: "99%",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  itemContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  tittleItem: {
    flex: 1,
    alignContent: "center",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  itemCol: {
    flexDirection: "column",
    paddingVertical: 5,
  },
  itemRow: {
    flexDirection: "row",
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "rgba(0,0,0,0.1)",
  },
  borderLeft: {
    borderLeftWidth: 1,
    borderLeftColor: "rgba(0,0,0,0.1)",
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: "rgba(0,0,0,0.1)",
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: "rgba(0,0,0,0.1)",
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex3: {
    flex: 3,
  },
});

export default memo(BottomSheetTextStylesPremium);
