import { ApplicablePublisherSearchResponse, Article, ArticleSearchFilters, SearchResponse } from "systemDomain";
import { SearchOptions } from "../context/appSearchStore";
import { buildApiUrl, downloadFile, http_request } from "../core/http-utils";

export class SearchApi {


    static async searchArticles(search: ArticleSearchFilters, options?: SearchOptions): Promise<SearchResponse<Article>> {

        const page = options.page || { skip: 0, limit: 10 }
        let url

        url = buildApiUrl('/articles/search', true)

        if (page.skip) {
            url.searchParams.append('skip', page.skip.toString())
        }
        if (page.limit) {
            url.searchParams.append('limit', page.limit.toString())
        }

        return await http_request<SearchResponse<Article>>(url, 'POST', { search, options })
    }

    static async searchArticlesExport(search: ArticleSearchFilters, options?: SearchOptions): Promise<any> {

        const page = options.page || { skip: 0, limit: 10 }
        let url

        url = buildApiUrl('/articles/search/export', true)

        if (page.skip) {
            url.searchParams.append('skip', page.skip.toString())
        }
        if (page.limit) {
            url.searchParams.append('limit', page.limit.toString())
        }

        return await downloadFile({url, method: 'POST', params: { search, options }})
    }

    static async searchArticlesPublishers(search: ArticleSearchFilters, nameSearch?: string): Promise<ApplicablePublisherSearchResponse> {


        let url

        url = buildApiUrl('/articles/search/brands', true)


        const body: any = { search }

        if (nameSearch && nameSearch.length > 0) {
            body.nameRegex = nameSearch
        }


        return await http_request<ApplicablePublisherSearchResponse>(url, 'POST', body)
    }
}
