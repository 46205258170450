import { MaterialCommunityIcons } from "@expo/vector-icons";
import { BottomTabNavigationEventMap, BottomTabNavigationOptions, createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { EventArg, RouteConfig, TabNavigationState } from "@react-navigation/native";
import React from "react";
import { Platform, StatusBar } from "react-native";
import ActionApi, { ACTION_NAMES } from "../../api/ActionApi";
import HomeWebPage from "../../app/pages/home/web/HomeWebPage";
import FavoritesTab from "../../app/tabs/FavoritesTab";
import { getAppTheme } from "../theme/ThemeBuilder";

import * as Haptics from 'expo-haptics';
import HomeNew from "../../app/components/new-home/pages/HomeNew";
import SearchPage from "../../app/components/search/SearchPage";
import { fixed_colors } from "../theme/colors";
import { DateFilterType, defaultDateFilter, useSearchStore } from "src/context/appSearchStore";
import moment from "moment";
import { ArticleSearchOrderBy, SortDirection } from "systemDomain";




export const getIcon = (name: any, size?: number, color?: string) => {
    return (props) => <MaterialCommunityIcons color={ color ?? props.color} size={size ?? props.size} name={name} />
}

export const bottomNavigationAction = async (screenName: string) => {
    ActionApi.createFrom({ name: ACTION_NAMES.BOTTOM_NAVIGATION, data: { screen: screenName, platform: Platform.OS } });
}

export type MainBottomTabsParamList = {
    "noticias": undefined,
    "for-you": undefined,
    explore: undefined,
    library: undefined,
    "quick-news": undefined | { articleId?: string, newspaper?: string }
}

export type BottomTabsScreenDefinition = RouteConfig<MainBottomTabsParamList, keyof MainBottomTabsParamList, TabNavigationState<MainBottomTabsParamList>, BottomTabNavigationOptions, BottomTabNavigationEventMap>
const BottomTabs = createBottomTabNavigator<MainBottomTabsParamList>()

function MainBottomTabsNavigator() {

    // console.log('MainBottomTabsNavigator')



    const theme = getAppTheme()
    // const { isGuest, ready, isEnterprise } = useAppSessionStore((store) => ({ isGuest: store.isGuest, ready: store.ready, isEnterprise: store.isEnterprise }))

    const defaultScreenOptions: BottomTabNavigationOptions = {
        tabBarActiveTintColor: theme.colors?.background[200]?.toString() ?? fixed_colors.white[300].toString(),
        tabBarInactiveTintColor: theme.colors?.background?.[700]?.toString() ?? fixed_colors.white[500].toString(),
        unmountOnBlur: false,
        lazy: false,
        tabBarStyle: { backgroundColor: theme.colors.primary[600] },
        tabBarLabelStyle: { fontSize: 12, fontWeight: 'bold', fontFamily: theme.fonts.default.bold.fontFamily },
        headerShown: false,
        headerTitle: null,
        tabBarLabel: '',
        tabBarIcon: null
    }
    // const defaultScreenOptions: BottomTabNavigationOptions = {
    //     tabBarActiveTintColor: theme.colors.primary[500].toString(),
    //     tabBarInactiveTintColor: theme.colors.secondary[300].toString(),
    //     unmountOnBlur: false,
    //     lazy: false,
    //     tabBarStyle: { backgroundColor: theme.colors.background[300] },
    //     tabBarLabelStyle: { fontSize: 12, fontWeight: 'bold', fontFamily: theme.fonts.default.bold.fontFamily },
    //     headerShown: false,
    //     headerTitle: null,
    //     tabBarLabel: '',
    //     tabBarIcon: null
    // }

    // const navigation = useNavigation<StackNavigationProp<ScreensParamsList>>()


    //  React.useEffect(() => {

    //     const result = !isGuest() || useAppTrialStore.getState().isTrialActive()
    //     console.log('Calculating isLoggedIn...', result)


    //     if(result == false)  {
    //         const state = navigation.getState()
    //         const loggedOutRoutes = ['changepassword', 'forgotpassword', 'logout', 'login', 'register', 'guest-welcome', 'register-enterprise']
    //         if(loggedOutRoutes.indexOf(state.routes[state.index].name) == -1) {
    //             console.log('Not logged in in a prohibited route!')
    //             navigation.reset({routes: [{name: 'guest-welcome'}]})
    //         }

    //     }

    // }, [ready])


    return (

        <BottomTabs.Navigator>
            {Platform.OS != 'web' && <BottomTabs.Screen
                navigationKey='for-you'
                name='for-you'
                options={{
                    ...defaultScreenOptions,
                    tabBarLabel: 'Para Ti',
                    tabBarIcon: getIcon('home'),

                }}
                component={HomeNew}
                listeners={{
                    tabPress: (event: EventArg<'tabPress', true, any>) => {

                        StatusBar.setBarStyle('dark-content')
                        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
                        bottomNavigationAction('for-you');
                    }
                }} />
            }
            {Platform.OS == 'web' && <BottomTabs.Screen
                navigationKey='news'
                name='noticias'
                options={{
                    ...defaultScreenOptions,
                    lazy: true,
                    tabBarLabel: 'Noticias',
                    tabBarIcon: getIcon('newspaper-variant-outline'),

                }}
                component={HomeWebPage}
                listeners={{
                    tabPress: (event: EventArg<'tabPress', true, any>) => {
                        StatusBar.setBarStyle('light-content')
                        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
                        bottomNavigationAction('news');
                    }
                }} />}
            <BottomTabs.Screen
                navigationKey='explore'
                name='explore'
                options={{
                    ...defaultScreenOptions,
                    lazy: false,
                    tabBarLabel: 'Explora',
                    tabBarIcon: getIcon('magnify'),
                }}
                component={SearchPage/* SearchTab2 */}
                listeners={{
                    tabPress: (event: EventArg<'tabPress', true, any>) => {
                        StatusBar.setBarStyle('dark-content')
                        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
                        bottomNavigationAction('search');
                        const search = useSearchStore.getState()
                        search.results?.length >= 1 ? null : search.search({ ...defaultDateFilter()}, { sort: { orderBy: ArticleSearchOrderBy.DATE, direction: SortDirection.DESC }, page: { limit: 20, skip: 0 } })
                        search.setDateFilterType(DateFilterType.TODAY)
                    }
                }}
            />
            <BottomTabs.Screen
                navigationKey='library'
                name='library'
                options={{
                    ...defaultScreenOptions,
                    tabBarLabel: 'Guardados',
                    headerShown: false,
                    lazy: true,
                    tabBarIcon: getIcon('bookmark-outline'),
                }}
                component={FavoritesTab}
                listeners={{
                    tabPress: (event: EventArg<'tabPress', true, any>) => {
                        StatusBar.setBarStyle('dark-content')
                        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
                        bottomNavigationAction('favourites');
                    }
                }}
            />
        </BottomTabs.Navigator>
    )

}

export default React.memo(MainBottomTabsNavigator)
