import Constants from "expo-constants";
import { Platform } from "react-native";

// PROD
// const localhost = Platform.OS == 'web' ? document.location.origin : 'https://thenewstoyou.com';
const localhost = Platform.OS == 'web' ? document.location.origin : 'https://diego.thenewstoyou.com';

// PROD
//  const backendhost = "http://backoffice.thenewstoyou.com";
const backendhost = "http://backofficediego.thenewstoyou.com";
const path1 = ':8081';

export const pdb = backendhost + path1;



const ENV = {
 dev: {
   apiUrl: localhost+'/api',
   batchesApiUrl: localhost+'/api-batches',
   imagesUrl: localhost+'/images/',
   apiV2Url: localhost + '/rest-api'
 },
 staging: {
   apiUrl: localhost+'/api',
   batchesApiUrl: localhost+'/api-batches',
   imagesUrl: localhost+'/images/',
   apiV2Url: localhost + '/rest-api'
 },
 prod: {
  apiUrl: localhost+'/api',
  batchesApiUrl: localhost+'/api-batches',
  imagesUrl: localhost+'/images/',
  apiV2Url: localhost + '/rest-api'
 }
};

interface EnvOptions {
  apiUrl: string,
  imagesUrl: string,
  batchesApiUrl: string,
  apiV2Url: string
}

const getEnvVars = (env = Constants.manifest.releaseChannel) : EnvOptions => {
 // What is __DEV__ ?
 // This variable is set to true when react-native is running in Dev mode.
 // __DEV__ is true when run locally, but false when published.
 if (__DEV__) {
   return ENV.dev;
 } else if (env === 'staging') {
   return ENV.staging;
 } else if (env === 'prod') {
   return ENV.prod;
 }
 return ENV.dev;
};

export const getMode = () => __DEV__?"dev":"prod";
export const getLocalhost = localhost;
export default getEnvVars;
