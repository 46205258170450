import React from 'react';
import {StyleSheet} from 'react-native'
import {Chip} from 'react-native-paper';
import { theme2 } from '../../core/theme';
import { getCategoryBG, getContrastColor } from '../../core/utils';


export interface CategoryChipProps {
    /**
     * The Category Text to be used for calculations. If `categoryLabel` is not present, `category` will be used as the label to display. Can be a raw string or an item object ({item: string, ...})
     */
    category: any,

    /**
     * The Category text to be used for display.
     */
     categoryLabel?: string,

    /**
     * React native StyleSheet created object, used for custom styling
     */
    style?:any,

    /**
     * React native StyleSheet created object, used for custom styling for the chip's text
     */
    textStyle?:any,

    /**
     * @deprecated
     * Whether or not to navigate to the CategoryList Page when this category gets clicked.
     * Default is false.
     */
    navigateToCategory?: boolean,

    /**
     * Function that launches whenever the Chip gets clicked, only used if 'navigateToCategory' is set to false
     */
    onPress?: (category: string)=>void

}





const CategoryChip = (props: CategoryChipProps) => {

    const bgColor = getCategoryBG(props.category, true);
    const color = getContrastColor(bgColor);

    const style = StyleSheet.create({
        chip: {
            backgroundColor: bgColor,
            color: color,
            textAlignVertical: 'center',
            fontWeight: 'bold'
        }
    });

    const chipPressed = () => {

        console.log(props.category);

        if(props.onPress != undefined)
            props.onPress(props.category);

        // if(props.navigateToCategory)
        //     throw new Error('Not yet implemented');

    }


    return (
        <Chip style={[style.chip, props.style]} textStyle={[style.chip, props.textStyle]} onPress={chipPressed}>
            {props.categoryLabel ? props.categoryLabel : typeof props.category == 'string' ? props.category : props.category.item}
        </Chip>
    )


}


export default CategoryChip;
