import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { TextInput } from "react-native-paper";
import { HighlightInterface, HighlightModel } from 'systemDomain';
import Button from "../../components/Button";


type Props = {
  initialhighlightData?: HighlightInterface ;
  onSubmit: any;
  buttonText: string;
};


const HighlightForm = ({initialhighlightData, onSubmit, buttonText}: Props) => {
  const [highlightData, setHighlightData] = React.useState(initialhighlightData?initialhighlightData:{});

  const _onSubmit = () => onSubmit(highlightData);
  return (
    <View style={{flex:1, flexDirection:"column", justifyContent:"center", alignItems: "center"}}>
        {
            HighlightModel.keys().map((key:string) =>
              <TextInput
                key={key}
                label={key}
                returnKeyType="done"
                //@ts-ignore
                value={ highlightData[key] }
                onChangeText={(text: string) => {
                  const newHighlightData:any = {};

                  let val = text == 'value' ? text == 'value' : text;
                  try {val = JSON.parse(text);} catch (e) {}
                  newHighlightData[key] = val;
                  setHighlightData({...highlightData, ...newHighlightData});
                }}
                onSubmitEditing={_onSubmit}
                style={styles.entry}
              />
            )
        }

        <View style={{ flexDirection: "row", justifyContent: 'center', width:"80%", alignSelf:"center", marginTop:10 }}>
          <Button onPress={_onSubmit}>
            {buttonText}
          </Button>
        </View>
      </View>
  )
};

const styles = StyleSheet.create({
  entry: {
    width: '80%'
  }
});

export default memo(HighlightForm);
