import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { KeyboardAvoidingView, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { ActionModel } from 'systemDomain'
import ActionApi, { ACTION_NAMES } from '../../../../api/ActionApi'
import { ScreensParamsList } from '../../../../core/navigation/screens/NavigationScreens'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'

import { useSnackBar } from '../../../context/snackBarStore'
import ButtonNty from '../../nty/button/ButtonNty'
import TextNty from '../../nty/text/TextNty'
import NtyTextInputRound from '../../nty/TextInput/TextInputNtyRound'
import TitleNty from '../../nty/title/TitleNty'

type Props = StackScreenProps<ScreensParamsList, 'NewSourceRequest'>;

function NewSourceRequest(props: { appLocation?: string, submitDone: (response: ActionModel) => void } & Props) {


    const [sourceName, setSourceName] = React.useState('')
    const [sourceSite, setSourceSite] = React.useState('');

    const theme = useAppTheme()

    const onSubmit = async () => {

        let action;

        try {

            action = await ActionApi.createFrom({ name: ACTION_NAMES.REQUEST_NEW_SOURCE, data: { sourceName, sourceSite, appLocation: props.appLocation ?? 'unknown' } })

        } catch (e) {

            action = null;

        } finally {

            props.submitDone ? props.submitDone(action) : null;
            showInfoMessage('¡Gracias por tus comentarios!')

        }



    }

    const showInfoMessage = useSnackBar(store => store.showInfoMessage);

    return (
        <KeyboardAvoidingView behavior='height' style={{flex: 1, paddingVertical: 10}}>

            <ScrollView
                style={{ paddingVertical: 20, paddingHorizontal: 5, flex: 1 }}>


                <View nativeID='formHeader' style={{ flexDirection: 'row', marginTop: 30, marginHorizontal: 10, marginBottom: 40 }}>

                    <View style={{ flex: 1, alignSelf: 'center' }}>
                        <TitleNty fontSize={25} style={{ textAlign: 'center', marginBottom: 8 }}>¿Qué echas de menos en NTY?</TitleNty>
                        <TextNty type='light' fontSize={14} color={theme.colors.text_primary[300]} style={{ textAlign: 'center' }}>Los más solicitados se añadirán en próximas versiones.</TextNty>
                    </View>


                </View>
                <View nativeID='formBody' style={{ paddingHorizontal: 20, marginBottom: 20, flexDirection: 'column', flex: 1 }}>

                    <View nativeID='who-row' style={{ flexDirection: 'column', marginBottom: 25 }}>

                        <TitleNty type='regular' fontSize={18} style={{ marginBottom: 5 }}>¿Quién?, ¿Qué?</TitleNty>

                        <NtyTextInputRound
                            nativeID='source-name'
                            placeholder='Añade tus contenidos favoritos'
                            onChangeText={setSourceName} />

                        {sourceName != '' && sourceName.length < 3 && <TextNty fontSize={12} color={theme.colors.danger[600]} style={{ marginBottom: 5 }} type='semiBold'>Introduce al menos 3 caracteres.</TextNty>}

                        <TextNty type='light' fontSize={16} color={theme.colors.text_primary[300]}>Lo añadiremos lo antes posible</TextNty>
                    </View>

                    <View nativeID='where-row' style={{ flexDirection: 'column', marginBottom: 25 }}>

                        <TitleNty type='regular' fontSize={18} style={{ marginBottom: 5 }}>¿Dónde publica?</TitleNty>

                        <NtyTextInputRound
                            nativeID='source-site'
                            placeholder='Web, Twitter, Youtube...'
                            onChangeText={setSourceSite} />

                        {sourceSite != '' && sourceSite.length < 3 && <TextNty fontSize={12} color={theme.colors.danger[600]} style={{ marginBottom: 5 }} type='semiBold'>Introduce al menos 3 caracteres.</TextNty>}

                        <TextNty type='light' fontSize={16} color={theme.colors.text_primary[300]}>Dinos dónde podemos encontrarlo e iremos a buscarlo. Da igual si es un blog, una Newsletter, una cuenta de Twitter... Puede ser lo que quieras.</TextNty>
                    </View>

                    <View nativeID='url-row' style={{ flexDirection: 'column' }}>

                        <TitleNty type='regular' fontSize={18} style={{ marginBottom: 5 }}>¿Alguna Referencia / Link ? <TextNty type='light' fontSize={14}>(opcional)</TextNty></TitleNty>

                        <NtyTextInputRound
                            nativeID='source-site'
                            placeholder='https://miperiodistafavorito.com'
                            onChangeText={setSourceSite} />

                        <TextNty type='light' fontSize={16} color={theme.colors.text_primary[300]}>Introduce una URL referencia para encontrar el contenido, su página web, el canal donde publica, su cuenta de RRSS...</TextNty>
                    </View>


                </View>

                <View style={{ width: "100%", paddingHorizontal: 10, justifyContent: 'center' }}>

                    <ButtonNty mode='text' mergeStyles={{alignSelf: 'flex-end'}} onPress={() => props.navigation.pop()} labelMergeStyles={{ fontFamily: theme.fonts.default.thin.fontFamily, fontSize: 15 }}>Cerrar</ButtonNty>

                    <ButtonNty mode='contained' mergeStyles={{ alignSelf: 'center', width: 200 }} disabled={!(sourceName && sourceSite && sourceName.length >= 3 && sourceSite.length >= 3)} onPress={onSubmit}>Enviar</ButtonNty>
                </View>





            </ScrollView>
        </KeyboardAvoidingView>
    )
}

export default NewSourceRequest
