import React from 'react'
import { View } from 'react-native'
import TextNty from '../../app/components/nty/text/TextNty'
import { useAppTrialStore } from '../../context/appTrialStore'
import { convertMsToTime } from '../../core/jsutils'
import { elevationShadowStyle } from '../../core/theme'
import { NtyTrial, QrTrial, TrialTypes } from '../../models/Trial'

function TrialBanner() {

    const [trial, setTrial] = React.useState(null);
    const [countDown, setCountDown] = React.useState(null);

    const trialRef = React.useRef<NtyTrial>()

    let interval

    const refreshInterval = (trial: NtyTrial) => {

        // console.log('Setting timer...');

        setTrial(trial.type)
        interval = setInterval(async () => {
            const timeStr: string = convertMsToTime(Date.now() - new Date(trial.data.startedAt).getTime()) + '';
            setCountDown(timeStr)
        }, 1000)



    }


    React.useEffect(() => {

        let sub = useAppTrialStore.subscribe((store) => {
            // console.log('Refresh Trial Changes...', store.trialData)

            switch (store.trial) {
                case TrialTypes.QR:

                    const trial = QrTrial.from(store.trialData);
                    // console.log('Trial', trial)

                    if (trialRef == null) {

                        if (!trial.isExpired() && trial.isActive()) {

                            trialRef.current = trial;
                            refreshInterval(trialRef.current);
                        }

                    } else if (!trial.isExpired() && trial.isActive() && trial.data.startedAt != trialRef.current?.data.startedAt) {
                        trialRef.current = trial;
                        refreshInterval(trialRef.current);
                    }
                    break;
                default:
                    trialRef.current = null;
                    setCountDown(null)
                    break;
            }

        });

        return () => {
            sub ? sub() : null;
            interval ? clearInterval(interval) : null;
        }

    }, [])

    return (
        countDown ?
            <View style={{ flexDirection: 'column', marginVertical: 20, padding: 10, borderRadius: 10, ...elevationShadowStyle(1) }}>
                <TextNty><TextNty type='bold'>Prueba:</TextNty> {trial.toUpperCase()}</TextNty>
                <TextNty><TextNty type='bold'>Tiempo de uso:</TextNty> {countDown}</TextNty>
            </View>
            : null
    )
}

export default TrialBanner
