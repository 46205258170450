import { Platform } from "react-native";
import { getWebAgent } from "src/core/jsutils";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Device from 'expo-device';
import { AppConfig } from "src/api/Config.api";

const getDeviceInfoObject: () => DeviceInfoObject = () => {


    const deviceInfo: DeviceInfoObject = {};

    const fields = Object.keys(Device);

    fields.forEach(
        f =>
            !f.startsWith('is') && !f.startsWith('get') && !f.startsWith('has') && !f.startsWith('D') ?
                Device[f] != null ?
                    deviceInfo[f] = Device[f] : null
                :
                null
    );

    deviceInfo.platform = Platform.OS;
    try {
        deviceInfo.webAgent = getWebAgent();
    } catch (e) {
        //Do nothing
        console.warn(e)

    }

    return deviceInfo;

}

const appConfig: AppConfig = require('src/../app.json')

type DeviceInfoObject = {

    /**
     * true if the app is running on a real device and false if running in a simulator or emulator. On web, this always returns true.
     */
    isDevice?: boolean
    /**
     * The device brand. The consumer-visible brand of the product/hardware. On web, this value is null.
     */
    brand?: string

    /**
     * The actual device manufacturer of the product or hardware. This value of this field may be null if it cannot be determined.
     */
    manufacturer?: string;

    /**
     * The human-friendly name of the device model. This is the name that people would typically use to refer to the device rather than a programmatic model identifier. This value of this field may be null if it cannot be determined.
     */
    modelName?: string

    /**
     * __iOS only.__ The internal model ID of the device. This is useful for programmatically identifying the type of device and is not a human-friendly string. On web and Android, this value is null.
     */
    modelId?: string

    /**
     * __Android only.__ The specific configuration or name of the industrial design. It represents the device's name when it was designed during manufacturing into mass production. On Android, it corresponds to Build.DEVICE. On web and iOS, this value is null.
     */
    designName?: string

    /**
     * Android only. The device's overall product name chosen by the device implementer containing the development name or code name of the device. Corresponds to Build.PRODUCT. On web and iOS, this value is null.
     */
    productName?: string

    /**
     * The device year class of this device. On web, this value is null.
     *
     * Device Year Class is an Android library that implements a simple algorithm that maps a device's RAM, CPU cores, and clock speed to the year where those combination of specs were considered high end. This allows a developer to easily modify application behavior based on the capabilities of the phone's hardware.
     *
     */
    yearClass?: string

    /**
     * The device's total memory, in bytes. This is the total memory accessible to the kernel, but not necessarily to a single app. This is basically the amount of RAM the device has, not including below-kernel fixed allocations like DMA buffers, RAM for the baseband CPU, etc… On web, this value is null.
     */
    totalMemory?: number | string,

    /**
     * The name of the OS running on the device.
     *
     * Device.osName; // Android: "Android"; iOS: "iOS" or "iPadOS"; web: "iOS", "Android", "Windows"
     *
     */
    osName?: string

    /**
     * The human-readable OS version string. Note that the version string may not always contain three numbers separated by dots.
     *
     * Device.osVersion; // Android: "4.0.3"; iOS: "12.3.1"; web: "11.0", "8.1.0"
     */
    osVersion?: string


    /**
     * The build ID of the OS that more precisely identifies the version of the OS. On Android, this corresponds to Build.DISPLAY (not Build.ID) and currently is a string as described here. On iOS, this corresponds to kern.osversion and is the detailed OS version sometimes displayed next to the more human-readable version. On web, this value is null.
     */
    osBuildId?: string

    /**
     * The internal build ID of the OS running on the device. On Android, this corresponds to Build.ID. On iOS, this is the same value as Device.osBuildId. On web, this value is null.
     */
    osInternalBuildId?: string

    /**
     * __Android only.__ A string that uniquely identifies the build of the currently running system OS. On web and iOS, this value is null. On Android, it follows this template:
     * `$(BRAND)/$(PRODUCT)/$(DEVICE)/$(BOARD):$(VERSION.RELEASE)/$(ID)/$(VERSION.INCREMENTAL):$(TYPE)/\$(TAGS)`
     */
    osBuildFingerPrint?: string

    /**
     * __Android only.__ The Android SDK version of the software currently running on this hardware device. This value never changes while a device is booted, but it may increase when the hardware manufacturer provides an OS update. See here to see all possible version codes and corresponding versions. On web and iOS, this value is null.
     */
    platformApiLevel?: string


    /**
     * The human-readable name of the device, which may be set by the device's user. If the device name is unavailable, particularly on web, this value is null.
     */
    deviceName?: string,


    deviceType?: "UNKNOWN" | "PHONE" | "TABLET" | "DESKTOP" | "TV" | string;

    /**
     * Expo Platform.OS value
     */
    platform?: string

    /**
     * @see getWebAgent
     */
    webAgent?: string
}


export type DeviceInfoStoreType = {

    appConfig: AppConfig ,
    deviceInfo: DeviceInfoObject,
    setDeviceType: (deviceType: DeviceInfoObject['deviceType']) => void,

}

export const DEVICE_INFO_STORE_KEY = 'deviceInfoStore';

const deviceInfoStoreInitialState = {
    appConfig,
    deviceInfo: getDeviceInfoObject()
}

const hydrationSetup = async (state: DeviceInfoStoreType) => {

    const value = await Device.getDeviceTypeAsync()

    const deviceType = Device.DeviceType[value]
    // console.log('DeviceType', deviceType)
    state.setDeviceType(deviceType)

}


const useDeviceInfoStore = create<DeviceInfoStoreType>()(
    persist(
        (set, get) => ({
            ...deviceInfoStoreInitialState,
            setDeviceType: (deviceType) => {
                // console.log('Setting deviceType', deviceType)
                set({ deviceInfo: { ...get().deviceInfo, deviceType } })
            }
        }),

        {
            name: DEVICE_INFO_STORE_KEY,
            version: 1,
            storage: createJSONStorage(() => AsyncStorage),
            onRehydrateStorage: (state) => {
                // console.log('Before hydration', state)
                // console.log('Rehydrating deviceInfoStore')
                const onHydrationDone = (state?: DeviceInfoStoreType, error?: any) => {
                    if(error) {
                        // console.error('Error rehydrating deviceInfoStore', error)
                        return
                    } else {
                        // console.log('Hydration Done', state.deviceInfo)
                        hydrationSetup(state)/* .then(() => console.log('Hydrated deviceInfoStore', state.deviceInfo)) */
                    }
                }
                return onHydrationDone;



            }
        }

    ))


export default useDeviceInfoStore;


