import React from 'react'
import { View } from 'react-native'
import { ActivityIndicator, Card, DataTable, IconButton } from 'react-native-paper'
import ActionApi from '../../../api/ActionApi'
import { ActionStats } from '../../../models/ActionStats'

import { Picker } from '@react-native-picker/picker'
import { getAppTheme } from '../../../core/theme/ThemeBuilder'
import TextNty from '../nty/text/TextNty'
import TitleNty from '../nty/title/TitleNty'



export type ActionTypeCardProps = {
    actionName: string;
    titleLabel?: string
}

function ActionTypeCard({ actionName, titleLabel }: ActionTypeCardProps) {


    const theme = getAppTheme()


    const [actionStats, setActionStats] = React.useState<ActionStats<string>[]>(null);
    const [actionDataLabels, setActionDataLabels] = React.useState<string[]>(null);

    const [selectedDataLabel, setSelectedDataLabel] = React.useState<string>(null);

    const [orderBy, setOrderBy] = React.useState<string>(null);
    const [direction, setDirection] = React.useState<'ascending' | 'descending'>(null);

    const [loading, setloading] = React.useState(true);

    const [timeInterval, setTimeInterval] = React.useState<'ever' | 'today' | 'lastWeek' | 'lastMonth' | 'lastMonths_3' | 'lastMonths_6'>('ever');

    const [error, seterror] = React.useState(null);

    // const { session, user } = useAppSessionStore();

    // const currentUser = user;


    const refresh = () => {

        setloading(true);
        setActionStats(null);
        fetchStatsData();
    }




    // const downloadCsv = () => {

    //     ActionApi.downloadStatsByActionNameAndLabel(actionName, selectedDataLabel, currentUser);

    // }

    // const downloadDateCsv = () => {

    //     ActionApi.downloadDateAggregationByActionNameAndLabel(actionName, selectedDataLabel, currentUser);

    // }


    const fetchStatsData = async () => {

        try {
            const data: ActionStats<string>[] = await ActionApi.getStatsByActionNameAndLabel(actionName, selectedDataLabel, timeInterval);
            setActionStats(data);
        } catch (e) {
            seterror(e);

        }
        setloading(false);


    }

    const fetchAtcionDataLabels = async () => {
        try {

            const labels: string[] = await ActionApi.getLabelsByActionName(actionName);
            setActionDataLabels(labels);
            if (labels.length > 0)
                setSelectedDataLabel(labels[0]);
        } catch (e) {
            seterror(e)
        }

    }

    const toggleOrder = (clickedOrder: string) => {

        if (orderBy == clickedOrder) {

            switch (direction) {
                case null: setDirection('ascending'); break;
                case 'ascending': setDirection('descending'); break;
                case 'descending': setDirection(null); break;
            }

        } else {
            setOrderBy(clickedOrder);
            setDirection('ascending');
        }

        if (direction) {
            const sortedStats = actionStats.sort((stat1, stat2) => {

                if (direction == 'ascending') {

                    if (typeof stat1[orderBy] == 'string') {
                        return ('' + stat1[orderBy]).localeCompare(stat2[orderBy]);
                    }
                    if (typeof stat1[orderBy] == 'number') {
                        return stat1[orderBy] - stat2[orderBy];
                    }

                } else {

                    if (typeof stat1[orderBy] == 'string') {
                        return ('' + stat2[orderBy]).localeCompare(stat1[orderBy]);
                    }
                    if (typeof stat1[orderBy] == 'number') {
                        return stat2[orderBy] - stat1[orderBy];
                    }

                }

            });
            setActionStats(sortedStats);
        }

    }
    const [expanded, setExpanded] = React.useState(false);
    const toggleExpandState = () => {
        setExpanded(!expanded);
    }

    React.useEffect(() => {

        if (selectedDataLabel) {

            fetchStatsData();
        } else {
            setloading(false);
        }

    }, [selectedDataLabel, timeInterval]);

    React.useEffect(() => {

        fetchAtcionDataLabels();

    }, []);

    return (

        <Card mode={'elevated'} style={{ marginVertical: 10, paddingVertical: 10, backgroundColor: theme.colors.background[300] }}>
            <Card.Title
                title={titleLabel ?? actionName}
                titleStyle={{ ...theme.fonts.default.semiBold, color: theme.colors.text_primary[300] }}
                subtitleStyle={{ ...theme.fonts.default.regular, color: theme.colors.text_primary[400] }}
                subtitle={selectedDataLabel ?? '-'}
                right={(props) => {
                    return (<>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <IconButton {...props} icon={expanded ? 'minus' : 'plus'} onPress={toggleExpandState} />
                            <IconButton {...props} icon="refresh" onPress={refresh} />
                            {/* {actionStats ? <IconButton {...props} icon="table-arrow-down" onPress={downloadCsv} /> : null}
                            {actionStats ? <IconButton {...props} icon="download" onPress={downloadDateCsv} /> : null} */}
                        </View>
                        {expanded && <>
                            <TitleNty>Desde: </TitleNty>
                            <Picker
                                selectedValue={timeInterval}
                                style={{ height: 50, width: 150, backgroundColor: theme.colors.background[500] }}
                                onValueChange={(itemValue) => { setloading(true); setTimeInterval(itemValue) }}
                            >
                                <Picker.Item style={{ color: theme.colors.text_primary[500] }} label="Siempre" value="ever" key="ever" />
                                <Picker.Item label="Último mes" value="lastMonths_6" key="lastMonths_6" />
                                <Picker.Item label="Último mes" value="lastMonths_3" key="lastMonths_3" />
                                <Picker.Item label="Último mes" value="lastMonth" key="lastMonth" />
                                <Picker.Item label="Última semana" value="lastWeek" key="lastWeek" />
                                <Picker.Item label="Ayer" value="yesterday" key="yesterday" />
                                <Picker.Item label="Hoy" value="today" key="today" />
                            </Picker>
                        </>}
                    </>)
                }}></Card.Title>

            {expanded &&
                <Card.Content>
                    {loading &&
                        <ActivityIndicator size={50} color={theme.colors.primary[500]} />}
                    {error ?
                        <TitleNty>Error inesperado al solicitar la información para la acción: {actionName}</TitleNty>
                        : <>
                            {actionStats && actionStats.length && actionStats.length > 0
                                ?
                                <TitleNty>Total: {actionStats.map((stat) => stat.count).reduce((a, b) => a + b)}</TitleNty>
                                :
                                <TitleNty>Sin resultados</TitleNty>
                            }
                            {actionDataLabels && actionDataLabels.length && actionDataLabels.length > 0 ?
                                <Picker
                                    selectedValue={selectedDataLabel}
                                    style={{ height: 50, width: 150 }}
                                    onValueChange={(itemValue) => { setloading(true); setSelectedDataLabel(itemValue) }}
                                >
                                    {actionDataLabels.map((label) => <Picker.Item label={label} value={label} />)}
                                </Picker>
                                :
                                <TextNty>No hay resultados</TextNty>
                            }
                            <DataTable >

                                <DataTable.Header>
                                    {/* <DataTable.TitleNty>Etiqueta</DataTable.TitleNty> */}
                                    <DataTable.Title numeric onPress={() => toggleOrder('value')} sortDirection={orderBy == 'value' ? direction : null}>Valor</DataTable.Title>
                                    <DataTable.Title numeric onPress={() => toggleOrder('count')} sortDirection={orderBy == 'count' ? direction : null}>Recuento</DataTable.Title>
                                </DataTable.Header>

                                {actionStats && actionStats.length > 0 && actionStats.map((stat, index) => {
                                    return (
                                        <DataTable.Row key={selectedDataLabel + '_' + index}>
                                            <DataTable.Cell key={index + 'text'}><TextNty adjustsFontSizeToFit>{typeof stat.value == 'boolean' ? stat.value ? 'true' : 'false' : stat.value}</TextNty></DataTable.Cell>
                                            <DataTable.Cell numeric key={index + 'value'}>{stat.count}</DataTable.Cell>
                                        </DataTable.Row>
                                    )
                                })}
                            </DataTable>
                        </>

                    }

                </Card.Content>}

        </Card>

    )
}

export default ActionTypeCard
