import { GestureResponderEvent, Pressable, View, StyleSheet, Image} from "react-native"
import TextNty from "src/app/components/nty/text/TextNty"
import { fixed_colors } from "src/core/theme/colors"
import { Article } from "systemDomain"

export const NewContentButton = (props: { articles: Article[], height?: number, onPress?: (event: GestureResponderEvent) => void }) => {

    const publishersLogos = props.articles.map((a) => a.publisher.logo).filter(logo => logo != null).slice(0, 3)

    const CHIP_HEIGHT = props.height ?? 35
    const CHIP_COLOR = fixed_colors.white[200]
    const CHIP_TEXT_COLOR = fixed_colors.grey[500]

    const styles = StyleSheet.create({
        chip: {
            height: CHIP_HEIGHT,
            // width: CHIP_HEIGHT * 2.5,
            borderRadius: CHIP_HEIGHT / 2,
            backgroundColor: CHIP_COLOR,
            borderColor: CHIP_TEXT_COLOR,
            borderWidth: 1,
            paddingHorizontal: CHIP_HEIGHT / 2,
            paddingVertical: (CHIP_HEIGHT / 15) / 2,
        },
        cicles_box: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',  // Centrar verticalmente
            justifyContent: 'center'  // Centrar horizontalmente
        },
        circle: {
            height: CHIP_HEIGHT / 1.3,
            width: CHIP_HEIGHT / 1.3,
            borderRadius: (CHIP_HEIGHT / 1.3) / 2,
            overflow: 'hidden',
            marginRight: -(CHIP_HEIGHT / 6)
        }
    });

    return <Pressable style={styles.chip} onPress={props.onPress} >

        <View style={styles.cicles_box}>
            {/* <View style={[styles.circle, {marginRight: CHIP_HEIGHT / 6 }]}> */}
            {/* <Avatar.Icon icon={'refresh'} size={CHIP_HEIGHT / 2} color={fixed_colors.white[500]} style={{backgroundColor: 'transparent'}}/> */}
            <TextNty style={{ marginRight: publishersLogos?.length > 0 ? CHIP_HEIGHT / 2.5 : 0 }} color={CHIP_TEXT_COLOR}>Últimas Noticias</TextNty>
            {/* </View> */}
            {publishersLogos.map(icon => <View key={icon} style={styles.circle}><Image source={{ uri: icon }} style={{ width: "100%", height: "100%", resizeMode: 'cover' }} /></View>)}


        </View>

    </Pressable>


}
