import { AppState, AppStateStatus, Platform } from "react-native";
import { convertMsToTime, hash_cyrb53 } from "src/core/jsutils";
import { StateCreator, StoreApi, create } from "zustand";
import { createJSONStorage, devtools, persist, subscribeWithSelector } from "zustand/middleware";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainerRef } from "@react-navigation/native";
import ActionApi, { ACTION_NAMES } from "src/api/ActionApi";
import AuthApi from "src/api/AuthApi";

import ProfileApi from "src/api/Profile.api";
import { SessionApi } from "src/api/SessionApi";
import { useFavouritesStore } from "src/app/context/favoritesStore";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";
import { compareObjects as areTheSameObj } from "src/core/utils";
import { AuthDBUser } from "src/models/user";
import { ProfileInterface, SessionInfo } from "systemDomain";
import { useAppTrialStore } from "./appTrialStore";



export const NTY_GUEST_DEFAULT_MAIL = 'guest@guest.thenewstoyou.com';

export enum LoginErrors {

    EMAIL_DOESNT_EXISTS = 'email_doesnt_exists',
    USER_PASS_WRONG = 'user_pass_wrong',
    CONNECTIVITY = 'connectivity_error'

}


export enum RegisterErrors {

    EMAIL_ALREADY_EXISTS = 'email_already_exists',
    CONNECTIVITY = 'connectivity_error'

}

const guestProfile: ProfileInterface = {
    created: new Date().toISOString(),
    email: NTY_GUEST_DEFAULT_MAIL,
    enterprise: 'NTYPUBLIC',
    name: 'Invitado',
    onBoardingDone: true,
    superUser: false,
    _id: NTY_GUEST_DEFAULT_MAIL,
    data: {}
}

export const SESSION_CONSTANTS = {
    SESSION_STORE_KEY: 'sessionStore',
    SESSION_EXPIRY_TIME: 1000 * 60 * 60 * 24 * 7, // 7 days
    VERSION: 0.31,
    guestProfile,
    defaultToken: NTY_GUEST_DEFAULT_MAIL,
    defaultEnterprise: 'NTYPUBLIC'
}

type SessionStoreType = SessionInfo & {
    navigatorRef?: NavigationContainerRef<ScreensParamsList>,
    setNavigatorRef: (ref: NavigationContainerRef<ScreensParamsList>) => void,
    session_id: string,
    updated_at: string,
    started_at: string,
    version: number,
    token: string,
    isGuest: boolean,
    // _hasHydrated: boolean,
    // setHasHydrated: (hasHydrated: boolean) => void,

    build: () => Promise<SessionStoreType>,

    loginUserPassword: (email: string, password: string) => Promise<SessionStoreType>,
    login: (user: AuthDBUser, profile?: ProfileInterface) => Promise<SessionStoreType>,
    register: (email: string, password: string, enterprise?: string) => Promise<SessionStoreType>,
    logout: () => Promise<Partial<SessionStoreType>>
    addContact: (contact: { name?: string, email: string }) => void,
    deleteContact: (email: string) => void
}

const get_session_id_chain = (email: string) => {
    const chain = email + '_' + Platform.OS + '_' + Date.now();
    return hash_cyrb53(chain) + '_' + chain;
}

const sessionInitialState = {
    session_id: get_session_id_chain(NTY_GUEST_DEFAULT_MAIL),
    // appConfig: require('src/../app.json'),
    // _hasHydrated: false,
    updated_at: new Date().toISOString(),
    started_at: null,
    version: SESSION_CONSTANTS.VERSION,
    profile: SESSION_CONSTANTS.guestProfile,
    token: SESSION_CONSTANTS.defaultToken,
    isGuest: true,
    sections: [],
    enterprise: null,
    feeds: []
}

const hydrationSetup = async (state: SessionStoreType) => {
    console.log('Hydration Setup for sessionStore')
    // state.setHasHydrated(true)
    return
}

const updateSessionInfo = (update: any, lastSession: SessionStoreType, sessionInfo: SessionInfo) => {

    if (!areTheSameObj(lastSession.profile, sessionInfo.profile)) {
        console.log('Profile has changed')
        update.profile = sessionInfo.profile
        update.session_id = get_session_id_chain(sessionInfo.profile.email)
    }

    if (!areTheSameObj(lastSession.enterprise, sessionInfo.enterprise)) {
        console.log('Enterprise has changed')
        update.enterprise = sessionInfo.enterprise
    }

    if (!areTheSameObj(lastSession.feeds, sessionInfo.feeds)) {
        console.log('Enterprise Feeds has changed')
        update.feeds = sessionInfo.feeds
    }

    if (!areTheSameObj(lastSession.sections, sessionInfo.sections)) {
        console.log('Sections has changed')
        update.sections = sessionInfo.sections
    }

}

const sessionAction = (action: ACTION_NAMES, data: any) => {

    setTimeout(async () => ActionApi.createFrom({ name: action, data }), 500)

}
// Sections is persisted no more so that sessionStore can be persisted without risk on Exceeding the AsyncStorage Quota (that leads on an error).
// There should be no problem with this except for reloads, but sections content may change between refreshes so its a bit worthless to show outdated data while the session is buildingy
const avoidPersistingThisFields = ['navigatorRef', 'sections']

const fetchSessionInfo = async (token?: string) => {

    const fetchSessionTime = Date.now()
    const sessionInfo = await SessionApi.build();
    console.log('Session Info Fetching in' + ((Date.now() - fetchSessionTime) / 1000) + 's')
    return sessionInfo

}

// let sessionStore:  StateCreator<SessionStoreType, [], [["zustand/subscribeWithSelector", never]]> = subscribeWithSelector((set, get) => ({
let sessionStore: (set: StoreApi<SessionStoreType>['setState'], get: StoreApi<SessionStoreType>['getState']) => SessionStoreType = (set, get) => ({
    ...sessionInitialState,
    setNavigatorRef: (ref: NavigationContainerRef<ScreensParamsList>) => set({ navigatorRef: ref }),
    // setHasHydrated: (hasHydrated: boolean) => set({ _hasHydrated: hasHydrated }),
    build: async () => {

        const buildStartTime = Date.now()

        const lastSession = get();
        const trial = useAppTrialStore.getState().resumeTrial();

        try {


            if (!useSessionStore.persist?.hasHydrated()) {
                console.log('Session hasnt been hydrated yet, no actions performed.');
                return;
            }
            // console.time('Session Info Fetching in')
            const update: any = {
                updated_at: new Date().toISOString(),
                started_at: new Date().toISOString(),
                stopped_at: null,
            };

            if (lastSession.started_at) {
                // This means that the session was already created by another access
                console.log('Session was already created!');
                console.log('Rebuilding session...');

                if (lastSession.isGuest) {
                    // There's no need to fetch session data for guest users unless is a web trial
                    if (Platform.OS == 'web' && trial?.isActive()) {
                        const sessionInfo = await fetchSessionInfo(lastSession.token)
                        updateSessionInfo(update, lastSession, sessionInfo)
                    }

                } else {
                    console.log('Fetching non-guest session data...')
                    const sessionInfo = await fetchSessionInfo(lastSession.token)
                    console.log('Session Info Fetched', sessionInfo)
                    updateSessionInfo(update, lastSession, sessionInfo)
                    // Initialize favourites store when a non-guest user logs in
                    useFavouritesStore.getState().init(update.profile)

                }

                const stopped_at = useAppStateStore.getState().last_stop
                const lastSessionDuration = stopped_at ? convertMsToTime(Date.now() - new Date(stopped_at).getTime()) : null
                set(update)
                sessionAction(ACTION_NAMES.SESSION_START, { recovered: true, lastSessionDuration, newSession: true })
            } else {
                // This meants the session was never created, so we have the default values and by the moment we will treat the user as a guest
                console.log('No existing session in storage!');
                console.log('Building new session...');
                // There's no need to fetch session data for guest users unless is a web trial
                if (Platform.OS == 'web' && trial?.isActive()) {
                    const sessionInfo = await fetchSessionInfo()
                    updateSessionInfo(update, lastSession, sessionInfo)
                } else {
                    set(update)
                }

                sessionAction(ACTION_NAMES.SESSION_START, { recovered: false, newSession: true });

            }


            return { ...get(), ...update }
        } catch (e) {
            console.error('Error building session', e)
            if (!new String(e).includes('Quota'))
                throw e
        } finally {
            console.log('Session builded in' + ((Date.now() - buildStartTime) / 1000) + 's')
            // setTimeout( async () => useFavouritesStore.getState().init(),100)
        }

    },
    loginUserPassword: async (username: string, password: string) => {

        try {

            if (!await AuthApi.doMailExists(username)) {
                throw new Error(LoginErrors.EMAIL_DOESNT_EXISTS);
            }
            const data = await AuthApi.login(username, password);
            const user: AuthDBUser = { email: data.email, token: data.token, type: data.type, status: data.status };

            return await get().login(user)

        } catch (e) {
            if (e.message === 'Failed to fetch') {
                throw new Error(LoginErrors.CONNECTIVITY, { cause: e });
            } else {
                throw new Error(LoginErrors.USER_PASS_WRONG, { cause: e });
            }

        }
    },
    login: async (user: AuthDBUser, profile?: ProfileInterface) => {

        useAppTrialStore.getState().clearTrial()
        // @ts-ignore
        set({ token: user.token, isGuest: false, profile: profile ?? { email: user.email } })
        sessionAction(ACTION_NAMES.LOGIN, { email: user.email })

        return await get().build()

    },
    logout: async () => {
        const lastSession = get();
        // lastSession.setStoppedAt(new Date().toISOString())
        useAppTrialStore.getState().clearTrial()
        sessionAction(ACTION_NAMES.LOGOUT, { email: lastSession.profile.email, session_id: lastSession.session_id, started_at: lastSession.started_at, stopped_at: new Date().toISOString() })
        const newSession = { ...sessionInitialState, navigatorRef: lastSession.navigatorRef, session_id: get_session_id_chain(NTY_GUEST_DEFAULT_MAIL), started_at: new Date().toISOString(), updated_at: new Date().toISOString(), stopped_at: null, _hasHydrated: true }
        set(newSession)
        useFavouritesStore.getState().cleanStore()
        return newSession
    },
    register: async (email: string, password: string, enterprise?: string) => {

        // const authApi = new AuthApi(new ApiCaller());
        if (await AuthApi.doMailExists(email)) {
            throw new Error(RegisterErrors.EMAIL_ALREADY_EXISTS);
        }

        try {

            console.log('Creating AuthUser...');
            const data = await AuthApi.register(email, password, email);
            const user: AuthDBUser = { email: data.email, token: data.token, type: data.type, status: data.status }
            // const profileApi = new ProfileApi(new ApiCaller(user.token));
            console.log('Creating Progile...');
            //@ts-ignore
            const to_create_profile: ProfileModelInterface = { email: user.email, name: user.email, onBoardingDone: false }
            if (enterprise) {
                //@ts-ignore
                to_create_profile.enterprise = enterprise
            }
            const profile = await ProfileApi.createProfile(to_create_profile);
            console.log('Register Completed.')


            sessionAction(ACTION_NAMES.REGISTER, { email: user.email, enterprise: enterprise })
            return await get().login(user, profile)
        } catch (e) {

            throw new Error(RegisterErrors.CONNECTIVITY, { cause: e });


        }

    },
    addContact: (contact: { name?: string, email: string }) => {
        const profile = get().profile
        const contacts = [...profile?.data?.contacts ?? []]
        const index = contacts.findIndex(c => c.email == contact.email)
        if (index != -1 && contact.name && contact.name != contacts[index].email) {
            contacts[index].name = contact.name
        } else if (index == -1) {
            contacts.push(contact)
        }
        if (!profile.data) {
            profile.data = {}
        }
        profile.data.contacts = contacts
        set({ profile })

    },
    deleteContact: (email: string) => {


        const profile = get().profile
        const contacts = profile.data?.contacts ?? []

        const index = contacts.findIndex(c => c.email == email)
        if (index != -1) {
            contacts.splice(index, 1)
        } else {
            return
        }

        if (!profile.data) {
            profile.data = {}
        }

        profile.data.contacts = contacts
        set({ profile })

    }
})

let store

if (__DEV__ && Platform.OS == 'web') {
    store = devtools(sessionStore);
} else {
    store = sessionStore;
}


const useSessionStore = create<SessionStoreType>()(
    persist(
        store,
        {
            name: SESSION_CONSTANTS.SESSION_STORE_KEY,
            version: SESSION_CONSTANTS.VERSION,
            storage: createJSONStorage(() => Platform.OS == 'web' ? localStorage : AsyncStorage),
            // getStorage: () => localStorage,
            partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key, value]) => !avoidPersistingThisFields.includes(key))),
            onRehydrateStorage: (state) => {
                console.log('Before hydration', state)
                console.log('Rehydrating sessionStore')
                const onHydrationDone = (state?: SessionStoreType, error?: any) => {
                    if (error) {
                        console.error('Error rehydrating sessionStore', error)
                        throw error
                    } else {
                        // console.log('Hydration Done', state)
                        hydrationSetup(state).then(() => {
                            // console.log('Session hydrated', useSessionStore.getState())
                            useSessionStore.getState().build()
                            // state.build().then(() => console.log('Session builded from hydration'))
                        })/* .then(() => console.log('Hydrated deviceInfoStore', state.deviceInfo)) */
                    }
                }
                return onHydrationDone;



            }
        }

    ))




export default useSessionStore;

export type AppStateStoreType = {
    state: AppStateStatus,
    initialized: boolean,
    last_stop: string,
    init: () => void
}

const AppStateStore: (set: StoreApi<AppStateStoreType>['setState'], get: StoreApi<AppStateStoreType>['getState']) => AppStateStoreType =
    (set, get) =>
    ({
        state: 'unknown',
        initialized: false,
        last_stop: null,
        init: () => {
            const appStateEventListener: (newState: AppStateStatus) => void = (newState) => {
                console.log('appState Changed', newState);

                if (newState == 'background' || newState == 'inactive') {
                    // useSessionStore.getState().setStoppedAt(new Date().toISOString())
                    set({ last_stop: new Date().toString() })
                } else {
                    set({ last_stop: null })
                }


                set({ state: newState })
            }
            if (!get().initialized) {
                AppState.addEventListener('change', appStateEventListener);
                set({ initialized: true, state: AppState.currentState });
            }
        }
    });

export const useAppStateStore = create<AppStateStoreType>(AppStateStore);

useAppStateStore.getState().init()


