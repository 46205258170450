import 'react-native-gesture-handler';

import * as SplashScreen from 'expo-splash-screen';
SplashScreen.preventAutoHideAsync()

// import * as Analytics from 'expo-firebase-analytics';
import * as React from 'react';
import { LogBox, Platform } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { ActivityIndicator, Provider as PaperProvider } from 'react-native-paper';
import RootNavigator from './src/core/navigation/RootNavigator';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppLoader from './src/core/loaders/AppLoader';
import { getAppTheme, getPaperTheme } from './src/core/theme/ThemeBuilder';

// import {useFonts} from 'expo-font'


if (Platform.OS != 'web')
  LogBox.ignoreLogs(['Setting a timer']);

SplashScreen.preventAutoHideAsync().then()

function Main() {



  const papertheme = getPaperTheme();

  const [fontsLoaded, setFontsLoaded] = React.useState<boolean | Error>(false);

  React.useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync().then()
    }
  }, [fontsLoaded]);

  // const [loading, setLoading] = React.useState(true)
  const appReady = fontsLoaded

  const colors = getAppTheme().colors

  return (

    <SafeAreaProvider>

      <PaperProvider theme={papertheme}>
        <AppLoader fontsLoaded={(error) => {
          if (!error) {
            setFontsLoaded(true)
          } else {
            setFontsLoaded(error)
          }
        }} />
        <ActivityIndicator color={colors.primary[500]} size={40} style={{ position: 'absolute', alignSelf: 'center', top: "40%" }} />
        <GestureHandlerRootView style={{ flex: 1 }}>
          {appReady == true &&
            <RootNavigator />}
        </GestureHandlerRootView>
      </PaperProvider>
    </SafeAreaProvider>
  );
}

export default Main;
