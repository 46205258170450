import moment from 'moment'
import React, { useState } from 'react'
import { Platform, View } from 'react-native'
import { PublisherApi } from 'src/api/PublisherApi'
import useSessionStore from 'src/context/sessionStore'
import { formatNumber } from 'src/core/jsutils'
import { Article, Brand, Source } from 'systemDomain'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'
import { fixed_colors } from '../../../../core/theme/colors'
import { maximumChars } from '../../../../core/utils'
import PublisherBubble from '../../brands_sources/PublisherBubble'
import TextNty from '../../nty/text/TextNty'
import TitleNty from '../../nty/title/TitleNty'
import { useWindowDimensions } from 'react-native'

function mapCedroTypeAndFormatsToKeyName(cedroType: string): string {
    const mapping: Record<string, string> = {
        "Digital": "web",
        "Edición": "paper",
        "Hiperlocal": "paper",
        "Paywall": "web",
        "Principal": "paper",
        "Suplemento": "paper",
        "Web": "web",
        "Tele": "video",
        "Radio": "audio",
        "Papel": "paper",
        "Audio": "web",
        "Video": "web"
    };

    return mapping[cedroType] ?? 'Web'
}


function ArticleHeading(props: { article: Article }) {

    const theme = useAppTheme()
    // const isEnterprise = useAppSessionStore(store => store.isEnterprise)
    const enterprise = useSessionStore(store => store.enterprise)
    const [publisherInfo, setPublisherInfo] = useState<Brand | Source>(null)

    const fetchPublisher = async (key: string) => {
        const publisher = await PublisherApi.getPublisherByKeyName(key)

        setPublisherInfo(publisher)

    }

    const publisherValues = React.useMemo(() => {

        if (!publisherInfo) return null

        const economicalValue = formatNumber(publisherInfo.economicalValue?.[mapCedroTypeAndFormatsToKeyName(props.article.format)], { currency: true, decimals: true })
        const audienceValue = formatNumber(publisherInfo.audience?.[mapCedroTypeAndFormatsToKeyName(props.article.format)])

        return { economicalValue, audienceValue }


    }, [publisherInfo])

    React.useEffect(() => {

        const publisher_key = props.article.publisher?.known_publisher ? props.article.publisher?.keyName : null
        if (!enterprise || !publisher_key) return

        if (Platform.OS == 'web')
            fetchPublisher(publisher_key)



    }, [props.article, enterprise])

    const dimensions = useWindowDimensions()
    const aspectRatio = React.useMemo(() => dimensions.width / dimensions.height, [dimensions.width, dimensions.height])

    const hasCover = !!props.article?.media?.cover?.url

    const primaryTextColor = hasCover ? fixed_colors.white[500] : fixed_colors.black[500]
    const secondaryTextColor = hasCover ? fixed_colors.white[400] : fixed_colors.black[400]

    return (
        <View style={{ paddingHorizontal: Platform.OS == 'web' ? (aspectRatio > 1 ? "15%" : 20) : 15, flexDirection: 'column', justifyContent: hasCover ? 'flex-end' : 'center', flex: 1, zIndex: 1000, paddingBottom: 10 }}>

            {/* <ArticleMedia article={new ArticleModel(props.article)} /> */}
            <TitleNty fontSize={Platform.OS == 'web' ? (aspectRatio > 1 ? 35 : 30) : 30} style={[{ marginBottom: 10, ...theme.fonts.article.bold, textAlign:  aspectRatio > 1 ? 'left': 'center'}, hasCover ? theme.shadows.text.medium: null]} color={primaryTextColor}>{maximumChars(props.article?.title ?? '--', 300)}</TitleNty>
            {/* <TextNty fontSize={20} color={fixed_colors.white[400]} type={'bold'}>{getPublisherName(props.article)}</TextNty> */}
            {/* <PublisherBubble style={{ marginBottom: 10 }} publisher={props.article.publisher} size={30} showLabel={true} labelPosition='right' labelStyle={{ color: primaryTextColor }} />
            <TextNty fontSize={20} color={primaryTextColor} type={'bold'}>{moment(props.article?.published_at).format('D MMMM YYYY, HH:mm')} | {moment(props.article?.published_at).fromNow()}</TextNty> */}
            {publisherValues &&

                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>

                    <TextNty type='bold' color={secondaryTextColor}>Audiencia: <TextNty type='regular' color={secondaryTextColor}>{publisherValues.audienceValue}</TextNty>   </TextNty>
                    <TextNty type='bold' color={secondaryTextColor}>Valor Económico: <TextNty type='regular' color={secondaryTextColor}>{publisherValues.economicalValue}</TextNty></TextNty>

                </View>
            }

        </View>
    )
}

export default ArticleHeading
