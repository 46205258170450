import React from 'react';
import Swiper from 'react-native-swiper/src';
import BottomSheetTextFontSelector from './BottomSheetTextFontSelector';
import BottomSheetTextStylesPremium from './BottomSheetTextStylesPremium';
import BottomSheetTextStylesSimple from './BottomSheetTextStylesSimple';


export interface BottomSheetTextStylesProps {
    navigation:any,
    onClose: () => void
}

export default function BottomSheetTextStyles({navigation, onClose}: BottomSheetTextStylesProps) {

    const swiperRef = React.useRef<Swiper>(null);

    if (typeof setImmediate === 'undefined') {
        global.setImmediate = setTimeout;
    }

    return (

        <Swiper index={0} showsButtons={false} ref={swiperRef} containerStyle={{justifyContent: 'center',flex: 1}} showsPagination={false} scrollEnabled={false}>
            <BottomSheetTextStylesSimple swiperRef={swiperRef} navigation={navigation} onClose={onClose}/>
            <BottomSheetTextStylesPremium swiperRef={swiperRef} navigation={navigation} onClose={onClose}/>
            <BottomSheetTextFontSelector swiperRef={swiperRef}/>
        </Swiper>

    )
}
