//@deprecated to be removed, see ArticleNewsToYouLight

import { forwardRef, useCallback } from 'react'
import { Dimensions, FlatListProps, LayoutChangeEvent, ListRenderItem, ListRenderItemInfo, Platform, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { getNumColumnsFlatlist } from 'src/core/utils'
import { Article } from 'systemDomain'
import LoadMoreButton from '../../LoadMoreButton'
import ArticleCard from '../cards/ArticleCard'
import { ArticleCardProps } from '../cards/ArticleCard/types'
import React from 'react'


export type ArticleListProps = Partial<FlatListProps<Article>> & {

    itemHeight?: number
    itemWidth?: number
    itemSeparatorHeight?: number,
    paginateEvery?: number,
    articleCardProps?: Omit<ArticleCardProps, 'article'>,
    onLoadMore?: () => void,
    loadMoreDisabled?: boolean,
    /**
     * Does not work yet
     */
    horizontal: boolean

}

function ArticleListC(props: ArticleListProps) {

    const [width, setWidth] = React.useState<number>(Dimensions.get('window').width)
    const height = Dimensions.get('window').height

    const onLayout = (e: LayoutChangeEvent) => {
        console.log({layoutW: e.nativeEvent.layout.width, w: width})
        if ( e.nativeEvent.layout.width == 0 || e.nativeEvent.layout.width == width) return;
        setWidth(e.nativeEvent.layout.width)
        // setHeight(e.nativeEvent.layout.height)
    }

    const numColsI = props.numColumns ? props.numColumns : getNumColumnsFlatlist(width);

    const DIMENSIONS = React.useMemo(() => {

        console.log('Generating dimensions', width)

        const LIST_HORIZONTAL_PADDING = 5//width * 0.005
        const ITEM_HORIZONTAL_PADDING = 5
        const ITEM_WIDTH = props.itemWidth ?? (width - (LIST_HORIZONTAL_PADDING * 2)) / numColsI
        // const ITEM_HEIGHT = props.itemHeight ?? height / 1.5 //ITEM_WIDTH * (Platform.OS == 'web' ? props.horizontal ? 1.35 : 1.65 : 0.9);
        const ITEM_HEIGHT = props.itemHeight ?? ITEM_WIDTH * (Platform.OS == 'web' ? 1.65 : 0.9);
        const ITEM_SEPARATOR_HEIGHT = props.itemSeparatorHeight ?? ITEM_HEIGHT * 0.05

        return {
            LIST_HORIZONTAL_PADDING,
            ITEM_HORIZONTAL_PADDING,
            ITEM_WIDTH,
            ITEM_HEIGHT,
            ITEM_SEPARATOR_HEIGHT
        }

    }, [width])

    // const LIST_HORIZONTAL_PADDING = width * 0.005
    // const ITEM_HORIZONTAL_PADDING = 5
    // const ITEM_WIDTH = props.itemWidth ?? (width - (LIST_HORIZONTAL_PADDING * 2)) / numColsI
    // const ITEM_HEIGHT = props.itemHeight ?? height / 1.5 //ITEM_WIDTH * (Platform.OS == 'web' ? props.horizontal ? 1.35 : 1.65 : 0.9);
    // const ITEM_SEPARATOR_HEIGHT = props.itemSeparatorHeight ?? ITEM_HEIGHT * 0.05

    const itemStyle = React.useMemo(() => ({
        width: DIMENSIONS.ITEM_WIDTH,
        height: DIMENSIONS.ITEM_HEIGHT,
        paddingHorizontal: DIMENSIONS.ITEM_HORIZONTAL_PADDING
    }), [DIMENSIONS.ITEM_WIDTH, DIMENSIONS.ITEM_HEIGHT, DIMENSIONS.ITEM_HORIZONTAL_PADDING])

    const paginationProps: Partial<FlatListProps<Article>> = {
        snapToInterval: (DIMENSIONS.ITEM_HEIGHT + DIMENSIONS.ITEM_SEPARATOR_HEIGHT) * props.paginateEvery,
        decelerationRate: 'fast'
    }


    const getItemLayoutInternal = useCallback((data, index) => {

        return (
            {
                length: DIMENSIONS.ITEM_HEIGHT,
                offset: (DIMENSIONS.ITEM_HEIGHT + DIMENSIONS.ITEM_SEPARATOR_HEIGHT) * (index),
                index
            }
        )

    }, [DIMENSIONS.ITEM_HEIGHT, DIMENSIONS.ITEM_SEPARATOR_HEIGHT])

    const defaultFooterComponent = () => props.onLoadMore && !props.loadMoreDisabled && props.data?.length > 0 ? <LoadMoreButton onLoadMore={() => { props.onLoadMore() }} /> : null;

    const renderItemInternal: ListRenderItem<Article> =
        useCallback((info: ListRenderItemInfo<Article>) => (
            <View style={itemStyle} key={info?.item?._id}>
                <ArticleCard
                    article={info.item}
                    {...props.articleCardProps}
                />
            </View>
        ), [DIMENSIONS.ITEM_HEIGHT, DIMENSIONS.ITEM_SEPARATOR_HEIGHT, itemStyle, props.articleCardProps])



    delete props.numColumns

    return (

        <FlatList<Article>
            key={numColsI + '_'}
            onLayout={onLayout}
            keyExtractor={(article) => article._id}
            getItemLayout={getItemLayoutInternal}
            renderItem={renderItemInternal}
            removeClippedSubviews={true}
            ItemSeparatorComponent={() => <View style={{ height: DIMENSIONS.ITEM_SEPARATOR_HEIGHT }} />}
            ListFooterComponent={props.ListFooterComponent ? props.ListFooterComponent : defaultFooterComponent}
            {...props.paginateEvery ? paginationProps : null}
            {...props}
            numColumns={numColsI}
            contentContainerStyle={[props.contentContainerStyle, { paddingBottom: 10,/*  borderWidth: 1, borderColor: 'black', */ paddingHorizontal: DIMENSIONS.LIST_HORIZONTAL_PADDING }]}
            columnWrapperStyle={numColsI > 1 ? {justifyContent: 'center'}: null}
        />

    )
}


/**
 * Using the WrappedTab component in order to fordward the `MutableRefObject` to the downside component without losing it's reference in memmory
 */
const ArticleList = forwardRef((props: ArticleListProps, ref: React.MutableRefObject<FlatList>) => {
    //In this case we dont use the 'ref' property, as the MutableRefObject created from the outside is going to be passed in the 'forwardedRef' property
    return <ArticleListC {...props} ref={ref ?? props.ref} />
})

export default ArticleList

