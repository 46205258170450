import { MutableRefObject, useCallback } from "react";
import { FlatList, useWindowDimensions } from "react-native";

export const useFlatListControls = (flatListRef: MutableRefObject<FlatList>, itemWidth: number, currentOffset: number, setCurrentOffset, items, listHorizontalPadding = 0, itemHorizontalPadding = 0) => {
    const { width } = useWindowDimensions()
    // const multiplier = isEnterprise ? 0.75 : 1;

    const handleNext = useCallback(() => {
        if (!items) return

        const itemWidthWithPadding = itemWidth + (itemHorizontalPadding * 2)
        const itemsThatFitOnScreen = Math.floor(width / itemWidthWithPadding)
        const totalWidthOfItems = itemWidthWithPadding * items.length
        let newOffset = currentOffset + (itemsThatFitOnScreen * itemWidthWithPadding);

        if (currentOffset == 0) {
            newOffset -= listHorizontalPadding
        }

        if (newOffset >= totalWidthOfItems - (itemsThatFitOnScreen * itemWidthWithPadding)) {
            newOffset = totalWidthOfItems - width + listHorizontalPadding;
        }

        flatListRef?.current?.scrollToOffset({ offset: newOffset });
        setCurrentOffset(newOffset);
    }, [flatListRef, currentOffset, items, width, listHorizontalPadding])

    const handlePrev = useCallback(() => {
        if (!items) return

        const itemWidthWithPadding = itemWidth + (itemHorizontalPadding * 2)
        const itemsThatFitOnScreen = Math.floor(width / itemWidthWithPadding)
        let newOffset = currentOffset - (itemsThatFitOnScreen * itemWidthWithPadding);

        if (newOffset < listHorizontalPadding) {
            newOffset = 0;
        }

        flatListRef?.current?.scrollToOffset({ offset: newOffset });
        setCurrentOffset(newOffset);
    }, [flatListRef, currentOffset, items, width, listHorizontalPadding])


    return { handleNext, handlePrev }
}
