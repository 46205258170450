import 'moment/locale/es';
import React, { memo } from 'react';
import { GestureResponderEvent, StyleSheet, View, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { fixed_colors } from '../../../../core/theme/colors';
// import { theme } from '../../../../core/theme';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import TitleNty from '../../nty/title/TitleNty';
import { getVerticalCardDimensions } from '../lists/ArticleListHorizontal';


export type ArticleCardPlaceHolderProps = {
    title?: string,
    subtitle?: string,
    onPress?: () => void,
    onLongPress?: () => void,
    cardStyle?: ViewStyle
    dimensions?: { width: number, height: number, separator?: number }
}



const ArticleCardPlaceHodler = (
    {
        title,
        subtitle,
        onPress,
        onLongPress,
        cardStyle,
        dimensions = getVerticalCardDimensions()
    }: ArticleCardPlaceHolderProps) => {

    const theme = useAppTheme()

    const styles = React.useMemo(() => {

        return StyleSheet.create({
            mainContainer: {
                width: dimensions.width,
                height: dimensions.height,
                marginLeft: dimensions.separator,
                backgroundColor: fixed_colors.white[700],
                borderRadius: 10,
                overflow: 'hidden',
                // borderWidth: 2,
                // borderColor: fixed_colors.grey[400],
                justifyContent: 'center',

                // borderRadius: 5

            }
        })

    }, [theme])


    const _onPress = (event?: GestureResponderEvent) => {
        onPress ? onPress() : null;

    }

    const _onLongPress = (event?: GestureResponderEvent) => {
        onLongPress ? onLongPress() : null;

    }

    return (
        <View style={[styles.mainContainer, cardStyle]}>

            <TouchableOpacity style={{ flex: 1 }} containerStyle={{ flex: 1 }} onPress={_onPress} onLongPress={_onLongPress}>

                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: fixed_colors.gray[500] }}>

                    <TitleNty fontSize={18} type='light' color={fixed_colors.grey[500]} >{title}</TitleNty>

                </View>

            </TouchableOpacity>
        </View>
    );


}



export default memo(ArticleCardPlaceHodler);



