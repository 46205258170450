import React from 'react';
import { GestureResponderEvent, Image, StyleSheet, View, ViewStyle } from 'react-native';
import { IconButton, TouchableRipple } from 'react-native-paper';
import { FavouriteListInterface } from 'systemDomain';
import { getLocalhost } from '../../../../../environments';

import { fixed_colors } from '../../../../core/theme/colors';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import { getRandomColor } from '../../../../core/utils';
import { useFavouritesStore } from '../../../context/favoritesStore';
import { useFavouriteListOptionsStore } from '../../../context/optionStores';
import { useSnackBar } from '../../../context/snackBarStore';
import TitleNty from '../../nty/title/TitleNty';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens';


export type FavouriteListCardProps = {

  list: FavouriteListInterface,
  onPress?: (list: FavouriteListInterface, ev?: GestureResponderEvent) => void,
  onLongPress?: (list: FavouriteListInterface, ev?: GestureResponderEvent) => void,
  style?: ViewStyle
}

function FavouriteListCard(props: FavouriteListCardProps) {

  const list = props.list

  let favourites = [...useFavouritesStore.getState().favourites]

  const theme = useAppTheme()

  const showInfoMessage = useSnackBar(store => store.showInfoMessage);

  const { open: openFavouriteListOptions } = useFavouriteListOptionsStore.getState()

  const style = StyleSheet.create({
    card: {
      borderRadius: 10,
      flexDirection: 'column',
      width: "100%",
      overflow: 'hidden'
    }
  })

  const navigator = useNavigation<StackNavigationProp<ScreensParamsList>>()

  const sendEmail = async () => {


    navigator.navigate('sendNewsletter', { id: list._id })


  }
  // const sendEmail = async () => {
  //   // try {
  //   //   const favs = (await FavouriteListApi.readFavourites(list._id, 0, 30))
  //   //   const text = favs.map(fav => `${fav.article.title}\r\n - ${getNewspaperName([fav.article.site])[0]?.props?.name ?? fav.article.site}\r\n----------------\r\n\r\n${fav.article.url}\r\n\r\n`).join('\r\n--\r\n\r\n')
  //   //   const url = encodeURI(`mailto:${currentUser.email}?subject=Newsletter '${list.name}'&body=${text}`)
  //   //   Linking.openURL(url)
  //   // } catch (e) {
  //   //   showInfoMessage('Hubo un problema recuperando los guardados de la lista, inténtalo más tarde.');
  //   // }

  //   await useFavouritesStore.getState().sendEmailFromFavlist(list, showInfoMessage)


  // }

  const FavListImage = React.useMemo(() => {


    let pic;

    if (props.list.image) {
      pic = list.image
    }

    if (!pic || pic?.startsWith('#')) {

      pic = favourites.sort((a, b) => b.created.localeCompare(a.created)).find(fav => fav.list == list._id && fav?.article?.photo)?.article?.photo ?? pic

    }


    return (

      <View style={[StyleSheet.absoluteFillObject, { zIndex: -1 }]}>

        {pic ?

          pic.startsWith('#') ?

            <View style={{ backgroundColor: pic, flex: 1 }} />
            :

            <Image
              source={
                {
                  uri: pic.includes("http") ?
                    pic
                    : getLocalhost + pic
                }
              }
              style={{ width: "100%", height: "100%", resizeMode: 'cover' }}
            /> :

          <View style={{ backgroundColor: getRandomColor(), flex: 1 }}>
          </View>
        }
      </View>

    )
  }, [props.list, favourites])



  return (
    <View style={[style.card, theme.shadows.box.medium, props.style]}>
      <>
        <View style={[StyleSheet.absoluteFillObject, { backgroundColor: fixed_colors.black[600] + '60' }]} />
        {FavListImage}
        <IconButton
          icon={'dots-horizontal'}
          color={theme.colors.text_primary[300]}
          style={{ position: 'absolute', left: 5, top: 2, backgroundColor: theme.colors.background[300] + 'D9', zIndex: 10000 }}
          onPress={() => openFavouriteListOptions(list)} />
        <IconButton
          icon={'email-fast'}
          color={theme.colors.text_primary[300]}
          style={{ position: 'absolute', right: 5, top: 2, backgroundColor: theme.colors.background[300] + 'D9', zIndex: 10000 }}
          onPress={sendEmail} />
        {list.default && <IconButton
          icon={'check-decagram'}
          color={theme.colors.success[500]}
          style={{ position: 'absolute', right: 5, bottom: 5, backgroundColor: theme.colors.background[300] + 'D9', zIndex: 10000 }}
          onPress={null} />}
        <TouchableRipple
          style={{ flex: 1 }}
          onPress={(ev) => props.onPress ? props.onPress(props.list, ev) : null}
          onLongPress={(ev) => props.onLongPress ? props.onLongPress(props.list, ev) : null}
        >
          <View style={[{ flex: 1, padding: 10, flexDirection: 'column', justifyContent: 'flex-end' }]}>

            <TitleNty style={{ marginBottom: 10 }} fontSize={25} type={'semiBold'} color={fixed_colors.white[400]}>{list.name}</TitleNty>
          </View>
        </TouchableRipple>
      </>
    </View>
  )
}

export default FavouriteListCard


