import React, { memo } from 'react';
import {
  Keyboard,
  Platform,
  StatusBar,
  useWindowDimensions,
  View,
  ViewStyle
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getAppTheme, useAppTheme } from '../core/theme/ThemeBuilder';


export type BackGroundProps = {
  safeInsets?: boolean,
  /**
   * Use this prop in order to make this background component behave like a mobile template.
   *
   * __This prop will only take an effect in Platform.OS == 'web' for now__
   *
   * Mobile templates are meant to be some kind of 16:9 aspect ratio, so in order to keep things 'calculated' and not fixed,
   * we'll calculate the maximum width based on the actual size multiplied by an 'standard' aspect ratio.
   *
   */
  mobileConstraint?: boolean,
  children: React.ReactNode;
  style?: ViewStyle;
  backgroundColor?: string;
  dismissKeyboardOnUnhandledTouches?: boolean
};

const Background = ({ safeInsets, children, style, backgroundColor, mobileConstraint = false, dismissKeyboardOnUnhandledTouches = true }: BackGroundProps) => {

  const insets = useSafeAreaInsets();
  const { height, width } = useWindowDimensions();

  const colors = getAppTheme().colors

  // If we're going to emulate a mobile, then calculate width based on height.
  // In this case its a portait 16:9 aspect ratio, so 16 units height equals 9 units width
  // normal aspect ratio => w / h
  const verticalAspectRatio = 9 / 16;

  // Ex: height: 800, width: x => 800/x = h/w
  // => 800/x = 16/9 =>  800 * 1/x = 16/9 => 800 * x = 9/16 => x = 800 * (9 / 16)

  const currentWidth = mobileConstraint && Platform.OS == 'web' ? (width > 500 ? (height * verticalAspectRatio) : width) : width;

  const handleUnhandledTouches = () => {
    Keyboard.dismiss();
    return false;
  }

  return (
    // <View style={{alignItems: 'center', flex: 1}}>
    <View style={{
      backgroundColor: backgroundColor ? backgroundColor : colors.background[500],
      flex: style && style.height ? null : 1,
      height: style && style.height ? style.height : null,
      width: style && style.width ? style.width : currentWidth,
      paddingTop: safeInsets ? insets.top : 0,
      paddingBottom: safeInsets ? insets.bottom : 0,
      alignSelf: mobileConstraint ? 'center' : null,
      ...style
    }}
      onStartShouldSetResponder={dismissKeyboardOnUnhandledTouches ? handleUnhandledTouches : null}
    >

      {/* {safeInsets ? <View style={{height: insets.top, width: "100%", backgroundColor: 'transparent'}}/> : null} */}


        {children}

      {/* {safeInsets ? <View style={{height: insets.top, width: "100%", backgroundColor: 'transparent'}}/> : null} */}
    </View>
    // </View>
  );
};

export default memo(Background);
