import { ColorValue } from 'react-native';
import { DefaultTheme } from 'react-native-paper';

// const defaultShadowOffset = {
//   width: 1,
//   height: 1
// };


const defaultShadowColor = '#262626'

function interpolate(i, a, b, a2, b2): number {
  return (i - a) * (b2 - a2) / (b - a) + a2;
}

export function elevationShadowStyle(elevation: number, shadowColor?: ColorValue) {
  return {
    elevation,
    shadowColor: shadowColor ?? defaultShadowColor,
    shadowOffset: { width: elevation == 0 ? 0 : 0.2, height: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 1, 10) },
    shadowOpacity: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 0.2, 0.55),
    shadowRadius: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 1, 20)
  };
}

export const GESTAMP_THEME = {

  colors: {
    main: '#063f66',
    background: '#ffffff'
  }

}


/**
 *
 * Old theme.
 * @deprecated use 'getAppTheme' or 'useAppTheme' instead
 *
 */
export const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
    primary: 'black',
    secondary: '#F05A28',
    blue: '#0089FF',
    shadedBox: '#efefef',
    navBar: 'white',
    colorTitleSection: '#555',
    colorParagraphItem: '#777',
    companyTextColor: '#062230',
    text: '#141c27',
    textInactive: '#a0a9b8',
    unselectedText: '#CECECE',
    ntyColor: '#E83F41',
    ntyColorDark: '#8f1f21',
    success: '#53a653',
    gray: '#dedede',
    grey: {
      full: '#383838',
      medium: '#7d7d7d',
      light: '#b8b8b8'
    },
    black: {
      full: '#000000',
      medium: '#121212',
      light: '#1f1f1f',
      semiTransparent: 'rgba(0,0,0,.3)'
    },
    white: {
      full: '#ffffff',
      medium: '#f5f5f5',
      light: '#e8e8e8',
      semiTransparent: 'rgba(255,255,255,.3)'
    },
    warning: {
      light: '#ffe838',
      normal: '#f7ca14',
      dark: '#9c6e19'
    }
  },
  shadows: {
    box: {
      dark: {
        xsmall: elevationShadowStyle(1),
        small: elevationShadowStyle(2),
        medium: elevationShadowStyle(4),
        large: elevationShadowStyle(6)
      }
    }
  },
  gradients: {
    main: ['black', 'black']
  }
};

// shadows: {
//   box: {
//     dark: {
//       xsmall: {
//         shadowOffset: defaultShadowOffset,
//         shadowColor: defaultShadowColor,
//         shadowRadius: 5,
//         shadowOpacity: .15,
//         elevation: 1
//       },
//       small: {
//         shadowOffset: defaultShadowOffset,
//         shadowColor: defaultShadowColor,
//         shadowRadius: 7,
//         shadowOpacity: .2,
//         elevation: 2
//       },
//       medium: {
//         shadowOffset: defaultShadowOffset,
//         shadowColor: defaultShadowColor,
//         shadowRadius: 10,
//         shadowOpacity: .25,
//         elevation: 3
//       },
//       large: {
//         shadowOffset: defaultShadowOffset,
//         shadowColor: defaultShadowColor,
//         shadowRadius: 15,
//         shadowOpacity: .3,
//         elevation: 4
//       }
//     }
//   }
// }
/**
 *
 * Old theme.
 *
 * @deprecated use 'getAppTheme' or 'useAppTheme' instead
 *
 */
export const theme2 = {

  ...DefaultTheme,
  fonts: {
    regular: { fontFamily: "Roboto_400Regular" },
    medium: { fontFamily: "Roboto_500Medium" },
    light: { fontFamily: "Roboto_300Light" },
    bold: { fontFamily: "Roboto_700Bold" }
  },
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
    primary: '#E83F41',
    primaryLight: '#ff746c',
    primaryDark: '#af001a',
    accent: '#424242',
    accentLight: '#6d6d6d',
    accentDark: '#1b1b1b',
    accentWhite: '#ffffff',
    primaryText: '#ffffff',
    accentText: '#141c27',
    blue: '#0089FF',
    shadedBox: '#efefef',
    navBar: 'white',
    colorTitleSection: '#555',
    colorParagraphItem: '#777',
    companyTextColor: '#062230',
    text: '#141c27',
    unselectedText: '#CECECE',
    ntyColor: '#E83F41',
    categories: {
      deportes: '#f8ffb8',
      economia: '#d8d7f6',
      internacional: '#6a36ad',
      generalistas: '#b00009'
    }
  },
  gradients: {
    main: ['black', 'black']
  }

}
