import React from 'react'
import VirtualScrollHeaderScreen from '../common-ui/VirtualScrollHeaderScreen'
import TextNty from '../nty/text/TextNty'
import SearchResults from './components/SearchResults'
import SearchBar from './components/SearchBar'
import { ScrollView, View } from 'react-native'
import { hexTransparency } from '../../../core/jsutils'
import { useAppTheme } from '../../../core/theme/ThemeBuilder'
import { fixed_colors } from '../../../core/theme/colors'
import { useScrollToTop } from '@react-navigation/native'
import { FlatList } from 'react-native-gesture-handler'
import { Platform } from 'react-native'
import { useSearchStore } from 'src/context/appSearchStore'


function SearchPage() {

    const scrollRef = React.useRef<FlatList>()
    const scrollToTopPage = async () => {
        scrollRef.current.scrollToOffset({ animated: true, offset: 0 });
    }
    const onTabIconClickedBehaviour = React.useRef({
        scrollToTop: scrollToTopPage
    })
    useScrollToTop(onTabIconClickedBehaviour);


    return (

        <VirtualScrollHeaderScreen
            ref={scrollRef}
            headerHeight={Platform.OS == 'web' ? 180: 230}
            headerAnimated={true}
            // headerBgColor={'transparent'}
            onRefresh={() => useSearchStore.getState().refresh() }
            header={
                    <SearchBar />
            }
        >
            <SearchResults />
        </VirtualScrollHeaderScreen>

    )
}

export default SearchPage
