import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { memo } from "react";
import { View, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { ActivityIndicator } from 'react-native-paper';
import { Article, Interest, Section, SectionTypes } from "systemDomain";
import ActionApi, { ACTION_NAMES } from '../../api/ActionApi';
import EnterpriseApi from '../../api/EnterpriseApi';
import { ScreensParamsList } from '../../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../../core/theme/ThemeBuilder';
import Background from "../Background";
import ArticleCardPlaceHolder from '../components/article/cards/ArticleCardPlaceHolder';
import ArticleListNewsToYouLight from '../components/article/lists/ArticleListNewsToYouLight';

import useSessionStore from 'src/context/sessionStore';
import { FeedHeader } from '../components/enterprise/lists/header/EnterpriseFeedHeader';
import ArticlesSection from '../components/new-home/components/sections/article/ArticlesSection';
import TextNty from '../components/nty/text/TextNty';
import ArticleList from '../components/article/lists/ArticleList';


const SearchEnterpriseFeed = () => {

  const route = useRoute<RouteProp<ScreensParamsList, 'searchEnterpriseFeed'>>()
  const navigation = useNavigation<StackNavigationProp<ScreensParamsList, 'searchEnterpriseFeed'>>();

  const [filteredArticles, setFilteredArticles] = React.useState<Article[]>();
  const [interests, setInterests] = React.useState<(Interest & { articles: Article[] })[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [noMore, setNoMore] = React.useState<boolean>(false);
  // const [searchQuery, setSearchQuery] = React.useState('');
  const [feedId, setFeedId] = React.useState<string>(route?.params?.id);
  const { feeds, enterprise } = useSessionStore((store) => ({ feeds: store.feeds, enterprise: store.enterprise }))

  console.log(feedId)

  const feed = React.useMemo(() => {
    console.log('Searching With FeedId: ' + feedId)
    console.log('Within feeds: ' + feeds?.length)
    const feed = feeds?.find((feed) => feed._id == feedId)
    console.log(feed)
    return feed

  }, [feedId])

  const [displayType, setDisplayType] = React.useState<'view-sequential' | 'view-grid'>(feed?.version == 'v2' && feed?.interests?.length > 0 ? 'view-grid' : 'view-sequential')

  const readFilteredArticles = async () => {
    setLoading(true);
    try {
      const articles = await EnterpriseApi.getFeedArticlesByFeedIdv2(feedId, 0, 50)
      setFilteredArticles(articles);
      ActionApi.createFrom({ name: ACTION_NAMES.ENTERPRISE_FEED_FETCH, data: { enterprise: enterprise.key, feed: feed?.name, feedId: feedId } })
    } catch (e) {
      console.error('Error: ', e);
    } finally {
      setLoading(false);
    }
  }

  const readFeedArticlesGrouped = async () => {
    setLoading(true)
    try {
      const articles = await EnterpriseApi.getFeedArticlesGrouped(feed._id)
      setInterests(articles);
      ActionApi.createFrom({ name: ACTION_NAMES.ENTERPRISE_FEED_FETCH_GROUPED, data: { enterprise: enterprise.key, feed: feed.name } })
    } catch (e) {
      console.error('Error: ', e);
      setInterests([])
    } finally {
      setLoading(false)
    }
  }

  const onLoadMore = async () => {


    var itemsToSkip: number = filteredArticles ? filteredArticles.length : 0;
    try {
      setLoading(true)
      const articles = await EnterpriseApi.getFeedArticlesByFeedIdv2(feed._id, itemsToSkip, 50)
      // console.log('got '+ articles.length + ' articles')
      if (articles && articles.length > 0) {
        // console.log('appending articles: '+ articles[0].props.title)
        setFilteredArticles((filteredArticles ?? []).concat(articles));
      } else {
        setNoMore(true)
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false)
    }

  }
  const onRefresh = async () => {

    ActionApi.createFrom({ name: ACTION_NAMES.ENTERPRISE_FEED_REFRESH, data: { enterprise: enterprise.key, feed: feed.name } })
    await init()

  }

  const fetchArticles = React.useCallback((limit: number = 15, skip: number = 0, sort: { [key: string]: 1 | -1 } = { published_at: -1 }) => {
    return EnterpriseApi.getFeedArticlesByFeedIdv2(feedId, skip, limit)
  }, [feedId])


  const Header = React.useMemo(() =>
    feed ? <FeedHeader
      data={filteredArticles}
      fetchArticles={fetchArticles}
      feed={feed}
      onRefresh={onRefresh}
      showBackButton={true}
      display={{ showDisplay: true, displayType, setDisplayType }} /> : null
    , [feed, displayType])

  const init = async () => {

    if (displayType == 'view-grid') {

      await readFeedArticlesGrouped();
    } else {

      await readFilteredArticles();
    }

  }

  React.useEffect(() => {
    if (feedId)
      init()
  }, [feedId, displayType])

  // React.useEffect(() => {
  //   const key: string = route?.params?.id;
  //   setFeedId(key);
  // }, [route])

  const { height } = useWindowDimensions();
  const theme = useAppTheme()

  return (

    <Background style={{ height: height }} safeInsets>

      {loading && <ActivityIndicator color={theme.colors.primary[500]} size={50} style={{ position: 'absolute', alignSelf: 'center', top: "45%", zIndex: 1000 }} />}

      <View style={{ flex: 10 }}>

        {displayType == 'view-sequential' ?

          <ArticleList

            articleCardProps={{
              feed: feed
            }}
            ListHeaderComponent={Header}
            ListEmptyComponent={!loading ? () => <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <TextNty color={theme.colors.text_primary[500]}>No se han encontrado resultados...</TextNty>
              {/* <TextNty type='extraLight' fontSize={15} color={theme.colors.text_primary.muted} style={{ marginTop: 10 }}>Prueba a cambiar los parámetros de tu búsqueda.</TextNty> */}
            </View> : null}
            // loadMoreDisabled={noMore}
            onLoadMore={onLoadMore}
            data={filteredArticles}
            onRefresh={onRefresh}
          />

          :
          <ScrollView style={{ flex: 1 }}>

            {Header}

            {interests && interests.map((interest, index) => {

              const section: Section = {
                title: interest.name,
                type: SectionTypes.ARTICLES,
                info: {
                  data: {
                    type: 'data',
                    items: interest.articles
                  }
                },
                goTo: {
                  screen: 'viewInterestArticles',
                  params: { feed_id: feedId, interest_key: interest.key }
                }

              }

              return <View style={{ marginBottom: 30 }} key={interest.key + index}>
                <ArticlesSection
                  section={section}
                  sectionStyles={{ container: { backgroundColor: 'transparent' }, textContainer: { paddingLeft: 10 } }}
                  listProps={{
                    contentContainerStyle: { paddingHorizontal: 10, paddingVertical: 5 },
                    ListFooterComponent: section.info.data.items && section.info.data.items.length > 0 && section.info.data.items.length <= 20 ? () => {
                      return <View style={{ paddingLeft: 5, flex: 1, width: 200 }}>
                        <ArticleCardPlaceHolder title="Ver más" onPress={() => { navigation.navigate('viewInterestArticles', { feed_id: feedId, interest_key: interest.key }) }} />
                      </View>
                    } : null,
                    ListEmptyComponent: () => {
                      return <View style={{ flex: 1 }}>
                        <ArticleCardPlaceHolder title="Aquí no hay nada" dimensions={{ height: 250, width: 250 }} />
                      </View>
                    }
                  }}
                />
              </View>

            })}

          </ScrollView>
        }
      </View>
    </Background>
  );
}

export default memo(SearchEnterpriseFeed);

