import produce from "immer";
import { Article, FavouriteListInterface, HighlightModel } from "systemDomain";
import { create } from "zustand";


export type ModalStoreType<T = undefined> = {
    modalVisible: boolean,
    open: (payload?: T) => void,
    close: (payload?: T) => void,
    toggleModal: () => void,
    setModalVisible: (payload: boolean) => void
}

export type ArticleOptionsStoreType = ModalStoreType<Article> & {
    article: Article,
    setArticle: (article: Article) => void,
}

export const useArticleOptionsStore = create<ArticleOptionsStoreType>((set, get) => ({
    article: null,
    modalVisible: false,
    toggleModal: () => set(produce((draft: ArticleOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: (article?: Article) => {

        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(article), 50)
            return
        }

        let update: any = {}
        if (article && article?._id != get()?.article?._id) {
            update.article = article
        }
        update.modalVisible = true;
        set(update)
    },
    close: (article?: Article) => set(produce((draft: ArticleOptionsStoreType) => {
        if (article) {
            draft.article = article
        }
        draft.modalVisible = false;
    })),
    setArticle: (payload) => set(produce((draft: ArticleOptionsStoreType) => {
        draft.article = payload;
    })),
    setModalVisible: (payload) => set(produce((draft: ArticleOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));


export type ArticleEnterpriseAdminOptionsList = ModalStoreType<Article> & {
    article: Article,
    setArticle: (article: Article) => void,
}

export const useArticleEnterpriseAdminOptionsStore = create<ArticleEnterpriseAdminOptionsList>((set, get) => ({
    article: null,
    modalVisible: false,
    toggleModal: () => set(produce((draft: ArticleEnterpriseAdminOptionsList) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: (article?: Article) => {
        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(article), 50)
            return
        }
        let update: any = {}
        if (article && article?._id != get()?.article?._id) {
            update.article = article
        }
        update.modalVisible = true;
        set(update)
    },
    close: (article?: Article) => set(produce((draft: ArticleEnterpriseAdminOptionsList) => {
        if (article) {
            draft.article = article
        }
        draft.modalVisible = false;
    })),
    setArticle: (payload) => set(produce((draft: ArticleEnterpriseAdminOptionsList) => {
        draft.article = payload;
    })),
    setModalVisible: (payload) => set(produce((draft: ArticleEnterpriseAdminOptionsList) => {
        draft.modalVisible = payload;
    }))
}));




export type ArticleSuperUserOptionsStoreType = ModalStoreType<Article> & {
    article: Article,
    setArticle: (article: Article) => void,
}

export const useArticleSuperUserOptionsStore = create<ArticleSuperUserOptionsStoreType>((set, get) => ({
    article: null,
    modalVisible: false,
    toggleModal: () => set(produce((draft: ArticleOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: (article?: Article) => {

        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(article), 50)
            return
        }

        let update: any = {}
        if (article && article?._id != get()?.article?._id) {
            update.article = article
        }
        update.modalVisible = true;
        set(update)
    },
    close: (article?: Article) => set(produce((draft: ArticleOptionsStoreType) => {
        if (article) {
            draft.article = article
        }
        draft.modalVisible = false;
    })),
    setArticle: (payload) => set(produce((draft: ArticleOptionsStoreType) => {
        draft.article = payload;
    })),
    setModalVisible: (payload) => set(produce((draft: ArticleOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));

export type HighlightOptionsStoreType = ModalStoreType<HighlightModel> & {
    highlight: HighlightModel,
    setHighlight: (highlight: HighlightModel) => void
}

export const useHighlightOptionsStore = create<HighlightOptionsStoreType>((set, get) => ({
    highlight: null,
    modalVisible: false,
    setHighlight: (payload) => set(produce((draft: HighlightOptionsStoreType) => {
        draft.highlight = payload;
    })),
    toggleModal: () => set(produce((draft: HighlightOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: (highlight?: HighlightModel) => {
        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(highlight), 50)
            return
        }
        let update: any = {}
        if (highlight && highlight?.props?._id != get()?.highlight?.props?._id) {
            update.highlight = highlight
        }
        update.modalVisible = true;
        set(update)
    },
    close: (highlight?: HighlightModel) => set(produce((draft: HighlightOptionsStoreType) => {
        if (highlight) {
            draft.highlight = highlight
        }
        draft.modalVisible = false;
    })),
    setModalVisible: (payload) => set(produce((draft: HighlightOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));


export type FavouriteListOptionsStoreType = ModalStoreType<FavouriteListInterface> & {
    list: FavouriteListInterface,
    setFavouriteList: (list: FavouriteListInterface) => void,
}

export const useFavouriteListOptionsStore = create<FavouriteListOptionsStoreType>((set, get) => ({
    list: null,
    modalVisible: false,
    setFavouriteList: (payload) => set(produce((draft: FavouriteListOptionsStoreType) => {
        draft.list = payload;
    })),
    toggleModal: () => set(produce((draft: FavouriteListOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: (payload?: FavouriteListInterface) => {

        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(payload), 50)
            return
        }

        let update: any = {}
        if (payload && payload?._id != get()?.list?._id) {
            update.list = payload
        }
        update.modalVisible = true;
        set(update)
    },
    close: (payload?: FavouriteListInterface) => set(produce((draft: FavouriteListOptionsStoreType) => {
        if (payload) {
            draft.list = payload
        }
        draft.modalVisible = false;
    })),
    setModalVisible: (payload) => set(produce((draft: FavouriteListOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));

export type OpenFavouriteListSelectorOptions = {
    article: Article
}

export type FavouriteListSelectorStoreType = Omit<ModalStoreType, 'open'> & {

    article: Article,
    open: (options?: OpenFavouriteListSelectorOptions) => Promise<FavouriteListInterface | undefined>
    /**
     * This is used to resolve the promise returned by the 'open' method.
     * @see FavouriteListSelectorModal
     */
    resolve: (value: FavouriteListInterface | undefined | PromiseLike<FavouriteListInterface | undefined>) => void
}



export const useFavouriteListSelectorStore = create<FavouriteListSelectorStoreType>((set, get) => ({
    article: null,
    modalVisible: false,
    resolve: null,
    open: (options?: OpenFavouriteListSelectorOptions) => {
        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(options), 50)
            return
        }

        let resolvePromise

        const promise = new Promise<FavouriteListInterface | undefined>(
            (resolve) => {
                resolvePromise = resolve
            }
        )

        let update: any = {}

        if (options?.article) {
            update.article = options.article
        }

        update.modalVisible = true;
        update.resolve = resolvePromise
        set(update)
        return promise
    },
    close: () => set(produce((draft: FavouriteListSelectorStoreType) => {
        draft.modalVisible = false;
    })),
    toggleModal: () => set(produce((draft: FavouriteListSelectorStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    setModalVisible: (payload) => set(produce((draft: FavouriteListSelectorStoreType) => {
        draft.modalVisible = payload;
    }
    )),
}));

