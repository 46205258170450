import { MaterialCommunityIcons } from '@expo/vector-icons'
import moment from 'moment'
import React from 'react'
import { Dimensions, GestureResponderEvent, Pressable, ScrollView, StyleSheet, View, ViewStyle } from 'react-native'
import { Chip } from 'react-native-paper'
import { useSnackBar } from 'src/app/context/snackBarStore'
import { DateFilterType, useSearchStore } from 'src/context/appSearchStore'
import useDebounce from 'src/core/hooks/useDebounce'
import { fixed_colors } from 'src/core/theme/colors'
import PublishersSelector from '../../brands_sources/PublisherSelector'
import { NewsToYouSheetModalType } from '../../modals/general/NewsToYouSheetModal'
import NtySheetModal from '../../modals/general/NtySheetModal'
import TextNty from '../../nty/text/TextNty'
import { ChipIcon, useSearchChipStyles } from './styles'
import { useSearchDateModal, useSearchPublisherSelectorModal } from 'src/app/context/searchModalsStores'
import { formatDate } from 'src/core/jsutils'

const readeableDate = (date: string) => {
    return moment(date).format('DD/MM - HH:mm[h]')
}






function DateChip(props: { onPress?: () => void }) {

    const dateFilters = useSearchStore((store) => ({ fromDate: store.currentSearch?.fromDate, toDate: store.currentSearch?.toDate }))

    const styles = useSearchChipStyles()

    const isSelected = React.useMemo(() => {
        return !!(dateFilters.fromDate || dateFilters.toDate)
    }, [dateFilters])

    const chipText = React.useMemo(() => {

        const dateFilterType = useSearchStore.getState().dateFilterType

        if (dateFilterType && dateFilterType != DateFilterType.CUSTOM) {
            return dateFilterType
        }

        // const now = moment()
        // const startOfDay = moment().startOf('day');

        if (dateFilters.fromDate && dateFilters.toDate) return `De ${formatDate(dateFilters.fromDate)} - A ${formatDate(dateFilters.toDate)}`
        if (dateFilters.fromDate) return `Desde ${formatDate(dateFilters.fromDate)}`
        if (dateFilters.toDate) return `Hasta ${formatDate(dateFilters.toDate)}`

    },
        [dateFilters])

    const _onPress = React.useCallback(() => {
        useSearchDateModal.getState().open()
        props.onPress?.()
    }, [props.onPress])

    return <Chip
        mode='outlined'
        icon={() => <ChipIcon icon={'calendar'} color={isSelected ? styles.selectedChipText.color : fixed_colors.grey[500]} />}
        selected={isSelected}
        style={isSelected ? styles.selectedChip : styles.chip}
        textStyle={isSelected ? styles.selectedChipText : styles.chipText}
        onPress={_onPress}
    >{chipText}</Chip>


}


function FormatChips(props: { onPress?: (clickedFormats: string[]) => void }) {

    const [formats, setFormats] = React.useState<string[]>(null)

    const debouncedFormats = useDebounce(formats, 500)

    React.useEffect(() => {
        if (!debouncedFormats) return
        useSearchStore.getState().search(null)
    }, [debouncedFormats])

    const formatFilters = useSearchStore((store) => store.currentSearch?.formats)

    const styles = useSearchChipStyles()

    const _onPress = React.useCallback((clickedFormats: string[]) => {
        const search = useSearchStore.getState().toggleFormat(clickedFormats)

        setFormats(search.formats ?? [])

        props.onPress?.(clickedFormats)
    }, [props.onPress])

    return <>

        <Chip
            mode='outlined'
            icon={() => <ChipIcon icon={'web'} color={formatFilters?.includes('Digital') ? styles.selectedChipText.color : fixed_colors.grey[500]} />}
            selected={formatFilters?.includes('Digital')}
            style={formatFilters?.includes('Digital') ? styles.selectedChip : styles.chip}
            textStyle={formatFilters?.includes('Digital') ? styles.selectedChipText : styles.chipText}
            onPress={() => {
                _onPress(['Digital'])
            }}
        >Web</Chip>
        <Chip
            mode='outlined'
            icon={() => <ChipIcon icon={'newspaper'} color={formatFilters?.includes('Papel') ? styles.selectedChipText.color : fixed_colors.grey[500]} />}
            selected={formatFilters?.includes('Papel')}
            style={formatFilters?.includes('Papel') ? styles.selectedChip : styles.chip}
            textStyle={formatFilters?.includes('Papel') ? styles.selectedChipText : styles.chipText}
            onPress={() => {
                _onPress(['Papel'])
            }}
        >Papel</Chip>
        <Chip
            mode='outlined'
            icon={() => <ChipIcon icon={'television-play'} color={formatFilters?.includes('Video') ? styles.selectedChipText.color : fixed_colors.grey[500]} />}
            selected={formatFilters?.includes('Video')}
            style={formatFilters?.includes('Video') ? styles.selectedChip : styles.chip}
            textStyle={formatFilters?.includes('Video') ? styles.selectedChipText : styles.chipText}
            onPress={() => {
                _onPress(['Audio', 'Video'])
            }}
        >Multimedia</Chip>

    </>


}


function PublisherChip(props: { onPress?: () => void }) {

    const styles = useSearchChipStyles()
    const publishers = useSearchStore((store) => ({ search: store.currentSearch, sources: store.currentSearch?.sources, brands: store.currentSearch?.sources }))

    const hasSearch = React.useMemo(() => {

        return publishers.search?.searchText && publishers.search?.searchText.length >= 2

    }, [publishers.search])

    const isSelected = React.useMemo(() => {
        return !!publishers.sources?.length
    }, [publishers])

    const _onPress = React.useCallback(() => {

        useSearchPublisherSelectorModal.getState().open()

    }, [props.onPress])


    return <Chip
        mode='outlined'
        icon={() => <ChipIcon icon={'newspaper-variant'} color={isSelected ? styles.selectedChipText.color : fixed_colors.grey[500]} />}
        selected={isSelected}
        style={isSelected ? styles.selectedChip : styles.chip}
        textStyle={isSelected ? styles.selectedChipText : styles.chipText}
        onPress={hasSearch ? _onPress : () => useSnackBar.getState().showInfoMessage('La búsqueda debe contener al menos 2 caracteres')}
    >Medio</Chip>
}



function SearchFilters() {

    return (
        <View style={{ flex: 4, minHeight: 40 }}>

            <ScrollView horizontal={true} style={{ flex: 1 }} contentContainerStyle={{ paddingVertical: 2 }}>

                <DateChip />

                <PublisherChip />

                <FormatChips />

            </ScrollView>
        </View>
    )

}

const FormatButton = (props: { selectedFormat?: string, height?: number, onPress?: (event: GestureResponderEvent) => void, style?: ViewStyle }) => {

    const publishersLogos = ['newspaper', 'television-play', 'web']

    const CHIP_HEIGHT = props.height ?? 35
    const CHIP_COLOR = fixed_colors.white[200]
    const CHIP_TEXT_COLOR = fixed_colors.grey[500]

    const styles = StyleSheet.create({
        chip: {
            height: CHIP_HEIGHT,
            // width: CHIP_HEIGHT * 2.5,
            borderRadius: CHIP_HEIGHT / 2.333,
            backgroundColor: CHIP_COLOR,
            borderColor: CHIP_TEXT_COLOR,
            borderWidth: 1,
            paddingHorizontal: CHIP_HEIGHT / 2,
            paddingVertical: (CHIP_HEIGHT / 15) / 2,
        },
        cicles_box: {
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',  // Centrar verticalmente
            justifyContent: 'center'  // Centrar horizontalmente
        },
        circle: {
            height: CHIP_HEIGHT / 1.3,
            width: CHIP_HEIGHT / 1.3,
            borderRadius: (CHIP_HEIGHT / 1.3) / 2,
            overflow: 'hidden',
            marginRight: -(CHIP_HEIGHT / 6)
        }
    });

    return <Pressable style={[styles.chip, props.style]} onPress={props.onPress} >

        <View style={styles.cicles_box}>

            {publishersLogos.map(icon => <View key={icon} style={styles.circle}><MaterialCommunityIcons name={icon} size={CHIP_HEIGHT / 1.5} color={CHIP_TEXT_COLOR} /></View>)}
            <TextNty style={{ marginLeft: CHIP_HEIGHT / 3, marginRight: publishersLogos?.length > 0 ? CHIP_HEIGHT / 2.5 : 0 }} color={CHIP_TEXT_COLOR}>Formato</TextNty>



        </View>

    </Pressable>


}

export default SearchFilters
