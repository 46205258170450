import { useAppTheme } from "src/core/theme/ThemeBuilder"
import { StyleSheet } from "react-native"
import { fixed_colors } from "src/core/theme/colors"
import { MaterialCommunityIcons } from "@expo/vector-icons"

export const useSearchChipStyles = () => {

    const theme = useAppTheme()

    const selectedBgColor = theme.colors.primary[500]
    const selectedColor = theme.colors.background[500]

    const styles = StyleSheet.create({
        labels: { color: theme.colors.text_primary[400], ...theme.fonts.default.semiBold, fontSize: 20 },
        selectedChip: { backgroundColor: selectedBgColor, marginRight: 10 },
        chip: { marginRight: 10 },
        chipText: { fontWeight: '500' },
        selectedChipText: { color: selectedColor, fontWeight: '500' },
    })

    return styles
}


export function ChipIcon(props: { icon: string, color?: string, size?: number }) {

    return <MaterialCommunityIcons name={props.icon} size={props.size ?? 20} color={props.color ?? fixed_colors.grey[500]} />
}
