import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { Platform, View } from 'react-native';
import { TouchableOpacity } from "react-native-gesture-handler";
import Logo from "../../components/Logo";
// import ArticleList from "../components/ArticleList";
import useSessionStore from "src/context/sessionStore";
import { useAppTheme } from "../../core/theme/ThemeBuilder";
import { getRandomColor } from "../../core/utils";
import { Navigation } from "../../types";
import Background from "../Background";
import FavouriteListsList from "../components/favourites/favouritelist/FavouriteListsList";
import ButtonNty from "../components/nty/button/ButtonNty";
import TitleHeaderNty from "../components/nty/header/TitleHeaderNty";
import TextNty from "../components/nty/text/TextNty";
import TitleNty from "../components/nty/title/TitleNty";
import { usePrompt, useRegisterModal } from "../context/alertContext";
import { useFavouritesStore } from "../context/favoritesStore";
import { useSnackBar } from "../context/snackBarStore";
import { FAB } from "react-native-paper";

type Props = {
  route: any;
  navigation: Navigation;
};
const FavoritesTab = ({ route, navigation }: Props) => {
  const { favouriteLists, init, createList } = useFavouritesStore(store => ({ favouriteLists: store.favouriteLists, init: store.init, createList: store.createList }));
  // const { user } = useAppSessionStore((store) => ({ user: store.user }));
  // const currentUser = user;

  const isGuest = useSessionStore(store => store.isGuest)

  // const theme = useAppTheme()

  const showRegisterModal = useRegisterModal(store => store.show)
  // const showInfoMessage = useSnackBar(store => store.showInfoMessage)
  const prompt = usePrompt((store) => store.prompt)

  const createNewFavList = () => {

    if (!isGuest) {
      prompt({
        title: 'Introduce un nombre',
        validations:
          [
            { function: (data) => favouriteLists?.find((list) => list.name == data) ? false : true, errorMessage: "El nombre ya existe en tus listas" },
            { regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' }
          ]
      })?.then((result) => {
        if (result && result.data)
          createList({ name: result.data, image: getRandomColor(), created: new Date().toISOString() })
      }).catch((reason) => console.log({ reason }))
    } else {
      showRegisterModal()
    }

  }



  return (

    (isGuest) ?

      <Background safeInsets style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Logo height={100} type={'normal'} />
        <TitleNty style={{ marginVertical: 40 }}>¿No has iniciado sesión?</TitleNty>
        <ButtonNty mode='contained' onPress={() => { navigation.navigate('guest-welcome') }}>Pulsa Aquí</ButtonNty>
      </Background>

      :
      <>
        <Background safeInsets>
          <TitleHeaderNty title="Mis Carpetas">
            {/* <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 20, paddingVertical: 5 }} onPress={createNewFavList}>
              <TextNty fontSize={16} type="thin" style={{ textDecorationLine: 'underline' }}>Nueva Carpeta</TextNty>
              <MaterialCommunityIcons name="plus" color={theme.colors.primary[400]} size={18} />
            </TouchableOpacity> */}
          </TitleHeaderNty>

          <View style={{ flex: Platform.OS == 'web' ? 1 : null }}>
            <FavouriteListsList
              onRefresh={() => init()}
              favLists={favouriteLists}
              loadMoreDisabled
              fixedItemHeight={300}
            />
          </View>
          <FAB
            style={{ position: 'absolute', bottom: 20, right: 20 }}
            icon={'plus'}
            onPress={createNewFavList}
          />
        </Background>
      </>

  );
}

export default FavoritesTab;
