import { ColorValue, Platform } from "react-native";
import { AppDataStoreType } from "../context/appDataStore";
import { AppSessionStoreType } from "../context/appSessionStore";
import { SessionUser } from "../models/user";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { AppTrialStoreType } from "../context/appTrialStore";


export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


export function capitalizeWords(string) {
    return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
}

/**
 * This method should only be used when the developer wants to detect the OS behind the web browser thats executing this app instance.
 * __SHOULD ONLY BE USED WHEN __ ´Platform.OS == 'web'´
 *
 * @returns an string indicating the userAgent for this specific web instance.
 */
export const getWebAgent: () => 'android' | 'ios' | 'windowsphone' | string = () => {

    if (Platform.OS != 'web') {
        return Platform.OS;
    }

    try {

        const userAgent = navigator.userAgent || navigator.vendor || navigator.platform;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "windowsphone";
        }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        //@ts-expect-error
        if (/iPad|iPhone|iPod|MacIntel/.test(userAgent) && !window.MSStream) {
            return "ios";
        }

        return userAgent;
    } catch (e) {
        return "unknown";
    }


}

export const isValidLink = (str: string) => {

    try {
        const url = new URL(str)
        return true
    } catch (e) {
        return false
    }

}






/*
  Apreciations for https://github.com/bryc, the real author of this code.

  Found myself another method of generating the hashcode with the `string.charCodeAt` function but i found in
  his response from stackoverflow that a more performant less-collisionant solution can be built.

  All the honnor is for him, as im just plain copying the code heer as i will only use it yet
  as a part of a prototype for my session_id generator.

  cyrb53 (c) 2018 bryc (github.com/bryc)
  A fast and simple hash function with decent collision resistance.
  Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
  Public domain. Attribution appreciated.
*/
export const hash_cyrb53 = function (str: string, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};


export const convertMsToTime: (miliseconds: number, h24Format?: boolean, returnObject?: boolean) => string | { secs: number, mins: number, hours: number } = (milliseconds, h24Format?: boolean, returnObject?: boolean) => {
    let secs = Math.floor(milliseconds / 1000);
    let mins = Math.floor(secs / 60);
    let hours = Math.floor(mins / 60);

    secs = secs % 60;
    mins = mins % 60;


    if (h24Format) {
        //  gets you `24:00:00` instead of `00:00:00`
        hours = hours % 24;
    }

    return returnObject ? { secs, mins, hours } : `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}


export function blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            const text = e.target?.result as string;
            resolve(text);
        });
        reader.addEventListener('error', (e) => {
            reject(e);
        });
        reader.readAsText(blob);
    });
}




export type NotFunction<T> = T extends Function ? never : T;


/**
 * Use this method to get a snapshot version of the current session being held in NTY.
 *
 * This is useful when you are outside of a functional component and you do not want to create a dependency chain with the session through all your method calls
 * just to do something with a specific session field value.
 *
 * **Do not use inside a functional component if you do not know what you are doing**
 *
 * @param filter Map function to be executed before returning the snapshot, this way you can convert session data to anything you'd like straight away.
 * @returns
 */
export const getAppSesionSnapshot: (filter?: (store: NotFunction<AppSessionStoreType>) => any | Partial<AppDataStoreType>) => Promise<Partial<AppSessionStoreType>> = async (filter?: (store: NotFunction<AppSessionStoreType>) => any | Partial<AppDataStoreType>) => {

    let sessionStoreData: { state: NotFunction<AppSessionStoreType> } = JSON.parse(await AsyncStorage.getItem("appSessionStore") ?? "false");

    if (sessionStoreData) {

        sessionStoreData.state.user = SessionUser.from(sessionStoreData.state.user);

        return filter ? filter(sessionStoreData.state) : sessionStoreData.state;

    } else {

        return null;
    }

}

/**
 * Use this method to get a snapshot version of the current trial being held in NTY.
 *
 * This is useful when you are outside of a functional component and you do not want to create a dependency chain with the trial store through all your method calls
 * just to do something with a specific trial field value.
 *
 * **Do not use inside a functional component if you do not know what you are doing**
 *
 * @param filter Map function to be executed before returning the snapshot, this way you can convert session data to anything you'd like straight away.
 * @returns
 */
export const getTrialSnapshot: (filter?: (store: NotFunction<AppTrialStoreType>) => any | Partial<AppTrialStoreType>) => Promise<Partial<AppTrialStoreType>> = async (filter?: (store: NotFunction<AppTrialStoreType>) => any | Partial<AppTrialStoreType>) => {

    let trialStore: { state: NotFunction<AppTrialStoreType> } = JSON.parse(await AsyncStorage.getItem("appTrialStore") ?? "false");

    if (trialStore) {

        return filter ? filter(trialStore.state) : trialStore.state;

    } else {

        return null;
    }

}

export const HEX_TRANSPARENCIES = {

    "100%": "FF",
    "99%": "FC",
    "98%": "FA",
    "97%": "F7",
    "96%": "F5",
    "95%": "F2",
    "94%": "F0",
    "93%": "ED",
    "92%": "EB",
    "91%": "E8",
    "90%": "E6",
    "89%": "E3",
    "88%": "E0",
    "87%": "DE",
    "86%": "DB",
    "85%": "D9",
    "84%": "D6",
    "83%": "D4",
    "82%": "D1",
    "81%": "CF",
    "80%": "CC",
    "79%": "C9",
    "78%": "C7",
    "77%": "C4",
    "76%": "C2",
    "75%": "BF",
    "74%": "BD",
    "73%": "BA",
    "72%": "B8",
    "71%": "B5",
    "70%": "B3",
    "69%": "B0",
    "68%": "AD",
    "67%": "AB",
    "66%": "A8",
    "65%": "A6",
    "64%": "A3",
    "63%": "A1",
    "62%": "9E",
    "61%": "9C",
    "60%": "99",
    "59%": "96",
    "58%": "94",
    "57%": "91",
    "56%": "8F",
    "55%": "8C",
    "54%": "8A",
    "53%": "87",
    "52%": "85",
    "51%": "82",
    "50%": "80",
    "49%": "7D",
    "48%": "7A",
    "47%": "78",
    "46%": "75",
    "45%": "73",
    "44%": "70",
    "43%": "6E",
    "42%": "6B",
    "41%": "69",
    "40%": "66",
    "39%": "63",
    "38%": "61",
    "37%": "5E",
    "36%": "5C",
    "35%": "59",
    "34%": "57",
    "33%": "54",
    "32%": "52",
    "31%": "4F",
    "30%": "4D",
    "29%": "4A",
    "28%": "47",
    "27%": "45",
    "26%": "42",
    "25%": "40",
    "24%": "3D",
    "23%": "3B",
    "22%": "38",
    "21%": "36",
    "20%": "33",
    "19%": "30",
    "18%": "2E",
    "17%": "2B",
    "16%": "29",
    "15%": "26",
    "14%": "24",
    "13%": "21",
    "12%": "1F",
    "11%": "1C",
    "10%": "1A",
    "9%": "17",
    "8%": "14",
    "7%": "12",
    "6%": "0F",
    "5%": "0D",
    "4%": "0A",
    "3%": "08",
    "2%": "05",
    "1%": "03",
    "0%": "00"
}

export function hexTransparency(hex_value: ColorValue | string, transparency: number) {

    let hex = typeof hex_value == 'string' ? hex_value : hex_value.toString()

    if (transparency > 100 || transparency < 0) {
        return hex_value
    }

    const key = transparency + '%'

    return hex + HEX_TRANSPARENCIES[key]

}


/**
 * Adjust the luminosity of a color.
 *
 * @param {string} hex - The hexadecimal color string.
 * @param {number} lum - The luminosity factor, e.g., -0.1 darkens the color by 10%, 0.2 lightens it by 20%.
 * @returns {string} - The adjusted color as a hexadecimal string.
 */
export function adjustLuminosity(hex, lum) {
    // Ensure the hex string has a hash prefix
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    let rgb = "#";
    for (let i = 0; i < 3; i++) {
        let c = parseInt(hex.substr(i * 2, 2), 16);
        let cs = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substring(cs.length);
    }

    return rgb;
}

import moment from 'moment-timezone';


export function formatDate(dateString) {
    if (!dateString) return dateString

    const now = moment().tz("Europe/Madrid");
    const date = moment(dateString).tz("Europe/Madrid");

    const diffDays = now.diff(date, 'days');

    if (Math.abs(diffDays) < 3) {
        if(date.isAfter(now)) {
            return date.to(date);
        } else {
            return date.from(now)
        }
    } else if (diffDays < 365) {
        return date.format('DD/MM');
    } else {
        return date.format('DD/MM/YYYY');
    }
}

export function formatArticleCardDate(publicationDate, creationDate) {

    const now = moment().tz("Europe/Madrid");
    const date = moment(publicationDate).tz("Europe/Madrid");

    const diffDays = now.diff(date, 'days');
    // const isFuture = now.isBefore(date)

    if (diffDays < 7) {
        return date.fromNow();
    } else if (diffDays < 0) {
        if (creationDate) {
            return formatArticleCardDate(creationDate, null)
        } else {
            return date.format('DD/MM/YYYY');
        }
    } else {
        return date.format('DD/MM/YYYY');
    }

}

export function fromStringToBase64(str: string) {
   return (typeof Buffer !== 'undefined')
        ?
        Buffer.from(str).toString('base64') // para Node.js
        :
        window.btoa(str);// para el navegador
}


export function formatNumber(value, { locale= 'es-ES', currency = false, decimals = false } = {}) {
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: decimals ? 2 : 0,
      maximumFractionDigits: decimals ? 2 : 0,
    };

    if (currency) {
      options.style = 'currency';
      options.currency = 'EUR'; // Puedes cambiar esto según la moneda que desees
    }

    return new Intl.NumberFormat('es-ES', options).format(value);
  }
