import { RouteProp, useRoute } from '@react-navigation/native';
import React, { memo } from "react";
import { View, useWindowDimensions } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import useSessionStore from 'src/context/sessionStore';
import { Article, Interest } from "systemDomain";
import ActionApi, { ACTION_NAMES } from '../../api/ActionApi';
import EnterpriseApi from '../../api/EnterpriseApi';
import { ScreensParamsList } from '../../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../../core/theme/ThemeBuilder';
import Background from "../Background";
import ArticleListNewsToYouLight from '../components/article/lists/ArticleListNewsToYouLight';
import TitleHeaderNty from '../components/nty/header/TitleHeaderNty';
import TextNty from '../components/nty/text/TextNty';
import ArticleList from '../components/article/lists/ArticleList';




const SearchEnterpriseFeedInterest = () => {

  const route = useRoute<RouteProp<ScreensParamsList, 'viewInterestArticles'>>();
  const [filteredArticles, setFilteredArticles] = React.useState<Article[]>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [feedId, setFeedId] = React.useState<string>(null);
  const [interest, setInterest] = React.useState<Interest>(null);
  const [interest_key, setInterestKey] = React.useState<string>(null);
  const { feeds, enterprise } = useSessionStore((store) => ({ feeds: store.feeds, enterprise: store.enterprise }))

  const feed = React.useMemo(() => feeds?.find((feed) => feed._id == feedId), [feedId])

  const readFilteredArticles = async () => {
    setLoading(true);
    try {
      const interest = await EnterpriseApi.getInterestArticles(feedId, interest_key, 0, 10)
      setFilteredArticles(interest.articles);
      setInterest(interest)
      ActionApi.createFrom({ name: ACTION_NAMES.ENTERPRISE_FEED_FETCH, data: { enterprise: enterprise.key, feed: feed.name, feedId: feedId } })
    } catch (e) {
      console.error('Error: ', e);
    } finally {
      setLoading(false);
    }
  }

  const onLoadMore = async () => {

    var itemsToSkip: number = filteredArticles ? filteredArticles.length : 0;
    try {
      setLoading(true)
      const articles = (await EnterpriseApi.getInterestArticles(feed._id, interest_key, itemsToSkip, 10)).articles
      // console.log('got '+ articles.length + ' articles')
      if (articles && filteredArticles && articles.length > 0 && articles[articles.length - 1]._id != filteredArticles[filteredArticles.length - 1]._id) {
        setFilteredArticles(filteredArticles.concat(articles));
      } else {
        setNoMore(true)
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false)
    }
  }

  const onRefresh = async () => {
    ActionApi.createFrom({ name: ACTION_NAMES.ENTERPRISE_FEED_REFRESH, data: { enterprise: enterprise.props.key, feed: feed.name } })
    await init()
  }

  const init = async () => {
    await readFilteredArticles();
  }

  React.useEffect(() => {
    if (feedId)
      init().then()
  }, [feedId, interest_key])

  React.useEffect(() => {
    const feed_id: string = route?.params?.feed_id;
    const interest_key: string = route?.params?.interest_key;
    setFeedId(feed_id);
    setInterestKey(interest_key);
  }, [route])

  const { height } = useWindowDimensions();
  const theme = useAppTheme()
  const [noMore, setNoMore] = React.useState<boolean>(false);

  return (

    <Background style={{ height: height }} safeInsets>

      {loading && <ActivityIndicator color={theme.colors.primary[500]} size={50} style={{ position: 'absolute', alignSelf: 'center', top: "45%", zIndex: 1000 }} />}

      <View style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>

        <TitleHeaderNty
          title={interest ? feed?.name + ' - ' + interest?.name : ''}
          titleStyle={{ fontSize: 20 }}
        />


        <View style={{ flex: 10 }}>

          {filteredArticles?.length == 0 && !loading
            ?
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <TextNty color={theme.colors.text_primary[500]}>No se han encontrado resultados...</TextNty>
              {/* <TextNty type='extraLight' fontSize={15} color={theme.colors.text_primary.muted} style={{ marginTop: 10 }}>Prueba a cambiar los parámetros de tu búsqueda.</TextNty> */}
            </View>
            :
            filteredArticles && <ArticleList
              // showMeasuresInfo={true}
              loadMoreDisabled={noMore}
              onLoadMore={onLoadMore}
              data={filteredArticles}
              onRefresh={onRefresh}
              contentContainerStyle={{ paddingBottom: 50, paddingTop: 15 }} />}

        </View>
      </View>
    </Background>
  );
}

export default memo(SearchEnterpriseFeedInterest);

