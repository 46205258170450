import React from 'react'
import { FlatListProps, ListRenderItemInfo, View } from 'react-native'
import ArticleCard from '../../../cards/ArticleCard'
import { Article, ArticleInterface } from 'systemDomain'
import { ArticleListHorizontalProps } from '..'
import { ArticleListProps } from '../../ArticleList'


export const useHorizontalFlatListProps = (ITEM_HEIGHT: number, ITEM_WIDTH: number, ITEM_SEPARATOR_WIDTH: number, props: ArticleListHorizontalProps | ArticleListProps) => {

    const itemStyle = {
        width: ITEM_WIDTH,
        height: ITEM_HEIGHT
    }

    const paginationProps: Partial<FlatListProps<Article>> = {
        snapToInterval: (ITEM_WIDTH + ITEM_SEPARATOR_WIDTH) * props.paginateEvery,
        decelerationRate: 'fast'
    }

    const getItemLayoutInternal = React.useCallback((data, index) => {

        return (
            {
                length: ITEM_HEIGHT,
                offset: (ITEM_WIDTH + ITEM_SEPARATOR_WIDTH) * (index),
                index
            }
        )

    }, [ITEM_HEIGHT, ITEM_SEPARATOR_WIDTH])

    const renderItemInternal: (info: ListRenderItemInfo<Article>) => React.ReactElement<any, string | React.JSXElementConstructor<any>> =
        (info: ListRenderItemInfo<Article>) => {


            return (
                <View style={itemStyle} key={info?.item?._id}>
                    <ArticleCard
                        article={info.item}
                        {...props.articleCardProps}
                    />
                </View>

            );

        }
    return { renderItemInternal, getItemLayoutInternal, paginationProps }

}
