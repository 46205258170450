import moment from 'moment';
import React, { memo } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Chip, IconButton, Switch } from "react-native-paper";
import { HighlightInterface, HighlightModel } from 'systemDomain';
import DatePickerNty from '../../components/DatePickerNty';
import { useAppTheme } from '../../core/theme/ThemeBuilder';
import { useAlert } from '../context/alertContext';

import ArticleCard from './article/cards/ArticleCard';
import NtyTextInputRound from './nty/TextInput/TextInputNtyRound';
import ButtonNty from './nty/button/ButtonNty';
import TextNty from './nty/text/TextNty';
import TitleNty from './nty/title/TitleNty';

type Props = {
    initialhighlightData?: Partial<HighlightInterface>;
    onSubmit: any;
    buttonText: string;
};


const HighlightFormAdvanced = ({ initialhighlightData, onSubmit, buttonText }: Props) => {

    const [highlightData, setHighlightData] = React.useState({});
    const [article, setArticle] = React.useState()
    const showAlert = useAlert((store) => store.alert)
    const theme = useAppTheme()


    const _onSubmit = () => {
        const copy: any = { ...highlightData };
        if (article) {
            copy.article = article;
        }
        if (schedule) {
            if (!copy.from && !copy.to) {
                // alert.current.alert({ title: 'Cuidado', message: 'Has marcado el destacado como programado sin poner fechas.Inroduce valores o desmárcalo para continuar' })
                showAlert({ title: 'Cuidado', message: 'Has marcado el destacado como programado sin poner fechas. Inroduce valores o desmárcalo para continuar', dismissable: false })
                return;
            }
            onSubmit(copy);
        } else {
            delete copy.from;
            delete copy.to;
            onSubmit(copy)
        }
    }

    const stringkeys = HighlightModel.keys().filter(e => ['article', 'from', 'to', 'created', 'email', 'visible', 'enterprise', 'feedId'].indexOf(e) == -1)
    const [schedule, setSchedule] = React.useState(false);

    const setData = (data: any, key: string) => {
        const type = typeof data;
        switch (type) {
            case 'undefined': return;
            case 'string': setValueToKey(getValueFromString(data), key);
        }
    }

    const getValueFromString = (text: string) => {
        const number = Number.parseInt(text);
        if (!Number.isNaN(number)) {
            return number;
        }
        if (text == 'true' || text == 'false') {
            return text == 'true';
        }
        return text;
    }

    const updateHighlightDateValue = (tobeDate: Date, mode: string, updateKey: string) => {
        const isoString = highlightData[updateKey] ?? null;
        const d = isoString ? new Date(isoString) : new Date();

        if (mode == 'date' || mode == 'both') {
            d.setDate(tobeDate.getDate())
            d.setMonth(tobeDate.getMonth())
            d.setFullYear(tobeDate.getFullYear())
        }
        if (mode == 'time' || mode == 'both') {
            d.setHours(tobeDate.getHours())
            d.setMinutes(tobeDate.getMinutes())
            d.setSeconds(0)
        }
        const newData = { ...highlightData }
        newData[updateKey] = d.toISOString();
        setHighlightData(newData)
    }


    const setValueToKey = (val: any, key: string) => {
        const newHighlightData: any = {};
        newHighlightData[key] = val;
        setHighlightData({ ...highlightData, ...newHighlightData });
    }

    const getPlaceHolder = (key: string) => {
        switch (key) {
            case 'picture': return "Imagen de Fondo (URL)"
            case 'slideshow': return "Título personalizado"
            case 'url': return "Link para el Destacado"
            case 'order': return "Puntuación para posicionar el destacado (de mayor a menor)"
            default: return key
        }
    }

    React.useEffect(() => {

        if (initialhighlightData && initialhighlightData.article) {
            setArticle(initialhighlightData.article)
        }

    }, [])

    return (
        <>
            <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingBottom: 65 }}>

                {article &&
                    <View style={{ flexDirection: 'column', alignContent: 'flex-start', width: "100%", marginBottom: 30, padding: 10 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <TitleNty>
                                Noticia:
                            </TitleNty>
                            <IconButton icon={'delete'} onPress={() => { setArticle(null) }} />
                        </View>

                        <ArticleCard horizontal={true} article={article} cardStyle={{ height: 160 }} />
                    </View>
                }

                <View style={{ flexDirection: 'column', alignContent: 'flex-start', width: "100%", marginTop: 20 }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, padding: 15, borderWidth: 1, borderRadius: 20, borderColor: theme.colors.secondary[400] }}>
                        <TitleNty>
                            Programación:
                        </TitleNty>
                        <Switch value={schedule} onValueChange={() => setSchedule(!schedule)} />
                    </View>

                    {schedule &&
                        <ScrollView horizontal={true} contentContainerStyle={{ justifyContent: 'center', flexDirection: 'row', paddingVertical: 10, paddingHorizontal: 5 }}>
                            <View style={{ ...theme.shadows.box.small, borderRadius: theme.dimensions.roundness.low, padding: 15, backgroundColor: theme.colors.background[400], marginRight: 30 }}>
                                <TitleNty fontSize={18} style={{ marginBottom: 20 }}>
                                    Desde:
                                </TitleNty>
                                {highlightData.from ?
                                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 3 }}>

                                        <Chip icon={'calendar'} style={{ paddingVertical: 5, marginRight: 10 }}>
                                            {highlightData.from ? moment(highlightData.from).locale('es').format('DD MMM') : ''}
                                        </Chip>
                                        <Chip icon={'clock'}>
                                            {highlightData.from ? moment(highlightData.from).locale('es').format('HH:mm[h]') : ''}
                                        </Chip>


                                    </View>
                                    : <TextNty style={{ marginVertical: 15 }}>Sin seleccionar</TextNty>}

                                <DatePickerNty style={{ marginBottom: 10 }} mode='both' onValueChange={(date: Date, mode) => {

                                    updateHighlightDateValue(date, mode, 'from');
                                }} />

                            </View>

                            <View style={{ ...theme.shadows.box.small, borderRadius: theme.dimensions.roundness.low, padding: 15, backgroundColor: theme.colors.background[400], marginRight: 30 }}>
                                <TitleNty fontSize={18} style={{ marginBottom: 20 }}>
                                    Hasta:
                                </TitleNty>
                                {/* <NtyTextInputRound
                                        placeholder="Hasta"
                                        returnKeyType="next"
                                        autoCapitalize="none"
                                        editable={false}
                                        value={highlightData.to ? new Date(highlightData.to).toISOString() : ''}
                                        icon='calendar-arrow-left'
                                    /> */}
                                {highlightData.to ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 3 }}>

                                    <Chip icon={'calendar'} style={{ paddingVertical: 5, marginRight: 10 }}>
                                        {highlightData.to ? moment(highlightData.to).locale('es').format('DD MMM') : ''}
                                    </Chip>
                                    <Chip icon={'clock'}>
                                        {highlightData.to ? moment(highlightData.to).locale('es').format('HH:mm[h]') : ''}
                                    </Chip>


                                </View>
                                    : <TextNty style={{ marginVertical: 15 }}>Sin seleccionar</TextNty>}
                                <DatePickerNty style={{ marginBottom: 10, alignSelf: 'flex-end' }} mode='both' onValueChange={(date: Date, mode) => {

                                    updateHighlightDateValue(date, mode, 'to');
                                }} />
                            </View>
                        </ScrollView>
                    }
                </View>

                {
                    stringkeys.map((key: string) =>
                        <NtyTextInputRound
                            key={key}
                            placeholder={getPlaceHolder(key)}
                            autoCapitalize="none"
                            selectTextOnFocus={false}
                            style={{ fontSize: 15, marginVertical: 3 }}
                            // value={highlightData[key]}
                            onChangeText={(text: string) => {
                                setData(text, key)
                            }}

                        />
                    )
                }

            </ScrollView>
            <ButtonNty onPress={_onSubmit} mode='floatingBottom' mergeStyles={{ marginBottom: 20 }}>
                {buttonText}
            </ButtonNty>

        </>
    )

};


export default memo(HighlightFormAdvanced);
