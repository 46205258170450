import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { KeyboardAvoidingView, useWindowDimensions } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Article, HighlightInterface } from 'systemDomain';
import ActionApi, { ACTION_NAMES } from '../../api/ActionApi';
import ArticleApi from '../../api/ArticleApi';
import HighlightApi from '../../api/HighlightApi';
import Background from '../../app/Background';
import HighlightFormAdvanced from '../../app/components/HighlightFormAdvanced';
import TitleHeaderNty from '../../app/components/nty/header/TitleHeaderNty';
import { useAlert } from '../../app/context/alertContext';
import { useAppTheme } from '../../core/theme/ThemeBuilder';
import { getArticleReport } from '../../core/utils';


function CreateFromPage() {

  const route = useRoute();
  const navigation = useNavigation();

  const id = route.params?.id;
  const enterprise = route.params?.enterprise
  const feedId = route.params?.feedId
  const feedName = route.params?.feedName
  const theme = useAppTheme()
  const [loadingArticle, setLoadingArticle] = React.useState(false);

  const [article, setArticle] = React.useState<Article>(null);

  const readArticle = async () => {
    setLoadingArticle(true);
    let apiArticle: Article;
    try {
      apiArticle = await ArticleApi.read(id);
    }
    catch (e) {
      console.error('Error: ', e)
      setLoadingArticle(false);
    }
    if (apiArticle) {
      setArticle(apiArticle);
    }
    setLoadingArticle(false);
  }

  const onSubmit = async (highlight: HighlightInterface) => {

    if (enterprise && enterprise != '') {

      if (feedId) {
        highlight.feedId = feedId
      }
      highlight.enterprise = enterprise?.key;

    }

    if (article && id && id != 'empty' && id != '') {
      const h = await HighlightApi.createFrom(highlight, article._id);
      if (h.getId()) {
        ActionApi.createFrom({ name: ACTION_NAMES.CREATE_HIGHLIGHT, data: { title: h.props.slideshow ?? '', url: h.props.url, ...getArticleReport(h.props.article) } })
        alert({ title: '¡Creado Correctamente!', message: 'Se ha creado el destacado, puedes ir a verlo a la sección de destacados del menú lateral.', buttons: [{ label: 'Entendido', cancel: true, onPress: () => { navigation.navigate('home') } }] })
      }
    } else if (id && id != 'empty' && id != '' && article == null) {
      alert({ title: 'Hubo un problema', message: 'No podemos crear el destacado a partir del artículo: "' + id + '". No lo hemos encontrado...' })
    } else if (!id || id == 'empty' || id == '') {
      delete highlight.article;
      const h = await HighlightApi.create(highlight);
      if (h?._id) {
        ActionApi.createFrom({ name: ACTION_NAMES.CREATE_HIGHLIGHT, data: { title: h.props.slideshow ?? '', url: h.props.url } })
        alert({ title: '¡Creado Correctamente!', message: 'Se ha creado el destacado, puedes ir a verlo a la sección de destacados del menú lateral.', buttons: [{ label: 'Entendido', cancel: true, onPress: () => { navigation.navigate('home') } }] })
      }
    }
  }

  const alert = useAlert((store) => store.alert)
  const { height } = useWindowDimensions()

  React.useEffect(() => {
    if (id && id != 'empty' && id != '')
      readArticle();
  }, [])

  const title = enterprise && feedId && feedName ? 'Destacado para: ' + enterprise?.name + " (" + feedName + ")" : 'Crear Destacado:'

  return (
    <Background safeInsets dismissKeyboardOnUnhandledTouches={false} style={{ height }} >
      <TitleHeaderNty title={title} />
      <KeyboardAvoidingView style={{ paddingHorizontal: 30, flex: 1 }} behavior={'padding'}>
        {loadingArticle ? <ActivityIndicator size={50} color={theme.colors.primary[500]} /> :
          <HighlightFormAdvanced initialhighlightData={{ article: article }} buttonText={'Crear Destacado'} onSubmit={onSubmit} />}
      </KeyboardAvoidingView>
      {/* <NtyAlert ref={alert} /> */}
    </Background>
  )
}

export default CreateFromPage
