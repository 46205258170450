import React from 'react'
import InfoMesageSnack from './snackMessages/InfoMessageSnack'

function ContextSnacksLoader() {



    return (
        <>
            <InfoMesageSnack />
        </>
    )
}

export default ContextSnacksLoader
