import AsyncStorage from '@react-native-async-storage/async-storage'
import { useScrollToTop } from '@react-navigation/native'
import React from 'react'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { HomeDefinition } from 'systemDomain'
import HomeApi from '../../../../api/HomeAPI'
import { fixed_colors } from '../../../../core/theme/colors'
import ScrollHeaderScreen from '../../common-ui/ScrollHeaderScreen'
import LoadingIndicator from '../../loaders/Loading'
import WelcomeHeaderNew from '../WelcomeHeaderNew'
import EnterpriseHomeSections from '../components/EnterpriseHomeSections'





function HomeEnterprise() {

    // const [loading, setLoading] = React.useState(true)


    const scrollRef = React.useRef<ScrollView>()

    const [homeDefinition, setHomeDefinition] = React.useState<HomeDefinition>()
    const [isLoading, setIsLoading] = React.useState(false)

    const loadHome = async (forceRefresh: boolean = false) => {
        setIsLoading(true)
        let savedHomeResult: string
        try {
            if (!forceRefresh) {

                savedHomeResult = await AsyncStorage.getItem('HomeEnterprise_homeDefinition')

                if (savedHomeResult) {
                    console.log('Got saved home definition')
                    if (!homeDefinition) {
                        console.log('Using saved home definition')
                        const home = JSON.parse(savedHomeResult)
                        setHomeDefinition(home)
                        setIsLoading(false)
                    }
                } else {
                    console.log('No copy of home in asyncStorage')
                }
            }
        } catch (e) {
            console.error('Error loading home from asyncStorage: ', e)
        }

        console.log('Fetching home definition')
        const home = await HomeApi.getHome()
        if (forceRefresh) {
            setHomeDefinition(home)
            setIsLoading(false)
        } else if (savedHomeResult != JSON.stringify(home)) {
            console.log(savedHomeResult?.length, JSON.stringify(home)?.length)
            console.log('Got Different home version from database!')
            AsyncStorage.setItem('HomeEnterprise_homeDefinition', JSON.stringify(home))
            setHomeDefinition(home)
            setIsLoading(false)
        }

    }

    const scrollToTopPage = async () => {
        scrollRef.current.scrollTo({ animated: true, y: 0 });
    }

    const onTabIconClickedBehaviour = React.useRef({
        scrollToTop: scrollToTopPage
    })

    useScrollToTop(onTabIconClickedBehaviour);

    React.useEffect(() => {
        loadHome()
    }, [])


    return (
        <>
            {isLoading &&
                <View style={{ marginVertical: 10, position: 'absolute', zIndex: 1000, top: 80, width: "100%", justifyContent: 'center' }}>
                    <LoadingIndicator style={{zIndex: 1100}} size={30}/>
                </View>
            }
            <ScrollHeaderScreen
                ref={scrollRef}
                headerHeight={120}
                headerBgColor={'transparent'}
                header={
                    <WelcomeHeaderNew iconsColor={fixed_colors.black[500]} />
                    // <View/>
                }
                onRefresh={() => { loadHome(true) }}
            >
                {/* {!homeDefinition ? <LoadingIndicator style={{ marginTop: 20 }} /> : <EnterpriseHomeSections homeDefinition={homeDefinition} />} */}
                <EnterpriseHomeSections homeDefinition={homeDefinition} />
            </ScrollHeaderScreen>
        </>
    )
}

export default HomeEnterprise
