import { RouteProp, useRoute } from '@react-navigation/native'
import * as Haptics from 'expo-haptics'
import React, { useState } from 'react'
import { GestureResponderEvent, Platform, StyleProp, ViewStyle } from 'react-native'
import { IconButton } from 'react-native-paper'
import useSessionStore from 'src/context/sessionStore'
import { buildFavourite } from 'src/core/favourites/FavouritesUtils'
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens'
import { Article } from 'systemDomain'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'
import { useRegisterModal } from '../../../context/alertContext'
import { useFavouritesStore } from '../../../context/favoritesStore'
import { useFavouriteListSelectorStore } from '../../../context/optionStores'
import { useSnackBar } from '../../../context/snackBarStore'

export type ArticleFavouriteToggleProps = {


    article: Article
    color?: string
    size?: number
    rippleColor?: string
    style?: StyleProp<ViewStyle>,
    disabled?: boolean,
    /**
     * Use this method in case you want to add a callback for the toggleFavourite function
     */
    onPress?: (article: Article) => void,
    onLongPress?: (article: Article) => void

}

export default function ArticleFavouriteToggle({ article, size, disabled = false, color, style, rippleColor, onPress = null, onLongPress = null }: ArticleFavouriteToggleProps) {

    const theme = useAppTheme()
    const { favourites, toggleFavourite } = useFavouritesStore((store) => ({ favourites: store.favourites, toggleFavourite: store.toggleFavourite }))
    const defaultList = useFavouritesStore((store) => store.defaultList)
    const [isFav, setIsFav] = useState<boolean>(favourites?.map((fav) => fav.articleId).includes(article._id));

    const isGuest = useSessionStore(store => store.isGuest)

    const showRegisterModal = useRegisterModal(store => store.show)
    const showInfoMessage = useSnackBar(store => store.showInfoMessage)
    const openFavouriteListSelector = useFavouriteListSelectorStore(store => store.open)

    const _onSavePress = () => {

        if (onPress) {
            onPress(article)
        }

        if (useSessionStore.getState().isGuest) {
            showRegisterModal()
            return;
        }
        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
        realFavToggle(article)
    }

    const [changed, setChanged] = React.useState(new Date().toISOString())

    const route = useRoute<RouteProp<ScreensParamsList, 'viewFavouriteList'>>()

    const realFavToggle = async (article: Article) => {

        if (defaultList) {

            let favourite

            if (route.name == 'viewFavouriteList') {
                favourite = favourites?.find(fav => article?._id == fav.articleId && fav.list == route.params.id)
            } else {
                favourite = favourites?.find(fav => article?._id == fav.articleId)
            }

            favourite = favourite ? favourite : buildFavourite(article, defaultList)
            // console.log('Toggling Article Fav', { article: article._id, fav: favourite._id, favArticle: favourite.article._id, })
            const result = await toggleFavourite(favourite)
            if (result && result.success) {
                if (result.added) {
                    showInfoMessage(`Añadido a '${defaultList?.name ?? 'favoritos'}'.`);
                } else {
                    const list = result.data?.list?.name ?? 'favoritos'
                    showInfoMessage(`Eliminado de '${list}'.`);
                }
            }
            setChanged(new Date().toISOString())
        } else {
            _onSaveLongPress(undefined)
        }
    }

    const _onSaveLongPress = (event: GestureResponderEvent) => {
        if (onLongPress) {
            onLongPress(article)
        }
        Platform.OS != 'web' ? Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light) : null;
        if (isGuest) {
            showRegisterModal()
        } else {
            openFavouriteListSelector({ article })?.then(() => {
                setChanged(new Date().toISOString())
            })
        }

    }

    // const isFavH = React.useMemo(() => {
    //     let fav = favourites?.find(fav => article?.props?._id == fav.articleId)
    //     // console.log('Recalculating', { articleId: article.props._id, fav })
    //     return fav ? true : false
    // }, [article, changed, favourites?.length])

    React.useEffect(() => {
        let fav = favourites?.find(fav => article?._id == fav.articleId)
        // console.log('Recalculating', { articleId: article.props._id, fav })
        setIsFav(fav ? true : false)
    }, [article, changed, favourites?.length])


    return (
        <IconButton
            disabled={disabled}
            size={size ?? 40}
            rippleColor={rippleColor ?? color ?? theme.colors.text_primary[500]}
            icon={(isFav) ? 'bookmark' : 'bookmark-outline'}
            color={color ?? theme.colors.text_primary[500]}
            style={style}
            onPress={_onSavePress}
            onLongPress={_onSaveLongPress}
        />
    )
}
