import React from 'react';
import { Animated, ColorValue, StyleSheet, TextProps } from 'react-native';
// import { Text } from 'react-native-paper';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';



export default function TextNty(props: TextProps & {
    color?: ColorValue,
    type?: 'regular' | 'medium' | 'bold' | 'light' | 'thin' | 'black' | 'semiBold' | 'extraBold' | 'extraLight',
    fontSize?: number
}) {

    const theme = useAppTheme();

    const fontType = props.type ?? 'regular';
    const fontSize = props.fontSize ?? 14

    const style = StyleSheet.create({
        title: {
            fontSize: fontSize,
            lineHeight: fontSize + 3,
            color: props.color ?? theme.colors.text_primary[500]
        }
    })
    return (
        <Animated.Text {...props} style={[style.title, theme.fonts.default[fontType], props.style]}>{props.children}</Animated.Text>
    )
}

