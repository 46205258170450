import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { memo } from "react";
import { Clipboard, GestureResponderEvent, Platform, StyleSheet, View } from "react-native";
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Divider } from 'react-native-paper';
import { Article, HighlightModel } from 'systemDomain';
import ActionApi, { ACTION_NAMES } from '../../../../api/ActionApi';
import HighlightApi from '../../../../api/HighlightApi';

import { useAppTheme } from '../../../../core/theme/ThemeBuilder';

import { useFavouritesStore } from 'src/app/context/favoritesStore';
import { useSnackBar } from 'src/app/context/snackBarStore';
import useSessionStore from 'src/context/sessionStore';
import { buildFavouriteFromHighlight } from '../../../../core/favourites/FavouritesUtils';
import { getArticleReport, openInBrowser, shareArticle, shareURL } from '../../../../core/utils';
import { useAlert } from '../../../context/alertContext';
import { useArticleOptionsStore, useFavouriteListSelectorStore, useHighlightOptionsStore } from '../../../context/optionStores';
import NtyTextInputRound from '../../nty/TextInput/TextInputNtyRound';
import ButtonNty from '../../nty/button/ButtonNty';
import TextNty from '../../nty/text/TextNty';




export type HighlightOptionsProps = {
    /**
     * Optional.
     *
     * Use this parameter to make this instance of ArticleOptionsList work for a specific article object.
     * If not passed, the article in {@link useArticleOptionsStore} would be used.
     */
    highlight?: HighlightModel;
    hide?: () => void,
    show?: () => void,
    showFollowAuthor?: boolean;
    showFollowNewspaper?: boolean;
    showAddToFavourites?: boolean;
}

/**
 *
 * This component is used to show common actions associated with an article.
 *
 * - Subscribe to newspaper
 * - Subscribe to author
 * - Save Newspaper
 * - Share article
 * - Open in external browser (only for devices)
 *
 */
const HighlightOptionsList = (props: HighlightOptionsProps) => {

    const theme = useAppTheme()

    const { open: openArticleOptions } = useArticleOptionsStore.getState()

    const alert = useAlert.getState().alert

    // const { session, profile, user, isEnterprise, enterprise } = useAppSessionStore();
    const {profile, enterprise}= useSessionStore( store => ({profile: store.profile, enterprise: store.enterprise}))
    // const currentUser = user;
    const highlight = props.highlight ? props.highlight : useHighlightOptionsStore((store) => store.highlight);
    const article = highlight?.props?.article as Partial<Article>

    const [order, setOrder] = React.useState<number>();


    const onSharePressed = async (event: GestureResponderEvent) => {

        if (article) {
            shareArticle(article);
        } else if (highlight.props.url) {
            const url = highlight.props.url;
            shareURL(url, event, profile)
        }
        props.hide ? Platform.OS != 'ios' ? props.hide() : null : null;
    }

    const _openBrowser = async () => {

        let url;

        if (highlight.props.url) {
            url = highlight.props.url;
        } else if (article) {
            url = article.url;
        }
        const opened = await openInBrowser(url);

        if (!opened) {
            Clipboard.setString(url);
        } else {
            HighlightApi.viewHighlightAction(highlight.props)
        }
        props.hide ? props.hide() : null;


    }

    const deleteHighlight = async () => {

        alert({ title: 'Borrando Destacado', message: 'Vas a borrar el destacado, con esto no borrarás su información, pero ya no aparecerá en los listados.' }).then(async (response) => {
            if (response) {
                try {
                    const deleted = await HighlightApi.delete(highlight.getId());
                    ActionApi.createFrom({ name: ACTION_NAMES.DELETE_HIGHLIGHT, data: { title: highlight.props.slideshow, url: highlight.props.url, ...getArticleReport(highlight.props.article) } })
                    alert({ title: '¡Completado!', message: 'El destacado se ha invisibilizado, refresca el listado para comprobarlo.' })
                } catch (e) {
                    alert({ title: '¡Oh oh!', message: 'Hubo un problema invisibilizando el destacado, pregunte a un administrador.' })
                }
            }
        })

    }

    const orderHighlight = async () => {

        try {

            const response = await HighlightApi.update({ ...highlight.props, ...{ order: order } })
            alert({ title: '¡Completado!', message: 'El nuevo orden del destacado es: ' + order })

        } catch (e) {
            console.error(e);
            alert({ title: '¡Oh oh!', message: 'Hubo un problema cambiando el orden del destacado, pregunte a un administrador.' })

        }
        props.hide ? props.hide() : null;
    }

    const onOrderChange = (e) => {
        if (e.nativeEvent && e.nativeEvent.text != '') {
            const n = Number.parseInt(e.nativeEvent.text);
            console.log(n);
            !Number.isNaN(n) ? setOrder(n) : alert({ title: 'Formato incorrecto', message: 'Introduce un número entero.' })
        }
    }

    const _toggleFavourite = async () => {
        const favourite = buildFavouriteFromHighlight(highlight.props)
        useFavouriteListSelectorStore.getState().open().then(list => {
            if(list) {
                console.log('Selected: ' + list.name)
                favourite.list = list._id
                useFavouritesStore.getState().toggleFavourite(favourite).then((res) => {
                    if(res && res.success) {
                        if(res.added) {
                            useSnackBar.getState().showInfoMessage('Destacado añadido a la lista: '+list.name)
                        } else {
                            useSnackBar.getState().showInfoMessage('Destacado eliminado de la lista: '+list.name)

                        }
                    } else {
                        useSnackBar.getState().showInfoMessage('Ha habido un error, inténtalo más tarde')

                    }
                })
            }

            props.hide()
        })
    }
0
    return (
        <View style={{ flex: 1, paddingHorizontal: 40, paddingVertical: 10 }}>

            <TouchableOpacity style={styles.itemContainer} onPress={onSharePressed}>
                <MaterialCommunityIcons
                    name="share"
                    color={theme.colors.text_primary[500]}
                    size={25}
                />
                <TextNty style={styles.text}>Compartir destacado</TextNty>
            </TouchableOpacity >

            <Divider style={styles.divider} />

            <TouchableOpacity style={styles.itemContainer} onPress={_toggleFavourite}>
                <MaterialCommunityIcons
                    name="bookmark-plus"
                    color={theme.colors.text_primary[500]}
                    size={25}
                />
                <TextNty style={styles.text}>Enviar a una lista</TextNty>
            </TouchableOpacity >

            <Divider style={styles.divider} />

            <TouchableOpacity style={styles.itemContainer} onPress={_openBrowser}>
                <MaterialCommunityIcons
                    name="web"
                    color={theme.colors.text_primary[500]}
                    size={25}
                />
                <TextNty style={styles.text}>{Platform.OS == 'web' ? 'Abrir en otra pestaña' : 'Ver en el navegador'}</TextNty>
            </TouchableOpacity >

            {
                (profile?.superUser) || (enterprise && enterprise.admins?.indexOf(profile.email) != -1) ?
                    <>
                        <Divider style={styles.divider} />
                        <TouchableOpacity style={styles.itemContainer} onPress={deleteHighlight}>
                            <MaterialCommunityIcons
                                name="delete"
                                color={theme.colors.text_primary[500]}
                                size={25}
                            />
                            <TextNty style={styles.text}>Eliminar destacado</TextNty>
                        </TouchableOpacity>
                    </> : null
            }

            {
                profile?.superUser ?
                    <>
                        <Divider style={styles.divider} />
                        <View style={[styles.itemContainer, { flexDirection: 'column' }]} >
                            <TextNty style={{ alignSelf: 'flex-start', marginBottom: 15 }} >Ordernar destacado</TextNty>
                            <NtyTextInputRound placeholder='Orden' defaultValue={highlight?.props?.order + ''} onChange={onOrderChange} />
                            <ButtonNty onPress={orderHighlight}>Ordenar</ButtonNty>
                        </View>
                    </> : null
            }

            {article && article._id &&
                <>
                    <Divider style={styles.divider} />
                    <TouchableOpacity style={styles.itemContainer} onPress={() => { openArticleOptions(article); props.hide ? props.hide() : null }}>

                        <MaterialCommunityIcons
                            name="newspaper-variant"
                            color={theme.colors.text_primary[500]}
                            size={25}
                        />

                        <TextNty style={styles.text}>Opciones del Artículo.</TextNty>

                    </TouchableOpacity >
                </>
            }

        </View>
    );
}

const styles = StyleSheet.create({
    text: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
        marginLeft: 25
    },
    itemContainer: {
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center'
    },
    divider: {
        marginBottom: 20
    }
})

export default memo(HighlightOptionsList);
