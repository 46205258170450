import AsyncStorage from '@react-native-async-storage/async-storage';
import produce from "immer";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { themes } from "../core/theme/ThemeBuilder";



const theme = themes.default;



export type TextStylesStore = {

    fontFamily: string,
    fontSize: number,
    lineHeight: number,
    textAlign: "auto" | "left" | "right" | "center" | "justify",
    fontWeight: "normal" | "bold" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900",
    style: string,
    textTheme: TextThemeColor,
    incrementFontSize: Function,
    decrementFontSize: Function,
    toggleBold: Function,
    setLineHeight: Function,
    setTextAlign: Function,
    setFontWeight: Function,
    setFontFamily: Function,
    setStyle: Function,
    changeThemeColor: Function,
    decrementLineHeight: Function,
    incrementLineHeight: Function,
    version?: number

}

export class TextThemeColor {

    public static readonly default = new TextThemeColor('Default', theme.colors.text_primary[500], theme.colors.background[300]);
    public static readonly newspaper = new TextThemeColor('Newspaper', '#515151', '#d7d7d7');
    public static readonly newspaperSepia = new TextThemeColor('Newspaper Sepia', '#5a584b', '#f9f6e5');
    public static readonly newspaperDark = new TextThemeColor('Newspaper Dark', '#fafafa', '#282828');


    private constructor(public readonly name: string, public readonly textColor: string, public readonly bgColor: string) { }

    public static getTheme(name: string) {
        switch (name) {
            case this.default.name: return this.default;
            case this.newspaper.name: return this.newspaper;
            case this.newspaperSepia.name: return this.newspaperSepia;
            case this.newspaperDark.name: return this.newspaperDark;
            default: return null;
        }
    }
}

export interface TextStylesStoreDataI {

    fontFamily: string;
    fontSize: number,
    lineHeight: number,
    textAlign: "auto" | "left" | "right" | "center" | "justify",
    fontWeight: "normal" | "bold" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900",
    textTheme: TextThemeColor

}

export const SmallStyle: TextStylesStoreDataI = {
    fontFamily: 'PlayfairDisplay_500Medium',
    fontSize: 12,
    lineHeight: 20,
    textAlign: "left",
    fontWeight: "200",
    textTheme: TextThemeColor.default
}

export const MediumStyle: TextStylesStoreDataI = {
    fontFamily: 'PlayfairDisplay_500Medium',
    fontSize: 19,
    lineHeight: 30,
    textAlign: "left",
    fontWeight: "300",
    textTheme: TextThemeColor.default
}

export const BigStyle: TextStylesStoreDataI = {
    fontFamily: 'PlayfairDisplay_500Medium',
    fontSize: 25,
    lineHeight: 42,
    textAlign: "left",
    fontWeight: "400",
    textTheme: TextThemeColor.default
}

const ACTUAL_VERSION = 1.0

let textStyleStore = (set) => ({
    ...MediumStyle,
    style: 'medium',
    version: ACTUAL_VERSION,
    setFontFamily: (fontFamilyName: string) => set(produce((draft: TextStylesStore) => {
        draft.fontFamily = fontFamilyName;
    })),
    setFontWeight: (weight: "normal" | "bold" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900") => set(produce((draft: TextStylesStore) => {
        draft.fontWeight = weight;
    })),
    setLineHeight: (height: number) => set(produce((draft: TextStylesStore) => {
        draft.lineHeight = height;
    })),
    setTextAlign: (textAlign: "auto" | "left" | "right" | "center" | "justify") => set(produce((draft: TextStylesStore) => {
        draft.textAlign = textAlign;
    })),
    incrementFontSize: () => set(produce((draft: TextStylesStore) => {
        draft.fontSize = draft.fontSize + 1;
    })),
    decrementFontSize: () => set(produce((draft: TextStylesStore) => {
        draft.fontSize = draft.fontSize - 1;
    })),
    incrementLineHeight: () => set(produce((draft: TextStylesStore) => {
        draft.lineHeight = draft.lineHeight + 1;
    })),
    decrementLineHeight: () => set(produce((draft: TextStylesStore) => {
        draft.lineHeight = draft.lineHeight - 1;
    })),
    // incrementFontWeight: () => set(produce((draft:WebViewStylesStore) => {
    //     let fontWeight: any = Number.parseInt(draft.fontWeight);

    //     if(fontWeight != NaN && fontWeight < 900) {
    //         fontWeight += 100;
    //         draft.fontWeight = fontWeight.toString();
    //     }


    // })),
    // decrementFontWeight: () => set(produce((draft:WebViewStylesStore) => {
    //     let fontWeight: any = Number.parseInt(draft.fontWeight);

    //     if(fontWeight != NaN && fontWeight > 100) {
    //         fontWeight -= 100;
    //         draft.fontWeight = fontWeight.toString();
    //     }
    // })),
    toggleBold: () => set(produce((draft: TextStylesStore) => {
        let fontWeight = draft.fontWeight;
        if (fontWeight == 'bold') {
            draft.fontWeight = 'normal';
        } else {
            draft.fontWeight = 'bold';
        }
    })),
    setStyle: (style: "large" | "medium" | "small") => {
        set(produce((draft: TextStylesStore) => {

            let usedStyle

            switch (style) {
                case "large":
                    usedStyle = BigStyle;
                    draft.style = 'large';
                    break;
                case "medium":
                    usedStyle = MediumStyle;
                    draft.style = 'medium';
                    break;
                case "small":
                    usedStyle = SmallStyle;
                    draft.style = 'small';
                    break;
            }

            draft.fontSize = usedStyle.fontSize;
            draft.fontFamily = usedStyle.fontFamily;
            draft.lineHeight = usedStyle.lineHeight;
            draft.fontWeight = usedStyle.fontWeight;
            draft.textAlign = usedStyle.textAlign;

            draft.version = ACTUAL_VERSION

        }))
    },
    changeThemeColor: (themeColor: TextThemeColor) => set(produce((draft: TextStylesStore) => {
        draft.textTheme = themeColor;
    })),

});

let persistedStore = persist(textStyleStore, {
    name: 'textStyleStore',
    storage: createJSONStorage(() => AsyncStorage),
    version: ACTUAL_VERSION,
    onRehydrateStorage: (state: TextStylesStore | never) => {

        // Before Hydration
        // console.log('Before Hydration:', state);
        const onHydrationDone = (state: TextStylesStore) => {

            // if (!state.version || state.version < ACTUAL_VERSION) {

            //     console.log('TextStylesStore version outdated! Refreshing...')
            //     state.setStyle('medium')

            // }

        }
        return onHydrationDone;

    }
});

// For testing purpouses
// persistedStore = devtools(persistedStore);


export const useTextStylesStore = create<TextStylesStore>()(persistedStore);
