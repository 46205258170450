import React, { memo } from 'react'
import { Dimensions, FlatListProps, Platform, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import {useHorizontalFlatListProps} from './hooks/useHorizontalFlatListProps'
import { ArticleCardProps } from '../../cards/ArticleCard/types'
import { Article, ArticleInterface } from 'systemDomain'


export type ArticleListHorizontalProps = Partial<FlatListProps<Article>> & {

    itemHeight?: number
    itemWidth?: number
    itemSeparatorWidth?: number,
    paginateEvery?: number,
    articleCardProps?: Partial<ArticleCardProps>

}

export function getVerticalCardDimensions() {

    if(Platform.OS == 'web') {
        return {
            separator: 15,
            width: 300,
            height: 450
        }
    }

    const swidth = Dimensions.get('screen').width
    const sheight = Dimensions.get('screen').height

    const cardWidthProp = 1/2
    const cardHeightProp = 1/1.8

    const ITEM_SEPARATOR_WIDTH = 15
    const ITEM_WIDTH = (swidth * cardWidthProp) - (ITEM_SEPARATOR_WIDTH * cardWidthProp)
    let ITEM_HEIGHT = sheight * cardHeightProp

    // Constraint for horizontal screens

    // if(ITEM_HEIGHT > Dimensions.get('screen').height / 2) {
    //     ITEM_HEIGHT = Dimensions.get('screen').height / 2
    // }

    return {
        separator: ITEM_SEPARATOR_WIDTH,
        width: ITEM_WIDTH,
        height: ITEM_HEIGHT
    }
}

function ArticleListH(props: ArticleListHorizontalProps) {


    const { separator: ITEM_SEPARATOR_WIDTH, height: ITEM_HEIGHT, width: ITEM_WIDTH } = getVerticalCardDimensions()

    const {getItemLayoutInternal,renderItemInternal,paginationProps} = useHorizontalFlatListProps(ITEM_HEIGHT,ITEM_WIDTH,ITEM_SEPARATOR_WIDTH,props);

    return (
        // @ts-ignore
        <FlatList
            keyExtractor={(article) => article?._id}
            getItemLayout={getItemLayoutInternal}
            renderItem={renderItemInternal}
            ItemSeparatorComponent={()=><View style={{width: ITEM_SEPARATOR_WIDTH}}/>}
            horizontal
            {...props.paginateEvery ? paginationProps : null}
            {...props}
        />

    )
}


/**
 * Using the WrappedTab component in order to fordward the `MutableRefObject` to the downside component without losing it's reference in memmory
 */
const ArticleListHorizontal = React.forwardRef((props: ArticleListHorizontalProps, ref: React.MutableRefObject<FlatList>) => {

    //@ts-ignore
    return <ArticleListH {...props} ref={props.ref} />
})

export default memo(ArticleListHorizontal)

