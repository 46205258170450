import React, { memo } from 'react';
import { Image, ImageStyle, ImageURISource, LayoutRectangle, Platform, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import TextNty from '../app/components/nty/text/TextNty';
import TitleNty from '../app/components/nty/title/TitleNty';

import useSessionStore from 'src/context/sessionStore';
import { useAppTheme } from '../core/theme/ThemeBuilder';



const Logo = (props: { imageStyle?, overrideStyles?, height?: number, width?: number, type?: 'normal' | 'dark', showDimensions?: boolean }) => {

  const theme = useAppTheme()
  // const { enterprise, isEnterprise } = useAppSessionStore((store) => ({ enterprise: store.enterprise, isEnterprise: store.isEnterprise }))
  const enterprise = useSessionStore((store) => store.enterprise)
  // const colorScheme = props.type ? props.type : theme.dark ? 'dark' : Appearance.getColorScheme();
  // const image: ImageURISource = (isEnterprise && (enterprise.props.icon ?? enterprise.props.logo) )? { uri: enterprise.props.icon ?? enterprise.props.logo } : colorScheme == 'dark' ? require('../assets/logos/logo_nty_compact_white.png') : require('../assets/logos/logo_nty_compact.png');
  const image: ImageURISource = enterprise?.icon ?? enterprise?.logo ? { uri: enterprise?.icon ?? enterprise?.logo } : require('../assets/logos/logo_nty_compact.png')


  // // const [imageDimensions, setImageDimensions] = React.useState<{width: number, heigth: number, ratio: number}>()
  const [imageStyle, setImageStyle] = React.useState<ImageStyle>()

  const [imageLayout, setImageLayout] = React.useState<LayoutRectangle>()

  const defaultDimensions = { width: 80, height: 80 }

  React.useEffect(() => {

      if (image.uri) {
        Image.getSize(image.uri, (w, h) => {
          setImageStyle(getImageStyle({ width: w, heigth: h, ratio: w / h }))
        })
      } else {
        if (Platform.OS == 'web') {
          Image.getSize(image, (w, h) => {
            setImageStyle(getImageStyle({ width: w, heigth: h, ratio: w / h }))
          })
        } else {
          const source = Image.resolveAssetSource(image)
          setImageStyle(getImageStyle({ width: source.width, height: source.height, ratio: source.width / source.height }))
        }
      }


  }, [enterprise, props.width, props.height])


  const getImageStyle: (dimensions) => ImageStyle = (dimensions: { width: number, height: number, ratio: number }) => {
    let w,h,style;

    if (dimensions.width > dimensions.height) {

      w = props.width ?? (props.height ? (props.height * dimensions.ratio) : (defaultDimensions.width))
      style = { width: w, aspectRatio: dimensions.ratio, resizeMode: 'contain' }

    } else {

      h = props.height ?? (props.width ? (props.width / dimensions.ratio) : (defaultDimensions.height))
      style = { height: h, aspectRatio: dimensions.ratio, resizeMode: 'contain' }

    }
    return style;
  }

  return (

    imageStyle ?
      <View style={{ padding: 2, flexDirection: 'row' }}>

        <Image source={image} style={props.overrideStyles ? [props.imageStyle] : [imageStyle, props.imageStyle]} onLayout={(event) => { setImageLayout(event.nativeEvent.layout) }} />
        {props.showDimensions ?
          <View>
            <View>
              <TitleNty>Layout</TitleNty>
              <TextNty>width: {imageLayout?.width}</TextNty>
              <TextNty>height: {imageLayout?.height}</TextNty>
            </View>
            <View>
              <TitleNty>Style</TitleNty>
              <TextNty>width: {imageStyle?.width}</TextNty>
              <TextNty>height: {imageStyle?.height}</TextNty>
              <TextNty>aspectRatio: {imageStyle?.aspectRatio}</TextNty>
            </View>
          </View>
          : null}
      </View>
    // <AutoImage source={image} width={props.width} height={props.height} />
          :
    <ActivityIndicator size={props.width ?? props.height ?? defaultDimensions.width} color={theme.colors.primary[500]} />

  )
};


export default memo(Logo);
