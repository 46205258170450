import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ResizeMode, Video } from 'expo-av';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { IconButton, ProgressBar } from 'react-native-paper';
import { NewspaperModel } from 'systemDomain';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import TextNty from '../../nty/text/TextNty';


export type ArticleVideoProps = {
    url: string;
    newspaper?: NewspaperModel;
    resizeMode?: any;
};

type AVVideoStatus = {
    isLoaded: boolean;
    androidImplementation?: string;
    uri?: string;
    progressUpdateIntervalMillis?: number;
    durationMillis?: number;
    positionMillis?: number;
    playableDurationMillis?: number;
    seekMillisToleranceBefore?: number;
    seekMillisToleranceAfter?: number;
    shouldPlay?: boolean;
    isPlaying?: boolean;
    isBuffering?: boolean;
    rate?: number;
    shouldCorrectPitch?: boolean;
    volume?: number;
    isMuted?: boolean;
    isLooping?: boolean;
    didJustFinish?: boolean;
    error?: string
};

type InteractionStatus = {

    isVideoShowing: boolean;
    isThumbnailLoading: boolean;

};


export default function ArticleVideo2({ url, resizeMode, newspaper }: ArticleVideoProps) {

    const initialVideoStatus = {
        progressUpdateIntervalMillis: 100,
        positionMillis: 0,
        shouldPlay: false,
        rate: 1.0,
        shouldCorrectPitch: false,
        volume: 1.0,
        isMuted: false,
        isLooping: false,
        isLoaded: false
    };
    const [status, setStatus] = React.useState<AVVideoStatus>(initialVideoStatus);

    const theme = useAppTheme()


    const [iStatus, setIStatus] = React.useState<InteractionStatus>({ isVideoShowing: false, isThumbnailLoading: true });
    const lastPositionMilis = React.useRef<number>(null);
    const video = React.useRef<Video>(null);
    // const [thumb, setThumb] = React.useState(null);

    const iconColor = theme.colors.primary[500];
    const backGroundImage = newspaper?.props?.logo?.rect ?? require('./../../../../assets/logos/logo_nty.png')

    // const generateThumbnail = () => {

    //     VideoThumbnails.getThumbnailAsync(
    //         url,
    //         {
    //             time: 1500,
    //             quality: 0.1
    //         }
    //     ).then((result) => {
    //         setThumb(result.uri);
    //         setIStatus({ ...iStatus, isThumbnailLoading: false });
    //     }).catch((e) => {
    //         console.error(e)
    //         setThumb("error");
    //         setIStatus({ ...iStatus, isThumbnailLoading: false });
    //     })
    // };

    React.useEffect(() => {

        // generateThumbnail();

        return () => {
            if (video && video.current) {
                video.current.stopAsync();
            }
        }

    }, [url])

    return (

        <View style={{ flex: 1 }}>



            <Video
                ref={video}
                style={{
                    width: "100%",
                    height: "100%"
                }}
                source={{
                    uri: url
                }}
                resizeMode={resizeMode ?? ResizeMode.COVER}
                onLoad={() => {
                    if (lastPositionMilis.current) {
                        video.current ? video.current.playFromPositionAsync(lastPositionMilis.current) : false;
                    }
                }}
                useNativeControls
                isLooping
                // shouldPlay
                onPlaybackStatusUpdate={(status) => { setStatus(status); }}
            />
            {status.error && status.error.length > 1 &&
                <View
                    style={{ position: 'absolute', top: 0, width: "100%", flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.colors.background[700] + '10', paddingHorizontal: 30, height: '100%' }}
                >
                    <MaterialCommunityIcons
                        name={'alert-circle'}
                        color={iconColor}
                        size={25}
                        style={{ marginLeft: 10 }}
                    />
                    <TextNty style={{ fontSize: 10 }}>{status.error ?? 'Unknown error'}</TextNty>
                    <IconButton
                        icon={'refresh'}
                        color={iconColor}
                        size={25}
                        style={{ marginRight: 10 }}
                        onPress={() => {
                            video.current.unloadAsync();
                            setIStatus({ ...iStatus, isVideoShowing: false })
                        }}
                    />
                </View>
            }
            {/* <Text>{(status.positionMillis * 100 / status.durationMillis)/100}</Text> */}
            {status && status.positionMillis && status.durationMillis ? <ProgressBar progress={Math.round((status.positionMillis * 100 / status.durationMillis)) / 100} color={iconColor} style={{ position: 'absolute', bottom: 0 }} /> : null}
            {status && !status.error && !status.isLoaded && status.isBuffering &&
                <ActivityIndicator size={50} color={iconColor} style={[StyleSheet.absoluteFillObject, { zIndex: 1, backgroundColor: status.isPlaying ? 'transparent' : 'rgba(0,0,0,0.2)' }]} />
            }
        </View>

    )
}
