import { FavouriteInterface, FavouriteListInterface, MailRecipient, NewsletterDispatch } from 'systemDomain';
import { buildApiUrl, downloadFile, http_request } from '../core/http-utils';

// const getApiCaller = (currentUser: SessionUser): ApiCaller => {
//     const apiCaller = new ApiCaller(currentUser.token);
//     return apiCaller
// }

export type SearchParams = {
    search?: string,
    sites?: string[],
    sections?: string[],
    authors?: string[],
    visible?: boolean
}

class FavouriteListApi {

    static base_path = '/favouritelists/'

    constructor() { }

    // static async all(currentUser: SessionUser, limit: number = 0): Promise<FavouriteListInterface[]> {

    //     let url = FavouriteListApi.base_path + '?limit=' + limit

    //     const data = await getApiCaller(currentUser).call(url, 'GET');
    //     return data;
    // }

    static async all(limit: number = 0): Promise<FavouriteListInterface[]> {

        const url = buildApiUrl(FavouriteListApi.base_path, true);

        if (limit > 0) {
            url.searchParams.append('limit', limit + '')
        }

        const data = await http_request<FavouriteListInterface[]>(url, 'GET');
        return data;
    }

    // static async readFavourites(id: string, skip: number = 0, limit: number = 10): Promise<FavouriteInterface[]> {

    //     let url = FavouriteListApi.base_path + id + '/favourites' + '?skip='+ skip + '&limit=' + limit
    //     const data = await http_request(url, 'GET');
    //     return data;
    // }
    static async readFavourites(id: string, skip: number = 0, limit: number = 10): Promise<FavouriteInterface[]> {

        const url = buildApiUrl(FavouriteListApi.base_path + id + '/favourites', true);

        if (skip > 0) {
            url.searchParams.append('skip', skip + '')
        }
        if (limit > 0) {
            url.searchParams.append('limit', limit + '')
        }

        const data = await http_request(url, 'GET');
        return data;
    }

    // static async read(id: string): Promise<FavouriteListInterface> {
    //     const data = await http_request<FavouriteListInterface>(FavouriteListApi.base_path + id, 'GET');
    //     return data;
    // }
    static async read(id: string): Promise<FavouriteListInterface> {
        const url = buildApiUrl(FavouriteListApi.base_path + id, true);
        const data = await http_request<FavouriteListInterface>(url, 'GET');
        return data;
    }

    // static async delete(currentUser: SessionUser, id: string): Promise<FavouriteListInterface> {
    //     const data = await getApiCaller(currentUser).call(FavouriteListApi.base_path + id, 'DELETE');
    //     return data;
    // }
    static async delete(id: string): Promise<FavouriteListInterface> {
        const url = buildApiUrl(FavouriteListApi.base_path + id, true);
        const data = await http_request<FavouriteListInterface>(url, 'DELETE');
        return data;
    }

    // static async empty(currentUser: SessionUser, id: string): Promise<{ emptied: boolean }> {
    //     let data;
    //     try {
    //         data = await getApiCaller(currentUser).call(FavouriteListApi.base_path + id + '/empty', 'POST');
    //     } catch (e) {
    //         return { emptied: false }
    //     }
    //     return data;
    // }
    static async empty(id: string): Promise<{ emptied: boolean }> {

        const url = buildApiUrl(FavouriteListApi.base_path + id + '/empty', true);
        let data = await http_request<{ emptied: boolean }>(url, 'POST');
        return data;
    }

    // static async update(currentUser: SessionUser, id: string, list: Partial<FavouriteListInterface>): Promise<FavouriteListInterface> {
    //     const data = await getApiCaller(currentUser).call(FavouriteListApi.base_path + id, 'PUT', list);
    //     return data;
    // }
    static async update(id: string, updatedList: Partial<FavouriteListInterface>): Promise<FavouriteListInterface> {

        const url = buildApiUrl(FavouriteListApi.base_path + id, true)
        const data = await http_request(url, 'PUT', updatedList)
        return data;
    }


    static async create(list: Partial<FavouriteListInterface>): Promise<FavouriteListInterface> {
        // const data = await getApiCaller(currentUser).call(FavouriteListApi.base_path, 'POST', list);
        // return data;
        const url = buildApiUrl('favouritelists', true);
        const data = await http_request<FavouriteListInterface>(url, 'POST', list);
        return data
    }


    // static async turnDefault(currentUser: SessionUser, id: string): Promise<FavouriteListInterface> {
    //     const data = await getApiCaller(currentUser).call(FavouriteListApi.base_path + id + '/default', 'PUT');
    //     return data;
    // }
    static async turnDefault(id: string): Promise<FavouriteListInterface> {
        const url = buildApiUrl(FavouriteListApi.base_path + id + '/default', true);
        const data = await http_request<FavouriteListInterface>(url, 'PUT');
        return data;
    }

    static async downloadlist(id: string, override_filename?: string) {

        try {

            const download_result = downloadFile({ url: buildApiUrl('favouritelists/' + id + '/xlsx', true), method: 'GET', override_filename })
            return download_result

        } catch (e) {
            console.error(e)
            console.trace(e)
            return { file_name: null, status: 500, headers: null, uri: null, error: e }
        }


    }
    static async sendNewsletter(id: string, options: { recipients: MailRecipient[], subject?: string, cco?: MailRecipient[] }) {

        return http_request(buildApiUrl('newsletter/from/favourite_list/' + id, true), 'POST', options)

    }
    static async getLastFavlistNewsletterDispatch(id: string): Promise<NewsletterDispatch> {

        return http_request(buildApiUrl('newsletter/from/favourite_list/' + id + '/last_dispatch', true), 'GET')

    }

}

export default FavouriteListApi;
