import { buildApiUrl, http_request } from 'src/core/http-utils';
import { AuthDBUser } from '../models/user';

class AuthApi {

    static login(email:string, password:string): Promise<AuthDBUser  | undefined> {
        const url = buildApiUrl('/v1/auth/login');
        return http_request(url, 'POST', {email:email, password:password}, {avoid_using_token: true})
    }

    static register(email:string, password:string, name:string): Promise<AuthDBUser | undefined> {
        const url = buildApiUrl('/v1/auth/register');
        return http_request(url, 'POST', {name:name, email:email, password:password}, {avoid_using_token: true})
        // return this.apiCaller.call('/v1/auth/register', 'POST', {name:name, email:email, password:password})
    }

    static verify(email: string, token: string): Promise<void | undefined> {
        const url = buildApiUrl('/v1/auth/verify?email='+email+'&code='+token);
        return http_request(url, 'GET');
    }

    static resetPassword(email: string): Promise<void | undefined> {
        const url = buildApiUrl('/v1/auth/resetPassword/'+email);
        return http_request(url, 'GET');
    }

    static changePassword(email: string, password: string, token: string): Promise<void | undefined> {
        const url = buildApiUrl('/v1/auth/resetPassword');
        return http_request(url, 'POST', {email: email, password: password, token: token});
        // return this.apiCaller.call('/v1/auth/resetPassword', 'POST', {email: email, password: password, token: token});
    }

    static doMailExists(email: string): Promise<boolean | undefined> {
        const url = buildApiUrl('/v1/exist/'+email);
        return http_request(url, 'GET');
        // return this.apiCaller.call('/v1/exist/'+email, 'GET');
    }

}

export default AuthApi;
