import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import moment from 'moment'

import React from 'react'
import { DataTable } from 'react-native-paper'
import { NewsletterApi } from 'src/api/NewsletterApi'
import Background from 'src/app/Background'
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens'
import { NewsletterDispatch, SearchResponse } from 'systemDomain'
import LoadingIndicator from '../../loaders/Loading'
import TextNty from '../../nty/text/TextNty'
import { Platform, View } from 'react-native'
import TitleNty from '../../nty/title/TitleNty'
import { useFavouritesStore } from 'src/app/context/favoritesStore'
import { ScrollView } from 'react-native-gesture-handler'
import { capitalizeWords } from 'src/core/jsutils'
import VirtualScrollHeaderScreen from '../../common-ui/VirtualScrollHeaderScreen'

function FavlistNewsletterHistoryPage() {

    const navigation = useNavigation()
    const route = useRoute<RouteProp<ScreensParamsList, 'favlistNewsletterDispatch'>>()

    const favlist_id = route.params.id

    const favlist = useFavouritesStore.getState().favouriteLists?.find(favlist => favlist._id === favlist_id)

    const [loading, setLoading] = React.useState(true)

    const [dispatchHistory, setDispatchHistory] = React.useState<SearchResponse<NewsletterDispatch>>(null)

    React.useEffect(() => {

        fetchNewsletterHistory()

    }, [route])

    const fetchNewsletterHistory = async () => {

        setLoading(true)
        try {
            const result = await NewsletterApi.getFavlistNewsletterDistpatchHistory(favlist_id, null, { page: { skip: 0, limit: 100 } })
            setDispatchHistory(result)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }

    }




    return (
        <VirtualScrollHeaderScreen
            headerHeight={Platform.OS == 'web' ? 100 : 180}
            header={<View style={{ paddingTop: Platform.OS == 'web' ? 10 : 0 }}>

                {loading && <LoadingIndicator />}

                <View style={{ paddingHorizontal: 10 }}>

                    {favlist && <TitleNty style={{ marginBottom: 10 }}>
                        Lista: "{favlist?.name}""
                    </TitleNty>}

                    {dispatchHistory &&
                        <>
                            {
                                dispatchHistory.results.length === 0 ?

                                    <TextNty>No hay envíos</TextNty>
                                    :
                                    < >
                                        <TitleNty type='bold'>Último envío: <TitleNty type='regular'>{capitalizeWords(moment(dispatchHistory.results[0].sent_at).format('dddd,D MMMM YYYY, HH:mm[h]'))}</TitleNty></TitleNty>
                                        <TitleNty type='bold'>Envíos totales: <TitleNty type='regular'>{dispatchHistory.page.totalItems}</TitleNty></TitleNty>
                                    </>
                            }
                        </>
                    }
                </View>
            </View>}
        >




                <DataTable>

                    <DataTable.Header>
                        {/* <DataTable.TitleNty>Etiqueta</DataTable.TitleNty> */}
                        <DataTable.Title >F. Envío</DataTable.Title>
                        <DataTable.Title >Asunto</DataTable.Title>
                        <DataTable.Title numeric >Artículos</DataTable.Title>
                        <DataTable.Title numeric >Destinatarios</DataTable.Title>
                    </DataTable.Header>

                    {dispatchHistory && dispatchHistory.results.length > 0 && dispatchHistory.results.map((dispatch, index) => {
                        return (
                            <DataTable.Row key={dispatch._id + '_' + index}>
                                <DataTable.Cell key={index + 'sent_at'}><TextNty fontSize={10}>{moment(dispatch.sent_at).format('DD/MM/YYYY HH:mm')}</TextNty></DataTable.Cell>
                                <DataTable.Cell key={index + 'subject'}><TextNty fontSize={10}>{dispatch.subject}</TextNty></DataTable.Cell>
                                <DataTable.Cell numeric key={index + 'count'}>{dispatch.articles?.length ?? 0}</DataTable.Cell>
                                <DataTable.Cell numeric key={index + 'count'}>{(dispatch.to?.length ?? 0) + (dispatch.cco?.length ?? 0)}</DataTable.Cell>
                            </DataTable.Row>
                        )
                    })}

                </DataTable>

        </VirtualScrollHeaderScreen >

    )
}

export default FavlistNewsletterHistoryPage
