import React from 'react'
import ArticleOptionsModal from './article/ArticleOptionsModal'
import ArticleSuperUserOptionsModal from './article/ArticleSuperUserOptionsModal'
import ArticleEnterpriseAdminOptionsModal from './enterprise/ArticleEnterpriseAdminOptionsModal'
import FavouriteListOptionsModal from './favourite/FavouriteListOptionsModal'
import FavouriteListSelectorModal from './favourite/FavouriteListSelectorModal'
import HighlightOptionsModal from './highlight/HighlightOptionsModal'
import SearchDateFiltersModal from './search/date/SearchDateFiltersModal'
import SearchSortModal from './search/sort/SearchSortModal'
import SearchPublisherSelectorModal from './search/publishers/SearchPublisherSelectorModal'

function ContextModalsLoader() {



  return (
    <>
      <ArticleOptionsModal />
      <HighlightOptionsModal />
      <FavouriteListOptionsModal />
      <FavouriteListSelectorModal />
      <ArticleEnterpriseAdminOptionsModal/>
      <ArticleSuperUserOptionsModal/>
      <SearchDateFiltersModal/>
      <SearchSortModal/>
      <SearchPublisherSelectorModal/>
    </>
  )
}

export default ContextModalsLoader
