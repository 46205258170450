import AsyncStorage from "@react-native-async-storage/async-storage";
import produce from "immer";
import moment from "moment";
import { Linking, Platform } from "react-native";
import { FavouriteInterface, MailRecipient, ProfileInterface } from "systemDomain";
import { StoreApi, create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import ActionApi, { ACTION_NAMES } from "../../api/ActionApi";
import FavouriteApi from "../../api/FavouriteApi";
import FavouriteListApi from "../../api/FavouriteListApi";
import { useAppDataStore } from "../../context/appDataStore";

import useSessionStore, { NTY_GUEST_DEFAULT_MAIL } from "src/context/sessionStore";
import { FavouriteListInterface } from "systemDomain";
import { DownloadResultNty } from "../../core/http-utils";
import { getArticleReport } from "../../core/utils";
import { usePrompt } from "./alertContext";
import { useSnackBar } from "./snackBarStore";
import { PDFApi } from "src/api/PDFApi";


export const FAVOURITES_STORE_KEY = 'favouritesStore'

export type FavouriteActionResult = {
    type: 'add' | 'delete' | 'toggle',
    success: boolean;
    added: boolean;
    data: { list: FavouriteListInterface, [key: string]: any };
}

export const readUserFavourites: () => Promise<FavouriteInterface[] | null> = async () => {

    let favs = [];
    try {
        // const data = await ArticleApi.readFavorites(user);
        const data = await FavouriteApi.all()
        favs = data
    } catch (err) {
        console.error("Error while fetching user favourites.");
        console.error(err);
        return null;
    }

    return favs;

}

export const readUserFavouriteLists: () => Promise<FavouriteListInterface[] | null> = async () => {

    let favs = [];
    try {
        // const data = await ArticleApi.readFavorites(user);
        const data = await FavouriteListApi.all()
        favs = data
    } catch (err) {
        console.error("Error while fetching user favourite lists.");
        console.error(err);
        return null;
    }

    return favs;

}

type FavouritesStoreType = {

    _hasHydrated: boolean,
    initialized: boolean,
    setHasHydrated: (to: boolean) => void
    setInitialized: (to: boolean) => void

    lastUpdated: string,
    currentProfile?: ProfileInterface,
    favourites: FavouriteInterface[],
    favouriteLists: FavouriteListInterface[],
    defaultList: FavouriteListInterface,
    toggleFavourite: (favourite: FavouriteInterface, options?: { toggleInApi: boolean, isRealArticle: boolean, list?: FavouriteListInterface }) => Promise<FavouriteActionResult>,
    init: (profile?: ProfileInterface) => Promise<void>,
    turnDefault: (list: FavouriteListInterface, showMessage?: (message: string) => void) => Promise<FavouriteListInterface>,
    sendEmailFromFavlist: (list: FavouriteListInterface, options: { recipients: MailRecipient[], subject?: string, cco?: MailRecipient[] } ) => Promise<void>,
    generatePDF: (list: FavouriteListInterface) => Promise<DownloadResultNty>,
    renameList: (list: FavouriteListInterface, newName: string, showMessage?: (message: string) => void) => Promise<FavouriteListInterface>,
    changeIcon: (list: FavouriteListInterface, newIcon: string, showMessage?: (message: string) => void) => Promise<FavouriteListInterface>,
    deleteList: (list: FavouriteListInterface, showMessage?: (message: string) => void) => Promise<FavouriteListInterface>,
    createList: (list: Partial<FavouriteListInterface> & { favourites?: FavouriteInterface[] }, showMessage?: (message: string) => void) => Promise<FavouriteListInterface>,
    emptyList: (list: FavouriteListInterface, showMessage?: (message: string) => void) => Promise<FavouriteListInterface>,
    downloadList: (list: FavouriteListInterface, override_filename?: string) => Promise<DownloadResultNty>
    addLists: (...lists: FavouriteListInterface[]) => void
    setFavourites: (favourites: FavouriteInterface[]) => void
    setFavouriteLists: (lists: FavouriteListInterface[]) => void
    cleanStore: () => void
}


const FavouritesStore: (set: StoreApi<FavouritesStoreType>['setState'], get: StoreApi<FavouritesStoreType>['getState']) => FavouritesStoreType =
    (set, get) => ({

        initialized: false,
        _hasHydrated: false,
        setHasHydrated: (to: boolean) => {

            set({ _hasHydrated: to })

        },
        setInitialized: (to: boolean) => {

            set({ initialized: to })

        },
        lastUpdated: null,
        currentProfile: null,
        favourites: [],
        favouriteLists: [],
        defaultList: null,
        init: async (profile?: ProfileInterface) => {

            const prevState = get()
            const profileToUse = profile ?? prevState?.currentProfile ?? useSessionStore.getState().profile
            console.log('initializing ' + FAVOURITES_STORE_KEY + ', user: ' + profileToUse?.email);

            if (profileToUse.email != NTY_GUEST_DEFAULT_MAIL) {

                const favs = await readUserFavourites();
                const lists = await readUserFavouriteLists();

                if (favs && lists) {

                    if (JSON.stringify(prevState?.favourites) != JSON.stringify(favs) || JSON.stringify(prevState?.favouriteLists) != JSON.stringify(lists)) {
                        console.log('Refreshing favourites data');
                        set({
                            favourites: favs.sort((f1, f2) => f2.created.localeCompare(f1.created)),
                            favouriteLists: lists.sort((list, list2) => list.default ? -1 : list2.created.localeCompare(list.created)),
                            defaultList: lists.find((l) => l.default),
                            currentProfile: profileToUse,
                            initialized: true,
                            lastUpdated: new Date().toISOString()
                        })
                    } else {
                        console.log('No changes were made in subscriptions store.')
                        set({ initialized: true, currentProfile: profileToUse, lastUpdated: new Date().toISOString() })
                    }

                    if (lists.length == 0) {
                        console.log('No lists found, creating default list')
                        const defaultList = await FavouriteListApi.create({ name: 'Seguimiento de medios', profile: profileToUse.email, default: true })
                        set({ favouriteLists: [defaultList], defaultList })
                    }


                }

            } else {
                set({ initialized: true, lastUpdated: new Date().toISOString() })
                console.warn(FAVOURITES_STORE_KEY + ' neeeds a non guest user to initialize')
            }

        },
        toggleFavourite: async (favourite: FavouriteInterface, arg_options) => {

            const defaultOptions = { toggleInApi: true }
            const options = { ...defaultOptions, ...arg_options }


            try {

                const lastStore = get()
                const lists = lastStore?.favouriteLists.sort((list, list2) => list.default ? -1 : list2.created.localeCompare(list.created))
                let list

                if (!favourite.list) {
                    list = options.list ? lists?.find(list => list._id == options.list?._id) : lists?.find(l => l.default)
                    favourite.list = list._id
                } else {
                    list = lists?.find(list => list._id == favourite.list)
                }

                let found;
                let newFavourites: FavouriteInterface[];

                try {
                    console.log('toggleFavourite', { fav: favourite._id, list: favourite.list, article: favourite.article?._id })
                    // console.log('lastStore.favourites', lastStore.favourites.map(f => f._id))
                    found = lastStore.favourites.find((fav) => fav._id == favourite._id)
                    if (found) {
                        console.log('found', found._id)
                        newFavourites = lastStore.favourites?.filter(f => f._id != found._id)
                        set({ favourites: newFavourites })
                        return { type: 'toggle', success: true, added: false, data: { list } };
                    } else {
                        console.log('not found')
                        newFavourites = [...lastStore.favourites, favourite]
                        set({ favourites: newFavourites })
                        return { type: 'toggle', success: true, added: true, data: { list } };
                    }

                } catch (e) {

                    console.error(e)
                    return { type: 'toggle', success: false, added: false, data: { list } };

                } finally {

                    if (options.toggleInApi) {

                        let fav;
                        const result = await FavouriteApi.toggleFavourite(favourite)

                        if (result) {

                            // let added = result.added == true;
                            ActionApi.createFrom({ name: ACTION_NAMES.SAVE_ARTICLE, data: { ...getArticleReport(favourite.article), list: favourite.list } })

                            // if (added) {
                            //     console.log('added', result._id)
                            //     fav = result
                            //     const idx = newFavourites?.findIndex(f => f._id == favourite._id)
                            //     if (idx == -1) {
                            //         console.warn('Error toggling favourite in store')
                            //     } else {
                            //         console.log({ old: idx, new: fav._id })
                            //         newFavourites[idx] = fav
                            //         set({ favourites: [...newFavourites] })
                            //     }
                            // }

                        } else {
                            console.warn('Error toggling favourite in api')
                            useSnackBar.getState().showInfoMessage('Error al crear el favorito, inténtalo más tarde.')
                        }
                    }
                    if (moment().diff(moment(lastStore.lastUpdated), 'hours') > 5) {
                        console.log('more than 5 hours have passed, refreshing favouriteStore')
                        lastStore.init(lastStore.currentProfile);
                    }
                }
            } catch (e) {
                console.error('Error: ', e);
                return { type: 'toggle', success: false, added: false, data: null };
            }


        },
        turnDefault: async (list: FavouriteListInterface, showMessage?: (message: string) => void) => {

            const lastStore = get()
            let oldDefault = lastStore.favouriteLists.find(l => l.default)

            let newList: FavouriteListInterface = { ...list, default: true }

            const newLists = [newList]

            if (oldDefault) {
                oldDefault = { ...oldDefault, default: false }
                newLists.push(oldDefault)
            }

            try {

                lastStore.addLists(...newLists)
                showMessage ? showMessage("Ahora " + list.name + " es la lista por defecto") : null
                return newList;

            } finally {
                try {
                    newList = await FavouriteListApi.turnDefault(list._id)
                } catch (e) {
                    showMessage ? showMessage('Hubo un problema al marcar la lista como \'por defecto\', inténtalo más tarde.') : null
                }
            }

        },
        generatePDF: async (list: FavouriteListInterface) => {

            let result
            try {
                result = await PDFApi.favouritelist_pdf(list._id)
                useSnackBar.getState().showInfoMessage('PDF generado correctamente.')
            } catch (e) {
                console.error(e)
                useSnackBar.getState().showInfoMessage('Hubo un problema generando el PDF, inténtalo más tarde.')
            }

            return result
        },
        sendEmailFromFavlist: async (list: FavouriteListInterface, options: { recipients: MailRecipient[], subject?: string, cco?: MailRecipient[] } ) => {

            const currentUser = get().currentProfile

            try {
                const response = await FavouriteListApi.sendNewsletter(list._id, options)
                useSnackBar.getState().showInfoMessage('Newsletter enviada correctamente.')

            } catch (e) {

                useSnackBar.getState().showInfoMessage('Hubo un problema enviando la newsletter, se abrirá en tu cliente de correo.')

                try {
                    const favs = (await FavouriteListApi.readFavourites(list._id, 0, 50))
                    const text = favs.map(fav => `${fav.article.title}\r\n - ${fav.article.publisher.name}\r\n----------------\r\n\r\n${fav.article.url}\r\n\r\n`).join('\r\n--\r\n\r\n')
                    const url = encodeURI(`mailto:${currentUser.email}?subject=Newsletter '${list.name}'&body=${text}`)
                    Linking.openURL(url)
                } catch (e) {
                    useSnackBar.getState().showInfoMessage('Hubo un problema recuperando los guardados de la lista, inténtalo más tarde.')
                }

            } finally {

                ActionApi.createFrom({ name: ACTION_NAMES.SEND_FAV_NEWSLETTER, data: { list: list._id, name: list.name, recipients: options.recipients.map(r => typeof r == 'string' ? r : r.email), cco: options.cco?.map(r => typeof r == 'string' ? r : r.email) } })

            }

        },
        renameList: async (list: FavouriteListInterface, newName: string, showMessage?: (message: string) => void) => {

            const currentUser = get().currentProfile
            let newList = { ...list, name: newName }

            try {
                get().addLists(newList)
                showMessage ? showMessage('Renombrado correctamente.') : null
                return newList
            } finally {
                try {
                    await FavouriteListApi.update(list._id, newList)
                } catch (e) {
                    showMessage ? showMessage('Hubo un problema, inténtalo más tarde.') : null;
                }
            }

        },
        changeIcon: async (list: FavouriteListInterface, newIcon: string, showMessage?: (message: string) => void) => {

            const currentUser = get().currentProfile
            let newList = { ...list, icon: newIcon }

            try {
                get().addLists(newList)
                showMessage ? showMessage('Cambiado correctamente.') : null
                return newList
            } finally {
                try {
                    await FavouriteListApi.update(list._id, newList)
                } catch (e) {
                    showMessage ? showMessage('Hubo un problema, inténtalo más tarde.') : null;
                }
            }

        },
        emptyList: async (list: FavouriteListInterface, showMessage?: (message: string) => void) => {

            const lastStore = get();

            try {

                const latestFavs = lastStore.favourites
                const filteredFavs = lastStore.favourites.filter(f => f.list != list._id);
                console.log({ deleted: latestFavs.length - filteredFavs.length })
                if (filteredFavs?.length != lastStore.favouriteLists?.length) {

                    lastStore.setFavourites(filteredFavs)
                    showMessage ? showMessage('\'' + list.name + '\' ' + ' ha sido vaciada.') : null
                }
                return list

            } catch (e) {
                showMessage ? showMessage('Hubo un problema, inténtalo más tarde.') : null
            } finally {
                try {
                    await FavouriteListApi.empty(list._id)
                } catch (e) {
                    showMessage ? showMessage('Hubo un problema vaciando la lista, inténtalo más tarde.') : null
                    lastStore.setFavourites(lastStore.favourites)
                }
            }
        },
        deleteList: async (list: FavouriteListInterface, showMessage?: (message: string) => void) => {


            try {
                const lists = get().favouriteLists
                const listIdx = lists.findIndex(l => l._id == list._id)

                if (listIdx != -1) {
                    lists.splice(listIdx, 1)
                    set({ favouriteLists: lists })
                    showMessage ? showMessage(`'${list.name}' eliminada de tus listas`) : null
                } else {
                    showMessage ? showMessage(`No hemos encontrado '${list.name}' en tus listas, inténtalo más tarde.`) : null
                }

                return list;

            } catch (e) {

                showMessage ? showMessage('Parece que hubo un problema, inténtalo más tarde.') : null
                return null;

            } finally {
                await FavouriteListApi.delete(list._id)
            }


        },
        createList: async (list: Partial<FavouriteListInterface> & { favourites?: FavouriteInterface[] }, showMessage?: (message: string) => void) => {
            const currentUser = get().currentProfile

            if (!list.profile) {
                list.profile = currentUser.email
            }

            try {

                const result: FavouriteListInterface & { favourites?: FavouriteInterface[] } = await FavouriteListApi.create(list)
                if (result) {
                    showMessage ? showMessage(result.name + ' creado correctamente.') : null
                } else {
                    showMessage ? showMessage('Hubo un problema, inténtalo más tarde.') : null
                }
                get().addLists(result)
                if (result.favourites?.length > 0) {
                    console.log('Adding Favourites....')
                    get().setFavourites([...get().favourites, ...result.favourites])
                }
                return result

            } catch (e) {
                console.error(e)
                showMessage ? showMessage('Hubo un problema creando la lista, inténtalo más tarde.') : null
                return null
            }
        },
        downloadList: async (list: FavouriteListInterface, override_filename?: string) => {

            let result
            try {
                result = await FavouriteListApi.downloadlist(list._id, override_filename)
            } catch (e) {
                console.error(e)
            }

            return result
        },
        setFavourites: (payload: FavouriteInterface[]) => {
            payload?.sort((f1, f2) => f2.created.localeCompare(f1.created))
            set({ favourites: payload });
        },
        setFavouriteLists: (payload: FavouriteListInterface[]) => {
            payload?.sort((list, list2) => list.default ? -1 : list2.created.localeCompare(list.created))
            set({ favouriteLists: payload });

        },
        addLists: (...payload: FavouriteListInterface[]) => {

            const lists = get().favouriteLists

            payload.forEach((newList) => {

                if (!newList) return;

                let replaced = false;

                for (let [index, list] of lists.entries()) {
                    if (list._id == newList._id) {
                        lists[index] = newList;
                        replaced = true;
                        break;
                    }
                }

                if (!replaced) {
                    console.log('Adding list: ', newList)
                    lists.push(newList)
                }
            })

            console.log(lists)

            set({ favouriteLists: lists, defaultList: lists.find(list => list.default) })

        },
        cleanStore: () => set(produce((draft: FavouritesStoreType) => {
            draft.favourites = []
            draft.favouriteLists = []
            draft.currentProfile = null
            draft.lastUpdated = new Date().toISOString()
        }))
    });


let store;

// for testing purpouses
if (__DEV__ && Platform.OS == 'web') {
    store = devtools(FavouritesStore);
} else {
    store = FavouritesStore;
}

const avoidPersistingThisFields = [];

const persistedStore = persist(store, {
    name: FAVOURITES_STORE_KEY,
    storage: createJSONStorage(() => AsyncStorage),
    partialize: (state: FavouritesStoreType) => {

        const toBePersisted: Partial<FavouritesStoreType> = {};
        const keys = Object.keys(state).filter(key => avoidPersistingThisFields.indexOf(key) == -1).filter(key => typeof state[key] !== 'function')

        for (let key of keys) {
            toBePersisted[key] = state[key]
        }

        // console.log('Filtered from storage: ', toBePersisted)
        return toBePersisted;

    },
    onRehydrateStorage: (state: FavouritesStoreType | never) => {

        // Before Hydration
        // console.log('Before favourites Hydration:', state);
        const onHydrationDone = (state: FavouritesStoreType) => {

            if (!state) {

                console.log('No previous ' + FAVOURITES_STORE_KEY + ' found on hydration.')
                // useAppSessionStore.subscribe((store) => {

                //     const currentState = useFavouritesStore.getState();

                //     if (store._hasHydrated && store.ready && !currentState?.initialized) {
                //         console.log('Initializing ' + FAVOURITES_STORE_KEY + ' with user: ' + store.user.email)

                //         currentState.init(store.user).then(() => {
                //             console.log(FAVOURITES_STORE_KEY + ' initialized!!');
                //         })
                //     }
                // })

                return;

            }


            // hydrationSetup(state);
            if (!state.currentProfile) {
                console.log(FAVOURITES_STORE_KEY + ' had no user, waiting for appSessionStore to initialize')
                // const sub = useAppSessionStore.subscribe((store) => {

                //     if (store._hasHydrated && store.ready && !state.initialized) {
                //         console.log('Initializing ' + FAVOURITES_STORE_KEY + ' with user: ' + store.user.email)

                //         state.init(store.user).then(() => {
                //             console.log(FAVOURITES_STORE_KEY + ' initialized!!');
                //             sub()
                //         })
                //     }
                // })
            } else {
                console.log('Hydrated ' + FAVOURITES_STORE_KEY + ' with current owned user: ' + store.user.email)
                // state.init(state.currentUser).then(() => {
                //     console.log(FAVOURITES_STORE_KEY + ' initialized!!');
                // })
            }
        }
        return onHydrationDone;

    }
});


const hydrationSetup = (state: FavouritesStoreType) => {

    // state?.setInitialized(false);
    state?.setHasHydrated(true);
    // if(!(state.user instanceof User2)) {
    //     console.log('Building User for Hydration...', state.user);
    //     state.user = User2.fromObject(state.user)
    // }

}

// const persistedStore = persist(store, { name: 'favouritesStore', getStorage: () => { return AsyncStorage } });

export const useFavouritesStore = create<FavouritesStoreType>()(persistedStore);
