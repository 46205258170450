import React from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { Article } from "systemDomain";
import { useTextStylesStore } from "../../../../../context/TextStylesStore";
import { useAppTheme } from "src/core/theme/ThemeBuilder";


export const useStyles = (article: Article)=>{

const { textTheme, fontFamily } = useTextStylesStore((textStyles) => ({ textTheme: textStyles.textTheme, fontFamily: textStyles.fontFamily }));

const dimensions = useWindowDimensions()

const theme = useAppTheme()

const aspectRatio = React.useMemo(() => dimensions.width / dimensions.height, [dimensions.width, dimensions.height])

const styles = StyleSheet.create({

    tagWrapper:
        { flexDirection: 'row', alignItems: 'flex-start', padding: 10, flexWrap: 'wrap' },

    divider: {
        width: '90%',
        alignSelf: 'center'
    },
    subscribed: {
        alignSelf: 'flex-start',
        fontWeight: '700',
        fontSize: 14,
        textAlign: 'left'
    },
    authorContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
        marginLeft: 5
    },
    mainContainer: {
        // width: "100%",
        // maxWidth: 600,
        paddingHorizontal: Platform.OS == 'web' ? (aspectRatio > 1 ? "15%" : 20) : 15,
        // alignSelf: 'center',
        flex: 1,
        paddingTop: 10,
        paddingBottom: article.media?.screenshots ? Platform.OS == 'web' ? dimensions.height * 0.3 : dimensions.height * 0.2 : 80,
        backgroundColor: textTheme.bgColor
    },
    photo: {
        width: "100%",
        maxWidth: 600,
        height: 250,
        alignItems: 'center',
        resizeMode: 'contain',
    },
    title: {
        width: '100%',
        textAlign: 'center',
        fontSize: 30,
        marginBottom: 15,
        paddingTop: 10,
        fontWeight: '700',
        lineHeight: 35,
        color: textTheme.textColor
    },
    subtitle: {
        alignSelf: 'center',
        fontSize: 22,
        lineHeight: 27,
        color: textTheme.textColor,
        textAlign: 'center',
        ...theme.fonts.article.medium
    },
    author: {
        alignSelf: 'flex-start',
        fontWeight: '700',
        fontSize: 14,
        marginBottom: 40,
        lineHeight: 16,
        color: textTheme.textColor
    },
    date: {
        alignSelf: 'center',
        marginBottom: 10,
        color: textTheme.textColor
    },
    credits: {
        alignSelf: 'center',
        // fontWeight: '100',
        fontSize: 16,
        lineHeight: 20,
        width: '90%',
        marginVertical: 15,
        textAlign: 'center'
    },
    body: {
        marginTop: 20,
        color: textTheme.textColor,
        width: '90%',
        alignSelf: 'center',
        fontWeight: '300',
        fontSize: 18,
        lineHeight: 27,
        marginBottom: 20,
        marginVertical: 0,
        fontFamily: "PTSerif-Regular"
    },
    bodyNoTextFormat: {
        marginTop: 20,
        width: '90%',
        alignSelf: 'center',
        marginBottom: 20,
    }
});

return {styles}
}
