import produce from "immer";
import React, { Component } from "react";
import { ColorValue } from "react-native";
import { create } from "zustand";


export type AlertData = {
    title: string,
    message: string | JSX.Element | (() => Component),
    buttons?: AlertButton[]
    dismissable?: boolean
}

export type AlertButton = {
    label: string,
    type?: "text" | "outlined" | "contained"
    color?: ColorValue,
    onPress?: ((button?: AlertButton) => void) | (() => void),
    cancel?: boolean,
    dismissButton?: boolean
}

export type AlertResult = { alert: AlertData, button: AlertButton };

export type AlertStore = {
    /**
     * Current instance of the alert objec.
     * Used in {@link NtyAlertModal}
     */
    alertInfo: AlertData,
    visible: boolean,
    resolveCurrentAlert?: Function,
    rejectCurrentAlert?: Function,
    /**
     * Builds a new alert object and shows the sheet / modal.
     * Returns a Promise that will be resolved/rejected whenever a button is clicked.
     * @param alert
     * @returns
     */
    alert: (alert: AlertData) => Promise<AlertResult>,
    hide: () => void
    show: () => void
}


const defaultAlertButtons: AlertButton[] = [
    { label: 'Ok', type: 'text', cancel: false }
    // { label: 'Cancel', type: 'text', cancel: true }
]

/**
 * This function is meant to be used as the default function to bind to the 'onPress' method for buttons.
 * Acts as a middleware between the developers desired action and the alert technical necesities.
 *
 * @param button
 * @param promise
 * @param alertData
 */
const alertButtonWrapperFunc = (button: AlertButton, alertData: AlertData, resolvePromise: (value: { alert: AlertData, button: AlertButton }) => void, rejectPromise: (reason: any) => void) => {

    const oldOnPress = button.onPress
    let newOnPress

    // console.log('Creating wrapper func', get().navigator)

    newOnPress = async (button: AlertButton) => {
        oldOnPress ? await oldOnPress(button) : null
        if (button.cancel) {
            rejectPromise({ button, alert: alertData})
        } else {
            resolvePromise({ button, alert: alertData})
        }
    }
    button.onPress = newOnPress
    return button;

}


export const useAlert = create<AlertStore>((set, get) => ({
    promise: null,
    alertInfo: {
        title: 'Título por defecto',
        message: null,
        buttons: defaultAlertButtons
    },
    rejectCurrentAlert: null,
    resolveCurrentAlert: null,
    visible: false,
    alert: (info?: AlertData) => {

        // console.log('Last State: ', {visible: get().visible})
        if (get().visible == true) {
            // console.log('Alert was visible, re-building...')
            set({ visible: false })
            setTimeout(() => get().alert(info), 50)
            return
        }

        let resolvePromise, rejectPromise
        const promise = new Promise<AlertResult>(
            (resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            }
        )

        // console.log(get().navigator)

        if (info) {
            let buttons;
            if (!info.buttons || info.buttons.length == 0) {
                buttons = defaultAlertButtons.map((b) => alertButtonWrapperFunc(b, info, resolvePromise, rejectPromise))
            } else {
                buttons = info.buttons.map((b) => alertButtonWrapperFunc(b, info, resolvePromise, rejectPromise))
            }
            info.buttons = buttons
            set({ alertInfo: info, visible: true, rejectCurrentAlert: rejectPromise, resolveCurrentAlert: resolvePromise })
        } else {
            set({ visible: true, rejectCurrentAlert: rejectPromise, resolveCurrentAlert: resolvePromise })
        }

        return promise
    },
    hide: () => {
        set({ visible: false })
    },
    show: () => {
        set({ visible: true })
    }
}));


export type PromptValidation<T = any> = {
    function?: (data: T) => boolean,
    regex?: RegExp,
    errorMessage: string
}

export type PromptData<T = any> = {
    title: string,
    message?: string | JSX.Element,
    inputPlaceHolder?: string,
    buttons?: PromptButton[],
    dismissable?: boolean,
    validations?: PromptValidation<T>[]
}

export type PromptButton = {
    label: string,
    type?: "text" | "outlined" | "contained"
    color?: ColorValue,
    onPress?: ((button?: PromptButton, promptData?: any) => void) | (() => void),
    /** Set to true in order to mark this button as a cancel button so that the alert promise gets rejected whenever this button is clicked. */
    cancel?: boolean,
    /**
     * Set to true in order to make this button dismiss the alert automatically after clicking on it and executing the 'onPress' method
     */
    dismissButton?: boolean
}


const defaultPromptButtons: PromptButton[] = [
    { label: 'Ok', type: 'text', cancel: false, dismissButton: true },
    { label: 'Cancelar', type: 'text', cancel: true, dismissButton: true }
]

export type PromptResult<T = any> = { prompt: PromptData, button: PromptButton, data: T };

export type PromptStore<T = string> = {
    /**
     * Current instance of the alert objec.
     * Used in {@link NtyAlertModal}
     */
    promptInfo: PromptData<T>,
    resolvePromise: (value: { prompt: PromptData, button: AlertButton, data: any }) => void,
    rejectPromise: (reason: any) => void,
    data: T
    setData: (payload: any) => T,
    visible: boolean,
    /**
     * Builds a new alert object and shows the sheet / modal.
     * Returns a Promise that will be resolved/rejected whenever a button is clicked.
     * @param prompt
     * @returns
     */
    prompt: <G = T>(promptInfo?: PromptData<G>) => Promise<PromptResult<G>>,
    hide: () => void
    show: () => void
}

/**
 * This function is meant to be used as the default function to bind to the 'onPress' method for buttons.
 * Acts as a middleware between the developers desired action and the alert technical necesities.
 *
 * @param button
 * @param promise
 * @param prompt
 */
const promptButtonWrapperFunc = (button: PromptButton, prompt: PromptData, resolvePromise: (value: { prompt: PromptData, button: AlertButton, data: any }) => void, rejectPromise: (reason: any) => void) => {

    const oldOnPress = button.onPress
    let newOnPress

    newOnPress = async (button: PromptButton, data: any) => {
        oldOnPress ? await oldOnPress(button, data) : null
        if (button.cancel) {
            console.log('Rejecting promise with data: ', data)
            rejectPromise({ button, prompt: prompt })
        } else {
            console.log('Resolving promise with data: ', data)
            resolvePromise({ button, prompt: prompt, data })
        }
    }
    button.onPress = newOnPress
    return button;

}

export const usePrompt = create<PromptStore<any>>((set, get) => ({
    promptInfo: {
        title: 'Título por defecto',
        message: null,
        inputPlaceHolder: 'Escribe Aquí',
        buttons: defaultPromptButtons,
        dismissable: true,
        validation: null
    },
    resolvePromise: null,
    rejectPromise: null,
    data: '',
    setData: (payload: any) => {
        set({ data: payload })
    },
    visible: false,
    prompt: (info?: PromptData) => {

        if (get().visible == true) {
            // console.log('Alert was visible, re-building...')
            set({ visible: false })
            setTimeout(() => get().prompt(info), 50)
            return
        }

        let resolvePromise, rejectPromise
        const promise = new Promise<PromptResult>(
            (resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            }
        )

        if (info) {
            let buttons;
            if (!info.buttons || info.buttons.length == 0) {
                buttons = defaultPromptButtons.map((b) => promptButtonWrapperFunc(b, info, resolvePromise, rejectPromise))
            } else {
                buttons = info.buttons.map((b) => promptButtonWrapperFunc(b, info, resolvePromise, rejectPromise))
            }
            info.buttons = buttons
            set({ promptInfo: info, visible: true, data: null, resolvePromise, rejectPromise })

        } else {
            set({ visible: true, data: '', resolvePromise, rejectPromise })
        }

        return promise
    },
    hide: () => {
        set({ visible: false })
    },
    show: () => {
        set({ visible: true })
    }
}));


type RegisterModalStore = {
    additionalText: string,
    visible: boolean
    show: (additionalText?: string) => void,
    hide: () => void,
}

export const useRegisterModal = create<RegisterModalStore>((set) => ({
    visible: false,
    additionalText: null,
    show: (additionalText?: string) => set(produce((draft: RegisterModalStore) => {
        draft.visible = true;
        if (additionalText && draft.additionalText != additionalText) {
            draft.additionalText = additionalText;
        }
    })),
    hide: () => set(produce((draft: RegisterModalStore) => {
        draft.visible = false;
    }))
}));





