import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import React from 'react'
import { Dimensions, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Article } from 'systemDomain'
import ArticleApi from '../../../../api/ArticleApi'
import { getAppTheme, useAppTheme } from '../../../../core/theme/ThemeBuilder'
import { maximumChars } from '../../../../core/utils'
import PublisherBubble from '../../brands_sources/PublisherBubble'
import TextNty from '../../nty/text/TextNty'
import TitleNty from '../../nty/title/TitleNty'

type LengthProps = {
    data: Article,
    maxLength?: number
    style?: ViewStyle | TextStyle,
    fontSize?: number
}

const CardPubDate = React.memo(({ data, style }: LengthProps) => {

    const dateValue = React.useMemo(() => data.pubDate ? moment(data.pubDate).locale("es").fromNow() : '--', [data]);

    return (
        <View style={[{ flexDirection: 'row', justifyContent: 'flex-start', marginVertical: 2 }, style]}>
            <TextNty
                style={{
                    fontSize: 12,
                    fontWeight: "400",
                    textAlign: "left",
                    color: theme.colors.text_primary[300]
                }}
            >
                {dateValue}
            </TextNty>
        </View>

    )
});

function ArticleTitle(props: { article: Article, disabled?: boolean }) {

    const theme = useAppTheme()

    const navigation = useNavigation()

    return (
        <TouchableOpacity onPress={props.disabled ? null : () => {
            navigation.push('viewArticle', { id: props.article._id, article: props.article })
            ArticleApi.viewArticleAction(props.article);
        }}>

            <View style={styles.articleContainer}>

                <PublisherBubble style={{ marginBottom: 5 }} publisher={props.article.publisher} size={25} showLabel={true} labelPosition={'right'} labelStyle={{ marginBottom: 5, paddingLeft: 5, fontSize: 12, lineHeight: 15, fontFamily: theme.fonts.default.regular.fontFamily }} />
                <TitleNty style={theme.fonts.article.semiBold} fontSize={20}>{maximumChars(props.article.title, 90)}</TitleNty>
                {/* <TitleNty fontSize={15} color={theme.colors.text_primary[300]}>{maximumChars(props.article.subtitle, 90)}</TitleNty> */}

                <CardPubDate data={props.article} />


            </View>
        </TouchableOpacity>
    )
}

export default ArticleTitle



export function ArticlesTitlesGroup(props: { articles: Article[] }) {

    return (
        <View style={styles.groupContainer}>

            {props.articles.map((article => <ArticleTitle article={article} key={article._id}/>))}

        </View>
    )

}

const theme = getAppTheme()

const styles = StyleSheet.create({
    groupContainer: {

        paddingVertical: 5,
        paddingHorizontal: 5,
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'space-between',
        width: Dimensions.get('window').width - 50
    },
    articleContainer: {
        marginBottom: 10
    },
    title: {

        marginBottom: 5

    },
    subtitle: {
        marginBottom: 10
    }
})
