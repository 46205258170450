import { SessionInfo } from "systemDomain";
import { buildApiUrl, http_request } from "../core/http-utils";

export class SessionApi {


    static async build(token?: string): Promise<SessionInfo> {

        let url = buildApiUrl('session/build', true)

        if (token) {
            return await http_request<SessionInfo>(url, 'GET', null, { avoid_using_token: true, headers: { 'Authorization': 'Bearer ' + token } })
        } else {
            return await http_request<SessionInfo>(url, 'GET')
        }
    }
}
