import React from 'react'
import { HomeDefinition } from 'systemDomain'

import SectionComponent from './sections/Section'


function EnterpriseHomeSections(props: { homeDefinition: HomeDefinition }) {


    return (
        props.homeDefinition?.sections.map((section, index) => <SectionComponent key={section.title + '_' + index} section={section}/>)
    )
}

export default EnterpriseHomeSections
