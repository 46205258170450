import * as FileSystem from 'expo-file-system';
import { Linking, Platform } from 'react-native';
import StaticApi from '../api/Static.api';
import Settings from './settings';

type RestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH'

type DownloadResultNty = { status: number, uri: string, file_name: string, headers: Headers, error?: any }

type ApiCallOptions = { response_type?: 'Response' | 'json' | 'text' | 'blob', throw_errors?: boolean, headers?: Headers }

const DEFAULT_CALL_OPTIONS: ApiCallOptions = { response_type: 'json', throw_errors: true }

type ExpoResponse<T = any> = {
    type: string;
    status: number;
    ok: boolean;
    statusText: string;
    headers: Headers;
    url: string;
    bodyUsed: boolean;
    text: () => Promise<string>;
    json: () => Promise<T>;
    blob: () => Promise<Blob>;
}
/**
 * @deprecated in favor of {@link [http_utils](./http_utils.ts)}
 */
class ApiCaller {
    token: string;
    apiUrl: string;
    batchesUrl: string;
    constructor(token?: string, apiUrl?: string, batchesUrl?: string) {
        this.token = token ? token : "";
        this.apiUrl = apiUrl ? apiUrl : Settings.getApiURL()
        this.batchesUrl = batchesUrl ? batchesUrl : Settings.getBatchesUrl()
    }

    static buildApiUrl(path: string, v2: boolean = false) {


        if (!path.startsWith('/')) {

            path = '/' + path

        }
        return new URL((v2 ? Settings.getApiV2Url() : Settings.getApiURL()) + path)

    }

    async call<T = any>(url: string | URL, method: RestMethods, params?: object, options: ApiCallOptions = DEFAULT_CALL_OPTIONS): Promise<string | T | Blob | ExpoResponse<T>> {

        let fetchParams: any = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
            }
        }

        if (params) {
            fetchParams.body = JSON.stringify(params);
        }


        if (typeof url == 'object') {

            url.searchParams.append('token', this.token)

            // console.log('fetch: ' + url.toString())

            if (options.response_type == 'Response') {
                console.log('Returning fetch directly:')
                return fetch(url, fetchParams)
            }

            return this.handleApiResponse<T>(fetch(url, fetchParams), options)
            // .then(function (response) {
            //     if (!response.ok) {
            //         console.error(response)
            //         console.error(response.body)
            //         throw new Error("Response Code: " + response.status + ". path: " + response.url)
            //     }
            //     return response;
            // }).catch((e) => {
            //     throw e
            // })
            // .then(response => response.json())

        } else {




            let separator = '?';
            if (url.indexOf('?') != -1) {
                separator = '&';
            }

            var defUrl = url + (this.token ? separator + "token=" + this.token : "");

            // console.log('fetch: ' + this.apiUrl + defUrl)

            return this.handleApiResponse(fetch(this.apiUrl + defUrl, fetchParams), options)
            // .then(function (response) {
            //     if (!response.ok) {
            //         console.error(response)
            //         console.error(response.body)
            //         throw new Error("Response Code: " + response.status + ". path: " + response.url)
            //     }
            //     return response;
            // }).catch((e) => {
            //     throw e
            // })
            // .then(response => response.json())
        }
    }


    getExpoUsableResponse<T = any>(response: Response): ExpoResponse<T> {

        return { type: response.type, status: response.status, ok: response.ok, statusText: response.statusText, headers: response.headers, url: response.url, bodyUsed: response.bodyUsed, text: response.text, json: response.json, blob: response.blob }
    }

    async handleApiResponse<T = any>(fetchPromise: Promise<Response>, options: ApiCallOptions = DEFAULT_CALL_OPTIONS): Promise<T | string | Blob | ExpoResponse<T>> {
        try {
            const response = await fetchPromise;

            if (!response.ok) {
                console.error(response)
                if (options.throw_errors) {
                    throw new Error('Response Code: ' + response.status + '. path: ' + response.url);
                }
            }

            switch (options.response_type) {
                case 'json':
                    return await response.json();
                case 'text':
                    return await response.text();
                case 'blob':
                    if (Platform.OS == 'web') { return await response.blob() } else {

                        console.error('Blob fetch is not supported in expo mobile!')
                        return null;

                    }
                default:
                    return this.getExpoUsableResponse(response);
            }
        } catch (e) {
            if (options.throw_errors) {
                throw e;
            }
        }
    }

    async callBatches(url: string, method: string, params?: object): Promise<any> {

        var fetchParams: any = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (params) {
            fetchParams.body = JSON.stringify(params);
        }

        let separator = '?';
        if (url.indexOf('?') != -1) {
            separator = '&';
        }

        var defUrl = url + (this.token ? separator + "token=" + this.token : "");

        return fetch(this.batchesUrl + defUrl, fetchParams)
            .then(function (response) {
                if (!response.ok) {
                    console.error(response)
                    console.error(response.body)
                    throw new Error("Response Code: " + response.status + ". path: " + response.url)
                }
                return response;
            }).catch((e) => {
                throw e
            })
            .then(response => response.json())
    }

    /**
     * @deprecated use this class member `downloadFile` instead. this method needs `expo-sharing` and couldn't make it work
     * @param url
     * @param method
     * @param params
     * @param override_filename
     * @returns
     */
    async downloadFileToFileSystem(url: string | URL, method: RestMethods, params?: object, override_filename?: string) {

        let result: DownloadResultNty
        console.log('ApiCaller')
        try {


            console.log('Donwloading ' + url, { token: this.token })
            const response = await this.call(url, method, params, { response_type: 'Response', throw_errors: false })
            console.log(response)
            const fileName = override_filename ?? response.headers.get('Content-Disposition')?.split("filename=")[1];
            if (Platform.OS == 'web') {

                const uri = await StaticApi.downloadFile(await response.blob(), fileName);
                result = { file_name: fileName, status: response.status, headers: response.headers, uri }
            } else {
                const downloaded_file = await FileSystem.downloadAsync(
                    typeof url == 'string' ? url : url.toString(),
                    FileSystem.documentDirectory + fileName,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + this.token
                        }
                    })
                if (downloaded_file.status == 200) {

                    const contentUri = await FileSystem.getContentUriAsync(result.uri)
                    console.log({ fileUri: result.uri, contentUri })
                    // TODO: Cant make this dependency work
                    // Sharing.shareAsync(contentUri, {
                    //     UTI: 'text/csv',
                    //     mimeType: 'text/csv'
                    // })
                    Linking.openURL(contentUri)

                } else {
                    console.error('Error Downloading file at: ' + url)
                    console.error({ details: { url: url, response, downloaded_file } })
                }
                result = { file_name: fileName, status: downloaded_file.status, headers: new Headers(downloaded_file.headers), uri: downloaded_file.uri }
            }

            console.log({ downloadFile: result })
        } catch (e) {

            console.error('error in downladFile')
            console.error(e)
            result = { file_name: null, status: 500, headers: null, uri: null, error: "error" }

        }

        return result;
    }



    async downloadFile(url: string | URL, method: RestMethods, params?: object, override_filename?: string) {

        let result: DownloadResultNty
        console.log('downloadFileLinking')
        try {

            console.log('Donwloading ' + url)
            const response = await this.call(url, method, params, { response_type: 'Response', throw_errors: false })

            console.log({ response })
            const fileName = override_filename ?? response.headers.get('Content-Disposition')?.split("filename=")[1];
            if (Platform.OS == 'web') {

                const uri = await StaticApi.downloadFile(await response.blob(), fileName);
                result = { file_name: fileName, status: response.status, headers: response.headers, uri }

            } else {

                const data = await response.text()
                await FileSystem.writeAsStringAsync(FileSystem.documentDirectory + fileName, data, { encoding: FileSystem.EncodingType.UTF8 })
                await Linking.openURL(FileSystem.documentDirectory + fileName)
                result = { file_name: fileName, status: 200, headers: new Headers(response.headers), uri: FileSystem.documentDirectory + fileName }
            }

            console.log({ downloadFile: result })
        } catch (e) {

            console.error('error in downloadFile')
            console.error(e)
            result = { file_name: null, status: 500, headers: null, uri: null, error: "error" }

        }

        return result;
    }

    /**
     * @deprecated use {@link ApiCaller.downloadFileToFileSystem} instead
     */
    async callFilesWeb(url: string, method: string, params?: object): Promise<Response> {

        var fetchParams: any = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (params) {
            fetchParams.body = JSON.stringify(params);
        }

        let separator = '?';
        if (url.indexOf('?') != -1) {
            separator = '&';
        }

        var defUrl = url + (this.token ? separator + "token=" + this.token : "");

        return fetch(Settings.getApiURL() + defUrl, fetchParams)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            });
    }
}

export default ApiCaller;
