import { Article, EnterpriseFeedV2, EnterpriseInterface, HighlightInterface, HighlightModel, Interest } from "systemDomain";
import { buildApiUrl, http_request } from "../core/http-utils";

// const getApiCaller = (currentUser?: SessionUser): ApiCaller => {
//     const apiCaller = new ApiCaller(currentUser?.token);
//     return apiCaller
// }

class EnterpriseApi {

    static base_path = '/enterprises/'
    static feeds_base_path = '/feeds/'

    constructor() { }

    static async getEnterprises(page: number = 0, limit: number = 10) {

        const url = buildApiUrl(EnterpriseApi.base_path , true)

        url.searchParams.append('skip', page + '')
        url.searchParams.append('limit', limit + '')

        const data = await http_request<EnterpriseInterface[]>(url, 'GET')
        return data
    }

    // /**
    //  *
    //  * @param enterpriseIdentifier can be either an id (uid) or enterprise key
    //  */
    // static async getEnterpriseByKey(enterpriseIdentifier: string) {
    //     const data = await http_request<EnterpriseInterface>('/v1/enterprise/' + enterpriseIdentifier, 'GET')
    //     return data
    // }
    /**
     *
     * @param enterpriseIdentifier can be either an id (uid) or enterprise key
     */
    static async getEnterpriseByKey(enterpriseIdentifier: string) {
        const url = buildApiUrl(EnterpriseApi.base_path + enterpriseIdentifier, true)
        const data = await http_request<EnterpriseInterface>(url, 'GET')
        return data
    }

    // /**
    //  *
    //  * @param enterpriseIdentifier can be either an id (uid) or enterprise key
    //  */
    // static async getFeedsByKey(enterpriseIdentifier: string) {

    //     const data = await http_request<EnterpriseFeedInterface[]>('/v1/enterprise/' + enterpriseIdentifier + '/feeds', 'GET')
    //     return data

    // }
    /**
     *
     * @param enterpriseIdentifier can be either an id (uid) or enterprise key
     */
    static async getEnterpriseFeedsByKey(enterpriseIdentifier: string, page: number = 0, limit: number = 0-1) {

        const url = buildApiUrl(EnterpriseApi.base_path + enterpriseIdentifier + '/feeds', true)

        url.searchParams.append('skip', page + '')
        url.searchParams.append('limit', limit + '')

        const data = await http_request<EnterpriseFeedV2[]>(url, 'GET')
        return data

    }

    /**
     *
     * @param id the identifier of the feed whose articles need to be fetched
     * @returns
     */
    static async getFeedArticlesByFeedIdv2(id: string, itemsToSkip: number = 0, limit: number = 10) {

        // const url = ApiCaller.buildApiUrl('feeds/'+id+'/articles', true)

        // url.searchParams.append('skip', itemsToSkip+'')
        // url.searchParams.append('limit', limit+'')

        // const data = await getApiCaller(user).call<ArticleInterface[]>(url, 'GET')
        // return data.map(e => new ArticleModel(e))

        const url = buildApiUrl('feeds/' + id + '/articles', true)
        url.searchParams.append('skip', itemsToSkip + '')
        url.searchParams.append('limit', limit + '')

        // console.log(url, useAppSessionStore.getState()?.user?.token)


        const data = await http_request<Article[]>(url, 'GET')
        return data

    }

    /**
     *
     * @param id the identifier of the feed whose articles need to be fetched
     * @returns
     */
    static async getFeedArticlesGrouped(id: string) {
        const url = buildApiUrl(EnterpriseApi.feeds_base_path + id + '/articles/grouped', true)
        const data = await http_request<(Interest & { articles: Article[] })[]>(url, 'GET')
        return data
    }

    /**
     *
     * @param feed_id the identifier of the feed whose articles need to be fetched
     * @param interest_key the interest key
     * @returns
     */
    static async getInterestArticles(feed_id: string, interest_key: string, itemsToSkip: number = 0, limit: number = 10) {
        const url = buildApiUrl(EnterpriseApi.feeds_base_path + feed_id + '/interest/' + interest_key + '/articles', true)

        url.searchParams.append('skip', itemsToSkip + '')
        url.searchParams.append('limit', limit + '')

        const data = await http_request<(Interest & { articles: Article[] })>(url, 'GET')
        return data

    }

    /**
     *
     * @param enterpriseId the identifier of the enterprise whose highlights need to be fetched
     * @returns
     */
    static async getEnterpriseHighlights(enterpriseId: string, itemsToSkip?: number) {

        const url = buildApiUrl(EnterpriseApi.base_path + enterpriseId + '/highlights', true)

        if (itemsToSkip) {
            url.searchParams.append('skip', itemsToSkip + '')
        }

        const data = await http_request<HighlightInterface[]>(url, 'GET') // await getApiCaller().call<HighlightInterface[]>('/v1/enterprise/' + enterpriseId + '/highlights?skip=' + (itemsToSkip ?? 0), 'GET')
        return data

    }

    // /**
    //  *
    //  * @param id the identifier of the feed whose articles need to be fetched
    //  * @returns
    //  */
    // static async getFeedHighlightsByFeedId(id: string, itemsToSkip?: number) {

    //     const url = buildApiUrl('feeds/' + id + '/highlights', true)
    //     const data = await getApiCaller().call<HighlightInterface[]>('/v1/enterpriseFeeds/' + id + '/highlights?skip=' + (itemsToSkip ?? 0), 'GET')
    //     return data.map(e => new HighlightModel(e))

    // }
    /**
     *
     * @param id the identifier of the feed whose articles need to be fetched
     * @returns
     */
    static async getFeedHighlightsByFeedId(id: string, itemsToSkip?: number) {

        const url = buildApiUrl(EnterpriseApi.feeds_base_path + id + '/highlights', true)

        if (itemsToSkip) {
            url.searchParams.append('skip', itemsToSkip + '')
        }

        const data = await http_request<HighlightInterface[]>(url, 'GET')
        return data.map(e => new HighlightModel(e))

    }
}

export default EnterpriseApi;
