import React, { forwardRef, useImperativeHandle, useState, useRef, MutableRefObject } from 'react';
import { Dimensions, Modal, Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native';
import { getAppTheme } from '../../../../core/theme/ThemeBuilder';
import { NtyTheme } from '../../../../core/theme/types';
import Animated from 'react-native-reanimated';
import { ScrollView, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import BottomSheet, { BottomSheetBackdrop, BottomSheetFooter, BottomSheetScrollView } from '@gorhom/bottom-sheet'

const size = Dimensions.get("window");

export type NewsToYouSheetModalProps = {
    children: JSX.Element[] | JSX.Element,
    /**
     * Set to true to force the component to act as a modal in all platforms.
     */
    forceModal?: boolean,
    /**
     * Set to false to make the sheet/modal not dismissable
     */
    dissmisable?: boolean,
    /**
     * Style prop to be passed to the modal component to ovverride the default {@link NewsToYouModalDefaultStyles.dialog}:
     *
     * ```js
     * const styles = StyleSheet.create({
     *       dialog: {
     *           width: '90%',
     *           paddingVertical: 30,
     *           paddingHorizontal: 10,
     *           maxWidth: 600,
     *           maxHeight: 500,
     *           alignSelf: 'center'
     *       }
     *   })
     * ```
     *
     */
    modalStyle?: ViewStyle,
    /**
     * Override the default height for the Modal
     * *This will only affect the modal version of this component*
     * @see snapPoints prop for
     */
    modalHeight?: number,


    /**
     *  Points for the bottom sheet to snap to. It accepts array of number, string or mix. String values should be a percentage.
     *
     *   @example
     *   snapPoints={[200, 500]}
     *   snapPoints={[200, '%50']}
     *   snapPoints={['%100']}
     */
    snapPoints?: (string | number)[],

    /**
     * Wether or not to put the `children` prop inside an ScrollView
     */
    scrollableContent?: boolean,
    /**
     * Executed when the sheet / modal closes
     */
    onClose?: () => void
};

export type NewsToYouSheetModalHandle = {
    show: () => void;
    hide: () => void;
    setHeight: (newHeight: number) => void;
};

const defaults = {
    snapPoints:  ["40%", "60%"],
    height: 500
}

/**
 *
 * The default behaviour epending on the Platform being used, the component will display:
 * - For devices: A BottomSheet
 * - For web: A Modal
 *
 * You can override this behaviour by passing the `forceModal` prop as true in order to force the component to act as a modal in all platforms.
 *
 *
 * __To use this component methods, you should pass in a reference to the component.__
 *
 * @example
 *
 * ```js
 * const modalRef = React.useRef();
 * ...
 * <NtySheetModal ref={modalRef}/>
 * ...
 * const show = () => {
 *    modalRef.current.show();
 * }
 *
 * ```
 */
const NtySheetModal = forwardRef<NewsToYouSheetModalHandle, NewsToYouSheetModalProps>(
    ({ forceModal, dissmisable, modalStyle, modalHeight, onClose, scrollableContent, children, snapPoints = defaults.snapPoints }, ref) => {

        const sheetRef = useRef<BottomSheet>(null);

        const [modalVisible, setModalVisible] = useState(false);
        const [height, setHeight] = useState(modalHeight || defaults.height);
        const theme = getAppTheme();

        const styles = StyleSheet.create({
            dialog: {
                width: Platform.OS == 'web' && size.width > 768 ? "50%" : "90%",
                paddingVertical: 15,
                paddingHorizontal: 8,
                backgroundColor: theme.colors.background[200],
                alignSelf: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                borderRadius: 40,
                ...(modalStyle || {}),
                maxHeight: height,
                height: modalHeight,
                // zIndex: 10000
            },
            sheet: {
                backgroundColor: theme.colors.background[300],
                borderTopLeftRadius: theme.dimensions.roundness.default,
                borderTopRightRadius: theme.dimensions.roundness.default,
                paddingBottom: 10,
            },
            outerStyle : {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Agrega un fondo semi-transparente oscuro
                // zIndex: -100
            }
        });

        const onOuterPress = () => {
            setModalVisible(false);
            if (onClose) {
                onClose();
            }
        };

        useImperativeHandle(ref, () => ({
            show: () => Platform.OS === 'web' || forceModal ? setModalVisible(true) : sheetRef.current.expand(),
            hide: () => Platform.OS === 'web' || forceModal ? setModalVisible(false) : sheetRef.current?.close(),
            setHeight: (newHeight: number) => setHeight(newHeight),
        }));

        return (
            Platform.OS === 'web' || forceModal ?
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={onClose}

                >
                     <Pressable style={styles.outerStyle} onPress={dissmisable != false ? onOuterPress : null }>
                        <Pressable style={styles.dialog} >
                            {scrollableContent ? <ScrollView contentContainerStyle={{ paddingVertical: 20, justifyContent: 'center' }} scrollEnabled={scrollableContent}>{children}</ScrollView> : children}
                        </Pressable>
                    </Pressable>
                </Modal>
                :
                // You can replace the below view with your BottomSheet component
                <BottomSheet

                    snapPoints={snapPoints}
                    ref={sheetRef}
                    index={-1}
                    keyboardBlurBehavior={'restore'}
                    keyboardBehavior={'interactive'}
                    // footerComponent={(props)=><BottomSheetFooter {...props}>
                    //     <View style={{flex: 1, justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 3, flexDirection: 'row', backgroundColor: this.state.theme.colors.background[200]}}>
                    //         <ButtonNty mode='text' labelMergeStyles={{fontSize: 12}} onPress={()=>this.sheetRef.current.snapToIndex(this.state.snapPoints.length - 1)}>Expandir</ButtonNty>
                    //         <ButtonNty mode='text' labelMergeStyles={{fontSize: 12}} onPress={()=>this.hide()}>Cerrar</ButtonNty>
                    //     </View>
                    // </BottomSheetFooter>}
                    handleHeight={100}
                    // onClose={() => onClose ? onClose() : null }
                    // onChange={(index) => { if (index == -1) { onClose ? onClose() : null } }}
                    // onAnimate={(from, to) => { console.log({from, to}); if(to == -1) { onClose ? onClose() : null } }}
                    enablePanDownToClose={dissmisable == false ? false : true}
                    children={scrollableContent ? <BottomSheetScrollView contentContainerStyle={{ paddingVertical: 10 }}>{children}</BottomSheetScrollView> : children}
                    backdropComponent={(props) =>
                        <BottomSheetBackdrop
                            {...props}
                            disappearsOnIndex={-1}
                            appearsOnIndex={1}
                        />
                    }
                />
        );
    }
);

export default NtySheetModal;
