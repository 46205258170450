import { Article, FavouriteInterface, FavouriteListInterface, HighlightInterface } from "systemDomain"

import useSessionStore from "src/context/sessionStore"
import { hash_cyrb53 } from "../jsutils"

export const buildFavourite = (article: Article, list?: FavouriteListInterface) => {

    // const session = useAppSessionStore.getState()
    const profile = useSessionStore.getState().profile

    let id = article._id

    if(list){
        id += '_' + list._id
    }


    const favourite: FavouriteInterface = {
        _id:  id,
        articleId: article._id,
        created: new Date().toISOString(),
        list: list?._id,
        profile: profile.email,
        title: null,
        visible: true,
        article: article
    }

    return favourite;

}

export const buildArticleFromHighlight: (highlight: HighlightInterface) => Partial<Article> = (highlight: HighlightInterface) => {

    const {enterprise, profile} = useSessionStore.getState()
    const id = 'article_from_highlight_' + hash_cyrb53(highlight.url ?? highlight.slideshow ?? highlight.picture)
    let article: any = highlight.article
    let site

    try {
        site = new URL(highlight.url).hostname
    } catch (e) {
        if (enterprise) {
            site = 'Destacados de ' + enterprise.name
        } else {
            site = 'Destacado por ' + profile.email
        }
    }

    const date = highlight.created ?? new Date().toISOString()

    article = {
        _id: id,
        title: highlight.slideshow,
        url: highlight.url,
        site: site,
        source: site,
        brand: site,
        pubDate: date,
        published_at: date,
        date
    }

    if (highlight.picture) {
        article.photo = highlight.picture
    }

    return article
}


export const buildFavouriteFromHighlight: (highlight: HighlightInterface, list?: FavouriteListInterface) => FavouriteInterface = (highlight: HighlightInterface, list?: FavouriteListInterface) => {

    const profile = useSessionStore.getState().profile
    // @ts-ignore
    let article: Partial<Article> = highlight.article;

    if (!article) {
        article = buildArticleFromHighlight(highlight)
    }

    let id = article._id

    if(list){
        id += '_' + list._id
    }


    //@ts-ignore
    const favourite: FavouriteInterface = {
        _id: id,
        articleId: article._id,
        created: new Date().toISOString(),
        profile: profile.email,
        title: null,
        visible: true,
        article: article
    }

    if (list) {
        favourite.list = list._id
    }


    return favourite;


}
