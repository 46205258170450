import React from 'react';
import { ActivityIndicator, Alert, PixelRatio, useWindowDimensions, View } from 'react-native';

import useSessionStore from 'src/context/sessionStore';
import ActionApi, { ACTION_NAMES } from '../../../api/ActionApi';
import ProfileApi from '../../../api/Profile.api';
import { useAppTheme } from '../../../core/theme/ThemeBuilder';
import ButtonNty from '../nty/button/ButtonNty';
import TextNty from '../nty/text/TextNty';
import TitleNty from '../nty/title/TitleNty';



export type DeleteProfileModalProps = {
  onDismiss?: () => void
  onLogOut?: () => void
  //onErrorAccount? : (msg) => void
}

export default function DeleteProfileModal(props: DeleteProfileModalProps) {

  const theme = useAppTheme()

  // const { user } = useAppSessionStore();
  const profile = useSessionStore(store => store.profile)
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [done, setDone] = React.useState<any>(false);

  const deleteProfile = async () => {

    Alert.alert(
      'Si borras tu cuenta no podrás recuperarla.',
      'Borraremos todos tus datos. Último Aviso.',
      [
        {
          text: 'Sí', onPress: async () => {
            setLoading(true)
            try {
              // const profileApi = new ProfileApi(new ApiCaller(currentUser.token));
              const response = await ProfileApi.deleteProfile();
              const status = response.status
              deleteAction(status)
              status == 200 ? doLogOut() : setDone(deleteError(status))
            }
            catch (e) {
              console.error('network error')
            }
          }
        },
        { text: 'No' }
      ]);



  }

  //custom message by endpoint payload : {status: ???}
  const deleteError = (status: number) => {
    if (status == 401) return 'Error de autentificación.'
    else if (status == 421) return 'No se ha podido borrar, tienes una subscripción premium activa.'
    else if (status == 500) return 'Error al realizar la copia de seguridad de tus datos.'
    else if (status == 501) return 'Error al restaurar los datos de la cuenta.'
    else if (status == 420) return 'Error al borrar los datos, tu cuenta ha sido restaurada.'
  }


  const deleteAction = (response: string) => { ActionApi.createFrom({ name: ACTION_NAMES.ACCOUNT_DELETED, data: { response } }) }

  const doLogOut = () => { props.onDismiss(); props.onLogOut(); }


  return (
    done ?
      <View style={{ flexDirection: 'column', justifyContent: 'space-around', height: height / 2 }}>


        <TitleNty style={{
          textAlign: "center",
          color: theme.colors.text_primary[500],
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 60
        }}>{done}</TitleNty>

        <View>
          <ButtonNty style={{ borderRadius: 20, backgroundColor: theme.colors.primary[500], marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(width / 20) }} onPress={props.onDismiss}><TextNty style={{ color: theme.colors.text_primary[300] }}>Salir</TextNty></ButtonNty>
        </View>
      </View> :
      loading ?
        <View style={{ flexDirection: 'column', justifyContent: 'space-around', height: height / 2 }}>
          <View>
            <TextNty type='bold' fontSize={22} style={{
              textAlign: "center",
              color: theme.colors.text_primary[500]
            }}></TextNty>
          </View>
          <View>
            <TextNty style={{
              textAlign: "center",
              color: theme.colors.primary[500],
              fontWeight: "bold",
              fontSize: 20,
              marginTop: 60
            }}>Borrando</TextNty>
            <TextNty style={{
              marginTop: 10,
              marginBottom: 60,

              textAlign: "center",
              color: theme.colors.text_primary[500],
              fontWeight: "normal",
              fontSize: 13
            }}>{profile.email}</TextNty>
          </View>
          <View>
            <ActivityIndicator size="large" animating={true} color={theme.colors.primary[500]} />
          </View>
        </View>

        : //if not done(error) nor loading render regular modal
        <View style={{ flexDirection: 'column', justifyContent: 'space-around', paddingVertical: 30, paddingHorizontal: 10, height: height / 2 }}>
          <View>
            <TitleNty style={{
              textAlign: "center",
              color: theme.colors.text_primary[500],
              fontWeight: "bold",
              fontSize: 26
            }}>¿Estás seguro?</TitleNty>
          </View>
          <View>
            <TextNty type='semiBold' style={{
              textAlign: "center",
              color: theme.colors.danger[500],
              fontSize: 22,
              marginTop: 60
            }}>Esta acción no se puede deshacer</TextNty>
            <TextNty style={{
              marginTop: 10,
              marginBottom: 60,

              textAlign: "center",
              color: theme.colors.danger[700],
              fontWeight: "normal",
              fontSize: 15
            }}>{'Perderás todos tus datos y no podrás \nrecuperarlos'}</TextNty>
          </View>
          <View>
            <ButtonNty style={{ borderRadius: 20, backgroundColor: theme.colors.primary[400], marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(width / 20) }} onPress={props.onDismiss}><TextNty style={{ color: theme.colors.text_secondary[200] }}>Lo he pensado mejor</TextNty></ButtonNty>
            <ButtonNty style={{ borderRadius: 20, backgroundColor: theme.colors.danger[500], marginTop: 15, marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(width / 20) }} onPress={deleteProfile}><TextNty style={{ color: theme.colors.text_secondary[200] }}>Borrar mi cuenta</TextNty></ButtonNty>
          </View>
        </View>

  )
}
