import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon'
import { getAppTheme } from '../../../core/theme/ThemeBuilder'
import TextNty from '../nty/text/TextNty'


export type ListItemProps = {

    mode?: 'ltr' | 'rtl',
    label: string,
    description?: string,
    icon?: IconSource,
    style?: ViewStyle,
    labelStyle?: TextStyle,
    descriptionStyle?: TextStyle,
    onPress?: (label?: string) => void,
    onLongPress?: (label: string) => void

}



function ListItem({ mode = 'ltr', icon = null, onPress, onLongPress, label, style, labelStyle, description, descriptionStyle }: ListItemProps) {


    const theme = getAppTheme();

    const _onPress = (ev) => {
        onPress ? onPress(label) : null
    }
    const _onLongPress = (ev) => {
        onLongPress ? onLongPress(label) : null
    }

    return (

        <TouchableOpacity onPress={_onPress} onLongPress={_onLongPress} disabled={!onPress}>
            <View style={[{ flex: 1, flexDirection: 'row', height: 'auto', padding: 4, justifyContent: 'flex-start', alignItems: 'center', direction: mode }, style]}>

                {
                    icon ?
                        <View nativeID='itemleft' style={{ marginHorizontal: 20, height: "100%", width: 'auto', justifyContent: 'center' }}>

                            {
                                typeof icon == 'string' ?
                                    <MaterialCommunityIcons size={30} color={theme.colors.text_primary[500]} name={icon} />
                                    :
                                    icon
                            }

                        </View>
                        :
                        null
                }

                <View nativeID='itemRight' style={{ flexDirection: 'column' }}>
                    <View>
                        <TextNty type='bold' fontSize={20} style={{width: "95%", ...labelStyle}} >{label}</TextNty>
                    </View>
                    {description &&
                        <View>
                            <TextNty type='light' fontSize={15} style={[{width: "95%", marginVertical: 5 }, descriptionStyle]}>{description}</TextNty>
                        </View>}
                </View>

            </View>
        </TouchableOpacity>
    )
}

export default ListItem
