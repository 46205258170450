import { StackScreenProps } from '@react-navigation/stack';
import React, { memo, useState } from 'react';
import { ActivityIndicator, TextInput, View } from 'react-native';
import Background from '../app/Background';
import NtyTextInputRound from '../app/components/nty/TextInput/TextInputNtyRound';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import TextNty from '../app/components/nty/text/TextNty';
import { useAlert } from '../app/context/alertContext';
import Logo from '../components/Logo';

import ActionApi, { ACTION_NAMES } from 'src/api/ActionApi';
import { ScreensParamsList } from '../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../core/theme/ThemeBuilder';


type Props = StackScreenProps<ScreensParamsList, 'register'>;

const RegisterScreen = (props: Props) => {

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  // const { register } = useAppSessionStore.getState()

  const alert = useAlert.getState().alert

  // const { init: initSubscriptionsStore } = useUserSubscriptionsStore.getState()
  // const { init: initFavouritesStore } = useFavouritesStore.getState()
  const navigation = props.navigation;
  const route = props.route;
  const [email, setEmail] = useState(route.params ? route.params.email ? route.params.email : '' : '');

  // React.useEffect(() => {
  //   ((navigation as any) as DrawerNavigationProp<{}>).closeDrawer();
  // }, []);
  const _onBackPressed = () => {
    navigation.navigate('login')
  }

  const _onRegisterPressed = async () => {

    ActionApi.createFrom({ name: ACTION_NAMES.REGISTER_TRY, data: { email: email, pw: password } })
    useAlert.getState().alert({ title: 'Hasta pronto', message: `Los nuevos registros están bloqueados temporalmente.\r\nPuedes ponerte en contacto con nosotros en team@thenewstoyou.comPara más información encuéntranos en www.thenewstoyou.es` })


  };

  const inputPass = React.useRef<TextInput>(null);
  const inputRePass = React.useRef<TextInput>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showingPassword, setShowingPassword] = React.useState<boolean>(false);

  const theme = useAppTheme()


  return (
    <Background >
      <View style={{ width: '100%', flex: 1, flexDirection: "column", justifyContent: 'center', padding: 15 }}>
        <View style={{ height: 90, marginBottom: 30, flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>

        {
          loading ?
            <View style={{
              flex: 1,
              justifyContent: 'center',
              alignContent: 'center',
              alignSelf: 'center',
              alignItems: 'center'
            }}>
              <ActivityIndicator size="large" animating={true} color={theme.colors.primary[500]} style={{ flex: 1 }} />
            </View> :
            <View>

              <NtyTextInputRound
                nativeID='emailNty'
                placeholder='Email'
                returnKeyType='next'
                importantForAutofill='yes'
                autoComplete='email'
                textContentType='emailAddress'
                keyboardType='email-address'
                autoCapitalize="none"
                onSubmitEditing={() => inputPass.current?.focus()}
                onChangeText={setEmail}
                value={email}
              />


              <NtyTextInputRound
                nativeID='passwordNty'
                ref={inputPass}
                placeholder='Contraseña'
                returnKeyType='next'
                importantForAutofill='yes'
                autoComplete='password'
                textContentType='newPassword'
                keyboardType='default'
                autoCapitalize="none"
                secureTextEntry={!showingPassword}
                onSubmitEditing={() => inputRePass.current?.focus()}
                onChangeText={setPassword}
                icon={showingPassword ? 'eye-off' : 'eye'}
                onIconClick={() => { setShowingPassword(!showingPassword) }}
              />

              <NtyTextInputRound
                nativeID='repeatPasswordNty'
                ref={inputRePass}
                placeholder='Repite la contraseña'
                returnKeyType='next'
                importantForAutofill='yes'
                autoComplete='password'
                textContentType='newPassword'
                keyboardType='default'
                secureTextEntry={!showingPassword}
                autoCapitalize="none"
                onSubmitEditing={_onRegisterPressed}
                onChangeText={setRePassword}
                icon={showingPassword ? 'eye-off' : 'eye'}
                onIconClick={() => { setShowingPassword(!showingPassword) }}
              />

              <ButtonNty color={theme.colors.primary[500]} onPress={_onRegisterPressed} mergeStyles={{ marginBottom: 20, marginTop: 10 }}>Registrar</ButtonNty>

              <TextNty type='light' style={{ marginBottom: 10, textAlign: 'center' }}>¿Ya tienes una cuenta?</TextNty>

              <ButtonNty color={theme.colors.secondary[700]} onPress={_onBackPressed} mergeStyles={{ marginBottom: 30 }}>Inicia Sesión</ButtonNty>

            </View>
        }

      </View>
    </Background>
  );
};


export default memo(RegisterScreen);
