import React, { MutableRefObject } from 'react'
import { Animated, ColorValue, NativeSyntheticEvent, Platform, StyleSheet, TextInput, TextInputChangeEventData, TextInputEndEditingEventData, TextInputFocusEventData, TextInputProps, TouchableOpacity, View, ViewStyle } from 'react-native'
import { IconButton } from 'react-native-paper'
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'




interface NtyInputExtraProps {

    /** Style that would be placed inside the wrapper of this custom InputText component. Use this for paddings and margins */
    contentContainerStyle?: ViewStyle,
    /** RGBA string color to be used as the tintColor when this input field is focused. __Override this animation with `placeHolderTextColor` property for a fixed color.__ */
    placeHolderTextColorActive?: string
    /** RGBA string color to be used as the tintColor when this field is focused. __Override this animation with `placeHolderTextColor` property for a fixed color.__*/
    placeHolderTextColorInactive?: string,

    /** Color to be used for the bottom line of the inputText, by default, the color should animate to be the same as the placeHolder depending on input focus state. Override the default behaviour
     * with this property, but the bottom line color will be fixed then.
      */
    borderBottomColor?: ColorValue,

    /**
     * If you pass in this prop, youll get an icon at the right end of the text input, the passed value will be used directly to the icon 'name' prop.
     */
    icon?: IconSource,
    /**
     * Function to be executed each time the icon box for this particular textInput gets clicked. You'll need to pass the 'icon' prop to this textinput to make this work.
     */
    onIconClick?: () => void
}

type NtyInputProps = Omit<TextInputProps, "ref"> & NtyInputExtraProps & {
    /**
    * Use the forwardedRef field to pass in a reference that will be pointing to these instance of NtyInputText without losing the reference
    */
    forwardedRef?: MutableRefObject<TextInput>
}

function NtyTextInputWrapped(props: NtyInputProps
) {

    const theme = useAppTheme()

    const translateYInitialValue = Platform.OS == 'web' ? 18 : 25;
    const translateYFinalValue = -25;

    const translateYAnimation = React.useRef(new Animated.Value(translateYInitialValue)).current;

    const inputRef = props.forwardedRef ?? React.useRef<TextInput>(null);
    const inputTextSize = React.useRef(props.defaultValue ? props.defaultValue.length : 0);

    const [finalColor, initialColor] = [props.placeHolderTextColorActive ? props.placeHolderTextColorActive : 'rgba(69, 73, 79, 1)', props.placeHolderTextColorInactive ? props.placeHolderTextColorInactive : 'rgba(160, 169, 184, 1)'];


    const colorAnimation = translateYAnimation.interpolate({
        inputRange: [translateYFinalValue, translateYInitialValue],
        outputRange: [finalColor, initialColor]
    })

    const fontSizeAnimation = translateYAnimation.interpolate({
        inputRange: [translateYFinalValue, translateYInitialValue],
        outputRange: [12, 15]
    })



    function onChangeTextInternal(text: string) {

        const hasText = text ? text.length > 0 : false;

        if (text)
            inputTextSize.current = text.length

        Animated.timing(
            translateYAnimation,
            {
                toValue: hasText ? translateYFinalValue : translateYInitialValue,
                duration: 100,
                useNativeDriver: false
            }
        ).start()

    }

    function onChangeTextInternalEvent(event: NativeSyntheticEvent<TextInputChangeEventData>) {

        const hasText = event.nativeEvent && event.nativeEvent.text ? event.nativeEvent.text : false;

        if (hasText)
            inputTextSize.current = hasText.length

        Animated.timing(
            translateYAnimation,
            {
                toValue: hasText ? translateYFinalValue : translateYInitialValue,
                duration: 100,
                useNativeDriver: false
            }
        ).start()

    }


    function onFocusInternal(event: NativeSyntheticEvent<TextInputEndEditingEventData>) {

        const hasText = event.nativeEvent.text ? event.nativeEvent.text.length > 0 : false;
        if (!hasText) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYFinalValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }
    }


    function onEndEditingInternal(event: NativeSyntheticEvent<TextInputEndEditingEventData>) {

        const hasText = event.nativeEvent.text ? event.nativeEvent.text.length > 0 : false;
        if (!hasText) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYInitialValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }
    }

    function onBlurInternal(event: NativeSyntheticEvent<TextInputFocusEventData>) {

        const textSize = inputTextSize.current
        if (textSize == 0) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYInitialValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }
    }

    React.useEffect(() => {

        const text = props.value ? props.value : props.defaultValue ? props.defaultValue : false;

        if (text) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYFinalValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }

    }, [inputRef, inputRef.current])





    const defaultStyle = StyleSheet.create({
        container: {
            flexDirection: 'row'
        },
        boxStyle: {
            marginVertical: 15,
            paddingVertical: 10,
            flex: 1
        },
        textStyle: {
            color: theme.colors.text_primary[400],
            width: "100%"
        },
        placeHolderText: {
            paddingLeft: 5,
            fontWeight: '400'
        }
    })

    return (
        <View style={defaultStyle.container}>

            <TouchableOpacity style={props.contentContainerStyle ?? defaultStyle.boxStyle} onPress={() => inputRef.current.focus()} activeOpacity={1}>

                {props.placeholder && <Animated.Text
                    style={[
                        {
                            transform: [{ translateY: translateYAnimation }],
                            fontSize: fontSizeAnimation,
                            color: props.placeholderTextColor ?? colorAnimation
                        },
                        defaultStyle.placeHolderText
                    ]}>{props.placeholder}</Animated.Text>}
                <TextInput
                    ref={inputRef}
                    {...props}
                    placeholder={null}
                    onChange={(text) => { onChangeTextInternalEvent(text); }}
                    style={[props.style, defaultStyle.textStyle]}
                    selectionColor={props.selectionColor ?? defaultStyle.textStyle.color}
                    onChangeText={(text) => { onChangeTextInternal(text); props.onChangeText ? props.onChangeText(text) : null }}
                    onFocus={(event) => { onFocusInternal(event); props.onFocus ? props.onFocus(event) : null }}
                    onBlur={(event) => { onBlurInternal(event); props.onBlur ? props.onBlur(event) : null }}
                    onEndEditing={(event) => { onEndEditingInternal(event); props.onEndEditing ? props.onEndEditing(event) : null }}
                />
                <Animated.View style={{ position: 'absolute', bottom: 0, height: 1, width: "100%", backgroundColor: props.borderBottomColor ?? colorAnimation }} />
            </TouchableOpacity>
            {props.icon ? <View style={{ justifyContent: 'center', alignItems: 'center'}}>
                <IconButton icon={props.icon} size={20} color={props.style && props.style.color ? props.style.color : defaultStyle.textStyle.color} onPress={props.onIconClick} />
            </View> : null}

        </View>
    )
}


/**
 * Using {@link React.forwardRef React Fordward Ref} and NtyTextInputWrapped component in order to fordward the `MutableRefObject` to the downside component without losing it's reference in memmory,
 *
 * TextInputProps `ref` property needs to be ommited just to avoid using it instead the desired one of {@link NtyInputProps NtyInputProps} under the hood.
 */
const NtyTextInput = React.forwardRef((props: Omit<TextInputProps, "ref"> & NtyInputExtraProps, ref: React.MutableRefObject<TextInput>) => {
    return <NtyTextInputWrapped {...props} forwardedRef={ref} />
})

export default NtyTextInput;
