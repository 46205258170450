import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { memo, MutableRefObject, useState } from 'react';
import { Animated, FlatList, GestureResponderEvent, ListRenderItemInfo, Platform, RefreshControl, useWindowDimensions, View, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Button } from 'react-native-paper';
import { Article } from 'systemDomain';
import ArticleApi from '../../../../api/ArticleApi';
import { getNumColumnsFlatlist, openNewTabLink } from '../../../../core/utils';
import { useFlatListControls } from '../../../hooks/useFlatListControls';
import LoadMoreButton from '../../LoadMoreButton';
import TextNty from '../../nty/text/TextNty';
import TitleNty from '../../nty/title/TitleNty';
import ArticleCard from '../cards/ArticleCard';
import { ArticleCardProps } from '../cards/ArticleCard/types';
import { styles } from './styles';



type ArticleListProps<T> = {
    articles: T[];
    loadMoreDisabled: boolean;
    categoryKey?: string;
    onLoadMore?: () => void,
    showMeasuresInfo?: boolean;
    /**
     * Indicates the number of columns that this Flatlist must render per row in the available space.
     *
     * The size of the items is represented as a fraction (factor) of the available size, so
     * the developer can decide what type of grid is going to be printed from outside of this component.
     *
     * Example:
     *
     *   | Columns | Factor |
     *   | ------ | ------ |
     *   | 1 | 1/1 |
     *   | 2 | 1/2 |
     *   | 3 | 1/3 |
     *   | 4 | 1/4 |
     *
     * Default value is calculated with the screen width see __getNumColumnsFlatlist__ from utils.ts:
     *
     * ```js
     * const {width} = useWindowDimensions();
     * ...
     * numCols = getNumColumnsFlatlist(width);
     * ```
     *
     *
     * __This property will be used in `FlatList.numColumns`:__
     *
     * Multiple columns can only be rendered with horizontal={false} and will zig-zag like a flexWrap layout. Items should all be the same height - masonry layouts are not supported.
     *
     *
     */
    numColumns?: number,
    /**
    * Whether or not to make this FlatList horizontal.
    * __Setting this to 'true' sets the 'numColumns' property value to 1, whatever it was.__
    */
    horizontal?: boolean,
    /**
     * Only used when 'horizontal' is set to true.
     * Callback to execute when 'Ver más' button is pressed.
     * @returns
     */
    onViewMorePressed?: () => void,
    // /**
    //  * Set this parameter in order to fix the height of each individual item in the list.
    //  */
    // fixedItemHeight?: number,
    /**
    * Size of the width of each card displayed horizontally. Default is 300.
    * __This value takes no effect when 'horizontal' is set to false.__
    */
    horizontalCardWidth?: number,
    /**
     * Function that will be executed each time the refresh control gets launched.
     */
    onRefresh?: () => void,
    onScroll?: (event) => void,
    /**
     * Function to use instead of the default one in order to print FlatList Items in a different way.
     *
     * Default behaviour takes T as {@link Article} and renders each item in {@link articleCard},
     * setting the onItemPress, onOptionsPressed parameters accordingly.
     *
     * __This function handles the 'onItemPress' event, so youll have to implement that mechanism too.__
     */
    renderItem?: (info: ListRenderItemInfo<T>) => React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    onItemPress?: (item: T, event?: GestureResponderEvent) => void,
    /**
     * Pass in a function in order to rewrite the default behaviour for article options.
     * Take in account that you'll have to implement the interface behaviour for when pressing in the options, aka;
     */
    onOptionsPressed?: (item: T, event?: GestureResponderEvent) => void,
    /***
     * Use this flag to manually disable the ArticleOptions default mechanism, so the options dots will not be visible on each card.
     * Default is false.
     */
    hideOptions?: boolean,
    contentContainerStyle?: ViewStyle,
    /**
     * When true, the scroll view stops on the next index (in relation to scroll position at release) regardless of how fast the gesture is. This can be used for pagination when the page is less than the width of the horizontal ScrollView or the height of the vertical ScrollView.
     */
    disableIntervalMomentum?: boolean,
    forwardedRef?: MutableRefObject<FlatList>,
    articleCardProps?: Partial<ArticleCardProps>,
    header?: React.ComponentType<any> | Animated.WithAnimatedObject<React.ReactElement<any, string | React.JSXElementConstructor<any>>>,
    listEmptyComponent?: React.ComponentType<any> | Animated.WithAnimatedObject<React.ReactElement<any, string | React.JSXElementConstructor<any>>>
};

const ArticleListNewsToYouLight = (
    {
        articles,
        onLoadMore,
        categoryKey,
        loadMoreDisabled,
        showMeasuresInfo = false,
        numColumns,
        horizontal = false,
        // fixedItemHeight = Platform.OS == 'web' ? 450 : null,
        horizontalCardWidth = 350,
        onRefresh,
        onScroll,
        onItemPress,
        onOptionsPressed = undefined,
        onViewMorePressed = undefined,
        hideOptions = false,
        header = null,
        listEmptyComponent = null,
        contentContainerStyle,
        disableIntervalMomentum = false,
        forwardedRef = null,
        articleCardProps = null
    }: ArticleListProps<Article>) => {

    const [refreshing, setRefreshing] = React.useState(false);
    const flatListRef: MutableRefObject<FlatList> = forwardedRef ?? React.useRef(null);

    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    // const { open: openArticleOptions } = useArticleOptionsStore.getState()

    const defaultItemHorizontalPadding = 5;
    const defaultRowSeparatorHeightInternal = 25;
    const rowHorizontalPadding = 5;

    const numColsI = horizontal ? 1 : numColumns ? numColumns : getNumColumnsFlatlist(width);
    // const numColsI = getNumColumnsFlatlist(width);

    const ITEM_WIDTH: number = horizontal ? horizontalCardWidth : (width - (rowHorizontalPadding * 2) - (defaultItemHorizontalPadding * numColsI)) / numColsI;
    const ITEM_HEIGHT = ITEM_WIDTH * (Platform.OS == 'web' ? horizontal ? 1.35 : 1.65 : 0.9);

    const itemStyle = {
        width: ITEM_WIDTH,
        height: ITEM_HEIGHT,
        paddingHorizontal: defaultItemHorizontalPadding,
        borderWidth: showMeasuresInfo ? 1 : 0
    }

    const viewArticleAction = async (article: Article) => {
        ArticleApi.viewArticleAction(article);
    }

    const articlePressInternal = async (article: Article, event?: GestureResponderEvent) => {
        if (Platform.OS == 'web' && article?.webview) {
            openNewTabLink(article?.url);
        } else {
            const articleId = article?._id;
            navigation.navigate('viewArticle', { id: articleId, article: article });
        }
        viewArticleAction(article)
    }

    const getItemLayoutInternal = React.useCallback((data, index) => {

        return (
            {
                length: ITEM_HEIGHT,
                offset: (ITEM_HEIGHT + defaultRowSeparatorHeightInternal) * (index / numColsI),
                index
            }
        )

    }, [ITEM_HEIGHT, numColsI])

    const itemPress = (article: Article, event?: GestureResponderEvent) => onItemPress ? onItemPress(article, event) : articlePressInternal(article, event);
    const keyExtractor = (item: Article, index: number) => ((item?._id) + '_' + index);


    const defaultFooterComponent = () => onLoadMore && !loadMoreDisabled && articles?.length > 0 ? <LoadMoreButton onLoadMore={() => { onLoadMore() }} /> : null;
    const defaultRowSeparatorComponent = () => <View style={{ height: defaultRowSeparatorHeightInternal }}></View>;


    const renderItemInternal: (info: ListRenderItemInfo<Article>) => React.ReactElement<any, string | React.JSXElementConstructor<any>> =
        (info: ListRenderItemInfo<Article>) => {


            return (
                <View style={itemStyle} key={info?.item?._id}>
                    <ArticleCard
                        article={info.item}
                        onPress={itemPress}
                        {...articleCardProps}
                    />
                </View>

            );

        }


    const showMeasuresInfoComponent = () => {
        return (
            <View style={{ width: "100%" }}>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Screen Width:  </TextNty> {width}</TextNty>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Num Columns:  </TextNty> {numColsI}</TextNty>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Item Horizontal Padding:  </TextNty> {defaultItemHorizontalPadding}</TextNty>
                <TextNty style={{ marginBottom: 10 }}><TextNty style={{ fontWeight: 'bold' }}>Content Horizontal padding:  </TextNty> {rowHorizontalPadding}</TextNty>
                <TextNty><TextNty style={{ fontWeight: 'bold' }}>Item Width:  </TextNty> {ITEM_WIDTH}</TextNty>
                <TextNty><TextNty style={{ fontWeight: 'bold' }}>Item Height:  </TextNty> {ITEM_HEIGHT}</TextNty>
            </View>
        )
    }

    const [currentOffset, setCurrentOffset] = useState(0);

    const { handlePrev, handleNext } = useFlatListControls(flatListRef, ITEM_WIDTH, currentOffset, setCurrentOffset, articles, rowHorizontalPadding);

    const defaultRefreshControl = () =>
        <RefreshControl
            progressViewOffset={20}
            refreshing={refreshing}
            onRefresh={onRefresh}
        />

    const renderFlatList = () => (<Animated.FlatList
        ref={flatListRef}
        data={articles}
        getItemLayout={getItemLayoutInternal}
        ListHeaderComponent={header ? header : showMeasuresInfo ? showMeasuresInfoComponent() : null}
        ListEmptyComponent={listEmptyComponent}
        maxToRenderPerBatch={15}
        fadingEdgeLength={10}
        renderItem={renderItemInternal}
        ItemSeparatorComponent={defaultRowSeparatorComponent}
        key={numColsI + '_'}
        keyExtractor={keyExtractor}
        numColumns={numColsI}
        // columnWrapperStyle={{borderWidth: showMeasuresInfo ? 1 : null}}
        onScroll={
            onScroll ?
                typeof onScroll == 'function' ?
                    (event) => { onScroll(event); }
                    :
                    (event) => { return onScroll; }
                : null}
        onEndReached={loadMoreDisabled ? null : Platform.OS == 'web' ? null : onLoadMore ? onLoadMore : null}
        onEndReachedThreshold={0.3}
        horizontal={horizontal}
        contentContainerStyle={[{ paddingHorizontal: rowHorizontalPadding, flexShrink: null, ...contentContainerStyle }, showMeasuresInfo ? { borderWidth: 2, borderColor: 'red' } : null]}
        ListFooterComponent={defaultFooterComponent}
        showsVerticalScrollIndicator={Platform.OS != 'web'}
        onRefresh={onRefresh ? onRefresh : null}
        refreshing={onRefresh ? refreshing : null}
        refreshControl={onRefresh ? defaultRefreshControl() : null}
        disableIntervalMomentum={disableIntervalMomentum}
        columnWrapperStyle={numColsI > 1 ? {justifyContent: 'center'}: null}
    />)
    return (
        horizontal ?
            (<View style={{ position: "relative", justifyContent: "center" }}>
                {renderFlatList()}
                <View style={styles.footerContainer}>

                    <View style={styles.seeMoreContainer}>

                        <TouchableOpacity onPress={onViewMorePressed ? onViewMorePressed : () => { navigation.navigate('newspaperscategory', { category: categoryKey }) }} >
                            <TitleNty fontSize={15} type={'thin'} style={{ textDecorationLine: 'underline' }}>Ver más</TitleNty>
                        </TouchableOpacity>
                    </View>
                    {width > 600 && <View style={styles.positionControls}>

                        <View style={styles.controlsContainer}>
                            <Button onPress={handlePrev} >
                                <MaterialCommunityIcons
                                    name="chevron-left"
                                    color={"red"}
                                    size={25} /> </Button>
                            <Button onPress={handleNext}>
                                <MaterialCommunityIcons
                                    name="chevron-right"
                                    color={"red"}
                                    size={25} /> </Button>
                        </View>
                    </View>}
                </View>
            </View>) : renderFlatList()
    );
}

/**
 * Using the WrappedTab component in order to fordward the `MutableRefObject` to the downside component without losing it's reference in memmory
 */
const WrappedArticleList = React.forwardRef((props: ArticleListProps<Article>, ref: React.MutableRefObject<FlatList>) => {
    //In this case we dont use the 'ref' property, as the MutableRefObject created from the outside is going to be passed in the 'forwardedRef' property
    return <ArticleListNewsToYouLight {...props} forwardedRef={props.forwardedRef} />
})

export default memo(WrappedArticleList)
