import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';

import { Dimensions, NativeSyntheticEvent, Platform, StyleProp, StyleSheet, TextInputFocusEventData, TextInputSubmitEditingEventData, TextStyle, useWindowDimensions, View, ViewStyle } from 'react-native'
import { Searchbar } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { elevationShadowStyle } from '../../../../core/theme';
import { getAppTheme, useAppTheme } from '../../../../core/theme/ThemeBuilder';
import { fixed_colors } from 'src/core/theme/colors';
// import { theme } from '../../../../core/theme';





export type NtySearchBarProps = {

    placeholder?: string,
    value: string,
    onChangeText?: ((text: string) => void) & ((query: string) => void)
    inputStyle?: StyleProp<TextStyle>
    style?: StyleProp<TextStyle> & StyleProp<ViewStyle>
    contentContainerStyle?: StyleProp<ViewStyle>
    onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    clearIcon?: IconSource
    selectTextOnFocus?: boolean,
    onSubmitEditing?:(e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void
    /**
     * Use this to rewrite the default action being performed in the 'clear' action of the searchBar
     */
    clearIconPress?: () => void

};

function NtySearchBar(props: NtySearchBarProps) {

    // const width = Dimensions.get('window').width;

    const theme = React.useMemo(getAppTheme, [])

    const styles = React.useMemo( () => StyleSheet.create({
        searchbar: {
            // borderWidth: 1,
            flex: 1,
            // borderRadius: 20,
            // flexDirection: 'row',
            // backgroundColor: theme.colors.background[300],
            backgroundColor: theme.colors.background[300],
            borderWidth: 1,
            borderColor: theme.colors.background[700],
            borderRadius: theme.dimensions.roundness.low,
            // height: "100%",
            // flex: 1,
        },
    }), [theme])

    const defaultClearIcon = React.useCallback(() => <MaterialCommunityIcons name="close" size={25} color={theme.colors.text_primary[200]} onPress={props.clearIconPress ? props.clearIconPress : () => props.onChangeText('')}/>, [theme, props.clearIconPress])

    return (
        <View style={[{

            flexDirection: 'row',
            zIndex: 10,


        }, props.contentContainerStyle]}>
            <Searchbar
                placeholder={props.placeholder ?? "Introduce texto a buscar"}
                placeholderTextColor={theme.colors.text_primary.muted}
                onChangeText={props.onChangeText}
                selectTextOnFocus={props.selectTextOnFocus}
                onSubmitEditing={props.onSubmitEditing}
                value={props.value}
                inputStyle={props.inputStyle ?? [{ fontSize: 16, textAlign: 'center' }, theme.fonts.default.regular]}
                style={[ styles.searchbar, props.style, elevationShadowStyle(1.5)]}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                icon={() => <MaterialCommunityIcons name={'magnify'} size={35} color={fixed_colors.grey[300]} />}
                clearIcon={props.clearIcon ? props.clearIcon : defaultClearIcon}
            />
        </View>
    )
}

export default NtySearchBar
