import React, { memo } from 'react';
import { Platform } from 'react-native';
import {FAB} from 'react-native-paper';

type Props = {
  onLoadMore: Function;
};

const LoadMoreButton = ({onLoadMore}: Props) => {
  return (
    Platform.OS=='web'?
    <FAB
      style={{backgroundColor:'white', borderWidth:1, borderColor:'rgba(0,0,0,0.1)', alignSelf:'center', justifyContent:'center', marginTop:10}}
      small
      icon='plus'
      onPress={()=>{onLoadMore()}}
    />:null
  )
};

export default memo(LoadMoreButton);