import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { List } from 'react-native-paper'
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens'

function SuperUserOptionsList() {

  const navigation = useNavigation<StackNavigationProp<ScreensParamsList>>()

  return (
    <List.AccordionGroup>
        <List.Accordion title="Noticias" id={'news'}>
            <List.Item title="Crear Noticia" onPress={() => navigation.push('superUserCreateArticle')}/>
        </List.Accordion>
    </List.AccordionGroup>
  )
}

export default SuperUserOptionsList
