import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import { DefinedSection, DefinedSectionTypesNames, EnterpriseFeedV2, EnterpriseModel, ProfileModel, SessionInfo, SubscriptionInterface } from "systemDomain";
import { GetState, SetState, StoreApi } from "zustand";
import { devtools, persist } from "zustand/middleware";
import ActionApi, { ACTION_NAMES } from "../api/ActionApi";
import AuthApi from "../api/AuthApi";
import EnterpriseApi from "../api/EnterpriseApi";
import ProfileApi from "../api/Profile.api";
import SubscriptionApi from "../api/SubscriptionApi";
import Session from "../models/Session";
import { AuthDBUser, SessionUser, UserTypes } from "../models/user";
import { useAppTrialStore } from "./appTrialStore";

import { NavigationContainerRef } from "@react-navigation/native";
import { SessionApi } from "src/api/SessionApi";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";
import { compareObjects, containsSame } from "../core/utils";

export const APP_SESION_STORE_KEY = 'appSessionStore';

const loadSubscriptions: (user: AuthDBUser | SessionUser) => Promise<SubscriptionInterface[]> = async (user) => {
    const subscriptions = await SubscriptionApi.all(user);
    return subscriptions && subscriptions.subscriptionArr ? subscriptions.subscriptionArr.map((sub) => sub.props) : null;

}

export type AppSessionSnapshot = {

    user: SessionUser,
    profile: ProfileModel,
    session: Session

}

export enum LoginErrors {

    EMAIL_DOESNT_EXISTS = 'email_doesnt_exists',
    USER_PASS_WRONG = 'user_pass_wrong',
    CONNECTIVITY = 'connectivity_error'

}


export enum RegisterErrors {

    EMAIL_ALREADY_EXISTS = 'email_already_exists',
    CONNECTIVITY = 'connectivity_error'

}

export const APP_SESSION_CONSTANTS = {
    APP_SESSION_STORE_KEY: 'appSessionStore',
    /**
     * This constant is used for versionning in order to make different versions of the appSessionStore incompatible with each other.
     * When the app starts up, it checks the version of the appSessionStore and if its different from the current version, it'll clear the store and start a new one.
     */
    VERSION: '1.0.1'
}


export type AppSessionStoreType = {

    /**
     * Version of the appSessionStore
     */
    version: string,

    navigatorRef: NavigationContainerRef<ScreensParamsList>,
    setNavigatorRef: (ref: NavigationContainerRef<ScreensParamsList>) => void,

    /**
     * Date ISO String representing the last time the store was updated.
     */
    updated: string,
    /**
     * Date ISO String representing moment the store was completely initialized, that is, the last time the store changed from ready = false to ready = true
     */
    created: string,
    /**
     * Flag that indicates the store is ready to use at this very moment.
     * The store has passed through the 'init()' method almost once. aka 'been propperly created'.
     * This flag __must be used in screens that use the appSessionStore__ in a way (or time) where the store might not be fully well builded.
     * That is, the screens closer to the start point of the execution of the ap, aka: {@link Main}, {@link App}
     */
    ready: boolean,

    /**
     * Flag that indicates the current logged in session is 'enterprise' type, that implies the retrieved profile from database has the enterprise flag informed.
     * If true, 'enterprise' and 'enterpriseFeeds' fields should be informed
     */
    isEnterprise: boolean,

    /**
     * The enterprise data for the session profile. This will only be informed if `isEnteprise` field is true for this session instance
     */
    enterprise: EnterpriseModel,

    // /**
    //  * Instance of a theme that should be applied by force, this should be used by the useAppTheme hook in order to use it if its informed.
    //  */
    // forcedTheme: NtyTheme,

    /**
     * The feeds associated to the enterprise of the session profile, this data is relevant in order to properly build the app home page
     */
    enterpriseFeeds: EnterpriseFeedV2[],

    /**
     * This flag indicates whether or not this instance of AppSessionStore has been created through re-hydration from {@link AsyncStorage}
     *
     * _Internal flag that should only be used by AppSessionStore implementation._
     *
     */
    _hasHydrated: boolean,

    /**
     * Information about the 'end user' thats using the App.
     *
     * Stores user data related to the 'personality' of the user thats using the application:
     *   - email
     *   - premium
     *   - token
     *   - type
     *   - status
     *   - rights
     *   - payments
     *   - subscriptions
     *
     */
    user: SessionUser,
    // /**
    //  * User calculated tabs for homescreen
    //  * @deprecated tabs builded in app session store are not used anymore
    //  */
    // userTabs: TabsDefinition[],
    // /**
    //  * @deprecated tabs builded in app session store are not used anymore
    // */
    // buildMobileTabs: (apply?: boolean) => Promise<TabsDefinition[]>,
    // /**
    //  * @deprecated tabs builded in app session store are not used anymore
    // */
    // buildWebTabs: (apply?: boolean) => Promise<TabsDefinition[]>,
    // /**
    //  * @deprecated tabs builded in app session store are not used anymore
    // */
    // buildTabs: (apply?: boolean) => Promise<TabsDefinition[]>,
    /**
     * Information about the 'end user' thats using the App.
     *
     * Stores user data related to the 'profile' (user app-related data stored in database) of the user thats using the application. check {@link ProfileModel}
     *
     */
    profile: ProfileModel | null,

    /**
     * This class is used to define and document the types used inside the session store session prop.
     *
     * Stores user data related to the execution on the current device of the user thats using the application:
     *   - Device Info
     *   - Session Start Time
     *   - etc
     */
    session: Session,

    // /**
    //  * This method should be only used at application startup.
    //  *
    //  * __Do not use from the outside of the AppSessionStore implementation__
    //  * @deprecated use `build` instead
    //  */
    // init: () => Promise<{ user: SessionUser, session: Session }>,
    /**
     * Newer version of the init method, that also returns the profile data.
     * This method should be only used at application startup / login
     *
     */
    build: () => Promise<{ user: SessionUser, session: Session, sessionInfo: SessionInfo }>,
    sections: DefinedSection<DefinedSectionTypesNames.FEED | DefinedSectionTypesNames.SECTION_ARTICLES>[],
    setHasHydrated: (to: boolean) => void,
    setReady: (to: boolean) => void,
    setProfile: (newProfile: ProfileModel) => void,
    setEnterprise: (enterprise: EnterpriseModel) => void,
    // forceTheme: (theme: NtyTheme) => void,
    isPremium: () => boolean,
    isGuest: () => boolean,
    /**
     * Location is not ye implemented.
     */
    getLastKnownLocation?: () => any,

    // Login Methods
    /**
     *
     * This method builds the appSession for the given accepted credentials (AuthUser) and ProfileData.
     *
     * - Loads user payment subscriptions
     * - Builds appSession fields:
     *   - Session
     *   - User
     * - Creates some session actions:
     *   - Login
     *   - Session Start
     * - Sets the new state of the store.
     *
     * The builded session will be a non-guest session.
     * The builded session will not include any active trial. In fact any existing trial will be cancelled;
     *
     * @param authUser An {@link AuthDBUser} well formed object retrieved from AuthServer
     * @param profile An {@link ProfileModel} well formed model retrieved from the API
     */
    login: (authUser: AuthDBUser, profile: ProfileModel) => Promise<{ user: SessionUser, profile: ProfileModel, session: Session }>,
    /**
     * This method performs all the necessary operations, given an username and password string parameters, to
     * try to verify those credentials against the authServer and then fetch the database entities associated to the account
     * that'll be necessary to build the session information.
     *
     * Once its all done, just makes use of the {@link AppSessionStoreType.login} method to build the session for the given credentials.
     *
     * @param username the username string. By now, should be the email.
     * @param password the user password string.
     * @returns A Promise that resolves whenever all the login steps got completed, so you can respond properly at the time the action ends
     *
     * @throws any of {@link LoginErrors} specific errors if an expected error situation occurs.
     * .
     */
    loginUserPassword: (userName: string, password: string) => Promise<{ user: SessionUser, profile: ProfileModel, session: Session }>,
    /**
     * This method performs all the necessary operations, to reset the current session to a brand new Guest Session.
     *
     * - First of all, sets sessionStore ready to false, to indicate that session is working.
     * - Then creates a new Guest User and a Session from it
     * - And sets the new state of the appSessionStore
     * - Creates a logout action with the last version of the user and session data.
     *
     */
    logout: () => Promise<void>,
    /***
     * This method performs all the necessary operations in order to register a new user in the nty system and also starts a new session
     * in this app instance for that user if it got created sucessfully.
     *
     * - Creates the Authuser
     * - Creates the Profile
     * - Calls 'get().login(user, profile)' method of the store in order to perform the login
     *
     * @returns a promise with the Created user info
     *
     * @throws any of {@link RegisterErrors} specific errors if an expected error situation occurs.
     *
     */
    register: (email: string, password: string, enterprise?: string) => Promise<{ user: SessionUser, profile: ProfileModel, session: Session }>,

}

const sessionAction = async (actionName: string, user: SessionUser, session: Session, profile: ProfileModel = null, mergeData?: any) => {

    ActionApi.createFrom({ name: actionName, profileEmail: user.email, userType: user.type, sessionId: session.id, enterprise: profile?.props.enterprise ?? null, data: { ...mergeData } });

}



/**
 * Fetches enterprise data for the given profile and performs some operations with the given set function paramenter for the zustand store
 * @param profile
 * @param set
 * @param get
 */
const enterpriseActions = async (profile: any, set?: SetState<AppSessionStoreType>, get?: GetState<AppSessionStoreType>) => {


    const prof = profile.props ? profile.props : profile

    const enterpriseKey = prof.enterprise;
    const lastSession = get()

    let update: any = { isEnterprise: true };

    function isEmailInOwners(feed, email) {
        if (!feed || !feed.owners || !Array.isArray(feed.owners)) {
            //The feed is unrestricted, as it has no owners field
            return true;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        for (const owner of feed.owners) {
            if (emailRegex.test(owner)) {
                // Si es un correo, comparación exacta de correo electrónico
                if (email === owner) {
                    return true;
                }
            } else {
                try {
                    const pattern = new RegExp(owner);
                    // Comprobar si el correo electrónico coincide con el patrón
                    if (pattern.test(email)) {
                        return true;
                    }
                } catch (error) {
                    // console.error(`Patrón de correo electrónico no válido en 'owners': ${owner}`);
                }
            }
        }

        return false;
    }

    try {

        const enterprise = new EnterpriseModel(await EnterpriseApi.getEnterpriseByKey(enterpriseKey))
        if (!compareObjects(enterprise, lastSession?.enterprise?.props)) {
            update.enterprise = enterprise
        }
        let feeds: EnterpriseFeedV2[] = []
        if (enterprise && enterprise.props.active) {

            feeds = await EnterpriseApi.getEnterpriseFeedsByKey(enterprise.props.key)

            // @ts-ignore
            feeds = feeds.filter((feed) => feed.visible != false).filter(feed => isEmailInOwners(feed, prof.email))

            if (!containsSame(feeds, lastSession?.enterpriseFeeds ?? [])) {
                update.enterpriseFeeds = feeds;
            }

            if (Object.keys(update).length > 0) {
                set ? set(update) : null
            } else {
                console.log('no need to update enterprise data')
            }

            return { enterprise, feeds }
        } else {
            return null;
        }
    } catch (e) {
        console.error(e)
        return null;
    }



}

const updateProfileAndSections = (update, sessionInfo, lastSession) => {
    if (!compareObjects(sessionInfo.profile, lastSession.profile)) {
        update.profile = new ProfileModel(sessionInfo.profile);
    }
    if (!compareObjects(sessionInfo.sections, lastSession.sessionSections)) {
        update.sessionSections = sessionInfo.sections;
    }
};



const AppSessionStore: (set: StoreApi<AppSessionStoreType>['setState'], get: StoreApi<AppSessionStoreType>['getState']) => AppSessionStoreType =
    (set, get) => ({
        version: APP_SESSION_CONSTANTS.VERSION,
        navigatorRef: null,
        setNavigatorRef: (ref: NavigationContainerRef<ScreensParamsList>) => {
            set({ navigatorRef: ref })
        },
        updated: null,
        created: null,
        ready: false,
        user: SessionUser.build(UserTypes.GUEST),
        session: null,
        profile: null,
        enterprise: null,
        enterpriseFeeds: null,
        isEnterprise: false,
        // forcedTheme: null,
        _hasHydrated: false,
        sections: null,
        setHasHydrated: (to: boolean) => {
            set({ _hasHydrated: to });
        },
        setReady: (to: boolean) => {
            set({ ready: to });
        },
        setEnterprise: (enterprise: EnterpriseModel) => {
            set({ enterprise: enterprise })
        },
        // forceTheme: (theme: NtyTheme) => {
        //     set({ forcedTheme: theme })
        // },
        setProfile: (newProfile: ProfileModel) => {
            set({ profile: newProfile, updated: new Date().toISOString() });
        },
        build: async () => {

            const startTime = Date.now()

            const lastSession = get();
            const trial = useAppTrialStore.getState().resumeTrial();

            if (!lastSession._hasHydrated) {
                console.log('Session hasnt been hydrated yet, no actions performed.');
                return;
            }
            // console.time('Session Info Fetching in')
            const startTime2 = Date.now()
            const sessionInfo = await SessionApi.build();
            console.log('Session Info Fetching in' + ((Date.now() - startTime2) / 1000) + 's')
            const update: any = {
                updated: new Date().toISOString(),
                created: new Date().toISOString(),
                ready: true
            };

            if (lastSession.created) {
                console.log('Session was already created!');
                const session = Session.from(lastSession.session);
                const user = SessionUser.from(lastSession.user);

                if (!user.isGuest()) {
                    const newSession = Session.build(user);
                    try {
                        update.user = user;
                        update.session = newSession;
                        update.enterprise = sessionInfo.enterprise ? new EnterpriseModel(sessionInfo.enterprise) : null
                        update.isEnterprise = update.enterprise ? true : false
                        update.enterpriseFeeds = sessionInfo.feeds
                        updateProfileAndSections(update, sessionInfo, lastSession);
                        set(update);
                    } catch (e) {
                        console.error('There was a problem while re-fetching app session data:', e);
                        console.warn('Built app session with the version of the last stored data.');
                        set({ user: user, session: newSession, updated: new Date().toISOString(), created: new Date().toISOString() });
                    }

                    sessionAction(ACTION_NAMES.SESSION_START, user, session);
                    console.log('Session builded in' + ((Date.now() - startTime) / 1000) + 's')
                    return { user, session: newSession, sessionInfo };
                } else {
                    const lastSessionDuration = session.getSessionDuration();
                    session.startTime = new Date();
                    console.log("Guest Session recovered at: ", session.startTime);

                    update.user = user;
                    update.session = session;
                    updateProfileAndSections(update, sessionInfo, lastSession);
                    set(update);

                    sessionAction(ACTION_NAMES.SESSION_RECOVERED, user, session, null, { lastStart: lastSession.session?.startTime, lastSessionDuration: lastSessionDuration });
                    console.log('Session builded in' + ((Date.now() - startTime) / 1000) + 's')
                    return { user, session, sessionInfo };
                }
            } else {
                console.log('No existing session in storage!');
                console.log('Building new session...');
                const initialUser = SessionUser.build(UserTypes.GUEST);
                const initialSession = Session.build(initialUser);

                set({
                    session: initialSession,
                    user: initialUser,
                    updated: new Date().toISOString(),
                    created: new Date().toISOString(),
                    ready: true,
                    sections: sessionInfo.sections,
                    profile: new ProfileModel(sessionInfo.profile)
                });

                sessionAction(ACTION_NAMES.SESSION_START, initialUser, initialSession);
                console.log('Session builded in' + ((Date.now() - startTime) / 1000) + 's')
                return { user: initialUser, session: initialSession, sessionInfo };
            }
        },
        // init: async () => {

        //     const lastSession = get();

        //     const trial = useAppTrialStore.getState().resumeTrial()

        //     // console.log('Initializing session from: ', lastSession);

        //     if (!lastSession._hasHydrated) {
        //         console.log('Session hasnt been hydrated yet, no actions performed.');
        //         return;
        //     }

        //     if (lastSession.created) {
        //         //If store is ready when initializing it means it has been recovered from a persited version.
        //         console.log('Session was already created!');
        //         const session = Session.from(lastSession.session)
        //         const user = SessionUser.from(lastSession.user);

        //         if (!user.isGuest()) {

        //             // If a normal user session gets recovered, restart a new session
        //             const newSession = Session.build(user);
        //             // reload profile and subscriptions from API
        //             let newProfileData;
        //             try {

        //                 newProfileData = await new ProfileApi(new ApiCaller(user.token)).getMyProfile();
        //                 if (newProfileData.props.enterprise) {
        //                     console.log('fetching enterprise data')
        //                     await enterpriseActions(newProfileData, set, get)
        //                 }
        //                 // user.payment.subscriptions = await loadSubscriptions(user);
        //                 // const tabs = await (Platform.OS == 'web' ? get().buildWebTabs(false) : get().buildMobileTabs(false));
        //                 set({ user: user, session: newSession, profile: newProfileData, updated: new Date().toISOString(), created: new Date().toISOString()/* , userTabs: tabs */ })
        //             } catch (e) {
        //                 console.error('There was a problem while re-fetching app session data:', e)
        //                 console.warn('Builded app session with the version of the last storaged data.')
        //                 set({ user: user, session: newSession, updated: new Date().toISOString(), created: new Date().toISOString(), ready: true })
        //             }

        //             sessionAction(ACTION_NAMES.SESSION_START, user, session, newProfileData)
        //             // Platform.OS == 'web' ? get().buildWebTabs() : get().buildMobileTabs();
        //             return { user, session: newSession }

        //         } else {

        //             // If a guest user session was recovered, dont refresh the session, just refresh the sesion start time
        //             const lastSessionDuration = session.getSessionDuration();
        //             // Refresh the new startTime
        //             session.startTime = new Date();
        //             console.log("Guest Session recovered at: ", session.startTime)


        //             set({ session: session, user: user, updated: new Date().toISOString(), ready: true/* , userTabs: tabs */ })
        //             sessionAction(ACTION_NAMES.SESSION_RECOVERED, user, session, null, { lastStart: lastSession.session?.startTime, lastSessionDuration: lastSessionDuration })
        //             // Platform.OS == 'web' ? get().buildWebTabs() : get().buildMobileTabs();
        //             return { user, session }

        //         }

        //     } else {
        //         console.log('No existing session in storage!');
        //         console.log('Building new session...');
        //         const initialUser: SessionUser = SessionUser.build(UserTypes.GUEST);
        //         const initialSession: Session = Session.build(initialUser);

        //         console.log(initialUser);
        //         console.log(initialSession);

        //         // No need to check a trial resume, as the session is brand new
        //         // const tabs = await (Platform.OS == 'web' ? get().buildWebTabs(false) : get().buildMobileTabs(false));
        //         set({ session: initialSession, user: initialUser, updated: new Date().toISOString(), created: new Date().toISOString(), ready: true/* , userTabs: tabs */ })
        //         sessionAction(ACTION_NAMES.SESSION_START, initialUser, initialSession)
        //         return { user: initialUser, session: initialSession }
        //     }



        //     // // SplashScreen.hideAsync()
        //     // return;

        // },
        // userTabs: null,
        // buildWebTabs: async (apply: boolean = true) => {

        //     console.log('Building web tabs...')

        //     const session = get()

        //     const allNewspapers = useAppDataStore.getState().newspapers;

        //     let tabs: TabsDefinition[] = []

        //     if (session.isEnterprise) {
        //         const enterpriseTabs: TabsDefinition[] = session.enterpriseFeeds.map((s) => ({ label: s.name, key: s.name, name: s.name, data: { isEnterprise: true, feed: s } })).sort((a, b) => b?.data?.feed?.order - a?.data?.feed?.order)
        //         tabs = enterpriseTabs
        //         // tabs = enterpriseTabs.concat([{ label: 'Tendencias', key: 'Tendencias', name: 'Tendencias' }])
        //     } else if (session.isGuest()) {
        //         const sections = [...new Set<string>(allNewspapers.newspaperArr.map(n => n.props.section))]
        //         const orderedSections = sections//subscribedSections.concat(allSections.filter(s => subscribedSections.indexOf(s) == -1));
        //         const firstTabName = 'Todos';
        //         tabs = [firstTabName, 'Tendencias', ...orderedSections].map((s) => ({ label: s, key: s, name: s }))
        //     } else {
        //         // No enterprise user, nor guest (trial)

        //         useAlert.getState().alert(
        //             {
        //                 title: 'Hasta pronto!',
        //                 message: `Este usuario ha sido desactivado. Puedes ponerte en contacto con nosotros en team@thenewstoyou.com.\r\nPara más información encuéntranos en www.thenewstoyou.es`,
        //                 dismissable: false
        //             }).then((result) => {
        //                 useAlert.getState().navigator.navigate('logout', { goTo: 'login-select' })
        //             })

        //     }


        //     if (apply && !compareObjects(tabs, session.userTabs)) {
        //         console.log('replacing tabs...')
        //         set({ userTabs: tabs, updated: new Date().toISOString() })
        //     }
        //     return tabs


        // },
        // buildMobileTabs: async (apply: boolean = true) => {

        //     console.log('Building mobile tabs...')

        //     const session = get()

        //     const allNewspapers = useAppDataStore.getState().newspapers;


        //     let tabs: TabsDefinition[] = []

        //     if (session.isEnterprise) {

        //         const enterpriseTabs: TabsDefinition[] = session.enterpriseFeeds.map((s) => ({ label: s.name, key: s.name, name: s.name, data: { isEnterprise: true, feed: s } })).sort((a, b) => b?.data?.feed?.order - a?.data?.feed?.order)
        //         tabs = enterpriseTabs
        //         // tabs = enterpriseTabs.concat([{ label: 'Tendencias', key: 'Tendencias', name: 'Tendencias' }])

        //     } else if (session.isGuest()) {
        //         const sections = [...new Set<string>(allNewspapers.newspaperArr.map(n => n.props.section))]
        //         const orderedSections = sections//subscribedSections.concat(allSections.filter(s => subscribedSections.indexOf(s) == -1));
        //         const firstTabName = 'Todos';
        //         tabs = [firstTabName, 'Tendencias', ...orderedSections].map((s) => ({ label: s, key: s, name: s }))
        //     } else {
        //         // No enterprise user, nor guest (trial)

        //         useAlert.getState().alert(
        //             {
        //                 title: 'Hasta pronto!',
        //                 message: `Este usuario ha sido desactivado. Puedes ponerte en contacto con nosotros en team@thenewstoyou.com.\r\nPara más información encuéntranos en www.thenewstoyou.es`,
        //                 dismissable: false
        //             }).then((result) => {
        //                 useAlert.getState().navigator.navigate('logout', { goTo: 'login-select' })
        //             })

        //     }

        //     if (apply && !compareObjects(tabs, session.userTabs)) {
        //         console.log('replacing tabs...')
        //         set({ userTabs: tabs, updated: new Date().toISOString() })
        //     }
        //     return tabs

        // },
        // buildTabs: async (apply = true) => {

        //     return Platform.OS == 'web' ? await get().buildWebTabs(apply) : await get().buildMobileTabs(apply)

        // },
        // getHomeSections: async () => {

        //     const sections: Section<Article>[] = [];

        // },
        isPremium: () => {
            return get().user.isPremium()
        },
        isGuest: () => {
            const user = get().user
            if (!user) return false // if user is null, then it's not a guest

            return SessionUser.fromObject(user).isGuest();
        },
        getLastKnownLocation: () => {
            return get().session.geolocation;
        },
        login: async (user: AuthDBUser, profile: ProfileModel) => {

            if (!user || !profile) {
                throw new Error("All parameters are mandatory to build the session:  login = async (user: AuthDBUser, profile: ProfileModel) {...}");
            }

            try {

                // if (profile.props.enterprise) {
                //     await enterpriseActions(profile, set, get)
                // }
                // const subscriptions = await loadSubscriptions(user)
                const tobeUser = SessionUser.build(UserTypes.USER, user.token, user.email);
                const tobeSession = Session.build(tobeUser);

                sessionAction(ACTION_NAMES.LOGIN, tobeUser, tobeSession, profile)
                sessionAction(ACTION_NAMES.SESSION_START, tobeUser, tobeSession, profile)

                useAppTrialStore.getState().clearTrial()

                set({ user: tobeUser, session: tobeSession, profile: profile, ready: true, updated: new Date().toISOString(), created: new Date().toISOString() })
                return { user: tobeUser, profile, session: tobeSession }

            } catch (e) {
                if (e.message === 'Failed to fetch') {
                    throw new Error(LoginErrors.CONNECTIVITY, { cause: e });
                } else {
                    throw new Error('Unexpected error during login(user, profile)', { cause: e })
                }
            }

        },
        loginUserPassword: async (username: string, password: string) => {

            try {

                if (!await AuthApi.doMailExists(username)) {
                    throw new Error(LoginErrors.EMAIL_DOESNT_EXISTS);
                }
                const data = await AuthApi.login(username, password);

                const user: AuthDBUser = { email: data.email, token: data.token, type: data.type, status: data.status };
                // const profileApi = new ProfileApi(new ApiCaller(user.token));
                const profile = await ProfileApi.getMyProfile();
                return await get().login(user, profile)

            } catch (e) {
                if (e.message === 'Failed to fetch') {
                    throw new Error(LoginErrors.CONNECTIVITY, { cause: e });
                } else {
                    throw new Error(LoginErrors.USER_PASS_WRONG, { cause: e });
                }

            }
        },
        logout: async () => {

            const lastUser = get().user;
            const lastSession = get().session;

            set({ ready: false, profile: null });

            const initialUser: SessionUser = SessionUser.build(UserTypes.GUEST);
            const initialSession: Session = Session.build(initialUser);


            set({ enterprise: null, isEnterprise: false, enterpriseFeeds: null, sections: null, session: initialSession, user: initialUser, profile: null, updated: new Date().toISOString(), ready: true, created: new Date().toISOString() })
            sessionAction(ACTION_NAMES.SESSION_LOGOUT, lastUser, lastSession, get().profile)
            sessionAction(ACTION_NAMES.SESSION_START, initialUser, initialSession, get().profile)
        },
        register: async (email: string, password: string, enterprise?: string) => {

            // const authApi = new AuthApi(new ApiCaller());
            if (await AuthApi.doMailExists(email)) {
                throw new Error(RegisterErrors.EMAIL_ALREADY_EXISTS);
            }

            try {

                console.log('Creating AuthUser...');
                const data = await AuthApi.register(email, password, email);
                const user: AuthDBUser = { email: data.email, token: data.token, type: data.type, status: data.status }
                // const profileApi = new ProfileApi(new ApiCaller(user.token));
                console.log('Creating Progile...');
                //@ts-ignore
                const to_create_profile: any = { email: user.email, name: user.email, onBoardingDone: false }
                if (enterprise) {
                    to_create_profile.enterprise = enterprise
                }
                const profile = new ProfileModel(await ProfileApi.createProfile(to_create_profile))

                console.log('Register Completed.')

                const tobeUser = SessionUser.build(UserTypes.USER, user.token, user.email, []);
                const tobeSession = Session.build(tobeUser);

                sessionAction(ACTION_NAMES.REGISTER, tobeUser, tobeSession, profile)
                return get().login(user, profile)
            } catch (e) {

                throw new Error(RegisterErrors.CONNECTIVITY, { cause: e });


            }

        },
        refresh: async () => {

            const user = get().user;
            if (user.isGuest()) {
                const tobeUser = SessionUser.build(UserTypes.GUEST);
                const tobeSession = Session.build(tobeUser);
                set({ session: tobeSession, user: tobeUser, updated: new Date().toISOString(), ready: true, created: new Date().toISOString() })
                sessionAction(ACTION_NAMES.SESSION_START, tobeUser, tobeSession, null)
            } else {

                // const profileApi = new ProfileApi(new ApiCaller(user.token));
                const toBeProfile = await ProfileApi.getMyProfile();
                const tobeSession = Session.build(user);
                // user.payment.subscriptions = await loadSubscriptions(user)

                sessionAction(ACTION_NAMES.SESSION_START, user, tobeSession, toBeProfile)
                set({ session: tobeSession, user: user, profile: toBeProfile, updated: new Date().toISOString(), ready: true, created: new Date().toISOString() })

            }

        }
    });



let store;

// // for testing purpouses
// if (__DEV__ && Platform.OS == 'web') {
//     store = devtools(AppSessionStore);
// } else {
//     store = AppSessionStore;
// }

// const avoidPersistingThisFields = ['ready', '_hasHydrated'];

//TODO: Delete this
// const persistedStore = persist(store, {
//     name: APP_SESSION_CONSTANTS.APP_SESSION_STORE_KEY,
//     getStorage: () => { return AsyncStorage },
//     partialize: (state: AppSessionStoreType) => {

//         const toBePersisted: Partial<AppSessionStoreType> = {};
//         const keys = Object.keys(state).filter(key => avoidPersistingThisFields.indexOf(key) == -1).filter(key => typeof state[key] !== 'function')

//         for (let key of keys) {
//             toBePersisted[key] = state[key]
//         }

//         // console.log('Filtered from storage: ', toBePersisted)
//         return toBePersisted;

//     },
//     onRehydrateStorage: (state: AppSessionStoreType | never) => {

//         // Before Hydration
//         // console.log('Before Hydration:', state);
//         const onHydrationDone = (state: AppSessionStoreType) => {
//             // console.log('Hydrated session Store, tabs:', state.userTabs);
//             // hydrationSetup(state).then((state) => {
//             //     console.log('SessionStore initialized!!');
//             //     useFavouritesStore.getState().init(state.sessionInfo.profile)
//             //     // if (state.forcedTheme && Object.entries(themes).map(([key, theme], index) => theme.name).indexOf(state.forcedTheme?.name) == -1)
//             //     //     state.forceTheme(null)
//             // }).catch(e => {

//             //     state.build()

//             // })
//             console.log('Hydrated deprecated session Store ');

//         }
//         return onHydrationDone;

//     }
// });


// const hydrationSetup = async (state: AppSessionStoreType) => {

//     state.setReady(false);
//     state.setHasHydrated(true);

//     if (state.version != APP_SESSION_CONSTANTS.VERSION) {
//         await AsyncStorage.removeItem(APP_SESSION_CONSTANTS.APP_SESSION_STORE_KEY)
//         console.error('App Session Store version mismatch')
//         throw ('App Session store mismatch')
//     } else {
//         return await state.build()
//     }

// }

/**
 * @deprecated use {@link useSessionStore} instead
 */
// const useAppSessionStore = create<AppSessionStoreType>()(persistedStore);






