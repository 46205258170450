import Storage from './storage';
// import { User2 } from '../models/user';
import getEnvVars, { getMode } from '../../environments';
// var currentUser: User2;

// var getCurrentUser = async (): Promise<User2> => {
//     var data = await Storage.read('user');
//     if(data == null || data == undefined || data == false) {
//         console.warn("No user retrieved");
//         throw "No user saved in storage";
//     }
//     currentUser = User.prototype.load(data);


//     return currentUser;
// }


const { apiUrl, imagesUrl, batchesApiUrl, apiV2Url } = getEnvVars();
//This enables animation while scrolling, actually false to commit in green
const animationSearchBarToggle = true;

var Settings = {
    // currentUser: (): User | User2 => currentUser,

    // getCurrentUser: getCurrentUser,


    // setCurrentUser: async (user: User | User2): Promise<void> => {
    //     currentUser = user;
    //     return await Storage.write('user', user);
    // },

    logout: async () => {
        return await Storage.clear('user');
    },

    getApiURL: () => {
        return apiUrl;
    },
    getApiV2Url: () => {
        return apiV2Url
    },
    getBatchesUrl: () => {
        return batchesApiUrl;
    },

    getImagesURL: () => {
        return imagesUrl;
    },

    getMode: () => {
        return getMode();
    },

    getHelpEmail: () => {
        return 'ayuda@xxx.com';
    },
    getAnimationSearchBarToggle: () => {
        return animationSearchBarToggle;
    },
    availableArticles: 15,
    restrictedArticles: false,
    /**
     * Number (in miliseconds) that indicates the max ammount of time any kind of trial can be running active in the app.
     */
    usageRestrictedTime: 1800000, // Every 30 min (in miliseconds)
    /**
     * Number (in miliseconds) that indicates how often the trial check should be performed for every kinf of trial
     */
    trialCheckIntervalTime: 5000, // Every 10 seconds (in miliseconds)
    qrcooldown: 86400000, // One day (ms)
    timeRestrictionEnabled: true,
    currentEnterpriseKey: null, // Set to null in order to make app function regularly,
    appDataDeprecationInterval: 2 // Every 2 hours appData will be re-fetched on startup
}

export default Settings;
