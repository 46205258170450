import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import React from 'react'
import { KeyboardAvoidingView, Platform, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Swiper from 'react-native-swiper/src'
import ArticleApi from 'src/api/ArticleApi'
import Background from 'src/app/Background'
import NtyTextInputRound from 'src/app/components/nty/TextInput/TextInputNtyRound'
import ButtonNty from 'src/app/components/nty/button/ButtonNty'
import TitleHeaderNty from 'src/app/components/nty/header/TitleHeaderNty'
import TextNty from 'src/app/components/nty/text/TextNty'
import TitleNty from 'src/app/components/nty/title/TitleNty'
import { useAlert, usePrompt } from 'src/app/context/alertContext'
import { useDatePrompt } from 'src/app/context/datePromptContext'
import { ListItem } from 'src/components/common-ui/lists/ListItem'
import useSessionStore from 'src/context/sessionStore'
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens'
import { Article, CreateQueueArticle, EnterpriseFeedInterface, EnterpriseFeedV2, EnterpriseInterface, QueueStatus } from 'systemDomain'
import FeedSelector from '../components/FeedSelector'


function SuperUserCreateArticlePage() {

    const navigation = useNavigation<StackNavigationProp<ScreensParamsList, 'superUserCreateArticle'>>()

    const swiperRef = React.useRef<Swiper>(null);

    const [article, setArticle] = React.useState<Partial<Article>>({})

    const [feed, setFeed] = React.useState<EnterpriseFeedInterface | EnterpriseFeedV2>()
    const [enterprise, setEnterprise] = React.useState<EnterpriseInterface>()


    const updateArticle = (update: Partial<Article>) => {
        setArticle({ ...article, ...update })
    }

    if (typeof setImmediate === 'undefined') {
        global.setImmediate = setTimeout;
    }

    const createArticle = async () => {

        // @ts-ignore
        const queue_article: CreateQueueArticle = {
            ...article,
            artifact: 'app',
            artifact_instance: 'app-user-' + useSessionStore.getState().profile.email,
            enterprises: [enterprise.key],
            insert_action: 'UPSERT',
            feeds: [feed._id],
            priority: 100,
            force_insert: true,
            status: QueueStatus.PENDING
        }
        try {
            const created = await ArticleApi.createQueueArticle(queue_article)
            useAlert.getState().alert(({ title: 'Completado!', message: 'Tu noticia se ha añadido a la cola con prioridad alta. La verás en unos minutos en News To You'
            , buttons: [
                { label: 'Volver a inicio', onPress: () => navigation.navigate('home') },
                { label: 'Crear otra noticia', onPress: () => navigation.replace('superUserCreateArticle') },
            ] }))
        } catch (e) {
            console.error(e)
            useAlert.getState().alert({ title: 'Error', message: 'No se ha podido crear la noticia, + [' + e + ']', buttons: [{ label: 'Ok' }] })
        }
    }

    return (

        <Background safeInsets>
            <KeyboardAvoidingView style={{ flex: 1 }} behavior='height' keyboardVerticalOffset={100}>

                <TitleHeaderNty title='Crear Noticia' />

                <Swiper
                    loop={false}
                    showsPagination={true}
                    index={0}
                    scrollEnabled={false}
                    ref={swiperRef}
                >
                    <View style={{ flex: 1, paddingHorizontal: 20 }}>
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', paddingVertical: 30 }}>
                            <TitleNty style={{ textAlign: 'center', marginBottom: 10 }}>Campos Principales</TitleNty>
                            <ListItem
                                label='Enlace *'
                                description={article?.url}
                                icon={'link'}
                                onPress={() => {
                                    usePrompt.getState().prompt(
                                        {
                                            title: 'Introduce el link a la noticia',
                                            message: 'Sólo enlaces directos a la noticia.',
                                            validations: [
                                                { regex: /.{3,}/, errorMessage: 'Introduce al menos 3 caracteres' },
                                                { regex: /(http|https)?:\/\/.{3,}/, errorMessage: 'Introduce una URL válida' }
                                            ]
                                        }).then((result) => {
                                            const url = result.data
                                            updateArticle({ url })
                                        })
                                }} />
                            <ListItem
                                label='Titular'
                                description={article?.title}
                                icon={'format-title'}
                                onPress={() => {
                                    usePrompt.getState().prompt(
                                        {
                                            title: 'Introduce el titular',
                                            validations: [{ regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' }]
                                        }).then((result) => {
                                            const title = result.data
                                            updateArticle({ title })
                                        })
                                }} />
                            <ListItem
                                label='Subtítulo'
                                description={article?.subtitle}
                                icon={'format-text'}
                                onPress={() => {
                                    usePrompt.getState().prompt(
                                        {
                                            title: 'Introduce el subtítulo',
                                            message: 'El subtítulo de la noticia, si lo tiene; o un breve resumen de la noticia',
                                            validations: [{ regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' }]
                                        }).then((result) => {
                                            const subtitle = result.data
                                            updateArticle({ subtitle })
                                        })
                                }} />
                            {/* <ListItem
                                label='Publicador *'
                                description={article?.site}
                                icon={'newspaper'}
                                onPress={() => {
                                    usePrompt.getState().prompt(
                                        {
                                            title: 'Introduce el Publicador',
                                            message: 'El nombre del medio o periodista a quien pertenece la notica. También vale el dominio base de la web del periódico (pej: "elpais.com")',
                                            validations: [{ regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' }]
                                        }).then((result) => {

                                            const site = result.data
                                            updateArticle({ site, source: site, brand: site })
                                        })
                                }} /> */}
                            <ListItem
                                label='Fecha de Publicación'
                                description={article?.published_at ? moment(article?.published_at).format('DD/mm/yyyy HH:mm') : null}
                                icon={'calendar'}
                                onPress={() => {
                                    useDatePrompt.getState().promptDate({ title: 'Fecha en la que se publicó la noticia', mode: 'date' }).then((date) => {


                                        console.log('transformed date', date.toISOString())

                                        useDatePrompt.getState().promptDate({ title: 'Hora de publicación', mode: 'time' }).then((time) => {
                                            console.log('transformed time', time.toISOString())

                                            updateArticle({ published_at: `${date.toISOString().split('T')[0]}T${time.toISOString().split('T')[1]}` })

                                        })

                                    })
                                }}
                            />
                            <ListItem
                                label='Imagen'
                                description={article?.photo}
                                icon={'image'}
                                onPress={() => {
                                    usePrompt.getState().prompt(
                                        {
                                            title: 'Enlace a la imagen',
                                            message: 'Introduce el enlace a la imagen de la noticia, si existe',
                                            validations: [
                                                { regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' },
                                                { regex: /(http|https)?:\/\/.{3,}/, errorMessage: 'Introduce una URL válida' }
                                            ]
                                        }).then((result) => {
                                            const photo = result.data
                                            updateArticle({ photo })
                                        })
                                }} />

                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignSelf: 'flex-end', paddingHorizontal: 10 }}>
                                <ButtonNty disabled={!article.url} onPress={() => swiperRef.current?.scrollBy(1)} mergeStyles={{ marginTop: 20 }}>Siguiente</ButtonNty>
                            </View>
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <ScrollView contentContainerStyle={{ paddingBottom: 50, paddingHorizontal: 20 }}>
                            <TitleNty style={{ textAlign: 'center', marginBottom: 20 }}>Campos Adiciconales</TitleNty>





                            <View style={{ flex: 2, paddingHorizontal: 20 }}>
                                <TextNty fontSize={16}>Contenido (texto)</TextNty>
                                <NtyTextInputRound
                                    icon={'text'}
                                    multiline
                                    numberOfLines={30}
                                    value={article?.text}
                                    onChangeText={(text) => updateArticle({ text })}
                                    // placeholder='Introduce el contenido de la noticia'
                                    style={{ marginVertical: 5, height: 200, fontSize: 12 }}

                                />
                            </View>
                            <View style={{ flex: 2, paddingHorizontal: 20 }}>
                                <TextNty fontSize={16}>Contenido (html)</TextNty>
                                <NtyTextInputRound
                                    icon={'code-tags'}
                                    multiline
                                    numberOfLines={30}
                                    value={article?.html}
                                    onChangeText={(text) => updateArticle({ html: text })}
                                    // placeholder='Introduce el contenido de la noticia'
                                    style={{ marginVertical: 5, height: 200, fontSize: 12 }}
                                />
                            </View>







                        </ScrollView>
                        <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-between',paddingHorizontal: 20, position: 'absolute', bottom: Platform.OS == 'web' ? 60 : 30 }}>
                            <ButtonNty onPress={() => swiperRef.current?.scrollBy(-1)} mergeStyles={{ marginTop: 20 }}>Atrás</ButtonNty>
                            <ButtonNty onPress={() => swiperRef.current?.scrollBy(1)} mergeStyles={{ marginTop: 20 }}>Siguiente</ButtonNty>
                        </View>
                    </View>

                    <View style={{ flex: 1, paddingHorizontal: 20 }}>
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', paddingVertical: 30 }}>
                            <TitleNty style={{ textAlign: 'center', marginBottom: 30 }}>Selección de empresa</TitleNty>

                            <View style={{ paddingHorizontal: 20 }}>

                                <View style={{ flexDirection: 'column', justifyContent: 'space-between', width: '100%', paddingHorizontal: 10 }}>

                                    <View style={{ flexDirection: 'row' }}>
                                        <TitleNty fontSize={18}>Empresa: </TitleNty>
                                        <TextNty fontSize={18} color={'red'}>{enterprise?.name ?? 'Ninguna'}</TextNty>
                                    </View>

                                    <View style={{ flexDirection: 'row' }}>
                                        <TitleNty fontSize={18}>Feed: </TitleNty>
                                        <TextNty fontSize={18} color={'red'}>{feed?.name ?? 'Ninguna'}</TextNty>
                                    </View>

                                </View>
                                <ButtonNty
                                    mode='outlined'
                                    onPress={() => {
                                        setEnterprise(undefined)
                                        setFeed(undefined)
                                    }
                                    } mergeStyles={{ marginTop: 10 }}>Borrar selección</ButtonNty>
                            </View>
                            <FeedSelector
                                onFeedChange={(feed) => {
                                    setFeed(feed)
                                }}
                                onEnterpriseChange={(enterprise) => {
                                    setEnterprise(enterprise)
                                    setFeed(undefined)
                                }}
                            />
                            <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10 }}>
                                <ButtonNty onPress={() => swiperRef.current?.scrollBy(-1)} mergeStyles={{ marginTop: 20 }}>Atrás</ButtonNty>
                                <ButtonNty disabled={Boolean(enterprise) !== Boolean(feed)} onPress={createArticle} mergeStyles={{ marginTop: 20 }}>Crear</ButtonNty>
                            </View>
                        </View>
                    </View>


                </Swiper>

            </KeyboardAvoidingView>
        </Background>
    )
}

export default SuperUserCreateArticlePage
