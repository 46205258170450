import React, { useCallback } from 'react';
import { Dimensions, FlatList, FlatListProps, LayoutChangeEvent, ListRenderItem, ListRenderItemInfo, StyleSheet, View } from 'react-native';
import PublisherBubble, { PublisherBubbleEntity } from '../PublisherBubble';
import { VerticalPublisherListProps, getNumColumnsPublisherList } from './publisherlist.types';



export default function VerticalPublisherList(props: VerticalPublisherListProps) {

    const [width, setWidth] = React.useState<number>(Dimensions.get('window').width)
    const height = Dimensions.get('window').height

    const onLayout = (e: LayoutChangeEvent) => {
        console.log({ layoutW: e.nativeEvent.layout.width, w: width })
        if (e.nativeEvent.layout.width == 0 || e.nativeEvent.layout.width == width) return;
        setWidth(e.nativeEvent.layout.width)
        // setHeight(e.nativeEvent.layout.height)
    }

    const numColsI = props.numColumns ? props.numColumns : getNumColumnsPublisherList(width);

    const DIMENSIONS = React.useMemo(() => {

        console.log('Generating dimensions', width)

        const LIST_HORIZONTAL_PADDING = 10//width * 0.005
        const ITEM_HORIZONTAL_PADDING = 5
        const ITEM_WIDTH = (width - (LIST_HORIZONTAL_PADDING * 2)) / numColsI
        const ITEM_HEIGHT = ITEM_WIDTH //ITEM_WIDTH * (Platform.OS == 'web' ? props.horizontal ? 1.35 : 1.65 : 0.9);
        const ITEM_SEPARATOR_HEIGHT = ITEM_HEIGHT * 0.05

        return {
            LIST_HORIZONTAL_PADDING,
            ITEM_HORIZONTAL_PADDING,
            ITEM_WIDTH,
            ITEM_HEIGHT,
            ITEM_SEPARATOR_HEIGHT
        }

    }, [width])

    // const LIST_HORIZONTAL_PADDING = width * 0.005
    // const ITEM_HORIZONTAL_PADDING = 5
    // const ITEM_WIDTH = props.itemWidth ?? (width - (LIST_HORIZONTAL_PADDING * 2)) / numColsI
    // const ITEM_HEIGHT = props.itemHeight ?? height / 1.5 //ITEM_WIDTH * (Platform.OS == 'web' ? props.horizontal ? 1.35 : 1.65 : 0.9);
    // const ITEM_SEPARATOR_HEIGHT = props.itemSeparatorHeight ?? ITEM_HEIGHT * 0.05

    const itemStyle = React.useMemo(() => ({
        width: DIMENSIONS.ITEM_WIDTH,
        height: DIMENSIONS.ITEM_HEIGHT,
        paddingHorizontal: DIMENSIONS.ITEM_HORIZONTAL_PADDING,
        // borderWidth: 1,
        // borderColor: 'red',
        justifyContent: 'center',
        alignItems: 'center'
    }), [DIMENSIONS.ITEM_WIDTH, DIMENSIONS.ITEM_HEIGHT, DIMENSIONS.ITEM_HORIZONTAL_PADDING])

    const paginationProps: Partial<FlatListProps<PublisherBubbleEntity>> = {
        snapToInterval: (DIMENSIONS.ITEM_HEIGHT + DIMENSIONS.ITEM_SEPARATOR_HEIGHT) * props.paginateEvery,
        decelerationRate: 'fast'
    }


    const getItemLayoutInternal = useCallback((data, index) => {

        return (
            {
                length: DIMENSIONS.ITEM_HEIGHT,
                offset: (DIMENSIONS.ITEM_HEIGHT + DIMENSIONS.ITEM_SEPARATOR_HEIGHT) * (index),
                index
            }
        )

    }, [DIMENSIONS.ITEM_HEIGHT, DIMENSIONS.ITEM_SEPARATOR_HEIGHT])

    // const defaultFooterComponent = () => props.onLoadMore && !props.loadMoreDisabled && props.data?.length > 0 ? <LoadMoreButton onLoadMore={() => { props.onLoadMore() }} /> : null;

    const keyExtractor = (p: PublisherBubbleEntity, index: number) => typeof p == 'string' ? index + '_' + p : p.keyName

    const renderItemInternal: ListRenderItem<PublisherBubbleEntity> = props.renderBubble ?
        (info: ListRenderItemInfo<PublisherBubbleEntity>) => (<View style={itemStyle} key={keyExtractor(info.item, info.index)}>
            {props.renderBubble(info.item, info.index)}
        </View >) :
        useCallback((info: ListRenderItemInfo<PublisherBubbleEntity>) => (
            <View style={itemStyle} key={keyExtractor(info.item, info.index)}>
                <PublisherBubble
                    publisher={info.item}
                    showLabel={true}
                    labelPosition='bottom'
                    fontSize={15}
                    {...props.bubbleProps}
                    onPress={(entity) => props.onBubblePress && props.onBubblePress(entity, info.index)}
                    onLongPress={(entity) => props.onBubbleLongPress && props.onBubbleLongPress(entity, info.index)}
                />
            </View >
        ), [DIMENSIONS.ITEM_HEIGHT, DIMENSIONS.ITEM_SEPARATOR_HEIGHT, itemStyle, props.bubbleProps])



    delete props.numColumns


    return (
        <FlatList<PublisherBubbleEntity>
            key={numColsI + '_'}
            numColumns={numColsI}
            data={props.publishers}
            keyExtractor={keyExtractor}
            renderItem={renderItemInternal}
            getItemLayout={getItemLayoutInternal}
            onLayout={onLayout}
            {...props.paginateEvery ? paginationProps : null}
            {...props}
            contentContainerStyle={[styles.container, { paddingHorizontal: DIMENSIONS.LIST_HORIZONTAL_PADDING }]}
            columnWrapperStyle={numColsI > 1 ? { justifyContent: 'center' } : null}
        />
    );
}
const styles = StyleSheet.create({
    container: {
        // flexDirection: 'row',
        alignItems: 'center',
    },
});
