import React, { memo, useState } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import Background from '../app/Background';
import Logo from '../components/Logo';

import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import TextNty from '../app/components/nty/text/TextNty';
import NtyTextInputRound from '../app/components/nty/TextInput/TextInputNtyRound';
import TitleNty from '../app/components/nty/title/TitleNty';
import ApiCaller from '../core/ApiCaller';
import { theme } from '../core/theme';
import { Navigation } from '../types';

type Props = {
  navigation: Navigation;
};

const ForgotPasswordScreen = ({ navigation }: Props) => {

  const [email, setEmail] = useState("");
  const [pwChangedTitle, setpwChangedTitle] = React.useState(null);
  const [pwChangedMessage, setpwChangedMessage] = React.useState(null);
  const modalRef = React.useRef<NewsToYouSheetModalHandle>(null)

  const _onRecoverMailPressed = async () => {

    Keyboard.dismiss();

    const apiCaller = new ApiCaller();
    try {
      const res = await apiCaller.call('/v1/forgotPassword', 'POST', { email: email.trim() });
      //alert(res)
      setpwChangedTitle("Listo")
      setpwChangedMessage("Hemos enviado un mail a " + email + " con un enlace para cambiar la contraseña. Mira en tu bandeja de entrada. (No olvides revisar tu correo no deseado)");

    } catch (e) {
      setpwChangedTitle("Lo sentimos")
      setpwChangedMessage("No hemos podido iniciar el proceso de cambio de contraseña. Es posible que " + email + " no exista en nuestra base de datos. Si estás seguro inténtalo más tarde.")
    }
    finally {
      modalRef.current.show()
    }
  };
  const _onBackPressed = () => {
    navigation.navigate('login')
  }

  return (
    <Background safeInsets mobileConstraint>
      <View style={{  flex: 1, flexDirection: "column", justifyContent: 'center', padding: 15}}>
        <View style={{ flex: 3, flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>
        <View style={{ flex: 7, flexDirection: 'column' }}>
          {/* <NtyTextInput
            placeholder="Email"
            returnKeyType="next"
            onChangeText={(text: string) => setEmail(text)}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
            value={email}
            onSubmitEditing={() => Keyboard.dismiss}
          /> */}
          <NtyTextInputRound
            nativeID='forgotPasswordNty'
            placeholder="Email"
            returnKeyType="next"
            onChangeText={(text: string) => setEmail(text)}
            autoCapitalize="none"
            autoComplete="email"
            textContentType="emailAddress"
            keyboardType="email-address"
            value={email}
            onSubmitEditing={() => Keyboard.dismiss}
          />
          <View style={{ flexDirection: "row", marginVertical: 100, justifyContent: 'center', width: "100%", alignSelf: "center" }}>
            <ButtonNty mergeStyles={{ width: "70%" }} onPress={_onRecoverMailPressed}>
              Recuperar
            </ButtonNty>
          </View>

          <View style={{ flexDirection: "row", justifyContent: 'center', width: "100%", alignSelf: "center", marginTop: 30 }}>
            <ButtonNty color={theme.colors.black.medium} mergeStyles={{ width: "70%" }} mode='outlined' onPress={_onBackPressed}>
              Atrás
            </ButtonNty>
          </View>
        </View>
      </View>
      <NtySheetModal ref={modalRef} forceModal={true} dissmisable={false}>
        <View style={{ flexDirection: 'column' }}>
          <View key={"header"} style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 30, marginBottom: 60 }}>
            <TitleNty>{pwChangedTitle}</TitleNty>
          </View>

          <View key={"Message"} style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 50, paddingHorizontal: 30 }}>
            <TextNty type='regular' style={{ textAlign: 'center' }}>{pwChangedMessage}</TextNty>
          </View>

          <View key={"Buttons"} style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: 30, marginHorizontal: 10 }}>

            {pwChangedTitle == 'Listo' ?
              <ButtonNty onPress={() => { navigation.navigate('login'); modalRef.current.hide(); }}>Revisa tu Correo</ButtonNty> : null}

            {pwChangedTitle == 'Lo sentimos' ?
              <ButtonNty onPress={() => { navigation.navigate('guest-welcome'); modalRef.current.hide(); }}>Reintentar</ButtonNty> : null}


          </View>

        </View>
      </NtySheetModal>
    </Background>
  );
};


const styles = StyleSheet.create({
  entry: {
    marginBottom: 120,
  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  buttonContained: {
    backgroundColor: theme.colors.primary,
    shadowRadius: 15,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOpacity: 0.2,
    elevation: 5

  },
  buttonOutlined: {
    borderWidth: 2,
    borderColor: theme.colors.primary,
    borderRadius: 8
  }
});
export default memo(ForgotPasswordScreen);
