import React from "react";
import { GestureResponderEvent, Platform, StyleSheet, View } from "react-native";
import { IconButton } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Article } from "systemDomain";
import { useAppTheme } from "../../core/theme/ThemeBuilder";
import { shareArticle } from "../../core/utils";
import { useArticleOptionsStore } from "../context/optionStores";
import BottomSheetTextStyles from "./BottomSheetTextStyles";
import TextToSpeech from "./TextToSpeech";
import { NewsToYouSheetModalType } from "./modals/general/NewsToYouSheetModal";
import NtySheetModal from "./modals/general/NtySheetModal";

export interface ArticleBottomOptionsProps {
  article: Article;
  navigation: any;
  lockedState: boolean;
  toggleWebView?: Function;
  isWebView?: boolean;
  goBackPresed?: (article: Article) => void
}

export default function ArticleBottomOptions({
  article,
  navigation,
  lockedState,
  toggleWebView,
  isWebView,
  goBackPresed
}: ArticleBottomOptionsProps) {

  // const refArticleOptions = React.useRef<NewsToYouSheetModalType>(null);

  const insetBottom = useSafeAreaInsets().bottom
  const { open: openArticleOptions } = useArticleOptionsStore.getState()
  const refTextStyles = React.useRef<NewsToYouSheetModalType>(null);

  const theme = useAppTheme();

  const onShare = async (event: GestureResponderEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    shareArticle(article);
  }

  const iconsColor: string = theme.colors.text_primary[500] as string

  return (
    <>
      <View style={{
        ...styles.bottomBarContainer, backgroundColor: theme.colors.background[400], paddingBottom: insetBottom, position: 'absolute', bottom: 0
      }}>
        <View style={styles.menuButton}>
          <IconButton
            icon={"chevron-left"}
            color={iconsColor}
            onPress={goBackPresed ? () => goBackPresed(article) : () =>
              navigation.canGoBack()
                ? navigation.goBack()
                : navigation.navigate("home")
            }
          ></IconButton>
        </View>
        <View style={styles.menuButton}>
          <TextToSpeech
            article={article}
            iconButton={true}
            navigation={navigation}
          />
        </View>
        <View style={styles.menuButton}>
          <IconButton icon={"share"} color={iconsColor} onPress={onShare}></IconButton>
        </View>
        {lockedState == false && article?.url?.startsWith('http') &&
          <View style={styles.menuButton}>
            {
              Platform.OS == 'web' ?
                <a href={article?.url} target="_blank" style={{ textDecoration: 'none' }}>
                  <IconButton icon={isWebView ? "format-text" : "web"} color={iconsColor} onPress={() => { }}></IconButton>
                </a>
                :
                <IconButton icon={isWebView ? "format-text" : "web"} color={iconsColor} onPress={() => { toggleWebView() }}></IconButton>
            }
          </View>
        }
        {!isWebView && <View style={styles.menuButton}>
          <IconButton
            icon={"format-letter-case"}
            color={iconsColor}
            onPress={() => {
              Platform.OS == 'web' ? refTextStyles.current.show() : navigation.push('textStyles')
            }}
          ></IconButton>
        </View>}
        <View style={styles.menuButton}>
          <IconButton
            icon={"dots-vertical"}
            color={iconsColor}
            onPress={() => {
              openArticleOptions(article)
            }}
          ></IconButton>
        </View>
      </View>
      {Platform.OS == 'web' && <NtySheetModal ref={refTextStyles} >
        {/* <View style={{ flex: 1 }}> */}

          <BottomSheetTextStyles
            navigation={navigation}
            onClose={() => refTextStyles.current.hide()}
          />

        {/* </View> */}
      </NtySheetModal>}
    </>
  );
}

const styles = StyleSheet.create({
  menuButton: {
    flex: 1,
    paddingHorizontal: 10,
    alignContent: "center",
    alignItems: "center",
  },
  bottomBarContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    paddingTop: 5
  }
});

