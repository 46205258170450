import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { MutableRefObject } from 'react';

import { Pressable, StyleSheet, View } from 'react-native';
import { Title, TouchableRipple } from 'react-native-paper';
import Swiper from "react-native-swiper/src";
import { useTextStylesStore } from '../../context/TextStylesStore';
import TextNty from './nty/text/TextNty';

export interface FontDef {
    name: string,
    literal: string
}

const fonts: FontDef[] = [
    {
        name: 'Archivo',
        literal: 'Archivo_400Regular',
    },
    {
        name: 'Roboto',
        literal: 'Roboto_400Regular',
    },
    {
        name: 'Playfair',
        literal: 'PlayfairDisplay_400Regular'
    }
    // {
    //     name: 'Newsreader',
    //     literal: 'Newsreader-Regular'
    // },
    // {
    //     name: 'AndadaPro',
    //     literal: 'AndadaPro-Regular'
    // },
    // {
    //     name: 'PTSerif',
    //     literal: 'PTSerif-Regular'
    // }
];


const styles = StyleSheet.create({

    item: {
        flexDirection: "column",
    }
    ,
    fontTile: {
        flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 80
    }

});




export type FontSelectorProps = {
    swiperRef?: MutableRefObject<Swiper>;
};

export default function BottomSheetTextFontSelector({ swiperRef }: FontSelectorProps) {


    const setFontFamily = useTextStylesStore((state) => state.setFontFamily)
    const fontFamily = useTextStylesStore((state) => state.fontFamily)


    const selectFont = (font: FontDef) => {
        setFontFamily(font.literal);
    }

    const renderFont = (item: any, index: number) => {

        return (
            // <Title>Hello World</Title>
            <TouchableRipple key={item.literal} onPress={() => selectFont(item)} style={index != 0 ? { borderTopWidth: 1, borderTopColor: "rgba(0,0,0,0.1)" } : null}>
                <View style={[styles.fontTile]}>
                    <Title style={[{ fontFamily: item.literal }, fontFamily == item.literal ? { textDecorationLine: 'underline', textDecorationStyle: 'solid', fontWeight: 'bold' } : null]}>{item.name}</Title>
                </View>
            </TouchableRipple>
        )


    }

    return (
        <View style={{ paddingHorizontal: 20, flex: 1 }}>

            <View style={[styles.item, { flex: 8, justifyContent: 'space-around', alignContent: 'center' }]}
            >
                {fonts.map((val, index) => { return renderFont(val, index) })}
            </View>
            {/* Go Back */}

            <Pressable
                onPress={() => {console.log('Font Atrás clicked'); swiperRef.current.scrollBy(-1, true) }}
                style={[{flexDirection: 'row'}, { flex: 1, justifyContent: 'flex-start', alignItems: 'center'}, {zIndex: 1000}]}>
                <MaterialCommunityIcons
                    name="chevron-left"
                    color={"black"}
                    size={20}
                />
                <TextNty>Atrás</TextNty>
            </Pressable>
        </View>
    )
}
