import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    spotifyContainer: {
      height: '130%',
    },
    spotifyWebView: {
      opacity: 0.99,
      overflow: 'hidden',
      backgroundColor: 'transparent',
      flex: 1,
    },
    loadingIndicator: {
      position: 'absolute',
      right: 15,
      top: 10,
    },
    imageWrapper: { width: "100%", height: "100%", resizeMode: 'cover' },
    //@ts-ignore
    videoWrapper: { resize: 'none', contain: 'layout' },
    iconContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center'},
    icon:  { zIndex: 1, backgroundColor: 'transparent', marginLeft: 10, marginTop: 10 },
    newspaperIconWrapper:{ width: "100%", height: "100%", justifyContent:"center"},
    newspaperIcon:{ alignSelf:"center"}

  });
