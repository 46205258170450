import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { Alert, Image, Linking, Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import AutoImage from 'src/components/AutoImage';
import { hexTransparency } from 'src/core/jsutils';
import Logo from '../../../../../components/Logo';
import { useAppTheme } from '../../../../../core/theme/ThemeBuilder';
import { isSpecialType } from '../../../../../core/utils';
import ArticleAudio from '../../media/ArticleAudio';
import ArticleVideo2 from '../../media/ArticleVideo2';
import SpotifyPlugin from './SpotifyPlugin';
import { styles } from './styles';
import { ArticleMediaProps } from './types';
import { getMediaUrlExtension } from './utils';

// function ArticleMedia({ article, imageStyle, resizeMode, forceState }: ArticleMediaProps) {
//     const theme = useAppTheme()

//     const specialType = useMemo(() => isSpecialType(article), [article])
//     let mediaobj: ArticleMedia = article.media
//     let articleMediaUrl = null;
//     let articleMediaType = null;

//     const articleType = article?.type;

//     const [spotyEpisodeNotFound, setSpotyEpisodeNotFound] = React.useState(false)


//     const publisher = article.publisher



//     articleMediaUrl = mediaobj?.cover?.url?.length > 10 ? mediaobj.cover.url : null;


//     if (forceState != null) {
//         articleMediaType = forceState;
//     } else {
//         // if (article.props.webview == true) {
//         //     articleMediaType = 'image';
//         // } else {
//         articleMediaType = mediaobj?.cover?.type ? mediaobj.cover.type : getMediaUrlExtension(articleMediaUrl, article);
//         // }
//     }

//     // return (
//     //     <TextNty>{articleMediaType}</TextNty>
//     // )





//     if ((forceState != 'image') && articleType == 'spotify') {

//         return <SpotifyPlugin article={article} />

//     } else if ((articleMediaType == 'image' && articleMediaUrl && articleMediaUrl.toLowerCase() != 'nan')) {

//         // if (Platform.OS != 'web')
//         //     cacheURLPictures([articleMediaUrl])

//         return <AutoImage source={{ uri: articleMediaUrl }} height={"100%"} width={"100%"} loadingSource={require('src/assets/icons/nty-square-loading.gif')} />
//         // return <Image
//         //     source={
//         //         {
//         //             uri: articleMediaUrl?.includes("http") ?
//         //                 articleMediaUrl
//         //                 : getLocalhost + articleMediaUrl,
//         //             cache: 'force-cache'
//         //         }
//         //     }

//         //     // loadingIndicatorSource={require('../../../../assets/logos/loading-spinner.gif')}
//         //     defaultSource={require('../../../../../assets/logos/logo_nty.png')}

//         //     style={imageStyle ?? [styles.imageWrapper, { resizeMode: resizeMode ?? 'cover' }]}
//         // />

//     } else if (articleMediaType == 'audio' && !specialType) {

//         return <ArticleAudio url={articleMediaUrl} />
//         // return <ArticleAudio2  />

//     } else if (articleMediaType == 'video' && !specialType) {

//         return Platform.OS == 'web' ?
//             <View style={{ justifyContent: 'center', flex: 1, backgroundColor: 'black' }}>
//                 <View style={{ width: '100%' }}>
//                     <video src={articleMediaUrl}
//                         controls={true}
//                         //@ts-ignore
//                         style={styles.videoWrapper} />
//                 </View>
//             </View>
//             :
//             // <View style={{height: 200, backgroundColor: 'red'}}>

//             // </View>
//             <ArticleVideo2 url={articleMediaUrl} />



//     } else if (articleMediaType == 'online' && !specialType) {

//         return <View>
//             <View style={[styles.iconContainer, { backgroundColor: hexTransparency(theme.colors.background[700], 13) }]}>
//                 <MaterialCommunityIcons
//                     name={'web'}
//                     color={theme.colors.primary[500]}
//                     size={40}
//                     style={[StyleSheet.absoluteFillObject, styles.icon]} />
//                 <Logo height={200} />
//             </View>
//         </View>

//     } else if (articleMediaType == 'pdf' && !specialType) {

//         return (
//             <TouchableWithoutFeedback style={{ flex: 1 }} onPress={() => {

//                 Alert.alert(
//                     'Visualización del Documento',
//                     'Seleccione de que manera desea abrir el documento asociado a la noticia.',
//                     [
//                         {
//                             text: 'Abrir en el navegador',
//                             onPress: () => Linking.openURL(article.url),
//                             style: 'default',
//                         },
//                         {
//                             text: 'Descargar',
//                             onPress: () => Linking.openURL(articleMediaUrl),
//                             style: 'default',
//                         },
//                     ],
//                     {
//                         cancelable: true
//                     }
//                 );
//             }}>
//                 <View style={styles.iconContainer}>
//                     <MaterialCommunityIcons
//                         name={'file-pdf-box'}
//                         color={theme.colors.background[300]}
//                         size={40}
//                         style={[StyleSheet.absoluteFillObject, styles.icon]} />
//                     <Logo height={200} />
//                 </View>
//             </TouchableWithoutFeedback>
//         )

//     } else if (articleMediaType == null || (articleMediaUrl == null || articleMediaUrl.length < 10)) {

//         if (publisher && publisher.logo) {

//             return <Image
//                 source={{ uri: publisher.logo, cache: 'force-cache' }}
//                 //@ts-ignore
//                 style={[styles.imageWrapper, { resizeMode: resizeMode ?? 'cover' }]} />

//         } else {
//             return <View style={[styles.newspaperIconWrapper, { backgroundColor: theme.colors.background.muted }]}>
//                 <MaterialCommunityIcons name='newspaper' style={styles.newspaperIcon} color={theme.colors.background[200]} size={80} />
//             </View>
//         }
//     }
// }

// export default React.memo(ArticleMedia)

const getArticleMediaData = (article, forceState) => {
    let mediaobj = article.media;
    let articleMediaUrl = mediaobj?.cover?.url?.length > 10 ? mediaobj.cover.url : null;
    let articleMediaType;

    if (forceState != null) {
        articleMediaType = forceState;
    } else {
        articleMediaType = mediaobj?.cover?.type ? mediaobj.cover.type : getMediaUrlExtension(articleMediaUrl, article);
    }

    return { articleMediaUrl, articleMediaType };
};

const ArticleMediaImage = ({ url, imageStyle, resizeMode }) => (
    <AutoImage
        source={{ uri: url }}
        height={"100%"}
        width={"100%"}
        loadingSource={require('src/assets/icons/nty-square-loading.gif')}
    />
);

const ArticleMediaAudio = ({ url, specialType }) => {
    if (!specialType) {
        return <ArticleAudio url={url} />;
    }
    return null;
};

const ArticleMediaVideo = ({ url, specialType }) => {
    if (!specialType) {
        return Platform.OS === 'web' ?
            (
                <View style={{ justifyContent: 'center', flex: 1, backgroundColor: 'black' }}>
                    <View style={{ width: '100%' }}>
                        {/* @ts-ignore*/ }
                        <video src={url} controls={true} style={styles.videoWrapper}/>
                    </View>
                </View>
            ) :
            <ArticleVideo2 url={url} />
    }
    return null;
};

const ArticleMediaOnline = ({ theme, specialType }) => {
    if (!specialType) {
        return (
            <View>
                <View style={styles.iconContainer}>
                    <MaterialCommunityIcons
                        name={'web'}
                        color={theme.colors.primary[500]}
                        size={40}
                        style={styles.icon}
                    />
                    <Logo height={200} />
                </View>
            </View>
        );
    }
    return null;
};

const ArticleMediaPDF = ({ url, theme }) => (
    <TouchableWithoutFeedback onPress={() => {
        Alert.alert(
            'Visualización del Documento',
            'Seleccione de que manera desea abrir el documento asociado a la noticia.',
            [
                {
                    text: 'Abrir en el navegador',
                    onPress: () => Linking.openURL(url),
                    style: 'default',
                },
                {
                    text: 'Descargar',
                    onPress: () => Linking.openURL(url),
                    style: 'default',
                },
            ],
            {
                cancelable: true
            }
        );
    }}>
        <View>
            <MaterialCommunityIcons
                name={'file-pdf-box'}
                color={theme.colors.background[300]}
                size={40}
            />
            <Logo height={200} />
        </View>
    </TouchableWithoutFeedback>
);


function ArticleMediaComponent({ article, imageStyle, resizeMode, forceState }: ArticleMediaProps) {
    const theme = useAppTheme();
    const specialType = useMemo(() => isSpecialType(article), [article]);
    const { articleMediaUrl, articleMediaType } = getArticleMediaData(article, forceState);
    const publisher = article.publisher;

    switch (articleMediaType) {
        case 'spotify':
            if (forceState !== 'image') {
                return <SpotifyPlugin article={article} />;
            }
            break;
        case 'image':
            if (articleMediaUrl && articleMediaUrl.toLowerCase() !== 'nan') {
                return <ArticleMediaImage url={articleMediaUrl} imageStyle={imageStyle} resizeMode={resizeMode} />;
            }
            break;
        case 'audio':
            return <ArticleMediaAudio url={articleMediaUrl} specialType={specialType} />;
        case 'video':
            return <ArticleMediaVideo url={articleMediaUrl} specialType={specialType} />;
        case 'online':
            return <ArticleMediaOnline theme={theme} specialType={specialType} />;
        case 'pdf':
            return <ArticleMediaPDF url={articleMediaUrl} theme={theme} />;
        default:
            if (publisher && publisher.logo) {
                //@ts-ignore
                return <Image style={styles.imageWrapper} source={{ uri: publisher.logo, cache: 'force-cache' }} />;
            } else {
                return (
                    <View style={[styles.newspaperIconWrapper,{ backgroundColor: theme.colors.background.muted }]}>
                        <MaterialCommunityIcons name='newspaper' color={theme.colors.background[200]} size={80} style={styles.newspaperIcon} />
                    </View>
                );
            }
    }
}

export default React.memo(ArticleMediaComponent);
