import { GetState, SetState } from "zustand"
import { AppTrialStoreType } from "../context/appTrialStore"
import Settings from "../core/settings"


/**
 * Values fot User.trial field
 */
 export enum TrialTypes {

    NONE = 'none',
    QR = 'qr'

}


/**
 * Type used to manage the data that the QR trial algorithm uses
 */
export type QrTrialData = {

    /**
     * The exact Date when the Qr were just scanned and used in the app.
     */
    qrAt: Date

    /**
     * Enterprise partner key
     */
    enterpriseKey: string

    /**
     * Whether or not this trial has been started yet.
     */
    started: boolean,

    /**
     * Whether or not this trial has ended yet.
     */
    finished: boolean,

    /**
     * Enable QR premium features. The Trial functionality is still active.
     */
    active: boolean

    /**
     * The time when the trial started.
     */
    startedAt: Date

    /**
     * The time when the trial ended.
     */
    endedAt: Date,

    /** This field is used as a flag to indicate that this trial needs to be shutted down at all efects.  */
    cancelled: boolean

}

/**
 * Composited type for the data being held in NTyTrial instances.
 */
export type TrialDataTypes = QrTrialData;

// CAUTION: THIS CODE BREAKS THE PROJECT BUILDING IN BABEL
// /**
//  * This class is used to create a class hierarchy for all the trials being used in the app from now on.
//  *
//  * This class must be implemented for any specific Trial Type that's going to be treated in the app.
//  *
//  */
//  export abstract class NtyTrial<T = TrialDataTypes> {

//     // id: string;
//     abstract type: TrialTypes;
//     abstract data: T


//     // constructor(type: TrialTypes) {
//     //     const seed = type + '_' + Date.now();
//     //     const id = hash_cyrb53(seed) + '_' + seed;
//     //     this.id = id;
//     // }

//     /**
//      * @returns `true` if the trial has not yet finished its chek interval phase
//      */
//     public abstract isRunning(): boolean;

//     /**
//      *
//      * The QR trial expires at the very moment the day it was initiated changes.
//      *
//      * So if you start a trial at 11.59 it'll be expired just one minute after that.
//      *
//      * @returns `true` if the trial data is still valid based on the date it was started.
//      */
//     public abstract isExpired(): boolean;

//     /**
//      *
//      * The QR trial is valid (stays active) for a time duration in miliseconds thats referred here {@link Settings.usageRestrictedTime}.
//      *
//      * When the active time (time passed between now and the tima it started) surpasses the threshold, the trial is no more valid (its effects should be deactivated).
//      *
//      * @returns `true` if the trial data is still valid based on the date it was started.
//      */
//     public abstract isStillValid(): boolean;


//     /**
//      * Get the time of use of this trial instance, in miliseconds.
//      *
//      * @returns `number` of miliseconds this trial instance has been running
//      */
//     public abstract getUsageTime(): number

//     /**
//      * @returns `true` if the trial has ended its check interval phase.
//      */
//     public abstract isFinished(): boolean;

//     /**
//      * **Should not be used to check trial validity, try** {@link NtyTrial.isStillValid}
//      * @returns the value stored in data.active;
//      */
//     public abstract isActive(): boolean;


//     /**
//      * Start the trial with the data placed inside the `data` property of this instance.
//      *
//      * This method should be called within the {@link AppTrialStoreType} in order to pass the `get` and `set` zustand function parameters.
//      *
//      * @returns a Promise containing the data of this trial instance *the moment the trial ends*
//      *
//      */
//     public abstract start(get: GetState<AppTrialStoreType>, set: SetState<AppTrialStoreType>): Promise<QrTrialData>;


// }



/**
 * This class is used to encapsulate all the data an logic needed to manage the QR trial.
 *
 * You can build a QrTrial with the {@link QrTrial.build} | {@link QrTrial.from} static methods with the required parameters.
 *
 * Then, just execute .start() in order to execute a function that returns a promise which uses setInterval
 * in order to check whether the trial still stays active or not.
 *
 * Whever the trial ends, the promise gets resolved with a snapshot of the trial data the time it ends.
 *
 */
export class QrTrial {

    type = TrialTypes.QR;
    data: QrTrialData;

    public static QR_TRIAL_NAME = 'qrEnterpriseTrial';

    private constructor(data: QrTrialData) {

        // super(TrialTypes.QR);

        Object.keys(data).forEach(
            (key) => {
                if (typeof data[key] == 'string') {

                    try {
                        const date = new Date(data[key]);
                        if (date instanceof Date && !isNaN(date)) {
                            data[key] = date;
                        }
                    } catch (e) { }

                }
            }
        )

        this.data = data;

    }

    /**
     * Creates a QrTrial object from the trialData from an old version of the trial ready to start working.
     *
     * @param trialData pass this parameter in order to build the QrTrial from the given data. This is used to build a running trial and resume it with .start()
     */
    public static from(trialData: QrTrialData) {

        // console.log('Building QR trial from data...')
        return new QrTrial(trialData);

    }

    /**
     * Creates a new QrTrial object ready to start working.
     *
     * **If you do not want to create a fresh copy of this kind of trial, try** {@link QrTial.from}
     *
     */
    public static build(params: { enterpriseKey: string }) {

        // console.log('Building QR trial...')

        let qrTrialData: QrTrialData;

        qrTrialData = {
            qrAt: new Date(),
            enterpriseKey: params.enterpriseKey,
            started: false,
            startedAt: null,
            endedAt: null,
            finished: false,
            active: true,
            cancelled: false
        }

        return new QrTrial(qrTrialData);

    }


    /**
     * @returns `true` if the trial has not yet finished its chek interval phase
     */
    public isRunning() {

        const running = (this.data.started && !this.data.finished);
        return running;

    }

    /**
     *
     * The QR trial expires at the very moment the day it was initiated changes.
     *
     * So if you start a trial at 11.59 it'll be expired just one minute after that.
     *
     * @returns `true` if the trial data is still valid based on the date it was started.
     */
    public isExpired() {

        if (!this.data.started || !this.data.startedAt) {
            //It can not be expired if it never started
            return false;
        }

        const currentDate = new Date();

        const currentLocaleDateStr = currentDate.toLocaleDateString();
        const firstUseLocaleDateStr = this.data.startedAt?.toLocaleDateString();

        // LocaleDateString returs smthing like 'DD/MM/YYYY' so we can just test if the strings are equal
        return currentLocaleDateStr !== firstUseLocaleDateStr


    }

    /**
     *
     * The QR trial is valid (stays active) for a time duration in miliseconds thats referred here {@link Settings.usageRestrictedTime}.
     *
     * When the active time (time passed between now and the tima it started) surpasses the threshold, the trial is no more valid (its effects should be deactivated).
     *
     * @returns `true` if the trial data is still valid based on the date it was started.
     */
    public isStillValid() {

        if (!this.data.started || !this.data.startedAt) {
            //It can not be valid (active) if it never started
            return false;
        }

        const currentDate = new Date();

        const firstDayUse = this.data.startedAt.getTime();
        const currentTimestamp = currentDate.getTime();

        // substract the miliseconds when it was started to the miliseconds of "right now" and compare it to the restricted time for the QR trial.
        return (currentTimestamp - firstDayUse) < Settings.usageRestrictedTime

    }


    public getUsageTime() {
        if (!this.data.started || !this.data.startedAt) {
            //It can not be valid (active) if it never started
            return 0;
        }

        const currentDate = new Date();

        const firstDayUse = this.data.startedAt.getTime();
        const currentTimestamp = currentDate.getTime();

        // substract the miliseconds when it was started to the miliseconds of "right now" and u get the usage time
        return (currentTimestamp - firstDayUse)
    }

    /**
     * @returns `true` if the trial has ended its check interval phase.
     */
    public isFinished() {

        return this.data.finished;
    }

    /**
     * **Should not be used to check trial validity, try** {@link QrTrial.isStillValid}
     * @returns the value stored in data.active;
     */
    public isActive() {

        return this.data.active;

    }


    /**
     * Start the QR trial with the data placed inside the `data` property of this instance.
     *
     * **Ensure you have created the instance with the {@link QrTrial.build} | {@link QrTrial.from} methods**
     *
     */
    public async start(get: GetState<AppTrialStoreType>, set: SetState<AppTrialStoreType>): Promise<QrTrialData> {

        const trial: QrTrialData = this.data;

        if (trial) {

            if (!trial.started) {
                //If the trial hasnt started yet is a new trial set start fields
                trial.started = true;
                trial.active = true;
                trial.startedAt = new Date();
            }

        } else {
            throw new Error('No trial data specified on start');
        }
        let interval

        const prom = new Promise<QrTrialData>((resolve, reject) => {

            // Every 10 seconds
            interval = setInterval(async () => {

                const data: QrTrialData = { ...this.data }; // await QrTrial.read();
                const sessionData: QrTrialData = get().trialData;
                const sessionTrialType = get().trial;

                if ((sessionData && sessionData.cancelled) || (sessionTrialType && sessionTrialType != this.type)) {

                    console.log("Session Trial " + (sessionData && sessionData.cancelled) ? "cancelled" : "is not the same type as 'this.type'" + ", clearing interval check...");
                    clearInterval(interval);
                    resolve({ ...data, cancelled: sessionData.cancelled != null ? sessionData.cancelled : false });
                    return;

                } else {
                    // console.log("Running Check:", QrTrial.QR_TRIAL_NAME);
                    // console.log("Usage Time: " + this.getUsageTime())

                    if (!this.isExpired()) {

                        if (!this.isStillValid()) {
                            // Time exceeded
                            data.active = false;
                            data.endedAt = new Date();
                            data.finished = true;
                            this.data = data;// await QrTrial.save(data);
                            console.log('Trial Finished!');
                            // console.log('Clearing Check Interval!!');
                            clearInterval(interval);
                            resolve(data);
                        } else {
                            // Time NOT exceeded
                            // Enable QR premium features
                            data.active = true;
                            data.endedAt = null;
                            data.finished = false;
                            this.data = data;// await QrTrial.save(data);

                        }
                        set ? set({ trial: this.type, trialData: data, updated: new Date().toISOString() }) : null;

                    } else {
                        //First day of use was not today, finish trial
                        console.log("Deleting QR trial as it was not initiated today...");
                        data.active = false;
                        data.endedAt = new Date();
                        data.finished = true;
                        this.data = data;
                        // console.log('Clearing Check Interval!!');
                        clearInterval(interval);
                        resolve(data);

                        set ? set({ trial: TrialTypes.NONE, trialData: null, updated: new Date().toISOString() }) : null;
                    }// If First day of use was not today but beggined a QRTrial, trialData should have gotten updated up

                }
            }, Settings.trialCheckIntervalTime)
        });
        return prom;

    }


}
