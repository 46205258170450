import { CommonActions, NavigationProp } from '@react-navigation/native';
import { ColorValue, Dimensions, GestureResponderEvent, Image, Linking, Platform, ScaledSize, Share } from 'react-native';
import { Article, ArticleInterface, ProfileModel } from 'systemDomain';
import { getLocalhost } from '../../environments';
import ActionApi, { ACTION_NAMES } from '../api/ActionApi';
import { Navigation } from '../types';
import { themes } from './theme/ThemeBuilder';
import { fixed_colors } from './theme/colors';


export const VIDEO_FORMATS = [
  'mp4', 'mov', 'wmv', 'avi', 'webm'
]

export const AUDIO_FORMATS = [
  'mp3', 'wav', 'aac', 'flac'
]

const theme = themes?.default
/**
 * This method is used in order to perform batch caching for images 'if necesary'.
 *
 * Makes use of the {@link Image.queryCache} and {@link Image.prefetch} methods in order to test if an image has been already cached
 * and tries to cache it if it was not.
 *
 * [See the docs](https://reactnative.dev/docs/image#prefetch)
 *
 * @param urls array of accesible URL images. TODO: does this work on locally hosted images?
 * @param verbose flag in order to indicate that some console output of the proccess should be made.
 * @param verbose flag in order to make the function retry to fetch each URL that failed during the first try. defaults to `true`
 */
export const cacheURLPictures = async (urls: string[], verbose = false, retry = true) => {

  let locations;

  locations = await Image.queryCache(urls);

  urls.forEach(async (url) => {

    if (url && url != '' && url.length > 5) {

      const cached = locations[url];

      if (cached) {
        verbose ? console.log('Image was catched!', url) : null
        verbose ? console.log(locations) : null
      } else {
        verbose ? console.log('Image needs to be catched: ' + url) : null

        try {
          console.log(url)
          const res = await Image.prefetch(url);
          res ? verbose ? console.log('catched: ' + url) : null : verbose ? console.log("Not cached") : null
        } catch (e) {
          if (verbose) {

            console.error('Error while caching picture: ' + url)
            console.error(e);

          }

          if (retry)
            setTimeout(async () => {
              try {
                const res2 = await Image.prefetch(url);
              } catch (e) { verbose ? console.warn('Still Failing: ' + url) : null }
            }, 300)

        }
      }
    } else {
      verbose ? console.log('Invalid URL: ' + new String(url)) : null;
    }
  })

}

export const emailValidator = (email: string) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 8) return 'El email tiene que tener más de 8 caracteres.';
  if (!re.test(email)) return 'El email debe tener un formato válido.';

  return null;
};

export const passwordValidator = (password: string) => {
  if (!password || password.length <= 3) return 'La contraseña tiene que tener más de 3 caractéres.';

  return null;
};
export const entepriseKeyValidator = (password: string) => {
  if (!password || password.length <= 0) return 'No has introducido una key de empresa';

  return null;
};

export const nameValidator = (name: string) => {
  if (!name || name.length <= 0) return 'Name cannot be empty.';

  return null;
};

export const getMinutesSecondsFromMilliseconds = (ms: number) => {
  const totalSeconds = ms / 1000
  const seconds = String(Math.floor(totalSeconds % 60))
  const minutes = String(Math.floor(totalSeconds / 60))

  return minutes.padStart(1, '0') + ':' + seconds.padStart(2, '0')
}



/**
 *
 * DEPRECATED / NOW NEWSPAPERS ARE FETCHED FROM DATABSE AND STORED IN AppDataStore
 *
 */
// export const getSignedNewsPapers = () => {
//   return [
//     // "elpais",
//     //"elmundo",
//     // "theGuardian",
//     // "elConfidencial",
//     //"Marca",
//     // "cincodias",
//     // "elMundoDeportivo",
//     // "investing",
//     "okDiario",
//     // "elPeriodico",
//     // "abc",
//     // "As",
//     // "elDiario",
//     // "euractiv",
//     // "clarin",
//     //"EFE",
//     "europapress",
//     "ejePrime",
//     "plantaDoce",
//     "palco23",
//     "modaEs",
//     "muyPymes",
//     "elEspanol",
//     "libertadDigital",
//     // "theAthletic",
//     // "politico",
//     "theConversation",
//     "cineMasComics",
//     "filmin",
//     "superficcion",
//     // "microsiervos",
//     "objetto",
//     "graffica",
//     "mussica",
//     "universoDeEmociones",
//     "dailyMirror",
//     "dailyRecord",
//     "dailyStar",
//     "buzz",
//     "footballLondon",
//     "servimedia",
//     "beSoccer",
//     "economistJurist",
//     //"imfarmacias",
//     "hidrogenoverde",
//     "energynews",
//     "latribunademadridnorte",
//     // "actualidadjoven"
//   ]
// }



/**
 *
 * DEPRECATED / NOW NEWSPAPERS ARE FETCHED FROM DATABSE AND STORED IN AppDataStore
 *
 */
// export const getNewspapers = (category?: string) => {
//   if (category == "generalistas") {
//     return [
//       "elpais",
//       // "elmundo",
//       //"elConfidencial",
//       "okDiario",
//       //"elPeriodico",
//       "abc",
//       //"elDiario",
//       //"EFE",
//       "europapress",
//       "elEspanol",
//       "libertadDigital",
//       "theConversation",
//       "serviMedia",
//       // "lavanguardia",
//       "publico",
//       "eldiario",
//       "20minutos",
//       // "actualidadjoven",
//       "65ymas",
//       // "prnoticias",
//       "elindependiente"
//     ];
//   } else if (category == "deportes") {
//     return [
//       "As",
//       "footballLondon",
//       "beSoccer",
//       "triatlonweb",
//       "ciclismoafondo",
//       "soycorredor",
//       "marca",
//       "sport",
//       "mundodeportivo",
//       "sportlife",
//       // "sportsillustrated"
//       // "theAthletic"
//     ];
//   }
//   else if (category == "inmobiliaria") {
//     return [
//       "ejePrime",
//       "idealista"
//     ];
//   }
//   else if (category == "economia") {
//     return ["cincodias", "investing", "palco23", "muyPymes", "economistJurist", "eleconomista", "decrypt"];
//   } else if (category == "entretenimiento") {
//     return [
//       "cineMasComics",
//       "filmin",
//       "superficcion",
//       "fotogramas",
//       "playground"
//     ];
//   }
//   else if (category == "corazon") {
//     return [
//       "lecturas",
//       "vanitatis",
//       "hola"
//     ];
//   }
//   else if (category == "energía") {
//     return [
//       "hidrogenoverde",
//       "energynews",
//       // "elperiodicodelaenergia"
//     ];
//   }
//   else if (category == "cine") {
//     return [
//       "cineMasComics",
//       "filmin",
//       "superficcion",
//       "fotogramas"
//     ];
//   }
//   else if (category == "ciencia") {
//     return [
//       "phys",
//       "nationalgeographic"
//     ];
//   }
//   else if (category == "internacional") {
//     return [/*"bbc"*/, "clarin", "euractiv", "theGuardian", "politico", "dailyMirror",
//       "dailyRecord",
//       "dailyStar",
//       "euronews",
//       "nytimes",
//       "buzz",
//       // "newsweek"
//     ];
//   } else if (category == "tecnologia") {
//     return [
//       "microsiervos", "thespoon", "hackaday", "xataka"
//     ];
//   } else if (category == "diseno") {
//     return [
//       "objetto",
//       "graffica",
//       "arquitecturaydiseno"
//     ];
//   } else if (category == "musica") {
//     return [
//       "mussica",
//       // "mondosonoro"
//     ];
//   } else if (category == "salud") {
//     return [
//       "plantaDoce", "universoDeEmociones", "imFarmacias"
//     ];
//   } else if (category == "automovil") {
//     return [
//       "infotaller", "rutadeltransporte"
//     ];
//   } else if (category == "moda") {
//     return [
//       "modaEs", "mujerhoy"
//     ];
//   } else if (category == "startup") {
//     return [
//       "elreferente", "theverge", "emprendedores"
//     ];
//   } else if (category == "local") {
//     return [
//       "diariodesevilla", "zamora24h", "latribunademadridnorte"
//     ];
//   } else {
//     //All newspapers (using site name)
//     return [
//       "elpais",
//       //"elmundo",
//       "theGuardian",
//       // "elConfidencial",
//       //"Marca",
//       "cincodias",
//       // "elMundoDeportivo",
//       "investing",
//       "okDiario",
//       // "elPeriodico",
//       "abc",
//       "As",
//       // "elDiario",
//       "euractiv",
//       "clarin",
//       //"EFE",
//       "europapress",
//       "ejePrime",
//       "plantaDoce",
//       "palco23",
//       "modaEs",
//       "muyPymes",
//       "elEspanol",
//       "libertadDigital",
//       // "theAthletic",
//       "politico",
//       "theConversation",
//       "cineMasComics",
//       "filmin",
//       "superficcion",
//       "microsiervos",
//       "objetto",
//       "graffica",
//       "mussica",
//       "universoDeEmociones",
//       "dailyMirror",
//       "dailyRecord",
//       "dailyStar",
//       "buzz",
//       "footballLondon",
//       "serviMedia",
//       "beSoccer",
//       "economistJurist",
//       "fotogramas",
//       "eleconomista",
//       "imFarmacias",
//       "decrypt",
//       "vanitatis",
//       "idealista",
//       // "lavanguardia",
//       "thespoon",
//       "hackaday",
//       // "bbc",
//       "diariodesevilla",
//       "infotaller",
//       "rutadeltransporte",
//       "xataka",
//       "zamora24h",
//       "triatlonweb",
//       "ciclismoafondo",
//       "soycorredor",
//       "phys",
//       "lecturas",
//       "arquitecturaydiseno",
//       "nationalgeographic",
//       "publico",
//       "nytimes",
//       "eldiario",
//       "marca",
//       "sport",
//       "mundodeportivo",
//       "20minutos",
//       "hidrogenoverde",
//       "energynews",
//       //"elperiodicodelaenergia",
//       "latribunademadridnorte",
//       // "actualidadjoven",
//       "mujerhoy",
//       "playground",
//       "hola",
//       "euronews",
//       "65ymas",
//       // "prnoticias",
//       "elindependiente",
//       "elreferente",
//       "theverge",
//       // "sportsillustrated",
//       // "newsweek",
//       "sportlife",
//       "emprendedores",
//       // "mondosonoro"x
//     ];
//   }
// }


/**
 *
 * Tries to open the given url in a new tab or in the system default browser.
 *
 * This method is meant to be executed either in browser or native environment.
 *
 * @param url the url to be opened in the browser
 */
export const openInBrowser = async (url: string) => {

  if (Platform.OS == 'web') {
    return openNewTabLink(url);
  } else {
    return openUrlBrowserNative(url)
  }

}


/**
 * USE ONLY IF PLATFORM IS 'ios' / 'android'
 *
 * @returns `true` if the lik was sucessfully opened, `false` otherwise
 *
 */
export const openUrlBrowserNative = async (url: string) => {

  if (url == null) {
    return false;
  }

  try {
    await Linking.openURL(url);
    return true;
  } catch (e) {
    console.warn('not supported url: ' + url)
    return false;
  }

}

function debugBase64(base64URL) {
  var win = window.open();
  win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

/**
 * USE ONLY IF PLATFORM IS WEB
 *
 * @returns `true` if the lik was sucessfully opened, `false` otherwise
 */
export const openNewTabLink = (url: string | URL) => {

  if (url == null) {
    return false;
  }

  try {

    if (typeof url == 'string' && url.startsWith('data:')) {

      debugBase64(url)

    } else {

      const link = Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url,
        style: "display: none"
      });

      link.click();
      link.remove();
    }

  } catch (e) {
    console.error(e);
    return false;
  }

  return true;

}

/**
 *
 * DEPRECATED / NOW NEWSPAPERS ARE FETCHED FROM DATABSE AND STORED IN AppDataStore
 *
 */
// export const siteTranslator = (site: string) => {

//   if (site) {

//     if (site.toLowerCase() == "elpais") {
//       return "El País";
//     }
//     else if (site.toLowerCase() == "abc") {
//       return "ABC";
//     }
//     else if (site.toLowerCase() == "elmundodeportivo") {
//       return "El Mundo Deportivo";
//     }
//     else if (site.toLowerCase() == "elmundo") {
//       return "El Mundo";
//     }
//     else if (site.toLowerCase() == "theguardian") {
//       return "The Guardian";
//     }
//     else if (site.toLowerCase() == "elconfidencial") {
//       return "El Confidencial";
//     }
//     else if (site.toLowerCase() == "marca") {
//       return "Marca";
//     }
//     else if (site.toLowerCase() == "cincodias") {
//       return "Cinco Días";
//     }
//     else if (site.toLowerCase() == "as") {
//       return "As";
//     }
//     else if (site.toLowerCase() == "investing") {
//       return "Investing";
//     }
//     else if (site.toLowerCase() == "eldiario") {
//       return "El Diario";
//     }
//     else if (site.toLowerCase() == "okdiario") {
//       return "OkDiario";
//     }
//     else if (site.toLowerCase() == "elperiodico") {
//       return "El Periódico";
//     }
//     else if (site.toLowerCase() == "euractiv") {
//       return "Euractiv";
//     }
//     else if (site.toLowerCase() == "clarin") {
//       return "Clarín";
//     }
//     else if (site.toLowerCase() == "efe") {
//       return "EFE";
//     }
//     else if (site.toLowerCase() == "europapress") {
//       return "Europa Press";
//     }
//     else if (site.toLowerCase() == "ejeprime") {
//       return "EjePrime";
//     }
//     else if (site.toLowerCase() == "modaes") {
//       return "ModaEs";
//     }
//     else if (site.toLowerCase() == "muypymes") {
//       return "MuyPymes";
//     }
//     else if (site.toLowerCase() == "palco23") {
//       return "Palco23";
//     }
//     else if (site.toLowerCase() == "plantadoce") {
//       return "PlantaDoce";
//     }
//     else if (site.toLowerCase() == "libertaddigital") {
//       return "Libertad Digital";
//     } else if (site.toLowerCase() == "elespanol") {
//       return "El Español";
//     } else if (site.toLowerCase() == "theconversation") {
//       return "The Conversation";
//     } else if (site.toLowerCase() == "cinemascomics") {
//       return "CineMasComics";
//     } else if (site.toLowerCase() == "superficcion") {
//       return "Super-Ficción";
//     } else if (site.toLowerCase() == "politico") {
//       return "Político";
//     } else if (site.toLowerCase() == "microsiervos") {
//       return "Microsiervos";
//     } else if (site.toLowerCase() == "graffica") {
//       return "Gráffica";
//     } else if (site.toLowerCase() == "objetto") {
//       return "Objetto";
//     } else if (site.toLowerCase() == "mussica") {
//       return "Mússica";
//     } else if (site.toLowerCase() == "universodeemociones") {
//       return "Universo de Emociones";
//     } else if (site.toLowerCase() == "dailymirror") {
//       return "Daily Mirror";
//     } else if (site.toLowerCase() == "dailystar") {
//       return "Daily Star";
//     } else if (site.toLowerCase() == "dailyrecord") {
//       return "Daily Record";
//     } else if (site.toLowerCase() == "buzz") {
//       return "Buzz";
//     } else if (site.toLowerCase() == "footballlondon") {
//       return "Football London";
//     } else if (site.toLowerCase() == "servimedia") {
//       return "Servimedia";
//     } else if (site.toLowerCase() == "besoccer") {
//       return "BeSoccer";
//     } else if (site.toLowerCase() == "economistjurist") {
//       return "Economist & Jurist";
//     } else if (site.toLowerCase() == "fotogramas") {
//       return "Fotogramas";
//     } else if (site.toLowerCase() == "eleconomista") {
//       return "El Economista";
//     } else if (site.toLowerCase() == "imfarmacias") {
//       return "ImFarmacias";
//     } else if (site.toLowerCase() == "lavanguardia") {
//       return "La Vanguardia";
//     } else if (site.toLowerCase() == "decrypt") {
//       return "Decrypt";
//     } else if (site.toLowerCase() == "idealista") {
//       return "Idealista";
//     } else if (site.toLowerCase() == "vanitatis") {
//       return "Vanitatis";
//     } else if (site.toLowerCase() == "thespoon") {
//       return "The Spoon";
//     } else if (site.toLowerCase() == "hackaday") {
//       return "Hackaday";
//     } else if (site.toLowerCase() == "bbc") {
//       return "BBC";
//     } else if (site.toLowerCase() == "diariodesevilla") {
//       return "Diario De Sevilla";
//     } else if (site.toLowerCase() == "infotaller") {
//       return "Info Taller";
//     } else if (site.toLowerCase() == "rutadeltransporte") {
//       return "Ruta del Transporte";
//     } else if (site.toLowerCase() == "xataka") {
//       return "Xataka";
//     } else if (site.toLowerCase() == "triatlonweb") {
//       return "Triatlón";
//     } else if (site.toLowerCase() == "ciclismoafondo") {
//       return "Ciclismo a Fondo";
//     } else if (site.toLowerCase() == "soycorredor") {
//       return "Soy Corredor";
//     } else if (site.toLowerCase() == "phys") {
//       return "Phys";
//     } else if (site.toLowerCase() == "arquitecturaydiseno") {
//       return "Arquitectura y Diseño";
//     } else if (site.toLowerCase() == "lecturas") {
//       return "Lecturas";
//     } else if (site.toLowerCase() == "nationalgeographic") {
//       return "National Geographic";
//     } else if (site.toLowerCase() == "publico") {
//       return "Público";
//     } else if (site.toLowerCase() == "nytimes") {
//       return "The New York Times";
//     } else if (site.toLowerCase() == "eldiario") {
//       return "elDiario";
//     } else if (site.toLowerCase() == "marca") {
//       return "MARCA";
//     } else if (site.toLowerCase() == "sport") {
//       return "SPORT";
//     } else if (site.toLowerCase() == "mundodeportivo") {
//       return "Mundo Deportivo";
//     } else if (site.toLowerCase() == "20minutos") {
//       return "20minutos";
//     } else if (site.toLowerCase() == "hidrogenoverde") {
//       return "Hidrógeno Verde";
//     } else if (site.toLowerCase() == "energynews") {
//       return "EnergyNews";
//     // } else if (site.toLowerCase() == "elperiodicodelaenergia") {
//     //   return "El Periódico de la Energía";
//     } else if (site.toLowerCase() == "latribunademadridnorte") {
//       return "La Tribuna de Madrid Norte";
//     } else if (site.toLowerCase() == "playground") {
//       return "PlayGround";
//     // } else if (site.toLowerCase() == "actualidadjoven") {
//     //   return "Actualidad Joven";
//     } else if (site.toLowerCase() == "mujerhoy") {
//       return "Mujer Hoy";
//     } else if (site.toLowerCase() == "hola") {
//       return "¡HOLA!";
//     } else if (site.toLowerCase() == "euronews") {
//       return "Euronews";
//     } else if (site.toLowerCase() == "65ymas") {
//       return "65YMÁS";
//     // // } else if (site.toLowerCase() == "prnoticias") {
//     // //   return "prnoticias";
//     // } else if (site.toLowerCase() == "prnoticias") {
//     //   return "prnoticias";
//     // } else if (site.toLowerCase() == "prnoticias") {
//     //   return "prnoticias";
//     } else if (site.toLowerCase() == "elindependiente") {
//       return "El Independiente";
//     } else if (site.toLowerCase() == "elreferente") {
//       return "El Referente";
//     } else if (site.toLowerCase() == "theverge") {
//       return "THE VERGE";
//     // } else if (site.toLowerCase() == "sportsillustrated") {
//     //   return "Sports Illustrated";
//     // } else if (site.toLowerCase() == "newsweek") {
//     //   return "Newsweek";
//     } else if (site.toLowerCase() == "sportlife") {
//       return "SportLife";
//     } else if (site.toLowerCase() == "emprendedores") {
//       return "Emprendedores";
//     // } else if (site.toLowerCase() == "mondosonoro") {
//     //   return "mondo sonoro";
//     }
//     else {
//       return site;
//     }
//   } else {
//     return "";
//   }
// }


export const listenScreenChanges = (handler: ({ window, screen }: { window: ScaledSize, screen: ScaledSize }) => void) => {
  Dimensions.addEventListener('change', handler);
}

export const getScreenSize = (): ScaledSize => {
  return Dimensions.get('window');
}

export const getNumColumnsFlatlist = (screenWidth?: number) => {

  const width = screenWidth ? screenWidth : Dimensions.get('window').width;

  if (width > 2760) {
    // more than 4K
    return 10;
  } else if (width > 2320) {
    // more than 4K
    return 8;
  } else if (width > 1880) {
    // more than 4K
    return 6;
  } else if (width > 1440) {
    // 4K
    return 5;
  } else if (width > 1024) {
    // Laptop L
    return 4;
  } else if (width > 768) {
    // Tablet
    return 3;
  } else if (width > 525) {
    // Mobile Large
    return 2;
  }
  else {
    //Default
    return 1
  }
}

export const getCardWidth = (screenWidth?: number) => {

  const width = screenWidth ? screenWidth : Dimensions.get('window').width - 150;
  let portions = getNumColumnsFlatlist(width);
  let cardW = Math.round((width / portions))
  return cardW;


}


/** This function is used in order to get a minimalistic object that serves as a report for an article.
 *  Should be used in the action reporting.
 *
 * @returns a set of fields relevant for article reporting
 */
export const getArticleReport = (article: Partial<ArticleInterface | Article>) => {

  if (article == undefined || article == null) return null;

  //@ts-ignore
  if (article.props) {
    //@ts-ignore
    article = article.props;
  }

  let report = {
    title: article?.title,
    //@ts-ignore
    publisher: article.publisher ?? article?.site,
    author: article?.author,
    //@ts-ignore
    category: article?.section ?? article?.category,
    url: article?.url,
    type: article?.type,
    webview: article?.webview
  }

  return report;

}





export const getWindowType = (screenWidth?: number) => {

  const width = screenWidth ? screenWidth : Dimensions.get('window').width;

  if (width > 1440) {
    // 4K
    return 4;
  } else if (width > 1024) {
    // Laptop L
    return 4;
  } else if (width > 768) {
    // Tablet
    return 3;
  } else if (width > 425) {
    // Mobile Large
    return 2;
  }
  else {
    //Default
    return 1
  }
}


export const shareURL = async (url: string, event: GestureResponderEvent, sessionStoreSnapShot: any, profile?: ProfileModel, urlType?: string) => {

  if (event && event.preventDefault) {
    event.preventDefault();
  }

  try {
    const urlToShare =
      url ??
      "Ha habido un problema";

    const result = await Share.share({
      message: 'Revisa este enlace que he encontrado en News To You!: ' + urlToShare,
      url: urlToShare == 'Ha habido un problema.' ? getLocalhost : urlToShare,
      title: "Compartido desde News To You.",
    }).finally(async () => {
      ActionApi.createFrom({ name: ACTION_NAMES.SHARE_URL, data: { url: url, urlType } })
    })
  } catch (error) { }
};


export const shareArticle = async (article: Article) => {

  try {
    // let url = article?.get("url") && article?.get('url').startsWith('http') ? new URL(article?.get('url')) : null
    let url = article.url && article.url.startsWith('http') ? new URL(article.url) : null

    const ntyUrl = new URL("/article/" + article._id, getLocalhost)
    // ntyUrl.searchParams.append('newspaper', article?.get('site'))

    //if it is different type return custom url
    article.type == 'spotify' ? url = new URL('/episode/' + article.url, 'https://open.spotify.com/') : null

    let message = "Revisa esta noticia que he visto en News To You!: "

    if (url) {
      message += url.toString() + ' / '
    }

    message += ntyUrl

    const result = await Share.share({
      message: message,
      url: article.webview ? url.toString() : ntyUrl.toString(),
      title: "News To You: " + article.title,
    }).finally(async () => {
      ActionApi.createFrom({ name: ACTION_NAMES.SHARE_ARTICLE, data: getArticleReport(article) });
    })
  } catch (error) { }
};


/**
 * Given an spotify article try to open the app with uri scheme :
 *  ' spotify://spotify:episode: x ' ' spotify:episode: x '
 *
 * If app cannot be opened navigate to url on browser
 *
 * @param article
 * @returns `void`
 */
export const openSpotifyPodcastLink = async (article: Article, profile?: ProfileModel) => {

  // For Article type compatibility
  // @ts-ignore
  const article_url = article.url ?? article?.props?.url;

  const uri = 'spotify:episode:' + article_url;
  const uri_with_protocol = 'spotify://spotify:episode:' + article_url;
  const url = 'https://open.spotify.com/episode/' + article_url;
  const urls = [uri, uri_with_protocol, url];

  //register navigation
  ActionApi.createFrom({ name: ACTION_NAMES.GO_TO, data: { url, params: { type: article?.type, site: article.brand ?? article?.site } } })

  if (Platform.OS == 'web') {
    openNewTabLink(url)
  }
  else {
    let done = false;
    for (const link of urls) {
      try {
        Linking.openURL(link);
        done = true;
        break;
      } catch (e) {
        console.warn(e)
        done = false;
      }
    }
    return done;
  }
}


export const isSpecialType = (article) => article?.props?.type == 'spotify' || article?.props?.type == 'twitter' ? true : false

//determine type and open correspondent link
export const openCusomLink = (article) => {

  if (article.type == 'spotify' || article?.props?.type == 'spotify') return openSpotifyPodcastLink(article)

}



const packageName = 'com.nty.app';

export const openAppInAndroMarket = () => {

  console.log(Platform.OS);
  if (Platform.OS != 'web') {
    Linking.openURL('market://details?id=' + packageName);
  } else {
    window.open('https://play.google.com/store/apps/details?id=' + packageName, '_blank');
  }


}

const appId = '1571145781';

export const openAppInIOSMarket = () => {

  // console.log(Platform.OS);
  if (Platform.OS != 'web') {
    Linking.openURL(`https://apps.apple.com/es/app/news-to-you/id${appId}`);
  } else {
    window.open(`https://apps.apple.com/es/app/news-to-you/id${appId}`, '_blank');
  }


}

export const openAppInMarketByPlatform = () => {
  Platform.OS == 'android' ? openAppInAndroMarket() : Platform.OS == 'ios' ? openAppInIOSMarket() : openAppInIOSMarket(); openAppInAndroMarket();

}


/**
 * Function used to return a specific string with a maximum length fixed.
 *
 * If the passed in 'text' length is greater than the 'maxChars' argument, then the input text gets substring'ed to that fixed length and gets three dots appended.
 *
 * Theres a 'fallBackValue' parameter that can be used to pass in a fallback text to return in case anything goes wrong.
 *
 * @param text
 * @param maxChars
 * @param fallBackValue
 * @returns
 */
export const maximumChars = (text: string, maxChars?: number, fallBackValue: string = ''): string => {
  try {
    if (!text) {
      return fallBackValue;
    }

    if (maxChars !== undefined && text.length > maxChars) {
      return text.substring(0, maxChars) + '...';
    }

    return text;
  } catch {
    return fallBackValue;
  }
}

/**
 *
 * This function is meant to work only with hex colors.
 * If no hex color is passed, @see theme.colors.background
 *
 * @param color
 * @returns
 */
export const getContrastColor: (color: string | ColorValue) => ColorValue = (color: string | ColorValue) => {

  let hex: string;
  if (typeof color == 'string') {
    hex = color;
  } else {
    hex = color?.toString();
  }
  if (hex.indexOf('#') == -1) {
    //is not a hex, cannot guess
    return theme.colors?.background[500];
  }

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const r = Number.parseInt(hex.slice(0, 2), 16),
    g = Number.parseInt(hex.slice(2, 4), 16),
    b = Number.parseInt(hex.slice(4, 6), 16);

  // http://stackoverflow.com/a/3943023/112731
  // Get the intensities of the individual colors and sum them up to get overall intensity
  const yiq = (r * 0.299 + g * 0.587 + b * 0.114)

  const contrastColor: ColorValue = yiq >= 190
    ? fixed_colors.black[400]
    : fixed_colors.white[300];

  return contrastColor;

}

/**
 * Given a category name (string) returns the hex color mapped in core/theme.tsx `theme2.colors.categories` for that category.
 * If found, the hex color string is returned.
 *
 * If not found, by default `theme.colors.background` hex string gets returned, but you can specify this method to return
 * a random color instead by passing `random` argument as true.
 *
 *
 * @param category
 * @param random if `true` a random color would get returned if category color was not found.
 * @returns
 */
export const getCategoryBG = (category, random = false) => {

  let normalized;
  if ((typeof category) == 'string') {
    normalized = category.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
  } else {
    normalized = category.item.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
  }

  //const normalized = category;
  const color = getRandomColor();


  return color;


}

export const containsSameStr = (arr1: string[], arr2: string[]) => {

  arr1.sort();
  arr2.sort();

  return arr1.join(',') == arr2.join(',');

}

/**
 * Compare to javascript objects.
 *
 * @param a
 * @param b
 * @returns `true` if the string comparision of the JSON stringyfied objects are the same, `false` otherwise
 */
export const compareObjects: (a: object, b: object) => boolean = (a: object, b: object) => {

  try {
    return JSON.stringify(a) === JSON.stringify(b)
  } catch (e) {
    console.error('Error comparing objects', e)
    return false
  }

}

export const containsSame = (arr1: any[], arr2: any[]) => {

  if (arr1?.length != arr2?.length) {
    return false;
  }

  const str1 = JSON.stringify(arr1?.sort())
  const str2 = JSON.stringify(arr2?.sort())

  return str1 === str2;

}

export const resetStackNavigatorNew = (navigation: NavigationProp<any>, routesToEliminate: string[] = []) => {
  navigation.dispatch(state => {
    // Remove the home route from the stack
    const routes = state.routes.filter(r => routesToEliminate.indexOf(r.name) === -1 && routesToEliminate.indexOf(r.name.toLowerCase()) === -1);

    return CommonActions.reset({
      ...state,
      routes,
      index: routes.length,
    });
  });
}

export const getRandomColor = () => {
  return '#' + (Math.random().toString(16) + "000000").substring(2, 8)
}

export const resetStackNavigator = (routeName: string, navigation: Navigation | any) => {
  const resetAction = CommonActions.reset({
    index: 1,
    routes: [{ name: routeName }]
  });
  navigation.dispatch(resetAction);
}

export const monthNames = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEPT", "OCT", "NOV", "DIC"]
export const noImage = 'https://www.allianceplast.com/wp-content/uploads/2017/11/no-image.png';


export const getCategoryLabel = (categoryKeyName: string) => {

  const key = categoryKeyName ? categoryKeyName.toLowerCase() : null;

  switch (categoryKeyName) {

    case null: return "";
    case "inmobiliaria": return "Sector Inmobiliario";
    case "tecnologia": return "Tecnología";
    case "generalistas": return "Generalistas";
    case "economia": return "Economía";
    case "tecnologia": return "Tecnología";
    case "internacional": return "Internacional";
    case "deportes": return "Deportes";
    case "salud": return "Saluld";
    case "entretenimiento": return "Entretenimiento";
    case "energía": return "Energía";
    case "automovil": return "Automóvil";
    case "moda": return "Moda";
    case "sratup": return "Startup";
    default:
      try {
        return categoryKeyName[0].toLowerCase() + categoryKeyName.substring(1);
      } catch (e) {
        return categoryKeyName;
      }
  }

}

export function extractKeywordsFromFeed(feed: any): string[] {
  let keywordsSet: Set<string> = new Set();

  if (!feed) {
    return []
  }

  if (feed.keywords) {
    feed.keywords.forEach(keyword => keywordsSet.add(keyword));
  }

  if (feed.conditions?.keywords) {
    feed.conditions.keywords.forEach(keyword => keywordsSet.add(keyword));
  }

  if (feed.interests) {
    feed.interests.forEach(interest => {
      if (interest.conditions?.keywords) {
        interest.conditions.keywords.forEach(keyword => keywordsSet.add(keyword));
      }
    });
  }

  //TODO: Remove the 'por' specific filter or use an stopwords array
  return Array.from(keywordsSet).filter(k => k.length > 2 && k != 'por')
}

const getKeywordsArr = (
  field: { keyword: string, enterprise?: string, feedId?: string }[],
  enterprise_filter?: string,
  feed_filter?: string) => {

  if (!field || field.length === 0) return []

  if (typeof field[0] != 'string' && isFieldObject(field)) {
    return extractKeywordsFromObjects(field, enterprise_filter, feed_filter);
  }

  return field;
}

const isFieldObject = (field) => typeof field[0] === 'object';

const extractKeywordsFromObjects = (field: { keyword: string, enterprise?: string, feedId?: string }[], enterprise_filter, feed_filter) => {
  let filtered = field;

  if (enterprise_filter) {
    filtered = filterByEnterprise(filtered, enterprise_filter);
  }

  if (feed_filter) {
    filtered = filterByFeed(filtered, feed_filter);
  }

  return extractKeywords(filtered);
}

const filterByEnterprise = (field, enterprise_filter) =>
  field.filter(k => k.enterprise == undefined || k.enterprise == enterprise_filter);

const filterByFeed = (field, feed_filter) =>
  field.filter(k => k.feedId == undefined || k.feedId == feed_filter);

const extractKeywords = (field) => field.map(k => k.keyword);

export function extractKeywordsFromArticle(article: any, enterprise_filter?: string, feed_filter?: string): string[] {

  let keywordsSet: Set<string> = new Set();

  // keywordsSet.add('k')

  // console.log(keywordsSet)

  // if (article.keywords_locations) {
  //   getKeywordsArr(article.matches, enterprise_filter, feed_filter).forEach(k => keywordsSet.add(k))
  // }

  // if (article.keywords) {
  //   getKeywordsArr(article.keywords, enterprise_filter, feed_filter).forEach(k => keywordsSet.add(k))
  // }

  if (article.keywords_info) {
    getKeywordsArr(article.keywords_info, enterprise_filter, feed_filter).forEach(k => keywordsSet.add(k))
  }

  // if (article.distinct_keywords) {
  //   getKeywordsArr(article.distinct_keywords, enterprise_filter, feed_filter).forEach(k => keywordsSet.add(k))
  // }

  //TODO: Remove the 'por' specific filter or use an stopwords array
  const keywords = Array.from(keywordsSet).filter(k => k.length > 2 && k != 'por')
  // if (article._id == '648bc5a839edd8d1465016bf')
  //   console.log({ returnedKeywords: keywords, keywordsSet, enterprise_filter, feed_filter, info: article.keywords_info, article: article.title, _id: article._id, info_feed: article.keywords_info.find((k) => k.feedId == feed_filter && k.enterprise == enterprise_filter) })
  return keywords
}


