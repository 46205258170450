import { createDrawerNavigator, DrawerNavigationEventMap, DrawerNavigationOptions } from "@react-navigation/drawer";
import { DrawerNavigationState, LinkingOptions, NavigationContainer, NavigationContainerRef, RouteConfig, StackNavigationState } from "@react-navigation/native";
import { createStackNavigator, StackNavigationEventMap, StackNavigationOptions } from "@react-navigation/stack";
import React, { useEffect, useRef } from "react";
import { Platform } from "react-native";
import ContextModalsLoader from "../../app/context/components/ ContextModalsLoader";
import RegisterModal from "../../app/context/components/register/RegisterModal";
import Menu from "../../app/Menu";

import { ChangePasswordScreen, ForgotPasswordScreen, LoginScreen, RegisterScreen } from "../../pages";
import LoginSelectionScreen from "../../pages/LoginSelection";

import { screens_definition, ScreensParamsList } from "./screens/NavigationScreens";

import ContextAlertsLoader from "src/app/context/components/ContextAlertsLoader";
import ContextSnacksLoader from "src/app/context/components/ContextSnacksLoader";
import DatePickersLoader from "src/app/context/components/DatePickersLoader";
import { useAppTrialStore } from "src/context/appTrialStore";
import useSessionStore from "src/context/sessionStore";
import LogoutScreen from "../../pages/Logout";
import MainBottomTabsNavigator from "./MainBottomTabsNavigator";


const screens = screens_definition.reduce((screens, screen) => {
    screens[screen.name] = screen.route
    return screens
}, {})

const appScreens = {
    ...screens,
    NotFound: '/not-found'
}



export type DrawerNavigatorScreenDefinition = RouteConfig<ScreensParamsList, keyof ScreensParamsList, DrawerNavigationState<ScreensParamsList>, DrawerNavigationOptions, DrawerNavigationEventMap>
export type StackNavigatorScreenDefinition = RouteConfig<ScreensParamsList, keyof ScreensParamsList, StackNavigationState<ScreensParamsList>, StackNavigationOptions, StackNavigationEventMap>


const Drawer = createDrawerNavigator()
const Stack = createStackNavigator<ScreensParamsList>()

const HomeScreen = (props) => {

    return (

        <Drawer.Navigator
            useLegacyImplementation={Platform.OS == 'web'}
            screenOptions={{ headerShown: false, swipeEdgeWidth: 40, swipeMinDistance: 70 }}
            drawerContent={(props) => <Menu />}
        >
            <Drawer.Screen name="nty" component={MainBottomTabsNavigator} />
        </Drawer.Navigator>

    )

}


function RootNavigator(props) {


    const linking = React.useMemo(() => {

        const prefixes = ['nty://', 'https://thenewstoyou.com/', "https:/\/*.thenewstoyou.com/"]

        const linking: LinkingOptions<ScreensParamsList> = {
            prefixes: prefixes,
            config: {
                screens: appScreens
            }
        }
        return linking

    }, [])

    const navigatorRef: React.Ref<NavigationContainerRef<ScreensParamsList>> = useRef(null)

    useEffect(() => {

        if (/* !ready ||  */!navigatorRef.current) {
            // console.log('Not Yet ready')
            return
        } else {
            // console.log('Ready')
            // useAppSessionStore.getState().setNavigatorRef(navigatorRef.current)
            useSessionStore.getState().setNavigatorRef(navigatorRef.current)
        }

    }, [navigatorRef.current])


    // const isLoggedIn = React.useMemo(() => {
    //     // const {user, ready, _hasHydrated} = useAppSessionStore.getState()
    //     // console.log('RootNavigator session: ', {user, ready, _hasHydrated})
    //     // No need to use ready here because ready is false when hydrating
    //     return !isGuest() || useAppTrialStore.getState().isTrialActive()
    // }, [ready])
    const isLoggedIn =
        // const {user, ready, _hasHydrated} = useAppSessionStore.getState()
        // console.log('RootNavigator session: ', {user, ready, _hasHydrated})
        // No need to use ready here because ready is false when hydrating
        !useSessionStore.getState().isGuest || useAppTrialStore.getState().isTrialActive()



    return (

        <NavigationContainer ref={navigatorRef} linking={linking} onReady={() => { console.log('Done loading navigator'); props?.onReady ? props.onReady() : null }} >
            <Stack.Navigator
                initialRouteName={isLoggedIn ? 'home' : 'login'}
                screenOptions={{ headerShown: false }}
            >

                <Stack.Screen name="home" component={HomeScreen} />
                {screens_definition.map((s) =>
                    // @ts-ignore
                    <Stack.Screen
                        key={s.name}
                        {...s}
                    // options={{
                    //     ...s.options,
                    //     headerTransparent: s.backHeaderShown == true ? true : s.options?.headerTransparent,
                    //     headerShown: s.backHeaderShown == true ? true: s.options?.headerShown,
                    //     header: s.backHeaderShown == true ?  : s.options?.header
                    //     }}
                    />)}

                <Stack.Screen name="guest-welcome" component={LoginSelectionScreen} />
                {/*@ts-ignore*/}
                <Stack.Screen name="register" route={{ path: 'register', params: { email: String } }} component={RegisterScreen} />
                <Stack.Screen name="login" component={LoginScreen} />
                <Stack.Screen name="logout" component={LogoutScreen} />
                <Stack.Screen name="forgotpassword" component={ForgotPasswordScreen} />
                <Stack.Screen name="changepassword" component={ChangePasswordScreen} />


            </Stack.Navigator>
            <ContextModalsLoader />
            <RegisterModal />
            <ContextAlertsLoader />
            <ContextSnacksLoader />
            <DatePickersLoader />
        </NavigationContainer>

    )

}

export default RootNavigator;
