import { buildApiUrl, downloadFile } from 'src/core/http-utils';
import ApiCaller from '../core/ApiCaller';
import { SessionUser } from '../models/user';

const getApiCaller = (currentUser: SessionUser): ApiCaller => {
    const apiCaller = new ApiCaller(currentUser.token);
    return apiCaller
}

class StaticApi {

    constructor() {
    }

    static async getPolicyFile() {
        const url = buildApiUrl(`/v1/policyWeb`);
        await downloadFile({url, method: 'GET', override_filename: 'privacyPolicyWeb.pdf'})
    }

    static async getCookiesFile() {
        const url = buildApiUrl(`/v1/policyCookies`);
        await downloadFile({url, method: 'GET', override_filename: 'privacyPolicyCookies.pdf'})
    }

    static  downloadFile = async (blob, fileName) => {

            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            return url
    }

}

export default StaticApi;
