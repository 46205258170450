import React from 'react'
import { Platform, View, ScrollView } from 'react-native';
import { Snackbar } from 'react-native-paper';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import TextNty from '../../../components/nty/text/TextNty';
import { useSnackBar } from '../../snackBarStore';

function InfoMesageSnack() {

    const { visibleInfoMessage, infoMessage, hideInfoMessage } = useSnackBar();
	const colors = useAppTheme().colors


    return (
        <Snackbar
            visible={visibleInfoMessage}
            onDismiss={() => { hideInfoMessage() }}
            action={{
                label: 'Listo',
                color: colors.text_secondary[500]
            }}
            duration={2500}
            wrapperStyle={{ position: 'absolute', bottom: Platform.OS == 'web' ? 90 : 50 }}
            style={{ backgroundColor: colors.secondary[600], alignItems: 'stretch', zIndex: 1000, borderRadius: 10}} >
            {/* <View> */}
                {/* <ScrollView horizontal={true} style={{borderWidth: 1, borderColor: 'red', width: "100%"}} contentContainerStyle={{width: "100%", height: "100%"}}> */}
                    <TextNty style={{ color: colors.text_secondary[400] }} adjustsFontSizeToFit>{infoMessage}</TextNty>
                {/* </ScrollView> */}
            {/* </View> */}
        </Snackbar>
    )
}

export default InfoMesageSnack
