import React from "react";
import { Image, ScaledSize, StyleSheet, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import {
  IconButton,
  List,
  Modal,
  Paragraph,
  Portal,
  Switch,
  Title
} from "react-native-paper";
//import Animated, { useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';
import Logo from "../../../components/Logo";
import Storage from "../../../core/storage";
import { theme2 } from "../../../core/theme";
import { getScreenSize } from "../../../core/utils";

import StaticApi from "../../../api/Static.api";

import ButtonNty from "../nty/button/ButtonNty";

export interface CookiesResponse {
  responded: boolean;
  allAccepted: boolean;
  timestamp: string;
  responses: CookieState[];
}

export interface CookieState {
  name: string;
  type: string;
  accepted: boolean;
  timestamp: string;
}

const CookieAdvisor = (props: {
  visible: boolean;
  actionDone: (response: CookiesResponse) => void;
}) => {
  //   const rotation = useSharedValue(0);

  // const animatedStyle = useAnimatedStyle(() => {
  //     return {
  //     transform: [{ rotateZ: `${rotation.value}deg` }],
  //     };
  // });

  const containerMaxWidth = (dimensions: ScaledSize) => {
    let size;

    if (dimensions.width > 1300) {
      size = "35%";
    } else if (dimensions.width > 700) {
      size = "45%";
    } else if (dimensions.width > 550) {
      size = "65%";
    } else {
      size = "85%";
    }

    return size;
  };

  const containerMinWidth = (dimensions: ScaledSize) => {
    let size;

    if (dimensions.width > 1300) {
      size = "25%";
    } else if (dimensions.width > 700) {
      size = "35%";
    } else if (dimensions.width > 550) {
      size = "55%";
    } else {
      size = "75%";
    }

    return size;
  };

  const [configuring, setConfiguring] = React.useState(false);

  const [maxWidth, setMaxWidth] = React.useState(
    containerMaxWidth(getScreenSize())
  );
  const [minWidth, setMinWidth] = React.useState(
    containerMinWidth(getScreenSize())
  );


  React.useEffect(() => {
    setMaxWidth(containerMaxWidth(getScreenSize()));
    setMinWidth(containerMaxWidth(getScreenSize()));
    //Rotate Cookie
    //rotation.value = withRepeat(withTiming(10), 6, true);
  }, []);

  const styles = StyleSheet.create({
    red: { backgroundColor: "red" },
    blue: { backgroundColor: "blue" },
    yellow: { backgroundColor: "yellow" },
    green: { backgroundColor: "green" },
    imgView: {
      flex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    headerTextView: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    heading: {
      fontSize: 24,
      fontWeight: "bold",
    },
    container: {
      flex: 1,
      flexDirection: "column",
      padding: 0,
      maxHeight: 500,
      backgroundColor: theme2.colors.accentWhite,
      alignSelf: "center",
      borderRadius: 10,
      justifyContent: "space-between",
      maxWidth: maxWidth,
      minWidth: minWidth
    },
    dimensionsWeb: {
      width: "40%",
    },
    dimensionsMobile: {
      width: "80%",
    },
    headerView: {
      paddingTop: 30,
      flex: 1,
      flexGrow: 3,
      alignContent: "center",
      flexDirection: "column",
      maxHeight: "35%",
    },
    textView: {
      alignContent: "center",
      flex: 1,
      flexGrow: 2,
      justifyContent: "center",
    },
    listsView: {
      paddingVertical: 10,
      alignContent: "center",
      flex: 1,
      flexGrow: 3,
      justifyContent: "center",
    },
    buttonsView: {
      paddingVertical: 5,
      alignContent: "flex-end",
      alignItems: "center",
      flexDirection: "row-reverse",
      flex: 1,
      paddingHorizontal: 10,
      backgroundColor: "#EDEDED",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    button: {
      flex: 1,
      marginHorizontal: 10,
      maxHeight: 40,
    },

    btnAccept: {
      backgroundColor: theme2.colors.primary,
      color: theme2.colors.primaryText,
    },
    btnDenny: {
      backgroundColor: theme2.colors.accent,
      color: theme2.colors.accentText,
    },
    imageStyle: {
      maxHeight: "100%",
      marginBottom: 0,
      zIndex: 1,
    },
    cookie: {
      width: 30,
      height: 30,
      resizeMode: "contain",
      position: "absolute",
      bottom: 5,
      right: -20,
      zIndex: 2,
    },
  });

  const [personalization, setPersonalization] = React.useState(false);
  const [ads, setAds] = React.useState(false);

  const acceptCookies = () => {
    let response: CookiesResponse;
    if (!configuring) {
      response = {
        responded: true,
        allAccepted: true,
        timestamp: new Date().toISOString(),
        responses: [],
      };
    } else {
      response = {
        responded: true,
        allAccepted: false,
        timestamp: new Date().toISOString(),
        responses: [],
      };

      const adsResponse: CookieState = {
        name: "ads",
        type: "ads",
        accepted: ads,
        timestamp: new Date().toISOString(),
      };
      const personalizationResponse: CookieState = {
        name: "content-personalization",
        type: "personalization",
        accepted: personalization,
        timestamp: new Date().toISOString(),
      };

      response.responses.push(adsResponse, personalizationResponse);
    }

    Storage.write("cookies_response", response);
    props.actionDone(response);
  };


  return (
    <Portal>
      <Modal visible={props.visible} contentContainerStyle={[styles.container]}>
        <View nativeID="tabConfig" style={{ flex: 1 }}>
          <View nativeID="ImageView" style={styles.headerView}>
            <View style={[styles.imgView]}>
              <View style={{ flex: 1 }}>
                <Logo imageStyle={styles.imageStyle}></Logo>
                <IconButton
                  icon="cookie"
                  color={theme2.colors.accentDark}
                  size={20}
                  style={styles.cookie}
                />
              </View>
            </View>
            <View style={[styles.headerTextView]}>
              <Title
                style={{
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  textAlign: "center",
                }}
              >
                Configuración de cookies
              </Title>
            </View>
          </View>
          {!configuring ? (
            <View nativeID="TextView" style={styles.textView}>
              <ScrollView
                contentContainerStyle={{
                  flex: 1,
                  paddingHorizontal: 30
                }}
              >
                <Paragraph>
                  En NTY
                  <b>
                    {" "}
                    utilizamos cookies propias y de terceros para analizar
                    nuestros servicios y mostrarte publicidad relacionada con
                    tus preferencias
                  </b>
                  , en base a un perfil elaborado a partir de tus hábitos de
                  navegación.Puede <b>aceptar todas las cookies</b> o configurar
                  cuales desea bloquear.
                  <br />
                  <b>Para más detalles, consulte:</b>
                  <br />
                  <ul>
                    <li>
                      Nuestra{" "}
                      <a
                        href="#"
                        onClick={() => {
                          StaticApi.getCookiesFile();
                        }}
                      >
                        Política de Cookies
                      </a>
                    </li>
                    <li>
                      Nuestra{" "}
                      <a
                        href="#"
                        onClick={() => {
                          StaticApi.getPolicyFile();
                        }}
                      >
                        Política de Privacidad
                      </a>
                    </li>
                  </ul>
                </Paragraph>
              </ScrollView>
            </View>
          ) : (
            <View nativeID="ConFigureView" style={styles.listsView}>
              <ScrollView
                contentContainerStyle={{
                  flex: 1,
                  alignItems: "stretch",
                  paddingHorizontal: 10,
                }}
              >
                <List.Section title="Cookies">
                  <List.Accordion
                    title="Imprescindibles"
                    left={(props) => (
                      <List.Icon
                        {...props}
                        icon="lock"
                        color={theme2.colors.accentText}
                      />
                    )}
                  >
                    <List.Item
                      title="Cookies de NTY"
                      description="Gracias a ellas funciona todo."
                      left={(props) => (
                        <List.Icon
                          {...props}
                          icon={({ size, color }) => (
                            <Image
                              source={require("./../../../assets/icons/nty_sq_50.png")}
                              style={{ width: size, height: size }}
                            />
                          )}
                        />
                      )}
                      right={(props) => (
                        <Switch
                          value={true}
                          disabled={true}
                          style={{ margin: "auto" }}
                        />
                      )}
                    />
                    <List.Item
                      title="Estadísticas de uso"
                      description="Hacemos un seguimiento de tu uso de la aplicación para mejorar tu experiencia futura."
                      left={(props) => (
                        <List.Icon
                          {...props}
                          icon="google-analytics"
                          color="#f9ab00"
                        />
                      )}
                      right={(props) => (
                        <Switch
                          value={true}
                          disabled={true}
                          style={{ margin: "auto" }}
                        />
                      )}
                    />
                  </List.Accordion>

                  <List.Accordion
                    title="Opcionales"
                    left={(props) => (
                      <List.Icon
                        {...props}
                        icon="wrench"
                        color={theme2.colors.accentText}
                      />
                    )}
                  >
                    <List.Item
                      title="Personalización"
                      description="Estas cookies permiten que la aplicación ofrezca una mejor funcionalidad y personalización. Pueden ser establecidas por nosotros o por terceros."
                      left={(props) => (
                        <List.Icon
                          {...props}
                          icon="puzzle"
                          color={theme2.colors.primary}
                        />
                      )}
                      right={(props) => (
                        <Switch
                          theme={{
                            ...theme2,
                            colors: { accent: theme2.colors.primary },
                          }}
                          value={personalization}
                          onValueChange={() =>
                            setPersonalization(!personalization)
                          }
                          style={{ margin: "auto" }}
                        />
                      )}
                    />
                    <List.Item
                      title="Publicidad."
                      description="Se utilizan para crear un perfil de tus intereses y mostrarte anuncios relevantes en otros sitios. Esta opción no afecta a la cantidad de anuncios."
                      left={(props) => (
                        <List.Icon
                          {...props}
                          icon="wrench"
                          color={theme2.colors.primary}
                        />
                      )}
                      right={(props) => (
                        <Switch
                          theme={{
                            ...theme2,
                            colors: { accent: theme2.colors.primary },
                          }}
                          value={ads}
                          onValueChange={() => setAds(!ads)}
                          style={{ margin: "auto" }}
                        />
                      )}
                    />
                  </List.Accordion>
                </List.Section>
              </ScrollView>
            </View>
          )}

          <View nativeID="ButtonsView" style={styles.buttonsView}>
            <ButtonNty
              nativeID="acceptButton"
              onPress={acceptCookies}
              mode="contained"
              mergeStyles={[{marginHorizontal: 10}]}
              // theme={theme2}
              // style={[styles.btnAccept, styles.button]}
            >
              Aceptar
            </ButtonNty>
            {!configuring ? (
              <ButtonNty
                onPress={() => setConfiguring(true)}
                mode="outlined"
                // theme={theme2}
                mergeStyles={[{marginHorizontal: 10}]}
              >
                Configurar
              </ButtonNty>
            ) : (
              <ButtonNty
                onPress={() => setConfiguring(false)}
                mode="outlined"
                mergeStyles={[{marginHorizontal: 10}]}
                // theme={theme2}
                // style={[styles.button]}
              >
                Atrás
              </ButtonNty>
            )}
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

export default CookieAdvisor;
