import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { Image, ListRenderItemInfo, Platform, View } from 'react-native'
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler'
import { useAppDataStore } from '../../../../context/appDataStore'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'
import { getNumColumnsFlatlist, openInBrowser } from '../../../../core/utils'
import { Notification } from '../../../../models/Notification'
import TextNty from '../../nty/text/TextNty'
import TitleNty from '../../nty/title/TitleNty'


export type NotificationListProps = {
    notifications: Notification[]
}

function NotificationList(props: NotificationListProps) {

    const theme = useAppTheme();

    const seenNotification = useAppDataStore.getState().seenNotification;
    const renderNotification = (renderInfo: ListRenderItemInfo<Notification>) => {

        seenNotification(renderInfo.item);

        return (
            <View style={{ flex: 1, paddingHorizontal: 10}}>

                <TouchableOpacity style={[{ borderRadius: 20, overflow: 'hidden', width:"100%", height: "100%" }, theme.shadows.box.small]} disabled={renderInfo.item.url == null || renderInfo.item.url.length < 3} onPress={() => {
                    const url = renderInfo.item.url;
                    if (url && url.length > 3) {
                        openInBrowser(url);
                    }
                }}>

                    {renderInfo.item.image ?
                        <View nativeID='header' style={{ height: 200, width: "100%"}}>
                            <Image style={{ height: "100%", resizeMode: 'cover' }} source={{ uri: renderInfo.item.image}} />
                        </View> : null}
                    <View nativeID='content' style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 10, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.colors.background[400] }}>
                        {
                            renderInfo.item.url ?
                                <View style={{ width: "100%", flexDirection: 'row-reverse' }}>
                                    <MaterialCommunityIcons size={15} name='open-in-new' color={theme.colors.background[600]} />
                                </View>
                                : null
                        }
                        <View style={{ width: "90%" }}>
                            <TitleNty fontSize={20} style={{ marginBottom: 25, flex: 2, width: "90%" }} >{renderInfo.item.title}</TitleNty>
                        </View>
                        <View style={{ width: "90%" }}>
                            <TextNty style={{ marginBottom: 10, flex: 3, width: "98%" }} >{renderInfo.item.subtitle}</TextNty>
                        </View>
                    </View>

                </TouchableOpacity>
            </View>
        )

    }


    return (
        <FlatList
            key={getNumColumnsFlatlist() + '_notificationList_' + Date.now()}
            data={props.notifications}
            numColumns={getNumColumnsFlatlist()}
            columnWrapperStyle={getNumColumnsFlatlist() > 1 ? { marginHorizontal: 10 } : null}
            keyExtractor={(n) => n._id ?? n.title + '_' + Date.now()}
            renderItem={renderNotification}
            contentContainerStyle={{ paddingBottom: 30, backgroundColor: theme.colors.background[600], paddingHorizontal: 10, paddingTop: 20, flex: Platform.OS == 'web' ? 1 : null }}
            ItemSeparatorComponent={() => <View style={{ height: 30 }}></View>}
        />
    )
}

export default NotificationList
