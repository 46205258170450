import { buildApiUrl, http_request } from "src/core/http-utils";
import { NewsletterDispatch, Pagination, SearchResponse } from "systemDomain";


export interface NewsletterDispatchSearchDTO {
    favlist_id?: string;
    enterprise?: string
    sent_at_from?: string;
    sent_at_to?: string;
}

export interface PaginationDTO { page: Pagination, sort?: Record<string, 1 | -1> }


export class NewsletterApi {

    public static BASE_PATH = 'newsletter'



    static async getFavlistNewsletterDistpatchHistory(favlist_id: string, search?: { sent_at_from?: string, sent_at_to?: string }, pagination?: PaginationDTO) {

        const default_pagination: PaginationDTO = { page: { skip: 0, limit: 10 }, sort: { sent_at: -1 } }
        pagination = { ...default_pagination, ...pagination }

        return await this.newsletterDispatchSearch({ favlist_id, ...search }, pagination)


    }

    static async newsletterDispatchSearch(search: NewsletterDispatchSearchDTO, pagination: PaginationDTO = { page: { skip: 0, limit: 10 }, sort: { sent_at: -1 } }) {

        const default_pagination: PaginationDTO = { page: { skip: 0, limit: 10 }, sort: { sent_at: -1 } }
        pagination = { ...default_pagination, ...pagination }

        const url =  buildApiUrl(`${this.BASE_PATH}/dispatch-history/search`, true)

        url.searchParams.append('skip', pagination.page.skip.toString())
        url.searchParams.append('limit', pagination.page.limit.toString())
        url.searchParams.append('sort', Object.entries(pagination.sort).map(([k, v]) => `${k}:${v}`).join(',') )


        const body = { ...search }

        return await http_request<SearchResponse<NewsletterDispatch>>(url, 'POST', body)

    }


}
