import React from 'react';
import { ColorValue, Insets, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Button } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
import { getContrastColor } from '../../../../core/utils';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { ColorSpace } from 'react-native-reanimated';
import { adjustLuminosity } from 'src/core/jsutils';


export type ButtonNtyProps = {
    children?: JSX.Element[] | JSX.Element | string,
    /**
     * Optional property that specifies the main color to be used for the button.
     * Default is @see theme.colors.ntyColor
     */
    color?: ColorValue,
    /**
     * Optional property that specifies the contrast color to be used for the button.
     * Default is @see theme.colors.white.full
     */
    contrastColor?: ColorValue,
    /** Flag to make the button display differently */
    mode?: "text" | "outlined" | "contained" | "floatingBottom" | "chip",
    /**
     * Pass this prop with a valid value in order to make the button print an icon at the left of the text.
     */
    icon?: IconSource,

    /**
     * Wether or not to show a loading indicator on this button
     */
    loading?: boolean

    /**
     * Set to true to disable the button
     */
    disabled?: boolean

    /** Pass in some custom styling to be used in addition to default styles*/
    mergeStyles?: StyleProp<ViewStyle>,
    /**
     * This prop will override the default styling for the text
     */
    style?: StyleProp<ViewStyle>,
    /**
     * Label Styles that will override the default styling for the label of the button
     */
    labelStyle?: StyleProp<TextStyle>,
    /**
     * Custom label styling to be used in addition to default label styles
     */
    labelMergeStyles?: StyleProp<TextStyle>,
    /** Function to be executed when the button gets clicked */
    onPress?: (() => void) | undefined
    /** Hit slop for the button */
    hitSlop?: Insets,
    nativeID?: any
}



function ButtonNty({
    children,
    color,
    contrastColor,
    mode = 'contained',
    icon,
    loading = false,
    disabled = false,
    mergeStyles,
    style,
    labelStyle,
    labelMergeStyles,
    onPress,
    hitSlop = { top: 2, bottom: 2, left: 2, right: 2 },
    nativeID
}: ButtonNtyProps) {

    const { colors, fonts } = useAppTheme();
    const bottomInset = useSafeAreaInsets().bottom;

    const MAIN_COLOR = color ?? colors.primary[400];
    const CONTRAST_COLOR = contrastColor ?? getContrastColor(MAIN_COLOR);
    const DISABLED_COLOR = colors.secondary[300];

    const { buttonStyles, labelStyles } = React.useMemo(() => {
        const commonLabelStyles = {
            fontFamily: fonts.default.semiBold.fontFamily,
            fontSize: 17,
            textTransform: 'none',
            paddingHorizontal: 10
        };

        const commonButtonStyles = {
            borderRadius: 20
        };

        const chipBorderColor = adjustLuminosity(MAIN_COLOR, -0.3)

        return {
            buttonStyles: StyleSheet.create({
                contained: {
                    ...commonButtonStyles,
                    backgroundColor: disabled ? DISABLED_COLOR : MAIN_COLOR,
                    borderWidth: 2.5,
                    borderColor: disabled ? DISABLED_COLOR : MAIN_COLOR
                },
                outlined: {
                    ...commonButtonStyles,
                    backgroundColor: 'transparent',
                    borderWidth: 2.5,
                    borderColor: disabled ? DISABLED_COLOR : MAIN_COLOR
                },
                floatingBottom: {
                    ...commonButtonStyles,
                    position: 'absolute',
                    bottom: bottomInset + 10,
                    alignSelf: 'center',
                    backgroundColor: disabled ? DISABLED_COLOR : MAIN_COLOR,
                    borderRadius: 15,
                    width: '70%',
                },
                text: {
                    ...commonButtonStyles,
                    backgroundColor: 'transparent',
                },
                chip: {
                    ...commonButtonStyles,
                    backgroundColor: MAIN_COLOR,
                    borderColor: chipBorderColor,
                    borderWidth: 1,
                    paddingHorizontal: 5,
                    paddingVertical: 2
                }
            }),
            labelStyles: StyleSheet.create({
                contained: {
                    ...commonLabelStyles,
                    color: CONTRAST_COLOR,
                    paddingVertical: 2
                },
                outlined: {
                    ...commonLabelStyles,
                    color: MAIN_COLOR,
                    paddingVertical:2
                },
                floatingBottom: {
                    ...commonLabelStyles,
                    color: CONTRAST_COLOR,
                    paddingVertical: 2
                },
                text: {
                    ...commonLabelStyles,
                    color: disabled ? DISABLED_COLOR : MAIN_COLOR
                },
                chip: {
                    ...commonLabelStyles,
                    color: CONTRAST_COLOR,
                    paddingVertical: 1
                }
            })
        };
    }, [MAIN_COLOR, disabled]);

    return (
        <Button
            nativeID={nativeID}
            hitSlop={hitSlop}
            icon={icon}
            loading={loading}
            disabled={disabled}
            labelStyle={labelStyle ? labelStyle : [labelStyles[mode], labelMergeStyles]}
            style={style ? style : [buttonStyles[mode], mergeStyles]}
            onPress={onPress}
        >
            {children}
        </Button>
    );
}

export default ButtonNty;
