import { NavigationProp } from "@react-navigation/native";
import React, { memo } from "react";
import { useLogInControl } from "src/app/hooks/useLogInControl";
import Background from "../../../Background";
import HomeSections_session from "./HomeSections_session";
import WebTopBar from "./components/WebTopBar";


type Props = {
  route: any;
  navigation: NavigationProp<any> & { push?: (route: string, parameters?: any) => void };
};


const HomeWebPage = ({ route, navigation }: Props) => {

  // useLogInControl()

  return (
    <Background safeInsets>


      <HomeSections_session/>
      {/* {(!isEnterprise || isGuest()) && <DownloadTheAppBanner style={{ position: 'absolute', bottom: 0 }} />} */}

    </Background>
  );
}


export default memo(HomeWebPage);
