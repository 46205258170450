import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';
import 'moment/locale/es';
import React, { memo } from 'react';
import { ColorValue, GestureResponderEvent, Platform, Pressable, StyleSheet, View, } from 'react-native';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { Article } from 'systemDomain';
import ArticleApi from '../../../../../api/ArticleApi';
import { ScreensParamsList } from '../../../../../core/navigation/screens/NavigationScreens';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Avatar, IconButton } from 'react-native-paper';
import PublisherBubble from 'src/app/components/brands_sources/PublisherBubble';
import useSessionStore from 'src/context/sessionStore';
import { getAppTheme } from 'src/core/theme/ThemeBuilder';
import { extractKeywordsFromArticle, maximumChars, openInBrowser } from '../../../../../core/utils';
import { useArticleOptionsStore } from '../../../../context/optionStores';
import TextNty from '../../../nty/text/TextNty';
import TitleNty from '../../../nty/title/TitleNty';
import ArticleFavouriteToggle from '../../bookmarks/ArticleFavouriteToggle';
import ArticleMediaComponent from '../ArticleMedia';
import { useStyles } from './hooks/useStyles';
import { ArticleCardProps, LengthProps } from './types';
import { BlurView } from 'expo-blur';
import { useAlert } from 'src/app/context/alertContext';
import { fixed_colors } from 'src/core/theme/colors';

function getFormatIcon(article: Article, color?: ColorValue): JSX.Element {

    const formatIcons = {
        'Web': 'web',
        'Papel': 'file-document',
        'Audio': 'play-box',
        'Video': 'play-box'
    }

    const iconName = formatIcons[article?.format] ?? 'web'
    return <MaterialCommunityIcons name={iconName} size={20} color={color ?? 'black'} />
}

const ArticleCard = (
    {
        article,
        onPress,
        onLongPress,
        onCardOptionsPress,
        cardStyle,
        keywords,
        feed,
        showSaveButton = true,
        showSubtitle = true,
        horizontal
    }: ArticleCardProps) => {

    const { styles } = useStyles(article)



    const navigation = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()
    const openArticleOptions = useArticleOptionsStore.getState().open

    const hasPhoto = React.useMemo(() => (article.photo || article.media?.cover?.url), [article])

    // const enterprise = useAppSessionStore.getState().enterprise
    const enterprise = useSessionStore.getState().enterprise

    const isPaywall = React.useMemo(() => {
        const a: Article = article;
        if (!a) return false;
        return a.isPaywall ?? a.cedro_info.publication?.publicationType == 'Paywall';
    }, [article])

    const blurrPaywall = React.useMemo(() => {
        return isPaywall == true && enterprise.options?.paywall?.visible != true && useSessionStore.getState().profile.superUser != true
    }, [isPaywall])

    // const k_array: string[] = keywords ? Array.from(new Set(article.keywords.filter(k => k.enterprise == enterprise?.props?.key).map(k => k.keyword))) : (keywords ?? [])
    const k_array: string[] = (enterprise) ? extractKeywordsFromArticle(article, enterprise?.key, feed?._id) : []

    const keyword = k_array[0]?.replace(new RegExp('"', 'g'), '')

    const articleId = article._id;

    const showSubtitleInternal = React.useMemo(() => {
        if (showSubtitle == 'force-show') return true
        if (showSubtitle == 'force-hide') return false
        if (showSubtitle) {
            if (article?.subtitle?.length > 0) {
                return Platform.OS == 'web' || !hasPhoto
            }
        } else {
            return !hasPhoto
        }
        return false
    }, [showSubtitle, article?.subtitle])

    const titleLength = React.useMemo(() => {

        let length = 85
        if (hasPhoto) {
            if (!showSubtitleInternal) {
                length = 145
            }
        } else {
            length = 115
        }
        return length
    }, [hasPhoto])


    const subtitleLength = React.useMemo(() => {
        let length
        if (hasPhoto) {
            length = Platform.OS == 'web' ? 150 : 130
        } else {
            length = Platform.OS == 'web' ? 200 : 180
        }
        return length
    }, [hasPhoto])





    const CardIcon = React.useMemo(() => getFormatIcon(article, getAppTheme().colors.text_primary[300]), [article])

    /**
    * Reusable piece of code that shows the title
    */
    const CardTitle = React.memo(({ data, maxLength, style, fontSize }: LengthProps) => {

        if (Platform.OS == 'web') {
            maxLength += 15
        }

        return (
            <View style={[styles.titleContainer, style]}>

                <TitleNty style={[styles.titleStyle, style]} fontSize={fontSize ?? 19} allowFontScaling={false} type={'medium'}>
                    {maximumChars(data?.title, maxLength)}
                </TitleNty>

            </View>
        )
    });

    /**
    * Reusable piece of code that shows the subtitle
    */
    const CardSubtitle = React.memo(({ data, maxLength, style, fontSize }: LengthProps) => {

        let subtitle: string = maximumChars(data?.subtitle, maxLength);

        if (subtitle?.length < 3) {

            subtitle = maximumChars(data.textBody, maxLength)

        }

        if (subtitle)
            subtitle = subtitle?.replaceAll ? subtitle?.replace(new RegExp(/(\r|\n|\t)\s*(\r|\n|\t)/g, 'g'), '') : subtitle


        return (
            <View style={[styles.subtitleContainer, style]}>
                <TextNty
                    allowFontScaling={false}
                    fontSize={fontSize ?? 15}
                    style={[styles.subtitleStyle]}
                >
                    {subtitle}
                </TextNty>
            </View>
        )
    })

    /**
    * Reusable piece of code that shows the author within a styled chip
    */
    const CardKeywords = React.memo(({ maxLength }: LengthProps) => {


        return (
            <TextNty
                key={keyword}
                style={styles.keywords}
            >
                {maximumChars(keyword, maxLength)}
                {k_array?.length > 1 ? ' +' : undefined}
            </TextNty>

        )
    })



    const CardPubDate = React.memo(({ data, style, authors, showAuthor = true }: LengthProps & { authors: string[], showAuthor: boolean }) => {

        const dateValue = React.useMemo(() => data.published_at ? moment(data.published_at).locale("es").format('D/MM/YYYY HH:mm') : '--', [data]);

        const author = maximumChars(data?.author, 17);

        return (
            <View style={[styles.pubDateContainer, style]}>
                {showAuthor ? <><TextNty
                    type={authors?.indexOf(data?.author) != -1 ? 'bold' : 'regular'}
                    style={
                        styles.authorText
                    }
                >
                    {author}
                </TextNty>
                    <TextNty style={styles.dashText}>-</TextNty></> : null}
                <TextNty
                    style={styles.dateText}
                >
                    {dateValue}
                </TextNty>
            </View>

        )
    });

    // const articleModel = new ArticleModel(article)

    const _onPress = (event?: GestureResponderEvent) => {

        if (blurrPaywall) {
            useAlert.getState().alert({ title: 'Contenido Premium', message: 'Este contenido tiene muro de pago y no está disponible en tu plan actual.' })
            return
        }

        if (onPress) {
            onPress(article, event);
        } else {

            if (article.webview) {
                openInBrowser(article.url)
            } else {
                navigation.push('viewArticle', { id: articleId, article: article, keywords: keywords })
            }
            ArticleApi.viewArticleAction(article);
        }

    }

    const _onLongPress = (event?: GestureResponderEvent) => {
        if (onLongPress) {
            onLongPress(article, event);
        } else {
            openArticleOptions(article)
        }
    }

    const _onCardOptionsPressed = (event?: GestureResponderEvent) => {

        if (onCardOptionsPress) {
            onCardOptionsPress(article, event);
        } else {
            openArticleOptions(article)
        }
    }

    // Primero, crea un nuevo estado para las dimensiones.
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

    // Después, agrega una función para manejar el evento onLayout.
    const onLayout = event => {
        if (event.nativeEvent.layout.width == 0 || event.nativeEvent.layout.height == 0) return
        if (dimensions.width == event.nativeEvent.layout.width && dimensions.height == event.nativeEvent.layout.height) return
        setDimensions({
            width: event.nativeEvent.layout.width,
            height: event.nativeEvent.layout.height
        });
    };

    const contentContainerFlex = 2

    // Finalmente, calcula el flex basado en las dimensiones.
    const mediaContainerFlex = React.useMemo(() => {
        let flex = 1.5;
        let aspectRatio = 1;
        if (dimensions.width != 0 && dimensions.height != 0) {
            aspectRatio = dimensions.width / dimensions.height;
            if (aspectRatio != 1) {
                flex = aspectRatio > 1 ? 2 : 1.6;
            }
        }

        const text = styles.textContainer.flex
        const bottom = styles.cardBottomContainer.flex
        const total = flex + text + styles.cardBottomContainer.flex
        const percentages = {
            card: flex * 100 / total,
            text: text * 100 / total,
            bottom: bottom * 100 / total
        }



        // console.log('flex', { card: flex, text, bottom, total, percentages, aspectRatio })
        return flex;
    }, [dimensions]); // El valor por defecto.


    // return (
    //     <View style={[styles.mainContainer, cardStyle]} onLayout={onLayout}>
    //         <TouchableOpacity style={[{ flex: 1 }, horizontal ? { flexDirection: 'row' } : null]} containerStyle={{ flex: 1, height: 100 }} /* onPress={_onPress} onLongPress={_onLongPress} */ >
    //             {/* <TouchableOpacity nativeID='contentContainer' style={[{ flex: 1 }, horizontal ? { flexDirection: 'row' } : null]}> */}
    //             {
    //                 hasPhoto ?
    //                     <View nativeID='mediaContainer' style={{ ...styles.mediaContainer, flex: mediaContainerFlex }} pointerEvents='box-none'>
    //                         {/* // <View nativeID='mediaContainer' style={{height: 100, flex: 1}}> */}

    //                         {/* <View style={styles.mediaCover} > */}
    //                         <ArticleMedia article={articleModel} />
    //                         {/* </View> */}
    //                     </View>
    //                     : null
    //             }
    //             <View nativeID='textContainer' style={[styles.textContainer, !hasPhoto ? { paddingTop: 45 } : null]}>
    //                 {/* <TextNty>Hello</TextNty> */}
    //                 <CardTitle data={article} maxLength={titleLength} />
    //                 {showSubtitleInternal &&
    //                     <CardSubtitle data={article} maxLength={subtitleLength} />}
    //             </View>
    //             <View nativeID='cardBottomContainer' style={styles.cardBottomContainer}>

    //                 <View style={styles.cardPubDateKeyContainer}>
    //                     {keyword && <CardKeywords data={article} />}
    //                     <CardPubDate data={article} showAuthor={false} authors={[]} />
    //                 </View>
    //                 <View style={{ height: "100%", justifyContent: 'flex-end' }}>
    //                     {CardIcon}
    //                 </View>

    //             </View>
    //         </TouchableOpacity>
    //         <View nativeID='optionsContainer' style={{ flexDirection: 'row', justifyContent: 'space-between', width: "100%", alignItems: 'center', position: 'absolute', top: Platform.OS == 'web' ? 2 : article.media?.cover?.type == 'video' ? "45%" : 2, paddingHorizontal: 6 }}>
    //             <ScrollView horizontal={true} style={{ maxHeight: 30 }} contentContainerStyle={{ alignItems: 'center' }}>
    //                 <NewspaperBubble
    //                     newspaper={article.newspaper ?? article.site}
    //                     size={30}
    //                     showShadow={false}
    //                     showLabel
    //                     labelPosition='right'
    //                     labelStyle={styles.newspaperBubbleLabel} />
    //             </ScrollView>
    //             <View style={styles.cardOptionsContainer}>

    //                 {Platform.OS == 'web' && <IconButton
    //                     icon={'dots-horizontal'}
    //                     color={styles.cardOptions.color}
    //                     style={styles.cardOptions}
    //                     onPress={_onCardOptionsPressed} />}

    //                 {showSaveButton && <ArticleFavouriteToggle article={articleModel} size={20} style={styles.favouriteArticle} />}

    //             </View>
    //         </View>
    //     </View>
    // );

    return (
        <View style={[styles.mainContainer, cardStyle, horizontal ? { flexDirection: 'row' } : null]} onLayout={onLayout}>
            {blurrPaywall ?
                <Pressable style={[StyleSheet.absoluteFillObject, { zIndex: 10, shadowOpacity: 0, shadowColor: 'transparent', justifyContent: 'center' }]} onPress={_onPress}>
                    <Avatar.Icon size={50} icon='lock' color={getAppTheme().colors.text_primary[300]} style={{ backgroundColor: 'transparent', alignSelf: 'center', zIndex: 11 }} />
                    <BlurView style={[StyleSheet.absoluteFillObject, { borderRadius: styles.mainContainer.borderRadius, zIndex: 10 }]} intensity={30} />
                </Pressable>
                : null}
            {/* {blurrPaywall ? <BlurView style={[StyleSheet.absoluteFillObject, { zIndex: 10, borderRadius: styles.mainContainer.borderRadius }]} intensity={30} /> : null} */}
            {
                hasPhoto ?
                    article?.media?.cover?.type == 'image' ?
                        <TouchableOpacity nativeID='mediaContainer' style={{ flex: mediaContainerFlex, ...styles.mediaContainer }} containerStyle={{ ...styles.mediaContainer, flex: mediaContainerFlex }} onPress={_onPress} onLongPress={_onLongPress} >
                            <ArticleMediaComponent article={article} />
                        </TouchableOpacity>
                        :
                        <View nativeID='mediaContainer' style={{ ...styles.mediaContainer, flex: mediaContainerFlex }} >

                            <ArticleMediaComponent article={article} />

                        </View>
                    : null
            }
            <TouchableOpacity nativeID='contentContainer' style={[{ flex: 1 }]} containerStyle={{ flex: contentContainerFlex }} hitSlop={20} onPress={_onPress} onLongPress={_onLongPress} >
                {/* <TouchableOpacity nativeID='contentContainer' style={[{ flex: 1 }, horizontal ? { flexDirection: 'row' } : null]}> */}

                <View nativeID='textContainer' style={[styles.textContainer, !hasPhoto ? { paddingTop: 25 } : null]}>
                    {/* <TextNty>Hello</TextNty> */}
                    <CardTitle data={article} maxLength={titleLength} />
                    {showSubtitleInternal &&
                        <CardSubtitle data={article} maxLength={subtitleLength} />}
                </View>
                {!blurrPaywall && <View nativeID='cardBottomContainer' style={styles.cardBottomContainer}>

                    <View style={styles.cardPubDateKeyContainer}>
                        {keyword && enterprise && <CardKeywords data={article} />}
                        <CardPubDate data={article} showAuthor={false} authors={[]} />
                    </View>
                    <View style={{ height: "100%", justifyContent: 'flex-end' }}>
                        {CardIcon}
                    </View>

                </View>}
            </TouchableOpacity>
            <View nativeID='optionsContainer' style={{ zIndex: 10, flexDirection: 'row', justifyContent: 'space-between', width: "100%", alignItems: 'center', position: 'absolute', top: Platform.OS == 'web' ? 2 : article.media?.cover?.type == 'video' ? "45%" : 5, paddingHorizontal: 6 }}>
                <ScrollView horizontal={true} style={{ maxHeight: 30 }} contentContainerStyle={{ alignItems: 'center' }}>
                    <PublisherBubble
                        publisher={article.publisher}
                        size={30}
                        showLabel
                        labelPosition='right'
                        labelStyle={styles.newspaperBubbleLabel} />
                </ScrollView>
                <View style={styles.cardOptionsContainer}>

                    {Platform.OS == 'web' && !useSessionStore.getState().isGuest && <IconButton
                        disabled={blurrPaywall}
                        size={20}
                        icon={'dots-horizontal'}
                        color={styles.cardOptions.color}
                        style={styles.cardOptions}
                        onPress={_onCardOptionsPressed} />}

                    {showSaveButton && !useSessionStore.getState().isGuest && <ArticleFavouriteToggle disabled={blurrPaywall} article={article} size={20} style={styles.favouriteArticle} />}

                </View>
            </View>
            {blurrPaywall ? <View nativeID='cardBottomContainer' style={[styles.cardBottomContainer, { position: 'absolute', bottom: 0, alignItems: 'flex-end' }]}>

                <View style={styles.cardPubDateKeyContainer}>
                    {keyword && enterprise && <CardKeywords data={article} />}
                    <CardPubDate data={article} showAuthor={false} authors={[]} />
                </View>
                <View style={{ height: "100%", justifyContent: 'flex-end'}}>
                    {CardIcon}
                </View>

            </View> : null}
        </View>
    );
}
export default memo(ArticleCard);



