import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import 'moment/locale/es';
import React, { memo } from 'react';
import { Helmet } from "react-helmet";
import { Clipboard, Linking, Platform, View, ViewStyle } from 'react-native';
import { Divider } from "react-native-paper";
import ArticleApi from 'src/api/ArticleApi';
import NtySectionBar from 'src/app/components/nty/section-bar/NtySectionBar';
import { Article } from 'systemDomain';
import { isValidLink } from '../../../../../core/jsutils';
import { ScreensParamsList } from '../../../../../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../../../../../core/theme/ThemeBuilder';
import { isSpecialType, maximumChars, openCusomLink, openNewTabLink } from '../../../../../core/utils';
import { useSnackBar } from '../../../../context/snackBarStore';
import ArticleText from '../../../ArticleText';
import CategoryChip from '../../../CategoryChip';
import ButtonNty from '../../../nty/button/ButtonNty';
import TextNty from '../../../nty/text/TextNty';
import ArticleListHorizontal from '../../lists/ArticleListHorizontal';
import { useStyles } from './useStyles';
import PublisherBubble from 'src/app/components/brands_sources/PublisherBubble';
import moment from 'moment';
import { fixed_colors } from 'src/core/theme/colors';


type Props = {
    article: Article,
    keywords?: string[],
    containerStyle?: ViewStyle

};

const ArticleContent = ({ article, keywords, containerStyle }: Props) => {

    const navigator = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()
    const showInfoMessage = useSnackBar(store => showInfoMessage);
    const { styles } = useStyles(article);

    const [mention, setMention] = React.useState<Article>()
    const [extended, setExtended] = React.useState<Article>()



    const showSnackMessage = (message: string) => {
        showInfoMessage(message)
    }

    const copyToClipboard = (url: string) => {
        Clipboard.setString(url);
    };

    const openInNewTab = async () => {

        if (Platform.OS == 'web') {


            try {

                openNewTabLink(article?.url);

            } catch (error) {
                console.error(error)
                //Alert.alert('Error inesperado', 'Hubo un problema al intentar abrir el enlace en el navegador. Se copiará el enlace en el portapapeles.');
                showSnackMessage('Hubo un problema al intentar abrir el enlace de la noticia. Se copiará el enlace en el portapapeles.')
                copyToClipboard(article?.url);

            }

        } else {

            try {

                Linking.canOpenURL(article?.url).then(
                    (supported) => {
                        if (supported) {
                            Linking.openURL(article?.url);
                        }
                    });

            } catch (error) {
                //Alert.alert('Error inesperado', 'Hubo un problema al intentar abrir el enlace en el navegador. Se copiará el enlace en el portapapeles.');
                showSnackMessage('Hubo un problema al intentar abrir el enlace en el navegador. Se copiará el enlace en el portapapeles.')
                copyToClipboard(article?.url);

            }

        }


    }

    const theme = useAppTheme()




    const loadMention = async (cedro_article_id: string) => {

        let article: Article

        try {
            article = await ArticleApi.readCedro(cedro_article_id)
        } catch { }

        if (article) {
            setMention(article)
        }

    }

    const loadExtended = async (cedro_article_id: string) => {

        let article: Article

        try {
            article = await ArticleApi.readCedro(cedro_article_id)
        } catch { }

        if (article) {
            setExtended(article)
        }

    }

    React.useEffect(() => {


        if (article) {

            if (article?.mention) {

                loadMention(article?.mention as string)

            } else if (article?.extended) {

                loadExtended(article?.extended as string)
            }
        }


    }, [article])

    return (
        <View style={[styles.mainContainer, containerStyle]}>

            {
                Platform.OS == 'web' &&
                <Helmet>
                    <title>{article?.title ? article.title : 'News To You'}</title>
                </Helmet>
            }

            <View style={{ flex: 1 }} key={article?._id + '_' + Date.now()}>


                {article.subtitle ? <TextNty style={styles.subtitle} type={'bold'}>{article.subtitle.length > 250 ? article.subtitle.substring(0, 250) + '...' : article.subtitle}</TextNty> : null}
                <View style={{ marginTop: 20, marginBottom: 20, alignItems: 'center', paddingVertical: 10, borderBottomColor: fixed_colors.grey[200], borderBottomWidth: 1, borderTopColor: fixed_colors.grey[200], borderTopWidth: 1 }}>
                    <PublisherBubble publisher={article.publisher} size={30} showLabel={true} labelPosition='right' labelStyle={{ color: theme.colors.text_primary[500] }} />
                    {article.author ? <TextNty color={fixed_colors.grey[300]} fontSize={18}>  {article?.author}</TextNty> : null}
                    <TextNty fontSize={18} color={fixed_colors.grey[300]} type={'medium'}>{moment(article?.published_at).format('D MMMM YYYY, HH:mm')}</TextNty>
                </View>


                {/* <View style={styles.authorContainer}>
                    <TextNty style={styles.author}>  {article?.author}</TextNty>
                </View> */}
                {isSpecialType(article) ? <ButtonNty mergeStyles={{ marginHorizontal: 30 }} icon={article.type == 'spotify' ? 'spotify' : null} onPress={() => openCusomLink(article)}>{article.type == 'spotify' ? 'Escuchar el episodio' : 'Acceder'}</ButtonNty> : null}


                <ArticleText article={article} showHtml={true} keywords={keywords} />

                {article?.tags && article?.tags.length > 0 &&
                    <>
                        <Divider style={styles.divider} />
                        <View style={styles.tagWrapper}>
                            {article?.tags?.map((tag) => {
                                return (<CategoryChip onPress={(k) => navigator.navigate('searchKeyword', { keyword: k })} key={tag + '_' + Date.now()} category={tag} style={{ height: 50, margin: 5 }}></CategoryChip>)
                            })}
                        </View>
                    </>}


                {mention && <>
                    <Divider style={styles.divider} />

                    <NtySectionBar title='Mencionado en'>
                        <ArticleListHorizontal

                            data={[mention]}
                        />
                    </NtySectionBar>
                </>}
                {extended && <>

                    <Divider style={styles.divider} />
                    <NtySectionBar title='Continúa en'>
                        <ArticleListHorizontal

                            data={[extended]}
                        />
                    </NtySectionBar>
                </>}


                {(isValidLink(article.url)) ? <TextNty
                    onPress={openInNewTab}
                    style={[styles.credits, { color: theme.colors.links[500], marginTop: 10 }]}>
                    Haz click aquí para ver la noticia en el medio original
                </TextNty> : null}



            </View>

        </View>
    )
};

export default memo(ArticleContent);


