import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import ArticleCardPlaceHolder from "src/app/components/article/cards/ArticleCardPlaceHolder";

import ArticleListHorizontal from "src/app/components/article/lists/ArticleListHorizontal";
import NtySectionBar from "src/app/components/nty/section-bar/NtySectionBar";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";
import { DefinedSection, DefinedSectionTypesNames } from "systemDomain";
import { useSectionNav } from "../hooks/useSectionNav";

function SectionArticlesSection(props: { section: DefinedSection<DefinedSectionTypesNames.SECTION_ARTICLES> }) {


    const nav = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()

    const { goToFunc } = useSectionNav(props.section.goTo)

    //TODO: Add specific features for 'section' type sections
    return (

        <NtySectionBar title={props.section.title} subtitle={props.section.subtitle} onViewSectionPress={props.section.goTo ? goToFunc : null} >

            <ArticleListHorizontal
                //@ts-ignore
                data={props.section.info.data.items.filter(a => a != undefined)}
                paginateEvery={2}
                ListFooterComponent={props.section.info.data.items && props.section.info.data.items.length == 10 ? () => {
                    return <ArticleCardPlaceHolder title="Ver más" onPress={() => {
                        nav.push('viewArticlesSection', { section: props.section })
                    }} />
                } : null}
                contentContainerStyle={{ paddingHorizontal: 10, paddingVertical: 15 }}
            />

        </NtySectionBar>
    )


}

export default SectionArticlesSection
