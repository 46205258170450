import React, { memo } from "react";
import { Animated, View } from 'react-native';
import { Navigation } from "../../types";
import Background from "../Background";

import { useNavigation } from "@react-navigation/native";
import { useAppTheme } from "../../core/theme/ThemeBuilder";
import ButtonNty from "../components/nty/button/ButtonNty";
import TitleHeaderNty from "../components/nty/header/TitleHeaderNty";
import TextNty from "../components/nty/text/TextNty";
import TitleNty from "../components/nty/title/TitleNty";


type Props = {
  navigation: Navigation;
  setHeaderBorder: Function;
  setHeaderStyle: null;
  route: any;
};

const PremiumErrorScreen = ({ navigation, setHeaderBorder, setHeaderStyle, route }: Props) => {

  const theme = useAppTheme()
  const fadeAnim = React.useRef(new Animated.Value(0)).current;
  const moveUp = React.useRef(new Animated.Value(15)).current;
  const moveDown = React.useRef(new Animated.Value(-15)).current;

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true
    }).start();

    Animated.timing(moveUp,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: true
      }).start()

    Animated.timing(moveDown,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: true
      }).start()
  };

  React.useEffect(() => {
    fadeIn();
  }, []);

  const nav = navigation ?? useNavigation()

  return (
    <Background safeInsets>
      <TitleHeaderNty title="Error en la compra" onBackIconPressed={() => nav.reset({ routes: [{ name: 'home' }] })} />
      <View style={{ width: '100%', maxWidth: 460, flex: 1, alignSelf: 'center', justifyContent: 'center', paddingHorizontal: 15 }}>
        <TitleNty >Parece que algo ha salido mal...</TitleNty>
        <TextNty>Hemos detectado un problema en la pasarela de pago. {'\n'}Comprueba tu conexión a internet y que no se hayan realizado cargos indebidos en tu cuenta.</TextNty>
        <ButtonNty
          style={{ marginHorizontal: 20, marginTop: 20 }}

          mode={'text'}

          onPress={() => navigation.navigate("plans")}
        >Volver a intentarlo</ButtonNty>
      </View>
    </Background>
  );
}

export default memo(PremiumErrorScreen);
