import React from 'react'
import DatePicker from './date/DatePicker'

function DatePickersLoader() {
    return (
        <>
            <DatePicker />
        </>

    )
}

export default DatePickersLoader
