import * as Speech from 'expo-speech';
import React, { memo } from "react";
import { Platform, useWindowDimensions, View } from 'react-native';
import { FAB, IconButton } from "react-native-paper";
import { Article } from "systemDomain";
import { getAppTheme } from '../../core/theme/ThemeBuilder';

// import Toast from 'react-native-root-toast';


let Toast;

if (Platform.OS != 'web') {
  import('react-native-root-toast').then((toast) => {
    Toast = toast.default;
  })
}




type Props = {
  play?: boolean;
  speak?: Function;
  stopSpeak?: Function;
  navigation?: any;
  article?: Article;
  iconButton?: boolean;
};

const TextToSpeech = ({ play, speak, stopSpeak, article, iconButton }: Props) => {

  // const showRegisterModal = useRegisterModal(store => store.show)
  // const {subscriptions, ready} = useSubscriptionStore();
  // const [QR, setQR] = useState(false);

  const { height } = useWindowDimensions();

  // const isQR = async () => {
  //   const result = await Storage.read('qrActive');
  //   setQR(result);
  // }

  // useEffect(() => {
  //   isQR();
  // }, []);

  // Text to speech functions
  const [playing, setPlay] = React.useState<boolean>(false);

  const stopSpeakFn = () => {
    Speech.stop()
    Speech.isSpeakingAsync().then((isPlaying) => {

      if (isPlaying) {
        Speech.stop()
      }

    })
    setPlay(false);
  };

  const getTitle = () => {

    let title = '';

    try {

      title = article?.title;

    } finally {
      return title;
    }

  }

  const getSubtitle = () => {

    let subtitle = '';

    try {

      subtitle = article?.subtitle;

    } finally {
      return subtitle;
    }

  }

  const getTextBody = () => {

    let subtitle = '';

    try {

      subtitle = article?.text ?? '';

    } finally {
      return subtitle;
    }

  }

  const speakContent = () => {

    const articleContent = getTextBody();

    const paragraphs = articleContent.split(". ");

    speakParagraphs(paragraphs, 0);


  }

  const speakParagraphs = (paragraphs: string[], index: number) => {

    if (paragraphs && paragraphs.length > index) {
      const thingToSay = paragraphs[index];
      Speech.speak(thingToSay, { language: 'es', onDone: () => { speakParagraphs(paragraphs, index + 1) } });
    } else {
      setPlay(false);
    }
  }

  const speakFn = () => {
    if (Platform.OS == 'ios')
      Toast.show('Asegúrate de tener desactivado el modo silencio en tu iphone.', { duration: 2000, position: height - 130, opacity: 0.9 });

    setPlay(true);
    const thingToSay = getTitle() + '\n\n\n\n\n\n\n' + getSubtitle();
    Speech.speak(thingToSay, { language: 'es', onDone: speakContent });
  };

  const theme = getAppTheme()
  const iconsColor = theme.colors.text_primary[500]
  const bgColor = theme.colors.text_primary[500]


  React.useEffect(() => {
    return (() => {
      stopSpeak ? stopSpeak() : stopSpeakFn()
    });
  }, [article])

  return (
    <>
      {
        iconButton ?

          <IconButton
            icon={playing ? "headphones-off" : "headphones"}
            style={{ maxWidth: 100 }}
            color={iconsColor}
            onPress={() => {
              playing ? stopSpeak ? stopSpeak() : stopSpeakFn() : speak ? speak() : speakFn()
            }}
          ></IconButton>
          :
          <View style={{ width: '100%', maxWidth: 600, alignSelf: 'center' }}>
            <FAB
              style={{ position: 'absolute', right: 20, bottom: 40, backgroundColor: bgColor, shadowRadius: 15, shadowOpacity: 0.1 }}
              medium
              icon={playing ? "headphones-off" : "headphones"}
              color={iconsColor}
              // PREMIUM
              onPress={() => {
                playing ? stopSpeak ? stopSpeak() : stopSpeakFn() : speak ? speak() : speakFn()
              }}></FAB>
          </View>


      }
    </>
  );
}

export default memo(TextToSpeech);

