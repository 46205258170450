import React from 'react'
import { ColorValue, StyleSheet, ViewStyle } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { useAppTheme } from '../../../core/theme/ThemeBuilder'

function LoadingIndicator(props: {style?: ViewStyle, size?: number, color?: ColorValue}) {


    const theme = useAppTheme()

    const size = props.size ?? 50

    const color = props.color ?? theme.colors.primary[500]


  return (
    //@ts-ignore
    <ActivityIndicator color={color} size={size} style={props.style}/>
  )
}

export default LoadingIndicator
