import { Brand, SearchBrands, Source } from "systemDomain";
import { buildApiUrl, http_request } from "../core/http-utils";
import { Pagination } from "src/context/appSearchStore";

export class PublisherApi {

    static BASE_PATH = 'publishers'


    static async getPublisherByKeyName(keyName: string): Promise<Brand | Source> {

        let url = buildApiUrl(PublisherApi.BASE_PATH + `/${keyName}`, true)
        // console.log({name, params, page})
        try {
            return await http_request<Brand | Source>(url, 'GET')
        } catch (e) {
            console.error(e.message ?? e)
        }

    }


    static async getBrands(page: Pagination = {skip: 0, limit: 20}): Promise<Brand[]> {

        let url = buildApiUrl(PublisherApi.BASE_PATH + `/brands`, true)


        if (page.skip) {
            url.searchParams.append('skip', page.skip.toString())
        }
        if (page.limit) {
            url.searchParams.append('limit', page.limit.toString())
        }

        // console.log({name, params, page})
        try {
            return await http_request<Brand[]>(url, 'GET')
        } catch (e) {
            console.error(e.message ?? e)
        }

    }

    static async searchBrands(search: SearchBrands) {


        let url = buildApiUrl(PublisherApi.BASE_PATH + `/search/brands`, true)

        try {
            return await http_request<Brand[]>(url, 'POST', search)
        } catch (e) {
            console.error(e.message ?? e)
        }



    }
}
