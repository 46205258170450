import ApiCaller from '../core/ApiCaller';
import { HighlightModel, HighlightCollection, ActionModel, ProfileModel } from 'systemDomain';
import { SessionUser } from '../models/user';
import ActionApi, { ACTION_NAMES } from './ActionApi';
import { getArticleReport } from '../core/utils';
import { HighlightInterface } from '../system/domain/lib';
import { buildApiUrl, http_request } from 'src/core/http-utils';


class HighlightApi {
    constructor() { }

    static async all(): Promise<HighlightInterface[]> {
        try {
            // const data = await getApiCaller(currentUser).call('/v1/highlights', 'GET');
            const url = buildApiUrl(`/v1/highlights`);
            const data = await http_request<HighlightInterface[]>(url, 'GET');
            return data
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    static async read(id: string): Promise<HighlightModel> {
        // const data = await getApiCaller(currentUser).call('/v1/highlights/' + id, 'GET');
        const url = buildApiUrl(`/v1/highlights/${id}`);
        const data = await http_request<HighlightInterface>(url, 'GET');
        return new HighlightModel(data);
    }

    static async create(highlight: HighlightInterface): Promise<HighlightInterface> {
        // const data = await getApiCaller(currentUser).call('/v1/highlights', 'POST', highlight.toObject());
        const url = buildApiUrl(`/v1/highlights`);
        const data = await http_request<HighlightInterface>(url, 'POST', highlight);
        return data
    }

    static async createFrom(highlight: Partial<HighlightInterface>, articleId: string): Promise<HighlightInterface> {

        // const data = await getApiCaller(currentUser).call('/v1/highlights/createFrom/' + articleId, 'POST', highlight);
        const url = buildApiUrl(`/v1/highlights/createFrom/${articleId}`);
        const data = await http_request<HighlightInterface>(url, 'POST', highlight);
        return data;
    }

    static async update(highlight: HighlightInterface): Promise<HighlightInterface> {
        // const data = await getApiCaller(currentUser).call('/v1/highlights/' + highlight.getId(), 'PUT', highlight.toObject());
        const url = buildApiUrl(`/v1/highlights/${highlight.getId()}`);
        const data = await http_request<HighlightInterface>(url, 'PUT', highlight);
        return data;
    }

    static async delete(id: string): Promise<void> {
        // await getApiCaller(currentUser).call('/v1/highlights/' + id, 'DELETE');
        const url = buildApiUrl(`/v1/highlights/${id}`);
        await http_request(url, 'DELETE');
    }


    static async viewHighlightAction(hihglight: HighlightInterface): Promise<void> {

        let data: any = { ...hihglight };
        const article = hihglight.article;
        if (article) {
            delete data.article;
            data = { ...data, ...{ article: getArticleReport(article) } }
        }
        // console.log(data);
        ActionApi.createFrom({ name: ACTION_NAMES.VIEW_HIGHLIGHT, data })
    }

}

export default HighlightApi;
