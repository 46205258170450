import React from 'react'
import { Platform, Pressable, View } from 'react-native'
import { Avatar } from 'react-native-paper'
import { SearchOrderSelector, useSearchOptions } from 'src/app/context/components/search/sort/SearchSortOptions'
import { useSearchSortModal } from 'src/app/context/searchModalsStores'
import TextNty from '../../nty/text/TextNty'
import { useSearchChipStyles } from './styles'



function SearchOrder() {

    const { selectedOrder, possibleOrders } = useSearchOptions()

    const styles = useSearchChipStyles()



    return (
        <View>


            {Platform.OS != 'web' ?
                <>
                    <Pressable onPress={() => useSearchSortModal.getState().open()} style={{ flexDirection: 'row', paddingLeft: 15, alignItems: 'center' }}>
                        <TextNty fontSize={15} type='thin'>{possibleOrders.find(order => order.key == selectedOrder).label}</TextNty>
                        <Avatar.Icon size={40} icon={'menu-down'} color={styles.selectedChip.backgroundColor} style={{ backgroundColor: 'transparent' }} />
                    </Pressable>
                </>
                :
                <SearchOrderSelector />
            }

        </View>
    )
}

export default SearchOrder
