import produce from "immer";
import { create } from "zustand";
import { ModalStoreType } from "./optionStores";

export type SearchOptionsStoreType = Omit<ModalStoreType, 'open'> & {
    open: () => Promise<void>,
    /**
     * This is used to resolve the promise returned by the 'open' method.
     * @see SearchFiltersModal
     */
    resolve: (value: void | PromiseLike<void>) => void
}


export const useSearchDateModal = create<SearchOptionsStoreType>((set, get) => ({
    modalVisible: false,
    resolve: null,
    toggleModal: () => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: () => {
        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(), 50)
            return
        }

        let resolvePromise

        const promise = new Promise<void>(
            (resolve) => {
                resolvePromise = resolve
            }
        )

        let update: any = {}
        update.modalVisible = true;
        update.resolve = resolvePromise
        set(update)
        console.log('opening search options', { update, promise })
        return promise
    },
    close: () => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = false;
    })),
    setModalVisible: (payload) => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));


export type SearchSortStoreType = Omit<ModalStoreType, 'open'> & {
    open: () => Promise<void>,
    /**
     * This is used to resolve the promise returned by the 'open' method.
     * @see SearchFiltersModal
     */
    resolve: (value: void | PromiseLike<void>) => void
}


export const useSearchSortModal = create<SearchOptionsStoreType>((set, get) => ({
    modalVisible: false,
    resolve: null,
    toggleModal: () => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: () => {
        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(), 50)
            return
        }

        let resolvePromise

        const promise = new Promise<void>(
            (resolve) => {
                resolvePromise = resolve
            }
        )

        let update: any = {}
        update.modalVisible = true;
        update.resolve = resolvePromise
        set(update)
        console.log('opening search options', { update, promise })
        return promise
    },
    close: () => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = false;
    })),
    setModalVisible: (payload) => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));



export type SearchPublisherSelectorModalStoreType = Omit<ModalStoreType, 'open'> & {
    open: () => Promise<void>,
    /**
     * This is used to resolve the promise returned by the 'open' method.
     * @see SearchFiltersModal
     */
    resolve: (value: void | PromiseLike<void>) => void
}


export const useSearchPublisherSelectorModal = create<SearchPublisherSelectorModalStoreType>((set, get) => ({
    modalVisible: false,
    resolve: null,
    toggleModal: () => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = !draft.modalVisible;
    })),
    open: () => {
        if (get().modalVisible == true) {
            // console.log('Alert was visible, re-building...')
            set({ modalVisible: false })
            setTimeout(() => get().open(), 50)
            return
        }

        let resolvePromise

        const promise = new Promise<void>(
            (resolve) => {
                resolvePromise = resolve
            }
        )

        let update: any = {}
        update.modalVisible = true;
        update.resolve = resolvePromise
        set(update)
        console.log('opening search options', { update, promise })
        return promise
    },
    close: () => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = false;
    })),
    setModalVisible: (payload) => set(produce((draft: SearchOptionsStoreType) => {
        draft.modalVisible = payload;
    }))
}));
