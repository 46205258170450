import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { memo } from "react";
import { Animated, StyleSheet, View } from "react-native";
import Logo from "../../components/Logo";

import { useAppTheme } from "../../core/theme/ThemeBuilder";
import { Navigation } from "../../types";
import Background from "../Background";
import ButtonNty from "../components/nty/button/ButtonNty";
import TextNty from "../components/nty/text/TextNty";


type Props = {
  navigation: Navigation;
  setHeaderBorder: Function;
  setHeaderStyle: null;
  route: any;
};

const PremiumScreen = ({
  navigation
}: Props) => {


  const theme = useAppTheme()

  const styles = React.useMemo(() => StyleSheet.create({
    title: {
      width: "90%",
      alignSelf: "center",
      fontSize: 30,
      marginTop: -30,
      marginBottom: 15,
      paddingTop: 10,
      fontWeight: "700",
      lineHeight: 35,
    },
    body: {
      color: "#373737",
      width: "90%",
      alignSelf: "center",
      fontWeight: "300",
      fontSize: 18,
      lineHeight: 27,
      marginVertical: 0,
      //fontFamily: "PTSerif-Regular",
    },
    fab: {
      backgroundColor: theme.colors.background[500],
      elevation: 3,
      shadowRadius: 3,
      shadowOpacity: 0.3,
      borderRadius: 100,
      height: 80,
      width: 80,
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 40,
    },
    topBar: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
      paddingHorizontal: 10
    },
    headingView: {
      flex: 4,
      flexDirection: "column",
      padding: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    contentView: {
      flex: 10,
      flexDirection: "column",
      flexWrap: "wrap",
      alignContent: "center",
      padding: 15,
    },
    contentItem: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
      marginBottom: 10,
      borderColor: theme.colors.secondary[200],
      borderWidth: 1,
      borderRadius: 4,
      alignContent: "center",
      maxHeight: 170
    },
    logoView: {
      flex: 1,
      padding: 0,
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",

    },
    headlineView: {
      padding: 0,
    },

    itemText: {
      flex: 3,
      flexDirection: "column",
      padding: 0,
      alignItems: "flex-start",
    },
    itemIcon: {
      flex: 1,
      flexDirection: "column",
      padding: 0,
      alignItems: "center"
    },
    footer: {
      flex: 3,
      padding: 10
    },
    contentFooter: {
      flex: 1,
      flexDirection: 'column'
    },
    footerHeadline: {
      flex: 1,
      alignItems: 'center'
    },
    footerDescription: {
      flex: 1,
      alignContent: 'center',
      justifyContent: 'center'
    }
  }), [theme])

  const fadeAnim = React.useRef(new Animated.Value(0)).current;
  const moveUp = React.useRef(new Animated.Value(15)).current;
  const moveDown = React.useRef(new Animated.Value(-15)).current;

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true
    }).start();

    Animated.timing(moveUp,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: true
      }).start()

    Animated.timing(moveDown,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: true
      }).start()
  };

  React.useEffect(() => {
    fadeIn();
  }, []);

  return (
    <Background safeInsets>
      <View style={{ width: '100%', maxWidth: 460, alignSelf: 'center', justifyContent: 'center', flex: 1 }}>
        <MaterialCommunityIcons
          name="close"
          color={theme.colors.primary[500]}
          style={{ position: 'absolute', zIndex: 100, top: 10, left: 10 }}
          size={30}
          onPress={() => { navigation.canGoBack() ? navigation.goBack() : navigation.navigate('home') }}
        />
        <View style={{ flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>
        <TextNty style={styles.title}>Ventajas de hacerte Premium</TextNty>
        <TextNty style={styles.body}>Guarda tus noticias para poder leerlas cuando desees en un solo clic.</TextNty>
        <MaterialCommunityIcons
          name="bookmark"
          color={theme.colors.primary[500]}
          style={{ alignSelf: 'center', marginVertical: 40 }}
          size={40}
        />
        <TextNty style={styles.body}>Deja que News To You lea las noticias por ti y así podrás estar informado en cualquier lugar.</TextNty>
        <View style={styles.fab}>
          <MaterialCommunityIcons
            name="headphones"
            color={theme.colors.primary[500]}
            size={40}
          />
        </View>
        <ButtonNty


          onPress={() => navigation.navigate("plans")}
        >Hazte premium!</ButtonNty>

      </View>

    </Background>
  );
};



export default memo(PremiumScreen);
