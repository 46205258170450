import { ColorValue } from "react-native";
import { fixed_colors, palletes } from "./colors";
import { Color, NtyTheme } from "./types";


export type AppThemes = { default: NtyTheme } & { [key: string]: NtyTheme }

export const themes: AppThemes = {

    default: {
        name: 'NTY Default',
        dark: false,
        dimensions: {
            roundness: {
                low: 15,
                default: 40,
                high: 70
            },
            item_separation: {
                low: 20,
                default: 40,
                high: 90
            }
        },
        colors: palletes.default,
        // pallete: palletes.default,
        fonts: {
            default: {
                black: { fontFamily: "Archivo_900Black" },
                extraBold: { fontFamily: "Archivo_800ExtraBold" },
                bold: { fontFamily: "Archivo_700Bold" },
                semiBold: { fontFamily: "Archivo_600SemiBold" },
                medium: { fontFamily: "Archivo_500Medium" },
                regular: { fontFamily: "Archivo_400Regular" },
                light: { fontFamily: "Archivo_300Light" },
                extraLight: { fontFamily: "Archivo_200ExtraLight" },
                thin: { fontFamily: "Archivo_100Thin" }
            },
            article: {
                extraBold: { fontFamily: "PlayfairDisplay_800ExtraBold" },
                bold: { fontFamily: "PlayfairDisplay_700Bold" },
                semiBold: { fontFamily: "PlayfairDisplay_600SemiBold" },
                medium: { fontFamily: "PlayfairDisplay_500Medium" },
                regular: { fontFamily: "PlayfairDisplay_400Regular" }
            }
        },
        shadows: {
            box: {
                xsmall: elevationShadowStyle(1, fixed_colors.black[500]),
                small: elevationShadowStyle(2, fixed_colors.black[500]),
                medium: elevationShadowStyle(4, fixed_colors.black[500]),
                large: elevationShadowStyle(6, fixed_colors.black[500])
            },
            text: {
                xsmall: textShadowStyle(1, fixed_colors.black[500]),
                small: textShadowStyle(2, fixed_colors.black[500]),
                medium: textShadowStyle(4, fixed_colors.black[500]),
                large: textShadowStyle(6, fixed_colors.black[500])
            }
        }
    },
    dark: {
        name: 'NTY Dark',
        dark: true,
        dimensions: {
            roundness: {
                low: 15,
                default: 40,
                high: 70
            },
            item_separation: {
                low: 20,
                default: 40,
                high: 90
            }
        },
        // colors: colors,
        colors: { ...palletes.default, primary: palletes.default.primary, secondary: fixed_colors.brown, background: fixed_colors.black, text_primary: palletes.default.text_secondary, text_secondary: palletes.default.text_primary },
        fonts: {
            default: {
                black: { fontFamily: "Archivo_900Black" },
                extraBold: { fontFamily: "Archivo_800ExtraBold" },
                bold: { fontFamily: "Archivo_700Bold" },
                semiBold: { fontFamily: "Archivo_600SemiBold" },
                medium: { fontFamily: "Archivo_500Medium" },
                regular: { fontFamily: "Archivo_400Regular" },
                light: { fontFamily: "Archivo_300Light" },
                extraLight: { fontFamily: "Archivo_200ExtraLight" },
                thin: { fontFamily: "Archivo_100Thin" },
            },
            article: {
                extraBold: { fontFamily: "PlayfairDisplay_800ExtraBold" },
                bold: { fontFamily: "PlayfairDisplay_700Bold" },
                semiBold: { fontFamily: "PlayfairDisplay_600SemiBold" },
                medium: { fontFamily: "PlayfairDisplay_500Medium" },
                regular: { fontFamily: "PlayfairDisplay_400Regular" },
            }
        },
        shadows: {
            box: {
                xsmall: elevationShadowStyle(1, fixed_colors.black[500]),
                small: elevationShadowStyle(2, fixed_colors.black[500]),
                medium: elevationShadowStyle(4, fixed_colors.black[500]),
                large: elevationShadowStyle(6, fixed_colors.black[500])
            },
            text: {
                xsmall: textShadowStyle(1, fixed_colors.black[500]),
                small: textShadowStyle(2, fixed_colors.black[500]),
                medium: textShadowStyle(4, fixed_colors.black[500]),
                large: textShadowStyle(6, fixed_colors.black[500])
            }
        }
    }
}

const defaultShadowColor = fixed_colors.black[500]

function interpolate(i, a, b, a2, b2): number {
    return (i - a) * (b2 - a2) / (b - a) + a2;
}

function elevationShadowStyle(elevation: number, shadowColor?: ColorValue) {
    return {
        elevation,
        shadowColor: shadowColor ?? defaultShadowColor,
        shadowOffset: { width: elevation == 0 ? 0 : 0.2, height: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 1, 10) },
        shadowOpacity: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 0.2, 0.55),
        shadowRadius: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 1, 20)
    };
}
function textShadowStyle(elevation: number, shadowColor?: ColorValue) {
    return {
        textShadowColor: shadowColor ?? defaultShadowColor,
        textShadowOffset: { width: elevation == 0 ? 0 : 0.2, height: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 1, 10) },
        textShadowRadius: elevation == 0 ? 0 : interpolate(elevation, 1, 24, 1, 20)
    };
}


export function getPaperTheme() {

    const paperTheme = {
        dark: false,
        mode: 'adaptive',
        roundness: themes.default.dimensions.roundness.default,
        colors: {
            primary: themes.default.colors.secondary[400],
            background: themes.default.colors.background[300],
            surface: themes.default.colors.background[400],
            accent: themes.default.colors.primary[500],
            error: themes.default.colors.danger[600],
            text: themes.default.colors.text_primary[500],
            onSurface: themes.default.colors.text_primary[500],
            disabled: themes.default.colors.secondary.muted,
            placeholder: themes.default.colors.background[600],
            backdrop: fixed_colors.black[500],
            notification: themes.default.colors.primary[500]

        },
        fonts: themes.default.fonts,
        animation: { scale: 1 }
    }

    return paperTheme;


}



import { useEffect } from 'react';
import useSessionStore from "src/context/sessionStore";
import { EnterpriseInterface } from "systemDomain";

/**
 * Custom hook in order to subscribe to themeChanges across functional components.
 *
 * If you only want to get the value of the current theme once, @see #getAppTheme
 *
 * @param themeName
 * @returns
 */
export function useAppTheme(themeName?: string): NtyTheme {

    // const { forcedTheme, enterprise, isEnterprise } = useAppSessionStore((store) => ({ enterprise: store.enterprise, isEnterprise: store.isEnterprise, forcedTheme: store.forcedTheme }))
    const { enterprise} = useSessionStore((store) => ({ enterprise: store.enterprise }))
    // const theme = themeName && themes[themeName] ? themes[themeName] : forcedTheme ? forcedTheme : (isEnterprise && enterprise && themes[enterprise?.props?.key]) ? themes[enterprise?.props?.key] : themes.default
    const theme = themeName && themes[themeName] ? themes[themeName] : ( enterprise && themes[enterprise?.key]) ? themes[enterprise?.key] : themes.default


    useEffect(() => {

        if ( enterprise && enterprise.colors) {

            const fieldNames = Object.keys(enterprise.colors)

            for (const field of fieldNames) {

                if (theme.colors[field]) {

                    theme.colors[field] = getColorFromFieldName(enterprise, field, theme.colors[field])

                } else {
                    console.warn("'" + field + "' was not found in theme.colors structure")
                }

            }

        }

     }, [enterprise])

    return theme;

}

function getColorFromFieldName(enterprise: EnterpriseInterface, fieldName: string, fallback: Color) {

    if (enterprise.colors[fieldName]) {
        if (typeof enterprise.colors[fieldName] == 'object') {
            return enterprise.colors[fieldName]
        } else if (typeof enterprise.colors[fieldName] == 'string') {
            return fixed_colors[enterprise.colors[fieldName]] ?? fallback
        }
    } else {
        return fallback;
    }
}

export function getAppTheme(themeName?: string): NtyTheme {

    const { enterprise } = useSessionStore.getState()

    // const enterprise = session.enterprise;

    const theme = themeName && themes[themeName] ? themes[themeName] : enterprise && themes[enterprise?.key] ? themes[enterprise?.key] : themes.default

    if ( enterprise && enterprise.colors) {

        const fieldNames = Object.keys(enterprise.colors)

        for (const field of fieldNames) {

            if (theme.colors[field]) {

                theme.colors[field] = getColorFromFieldName(enterprise, field, theme.colors[field])

            } else {
                console.warn("'" + field + "' was not found in theme.colors structure")
            }

        }

    }

    return theme;


}
