import ApiCaller from '../core/ApiCaller';
import { SubscriptionModel, SubscriptionCollection } from 'systemDomain';
import { AuthDBUser, SessionUser } from '../models/user';

const getApiCaller = (currentUser: SessionUser | AuthDBUser): ApiCaller => {
    const apiCaller = new ApiCaller(currentUser.token);
    return apiCaller
}

class SubscriptionApi {
    constructor() {}

    static async all(currentUser: SessionUser | AuthDBUser): Promise<SubscriptionCollection> {
        const data = await getApiCaller(currentUser).call('/v1/subscription', 'GET');
        return new SubscriptionCollection(data.map((item:any) => {
            return new SubscriptionModel(item);
        }));
    }

    /*static async read(currentUser: User2): Promise<SubscriptionModel> {
        const data = await getApiCaller(currentUser).call('/v1/subscription', 'GET');
        return new SubscriptionModel(data);
    }*/

    static async create(currentUser: SessionUser, subscription: SubscriptionModel): Promise<SubscriptionModel>{
        const data = await getApiCaller(currentUser).call('/v1/subscriptions', 'POST', subscription.toObject());
        return new SubscriptionModel(data);
    }

    static async update(currentUser: SessionUser, subscription: SubscriptionModel): Promise<SubscriptionModel>{
        const data = await getApiCaller(currentUser).call('/v1/subscriptions/'+subscription.getId(), 'POST', subscription.toObject());
        return new SubscriptionModel(data);
    }

    static async delete(currentUser: SessionUser, id: string): Promise<void>{
        return await getApiCaller(currentUser).call('/v1/subscriptions/'+id+'/delete', 'GET');
    }
}

export default SubscriptionApi;
