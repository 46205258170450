import React from 'react';
import { Platform, View } from 'react-native';
import { useSearchStore } from '../../../../context/appSearchStore';
import ArticleList from '../../article/lists/ArticleList';
import LoadingIndicator from '../../loaders/Loading';
import ButtonNty from '../../nty/button/ButtonNty';
import TextNty from '../../nty/text/TextNty';
import SearchOrder from './SearchOrder';
import { IconButton } from 'react-native-paper';
import { useSnackBar } from 'src/app/context/snackBarStore';
import Loading from 'src/components/Loading';

function SearchResults() {


    const { results, search, loading, response } = useSearchStore((store) => ({
        results: store.results,
        search: store.currentSearch,
        loading: store.loading,
        response: store.searchResponse
    }));


    // React.useEffect(() => {

    //     console.log('search', search)

    //     useSearchStore.getState().search(search)

    // }, [JSON.stringify(search)])

    // React.useLayoutEffect(() => {
    //     if (!search) {
    //         console.log('There is no search')
    //         useSearchStore.getState()
    //     }
    // }, [])

    const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);

    const _onDownloadPress = async () => {

        if (response?.page.totalItems > 0) {

            setDownloadLoading(true)

            if (response.page.totalItems > 10000) {
                useSnackBar.getState().showInfoMessage('Los resultados de los reportes demasiado grandes se limitan a 10.000 registros.')
            }
            await useSearchStore.getState().downloadSearchReport()

            setDownloadLoading(false)

        }
    }

    const Content = () => {


        if (results && results.length > 0) {
            return (
                // <View style={{ flex: 1, alignItems: 'center', paddingTop: 20, paddingBottom: 25 }}>
                <>
                    <ArticleList
                        ListHeaderComponent={() =>

                            response ?
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 5, marginBottom: 8 }}>

                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <TextNty type='bold' fontSize={15} ><TextNty fontSize={15}>Mostrando</TextNty> {response?.page.totalItems} resultados</TextNty>
                                        {downloadLoading ? <LoadingIndicator size={20} style={{ marginLeft: 5 }} /> : <IconButton icon={'download'} onPress={_onDownloadPress} size={20} />}
                                    </View>
                                    <SearchOrder />
                                </View> : null

                        }
                        // articleCardProps={{showSubtitle: 'force-show'}}
                        data={results}
                        loadMoreDisabled={false}
                        onLoadMore={() => {

                            useSearchStore.getState().loadMore({ limit: 50, skip: results.length })
                        }}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        onEndReached={ Platform.OS == 'web' ? null : () => useSearchStore.getState().loadMore({ limit: 50, skip: results.length }) }
                        onEndReachedThreshold={0.3}


                    // showMeasuresInfo={true}
                    />
                </>
            );
        }

        if (loading) {
            return null
        }

        if (search) {
            return (
                <View
                    style={{ flex: 1, height: 200, paddingTop: 100, justifyContent: 'center', alignItems: 'center' }}
                >
                    <TextNty fontSize={18}>No hay resultados...</TextNty>
                    <TextNty type="extraLight" fontSize={16} style={{ marginTop: 10 }}>
                        Cambia tus criterios de búsqueda
                    </TextNty>
                    <ButtonNty mode='outlined' onPress={() => { useSearchStore.getState().refresh() }} mergeStyles={{ marginTop: 20 }}>O inténtalo de nuevo</ButtonNty>
                </View>
            );
        }

        return <View
            style={{ flex: 1, height: 200, paddingTop: 100, justifyContent: 'center', alignItems: 'center' }}
        >
            <TextNty fontSize={18}>Escribe algo para empezar a buscar.</TextNty>
            <TextNty type="extraLight" fontSize={16} style={{ marginTop: 10 }}>
                Haz tu primera búsqueda
            </TextNty>
        </View>
    }

    return <View style={{ paddingHorizontal: Platform.OS == 'web' ? "5%" : 10 }}>
        {Content()}
    </View>;
}


export default SearchResults
