import React from 'react'
import { Platform, View } from 'react-native'
import { Dialog, Portal } from 'react-native-paper'
import ConfigApi, { AppConfig } from '../../api/Config.api'
import ButtonNty from '../../app/components/nty/button/ButtonNty'
import TextNty from '../../app/components/nty/text/TextNty'
import TitleNty from '../../app/components/nty/title/TitleNty'
import { getAppTheme } from '../theme/ThemeBuilder'
import { openAppInAndroMarket, openAppInIOSMarket } from '../utils'
import useSessionStore from 'src/context/sessionStore'



function VersionCheker() {

    const appConfigData = useSessionStore.getState().appConfig

    const colors = getAppTheme().colors

    const [errorVisible, setErrorVisible] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [errorButtons, setErrorButtons] = React.useState<boolean>(false);
    const [errorTitle, setErrorTitle] = React.useState<string>('');


    const checkAppVersion = async (): Promise<{ result: boolean, backEndVersion?: AppConfig }> => {

        if (Platform.OS == 'web') {
            return Promise.resolve({ result: true });
        }
        try {
            const backEndAppVersion = await ConfigApi.getAppInfoFromServer();

            const expoVersion = appConfigData.expo.version;
            const androidVersion = appConfigData.expo.android.versionCode;
            const iosBuildN = appConfigData.expo.ios.buildNumber;


            if (__DEV__ && Platform.OS == 'android') console.warn(`Expo Version [ App: ${expoVersion}, Server: ${backEndAppVersion.expo.version}] | Android Version [App: ${androidVersion}, Server: ${backEndAppVersion.expo.android.versionCode}]`)

            if (__DEV__ && Platform.OS == 'ios') console.warn(`Expo Version [ App: ${expoVersion}, Server: ${backEndAppVersion.expo.version}] | IOS Build Number [App: ${iosBuildN}, Server: ${backEndAppVersion.expo.ios.buildNumber}]`)

            if (Platform.OS == 'android') {

                if (expoVersion.localeCompare(backEndAppVersion.expo.version) < 0 || androidVersion < backEndAppVersion.expo.android.versionCode) {
                    return Promise.resolve({ result: false, backEndVersion: backEndAppVersion });
                } else {
                    return Promise.resolve({ result: true, backEndVersion: backEndAppVersion });
                }
            }

            if (Platform.OS == 'ios') {

                // console.log('expoVersion.localeCompare(backEndAppVersion.expo.version): ', expoVersion.localeCompare(backEndAppVersion.expo.version))
                // console.log('iosBuildN.localeCompare(backEndAppVersion.expo.ios.buildNumber): ', iosBuildN.localeCompare(backEndAppVersion.expo.ios.buildNumber))

                if (expoVersion.localeCompare(backEndAppVersion.expo.version) < 0 || iosBuildN.localeCompare(backEndAppVersion.expo.ios.buildNumber) < 0) {
                    return Promise.resolve({ result: false, backEndVersion: backEndAppVersion });
                } else {
                    return Promise.resolve({ result: true, backEndVersion: backEndAppVersion });
                }
            }
        } catch (error) {
            // alert("Error inesperado al comprobar la versión de la aplicación.")
            return Promise.resolve({ result: false });
        }
    }

    const openStore = () => {
        Platform.OS == 'android' ? openAppInAndroMarket() : Platform.OS == 'ios' ? openAppInIOSMarket() : openAppInIOSMarket(); openAppInAndroMarket();
    }

    const init = async () => {

        const versionResult = await checkAppVersion();
        // console.log({versionResult: versionResult.result})

        if (!versionResult.result && versionResult.backEndVersion) {
            setErrorTitle("Nueva Versión (" + versionResult.backEndVersion.expo.version + ") Disponible!");
            setErrorMessage("Actualmente tienes instalada la versión: " + appConfigData.expo.version + ". \r\nHay una versión más reciente de la aplicación publicada en la store, actualiza NTY para seguir la actualidad con las últimas funcionalidades.")
            setErrorButtons(true);
            setErrorVisible(true);
        }

    }

    const laterClicked = () => {
        setErrorTitle("Aviso");
        setErrorMessage("Es posible que las versiones no sean compatibles y la app no funcione como se espera.")
        setErrorButtons(false);
    }

    const updateClicked = () => {
        // setNotUpdated(true);
        // openStore();
        setErrorVisible(false);
    }


    React.useEffect(() => {

        init()

    }, [])



    return (
        <Portal>
            <Dialog visible={errorVisible} style={{ flexDirection: 'column', paddingVertical: 30, paddingHorizontal: 25 }} onDismiss={() => setErrorVisible(false)}>
                <TitleNty style={{ marginBottom: 10, color: colors.text_primary[500] }}>{errorTitle}</TitleNty>
                <TextNty style={{ color: colors.text_primary[400] }}>{errorMessage}</TextNty>
                <View style={{ marginTop: 30, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {errorButtons ?
                        <>
                            <ButtonNty mode='text' onPress={laterClicked}>
                                Más Tarde
                            </ButtonNty>
                            {/* <ButtonNty mode='contained'  onPress={updateClicked} mergeStyles={{ marginLeft: 15 }}>
                                Entendido
                            </ButtonNty> */}
                        </>
                        :
                        <ButtonNty onPress={() => setErrorVisible(false)}>Entendido</ButtonNty>
                    }
                </View>
            </Dialog>
        </Portal>
    )
}

export default React.memo(VersionCheker)
