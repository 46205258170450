import { StyleSheet, View } from 'react-native';

export const styles = StyleSheet.create({
    controlsContainer:{
        flexDirection:"row", justifyContent:"flex-end"
    },
    footerContainer:{justifyContent:"space-between",alignItems:"center"},
    seeMoreContainer:{padding:5},
    positionControls:{position:"absolute", right:"15px"}

})
