import { buildApiUrl, http_request } from 'src/core/http-utils';
import { ProfileInterface } from 'systemDomain';
import ProfileApiBase from './Profile.api.b';

export default class ProfileApi extends ProfileApiBase {

  static base_path = '/profile'

  // static async getMyProfile(): Promise<ProfileModel> {
  //   const url = buildApiUrl('/1/profile');
  //   const data = await http_request(url, 'GET');
  //   return new ProfileModel(data);
  // }
  static async getMyProfile(): Promise<ProfileInterface> {
    const url = buildApiUrl(ProfileApi.base_path, true);
    const data = await http_request(url, 'GET');
    return data
  }

  // static async createProfile(profile): Promise<ProfileInterface> {
  //   const url = buildApiUrl('/v1/profile');
  //   const data = await http_request<ProfileInterface>(url, 'POST', {created: new Date().toISOString(), ...profile});
  //   return data;
  //   // const data = await this.apiCaller.call('/v1/profile', 'POST', {created: new Date().toISOString(), ...profile});
  //   // return new ProfileModel(data);
  // }
  static async createProfile(profile): Promise<ProfileInterface> {
    const url = buildApiUrl(ProfileApi.base_path, true);
    const data = await http_request<ProfileInterface>(url, 'POST', { created: new Date().toISOString(), ...profile });
    return data;
    // const data = await this.apiCaller.call('/v1/profile', 'POST', {created: new Date().toISOString(), ...profile});
    // return new ProfileModel(data);
  }
  static async createProfileContact(profileEmail: string, contact: { name?: string, email: string }): Promise<ProfileInterface> {
    const url = buildApiUrl(ProfileApi.base_path + '/' + profileEmail + '/contact', true);
    const data = await http_request<ProfileInterface>(url, 'POST', contact);
    return data;
    // const data = await this.apiCaller.call('/v1/profile', 'POST', {created: new Date().toISOString(), ...profile});
    // return new ProfileModel(data);
  }
  static async deleteProfileContact(profileEmail: string, contact: { name?: string, email: string }): Promise<ProfileInterface> {
    const url = buildApiUrl(ProfileApi.base_path + '/' + profileEmail + '/contact', true);
    const data = await http_request<ProfileInterface>(url, 'DELETE', contact);
    return data;
    // const data = await this.apiCaller.call('/v1/profile', 'POST', {created: new Date().toISOString(), ...profile});
    // return new ProfileModel(data);
  }

  // static async setOnBoardingDone(profile: ProfileModel, done: boolean): Promise<ProfileModel> {
  //   const url = buildApiUrl('/v1/profile/'+profile.getId());
  //   const data = await http_request(url, 'POST', {...profile.props, onBoardingDone: done });
  //   return new ProfileModel(data);
  //   // const data = await this.apiCaller.call('/v1/profile/'+profile.getId(), 'POST', {...profile.props, onBoardingDone: done });
  //   // return new ProfileModel(data);
  // }

  static async deleteProfile(): Promise<any> {
    const url = buildApiUrl('/v1/deleteProfile');
    return await http_request(url, 'POST');
    // return await this.apiCaller.call('/v1/deleteProfile', 'POST');
  }
}
