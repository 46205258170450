import React from "react"
import { View } from "react-native"
import { FlatList, TouchableOpacity } from "react-native-gesture-handler"
import AutoImage from "src/components/AutoImage"
import { openInBrowser } from "src/core/utils"
import { Section } from "systemDomain"
import { getAppTheme } from "../../../../../../core/theme/ThemeBuilder"
import NtySectionBar from '../../../../nty/section-bar/NtySectionBar'
import { useSectionNav } from "../hooks/useSectionNav"

function NewspapersCoversSection(props: {
    section: Section<{
        cover: string,
        coverFullUrl: string,
        newspaper: string
    }>
}) {


    const { goToFunc } = useSectionNav(props.section.goTo)

    const COVER_W_H_RATIO = 150 / 190
    const COVER_HEIGHT = 320
    const COVER_WIDTH = COVER_HEIGHT * COVER_W_H_RATIO

    const SEPARATOR_WIDTH = 20


    return (

        <NtySectionBar title={props.section.title} onViewSectionPress={props.section.goTo ? goToFunc : null}>

            <FlatList<{
                cover: string,
                coverFullUrl: string,
                newspaper: string
            }>

                keyExtractor={(item) => item.cover}
                data={props.section.info.data.items}
                horizontal={true}
                snapToInterval={(COVER_WIDTH + SEPARATOR_WIDTH)}
                decelerationRate={'fast'}
                contentContainerStyle={{ paddingHorizontal: 15 }}
                ItemSeparatorComponent={() => <View style={{ width: SEPARATOR_WIDTH, height: "100%" }} />}
                renderItem={(info) => <TouchableOpacity containerStyle={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} onPress={() => {
                    if (info.item.coverFullUrl) {
                        openInBrowser(info.item.coverFullUrl)
                    } else {
                        openInBrowser(info.item.cover)
                    }
                }}>
                    <AutoImage source={{ uri: info.item.cover }} height={COVER_HEIGHT} width={COVER_WIDTH} style={{ borderRadius: 5, resizeMode: 'contain' }} loadingSource={require('src/assets/icons/nty-square-loading.gif')} containerStyle={{ ...theme.shadows.box.medium }} />
                </TouchableOpacity>}

            />

        </NtySectionBar>
    )
}
const theme = getAppTheme()
export default React.memo(NewspapersCoversSection)
