import { buildApiUrl, http_request } from 'src/core/http-utils';
import { FavouriteInterface } from 'systemDomain';

export type SearchParams = {
    search?: string,
    sites?: string[],
    sections?: string[],
    authors?: string[],
    visible?: boolean
}

class FavouriteApi {

    static base_path = '/favourites'

    constructor() { }

    static async all(): Promise<FavouriteInterface[]> {

        // let url = FavouriteApi.base_path + '?limit=' + limit;
        // const data = await getApiCaller(currentUser).call<FavouriteInterface[]>(url, 'GET');

        const url = buildApiUrl(FavouriteApi.base_path, true);
        const data = await http_request<FavouriteInterface[]>(url, 'GET');

        // console.log('FavouriteApi.all', data);

        return data;

    }


    /**
     * @param favourite
     * @returns
     */
    static async toggleFavourite(favourite: FavouriteInterface): Promise<FavouriteInterface | false> {

        const url = buildApiUrl(FavouriteApi.base_path + '/toggle', true);

        try {
            return await http_request(url, 'POST', favourite);
        } catch (e) {
            return false;
        }


    }



    /**
     * This is not used
     * @param favourite
     * @returns
     */
    static async createFavourite(favourite: FavouriteInterface): Promise<FavouriteInterface | false> {

        const url = buildApiUrl('favourites', true);
        console.log('createFavourite', url);
        try {
            const response = await http_request(url, 'POST', favourite);
            console.log('createFavourite', response);
            return !!response ? response : false;
        } catch (e) {
            console.log('createFavourite', e);
            return false;
        }

    }

}

export default FavouriteApi;
