import React from 'react'
import Background from '../../Background'
import TitleHeaderNty from '../../components/nty/header/TitleHeaderNty'
import SuperUserOptionsList from './components/SuperUserOptionsList'

function SuperUserPage() {
  return (
    <Background safeInsets>
        <TitleHeaderNty titleStyle={{fontSize: 20}} title='Opciones para Super Usuarios'/>
        <SuperUserOptionsList/>
    </Background>
  )
}

export default SuperUserPage
