import React from 'react'
import { Platform, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ActivityIndicator } from 'react-native-paper'
import WebView from 'react-native-webview'
import { ConfigParamModel } from 'systemDomain'
import { useAppDataStore } from '../../../context/appDataStore'
import { useAppTheme } from '../../../core/theme/ThemeBuilder'

import { openInBrowser } from '../../../core/utils'
import Background from '../../Background'
import NotificationList from '../../components/notification/lists/NotificationList'
import TitleHeaderNty from '../../components/nty/header/TitleHeaderNty'
import TitleNty from '../../components/nty/title/TitleNty'


function NewsPage() {


    const [tab, setTab] = React.useState('notifications')
    const { notifications, configParams, configParamsLoaded } = useAppDataStore((store) => ({ notifications: store.notifications, configParams: store.configParams, configParamsLoaded: store.configParamsLoaded }))
    // const boardParam = configParams.get ? configParams.get('newsBoard') : null
    const [boardParam, setBoardParam] = React.useState<ConfigParamModel>(null)

    React.useEffect(() => {

        if (!boardParam && configParamsLoaded && configParams?.get) {

            setBoardParam(configParams.get('newsBoard')[0])

        }


    }, [configParamsLoaded, configParams])

    const theme = useAppTheme()


    return (
        <Background safeInsets>

            <TitleHeaderNty title='Novedades' />

            {!configParamsLoaded &&
                <View style={{ flex: 1, justifyContent: 'center' }}>

                    <ActivityIndicator color={theme.colors.primary[500]} size={60} />

                </View>
            }

            {configParamsLoaded &&

                <View style={{ flex: 1 }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', borderBottomColor: theme.colors.secondary[200], borderBottomWidth: 0.3 }}>

                        <TouchableOpacity style={[{ paddingHorizontal: 15, paddingVertical: 8, borderTopRightRadius: 5, borderTopLeftRadius: 5 }]} onPress={() => tab != 'notifications' ? setTab('notifications') : null} >
                            <TitleNty fontSize={17} color={tab == 'notifications' ? theme.colors.text_primary[400] : theme.colors.text_primary.muted}>Notificaciones</TitleNty>
                            {tab == 'notifications' ? <View style={{ height: 5, borderRadius: 2, backgroundColor: theme.colors.primary[500], marginRight: 25 }} /> : null}
                        </TouchableOpacity>

                        {boardParam && boardParam?.props.data?.url && <TouchableOpacity style={[{ paddingHorizontal: 15, paddingVertical: 8, borderTopRightRadius: 5, borderTopLeftRadius: 5 }]} onPress={() => tab != 'board' ? setTab('board') : null} >
                            <TitleNty fontSize={17} color={tab == 'board' ? theme.colors.text_primary[400] : theme.colors.text_primary.muted}>Tablón de anuncios</TitleNty>
                            {tab == 'board' ? <View style={{ height: 5, borderRadius: 2, backgroundColor: theme.colors.primary[500], marginRight: 25 }} /> : null}
                        </TouchableOpacity>}

                    </View>
                    <View style={[{ flex: 1 }]}>

                        {tab == 'notifications' &&
                            <View style={{ flex: 1}}>
                                <NotificationList notifications={notifications} />
                            </View>
                        }
                        {tab == 'board' && boardParam?.props.data?.url ?
                            Platform.OS == 'web' ?
                                <iframe style={{ border: 0 }} src={boardParam?.props.data?.url} height="100%" width="100%" />
                                :
                                <WebView
                                    source={{ uri: boardParam?.props.data?.url }}
                                    onShouldStartLoadWithRequest={(req) => {

                                        if (req.url == boardParam?.props.data?.url) return true;

                                        if(req.url.indexOf('spotify.com/embed') != -1) return true;
                                        openInBrowser(req.url);
                                        return false;
                                    }}
                                    containerStyle={{ height: "100%", width: "100%" }} /> : null}


                    </View>

                </View>

            }

        </Background>
    )
}

export default NewsPage
