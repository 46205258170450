import { Dimensions, FlatListProps } from "react-native";
import { PublisherBubbleEntity, BubbleProps } from "../PublisherBubble";

type CommonPublisherListProps = {
    publishers: PublisherBubbleEntity[],
    bubbleProps?: Omit<BubbleProps, 'publisher' | 'onPress' | 'onLongPress'>,
    onBubblePress?: (entity: PublisherBubbleEntity, index: number) => void,
    onBubbleLongPress?: (entity: PublisherBubbleEntity, index: number) => void,
    paginateEvery?: number,
    horizontal?: boolean,
    renderBubble?: (entity: PublisherBubbleEntity, index: number) => JSX.Element
    renderItem?: (props: { item: PublisherBubbleEntity, index: number }) => JSX.Element
} & Omit<FlatListProps<PublisherBubbleEntity>, 'data' | 'renderItem' | 'keyExtractor' | 'horizontal'>


export type HorizontalPublisherListProps = CommonPublisherListProps;
export type VerticalPublisherListProps = CommonPublisherListProps;

export type PublisherListProps = CommonPublisherListProps


export function getNumColumnsPublisherList(screenWidth: number): number {

    const width = screenWidth ? screenWidth : Dimensions.get('window').width;

    if (width > 1440) {
        // 4K
        return 8;
    } else if (width > 1024) {
        // Laptop L
        return 6;
    } else if (width > 768) {
        // Tablet
        return 5;
    } else if (width > 525) {
        // Mobile Large
        return 4;
    }
    else {
        //Default
        return 3
    }
}
