import React from "react"


import { Dimensions } from 'react-native'
import { FlatList } from "react-native-gesture-handler"
import { ArticleInterface, Section } from "systemDomain"
import { ArticlesTitlesGroup } from "../../../../article/cards/ArticleTitle"
import NtySectionBar from '../../../../nty/section-bar/NtySectionBar'
import { useSectionNav } from "../hooks/useSectionNav"

function ArticlesTitlesSection(props: { section: Section }) {


  const {goToFunc} = useSectionNav(props.section.goTo)

    const [groups, setGroups] = React.useState<ArticleInterface[][]>(undefined)

    React.useEffect(()=>{


        let finalGroups = []
        let intermediateGroup;

        // console.log(props.section)

        props.section.info.data.items.forEach((article, index)=>{

            if(index % 3 == 0) {
                if(intermediateGroup){
                    finalGroups.push(intermediateGroup)
                }
                intermediateGroup = [article]
                return;
            } else {
                intermediateGroup.push(article)
            }

        })

        // console.log(finalGroups)
        setGroups(finalGroups)

    }, [])



    return (

        <NtySectionBar title={props.section.title} onViewSectionPress={props.section.goTo ? goToFunc : null}>

            <FlatList
                keyExtractor={(item) => item[0]._id}
                data={groups}
                horizontal={true}
                decelerationRate={'fast'}
                snapToInterval={Dimensions.get('window').width - 60}
                contentContainerStyle={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10}}
                // paginateEvery={2}
                renderItem={(items) => {
                    return <ArticlesTitlesGroup articles={items.item}/>
                }}
            />

        </NtySectionBar>
    )
}

export default React.memo(ArticlesTitlesSection)
