import { MaterialCommunityIcons } from '@expo/vector-icons'
import { BottomSheetFlatList } from '@gorhom/bottom-sheet'
import React from 'react'
import { Image, ListRenderItemInfo, Platform, StyleSheet, View } from 'react-native'
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler'
import { ActivityIndicator } from 'react-native-paper'
import useSessionStore from 'src/context/sessionStore'
import { buildFavourite } from 'src/core/favourites/FavouritesUtils'
import { FavouriteListInterface } from 'systemDomain'
import { getLocalhost } from '../../../../../environments'
import { hash_cyrb53 } from '../../../../core/jsutils'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'
import { getRandomColor } from '../../../../core/utils'
import { usePrompt, useRegisterModal } from '../../../context/alertContext'
import { useFavouritesStore } from '../../../context/favoritesStore'
import { useFavouriteListSelectorStore } from '../../../context/optionStores'
import { useSnackBar } from '../../../context/snackBarStore'
import TitleNty from '../../nty/title/TitleNty'

export type FavouriteListSelectorProps = {
    hide: (list?: FavouriteListInterface) => void
}

function FavouriteListSelector(props: FavouriteListSelectorProps) {

    // const { favouriteLists, toggleFavourite, createList } = useFavouritesStore(store => (
    //     {
    //         favouriteLists: store.favouriteLists,
    //         toggleFavourite: store.toggleFavourite,
    //         createList: store.createList
    //     }))
    const { favouriteLists, toggleFavourite, createList } = useFavouritesStore.getState()

    const favourites = useFavouritesStore.getState().favourites
    const favlists: FavouriteListInterface[] = [...favouriteLists].sort((a, b) => a.default ? -1 : 0)



    const { article } = useFavouriteListSelectorStore()
    // const [search, setSearch] = React.useState<string>()
    const theme = useAppTheme()

    const prompt = usePrompt((store) => store.prompt)

    const [loading, setLoading] = React.useState(false)
    const showRegisterModal = useRegisterModal(store => store.show)
    const showInfoMessage = useSnackBar(store => store.showInfoMessage);

    const onListPress = async (list: FavouriteListInterface) => {

        setLoading(true)
        if (useSessionStore.getState().isGuest) {
            showRegisterModal()
            return;
        }

        try {
            if (article) {
                const favourite = buildFavourite(article, list)
                const result = await toggleFavourite(favourite)
                if (result && result.success) {
                    if (result.added) {
                        showInfoMessage(`Añadido a ${list?.name ?? 'favoritos'}.`);
                    } else {
                        showInfoMessage(`Eliminado de  ${list?.name ?? 'favoritos'}.`);
                    }
                }
            }
        } catch (e) {
            console.error(e)
            showInfoMessage('Hubo un problema añadiendo el favorito a la lista.')
        } finally {
            setLoading(false)
            props.hide ? props.hide(list) : null
        }


    }

    const FavListImage = (list) => {


        let pic;

        if (list.image) {
            pic = list.image
        }

        if (!pic || pic?.startsWith('#')) {

            pic = favourites.find(fav => fav.list == list._id && fav?.article?.photo)?.article?.photo ?? pic

        }


        return (

            <View style={[StyleSheet.absoluteFillObject, { zIndex: -1 }]}>

                {pic ?

                    pic.startsWith('#') ?

                        <View style={{ backgroundColor: pic, flex: 1 }} />
                        :

                        <Image
                            source={
                                {
                                    uri: pic.includes("http") ?
                                        pic
                                        : getLocalhost + pic
                                }
                            }
                            style={{ width: "100%", height: "100%", resizeMode: 'cover' }}
                        /> :

                    <View style={{ backgroundColor: getRandomColor(), flex: 1 }}>
                    </View>
                }
            </View>

        )
    }

    const renderItem = (item: ListRenderItemInfo<FavouriteListInterface>) => {

        return (

            <TouchableOpacity onPress={() => onListPress(item.item)} style={{ flexDirection: 'row', height: 80 }} disabled={loading}>

                <View nativeID='icon' style={{ margin: 2 }}>
                    <View style={{ borderRadius: 20, overflow: 'hidden', height: 80, width: 80 }}>
                        {FavListImage(item.item)}
                    </View>
                </View>

                <View nativeID='name' style={{ flex: 1, marginLeft: 10, justifyContent: 'center' }}>
                    <TitleNty>{item.item.name}</TitleNty>
                </View>

            </TouchableOpacity>
        )

    }



    const createNewFavList = () => {

        const onDone = (list?) => {
            setLoading(false)
            props.hide ? props.hide(list) : null
        }

        if (!useSessionStore.getState().isGuest) {
            prompt({
                title: 'Introduce un nombre',
                validations:
                    [
                        { function: (data) => favouriteLists?.find((list) => list.name == data) ? false : true, errorMessage: "El nombre ya existe en tus listas" },
                        { regex: /\w{3,}/, errorMessage: 'Introduce al menos 3 caracteres' }
                    ]
            })?.then(async (result) => {
                if (result && result.data) {
                    const favourite = buildFavourite(article)
                    const list = await createList({ name: result.data, image: getRandomColor(), profile: useSessionStore.getState().profile.email, favourites: [favourite] }, showInfoMessage)
                    onDone(list)
                }
            }).catch((reason) => {console.log({ reason }); onDone()})
        } else {
            showRegisterModal()
        }

    }

    const List = Platform.OS == 'web' ? FlatList : BottomSheetFlatList

    const Wrapper = (props: { children?: JSX.Element }) => Platform.OS == 'web' ? <View style={{ height: 600 }}>{props.children}</View> : props.children


    return (
        <View style={{ flex: 1, paddingHorizontal: 40, paddingVertical: 10 }}>


            <View style={{ marginBottom: 10, alignItems: 'center' }}>

                <TitleNty>Guardar en lista</TitleNty>

            </View>

            {loading &&
                // @ts-ignore
                <ActivityIndicator color={theme.colors.primary[500]} size={30} style={{ position: 'absolute', top: "40%", alignSelf: 'center' }} />
            }

            <Wrapper>

                <List
                    data={favlists}
                    keyExtractor={(item) => hash_cyrb53(item._id, Date.now() * ((200 * Math.random()) + 1)) + '_' + item._id}
                    ListHeaderComponent={() =>

                        <TouchableOpacity onPress={() => createNewFavList()} style={{ flexDirection: 'row', height: 80, marginBottom: 15 }} disabled={loading}>
                            <View nativeID='icon' style={{ margin: 2 }}>
                                <View style={{ borderRadius: 20, overflow: 'hidden', height: 80, width: 80, backgroundColor: theme.colors.background.muted, flexDirection: 'column', justifyContent: 'center' }}>
                                    <MaterialCommunityIcons
                                        name='plus'
                                        size={40}
                                        color={theme.colors.text_secondary[300]}
                                        style={{ alignSelf: 'center' }}
                                    />
                                </View>
                            </View>

                            <View nativeID='name' style={{ flex: 1, marginLeft: 10, justifyContent: 'center' }}>
                                <TitleNty>Crear Tablero</TitleNty>
                            </View>

                        </TouchableOpacity>
                    }
                    renderItem={renderItem}
                    ItemSeparatorComponent={() => <View style={{ height: 15 }} />}
                    contentContainerStyle={{ paddingVertical: 30 }}
                // style={{ flex: Platform.OS == 'web' ? null : 1, height: Platform.OS == 'web' ? "20%" : null }}

                />

            </Wrapper>
        </View>
    )
}

export default FavouriteListSelector
