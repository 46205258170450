import DateTimePicker from '@react-native-community/datetimepicker';
import React from 'react';
import {  Platform, View, ViewStyle } from 'react-native';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { useAppTheme } from '../core/theme/ThemeBuilder';

export type DatePickerNty = {
  mode: 'date' | 'time' | 'both',
  value?: Date,
  onValueChange?: (dateValue: Date, mode: 'date' | 'time' | 'both') => void,
  style?: ViewStyle
}


/**
 * @deprecated
 * @param props
 * @returns
 */
function DatePickerNty(props: DatePickerNty) {

  const [date, setDate] = React.useState<Date>(props.value ?? new Date());
  const [mode, setMode] = React.useState(null);
  const [show, setShow] = React.useState(false);

  const theme = useAppTheme()

  const onChange = (event, selectedDate) => {
    setShow(false);
    if (selectedDate && event.type != 'dismissed') {
      const currentDate = selectedDate;
      setDate(currentDate);
      props.onValueChange ? props.onValueChange(currentDate, mode) : null;
    }

  };

  const showMode = (currentMode) => {
    setMode(currentMode);
    setShow(true);
  };

  const showDatepicker = () => {
    showMode('date')
  };

  const showTimepicker = () => {
    showMode('time');
  };

  const html5ElementRef = React.useRef<HTMLInputElement>()

  const DateTimePickerWeb = ({ value, onChange, style }) => {

    return mode == 'date' ? <DatePickerModal
      // locale="en"
      mode="single"
      visible={show}
      date={value}
      onDismiss={() => { onChange({ type: 'dismissed' }, null) }}
      onConfirm={(params) => { onChange({ type: 'confirmed' }, params.date) }}
      // validRange={{
      //   startDate: new Date(2021, 1, 2),  // optional
      //   endDate: new Date(), // optional
      //   disabledDates: [new Date()] // optional
      // }}
      // onChange={} // same props as onConfirm but triggered without confirmed by user
      saveLabel="Confirmar" // optional
      // saveLabelDisabled={true} // optional, default is false
      // uppercase={false} // optional, default is true
      label="Selecciona Fecha" // optional
    // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
    // startYear={2000} // optional, default is 1800
    // endYear={2100} // optional, default is 2200
    // closeIcon="close" // optional, default is "close"
    // editIcon="pencil" // optional, default is "pencil"
    // calendarIcon="calendar" // optional, default is "calendar"

    />
      :
      <TimePickerModal
        visible={show}
        onDismiss={() => { onChange({ type: 'dismissed' }, null) }}
        onConfirm={(params) => {
          const hourDate = date ? date : new Date()
          hourDate.setHours(params.hours)
          hourDate.setMinutes(params.minutes)
          onChange({ type: 'confirmed' }, hourDate) }}
        hours={12} // default: current hours
        minutes={14} // default: current minutes
        label="Selecciona Hora" // optional, default 'Select time'
        uppercase={false} // optional, default is true
        cancelLabel="Cancelar" // optional, default: 'Cancel'
        confirmLabel="Confirmar" // optional, default: 'Ok'
        animationType="fade" // optional, default is 'none'
      // locale="en" // optional, default is automically detected by your system
      // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
      // clockIcon="clock-outline" // optional, default is "clock-outline"
      />

    // return <input type={'datetime-local'} value={value} onChange={(event) => onChange(event, event.target && event.target.value ? new Date(event.target.value) : null)} ref={html5ElementRef} style={style} />

  }


  const renderWeb = () => {

    return (
      <View style={[{ flexDirection: 'row', justifyContent: 'center' }, props.style]}>
        <DateTimePickerWeb value={date} onChange={onChange} style={{ position: 'absolute', zIndex: -100 }} />
        {/* <ButtonNty onPress={showDatepicker} mode='contained' mergeStyles={{ width: "100%" }}>
          {'Introduce Fecha'}
        </ButtonNty> */}
        <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>

          {
            (props.mode == 'date' || props.mode == 'both') &&
            <ButtonNty onPress={showDatepicker} mode='text' mergeStyles={{ marginVertical: 3 }}>
              {'Fecha'}
            </ButtonNty>
          }
          {
            (props.mode == 'time' || props.mode == 'both') &&
            <ButtonNty onPress={showTimepicker} mode='text' mergeStyles={{ marginVertical: 3 }}>
              {'Hora'}
            </ButtonNty>
          }
        </View>
      </View>
    )

  }

  const renderNative = () => {

    return (
      <View style={props.style}>
        {show && Platform.OS != 'web' && (
          <DateTimePicker
            testID="dateTimePicker"
            value={date}
            mode={mode}
            is24Hour={true}
            onChange={onChange}
          />
        )}
        <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>

          {
            (props.mode == 'date' || props.mode == 'both') &&
            <ButtonNty onPress={showDatepicker} mode='text' mergeStyles={{ marginVertical: 3 }}>
              {'Fecha'}
            </ButtonNty>
          }
          {
            (props.mode == 'time' || props.mode == 'both') &&
            <ButtonNty onPress={showTimepicker} mode='text' mergeStyles={{ marginVertical: 3 }}>
              {'Hora'}
            </ButtonNty>
          }
        </View>
      </View>
    )
  }

  return (

    <>
      {Platform.OS == 'web' ?

        renderWeb()

        :

        renderNative()
      }


    </>
  )
}

export default DatePickerNty
