import React, { memo } from "react";
import { KeyboardAvoidingView } from 'react-native';
import { Navigation } from "../../types";
import Background from "../Background";



import { ScrollView } from "react-native-gesture-handler";
import { HighlightInterface } from 'systemDomain';
import HighlightApi from "../../api/HighlightApi";
import HighlightForm from "../components/HighlightForm";

type Props = {
  navigation: Navigation;
  setHeaderBorder: Function;
  setHeaderStyle: Function;
  route: any;
};

const AddScreen = ({ navigation, setHeaderBorder, setHeaderStyle, route }: Props) => {

  const onAddHighlight = async (highlightData: HighlightInterface) => {
    const highlight = await HighlightApi.create(highlightData);
    //@ts-ignore
    navigation.navigate('viewHighlight', { id: highlight.getId() })
  }

  return (
    <Background>
      <KeyboardAvoidingView behavior="height" style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{marginTop: 80}}>
          <HighlightForm buttonText="Add Highlight" onSubmit={onAddHighlight}></HighlightForm>
        </ScrollView>
      </KeyboardAvoidingView>
    </Background>
  );
}

export default memo(AddScreen);
