import { Article, ArticleInterface, CreateQueueArticle, QueueArticle } from 'systemDomain';
import { fromStringToBase64 } from '../core/jsutils';
import { getArticleReport } from '../core/utils';
import { QrTrialData, TrialTypes } from '../models/Trial';
import { SessionUser } from '../models/user';

import { useAppTrialStore } from 'src/context/appTrialStore';
import { getLocalhost } from '../../environments';
import { buildApiUrl, http_request } from '../core/http-utils';
import ActionApi, { ACTION_NAMES } from './ActionApi';

// const getApiCaller = (currentUser?: SessionUser): ApiCaller => {
//     const apiCaller = new ApiCaller(currentUser?.token);
//     return apiCaller
// }

export type SearchParams = {
    search?: string,
    sites?: string[],
    sections?: string[],
    authors?: string[],
    visible?: boolean | 'none'
    enterprise?: string
}

class ArticleApi {

    constructor() { }

    // static async all(currentUser: SessionUser): Promise<ArticleCollection> {
    //     const data = await getApiCaller(currentUser).call('/v1/articles', 'GET');
    //     // this.fetchArticlesAction(currentUser, 'all', '', data.length, '/v1/articles');
    //     return new ArticleCollection(data.map((item: any) => {
    //         return new ArticleModel(item);
    //     }));
    // }

    // static async batch(currentUser: SessionUser, ids: string[]): Promise<ArticleCollection> {
    //     const data = await getApiCaller(currentUser).call('/v1/articlesBatch', 'POST', ids);
    //     // this.fetchArticlesAction(currentUser, 'all', '', data.length, '/v1/articles');
    //     return new ArticleCollection(data.map((item: any) => {
    //         return new ArticleModel(item);
    //     }));
    // }

    static async read(id: string): Promise<Article> {
        // const data = await getApiCaller(currentUser).call('/v1/articles/' + id, 'GET');
        const url = buildApiUrl('/v1/articles/' + id)
        const data = await http_request<Article>(url, 'GET')
        return data
    }
    static async readCedro( article_cedro_composite_id: string): Promise<Article> {
        // const url = ApiCaller.buildApiUrl('articles/'+article_id+'/feed/'+feed_id, true)
        const url = buildApiUrl('articles/cedro/'+article_cedro_composite_id, true)

        const prom = http_request<Article>(url, 'GET')

        return prom
    }

    // static async create(currentUser: SessionUser, article: ArticleModel): Promise<ArticleModel> {
    //     const data = await getApiCaller(currentUser).call('/v1/articles', 'POST', article.toObject());
    //     return new ArticleModel(data);
    // }

    static async createQueueArticle(article: CreateQueueArticle): Promise<QueueArticle> {
        const url = new URL(getLocalhost + '/queue-api/article-queue')
        const basicAuth = 'Basic ' + fromStringToBase64('article_queue_service_user:article_queue_dev')
        const data = await http_request<QueueArticle>(url, 'POST', article, {headers: { 'Authorization': basicAuth }});
        return data;
    }

    static async update(articleId: string, article: Partial<Article>): Promise<Article> {

        // const url = ApiCaller.buildApiUrl('articles/'+ articleId, true)
        // const data = await getApiCaller(currentUser).call(url, 'PUT', article);
        const url = buildApiUrl('articles/'+ articleId, true)
        const data = await http_request<Article>(url, 'PUT', article);
        return data;
    }

    static async delete(currentUser: SessionUser, id: string): Promise<void> {
        const url = buildApiUrl('/v1/articles/' + id + '/delete')
        return await http_request(url, 'GET')
    }


    // static async userArticles(currentUser: SessionUser, categories?: string[], skip?: number, limit?: number): Promise<ArticleCollection> {
    //     const categoriesValues = categories && categories.length > 0 ? categories : [];


    //     let url = ApiCaller.buildApiUrl('/v1/user/articles');

    //     if (skip)
    //         url.searchParams.append('skip', skip.toString())

    //     if (limit)
    //         url.searchParams.append('limit', limit.toString())

    //     try {
    //         const data = await getApiCaller(currentUser).call(url, 'POST', { categories: categoriesValues });
    //         // this.fetchArticlesAction(currentUser, 'userArticles', categories[0], data.length, '/v1/user/articles/itemsToSkip');
    //         return new ArticleCollection(data.map((item: any) => {
    //             return new ArticleModel(item);
    //         }));
    //     } catch (e) {
    //         console.log('User Articles')
    //         console.log(url)
    //         console.error(e);
    //         return new ArticleCollection([])
    //     }
    // }

    // static async enterpriseArticles(enterpriseKeyName: string, currentUser: SessionUser, categories?: string[], itemstoSkip?: number, limit?: number): Promise<ArticleCollection> {


    //     const categoriesValues = categories && categories.length > 0 ? categories : [];

    //     const skip = itemstoSkip && itemstoSkip >= 1 ? itemstoSkip : 0;
    //     const searchLimit = limit ? limit : 0;

    //     let url = ApiCaller.buildApiUrl(`/v1/enterprise/${enterpriseKeyName}/articles`);

    //     url.searchParams.append('skip', skip.toString())
    //     url.searchParams.append('limit', limit.toString())

    //     try {
    //         const data = await getApiCaller(currentUser).call(url.toString(), 'POST', { categories: categoriesValues });
    //         // this.fetchArticlesAction(currentUser, 'enterpriseArticles', categories[0], data.length, `/v1/enterprise/${enterpriseKeyName}/articles/`);
    //         return new ArticleCollection(data.map((item: any) => {
    //             return new ArticleModel(item);
    //         }));
    //     } catch (e) {
    //         console.log('Enterprise Articles')
    //         console.log(url)
    //         console.error(e);
    //         return new ArticleCollection([])
    //     }
    // }

    // static async userQuickNews(currentUser: SessionUser, discardedIds: string[], categories?: string[], itemstoSkip?: number): Promise<ArticleCollection> {

    //     const categoriesValues = categories && categories.length > 0 ? categories : [];

    //     const disardedIdsValues = discardedIds && discardedIds.length > 0 ? discardedIds : [];

    //     const skip = itemstoSkip && itemstoSkip >= 1 ? itemstoSkip : 0;

    //     let url = '/v1/user/quicknews/' + skip;
    //     try {
    //         const data = await getApiCaller(currentUser).call(url, 'POST', { categories: categoriesValues, discards: disardedIdsValues });
    //         // this.fetchArticlesAction(currentUser, 'userQuickNews', '', data.length, '/v1/user/quicknews/itemsToSkip');
    //         return new ArticleCollection(data.map((item: any) => {
    //             return new ArticleModel(item);
    //         }));
    //     } catch (e) {
    //         console.log(url)
    //         console.error(e);
    //         return new ArticleCollection([])
    //     }
    // }

    // static async userPodcasts(currentUser: SessionUser, discardedIds: string[], categories?: string[], itemstoSkip?: number): Promise<ArticleCollection> {

    //     const categoriesValues = categories && categories.length > 0 ? categories : [];

    //     const disardedIdsValues = discardedIds && discardedIds.length > 0 ? discardedIds : [];

    //     const skip = itemstoSkip && itemstoSkip >= 1 ? itemstoSkip : 0;

    //     let url = '/v1/user/podcast/all/' + skip;
    //     try {
    //         const data = await getApiCaller(currentUser).call(url, 'POST', { categories: categoriesValues, discards: disardedIdsValues });
    //         // this.fetchArticlesAction(currentUser, 'userPodcasts', '', data.length, '/v1/user/podcast/itemsToSkip');
    //         return new ArticleCollection(data.map((item: any) => {
    //             return new ArticleModel(item);
    //         }));
    //     } catch (e) {
    //         console.log(url)
    //         console.error(e);
    //         return new ArticleCollection([])
    //     }
    // }

    // static async allQuickNews(currentUser: SessionUser, discardedIds: string[], categories?: string[], itemstoSkip?: number): Promise<ArticleCollection> {

    //     const categoriesValues = categories && categories.length > 0 ? categories : [];

    //     const disardedIdsValues = discardedIds && discardedIds.length > 0 ? discardedIds : [];

    //     const skip = itemstoSkip && itemstoSkip >= 1 ? itemstoSkip : 0;

    //     let url = '/v1/user/quickNews/all/' + skip;
    //     try {
    //         const data = await getApiCaller(currentUser).call(url, 'POST', { categories: categoriesValues, discards: disardedIdsValues });
    //         // this.fetchArticlesAction(currentUser, 'allQuickNews', '', data.length, '/v1/user/quicknews/all/itemsToSkip');
    //         return new ArticleCollection(data.map((item: any) => {
    //             return new ArticleModel(item);
    //         }));
    //     } catch (e) {
    //         console.log(url)
    //         console.error(e);
    //         return new ArticleCollection([])
    //     }
    // }

    // static async getArticlesBySectionName(currentUser: SessionUser, sectionName: string, itemsToSkip: number = 0, limit: number = 10): Promise<ArticleCollection> {

    //     let path = '/v1/articles/section/' + sectionName;
    //     const url = ApiCaller.buildApiUrl(path)

    //     if (itemsToSkip) {
    //         // url += ('?itemsToSkip=' + itemsToSkip)
    //         url.searchParams.append('skip', itemsToSkip.toString())
    //     }

    //     if (limit) {
    //         // url += ('&limit=' + limit)
    //         url.searchParams.append('limit', limit.toString())
    //     }


    //     const data = await getApiCaller(currentUser).call(url, 'GET');
    //     // this.fetchArticlesAction(currentUser, 'sectionArticles', sectionName, data.length, '/v1/articles/sections/' + sectionName);
    //     return new ArticleCollection(data.map((item: any) => {
    //         return new ArticleModel(item);
    //     }));
    // }


    // static async getNaiveCategorized(currentUser: SessionUser, searchQuery: string, itemsToSkip: number): Promise<ArticleCollection> {

    //     let url = '/v1/naive/categorized/';
    //     if (searchQuery && itemsToSkip) {
    //         url += '?search=' + searchQuery + '&itemsToSkip=' + itemsToSkip
    //     } else if (searchQuery) {
    //         url += '?search=' + searchQuery
    //     }
    //     if (itemsToSkip) {
    //         url += '?itemsToSkip=' + itemsToSkip
    //     }


    //     const data = await getApiCaller(currentUser).call(url, 'GET');
    //     return new ArticleCollection(data.map((item: any) => {
    //         return new ArticleModel(item);
    //     }));
    // }

    /**
     * This method writes visualization data for the given article in 3 stages:
     *
     *  - 1: If the current session is 'qrActive', creates a viewArticleQr action
     *  - 2: Creates a viewArticleAction
     *  - 3: Adds a 'view' to the article in the 'views' field.
     *
     * @param article the article that is going to be updated
     * @param currentUser the logged in user
     */
    static async viewArticleAction(article: Article) {

        const sessionData: any = useAppTrialStore.getState()
        const articleData = getArticleReport(article)

        //Stage 1 viewArticleQr
        try {
            const active = sessionData.trial == TrialTypes.QR;
            if (active) {
                const data: QrTrialData = sessionData.trialData;
                ActionApi.createFrom({ name: ACTION_NAMES.VIEW_ARTICLE_QR, data: { ...articleData, enterprise: data.enterpriseKey ?? 'none' } })
            }
        } catch (e) {
            console.warn("Something went wrong while creating viewArticleQR action.");
            console.error(e);
        }

        //Stage 2 viewArticle
        try {

            ActionApi.createFrom({ name: ACTION_NAMES.VIEW_ARTICLE, data: articleData });

        } catch (e) {
            console.warn("Something went wrong while creating viewArticle action.");
            console.error(e);
        }

        //Stage 3 add Article view
        try {
            ArticleApi.addArticleView(article);
        } catch (e) {
            console.warn("Something went wrong while adding a view to the article.");
            console.error(e);
        }
    }


    static async addArticleView(article: Article) {
        const id = article._id
        // const id = article.getId();
        // const data = await getApiCaller(currentUser).call('/v1/articles/' + id + '/viewed', 'POST');
        const url = buildApiUrl('/v1/articles/' + id + '/viewed')
        const data = await http_request<Article>(url, 'POST')
        return data

    }


    /**
     * Deletes the article from the user's feed
     * @returns
     *
     */
    static async unFeed(article_id: string, feed_id: string, currentUser?: SessionUser) {
        // const url = ApiCaller.buildApiUrl('articles/'+article_id+'/feed/'+feed_id, true)
        const url = buildApiUrl('articles/'+article_id+'/feed/'+feed_id, true)
        const data = await http_request<Article>(url, 'DELETE')
        // const data = await getApiCaller(currentUser).call<ArticleInterface>(url, 'DELETE')
        return data
    }

    /**
     *
     * @param article_id the identifier of the feed whose articles need to be fetched
     * @returns
     */
    static async addToFeed(article_id: string, feed_id: string) {

        // const url = ApiCaller.buildApiUrl('articles/'+article_id+'/feed/'+feed_id, true)
        const url = buildApiUrl('articles/'+article_id+'/feed/'+feed_id, true)
        const data = await http_request<Article>(url, 'POST')
        // const data = await getApiCaller(currentUser).call<ArticleInterface>(url, 'POST')
        return data

    }

}

export default ArticleApi;
