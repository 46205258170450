import React from 'react'


import { Platform } from 'react-native'
import FontsLoader from './FontsLoader'
import CookiesChecker from './CookiesChecker'
import VersionCheker from './VersionCheker'



const web_custom_fixed_styles = `
textarea, select, input {
	-webkit-appearance: none;
	outline: none!important;
}
textarea:focus, select:focus, input:focus, button:focus {
	-webkit-appearance: none;
	outline: none!important;
}

html, body {
	overflow-x: hidden;
	overflow-y: hidden;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
::-webkit-scrollbar-track {
	margin: 15px;
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
  }
::-webkit-scrollbar-thumb:hover{
  	background: rgba(0,0,0,0.3);
  }
::-webkit-scrollbar-thumb:active{
  	background: rgba(0,0,0,.7);
  }
`

export const injectWebCss = () => {

    // Only on web
    if (Platform.OS != 'web') return

    // Inject style
    const style = document.createElement('style')
    style.textContent = web_custom_fixed_styles;
    return document.head.append(style)
}

// 👉 And this in the App.js file
injectWebCss()

function AppLoader(props: {fontsLoaded?: (error?: Error) => void}) {

    // const theme = getAppTheme()

    return (
        <>
            {/* <ActivityIndicator color={theme.colors.primary[500]} size={100} style={{ position: 'absolute', alignSelf: 'center', top: "40%" }} /> */}
            {/* <MainFontsLoader /> */}
            <FontsLoader fontsLoaded={props.fontsLoaded}/>
            <VersionCheker />
            <CookiesChecker />
        </>

    )
}

export default AppLoader
