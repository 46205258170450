import React from 'react'
import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'
import { FavouriteListInterface } from 'systemDomain'
import FavouriteListSelector from '../../../components/favourites/favouritelist/FavouriteListSelector'
import { useFavouriteListSelectorStore } from '../../optionStores'

function FavouriteListSelectorModal() {

    const { modalVisible, setModalVisible, resolve } = useFavouriteListSelectorStore()
    const modalRef = React.useRef<NewsToYouSheetModalHandle>()

    const visibleRef = React.useRef(false)

    const hideModal = (list?: FavouriteListInterface) => {

        visibleRef.current = false;
        modalRef.current.hide()
        setModalVisible(false)
        resolve? resolve(list) : null

    }
    const showModal = () => {

        visibleRef.current = true;
        modalRef.current.show()
        setModalVisible(true)

    }

    React.useEffect(() => {
        // console.log({visible, visibleRef: visibleRef.current})
        if (modalVisible == true && visibleRef.current == false) {
            console.log('showing')
            showModal()
        } else if(modalVisible == false && visibleRef.current == true){
            console.log('closing')
            hideModal()
        }
    }, [modalVisible])

    return (
        <NtySheetModal ref={modalRef} onClose={()=>hideModal()} modalHeight={550} >
           <FavouriteListSelector hide={(list)=>hideModal(list)}/>
        </NtySheetModal>
    )
}

export default FavouriteListSelectorModal
