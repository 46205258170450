import React, { memo, useEffect } from "react";
import { View, useWindowDimensions } from 'react-native';
import { ActivityIndicator, IconButton } from 'react-native-paper';
import { Article, ArticleSearchOrderBy, SearchResponse, SortDirection } from "systemDomain";
import ActionApi, { ACTION_NAMES } from '../../api/ActionApi';
import { useAppTheme } from '../../core/theme/ThemeBuilder';
import Background from "../Background";
import ArticleListNewsToYouLight from '../components/article/lists/ArticleListNewsToYouLight';
import TitleHeaderNty from '../components/nty/header/TitleHeaderNty';
import NtySearchBar from '../components/nty/searchbar/NtySearchBar';
import TextNty from '../components/nty/text/TextNty';
import { SearchApi } from "src/api/SearchApi";
import ArticleList from "../components/article/lists/ArticleList";
import { defaultDateFilter, useSearchStore } from "src/context/appSearchStore";
import useDebounce from "src/core/hooks/useDebounce";
import LoadingIndicator from "../components/loaders/Loading";

type Props = {
  setHeaderBorder: Function;
  setHeaderStyle: Function;
  route: any;
};

const SearchKeyScreen = ({ setHeaderStyle, route }: Props) => {


  const [filteredArticles, setFilteredArticles] = React.useState<Article[]>();
  const [searchResponse, setResponse] = React.useState<SearchResponse<Article>>()
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedText = useDebounce(searchQuery, 700)
  const [keyword, setKeyword] = React.useState<string>(null);

  const searchAction = (response: any[]) => {

    const actionData = {
      search: searchQuery,
      results: response?.length
    }

    if (searchQuery && searchQuery.length > 5)
      ActionApi.createFrom({ name: ACTION_NAMES.SEARCH_KEYWORD, data: actionData })

  }

  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
  };
  const readFilteredArticles = async () => {
    try {
      setLoading(true);
      let articles = [];
      const search = debouncedText ? `"${keyword}" "${debouncedText}"` : `"${keyword}"`

      const response = await SearchApi.searchArticles({ searchText: search, ...defaultDateFilter() }, { sort: { orderBy: ArticleSearchOrderBy.RELEVANCE, direction: SortDirection.DESC }, page: { skip: 0, limit: 20 } })
      articles = response.results

      setFilteredArticles(articles);
      setResponse(response)
      searchAction(articles)
      return articles;

    } catch (err) {
      console.error(err)
      setFilteredArticles(null)
    } finally {
      setLoading(false);
    }
  }
  const onLoadMore = async () => {
    var itemsToSkip: number = filteredArticles?.length;
    if (itemsToSkip) {
      setLoading(true);
      try {
        let articles = [];
        const search = debouncedText ? `"${keyword}" "${debouncedText}"` : `"${keyword}"`
        const response = await SearchApi.searchArticles({ searchText: search }, { sort: { orderBy: ArticleSearchOrderBy.RELEVANCE, direction: SortDirection.DESC }, page: { skip: itemsToSkip, limit: 20 } })
        articles = response.results

        const repeated = articles.some((a) => filteredArticles?.indexOf(a) >= 0)
        if (articles.length > 0 && !repeated) {
          const result = filteredArticles?.concat(articles);
          setFilteredArticles(result);
          setResponse(response)
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  }
  const onRefresh = async () => {

    setLoading(true);
    await readFilteredArticles();
    setLoading(false);
    //return Promise.resolve(articles);
  }

  const onFiltersCancel = () => {
    setSearchQuery('');
  }

  useEffect(() => {
    if (keyword)
      readFilteredArticles();
  }, [debouncedText, keyword]);






  React.useEffect(() => {

    const key: string = decodeURIComponent(route?.params?.keyword);

    if (keyword != key) {
      setFilteredArticles(null)
    }
    if (key && key.length > 1) {
      if (key.indexOf('"') != -1) {
        setKeyword(key.replace(new RegExp('"', 'g'), ''));
      } else {
        setKeyword(key);

      }
    }

  }, [route]);

  const { height } = useWindowDimensions();
  const theme = useAppTheme()

  return (

    <Background style={{ height: height }} safeInsets>

      {loading && <ActivityIndicator color={theme.colors.primary[500]} size={50} style={{ position: 'absolute', alignSelf: 'center', top: "45%", zIndex: 1000 }} />}

      <View style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>

        <TitleHeaderNty
          title={keyword}
          titleStyle={{ fontSize: 22 }}
        />

        <View style={{ zIndex: 1000, marginTop: 10, paddingHorizontal: 3 }}>

          <NtySearchBar
            placeholder="Introduce un término de búsqueda"
            onChangeText={onChangeSearch}
            clearIconPress={onFiltersCancel}
            value={searchQuery}
          />

        </View>
        <View style={{ flex: 10, marginTop: -5 }}>
          {
            (filteredArticles?.length == 0) && !loading
              ?
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <TextNty color={theme.colors.text_primary[500]}>No se han encontrado resultados...</TextNty>
                <TextNty type='extraLight' fontSize={15} color={theme.colors.text_primary.muted} style={{ marginTop: 10 }}>Prueba a cambiar los parámetros de tu búsqueda.</TextNty>
              </View>

              :
              filteredArticles && <ArticleList
                ListHeaderComponent={() =>

                  searchResponse ?
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 5, marginBottom: 8 }}>

                      <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <TextNty type='bold' fontSize={15} ><TextNty fontSize={15}>Mostrando</TextNty> {searchResponse?.page.totalItems} resultados</TextNty>
                        {/* {downloadLoading ? <LoadingIndicator size={20} style={{ marginLeft: 5 }} /> : <IconButton icon={'download'} onPress={_onDownloadPress} size={20} />} */}
                      </View>
                    </View> : null

                }
                loadMoreDisabled={false}
                onLoadMore={filteredArticles.length < searchResponse?.page.totalItems ? onLoadMore : null}
                data={filteredArticles}
                onRefresh={onRefresh}
                contentContainerStyle={{ paddingTop: 20, paddingBottom: 50 }} />

          }
        </View>
      </View>
    </Background>
  );
}

export default memo(SearchKeyScreen);

