import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'
import { ScrollView, TouchableWithoutFeedback } from 'react-native-gesture-handler'
import { IconButton } from 'react-native-paper'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'
import TitleNty from '../title/TitleNty'


export type TitleHeaderNtyProps = {
  children?: JSX.Element,
  title: string,
  titleStyle?: TextStyle,
  style?: ViewStyle,
  onPress?: () => void,
  onBackIconPressed?: () => void,
  onOptionsPress?: (title?: string) => void
  goBackHidden?: boolean

}

function TitleHeaderNty(props: TitleHeaderNtyProps) {

  const navigation = useNavigation();
  const theme = useAppTheme();


  const goBack = () => {

    if(props.onBackIconPressed) {
      props.onBackIconPressed()
      return
    }

    if (navigation.canGoBack()) {
      navigation.goBack()
    }
    else {
      navigation.navigate('home');
    }
  }


  return (
    <View style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch' }}>

      <View style={[{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5, marginTop: 5 }, props.style]}>

        {!props.goBackHidden && <IconButton
          color={theme.colors.text_primary[400]}
          size={30}
          style={{ zIndex: 10000, position: 'absolute', left: 0, backgroundColor: theme.colors.background[500] + 'D9' }}
          // background={theme.colors.background[500]}
          icon='chevron-left'
          onPress={goBack}
        />}
        <TouchableWithoutFeedback style={{ flex: 1 }} containerStyle={{ flex: 1, flexDirection: 'row' }} onPress={props.onPress ? () => { props.onPress() } : null}>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flex: 1 }} style={{ paddingVertical: 10, paddingHorizontal: 10 }} >
            <TitleNty style={{ ...props.titleStyle, marginVertical: 7 }} color={theme.colors.text_primary[500]}>{props.title}</TitleNty>
          </ScrollView>
        </TouchableWithoutFeedback>
        {
          props.onOptionsPress && <IconButton
            color={theme.colors.text_primary[400]}
            size={25}
            style={{ zIndex: 10000, position: 'absolute', right: 5, backgroundColor: theme.colors.background[500] + 'D9' }}
            // background={theme.colors.background[500]}
            icon='dots-vertical'
            onPress={() => props.onOptionsPress(props.title)}
          />
        }
      </View>
      {props.children ? props.children : null}
    </View>

  )
}

export default TitleHeaderNty
