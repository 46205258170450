//@deprecated to be removed, not in use

import { StackScreenProps } from '@react-navigation/stack';
import React from 'react';
import { View, useWindowDimensions } from 'react-native';
import { NamedQueryApi } from 'src/api/NamedQueriesApi';
import ArticleListNewsToYouLight from 'src/app/components/article/lists/ArticleListNewsToYouLight';
import { Article, Section } from 'systemDomain';
import HomeApi from '../../../../../api/HomeAPI';
import { ScreensParamsList } from '../../../../../core/navigation/screens/NavigationScreens';
import Background from '../../../../Background';
import TitleHeaderNty from '../../../nty/header/TitleHeaderNty';


type ArticlesSectionPageProps = StackScreenProps<ScreensParamsList, 'viewArticlesSection'>;

function ArticlesSectionPage({ route, navigation }: ArticlesSectionPageProps) {

    const [articles, setArticles] = React.useState(route.params.section.info.data.items)
    const [loading, setLoading] = React.useState(false)
    const section: Section<Article, any> = React.useMemo(() => route.params.section, [route.params])

    const loadMore = async () => {
        if (section.info.data.type == 'data') return

        if (loading) return

        if (section.info.data.type == 'namedQuery') {

            setLoading(true)
            const newArticles = await NamedQueryApi.fetch(section.info.data.namedQuery, section.info.params, { skip: articles.length, limit: 10, sort: { published_at: -1 } })
            if (newArticles.length > 0) {
                setArticles(articles.concat(newArticles.filter(article => !articles.find(a => a._id == article._id))))
            }
            setLoading(false)

        } else {

            setLoading(true)
            const newArticles = await HomeApi.sectionQuery(section.info.data.query, articles.length, 10)
            if (newArticles.length > 0) {
                setArticles(articles.concat(newArticles.filter(article => !articles.find(a => a._id == article._id))))
            }
            setLoading(false)
        }
    }

    const { height } = useWindowDimensions();

    return (
        <Background safeInsets style={{ height: height }}>
            <View style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>

                <TitleHeaderNty
                    title={section.title}

                    titleStyle={{ fontSize: 22 }}

                />

                <ArticleListNewsToYouLight

                    articles={articles}
                    // onEndReachedThreshold={0.4}
                    onLoadMore={loadMore}
                    loadMoreDisabled={false}
                />
            </View>
        </Background>
    )
}

export default ArticlesSectionPage
