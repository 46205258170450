import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Linking, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { WebView } from 'react-native-webview';
import useSessionStore from "src/context/sessionStore";
import { extractKeywordsFromArticle } from "src/core/utils";
import { Article, ArticleInterface } from "systemDomain";


export interface ArticleWebViewProps {
    article: Article,
    src: string,
    useNativeWebView?: boolean,
    useIframe?: boolean
}

const ArticleWebView = (props: ArticleWebViewProps) => {

    const navigation = useNavigation();

    const url = props.src;
    const [ready, setReady] = React.useState(false);

    useEffect(() => {

        if (!props.useNativeWebView) {
            Linking.canOpenURL(url).then(
                (supported) => {
                    if (supported) {
                        Linking.openURL(url);
                    }
                })
        } else if (props.useNativeWebView) {
            setReady(true);
        }
        //test if URL is accesible

    }, []);


    const openNewTabWindow = () => {


        const w = window.open(url, '_blank');
        if (windowOpened(w)) {
            navigation.goBack();
        } else {
            alert("Popups must be enabled.");
            navigation.goBack();
        }

    }


    const windowOpened = (pop) => {

        if (!pop || pop.closed || pop.closed == "undefined" || pop == "undefined") {
            pop && pop.close();
            return false;
        } else {
            return true;
        }
    }

    const enterprise = useSessionStore.getState().enterprise
    const keywords: string[] = enterprise ? extractKeywordsFromArticle(props.article, enterprise?.key) : []

    const renderLoading = () => <ActivityIndicator size="large" animating={true} color="red" style={{ position: 'absolute', right: 25, top: 10 }} />
    const injectedJavaScript = () => {
        const script = `
      setTimeout(()=>{
        // el Economista
        // ABC
        const didomiButton = document.getElementById('didomi-notice-agree-button');
        if(didomiButton) {
            didomiButton.click();
        }
        //the Guardian
        const theGuardianButton = document.querySelector('button[title^="Yes"]');
        if(theGuardianButton) {
            theGuardianButton.click();
        }
      }, 1000);

      function highlight2(tokens) {

        let html = document.body.innerHTML

        for (var token of tokens) {

            const indexes = locations(token.replace(new RegExp('"', "").toLowerCase(), 'g'), html.toLowerCase())

            for(let index of indexes){

                if(index != -1) {

                    let contentBefore = html.substring(0, index);
                    let highlight = "<span style='background-color: yellow'>" + html.substring(index, index + token.length) + "</span>"
                    let contentAfter = html.substring(index + token.length);

                    console.log({contentBefore, highlight, contentAfter})

                    html = contentBefore + highlight + contentAfter

                }

            }

        }

        document.body.innerHTML = html
    }

    function highlight3(tokens) {

        let html = document.body.innerHTML

        for(let token of tokens) {
            let re = new RegExp('<\\\\w+[^>]*>(?:(?:(?!<\\\\/\\\\w+>).)*?('+token.replace(new RegExp('"', '')+')(?:(?!<\\\\/\\\\w+>).)*?)<\\\\/\\\\w+>', 'g'), 'gi');



        }

    }
    function h4(tokens) {

        let html = document.body.innerHTML;

        for (let token of tokens) {
            let index = -1

            token = token.replace(new RegExp('"', 'g'), "").toLowerCase()

            while((index=html.toLowerCase().indexOf(token,i+1)) >= 0) {
                if(index != -1) {

                    let contentBefore = html.substring(0, index);
                    let highlight = "<span style='background-color: yellow'>" + html.substring(index, index + token.length ) + "</span>"
                    let contentAfter = html.substring(index + token.length + 1 , html.length);

                    console.log({contentBefore, highlight, contentAfter})

                    html = contentBefore + highlight + contentAfter
                    // e.innerHTML = html;
                }


            }
        }

        document.body.innerHTML = html
    }

      function highlight(tokens) {
        if(!tokens || tokens.length == 0) return;



        for(var token of tokens) {



            var body = document.body

            const tagNames = ['p','h1','h2','h3','h4','h5']

            var elements = []

            for(let tag of tagNames) {
                // alert(tag)
                elements = elements.concat(Array.from(body.getElementsByTagName(tag)))
            }
            // alert(JSON.stringify(elements))

            // var re = new RegExp('<\\\\w+[^>]*>(?:(?:(?!<\\\\/\\\\w+>).)*?('+token.replace(new RegExp('"', '')+')(?:(?!<\\\\/\\\\w+>).)*?)<\\\\/\\\\w+>', 'g'), 'gi');
            // alert(re.toString())

            try {


                for(var e of  elements) {

                    var html = e.innerHTML

                    if(html != null) {

                        // var re = new RegExp(token.replace(new RegExp('"', ''), 'g'), 'gi');
                        // var matches = html.match(re);

                        // if(matches) {
                        //     // alert(JSON.stringify(matches))
                        //     for(var match of matches){

                        //         match = re.exec(html)

                        //         var index = match.index

                        //         if(index != -1) {

                        //             let contentBefore = html.substring(0, index);
                        //             let highlight = "<span style='background-color: yellow'>" + html.substring(index, index + token.length - 1) + "</span>"
                        //             let contentAfter = html.substring(index + token.length + 1, html.length);

                        //             alert(JSON.stringify({ highlight }))

                        //             html = contentBefore + highlight + contentAfter
                        //             e.innerHTML = html;
                        //         }
                        //     }
                        // }


                        // #####################################



                        // var re = new RegExp('>.*'+token.replace(new RegExp('"', '')+'.*<', 'g'), 'gi');
                        // var matches = html.match(re);

                        // if(matches) {
                        //     alert(JSON.stringify(matches))
                        //     for(var match of matches){

                        //         match = re.exec(html)

                        //         var index = match.index

                        //         if(index != -1) {

                        //             let contentBefore = html.substring(0, index);
                        //             let highlight = "<span style='background-color: yellow'>" + html.substring(index, index + token.length - 1) + "</span>"
                        //             let contentAfter = html.substring(index + token.length + 1, html.length);

                        //             // alert(JSON.stringify({ contentBefore, highlight, contentAfter }))

                        //             html = contentBefore + highlight + contentAfter

                        //         }
                        //     }
                        // }



                        // #####################################


                        // const indexes = locations(token.replace(new RegExp('"', "").toLowerCase(), 'g'), html.toLowerCase())
                        // console.log({indexes, element: html})

                        // for(let index of indexes){

                        //     if(index != -1) {

                        //         let contentBefore = html.substring(0, index);
                        //         let highlight = "<span style='background-color: yellow'>" + html.substring(index, index + token.length - 1) + "</span>"
                        //         let contentAfter = html.substring(index + token.length + 1, html.length);

                        //         console.log({contentBefore, highlight, contentAfter})

                        //         html = contentBefore + highlight + contentAfter

                        //     }

                        // }



                        var index = html.toLowerCase().indexOf(token.replace(new RegExp('"', 'g'), "").toLowerCase())

                        if(index != -1) {

                            let contentBefore = html.substring(0, index);
                            let highlight = "<span style='background-color: yellow'>" + html.substring(index, index + token.length - 1 ) + "</span>"
                            let contentAfter = html.substring(index + token.length + 1 , html.length);

                            console.log({contentBefore, highlight, contentAfter})

                            html = contentBefore + highlight + contentAfter
                            e.innerHTML = html;
                        }

                        // e.innerHTML = html

                    }
                }
            } catch (e) {
                alert(e)
                alert(JSON.stringify(e))
            }
        }
      }

      function locations(substring,string){
        var a=[],i=-1;
        while((i=string.indexOf(substring,i+1)) >= 0) a.push(i);
        return a;
      }


        highlight(${JSON.stringify(keywords ?? [])});
        // setTimeout(() => alert('hi'), 1000)}


     `;

        return script;


    }

    return (

        <>
            {/* <LogoHeaderNty showMenu={false} /> */}
            {
                (ready) ?

                    (props.useNativeWebView) ?
                        <View style={{ flex: 1 }}>
                            {
                                url ?
                                    <>
                                        <WebView
                                            injectedJavaScript={injectedJavaScript()}
                                            style={[{ flex: 1 }]}
                                            source={{ uri: url }}
                                            renderLoading={renderLoading}
                                            startInLoadingState={true}
                                            domStorageEnabled={true}
                                            scrollEnabled={true}
                                            onMessage={(event) => { }}
                                        />
                                    </>
                                    :
                                    null
                            }
                        </View>
                        : props.useIframe ?
                            <View style={{ flex: 1 }}>
                                {
                                    url ?
                                        <>
                                            <iframe resource={url}></iframe>
                                        </>
                                        :
                                        null
                                }
                            </View>
                            : null
                    :
                    renderLoading

            }
        </>
    )
}

export default ArticleWebView;
