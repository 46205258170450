import React from 'react'
import NtyAlertModal from './alerts/NtyAlertModal'
import NtyPromptAlertModal from './alerts/NtyPromptAlertModal'

function ContextAlertsLoader() {

  return (
    <>
      <NtyAlertModal />
      <NtyPromptAlertModal />
    </>
  )
}

export default ContextAlertsLoader
