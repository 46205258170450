import React, { memo, useEffect, useState } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import Background from '../app/Background';
import Logo from '../components/Logo';

import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import TextNty from '../app/components/nty/text/TextNty';
import NtyTextInput from '../app/components/nty/TextInput/TextInputNty';
import TitleNty from '../app/components/nty/title/TitleNty';
import ApiCaller from '../core/ApiCaller';
import { theme } from '../core/theme';

type Props = {
  navigation: any;
};

const ChangePasswordScreen = ({ navigation }: Props) => {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [token, setToken] = useState<string>();

  useEffect(() => {
    let initialUrl = window.location.href;
    if (initialUrl.includes('?token=')) {
      const splittedUrl = initialUrl.split('?token=');
      let tokenTmp = splittedUrl[1];
      setToken(tokenTmp);
    }
  }, [])

  const [pwChangedTitle, setpwChangedTitle] = React.useState(null);
  const [pwChangedMessage, setpwChangedMessage] = React.useState(null);
  const modalRef = React.useRef<NewsToYouSheetModalHandle>(null)


  const _onChangePasswordPressed = async () => {
    if (password != rePassword) {
      console.log(password, rePassword);
      setpwChangedMessage("Las contraseñas no coinciden");
      return;
    }
    const apiCaller = new ApiCaller(token);
    try {
      const res = await apiCaller.call('/v1/changePassword/', 'POST', { password });
      console.log("Response", res)
      if (res['modifiedCount'] >= 0) {
        setpwChangedTitle('Contraseña cambiada correctamente');
        setpwChangedMessage('¡Puedes iniciar sesión con tus nuevas credenciales en News To You!')
        setPassword('');
        setRePassword('')
      }
      else if (res.name == 'TokenExpiredError') {
        setpwChangedTitle('Lo sentimos, llegas tarde')
        setpwChangedMessage('¡El enlace ha expirado! Los links de cambio de contraseña expiran en 15 minutos. Inténtalo de nuevo.')
        setPassword('');
        setRePassword('')
      }

    } catch (e) {
      setpwChangedTitle('Error inesperado')
      setpwChangedMessage("Se ha producido un error. Por favor, inténtalo de nuevo más tarde");
    } finally {
      modalRef.current.show()
    }
  }
  return (
    <Background style={{ alignItems: "center" }}>
      <View style={{ maxWidth: 600, width: '100%', flex: 1, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15 }}>
        <View style={{ flex: 3, flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>
        <View style={{ flex: 7, flexDirection: 'column' }}>
          <NtyTextInput
            placeholder="Contraseña"
            returnKeyType="done"
            onChangeText={(text: string) => setPassword(text)}
            secureTextEntry
            value={password}
            onSubmitEditing={Keyboard.dismiss}

          />
          <NtyTextInput
            placeholder="Repetir contraseña"
            returnKeyType="done"
            onChangeText={(text: string) => setRePassword(text)}
            secureTextEntry
            value={rePassword}
            onSubmitEditing={Keyboard.dismiss}

          />
          <View style={{ flexDirection: "row", marginTop: 40, justifyContent: 'center', width: "70%", alignSelf: "center" }}>
            <ButtonNty onPress={_onChangePasswordPressed}>
              Cambiar contraseña
            </ButtonNty>
          </View>
        </View>
      </View>

      <NtySheetModal ref={modalRef} forceModal={true} dissmisable={false}>
        <View style={{ flexDirection: 'column' }}>
          <View key={"header"} style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 30, marginBottom: 60 }}>
            <TitleNty style={{textAlign: 'center'}}>{pwChangedTitle}</TitleNty>
          </View>

          <View key={"Message"} style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 30, paddingHorizontal: 30 }}>
            <TextNty style={{textAlign: 'center'}}>{pwChangedMessage}</TextNty>
          </View>

          <View key={"Buttons"} style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: 30, marginHorizontal: 10 }}>

            {pwChangedTitle == 'Contraseña cambiada correctamente' ?
              <ButtonNty onPress={() => {navigation.navigate('login'); modalRef.current.hide();}}>Vamos</ButtonNty> : null}

            {pwChangedTitle == 'Lo sentimos, llegas tarde' || pwChangedTitle == 'Error inesperado' ?
              <ButtonNty onPress={() => {navigation.navigate('guest-welcome'); modalRef.current.hide();}}>Reintentar</ButtonNty> : null}

          </View>

        </View>
      </NtySheetModal>

    </Background>
  );
};


const styles = StyleSheet.create({
  entry: {
    marginBottom: 30
  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
});
export default memo(ChangePasswordScreen);
