import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Logo from '../../../../../components/Logo';
import { useAppDataStore } from '../../../../../context/appDataStore';
import { fixed_colors } from '../../../../../core/theme/colors';

import useSessionStore from 'src/context/sessionStore';
import { useAppTheme } from '../../../../../core/theme/ThemeBuilder';
import { getContrastColor } from '../../../../../core/utils';
import TextNty from '../../../../components/nty/text/TextNty';

function WebTopBar() {

    const theme = useAppTheme()
    // const isEnterprise = useAppSessionStore(store => store.isEnterprise)
    const enterprise = useSessionStore(store => store.enterprise)
    const { notifications, seenNotifications } = useAppDataStore((store) => ({ notifications: store.notifications, seenNotifications: store.seenNotifications, clear: store.clearSeenNotifications }));

    const navigation = useNavigation()
    const unseenNotifications = React.useMemo(() => {
        if(!notifications) return null;
        notifications.length - (seenNotifications?.length ?? 0)
    }, [notifications, seenNotifications])

    return (
        <View nativeID='topBar' style={{ flexDirection: 'row', zIndex: 100, justifyContent: 'space-between', /* position: 'fixed' , top: 0, */ paddingVertical: 5, paddingHorizontal: 10, width: "100%", backgroundColor: 'transparent' }}>

            <View nativeID='left' style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

                {unseenNotifications && !enterprise &&
                    <View style={{ position: 'absolute', top: 10, left: 30, width: 12, height: 12, borderRadius: 6, zIndex: 10, backgroundColor: theme.colors.primary[400] }}>
                        <TextNty fontSize={9} type='bold' color={getContrastColor(theme.colors.primary[400])} style={{ textAlign: 'center', textAlignVertical: 'center' }}>{unseenNotifications}</TextNty>
                    </View>}
                <TouchableOpacity
                    onPress={() => {
                        ((navigation as any) as DrawerNavigationProp<{}>).openDrawer();
                    }}>
                    <MaterialCommunityIcons name='menu' size={35} color={theme.colors.text_primary[200]} />
                </TouchableOpacity>



            </View>
            <Logo height={50} />
            <View nativeID='right' style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>


                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('explore');
                    }}>
                    <MaterialCommunityIcons name='magnify' size={30} color={theme.colors.text_primary[200]} />
                </TouchableOpacity>




            </View>

        </View>
    )
}

export default WebTopBar
