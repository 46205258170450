import { StackNavigationProp } from "@react-navigation/stack";
import { Platform } from "react-native";
// import Purchases, { CustomerInfo, LOG_LEVEL, PurchasesOfferings, PurchasesPackage } from "react-native-purchases";
import { buildApiUrl, http_request } from "src/core/http-utils";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";
// import { SessionUser } from "../../models/user";
import ActionApi, { ACTION_NAMES } from "src/api/ActionApi";
import { ActionInterface } from "systemDomain";
// import { useAlert } from "../context/alertContext";

interface Plan {
    id: string;
    amount: number;

    name: string;
    product: {
        name: string;
        description: string;
    };
    package?: /* PurchasesPackage */ any;
}

interface PaymentApi {
    init: (userId?: string) => Promise<void>;
    getPlans: () => Promise<Plan[]>;
    purchase: (navigation: StackNavigationProp<ScreensParamsList>, plan: Plan) => Promise<void>;
    restorePurchases: () => Promise<void | /* CustomerInfo */ any>;
}

const webPaymentApi: PaymentApi = {
    init: async () => { },
    getPlans: async () => {
        const plans = await http_request(buildApiUrl('/v1/payment/init'), 'GET')
        return plans;
    },
    purchase: async (navigation, plan) => {
        // @ts-ignore
        const action: ActionInterface = {
            name: ACTION_NAMES.PURCHASE_CLICK,
            // @ts-ignore
            data: { plan }
        }
        navigation.navigate("payment", { planId: plan.id })
        await ActionApi.createFrom(action)
    },
    restorePurchases: async () => {
        throw new Error("Restoring purchases is not supported in web version");
    }
}

const mobilePaymentApi: PaymentApi = {
    init: async (userId) => {
        // await Purchases.setup("DvAKeddHlKKakVLPBYNSXLwWuoenYyTS", userId);
        // if(__DEV__) Purchases.setLogLevel(LOG_LEVEL.DEBUG)

        // if(await Purchases.isConfigured()) {
        //     console.log("Purchases is already configured")
        //     console.log(await Purchases.getCustomerInfo())
        // } else {
        //     console.log("Purchases is not configured")
        //     await Purchases.configure({apiKey: 'DvAKeddHlKKakVLPBYNSXLwWuoenYyTS', appUserID: userId })
        // }
    },
    getPlans: async () => {
        // const offerings: PurchasesOfferings = await Purchases.getOfferings();
        // //convert offerings to stripe plans, so we have a unified domain object to transfer this information
        // const plans: Plan[] = offerings.all.default.availablePackages.map((pkg) => {
        //     return {
        //         id: pkg.identifier,
        //         amount: pkg.product.price * 100, //stripe returns amounts in cents
        //         name: pkg.product.title,
        //         product: {
        //             name: pkg.product.title,
        //             description: pkg.product.description
        //         },
        //         package: pkg
        //     }
        // });
        // return plans;
        console.log("getPlans not implemented on mobile")
        return [];
    },
    purchase: async (navigation, plan) => {
        // try {
        //     const { customerInfo, productIdentifier } = await Purchases.purchasePackage(plan.package);
        //     const action: ActionInterface = {
        //         name: ACTION_NAMES.PURCHASE_CLICK,
        //         // @ts-ignore
        //         data: {
        //             productIdentifier: productIdentifier,
        //             customerInfo: customerInfo,
        //             plan
        //         }
        //     }
        //     navigation.navigate("success");
        //     await ActionApi.createFrom(action)
        // } catch (e) {
        //     if (!e.userCancelled) {
        //         navigation.navigate("cancel");
        //     }
        // }
        console.log("purchase not implemented on mobile")
    },
    restorePurchases: async () => {

        // try {
        //     const purchaserInfo = await Purchases.restorePurchases()
        //     return purchaserInfo

        // }catch(rejection) {
        //     console.error("Error restoring purchases", rejection)
        //     useAlert.getState().alert({ title: "Hubo un problema restaurando tus compras.", message: '¿Seguro que estás logueado con la misma cuenta que usaste para comprar?' });
        // }

        console.log("restorePurchases not implemented on mobile")


    }
}

const apiImpl: PaymentApi = Platform.OS != 'ios' ? webPaymentApi : mobilePaymentApi;

export default apiImpl;
