import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { ScrollView, View } from 'react-native'
import { IconButton, Title } from 'react-native-paper'
import { ACTION_NAMES } from '../../../api/ActionApi'
import { useAppTheme } from '../../../core/theme/ThemeBuilder'

import useSessionStore from 'src/context/sessionStore'
import { resetStackNavigator } from '../../../core/utils'
import Background from '../../Background'
import ActionTypeCard from '../../components/action/ActionTypeView'

export default function UsageStatsPage() {


    const navigation = useNavigation();
    const {profile} = useSessionStore((store)=>({profile: store.profile}))

    const theme = useAppTheme()

    React.useEffect(()=>{
        if(!profile.superUser || profile.superUser != true) {
            resetStackNavigator('home', navigation);
        }
    })

    return (
        <Background safeInsets>
            <ScrollView contentContainerStyle={{paddingHorizontal: 10}}>
                <View style={{flexDirection: 'row', alignItems: 'center', marginVertical: 20}}>
                    <IconButton icon={'arrow-left'} size={35} color={theme.colors.text_primary[500]} onPress={()=>resetStackNavigator('home', navigation)}/>
                    <Title style={{fontSize: 28}}>Estadísticas Internas:</Title>
                </View>
                {Object.values(ACTION_NAMES).map((actionName) => {
                   return <ActionTypeCard actionName={actionName} key={actionName+'_Card'}></ActionTypeCard>
                })}

            </ScrollView>
        </Background>
    )
}
