import React from 'react'
import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'
import HighlightOptionsList from '../../../components/highlights/options/HighlightOptionsList'
import { useHighlightOptionsStore } from '../../optionStores'

function HighlightOptionsModal() {

    const { modalVisible, setModalVisible } = useHighlightOptionsStore()

    const modalRef = React.useRef<NewsToYouSheetModalHandle>()

    const visibleRef = React.useRef(false)

    const hideModal = () => {

        visibleRef.current = false;
        modalRef.current.hide()
        setModalVisible(false)

    }
    const showModal = () => {

        visibleRef.current = true;
        modalRef.current.show()
        setModalVisible(true)

    }

    React.useEffect(() => {
        // console.log({visible, visibleRef: visibleRef.current})
        if (modalVisible == true && visibleRef.current == false) {
            console.log('showing')
            showModal()
        } else if(modalVisible == false && visibleRef.current == true){
            console.log('closing')
            hideModal()
        }
    }, [modalVisible])

    return (
        <NtySheetModal ref={modalRef} scrollableContent={true} onClose={()=>hideModal()}>
            <HighlightOptionsList
                hide={() => hideModal()}
            />
        </NtySheetModal>
    )
}

export default HighlightOptionsModal
