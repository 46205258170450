import { Picker } from '@react-native-picker/picker';
import React, { useEffect, useState } from 'react';
import EnterpriseApi from 'src/api/EnterpriseApi';
import { NtyPicker } from 'src/components/common-ui/picker/NtyPicker';
import { EnterpriseFeedInterface, EnterpriseFeedV2, EnterpriseInterface } from 'systemDomain';

export type FeedSelectorProps = {
    /**
     * Enterprise object or enterprise key
     */
    enterprise?: EnterpriseInterface | string,
    onEnterpriseChange?: (itemValue: EnterpriseInterface) => void,
    onFeedChange?: (itemValue: EnterpriseFeedInterface | EnterpriseFeedV2) => void,
}

function FeedSelector(props: FeedSelectorProps) {
    const [loadingEnterprises, setLoadingEnterprises] = useState<boolean>(false);
    const [enterprises, setEnterprises] = useState<EnterpriseInterface[]>([]);
    const [loadingFeeds, setLoadingFeeds] = useState<boolean>(false);
    const [feeds, setFeeds] = useState<(EnterpriseFeedV2)[]>([]);
    const [selectedEnterpriseKey, setSelectedEnterpriseKey] = useState<string>(typeof props.enterprise == 'object' ? props.enterprise.key : props.enterprise);
    const [selectedFeedId, setSelectedFeedId] = useState<string>();


    const _selectEnterprise = (enterprise: EnterpriseInterface) => {
        setSelectedEnterpriseKey(enterprise.key);
        props.onEnterpriseChange && props.onEnterpriseChange(enterprise);
    }

    const _selectFeed = (feed: EnterpriseFeedV2) => {
        setSelectedFeedId(feed._id);
        props.onFeedChange && props.onFeedChange(feed);
    }

    useEffect(() => {

        if (!props.enterprise) {
            setLoadingEnterprises(true);
            // fetch enterprises
            EnterpriseApi.getEnterprises(0, -1).then((res) => {
                setEnterprises(res);
                setLoadingEnterprises(false);
            });
        }

    }, []);

    useEffect(() => {
        if (selectedEnterpriseKey) {
            setLoadingFeeds(true);
            // fetch feeds
            EnterpriseApi.getEnterpriseFeedsByKey(selectedEnterpriseKey, 0, 100).then((res) => {
                setFeeds(res);
                props.onFeedChange?.(res[0])
                setLoadingFeeds(false);
            }
            );
        }
    }, [selectedEnterpriseKey]);

    return (
        <>
            {
                !props.enterprise &&
                // <View style={{ flexDirection: 'row', alignItems: 'center', height: 140 }}>
                //     <TextNty fontSize={19} style={{ marginRight: 10 }}>Empresa:</TextNty>
                //     <Picker
                //         style={{ flex: 1 }}
                //         selectedValue={selectedEnterprise}
                //         onValueChange={_selectEnterprise}

                //     >
                //         {enterprises.map(enterprise => (
                //             <Picker.Item label={enterprise.name} value={enterprise._id} key={enterprise._id} />
                //         ))}
                //     </Picker>
                //     <ActivityIndicator animating={loadingEnterprises} />
                // </View>
                <NtyPicker
                    pickerTitle='Empresa:'
                    selectedValue={selectedEnterpriseKey}
                    onValueChange={(value, index) => { console.log('valueChanged', value); _selectEnterprise(enterprises[index]) }}
                    loading={loadingEnterprises}
                >
                    {enterprises.map(enterprise => (
                        <Picker.Item label={enterprise.name} value={enterprise.key} key={enterprise._id} />
                    ))}
                </NtyPicker>
            }

            {/* <View style={{ flexDirection: 'row', alignItems: 'center', height: 140 }}>
                <TextNty fontSize={19} style={{ marginRight: 10 }}>Feed:</TextNty>
                <Picker
                    style={{ flex: 1 }}
                    selectedValue={selectedFeed}
                    onValueChange={_selectFeed}
                >
                    {feeds.map(feed => (
                        <Picker.Item label={feed.name} value={feed._id} key={feed._id} />
                    ))}
                </Picker>
                <ActivityIndicator animating={loadingFeeds} />
            </View> */}

            <NtyPicker

                pickerTitle='Feed:'
                selectedValue={selectedFeedId}
                onValueChange={(feed, index) => _selectFeed(feeds[index])}
                loading={loadingFeeds}
            >

                {feeds.map(feed => (
                    <Picker.Item label={feed.name} value={feed._id} key={feed._id} />
                ))}

            </NtyPicker>
        </>
    );
}

export default FeedSelector
