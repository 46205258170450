import React from 'react'
import { PublisherApi } from 'src/api/PublisherApi'
import { ApplicablePublisher, ApplicablePublisherSearchResponse, Publisher } from 'systemDomain'
import PublisherList from './lists/PublisherList'
import { ScrollView, Pressable, View, Platform } from 'react-native'
import { IconButton } from 'react-native-paper'
import TextNty from '../nty/text/TextNty'
import { SearchApi } from 'src/api/SearchApi'
import { useSearchStore } from 'src/context/appSearchStore'
import PublisherBubble from './PublisherBubble'
import { useAppTheme } from 'src/core/theme/ThemeBuilder'
import TitleHeaderNty from '../nty/header/TitleHeaderNty'
import NtySearchBar from '../nty/searchbar/NtySearchBar'
import useDebounce from 'src/core/hooks/useDebounce'
import ButtonNty from '../nty/button/ButtonNty'
import { fixed_colors } from 'src/core/theme/colors'
import Loading from 'src/components/Loading'
import LoadingIndicator from '../loaders/Loading'
import { BottomSheetScrollView } from '@gorhom/bottom-sheet'


const PublisherItem = React.memo(({ publisher, isSelected, publisherInfo, onToggle }: any) => {
    const theme = useAppTheme();

    return (
        <Pressable
            style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1, width: "100%" }}
            onPress={onToggle}>
            <View>

                {publisherInfo ?
                    <View style={{ zIndex: 1000, position: 'absolute', top: -15, right: -25, justifyContent: 'center', alignItems: 'center', borderRadius: 15, paddingVertical: 5, paddingHorizontal: 8, backgroundColor: theme.colors.primary[500] }}>
                        <TextNty color={theme.colors.text_secondary[500]} fontSize={15}>{publisherInfo?.articleCount}</TextNty>
                    </View> : null}
                {isSelected ?
                    <View style={{ zIndex: 1000, position: 'absolute', top: -15, left: -5, justifyContent: 'center', alignItems: 'center', borderRadius: 10, width: 20, height: 20, padding: 3, backgroundColor: fixed_colors.green[600] }}>
                        <TextNty type='bold' color={fixed_colors.white[300]} fontSize={15}>✓</TextNty>
                    </View> : null}


                <PublisherBubble
                    publisher={publisher}
                    showLabel={true}
                    labelPosition='bottom'
                    labelStyle={{ fontSize: 13, lineHeight: 15 }}
                    fontSize={15}
                    disabled={true}
                />
            </View>
        </Pressable>
    );
});

const ScrollComponent = Platform.OS == 'web' ? ScrollView : BottomSheetScrollView

function PublishersSelector(props: { onDone: (keyNames: string[]) => void, onCancel: () => void }) {

    const search = useSearchStore(state => state.currentSearch);

    const theme = useAppTheme();
    const [loading, setLoading] = React.useState(false);
    const [selectedPublishersKeyNames, setSelectedPublishers] = React.useState<string[]>([]);
    const [searchText, setSearchText] = React.useState('');
    const debouncedText = useDebounce(searchText, 500);
    const [publishers, setPublishers] = React.useState(null);

    const fetchPublishers = async () => {
        setLoading(true);
        const brands = await useSearchStore.getState().getApplicablePublishers(null, debouncedText);
        setPublishers(brands);
        setLoading(false);
    };

    React.useEffect(() => {
        fetchPublishers();
    }, [debouncedText]);

    const togglePublisher = (keyName) => {
        setSelectedPublishers(prev => prev.includes(keyName) ?
            prev.filter(p => p !== keyName) :
            [...prev, keyName]
        );
    };

    const renderBubble = React.useCallback((publisher) => {
        const isSelected = selectedPublishersKeyNames.includes(publisher.keyName);
        const publisherInfo = publishers?.results.find(p => p.publisher.keyName === publisher.keyName);

        return <PublisherItem publisher={publisher} isSelected={isSelected} publisherInfo={publisherInfo} onToggle={() => togglePublisher(publisher.keyName)} />;
    }, [selectedPublishersKeyNames, publishers])




    return (
        <React.Fragment>
            {loading && <View style={{ justifyContent: 'center', width: "100%", position: 'absolute', top: "30%" }}>
                <LoadingIndicator />
            </View>
            }
            <View style={{ paddingHorizontal: '5%' }}>
                <TitleHeaderNty title='Filtro de Medios' onBackIconPressed={props.onCancel} />
                <NtySearchBar
                    placeholder='Buscar Medio'
                    style={{
                        flex: 1,
                        backgroundColor: theme.colors.background[300],
                        borderWidth: 1,
                        borderColor: theme.colors.background[700],
                        borderRadius: theme.dimensions.roundness.low
                    }}
                    value={searchText}
                    onChangeText={setSearchText}
                />
            </View>
            <ScrollComponent style={{ flex: 4 }} contentContainerStyle={{paddingTop: 10, paddingBottom: 80}}>
                <PublisherList
                    ListHeaderComponent={publishers ?
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 10, paddingBottom: 20 }}>
                            <TextNty>{publishers?.results.length} publicadores diferentes para tu búsqueda</TextNty>
                        </View> : null}
                    renderBubble={renderBubble}
                    publishers={publishers?.results?.map(r => r.publisher)}
                    // contentContainerStyle={{ paddingBottom: 200 }}
                />
            </ScrollComponent>

                <ButtonNty mode='floatingBottom' onPress={() => props.onDone(selectedPublishersKeyNames)}>Listo</ButtonNty>

        </React.Fragment>
    )
}

export default PublishersSelector
