import { BottomSheetTextInput } from '@gorhom/bottom-sheet'
import React, { MutableRefObject } from 'react'
import { Animated, ColorValue, NativeSyntheticEvent, Platform, StyleSheet, TextInput, TextInputChangeEventData, TextInputEndEditingEventData, TextInputFocusEventData, TextInputProps, TouchableOpacity, View, ViewStyle } from 'react-native'

import { IconButton } from 'react-native-paper'
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon'
import { useAppTheme } from '../../../../core/theme/ThemeBuilder'




interface NtyInputRoundExtraProps {

    /** Style that would be placed inside the wrapper of this custom InputText component. Use this for paddings and margins */
    contentContainerStyle?: ViewStyle,
    /** RGBA string color to be used as the tintColor when this input field is focused. __Override this animation with `placeHolderTextColor` property for a fixed color.__ */
    placeHolderTextColorActive?: string
    /** RGBA string color to be used as the tintColor when this field is focused. __Override this animation with `placeHolderTextColor` property for a fixed color.__*/
    placeHolderTextColorInactive?: string,

    /** Color to be used for the bottom line of the inputText, by default, the color should animate to be the same as the placeHolder depending on input focus state. Override the default behaviour
     * with this property, but the bottom line color will be fixed then.
      */
    borderBottomColor?: ColorValue,

    /**
     * If you pass in this prop, youll get an icon at the right end of the text input, the passed value will be used directly to the icon 'name' prop.
     */
    icon?: IconSource,
    /**
     * Function to be executed each time the icon box for this particular textInput gets clicked. You'll need to pass the 'icon' prop to this textinput to make this work.
     */
    onIconClick?: () => void,

    /**Whether or not to use a {BottomSheetTextInput} in this particular input instance */
    bottomSheetInput?: boolean
}

export type NtyInputRoundProps = TextInputProps & NtyInputRoundExtraProps

const NtyTextInputRound = React.forwardRef<TextInput, NtyInputRoundProps>((props: NtyInputRoundProps, ref) => {


    const theme = useAppTheme()
    const fontSize = props?.style?.fontSize ?? 20
    const translateYInitialValue = 0;
    const translateYFinalValue = -(fontSize * 2.2);


    const translateYAnimation = React.useRef(new Animated.Value(translateYInitialValue)).current;

    const inputRef = ref ?? React.useRef<TextInput>(null);
    const inputTextSize = React.useRef(props.defaultValue ? props.defaultValue.length : props.value ? props.value.length : 0);

    const defaultInactiveColor = theme.colors.text_primary.muted
    const defaultActiveColor = theme.colors.text_primary[400]

    const [finalColor, initialColor] = [props.placeHolderTextColorActive ? props.placeHolderTextColorActive : defaultActiveColor, props.placeHolderTextColorInactive ? props.placeHolderTextColorInactive : defaultInactiveColor];


    const colorAnimation = translateYAnimation.interpolate({
        inputRange: [translateYFinalValue, translateYInitialValue],
        outputRange: [finalColor, initialColor]
    })

    const fontSizeAnimation = translateYAnimation.interpolate({
        inputRange: [translateYFinalValue, translateYInitialValue],
        outputRange: [fontSize - (fontSize / 5), fontSize + (fontSize / 58)]
    })




    function onChangeTextInternal(text: string) {

        const hasText = text ? text.length > 0 : false;

        if (text)
            inputTextSize.current = text.length

        Animated.timing(
            translateYAnimation,
            {
                toValue: hasText ? translateYFinalValue : translateYInitialValue,
                duration: 100,
                useNativeDriver: false
            }
        ).start()

    }

    function onChangeTextInternalEvent(event: NativeSyntheticEvent<TextInputChangeEventData>) {

        const hasText = event.nativeEvent && event.nativeEvent.text ? event.nativeEvent.text : false;

        if (hasText)
            inputTextSize.current = hasText.length

        Animated.timing(
            translateYAnimation,
            {
                toValue: hasText ? translateYFinalValue : translateYInitialValue,
                duration: 100,
                useNativeDriver: false
            }
        ).start()

    }


    function onFocusInternal(event: NativeSyntheticEvent<TextInputEndEditingEventData>) {

        const hasText = event.nativeEvent.text ? event.nativeEvent.text.length > 0 : false;
        if (!hasText) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYFinalValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }
    }


    function onEndEditingInternal(event: NativeSyntheticEvent<TextInputEndEditingEventData>) {

        const hasText = event.nativeEvent.text ? event.nativeEvent.text.length > 0 : false;
        if (!hasText) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYInitialValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }
    }

    function onBlurInternal(event: NativeSyntheticEvent<TextInputFocusEventData>) {

        const textSize = inputTextSize.current
        if (textSize == 0) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYInitialValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }
    }

    React.useEffect(() => {

        const text = props.value ? props.value : props.defaultValue ? props.defaultValue : false;

        if (text) {
            Animated.timing(
                translateYAnimation,
                {
                    toValue: translateYFinalValue,
                    duration: 100,
                    useNativeDriver: false
                }
            ).start()
        }

    }, [inputRef, inputRef.current, props.value])

    const borderBox = { padding: 10, width: "100%", borderColor: props.borderBottomColor ?? colorAnimation, borderWidth: 1.5, borderRadius: 15, flexDirection: 'row' };

    const defaultStyle = StyleSheet.create({
        container: {
            flexDirection: 'row'
        },
        boxStyle: {
            marginVertical: 22,
            justifyContent: 'center',
            flex: 1
        },
        textStyle: {
            alignSelf: 'center',
            color: theme.colors.text_primary[500],
            fontSize,
            lineHeight: fontSize + 4,
            paddingLeft: 5,
            // borderWidth: 1,
            flex: 1
        },
        placeHolderText: {
            fontWeight: '500',
            // backgroundColor: theme.colors.background,
            // borderWidth: 1,
            padding: 5,
            position: 'absolute',
            left: 5,
            zIndex: 10
        }
    })



    return (
        <View style={defaultStyle.container}>

            <TouchableOpacity style={props.contentContainerStyle ?? defaultStyle.boxStyle} onPress={() => inputRef.current.focus()} activeOpacity={1}>

                {props.placeholder && <Animated.Text
                    style={[
                        {
                            transform: [{ translateY: translateYAnimation }],
                            fontSize: fontSizeAnimation,
                            color: props.placeholderTextColor ?? colorAnimation
                        },
                        defaultStyle.placeHolderText
                    ]}>{props.placeholder}</Animated.Text>}
                <Animated.View style={borderBox} >
                    {props.bottomSheetInput && (Platform.OS == 'ios' || Platform.OS == 'android') ?
                        <BottomSheetTextInput
                            ref={inputRef}
                            {...props}
                            placeholder={null}
                            onChange={(text) => { onChangeTextInternalEvent(text); props.onChange ? props.onChange(text) : null }}
                            style={[props.style, defaultStyle.textStyle]}
                            selectionColor={props.selectionColor ?? defaultStyle.textStyle.color}
                            onChangeText={(text) => { onChangeTextInternal(text); props.onChangeText ? props.onChangeText(text) : null }}
                            onFocus={(event) => { onFocusInternal(event); props.onFocus ? props.onFocus(event) : null }}
                            onBlur={(event) => { onBlurInternal(event); props.onBlur ? props.onBlur(event) : null }}
                            onEndEditing={(event) => { onEndEditingInternal(event); props.onEndEditing ? props.onEndEditing(event) : null }}
                        /> :
                        <TextInput
                            ref={inputRef}
                            {...props}
                            placeholder={null}
                            onChange={(text) => { onChangeTextInternalEvent(text); props.onChange ? props.onChange(text) : null }}
                            style={[props.style, defaultStyle.textStyle]}
                            selectionColor={props.selectionColor ?? defaultStyle.textStyle.color}
                            onChangeText={(text) => { onChangeTextInternal(text); props.onChangeText ? props.onChangeText(text) : null }}
                            onFocus={(event) => { onFocusInternal(event); props.onFocus ? props.onFocus(event) : null }}
                            onBlur={(event) => { onBlurInternal(event); props.onBlur ? props.onBlur(event) : null }}
                            onEndEditing={(event) => { onEndEditingInternal(event); props.onEndEditing ? props.onEndEditing(event) : null }}
                        />
                    }
                    {props.icon ? <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton icon={props.icon} size={18} style={{ padding: 0, margin: 0 }} color={props.style && props.style.color ? props.style.color : defaultStyle.textStyle.color} onPress={props.onIconClick} />
                    </View> : null}

                </Animated.View>
            </TouchableOpacity>


        </View>
    )
})



export default NtyTextInputRound;
