import React, { useRef } from 'react'
import { Platform, View } from 'react-native';
import { CSSProperties } from 'react-native-render-html';
import WebView, { WebViewProps } from 'react-native-webview'

export type MultiPlatformWebviewProps = WebViewProps & { iframeStyle?: CSSProperties, iframeSource?: string };

function MultiPlatformWebview(props: MultiPlatformWebviewProps) {
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      // Aquí es donde puedes inyectar tu código JavaScript.
      // Por ejemplo:
      iframe.contentWindow.postMessage(props.injectedJavaScript, '*');
    }
  }

  return (
    Platform.OS == 'web' ?
      <View style={[{ flex: 1 }, props.style]}>
        <iframe
          ref={iframeRef}
          onLoad={handleIframeLoad}
          style={props.iframeStyle}
          src={props.iframeSource ?? (props.source?.uri ? props.source.uri : props.source)}
          id={'iframe_' + Date.now() + '_' + (Math.random() * 10)} name={'iframe_' + Date.now() + '_' + (Math.random() * 10)}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </View>
      :
      <WebView {...props} />
  )
}

export default MultiPlatformWebview
