import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import React, { memo, useState } from 'react';
import { ActivityIndicator, Animated, Easing, Image, Platform, TextInput, View, useWindowDimensions } from 'react-native';
import NtyTextInputRound from '../app/components/nty/TextInput/TextInputNtyRound';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import TextNty from '../app/components/nty/text/TextNty';
import { useAlert } from '../app/context/alertContext';
import { useFavouritesStore } from '../app/context/favoritesStore';
import Logo from '../components/Logo';

import TitleNty from 'src/app/components/nty/title/TitleNty';
import useSessionStore from 'src/context/sessionStore';
import { hexTransparency } from 'src/core/jsutils';
import { fixed_colors } from 'src/core/theme/colors';
import { LoginErrors } from '../context/appSessionStore';
import { ScreensParamsList } from '../core/navigation/screens/NavigationScreens';
import { getAppTheme } from '../core/theme/ThemeBuilder';



const theme = getAppTheme()


type Props = StackScreenProps<ScreensParamsList, 'login'>;

const LoginScreen = (props: Props) => {

  // const device = useDeviceInfoStore()

  const navigation = useNavigation<StackNavigationProp<ScreensParamsList, 'login'>>()

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const { loginUserPassword } = useAppSessionStore.getState()
  // const initSubscriptions = useUserSubscriptionsStore.getState().init

  const alert = useAlert.getState().alert


  const _onRegisterPressed = () => {
    navigation.navigate('register', { email: email })
  }

  const _login = async () => {

    setLoading(true);
    useSessionStore.getState().loginUserPassword(email.trim(), password.trim()).then(async (result) => {
      console.log('Done Login');

      try {
        navigation.reset({ routes: [{ name: 'home' }] })
      } catch (e) {
        navigation.navigate('home')
      }
    })
      .catch((e: Error) => {
        setLoading(false);
        switch (e.message) {
          case LoginErrors.EMAIL_DOESNT_EXISTS:
            alert({ title: 'No te hemos encontrado...', message: 'El usuario ' + email + ' no existe en nuestro sistema.' })
            // loginError('El usuario ' + email + ' no existe en nuestro sistema');
            return;
          case LoginErrors.USER_PASS_WRONG:
            alert({ title: 'Datos Incorrectos', message: 'Combinación usuario/contraseña incorrecta' })
            // loginError('Combinación usuario/contraseña incorrecta');
            return;
          case 'Failed to fetch':
            alert({ title: 'Problemas de conexión', message: 'Hubo un problema contactando con el servidor, comprueba tu conexión a internet o inténtalo más tarde' })
            // loginError('Hubo un problema contactando con el servidor, comprueba tu conexión a internet o inténtalo más tarde')
            return;
        }
      })
  }

  const logoYInitial = -30;
  const logoYFinal = 180;
  const logoY = new Animated.Value(logoYInitial);
  const logoOpacityAnim = logoY.interpolate({
    inputRange: [logoYInitial, logoYFinal],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    // ((navigation as any) as DrawerNavigationProp<{}>).closeDrawer();

    Animated.timing(logoY,
      {
        toValue: logoYFinal,
        duration: 1300,
        useNativeDriver: true,
        easing: Easing.out(Easing.quad)
      }).start();

  }, []);

  const passInput = React.useRef<TextInput>(null);
  const [showingPassword, setShowingPassword] = React.useState<boolean>(false);
  const width = useWindowDimensions().width

  const web = Platform.OS == 'web' && width > 1100

  return (
    // <Background safeInsets>
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <Image style={{ position: 'absolute', width: "100%", height: "100%", resizeMode: 'cover' }} source={require('src/assets/designs/login/login_background.jpg')} />

      {
        web && <View style={{ flex: 2.5 }}>
          <View style={[{ width: "100%", height: "100%", position: 'absolute', backgroundColor: hexTransparency(fixed_colors.black[500], 40) }]} />

          <View style={{ flex: 1 }} >
            <TitleNty style={{ zIndex: 100, position: 'absolute', top: "10%", left: "10%" }} fontSize={80} color={fixed_colors.white[500]}>{'Explora.\r\nDescubre.\r\nInforma.'}</TitleNty>
          </View>


        </View>
      }

      <View
        style={{ flex: 1, flexDirection: "column", justifyContent: 'center', padding: 15, backgroundColor: Platform.OS == 'web' ? hexTransparency(fixed_colors.white[500], 97) : fixed_colors.white[200] }}>
        <Animated.View style={{ height: 90, marginBottom: 45, alignItems: 'center' }}>
          <Logo />
        </Animated.View>
        {
          loading ?
            <View style={{
              flex: 1,
              justifyContent: 'center',
              alignContent: 'center',
              alignSelf: 'center',
              alignItems: 'center'
            }}>
              <ActivityIndicator size="large" animating={true} color={theme.colors.primary[500]} style={{ flex: 1 }} />
            </View> :

            <View>

              <NtyTextInputRound
                nativeID='emailNty'
                placeholder="Email"
                returnKeyType="next"
                autoCapitalize="none"
                autoComplete="email"
                textContentType='emailAddress'
                keyboardType='email-address'
                importantForAutofill='yes'
                onSubmitEditing={() => { passInput.current?.focus() }}
                onChangeText={(text: string) => setEmail(text)}
              />

              <NtyTextInputRound
                nativeID='passwordnty'
                ref={passInput}
                textContentType='password'
                keyboardType='default'
                placeholder="Contraseña"
                returnKeyType="done"
                autoCapitalize="none"
                autoComplete="password"
                importantForAutofill='yes'
                onSubmitEditing={_login}
                secureTextEntry={!showingPassword}
                onChangeText={(text: string) => setPassword(text)}
                icon={showingPassword ? 'eye-off' : 'eye'}
                onIconClick={() => { setShowingPassword(!showingPassword) }}
              />
              <TextNty
                onPress={() => { navigation.navigate('forgotpassword') }}
                type='semiBold'
                style={{ marginBottom: 10, marginTop: 5, textAlign: 'center' }}>¿Has olvidado tu contraseña?</TextNty>
              {/* <TextInput
              label="Email"
              returnKeyType="next"
              onChangeText={(text: string) => setEmail(text)}
              autoCapitalize="none"
              autoCompleteType="email"
              textContentType="emailAddress"
              keyboardType="email-address"
              style={styles.entry}
            />
            <TextInput
              label="Contraseña"
              returnKeyType="next"
              onChangeText={(text: string) => setPassword(text)}
              secureTextEntry
              style={styles.entry}
            /> */}
              {/* <Text style={{ textAlign: 'right', fontFamily: theme.fonts.medium.fontFamily, color: theme.colors.primary, marginTop: 25}}>
              <Link to='/forgotpassword'>
              ¿Has olvidado tu contraseña?
              </Link>
            </Text> */}



              <ButtonNty color={theme.colors.primary[500]} onPress={_login} mergeStyles={{ marginVertical: 40 }}>Entrar</ButtonNty>

              {/* <TextNty type='extraLight' style={{ marginBottom: 15, textAlign: 'center' }}>¿Aún no estás registrado?</TextNty>

                <ButtonNty color={theme.colors.secondary[700]} mergeStyles={{ marginBottom: 15 }} onPress={_onRegisterPressed}>Registrar</ButtonNty> */}

              {/* <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center", marginTop: 20}}>
              <Button labelStyle={{ color: theme.colors.background, fontWeight: '700', textTransform: 'none' }} style={[styles.buttonContained]} onPress={_onLoginPressed}>
              Entrar
              </Button>
              </View>
              <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center", marginTop: 50}}>
              <Text style={{ color: theme.colors.primary, fontFamily: theme.fonts.medium.fontFamily, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', flexDirection: 'row' }}>
                ¿Aún no estás registrado?
                </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center"}}>
                <Button mode={'outlined'} labelStyle={{ color: theme.colors.primary, fontWeight: '700', textTransform: 'none' }} style={styles.buttonOutlined} onPress={_onRegisterPressed}>
                Registrar
                </Button>
              </View> */}
            </View>
        }
      </View>

      <View style={{position: 'absolute', padding: 15, bottom: 10}}>

        <TextNty type='bold' color={web ? 'white' : 'black' }>
          Original News To You SL
        </TextNty>

      </View>

    </View>
    // </Background>
  );
};



export default memo(LoginScreen);


