import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import React from "react"
import { ScreensParamsList } from "../../../../../../core/navigation/screens/NavigationScreens"

export const useSectionNav = (goTo:any) => {
    const nav = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>()

    const goToFunc = React.useCallback(() => {
        if (!goTo) return null
        const screen = goTo.screen
        const params = goTo.params

        if (screen)
            nav.push(screen as keyof ScreensParamsList, params)

    }, [goTo])

    return {goToFunc}
}
