import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { memo } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Divider } from 'react-native-paper';
import { Article } from 'systemDomain';
import { pdb } from '../../../../../environments';
import ArticleApi from '../../../../api/ArticleApi';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';

import useSessionStore from 'src/context/sessionStore';
import { openInBrowser } from '../../../../core/utils';
import { Navigation } from '../../../../types';
import { useAlert } from '../../../context/alertContext';
import { useArticleOptionsStore } from '../../../context/optionStores';
import TextNty from '../../nty/text/TextNty';
import TitleNty from '../../nty/title/TitleNty';



export type ArticleSuperUserOptionsProps = {
    /**
     * Optional.
     *
     * Use this parameter to make this instance of ArticleOptionsList work for a specific article object.
     * If not passed, the article in {@link useArticleOptionsStore} would be used.
     */
    article?: Article;
    hide?: () => void,
    show?: () => void,
    navigation?: Navigation
}

/**
 *
 * This component is used to show common actions associated with an article that are supposed to be accesed only for superUsers
 *
 *
 * - Categorize article
 * - Open in DB
 * - Highlight article
 *
 */
const ArticleSuperUserOptionsList = ({ navigation, show, hide, article: articleParam = null }: ArticleSuperUserOptionsProps) => {

    if (navigation == undefined) {
        navigation = useNavigation();
    }

    const theme = useAppTheme();
    const { alert, hide: hideAlert } = useAlert.getState()
    const profile = useSessionStore((store) => store.profile);
    const article = articleParam ? articleParam : useArticleOptionsStore((store) => store.article);


    const deleteArticle = () => {

        alert({
            title: 'Borrar Artículo',
            message: <>
                <TitleNty color={theme.colors.danger[600]} style={{ marginBottom: 10 }}>Cuidado</TitleNty>
                <TextNty style={{ marginBottom: 10 }}>Vas a borrar: <TextNty type='bold'>{article.title}</TextNty></TextNty>
                <TextNty>Borrar un artículo supone marcarlo como <TextNty type='bold'>visible: false</TextNty> en BBDD. Haciendo que desaparezca de los listados recientes, pero no de las búsquedas y listados específicos.</TextNty>
            </>,
            buttons: [
                {
                    label: 'OK', onPress: async () => {
                        hideAlert()
                        await ArticleApi.update(article._id, { visible: false })
                        alert({ title: 'Completado', message: 'Artículo actualizado correctamente.' })
                    }
                }
            ]
        })

    }

    if (!article) return <View style={{ justifyContent: 'center' }}><TitleNty style={{ alignSelf: 'center' }}>No hay ninguna articulo seleccionada</TitleNty></View>
    const isTempArticle = article?._id.indexOf('temp_article') != -1

    return (
        <>
            <View style={{ flex: 1, paddingHorizontal: 40, paddingTop: 15, paddingBottom: 30 }}>

                {isTempArticle ? <View style={{ justifyContent: 'center', marginVertical: 15 }}>
                    <TitleNty style={{ fontSize: 15 }}>!Este artículo no existe en BBDD!</TitleNty>
                </View> : null}
                {!isTempArticle && profile?.superUser &&
                    <>
                        <TouchableOpacity style={styles.itemContainer} onPress={() => { navigation.navigate('createHighlightFrom', { id: article._id }); hide() }}>
                            <MaterialCommunityIcons
                                name="star-circle"
                                color={theme.colors.primary[400]}
                                size={25}
                            />
                            <TextNty style={styles.text}>Crear Destacado</TextNty>
                        </TouchableOpacity>

                        {!isTempArticle &&
                            <>
                                <Divider style={styles.divider} />
                                <TouchableOpacity style={styles.itemContainer} onPress={() => { openInBrowser(pdb + '/db/db/article/' + '"' + article?._id + '"') }}>
                                    <MaterialCommunityIcons
                                        name="database-search"
                                        color={theme.colors.text_primary[500]}
                                        size={25}
                                    />
                                    <TextNty style={styles.text}>Abrir en BBDD</TextNty>

                                </TouchableOpacity>
                            </>}

                        {!isTempArticle &&
                            <>
                                <Divider style={styles.divider} />
                                <TouchableOpacity style={styles.itemContainer} onPress={() => { deleteArticle() }}>

                                    <MaterialCommunityIcons
                                        name="delete"
                                        color={theme.colors.danger[500]}
                                        size={25}
                                    />

                                    <TextNty style={styles.text}>Borrar</TextNty>

                                </TouchableOpacity>
                            </>}
                    </>
                }
            </View>
        </>
    );
}


const styles = StyleSheet.create({
    text: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
        marginLeft: 25
    },
    itemContainer: {
        maxHeight: 30,
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center'
    },
    divider: {
        marginBottom: 20
    }
})

export default memo(ArticleSuperUserOptionsList);


