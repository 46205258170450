import { StackScreenProps } from '@react-navigation/stack';
import React, { memo, useState } from 'react';
import { ActivityIndicator, TextInput, View } from 'react-native';
import Background from '../app/Background';
import NtyTextInputRound from '../app/components/nty/TextInput/TextInputNtyRound';
import ButtonNty from '../app/components/nty/button/ButtonNty';
import TextNty from '../app/components/nty/text/TextNty';
import { useAlert } from '../app/context/alertContext';
import Logo from '../components/Logo';

import useSessionStore from 'src/context/sessionStore';
import { RegisterErrors } from '../context/appSessionStore';
import { ScreensParamsList } from '../core/navigation/screens/NavigationScreens';
import { useAppTheme } from '../core/theme/ThemeBuilder';
import { emailValidator, entepriseKeyValidator, passwordValidator } from '../core/utils';


type Props = StackScreenProps<ScreensParamsList, 'registerEnterprise'>;

const RegisterEnterpriseScreen = (props: Props) => {

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  // const { register } = useAppSessionStore.getState()



  const alert = useAlert.getState().alert

  // const { init: initSubscriptionsStore } = useUserSubscriptionsStore.getState()
  // const { init: initFavouritesStore } = useFavouritesStore.getState()
  const navigation = props.navigation;
  const route = props.route;
  const [email, setEmail] = useState(route.params ? route.params.email ? route.params.email : '' : '');
  const [enterprise, setEnterprise] = useState(route.params ? route.params.enterprise ? route.params.enterprise : '' : '');

  // React.useEffect(() => {
  //   ((navigation as any) as DrawerNavigationProp<{}>).closeDrawer();
  // }, []);
  const _onBackPressed = () => {
    navigation.navigate('login')
  }

  const _onRegisterPressed = async () => {

    const emailError = emailValidator(email);
    const passwordError = passwordValidator(password);
    const enterpriseKeyError = entepriseKeyValidator(enterprise)

    if (emailError) {
      alert({ title: 'Revisa el email.', message: emailError })
      return;
    }

    if (enterpriseKeyError) {
      alert({ title: 'Revisa la empresa.', message: enterpriseKeyError })
      return;
    }

    if (passwordError) {
      alert({ title: 'Revisa la contraseña.', message: passwordError })
      return;
    }

    if (password != rePassword) {
      alert({ title: 'Comprueba los datos', message: 'Las contraseñas no coinciden' })
      return;
    }
    setLoading(true);
    //do register
    try {

      console.log(email, password, enterprise)
      await useSessionStore.getState().register(email, password, enterprise)
      try {
        navigation.reset({ routes: [{ name: 'home' }] })
      } catch (e) {
        navigation.navigate('home')
      }

    }
    catch (e) {
      if (e.message === RegisterErrors.CONNECTIVITY) {

        alert({ title: 'Problemas de conexión', message: 'Hubo un problema contactando con el servidor, comprueba tu conexión a internet o inténtalo más tarde' })
        // registerError('Hubo un problema contactando con el servidor, comprueba tu conexión a internet o inténtalo más tarde')
      } else if (e.message === RegisterErrors.EMAIL_ALREADY_EXISTS) {
        alert({ title: 'Cuenta duplicada', message: 'El email ' + email + ' ya existe en nuestro sistema.' })
        // registerError('El email '+ email + ' ya existe en nuestro sistema.')
      } else {
        alert({ title: 'Error desconocido', message: 'Hubo un problema inesperado creando la cuenta' })
        // registerError('Hubo un problema inesperado creando la cuenta')
      }

      setLoading(false)

    }

  };

  const inputEnterprise = React.useRef<TextInput>(null);
  const inputPass = React.useRef<TextInput>(null);
  const inputRePass = React.useRef<TextInput>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showingPassword, setShowingPassword] = React.useState<boolean>(false);

  const theme = useAppTheme()

  // const [showEnterprise, setShowEnterprise] = React.useState(false)
  // const [enterpriseCode, setEnterpriseCode] = React.useState(null)

  return (
    <Background >
      <View style={{ width: '100%', flex: 1, flexDirection: "column", justifyContent: 'center', padding: 15 }}>
        <View style={{ height: 90, marginBottom: 30, flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>

        {
          loading ?
            <View style={{
              flex: 1,
              justifyContent: 'center',
              alignContent: 'center',
              alignSelf: 'center',
              alignItems: 'center'
            }}>
              <ActivityIndicator size="large" animating={true} color={theme.colors.primary[500]} style={{ flex: 1 }} />
            </View> :
            <View>

              <NtyTextInputRound
                nativeID='emailNty'
                placeholder='Email'
                returnKeyType='next'
                importantForAutofill='yes'
                autoComplete='email'
                textContentType='emailAddress'
                keyboardType='email-address'
                autoCapitalize="none"
                onSubmitEditing={() => inputEnterprise.current?.focus()}
                onChangeText={setEmail}
                value={email}
              />

              <NtyTextInputRound
                ref={inputEnterprise}
                nativeID='enterpriseNty'
                placeholder='Empresa'
                returnKeyType='next'
                importantForAutofill='yes'
                autoCapitalize="none"
                onSubmitEditing={() => inputPass.current?.focus()}
                onChangeText={setEnterprise}
                value={enterprise}
              />


              <NtyTextInputRound
                nativeID='passwordNty'
                ref={inputPass}
                placeholder='Contraseña'
                returnKeyType='next'
                importantForAutofill='yes'
                autoComplete='password'
                textContentType='newPassword'
                keyboardType='default'
                autoCapitalize="none"
                secureTextEntry={!showingPassword}
                onSubmitEditing={() => inputRePass.current?.focus()}
                onChangeText={setPassword}
                icon={showingPassword ? 'eye-off' : 'eye'}
                onIconClick={() => { setShowingPassword(!showingPassword) }}
              />

              <NtyTextInputRound
                nativeID='repeatPasswordNty'
                ref={inputRePass}
                placeholder='Repite la contraseña'
                returnKeyType='next'
                importantForAutofill='yes'
                autoComplete='password'
                textContentType='newPassword'
                keyboardType='default'
                secureTextEntry={!showingPassword}
                autoCapitalize="none"
                onSubmitEditing={_onRegisterPressed}
                onChangeText={setRePassword}
                icon={showingPassword ? 'eye-off' : 'eye'}
                onIconClick={() => { setShowingPassword(!showingPassword) }}
              />

              <ButtonNty color={theme.colors.primary[500]} onPress={_onRegisterPressed} mergeStyles={{ marginBottom: 20, marginTop: 10 }}>Registrar</ButtonNty>

              <TextNty type='light' style={{ marginBottom: 10, textAlign: 'center' }}>¿Ya tienes una cuenta?</TextNty>

              <ButtonNty color={theme.colors.secondary[700]} onPress={_onBackPressed} mergeStyles={{ marginBottom: 30 }}>Inicia Sesión</ButtonNty>

              {/* <TextNty type='light' style={{ marginBottom: 10, textAlign: 'center' }}>¿Vienes de una Empresa?</TextNty>
              <ButtonNty color={theme.colors.secondary[700]} onPress={() => { setShowEnterprise(true) }} mergeStyles={{ marginBottom: 30 }}>Regístrate aquí</ButtonNty> */}

              {/* {showEnterprise ?
                <View style={{marginBottom: 20}}>
                  <NtyTextInputRound
                    nativeID='eneterpriseCode'
                    placeholder='Introduce un Código de Empresa válido.'
                    returnKeyType='next'
                    importantForAutofill='no'
                    keyboardType='default'
                    autoCapitalize="none"
                    onChangeText={setEnterpriseCode}
                    icon={'enterprise'}
                  ></NtyTextInputRound>
                </View> : null} */}

            </View>
        }

      </View>
    </Background>
  );
};


export default memo(RegisterEnterpriseScreen);
