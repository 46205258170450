import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, ScaledSize, useWindowDimensions, View } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import ActionApi, { ACTION_NAMES } from '../../../api/ActionApi';
import { getWebAgent } from '../../../core/jsutils';
import Storage from '../../../core/storage';
import { useAppTheme } from '../../../core/theme/ThemeBuilder';
import { openAppInAndroMarket, openAppInIOSMarket } from '../../../core/utils';
import { Navigation } from '../../../types';
import { useRegisterModal } from '../../context/alertContext';
import ButtonNty from '../nty/button/ButtonNty';
import TextNty from '../nty/text/TextNty';
import TitleNty from '../nty/title/TitleNty';


export type DownloadTheAppModalProps = {

    visible: boolean,
    onActionPressed?: (action: 'login' | 'register' | 'download' | 'later' | 'premium' | 'plans') => void,
    onLaterPressed?: () => void,
    navigation?: Navigation

}

export default function DownloadTheAppModal(props: DownloadTheAppModalProps) {

    const additionalText = useRegisterModal(store => store.additionalText)

    const dimensions = useWindowDimensions();
    const navigation: Navigation = useNavigation();
    const theme = useAppTheme();


    const containerMaxWidth = (dimensions: ScaledSize) => {
        let size;

        if (dimensions.width > 1300) {
            size = "25%";
        } else if (dimensions.width > 700) {
            size = "35%";
        } else if (dimensions.width > 550) {
            size = "65%";
        } else {
            size = "85%";
        }

        return size;
    };

    // const isUserLoggedIn = () => {
    //     if (currentUser.token) {
    //         return true;
    //     }
    // }

    const openAppMarket = async () => {

        const agent = getWebAgent();
        const active = await Storage.read('qrActive');
        const enterprise = await Storage.read('qrEnterprise');

        ActionApi.createFrom({ name: ACTION_NAMES.DOWNLOAD_APP, data: { qr: active ? true : false, enterprise: enterprise } })

        if (Platform.OS == 'web') {
            if (agent == 'android') {
                openAppInAndroMarket();
            }

            else if (agent == 'ios') {
                openAppInIOSMarket();
            } else {

                openAppInIOSMarket();
                openAppInAndroMarket();

            }
        } else if (Platform.OS == 'android') {
            openAppInAndroMarket();
        } else if (Platform.OS == 'ios') {
            openAppInIOSMarket();
        }

    }

    const login = () => {

        navigation.navigate('login');
        props.onActionPressed ? props.onActionPressed('login') : null;

    }

    const register = () => {

        navigation.navigate('register');
        props.onActionPressed ? props.onActionPressed('register') : null;
    }

    // const goPremium = () => {
    //     navigation.navigate('plans');
    //     ActionApi.createFrom({ name: ACTION_NAMES.CLICK_PREMIUM, data: null })
    //     props.onActionPressed ? props.onActionPressed('plans') : null;
    // }

    const later = () => {

        props.onLaterPressed ? props.onLaterPressed() : null;
        props.onActionPressed ? props.onActionPressed('later') : null;

    }

    return (
        <Portal>
            <Modal visible={props.visible} contentContainerStyle={{ maxWidth: containerMaxWidth(dimensions), backgroundColor: theme.colors.background[300], alignSelf: "center", borderRadius: 30, paddingVertical: 10 }} dismissable={false}>

                <View nativeID='modal-box' style={{ flexDirection: 'column', justifyContent: 'center', overflow: 'hidden', paddingHorizontal: 10, paddingVertical: 25 }}>

                    <View nativeID='modal-header' style={[{ justifyContent: 'center', paddingHorizontal: 10, marginTop: 10, marginBottom: 30 }]}>
                        <TitleNty color={theme.colors.text_primary[600]} style={{ textAlign: 'center' }}>{additionalText ? additionalText : 'Para utilizar esta función tienes que estar registrado'}</TitleNty>
                    </View>

                    <View style={[{ flexDirection: 'row', paddingVertical: 25 }]}>
                        <View style={{ flex: 1, justifyContent: 'center', paddingHorizontal: 20 }}>
                            <TextNty type='semiBold' style={{ textAlign: 'center' }}>¡Regístrate en menos de un minuto y sigue a tus medios y autores favoritos!</TextNty>
                            {Platform.OS == 'web' && <TextNty type='light' style={{ marginTop: 10, textAlign: 'center' }} >Disfruta la experiencia completa en la app</TextNty>}
                        </View>
                    </View>
                    <View style={[{ flexDirection: 'row', paddingVertical: 20 }]}>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', paddingHorizontal: 20 }}>
                            <MaterialCommunityIcons name='headphones' size={40} color={theme.colors.text_primary[400]} />
                            <MaterialCommunityIcons name='bookmark-outline' size={40} color={theme.colors.text_primary[400]} />
                            <MaterialCommunityIcons name='format-letter-case' size={40} color={theme.colors.text_primary[400]} />
                        </View>
                    </View>
                    <View nativeID='actions' style={{ paddingHorizontal: 30, marginTop: 15, flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        <ButtonNty mergeStyles={{ marginTop: 5 }} onPress={login}>Iniciar Sesión</ButtonNty>
                        <ButtonNty mergeStyles={{ marginTop: 5 }} mode={'outlined'} color={theme.colors.secondary[600]} onPress={register}>Registrarse</ButtonNty>
                        {Platform.OS == 'web' && <ButtonNty mergeStyles={{ marginTop: 5 }} icon={'download'} mode={'outlined'} onPress={openAppMarket}>Abrir en la App</ButtonNty>}
                        <ButtonNty mergeStyles={{ marginTop: 5 }} mode={'text'} color={theme.colors.secondary[400]} onPress={later}>Más tarde</ButtonNty>
                    </View>
                </View>
            </Modal>
        </Portal >
    )
}
