import React from 'react'
import { View } from 'react-native'
import NtySheetModal, { NewsToYouSheetModalHandle } from 'src/app/components/modals/general/NtySheetModal'
import ButtonNty from '../../../components/nty/button/ButtonNty'
import TextNty from '../../../components/nty/text/TextNty'
import TitleNty from '../../../components/nty/title/TitleNty'
import { useAlert } from '../../alertContext'
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types'
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens'
import { useNavigation } from '@react-navigation/native'


function interpolate(i, a, b, a2, b2): number {
    return (i - a) * (b2 - a2) / (b - a) + a2;
}

function NtyAlertModal() {

    const { visible, alertInfo: data, hide, show, rejectCurrentAlert, resolveCurrentAlert } = useAlert()
    const refAlert = React.useRef<NewsToYouSheetModalHandle>()

    const visibleRef = React.useRef(false)

    const hideAlert = () => {

        visibleRef.current = false;
        refAlert?.current?.hide()
        hide()

    }
    const showAlert = () => {

        visibleRef.current = true;
        refAlert?.current?.show()
        show()

    }

    React.useEffect(() => {
        console.log({ visible, visibleRef: visibleRef.current })
        if (visible == true && visibleRef.current == false) {
            console.log('showing')
            showAlert()
        } else if (visible == false && visibleRef.current == true) {
            console.log('closing')
            hideAlert()
        }
    }, [visible])

    const MessageComponent = data.message

    return (
        <NtySheetModal modalStyle={{zIndex: 10000}} dissmisable={data.dismissable != false} ref={refAlert} onClose={() => {  hideAlert(); resolveCurrentAlert(null) }} snapPoints={[interpolate(typeof data.message == 'string' ? data.message.length : 200, 0, 600, 260, 700), "60%"]}  >

            <View style={{ flex: 1, paddingHorizontal: 30, paddingTop: 20 }}>

                <TitleNty fontSize={22}>{data.title}</TitleNty>
                {
                    typeof data.message == 'string' ?
                        <View style={{ width: "95%", marginVertical: 20, paddingHorizontal: 10 }}>
                            <TextNty fontSize={20}>{data.message}</TextNty>
                        </View>
                        : (typeof data.message == 'function' ? <MessageComponent/> : data.message)
                }
                {data.buttons && <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}>
                    {data.buttons?.map((b) => <ButtonNty key={b.label + Date.now().toString()} mode={b.type ?? 'contained'} onPress={() => { b.onPress ? b.onPress(b) : null; hideAlert() }} mergeStyles={{ marginLeft: 10 }}>{b.label}</ButtonNty>)}
                </View>}

            </View>

        </NtySheetModal>
    )
}

export default NtyAlertModal
