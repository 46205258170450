import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { memo } from "react";
import { Clipboard, GestureResponderEvent, Linking, Platform, StyleSheet, View } from "react-native";
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Divider } from 'react-native-paper';
import { Article } from 'systemDomain';
import { useAppTheme } from '../../../../core/theme/ThemeBuilder';
// import { theme } from '../../../../core/theme';
import { StackNavigationProp } from '@react-navigation/stack';
import useSessionStore from 'src/context/sessionStore';
import { ScreensParamsList } from 'src/core/navigation/screens/NavigationScreens';
import { isValidLink } from '../../../../core/jsutils';
import { isSpecialType, openCusomLink, openInBrowser, openNewTabLink, shareArticle } from '../../../../core/utils';
import { useArticleEnterpriseAdminOptionsStore, useArticleOptionsStore, useArticleSuperUserOptionsStore, useFavouriteListSelectorStore } from '../../../context/optionStores';
import { useSnackBar } from '../../../context/snackBarStore';
import TextNty from '../../nty/text/TextNty';
import TitleNty from '../../nty/title/TitleNty';



export type ArticleOptionsProps = {
    /**
     * Optional.
     *
     * Use this parameter to make this instance of ArticleOptionsList work for a specific article object.
     * If not passed, the article in {@link useArticleOptionsStore} would be used.
     */
    article?: Article;
    hide?: () => void,
    show?: () => void,
    showFollowAuthor?: boolean;
    showFollowNewspaper?: boolean;
    showAddToFavourites?: boolean;
    navigation?: StackNavigationProp<ScreensParamsList, null>;
}

/**
 *
 * This component is used to show common actions associated with an article.
 *
 * - Subscribe to newspaper
 * - Subscribe to author
 * - Save Newspaper
 * - Share article
 * - Open in external browser (only for devices)
 *
 */
const ArticleOptionsList = ({ navigation, show, hide, article: articleParam = null, showFollowAuthor = true, showFollowNewspaper = true, showAddToFavourites = true }: ArticleOptionsProps) => {


    if (navigation == undefined) {
        navigation = useNavigation();
    }

    const theme = useAppTheme()

    // const showRegisterModal = useRegisterModal(store => store.show)

    const showInfoMessage = useSnackBar((store) => store.showInfoMessage);

    // const { session, user, profile, enterprise, isEnterprise } = useAppSessionStore((store) => ({ user: store.user, session: store.session, profile: store.profile, enterprise: store.enterprise, isEnterprise: store.isEnterprise }));
    // const currentUser = user instanceof SessionUser ? user : SessionUser.fromObject(user);

    const { profile, enterprise, isGuest } = useSessionStore((store) => ({ profile: store.profile, enterprise: store.enterprise, isGuest: store.isGuest }))
    const article = articleParam ? articleParam : useArticleOptionsStore((store) => store.article);

    const showSnackMessage = (message: string) => {
        showInfoMessage(message)
    }

    const copyToClipboard = (url: string) => {
        Clipboard.setString(url);
    };

    const onSharePressed = async (event: GestureResponderEvent) => {
        shareArticle(article);
        hide ? Platform.OS != 'ios' ? hide() : null : null;
    }

    const _toggleFavourite = async () => {
        openFavouriteListSelector({article: article})
    }

    const _openBrowser = async () => {

        try {
            isSpecialType(article) ? openCusomLink(article) :
                Linking.canOpenURL(article?.url).then(
                    (supported) => {
                        if (supported) {
                            Linking.openURL(article?.url);
                        }
                    })

        } catch (error) {
            //Alert.alert('Error inesperado', 'Hubo un problema al intentar abrir el enlace en el navegador. Se copiará el enlace en el portapapeles.');
            showSnackMessage('Hubo un problema al intentar abrir el enlace en el navegador. Se copiará el enlace en el portapapeles.')
            copyToClipboard(article?.url);

        } finally {

            hide ? hide() : null;
        }

    }

    const _openScreenshot = () => {

        // navigation.navigate('viewArticleScreenshot', {article: article.props, id: article.getId()})

        for(const sc of article.media.screenshots) {
            openInBrowser(sc.url)
        }

        hide ? hide() : null

    }


    const openInNewTab = async () => {

        try {

            isSpecialType(article) ? openCusomLink(article) : openNewTabLink(article.url)

        } catch (error) {
            console.error(error)
            //Alert.alert('Error inesperado', 'Hubo un problema al intentar abrir el enlace en el navegador. Se copiará el enlace en el portapapeles.');
            showSnackMessage('Hubo un problema al intentar abrir el enlace de la noticia. Se copiará el enlace en el portapapeles.')
            copyToClipboard(article.url);

        } finally {

            hide ? hide() : null;
        }

    }

    const { open: openSuperUserOptions } = useArticleSuperUserOptionsStore.getState()
    const { open: openFavouriteListSelector } = useFavouriteListSelectorStore.getState()
    const { open: openEnterpriseAdminOptions } = useArticleEnterpriseAdminOptionsStore.getState()

    const isTempArticle = article?._id.indexOf('temp_article') != -1


    if (!article) return <View style={{ flex: 1, justifyContent: 'center', paddingHorizontal: 30, paddingVertical: 20 }}><TitleNty style={{ alignSelf: 'center' }}>No se ha selecionado un artículo</TitleNty></View>


    if (isTempArticle) return <View style={{ flex: 1, justifyContent: 'center', paddingHorizontal: 20, paddingVertical: 20 }}>
        <TitleNty style={{ marginVertical: 10 }}>No hay opciones disponibles para el artículo</TitleNty>
        <TextNty style={{ marginVertical: 10 }} fontSize={16}>Este artículo es temporal, se creó como parte de un destacado.</TextNty>
        <TextNty fontSize={16}>No existe en nuestra base de datos. </TextNty>
    </View>

    return (
        <View style={{ flex: 1, paddingHorizontal: 40, paddingVertical: 10, alignContent: 'center' }}>

            {showAddToFavourites && !isGuest &&
                <>
                    <TouchableOpacity style={styles.itemContainer} onPress={_toggleFavourite}>


                        {/* {favouriteLoading ?
                            <ActivityIndicator style={{ position: "absolute" }} size={15} color={theme.colors.text_primary[400]} /> : */}
                        <MaterialCommunityIcons
                            name={"bookmark-plus"}
                            color={theme.colors.text_primary[400]}
                            size={25}
                        />
                        {/* } */}

                        <TextNty style={[styles.text, { color: theme.colors.text_primary[400] }]}>{"Añadir a una lista"}</TextNty>


                    </TouchableOpacity >
                    <Divider style={styles.divider} />
                </>
            }
            {
                // @ts-ignore
                <TouchableOpacity style={styles.itemContainer} onPress={onSharePressed}>

                    <MaterialCommunityIcons
                        name="share"
                        color={theme.colors.text_primary[400]}
                        size={25}
                    />
                    <TextNty style={styles.text}>Compartir noticia</TextNty>

                </TouchableOpacity >
            }
            {Platform.OS != 'web' && isValidLink(article.url) &&
                <>
                    <Divider style={styles.divider} />
                    <TouchableOpacity style={styles.itemContainer} onPress={_openBrowser}>



                        <MaterialCommunityIcons
                            name="web"
                            color={theme.colors.text_primary[400]}
                            size={25}
                        />

                        <TextNty style={styles.text}>Ver en el navegador</TextNty>

                    </TouchableOpacity >
                </>
            }

            {article.media?.screenshots?.length &&
                <>
                    <Divider style={styles.divider} />
                    <TouchableOpacity style={styles.itemContainer} onPress={_openScreenshot}>



                        <MaterialCommunityIcons
                            name="image-area"
                            color={theme.colors.text_primary[400]}
                            size={25}
                        />

                        <TextNty style={styles.text}>Recorte completo</TextNty>

                    </TouchableOpacity >
                </>
            }

            {Platform.OS == 'web' && isValidLink(article.url) &&
                <>
                    <Divider style={styles.divider} />
                    <TouchableOpacity style={styles.itemContainer} onPress={openInNewTab}>
                        <MaterialCommunityIcons
                            name="web"
                            color={theme.colors.text_primary[400]}
                            size={25}
                        />
                        <TextNty style={styles.text}>Abrir en otra pestaña</TextNty>
                    </TouchableOpacity>
                </>
            }

            {profile && profile.superUser &&
                <>
                    <Divider style={styles.divider} />
                    <TouchableOpacity style={styles.itemContainer} onPress={() => { openSuperUserOptions(article); hide() }}>

                        <MaterialCommunityIcons
                            name="professional-hexagon"
                            color={theme.colors.text_primary[400]}
                            size={25}
                        />

                        <TextNty style={styles.text}>Opciones de Super Usuario</TextNty>

                    </TouchableOpacity >
                </>
            }

            {enterprise && enterprise?.admins?.indexOf(profile.email) != -1 &&
                <>
                    <Divider style={styles.divider} />
                    <TouchableOpacity style={styles.itemContainer} onPress={() => { openEnterpriseAdminOptions(article); hide() }}>

                        <MaterialCommunityIcons
                            name="professional-hexagon"
                            color={theme.colors.text_primary[400]}
                            size={25}
                        />

                        <TextNty style={styles.text}>Opciones de Administrador de Empresa</TextNty>

                    </TouchableOpacity >
                </>
            }

        </View>
    );
}

const styles = StyleSheet.create({
    text: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
        marginLeft: 25
    },
    itemContainer: {
        maxHeight: 30,
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center'
    },
    divider: {
        marginBottom: 20
    }
})

export default memo(ArticleOptionsList);
