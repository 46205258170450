import React from "react"
import { StyleSheet } from "react-native"
import { fixed_colors } from '../../../../../../core/theme/colors';
import { useAppTheme } from "../../../../../../core/theme/ThemeBuilder";
import { hexTransparency } from "../../../../../../core/jsutils";
import { Platform } from "react-native";
import { Article } from "systemDomain";


export const useStyles = (article) => {

  const isPaywall = React.useMemo(() => {
    const a: Article = article;
    if (!a) return false;
    return a.isPaywall ?? a.cedro_info.publication?.publicationType == 'Paywall';
  }, [article])

  const theme = useAppTheme();


  const styles = React.useMemo(() => {

    return StyleSheet.create({
      mediaContainer: {
        borderTopStartRadius: 5,
        borderTopEndRadius: 5,
        overflow: 'hidden'
      },
      mainContainer: {
        flex: 1,
        backgroundColor: isPaywall ? '#FFF1E5' : theme.colors.background[200],
        borderRadius: 5,
        ...theme.shadows.box.xsmall,
        width: "100%",
        height: "100%"
      },
      mediaCover: {
        ...StyleSheet.absoluteFillObject,
        zIndex: -10
      },
      titleStyle: { color: theme.colors.text_primary[400], ...theme.fonts.article.semiBold, lineHeight: 22 },
      subtitleStyle: { color: theme.colors.text_primary[300], ...theme.fonts.article.regular, },
      cardContent: {
        flex: 1,
        padding: 5,
        flexDirection: 'column-reverse'
      },
      pubDateContainer: { flexDirection: 'row', justifyContent: 'flex-start', marginVertical: 2 },
      subtitleContainer: { paddingLeft: Platform.OS == 'web' ? 10 : 5, paddingVertical: 5, marginTop: 5, marginBottom: 10, marginLeft: 6, borderColor: theme.colors.primary[600], borderLeftWidth: 1, justifyContent: 'center' },
      // subtitleContainer: { flex: 2.2, paddingLeft: Platform.OS == 'web' ? 10 : 5, paddingVertical: 5, marginTop: 5, marginLeft: 6, borderColor: theme.colors.primary[600], borderLeftWidth: 1, justifyContent: 'center' },
      // titleContainer: { flex: Platform.OS == 'web' ? 2.5 : 2 , paddingLeft: 5, justifyContent: 'center', marginTop: 5 },
      titleContainer: { paddingLeft: 5, marginTop: 5, marginBottom: 10 },
      keywords: { color: theme.colors.text_primary[300], fontWeight: "bold", fontSize: 10 },
      authorText: {
        fontSize: 12,
        textAlign: "left",
        color: theme.colors.text_primary[300]
      },
      dashText: {
        marginHorizontal: 5,
        fontSize: 11,
        fontWeight: "400",
        textAlign: "left",
        color: theme.colors.text_primary[300]
      },
      dateText: {
        fontSize: 12,
        fontWeight: "400",
        textAlign: "left",
        color: theme.colors.text_primary[300]
      },
      newspaperBubbleLabel: {
        fontFamily: theme.fonts.default.medium.fontFamily,
        fontSize: 11,
        lineHeight: 18,
        backgroundColor: hexTransparency(theme.colors.background[200], 70),
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 10,
        overflow: 'hidden',
      },
      cardPubDateKeyContainer: {

        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      textContainer: { flex: 2, paddingHorizontal: 5, paddingTop: 5, justifyContent: 'center' },
      cardBottomContainer: {
        width: "100%",
        // marginTop: 5,
        marginBottom: 5,
        paddingHorizontal: 5,
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 0.3,
        zIndex: 1100
        // position: 'absolute',
        // bottom: 0
      },
      favouriteArticle: { zIndex: 9, backgroundColor: 'white' },
      cardOptionsContainer: {
        // zIndex: 5,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      cardOptions: {
        // zIndex: 5,
        marginRight: 5,
        color: theme.colors.text_primary[300],
        backgroundColor: 'white'
      }

    })

  }, [theme, isPaywall])

  return { styles }
}
