import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import 'moment/locale/es';
import React from 'react';
import { Animated, ColorValue, RegisteredStyle, StyleSheet, View, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import useSessionStore from 'src/context/sessionStore';
import Logo from '../../../components/Logo';
import { useAppDataStore } from '../../../context/appDataStore';
import { useAppTheme } from '../../../core/theme/ThemeBuilder';
import { getContrastColor } from '../../../core/utils';
import TextNty from '../nty/text/TextNty';



export interface WelcomeHeaderNewProps {
    contentContainerStyle?: false | RegisteredStyle<ViewStyle> | Animated.Value | Animated.AnimatedInterpolation<string | number> | Animated.WithAnimatedObject<ViewStyle> | Animated.WithAnimatedArray<ViewStyle>,
    iconsColor?: ColorValue
}


export default function WelcomeHeaderNew({ contentContainerStyle, iconsColor }: WelcomeHeaderNewProps) {

    const navigation = useNavigation();

    const theme = useAppTheme();

    const styles = React.useMemo(() => StyleSheet.create({
        container: {
            flexDirection: 'row'
        },
        header: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        left: {
            flex: 3,
            flexDirection: 'row',
            justifyContent: 'center'
        },
        right: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center'
        },
        dateTittle: {
            fontSize: 20,
            fontWeight: '800',
            color: theme.colors.text_primary[500],
            fontFamily: 'Roboto_400Regular',
        }
    }), [theme])

    const [unseenNotifications, setUnseenNotifications] = React.useState<any>(false);
    const { notifications, seenNotifications, clear } = useAppDataStore((store) => ({ notifications: store.notifications, seenNotifications: store.seenNotifications, clear: store.clearSeenNotifications }));

    // const isEnterprise = useAppSessionStore(store => store.isEnterprise)

    const enterprise = useSessionStore(store => store.enterprise)
    const checkUnseenNotifications = async () => {

        let seen = 0;
        for (let n of notifications) {
            const key = 'notification_' + n._id;
            const found = seenNotifications.find((seen) => seen._id == n._id)
            if (found) {
                seen++;
            }
        }
        if (notifications.length != seen) {
            setUnseenNotifications(notifications.length - seen)
        } else {
            setUnseenNotifications(false)
        }
    }

    React.useEffect(

        () => {
            // AsyncStorage.removeItem('notification_327daffsd602ahsdasds31b6b49b09a1ec89c72a7ce16as')
            if (!notifications || notifications.length == 0) return

            checkUnseenNotifications()
        }
        , [notifications, seenNotifications]);

    // const {open} = useArticleSuperUserOptionsStore()

    // const {expand} = useBottomSheet()



    return (
        <Animated.View style={contentContainerStyle ?? styles.container}>
            <View style={[styles.left]}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                    {/* <View style={{padding: 5, borderRadius: 32, backgroundColor: theme.colors.background[500]}}> */}
                    <Logo height={45} type={'dark'}/>
                    {/* </View> */}

                </View>
                <View style={{ position: 'absolute', left: 20, height: "100%", flexDirection: 'column', justifyContent: 'center' }}>
                    {unseenNotifications && !enterprise &&
                        <View style={{ position: 'absolute', top: 10, right: 3, width: 12, height: 12, borderRadius: 6, zIndex: 10, backgroundColor: theme.colors.primary[400] }}>
                            <TextNty fontSize={9} type='bold' color={iconsColor ?? getContrastColor(theme.colors.primary[400])} style={{ textAlign: 'center', textAlignVertical: 'center' }}>{unseenNotifications}</TextNty>
                        </View>}
                    <TouchableOpacity
                        // style={{ paddingHorizontal: 15}}
                        onPress={() => {
                            ((navigation as any) as DrawerNavigationProp<{}>).openDrawer();
                        }}>
                        <MaterialCommunityIcons name='menu' size={32} color={iconsColor ?? getContrastColor(theme.colors.primary[500])} />
                    </TouchableOpacity>
                </View>
            </View>
            {/* <BottomSheetNty/> */}
        </Animated.View>
    )
}


