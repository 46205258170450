import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useRef } from "react";
import { ActivityIndicator, View, useWindowDimensions } from "react-native";
import { NamedQueryApi } from "src/api/NamedQueriesApi";
import ArticleListNewsToYouLight from "src/app/components/article/lists/ArticleListNewsToYouLight";
import { FeedHeader } from "src/app/components/enterprise/lists/header/EnterpriseFeedHeader";
import useSessionStore from "src/context/sessionStore";
import { ScreensParamsList } from "src/core/navigation/screens/NavigationScreens";
import { useAppTheme } from "src/core/theme/ThemeBuilder";
import { getNumColumnsFlatlist } from "src/core/utils";
import { Article, DefinedSection, DefinedSectionTypesNames } from "systemDomain";


const FeedArticlesSection = (props: { section: DefinedSection<DefinedSectionTypesNames.FEED> }) => {

    // const { enterpriseFeeds } = useAppSessionStore.getState()

    const feeds = useSessionStore(store => store.feeds)

    const feed = React.useMemo(() => feeds?.find((f) => f._id == props.section.info.params.feedId), [feeds])

    const theme = useAppTheme();
    const nav = useNavigation<StackNavigationProp<ScreensParamsList, any, undefined>>();

    const [filteredArticles, setFilteredArticles] = React.useState<Article[]>(props.section.info.data.items)
    const [loading, setLoading] = React.useState(false)


    const onRefresh = async () => {
        setLoading(true)
        const articles = await fetchArticles()
        setFilteredArticles(articles);
        setLoading(false)
    }

    const fetchArticles = (limit: number = 15, skip: number = 0, sort: { [key: string]: 1 | -1 } = { published_at: -1 }) => {
        return NamedQueryApi.fetch(props.section.info.data.namedQuery, props.section.info.params, { limit, skip, sort })
    }


    const flatListRef = useRef(null);
    // const [currentOffset, setCurrentOffset] = useState(0)
    const { width } = useWindowDimensions()



    // const { handlePrev, handleNext } = useFlatListControls(flatListRef, getCardWidth(), currentOffset, setCurrentOffset, filteredArticles, true)


    return (
        <View style={{ flex: 1, marginVertical: 15 }}>

            <FeedHeader feed={feed} onRefresh={onRefresh} fetchArticles={fetchArticles} data={filteredArticles} />

            <ArticleListNewsToYouLight
                articleCardProps={
                    {
                        feed: feed
                    }
                }
                ref={flatListRef}
                horizontal={true}
                loadMoreDisabled={true}
                contentContainerStyle={{ paddingBottom: 15 }}
                articles={filteredArticles}
                // contentContainerStyle={{ paddingVertical: Platform.OS != 'web' ? '5%' : 5 }}
                onViewMorePressed={() => { nav.navigate('searchEnterpriseFeed', { id: feed?._id }) }}
                numColumns={getNumColumnsFlatlist(width)} />

            {loading && <ActivityIndicator color={theme.colors.primary[500]} size={50} style={{ position: 'absolute', alignSelf: 'center', top: "15%", zIndex: 1000 }} />}

        </View>
    )
};


export default FeedArticlesSection;
