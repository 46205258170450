import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import { TouchableOpacity } from "react-native-gesture-handler";
import { FavouriteInterface, FavouriteListInterface } from "systemDomain";
import FavouriteListApi from "../../../api/FavouriteListApi";
import { theme } from "../../../core/theme";
import { getNumColumnsFlatlist } from "../../../core/utils";
import Background from "../../Background";
import ArticleListNewsToYouLight from "../../components/article/lists/ArticleListNewsToYouLight";
import TitleHeaderNty from "../../components/nty/header/TitleHeaderNty";
import TextNty from "../../components/nty/text/TextNty";
import { useFavouritesStore } from "../../context/favoritesStore";
import { useFavouriteListOptionsStore } from "../../context/optionStores";
import { useSnackBar } from "../../context/snackBarStore";
import ArticleList from "src/app/components/article/lists/ArticleList";
import useSessionStore from "src/context/sessionStore";
import Logo from "src/components/Logo";
import VirtualScrollHeaderScreen from "src/app/components/common-ui/VirtualScrollHeaderScreen";
import AutoImage from "src/components/AutoImage";



const FavouritesListPage = () => {

    const navigation = useNavigation()
    const route = useRoute()
    const { favourites: favs, favouriteLists, sendEmailFromFavlist: sendEmail } = useFavouritesStore();
    // const { width } = useWindowDimensions();
    const { isGuest } = useSessionStore()

    const [favList, setFavList] = React.useState<FavouriteListInterface>()
    const [favourites, setFavourites] = React.useState<FavouriteInterface[]>([])

    // const getNewspaperName = useAppDataStore.getState().getNewspapersByKeyName
    const [loadMoreDisabled, setLoadMoreDisabled] = React.useState(false)

    const showInfoMessage = useSnackBar(store => store.showInfoMessage);

    const fetchList = async (id: string) => {
        let list = favouriteLists?.find((l) => l._id == id)
        if (!list) {
            list = await FavouriteListApi.read(id)
        }
        setFavList(list)
    }

    const fetchListFavourites = async () => {
        let newFavs = favs?.filter((fav) => fav.list == favList?._id/*  || (favList.default && fav.list == null) */)
        if (!newFavs || newFavs.length == 0) {
            // console.log('Loading favourites from server for list ' + favList._id)
            newFavs = await FavouriteListApi.readFavourites(favList._id, 0, 0)
        }
        // console.log('Setting favourites for list ' + favList?._id)
        // console.log(newFavs.map(fav => ({ fav: fav?._id, article: fav.article?._id })))
        setFavourites(newFavs)
    }

    const loadMore = async () => {
        let newFavs = favs?.filter((fav) => fav.list == favList?._id/*  || (favList.default && fav.list == null) */)
        if (!newFavs || newFavs.length == 0) {
            // console.log('Loading favourites from server for list ' + favList._id)
            newFavs = await FavouriteListApi.readFavourites(favList._id, favourites.length, 20)
            if (newFavs.length < 20) {
                setLoadMoreDisabled(true)
            }
        }
        // console.log('Setting favourites for list ' + favList?._id)
        // console.log(newFavs.map(fav => ({ fav: fav?._id, article: fav.article?._id })))
        setFavourites(favourites.concat(newFavs))
    }

    // const _sendEmail = async () => {
    //     await sendEmail(favList)
    // }

    const height = useWindowDimensions()

    const openFavlistOptions = useFavouriteListOptionsStore.getState().open

    React.useEffect(() => {

        const id = route.params.id;
        fetchList(id)

    }, [route])

    React.useEffect(() => {
        console.log('fetching list favs')
        if (favList)
            fetchListFavourites()

    }, [favs, favList])

    const showFavouritesContent = () => {

        if (favourites && favourites.length != 0) {
            return (


                // <ArticleListNewsToYouLight
                //     // loadMoreDisabled={false}
                //     contentContainerStyle={{ paddingTop: 30, paddingBottom: 20 }}
                //     articles={favourites.map(fav => fav.article)}
                //     // contentContainerStyle={{ paddingVertical: Platform.OS != 'web' ? '5%' : 5 }}
                //     numColumns={getNumColumnsFlatlist(width)}
                //     // onLoadMore={() => }
                //     />

                <ArticleList
                    loadMoreDisabled={loadMoreDisabled}
                    data={favourites.map(fav => fav.article)}
                    contentContainerStyle={{ paddingTop: 30, paddingBottom: 50 }}
                    onLoadMore={() => loadMore()}
                    horizontal={false}
                />


            );
        }
        else {
            return (
                <View style={{ justifyContent: 'center', alignSelf: 'center', paddingVertical: 20 }}>
                    <TextNty style={styles.text}>No tienes ninguna noticia guardada.</TextNty>
                </View>
            );
        }
    }

    return (
        // <Background style={{ height: height.height }} safeInsets>
        //     <TitleHeaderNty title={favList?.name ?? '--'} onOptionsPress={!isGuest ? () => openFavlistOptions(favList) : null} onBackIconPressed={isGuest ? () => navigation.navigate('login'): null}>
        //         {!isGuest && <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 20, paddingVertical: 5 }} onPress={_sendEmail}>
        //             <TextNty fontSize={16} type="thin" color={'black'} style={{ textDecorationLine: 'underline' }}>Enviar Newsletter</TextNty>
        //             <MaterialCommunityIcons name="email-fast" color={theme.colors.primary[400]} size={20} style={{ marginLeft: 5 }} />
        //         </TouchableOpacity>}
        //     </TitleHeaderNty>
        //     <View style={styles.mainContainer}>

        //         {
        //             showFavouritesContent()
        //         }
        //     </View>


        // </Background>

        <VirtualScrollHeaderScreen
            headerHeight={favList?.icon ? 210 : Platform.OS == 'web' ? 100 : 120}
            headerBgColor={'transparent'}
            header={
                <View>
                    {favList?.icon && <View style={{ width: "100%", justifyContent: 'center', alignItems: 'center', marginVertical: 10 }}>

                        <AutoImage source={{ uri: favList?.icon }} height={120} />

                    </View>}
                    <TitleHeaderNty title={favList?.name ?? '--'} onOptionsPress={!isGuest ? () => openFavlistOptions(favList) : null} onBackIconPressed={isGuest ? () => navigation.navigate('login') : () => { navigation.navigate('home', { screen: 'nty/library' }) }} />
                </View>

            }
        >

            <View style={styles.mainContainer}>

                {
                    showFavouritesContent()
                }
            </View>
        </VirtualScrollHeaderScreen>



    );
}

export default FavouritesListPage;

const styles = StyleSheet.create({
    text: {
        color: '#9F9F9F',
        fontSize: 14,
        alignSelf: "center",
        justifyContent: 'center',
        width: '90%',
        textAlign: 'center'
    },
    titleContainer: {
        justifyContent: 'flex-start',
        alignSelf: 'center',
        flexDirection: 'row',
        width: '90%',
        marginBottom: 10,
        maxWidth: 700,
        minWidth: 350
    },
    title: {
        textAlign: 'left',
        color: 'rgba(55, 55, 55, 1)',
        fontWeight: '700',
        fontSize: 24
    },
    mainContainer: {
        // height: "100%",
        // paddingVertical: 20,
        flex: 1
        // paddingTop: Platform.OS != 'web' ? '10%' : 10
    }
});
