import { Platform } from "react-native";
import { ApplicablePublisherSearchResponse, Article, ArticleSearchFilters, ArticleSearchOrderBy, ArticleSearchSort, Pagination, SearchResponse, SortDirection } from "systemDomain";
import { StoreApi, create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { SearchApi } from "../api/SearchApi";


import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";


export enum DateFilterType {
    LAST_HOUR = 'Última Hora',
    TODAY = 'Hoy',
    YESTERDAY = 'Ayer',
    LAST_WEEK = 'Última semana',
    CUSTOM = 'Custom'
}


const searchInitialState: ArticleSearchFilters = {
    searchText: '',
    fromDate: '',
    toDate: '',
    formats: null,
    sources: []
}

export type SearchOptions = {
    page?: Pagination,
    sort?: ArticleSearchSort
}

const defaultSearchOptions: SearchOptions = {
    page: {
        skip: 0,
        limit: 20
    },
    sort: {
        orderBy: ArticleSearchOrderBy.DATE,
        direction: SortDirection.DESC
    }
}



export type SearchStoreType = {

    updated: Date,
    currentSearch: ArticleSearchFilters,
    currentOptions: SearchOptions,
    dateFilterType: DateFilterType,
    setDateFilterType: (type: DateFilterType) => void,
    loading: boolean,
    results: Article[] | undefined,
    searchResponse: SearchResponse<Article>,
    error: any,
    /**
     * This method will toggle the format filter in the current search
     * @param format
     * @returns
     */
    toggleFormat: (formats: string[]) => ArticleSearchFilters,
    /**
     * This method will perform a search and update the store with the results
     * @param search search filters
     * @param options search options
     * @param override `false`. Flag to override the current search. If false, the search will be merged with the current one. __Default is false.__
     * @returns
     */
    search: (search: ArticleSearchFilters, options?: SearchOptions, override?: boolean) => Promise<SearchResponse<Article> | null>,
    /**
     * This method will update the current search and options, but will not perform a search
     * @param search search filters
     * @param options search options
     * @param override `false`. Flag to override the current search. If false, the search will be merged with the current one. __Default is false.__
     * @returns
     */
    updateSearch: (search?: ArticleSearchFilters, options?: SearchOptions, override?: boolean) => void,
    /**
     * This method will load more results for the current search
     * @param page optional. Pagination object to load more results, if not provided, the current page will be used and skip will be increased by limit
     * @returns
     */
    /**
     * Returns the list of Publishers with results for the current or provided search
     * @returns
     */
    getApplicablePublishers: (search?: ArticleSearchFilters, nameSearch?: string) => Promise<ApplicablePublisherSearchResponse>
    downloadSearchReport: (search?: ArticleSearchFilters, options?: SearchOptions) => Promise<any>,
    loadMore: (page: Pagination) => Promise<SearchResponse<Article> | null>,
    refresh: () => Promise<SearchResponse<Article> | null>,
    reset: () => void
    // setTextSearch: (text: string) => void

}


const SearchStore: (set: StoreApi<SearchStoreType>['setState'], get: StoreApi<SearchStoreType>['getState']) => SearchStoreType =
    (set, get) => ({
        updated: new Date(),
        currentSearch: null,
        currentOptions: defaultSearchOptions,
        dateFilterType: DateFilterType.TODAY,
        setDateFilterType: (type) => {set({dateFilterType: type})},
        loading: false,
        results: null,
        searchResponse: null,
        error: null,
        reset: () => {
            set({ currentSearch: defaultDateFilter(), dateFilterType: DateFilterType.TODAY, currentOptions: { sort: { orderBy: ArticleSearchOrderBy.DATE, direction: SortDirection.DESC }, page: { limit: 20, skip: 0 } }, results: null, error: null, loading: false })
        },
        toggleFormat: (formats: string[]) => {
            const { currentSearch } = get()

            const search = { ...currentSearch }

            const curent_formats = search.formats ? [...search.formats] : []


            formats.forEach(format => {
                const index = curent_formats.indexOf(format)
                if (index == -1) {
                    curent_formats.push(format)
                } else {
                    curent_formats.splice(index, 1)
                }
            })

            search.formats = curent_formats

            if (search.formats.length == 0) {
                delete search.formats
            }

            set({ currentSearch: search })

            return search
        },
        getSearchBrands: () => {
            const { results } = get()
            if (results) {
                const brands = results.map(article => article.brand)
                return [...new Set(brands)]
            }
            return []
        },
        search: async (search: ArticleSearchFilters, arg_options?: SearchOptions, override: boolean = false) => {

            const { currentSearch, currentOptions } = get()
            const to_search = override ? search : { ...currentSearch, ...search }

            console.log(to_search)


            const options = { sort: { ...defaultSearchOptions.sort, ...currentOptions?.sort, ...arg_options?.sort }, page: { ...defaultSearchOptions.page, ...arg_options?.page } }
            try {
                if (to_search) {

                    set({ loading: true, currentSearch: to_search, currentOptions: options })
                    console.log('searching articles', { to_search, options })
                    const response = await SearchApi.searchArticles(to_search, options)
                    // console.log('searching articles RESULTS', results)
                    set({ results: response.results, searchResponse: response, loading: false })
                    return response
                }
            } catch (error) {
                console.error(error)
                set({ error: error, loading: false })
                return null
            }
        },
        updateSearch: (search?: ArticleSearchFilters, arg_options?: SearchOptions, override: boolean = false) => {

            const { currentSearch } = get()

            let update: any = {}

            if (search) {
                if (override) {
                    update = { currentSearch: search }
                } else {
                    update = { currentSearch: { ...currentSearch, ...search } }
                }
            }

            if (arg_options) {
                const options = { ...defaultSearchOptions, ...arg_options }
                update.currentOptions = options
            }

            // console.log('updateSearch', update)

            set(update)

        },
        downloadSearchReport: async (search?: ArticleSearchFilters, arg_options?: SearchOptions) => {

            const to_search = search ? search : get().currentSearch
            const options = arg_options ? arg_options : get().currentOptions

            if (to_search) {
                try {
                    const response = await SearchApi.searchArticlesExport(to_search, options)
                    return response
                } catch (error) {
                    set({ error: error })
                    return null
                }
            }

        },
        loadMore: async (page_arg?: Pagination) => {

            const { currentSearch, currentOptions, results } = get()
            let page

            if (page_arg) {
                page = page_arg
            } else {
                page = currentOptions.page
                page.skip += page.limit
            }

            if (currentSearch) {
                try {
                    const newOptions = { ...currentOptions, page: page }
                    set({ loading: true, currentOptions: newOptions })
                    const response = await SearchApi.searchArticles(currentSearch, newOptions)
                    set({ results: results.concat(response.results), searchResponse: response, loading: false })
                    return response
                } catch (error) {
                    set({ error: error, loading: false })
                    return null
                }
            }
            return null
        },
        refresh: async () => {
            const { currentSearch, currentOptions } = get()
            if (currentSearch) {
                try {
                    set({ loading: true })
                    const response = await SearchApi.searchArticles(currentSearch, currentOptions)
                    set({ results: response.results, searchResponse: response, loading: false })
                    return response
                } catch (error) {
                    set({ error: error, loading: false })
                    return null
                }
            }
            return null
        },
        getApplicablePublishers: async (search?: ArticleSearchFilters, nameSearch?: string) => {

            const to_search = search ? search : get().currentSearch

            delete to_search.sources

            if (to_search) {
                try {
                    const results = await SearchApi.searchArticlesPublishers(to_search, nameSearch)
                    return results
                } catch (error) {
                    set({ error: error, loading: false })
                    return null
                }
            }
            return null

        },
        /* ,
        setTextSearch: (text: string) => {
            const { currentSearch } = get()
            const search = produce(currentSearch, draft => {
                if (!draft) {
                    return {
                        searchText: text
                    }
                } else {
                    draft.searchText = text
                }
            })
            set({ currentSearch: search })
        },

        setDateFrom: (date: Date) => {
            const { currentSearch } = get()
            const search = produce(currentSearch, draft => {
                if (!draft) {
                    return {
                        fromDate: date.toISOString()
                    }
                } else {
                    draft.fromDate = date.toISOString()
                }
            })
            set({ currentSearch: search })
        } */


    });



let store;

// for testing purpouses
if (__DEV__ && Platform.OS == 'web') {
    store = devtools(SearchStore);
} else {
    store = SearchStore;
}

const SEARCH_STORE_KEY = 'searchStore'
// const persistedStore = persist(store, { name: 'searchStore', getStorage: () => { return AsyncStorage } });
const avoidPersistingThisFields = ['results', 'currentSearch', 'error'];

const persistedStore = create<SearchStoreType>()(persist(store, {
    name: SEARCH_STORE_KEY,
    // getStorage: () => { return AsyncStorage },
    storage: createJSONStorage(() => AsyncStorage),
    partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key, value]) => !avoidPersistingThisFields.includes(key))),
    // onRehydrateStorage: (state: SearchStoreType | never) => {

    //     // Before Hydration
    //     // console.log('Before Hydration:', state);
    //     const onHydrationDone = (state: AppSessionStoreType) => {
    //         // console.log('Hydrated session Store, tabs:', state.userTabs);
    //         hydrationSetup(state);
    //         if (state.forcedTheme && Object.entries(themes).map(([key, theme], index) => theme.name).indexOf(state.forcedTheme?.name) == -1)
    //             state.forceTheme(null)
    //         state.init().then(({ user }) => {
    //             console.log('SessionStore initialized!!');
    //             // state.setReady(true);
    //             useUserSubscriptionsStore.getState().init(user)
    //             useFavouritesStore.getState().init(user)
    //         })
    //     }
    //     return onHydrationDone;

    // }
}))


export const defaultDateFilter = () => ({ toDate: moment().seconds(0).milliseconds(0).toISOString(), fromDate: moment().subtract(1, 'day').startOf('day').toISOString() })


export const useSearchStore = persistedStore;
