import { ConfigParamCollection, ConfigParamModel } from 'systemDomain';
import ApiCaller from '../core/ApiCaller';

const getApiCaller = (): ApiCaller => {
    const apiCaller = new ApiCaller();
    return apiCaller
}


export enum CONFIG_PARAM_NAMES {

    SECTIONS_ORDER = 'sectionsOrder'

}

class ConfigParamApi {
    constructor() { }

    static async getConfigParamsByGroupName(groupName: string): Promise<ConfigParamCollection> {
        const url = '/v1/configParams/group/' + groupName
        const data = await getApiCaller().call(url, 'GET');
        return new ConfigParamCollection(data.map((item: any) => {
            return new ConfigParamModel(item);
        }));
    }


    static async getConfigParamByName(name: string): Promise<ConfigParamModel> {
        const url = '/v1/configParams/name/' + name
        const data = await getApiCaller().call(url, 'GET');
        return new ConfigParamModel(data[0]);
    }

}

export default ConfigParamApi;
